"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _financial = require("@/api/financial");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'AccountsCapital',
  data: function data() {
    return {
      timeVal: [],
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        // type: '',
        dateLimit: '',
        keywords: '',
        page: 1,
        limit: 20
      },
      categoryId: '',
      fromList: this.$constants.fromList,
      optionList: []
    };
  },
  mounted: function mounted() {
    // this.getTypes()
    this.getOptionList();
    this.getList();
  },
  methods: {
    getOptionList: function getOptionList() {
      var _this = this;
      (0, _financial.monitorListOptionApi)().then(function (res) {
        _this.optionList = res;
      });
    },
    selectChange: function selectChange(tab) {
      this.tableFrom.dateLimit = tab;
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _financial.monitorListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};
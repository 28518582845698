var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        [
          _c("div", { staticClass: "headerClass" }, [_vm._v("满意度评价")]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-left": "50px", "margin-bottom": "20px" } },
            [
              _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
              _vm._v("满意度评价提示语:\n    "),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.ruleForm,
                "label-width": "50px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "550px" },
                  attrs: { label: "", prop: "tips" },
                },
                [
                  _c("el-input", {
                    staticClass: "resizeNone",
                    attrs: { type: "textarea", rows: 5, maxlength: "150" },
                    model: {
                      value: _vm.ruleForm.tips,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "tips", $$v)
                      },
                      expression: "ruleForm.tips",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("(已输入" + _vm._s(_vm.num) + ")字")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addWuliu = addWuliu;
exports.cityInfo = cityInfo;
exports.cityList = cityList;
exports.cityListTree = cityListTree;
exports.cityUpdate = cityUpdate;
exports.expressDelete = expressDelete;
exports.expressInfo = expressInfo;
exports.expressList = expressList;
exports.expressSave = expressSave;
exports.expressSyncApi = expressSyncApi;
exports.expressUpdate = expressUpdate;
exports.expressUpdateShow = expressUpdateShow;
exports.shippingDetete = shippingDetete;
exports.shippingFree = shippingFree;
exports.shippingRegion = shippingRegion;
exports.shippingSave = shippingSave;
exports.shippingTemplatesList = shippingTemplatesList;
exports.shippingUpdate = shippingUpdate;
exports.templateDetailApi = templateDetailApi;
exports.updateStatus = updateStatus;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// 城市列表
function cityList(data) {
  return (0, _request.default)({
    url: '/admin/system/city/list',
    method: 'get',
    params: _objectSpread({}, data)
  });
}

// 城市列表
function cityListTree() {
  return (0, _request.default)({
    url: '/admin/system/city/list/tree',
    method: 'get'
  });
}

// 城市修改状态
function updateStatus(data) {
  return (0, _request.default)({
    url: '/admin/system/city/update/status',
    method: 'post',
    params: _objectSpread({}, data)
  });
}

// 城市修改
function cityUpdate(data) {
  return (0, _request.default)({
    url: '/admin/system/city/update',
    method: 'post',
    params: _objectSpread({}, data)
  });
}

// 城市详情
function cityInfo(data) {
  return (0, _request.default)({
    url: '/admin/system/city/info',
    method: 'get',
    params: _objectSpread({}, data)
  });
}
// 添加物流
function addWuliu(data) {
  return (0, _request.default)({
    url: '/admin/express/save',
    method: 'post',
    data: data
  });
}
// 物流公司列表
function expressList(data) {
  return (0, _request.default)({
    url: '/admin/express/list',
    method: 'get',
    params: _objectSpread({}, data)
  });
}

// 同步物流公司
function expressSyncApi() {
  return (0, _request.default)({
    url: '/admin/express/sync/express',
    method: 'post'
  });
}

// 物流公司修改状态
function expressUpdateShow(data) {
  return (0, _request.default)({
    url: '/admin/express/update/show',
    method: 'post',
    data: data
  });
}

// 物流公司 编辑
function expressUpdate(data) {
  return (0, _request.default)({
    url: '/admin/express/update',
    method: 'post',
    data: data
  });
}

// 新增物流公司
function expressSave(data) {
  return (0, _request.default)({
    url: '/admin/express/save',
    method: 'post',
    params: _objectSpread({}, data)
  });
}

// 删除物流公司
function expressDelete(data) {
  return (0, _request.default)({
    url: '/admin/express/delete',
    method: 'GET',
    params: _objectSpread({}, data)
  });
}

// 物流运费模板列表
function shippingTemplatesList(data) {
  return (0, _request.default)({
    url: '/admin/express/shipping/templates/list',
    method: 'get',
    params: _objectSpread({}, data)
  });
}

// 物流运费模板详情
function templateDetailApi(data) {
  return (0, _request.default)({
    url: '/admin/express/shipping/templates/info',
    method: 'get',
    params: _objectSpread({}, data)
  });
}

// 物流运费模板包邮
function shippingFree(data) {
  return (0, _request.default)({
    url: '/admin/express/shipping/free/list',
    method: 'get',
    params: _objectSpread({}, data)
  });
}

// 物流运费模板不包邮
function shippingRegion(data) {
  return (0, _request.default)({
    url: 'admin/express/shipping/region/list',
    method: 'get',
    params: _objectSpread({}, data)
  });
}

// 物流运费模板新增
function shippingSave(data) {
  return (0, _request.default)({
    url: 'admin/express/shipping/templates/save',
    method: 'post',
    data: data
  });
}

// 物流运费模板更新
function shippingUpdate(data, id) {
  return (0, _request.default)({
    url: 'admin/express/shipping/templates/update',
    method: 'post',
    data: data,
    params: _objectSpread({}, id)
  });
}

// 物流运费模板删除
function shippingDetete(data) {
  return (0, _request.default)({
    url: 'admin/express/shipping/templates/delete',
    method: 'get',
    params: data
  });
}

// 物流公司详情
function expressInfo(data) {
  return (0, _request.default)({
    url: 'admin/express/info',
    method: 'get',
    params: _objectSpread({}, data)
  });
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "tool", staticClass: "flow-menu" },
    _vm._l(_vm.menuList, function (menu) {
      return _c("div", { key: menu.id }, [
        _c(
          "span",
          {
            staticClass: "ef-node-pmenu",
            on: {
              click: function ($event) {
                menu.open = !menu.open
              },
            },
          },
          [
            _c("i", {
              class: {
                "el-icon-caret-bottom": menu.open,
                "el-icon-caret-right": !menu.open,
              },
            }),
            _vm._v(" " + _vm._s(menu.name)),
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: menu.open,
                expression: "menu.open",
              },
            ],
            staticClass: "ef-node-menu-ul clearfix",
          },
          [
            _c(
              "draggable",
              {
                attrs: { options: _vm.draggableOptions },
                on: { end: _vm.end, start: _vm.move },
                model: {
                  value: menu.children,
                  callback: function ($$v) {
                    _vm.$set(menu, "children", $$v)
                  },
                  expression: "menu.children",
                },
              },
              _vm._l(menu.children, function (subMenu) {
                return _c(
                  "li",
                  {
                    key: subMenu.id,
                    staticClass: "ef-node-menu-li",
                    attrs: { type: subMenu.type },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": subMenu.ico },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n            " + _vm._s(subMenu.name) + "\n          "
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
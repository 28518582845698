var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "editPram",
          attrs: { model: _vm.editPram, "label-width": "130px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "分类名称",
                prop: "name",
                rules: [
                  {
                    required: true,
                    message: "请输入分类名称",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: {
                  maxlength: _vm.biztype.value === 1 ? 8 : 20,
                  placeholder: "分类名称",
                },
                model: {
                  value: _vm.editPram.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.editPram, "name", $$v)
                  },
                  expression: "editPram.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "父级" } },
            [
              _c("el-cascader", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: _vm.isCreate === 1 && _vm.editPram.pid === 0,
                  options: _vm.allTreeList,
                  props: _vm.categoryProps,
                },
                model: {
                  value: _vm.editPram.pid,
                  callback: function ($$v) {
                    _vm.$set(_vm.editPram, "pid", $$v)
                  },
                  expression: "editPram.pid",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序" } },
            [
              _c("el-input-number", {
                model: {
                  value: _vm.sort,
                  callback: function ($$v) {
                    _vm.sort = $$v
                  },
                  expression: "sort",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-text": "显示",
                  "inactive-text": "隐藏",
                  "active-value": true,
                  "inactive-value": false,
                },
                model: {
                  value: _vm.editPram.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.editPram, "status", $$v)
                  },
                  expression: "editPram.status",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.biztype.value !== 1 &&
          _vm.biztype.value !== 3 &&
          _vm.biztype.value !== 5
            ? _c(
                "el-form-item",
                { attrs: { label: "扩展字段" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "扩展字段" },
                    model: {
                      value: _vm.editPram.extra,
                      callback: function ($$v) {
                        _vm.$set(_vm.editPram, "extra", $$v)
                      },
                      expression: "editPram.extra",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingBtn },
                  on: {
                    click: function ($event) {
                      return _vm.handlerSubmit("editPram")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-form",
        {
          ref: "formInline",
          staticClass: "login-form",
          attrs: {
            size: "small",
            model: _vm.formInline,
            rules: _vm.ruleInline,
            autocomplete: "on",
            "label-position": "left",
          },
        },
        [
          _c("div", { staticClass: "title-container" }, [
            _c("h3", { staticClass: "title mb15" }, [_vm._v("一号通账户注册")]),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "phone" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入您的手机号",
                  "prefix-icon": "el-icon-phone-outline",
                },
                model: {
                  value: _vm.formInline.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "phone", $$v)
                  },
                  expression: "formInline.phone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c("el-input", {
                key: _vm.passwordType,
                attrs: {
                  type: _vm.passwordType,
                  placeholder: "请输入短信平台密码/token",
                  tabindex: "2",
                  "auto-complete": "off",
                  "prefix-icon": "el-icon-lock",
                },
                model: {
                  value: _vm.formInline.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "password", $$v)
                  },
                  expression: "formInline.password",
                },
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                [
                  _c("svg-icon", {
                    attrs: {
                      "icon-class":
                        _vm.passwordType === "password" ? "eye" : "eye-open",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "domain" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入网址域名",
                  "prefix-icon": "el-icon-position",
                },
                model: {
                  value: _vm.formInline.domain,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "domain", $$v)
                  },
                  expression: "formInline.domain",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "captcha", attrs: { prop: "code" } },
            [
              _c(
                "div",
                {
                  staticClass: "acea-row",
                  staticStyle: { "flex-wrap": "nowrap" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "90%" },
                    attrs: {
                      placeholder: "验证码",
                      type: "text",
                      tabindex: "1",
                      autocomplete: "off",
                      "prefix-icon": "el-icon-message",
                    },
                    model: {
                      value: _vm.formInline.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "code", $$v)
                      },
                      expression: "formInline.code",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", disabled: !this.canClick },
                      on: { click: _vm.cutDown },
                    },
                    [_vm._v(_vm._s(_vm.cutNUm))]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: { loading: _vm.loading, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleSubmit("formInline")
                },
              },
            },
            [_vm._v("注册")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: { type: "primary" },
              on: { click: _vm.changelogo },
            },
            [_vm._v("立即登录")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
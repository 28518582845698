"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.articleTypeFilter = articleTypeFilter;
exports.bargainColorFilter = bargainColorFilter;
exports.bargainStatusFilter = bargainStatusFilter;
exports.couponApplyFilter = couponApplyFilter;
exports.couponCodeFilter = couponCodeFilter;
exports.couponTypeFilter = couponTypeFilter;
exports.couponUserTypeFilter = couponUserTypeFilter;
exports.extractStatusFilter = extractStatusFilter;
exports.extractTypeFilter = extractTypeFilter;
exports.filterCategroyType = filterCategroyType;
exports.filterConfigCategory = filterConfigCategory;
exports.filterEmpty = filterEmpty;
exports.filterShowOrHide = filterShowOrHide;
exports.filterShowOrHideForFormConfig = filterShowOrHideForFormConfig;
exports.filterYesOrNo = filterYesOrNo;
exports.filterYesOrNoIs = filterYesOrNoIs;
exports.formatDate = formatDate;
exports.groupColorFilter = groupColorFilter;
exports.groupStatusFilter = groupStatusFilter;
exports.hehhe = hehhe;
exports.keywordStatusFilter = keywordStatusFilter;
exports.onePassTypeFilter = onePassTypeFilter;
exports.payStatusFilter = payStatusFilter;
exports.rechargeTypeFilter = rechargeTypeFilter;
var constants = _interopRequireWildcard(require("@/utils/constants.js"));
var _index = require("@/utils/index");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
// 公共过滤器
function filterEmpty(val) {
  var _result = "-";
  if (!val) {
    return _result;
  }
  _result = val;
  return _result;
}

// 时间过滤器
function formatDate(time) {
  if (time !== 0) {
    var date = new Date(time * 1000);
    return (0, _index.formatDates)(date, "yyyy-MM-dd hh:mm");
  }
}
function filterYesOrNo(value) {
  return value ? "是" : "否";
}
function filterShowOrHide(value) {
  return value ? "显示" : "不显示";
}
function filterShowOrHideForFormConfig(value) {
  return value === "‘0’" ? "显示" : "不显示";
}
function filterYesOrNoIs(value) {
  return value ? "否" : "是";
}
function filterCategroyType(value) {
  return constants.categoryType.filter(function (item) {
    return value === item.value;
  })[0].name;
}
function filterConfigCategory(value) {
  return constants.configCategory.filter(function (item) {
    return value === item.value;
  })[0].label;
}

/**
 * @description 公众号回复类型
 */
function keywordStatusFilter(status) {
  var statusMap = {
    text: "文字消息",
    image: "图片消息",
    news: "图文消息",
    voice: "声音消息"
  };
  return statusMap[status];
}

/**
 * @description 优惠券类型
 */
function couponUserTypeFilter(status) {
  var statusMap = {
    1: "通用券",
    2: "商品券",
    3: "品类券"
  };
  return statusMap[status];
}

/**
 * @description 优惠券领取方式
 */
function couponTypeFilter(status) {
  var statusMap = {
    1: "手动领取",
    2: "新人券",
    3: "赠送券",
    4: "新企业会员"
  };
  return statusMap[status];
}

/**
 * @description 优惠券适用身份
 */
function couponApplyFilter(status) {
  var statusMap = {
    1: "企业身份",
    2: "园区身份"
  };
  return statusMap[status];
}

/**
 * @description 优惠券是否券码
 */
function couponCodeFilter(status) {
  var statusMap = {
    1: "线上码"
  };
  return statusMap[status];
}

/**
 * @description 文章分类
 */
function articleTypeFilter(status) {
  if (!status) {
    return "";
  }
  var arr2 = status.map(Number);
  // return
  var arr1 = JSON.parse(localStorage.getItem("articleClass"));
  // if(arrayList.filter(item => Number(status) === Number(item.id)).length < 1){
  //   return ''
  // }
  // return arrayList.filter(item => Number(status) === Number(item.id))[0].name
  var arr3 = [];
  for (var i = 0; i < arr1.length; i++) {
    for (var j = 0; j < arr2.length; j++) {
      if (arr2[j] === arr1[i].id) {
        arr3.push(arr1[i].name);
        arr2.splice(j, 1);
      }
    }
  }
  arr3.push.apply(arr3, _toConsumableArray(arr2));
  return arr3.join(" | ");
}
/**
 *  文章分类过滤器
 */
function hehhe(status) {
  if (!status) {
    return "";
  }
}
/**
 * @description 支付状态
 */
function payStatusFilter(status) {
  var statusMap = {
    false: "未支付",
    true: "已支付"
  };
  return statusMap[status];
}

/**
 * @description 提现方式
 */
function extractTypeFilter(status) {
  var statusMap = {
    bank: "银行卡",
    alipay: "支付宝",
    weixin: "微信"
  };
  return statusMap[status];
}

/**
 * @description 充值类型
 */
function rechargeTypeFilter(status) {
  var statusMap = {
    public: "微信公众号",
    weixinh5: "微信H5支付",
    routine: "小程序"
  };
  return statusMap[status];
}

/**
 * @description 财务审核状态
 */
function extractStatusFilter(status) {
  var statusMap = {
    "-1": "已拒绝",
    "0": "审核中",
    "1": "已提现"
  };
  return statusMap[status];
}

/**
 * @description 砍价状态
 */
function bargainStatusFilter(status) {
  var statusMap = {
    "1": "进行中",
    "2": "未完成",
    "3": "已成功"
  };
  return statusMap[status];
}

/**
 * @description 砍价状态
 */
function bargainColorFilter(status) {
  var statusMap = {
    "1": "",
    "2": "danger",
    "3": "success"
  };
  return statusMap[status];
}

/**
 * @description 拼团状态
 */
function groupStatusFilter(status) {
  var statusMap = {
    "1": "进行中",
    "2": "已成功",
    "3": "未完成"
  };
  return statusMap[status];
}

/**
 * @description 拼团状态
 */
function groupColorFilter(status) {
  var statusMap = {
    "1": "",
    "2": "success",
    "3": "danger"
  };
  return statusMap[status];
}

/**
 * @description 一号通tab值
 */
function onePassTypeFilter(status) {
  var statusMap = {
    sms: "短信",
    copy: "商品采集",
    expr_query: "物流查询",
    expr_dump: "电子面单打印"
  };
  return statusMap[status];
}
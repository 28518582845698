var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.infoLoading,
                  expression: "infoLoading",
                },
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-position": "left",
                "label-width": "120px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cont-block" },
                [
                  _c("div", { staticClass: "cont-t" }, [_vm._v("主Banner")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片配置：", prop: "name" } },
                    [
                      _c("div", { staticStyle: { color: "#999" } }, [
                        _vm._v(
                          "\n            （请上传Banner图，宽度为750px，高度不限，最多可上传6张图片）\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.ruleForm.bannerList,
                        function (rowItem, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "row-set" },
                            [
                              _c("div", { staticClass: "f-t" }, [
                                _vm._v("访问页面："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleSetUrl(
                                            "bannerList",
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          rowItem.jumpType > 0
                                            ? "已配置"
                                            : "路径配置"
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "f-t" }, [
                                _vm._v("图片："),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "upLoadPicBox",
                                    on: {
                                      click: function ($event) {
                                        return _vm.modalBannerPicTap(index)
                                      },
                                    },
                                  },
                                  [
                                    rowItem.pic
                                      ? _c("div", { staticClass: "pictrue" }, [
                                          _c("img", {
                                            attrs: { src: rowItem.pic },
                                          }),
                                        ])
                                      : _c("div", { staticClass: "upLoad" }, [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-camera cameraIconfont",
                                          }),
                                        ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "f-t" }, [
                                _vm._v("图片名称："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "",
                                        prop:
                                          "bannerList." + index + ".picName",
                                        rules: [
                                          {
                                            required: true,
                                            validator: _vm.validPicName,
                                            trigger: "blur",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "180px",
                                          "margin-right": "20px",
                                        },
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: rowItem.picName,
                                          callback: function ($$v) {
                                            _vm.$set(rowItem, "picName", $$v)
                                          },
                                          expression: "rowItem.picName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delBanner(index)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }
                      ),
                      _vm._v(" "),
                      !!_vm.bannerModel
                        ? _c("div", { staticClass: "row-set" }, [
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("访问页面："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSetUrl(
                                          "bannerList",
                                          -1
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.bannerModel.jumpType > 0
                                          ? "已配置"
                                          : "路径配置"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("图片："),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalBannerPicTap(-1)
                                    },
                                  },
                                },
                                [
                                  _vm.bannerModel.pic
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: { src: _vm.bannerModel.pic },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("图片名称："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "", prop: "picName" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "180px",
                                        "margin-right": "20px",
                                      },
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: _vm.bannerModel.picName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.bannerModel,
                                            "picName",
                                            $$v
                                          )
                                        },
                                        expression: "bannerModel.picName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.submitBannerRow },
                                  },
                                  [_vm._v("保存")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.bannerCancle },
                                  },
                                  [_vm._v("取消")]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm.ruleForm.bannerList &&
                          _vm.ruleForm.bannerList.length < 6
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", plain: "" },
                                  on: { click: _vm.addBanner },
                                },
                                [_vm._v("新增图片配置\n            ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cont-block" },
                [
                  _c("div", { staticClass: "cont-t" }, [_vm._v("活动区")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片配置：", prop: "active" } },
                    [
                      _c("span", { staticStyle: { color: "#999" } }, [
                        _vm._v(
                          "(请上传Offer Banner图，宽度为750px，高度不限，最多可上传6张图片)"
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.ruleForm.activeList,
                        function (rowItem, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "row-set" },
                            [
                              _c("div", { staticClass: "f-t" }, [
                                _vm._v("访问页面："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleSetUrl(
                                            "activeList",
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          rowItem.jumpType > 0
                                            ? "已配置"
                                            : "路径配置"
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "f-t" }, [
                                _vm._v("图片："),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "upLoadPicBox",
                                    on: {
                                      click: function ($event) {
                                        return _vm.modalActiveTap(index)
                                      },
                                    },
                                  },
                                  [
                                    rowItem.pic
                                      ? _c("div", { staticClass: "pictrue" }, [
                                          _c("img", {
                                            attrs: { src: rowItem.pic },
                                          }),
                                        ])
                                      : _c("div", { staticClass: "upLoad" }, [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-camera cameraIconfont",
                                          }),
                                        ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "f-t" }, [
                                _vm._v("图片名称："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "",
                                        prop:
                                          "activeList." + index + ".picName",
                                        rules: [
                                          {
                                            required: true,
                                            validator: _vm.validPicName,
                                            trigger: "blur",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "180px",
                                          "margin-right": "20px",
                                        },
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: rowItem.picName,
                                          callback: function ($$v) {
                                            _vm.$set(rowItem, "picName", $$v)
                                          },
                                          expression: "rowItem.picName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delActive(index)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }
                      ),
                      _vm._v(" "),
                      _vm.activeModelShow
                        ? _c("div", { staticClass: "row-set" }, [
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("访问页面："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSetUrl(
                                          "activeList",
                                          -1
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.activeModel.jumpType > 0
                                          ? "已配置"
                                          : "路径配置"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("图片："),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalActiveTap(-1)
                                    },
                                  },
                                },
                                [
                                  _vm.activeModel.pic
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: { src: _vm.activeModel.pic },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("图片名称："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "", prop: "picName" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "180px",
                                        "margin-right": "20px",
                                      },
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: _vm.activeModel.picName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.activeModel,
                                            "picName",
                                            $$v
                                          )
                                        },
                                        expression: "activeModel.picName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.submitActiveRow },
                                  },
                                  [_vm._v("保存")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.activeCancle },
                                  },
                                  [_vm._v("取消")]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm.ruleForm.activeList.length < 6
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", plain: "" },
                                  on: { click: _vm.addActive },
                                },
                                [_vm._v("新增图片配置\n            ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cont-block" },
                [
                  _c("div", { staticClass: "cont-t" }, [
                    _vm._v("活动区轮播区"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片配置：", prop: "firm" } },
                    [
                      _c("span", { staticStyle: { color: "#999" } }, [
                        _vm._v(
                          "(请上传Offer Banner图，宽度为750px，高度不限，最多可上传6张图片)"
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.ruleForm.rotationList,
                        function (rowItem, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "row-set" },
                            [
                              _c("div", { staticClass: "f-t" }, [
                                _vm._v("访问页面："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleSetUrl(
                                            "rotationList",
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          rowItem.jumpType > 0
                                            ? "已配置"
                                            : "路径配置"
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "f-t" }, [
                                _vm._v("图片："),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "upLoadPicBox",
                                    on: {
                                      click: function ($event) {
                                        return _vm.modalActiveSliderTap(index)
                                      },
                                    },
                                  },
                                  [
                                    rowItem.pic
                                      ? _c("div", { staticClass: "pictrue" }, [
                                          _c("img", {
                                            attrs: { src: rowItem.pic },
                                          }),
                                        ])
                                      : _c("div", { staticClass: "upLoad" }, [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-camera cameraIconfont",
                                          }),
                                        ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "f-t" }, [
                                _vm._v("图片名称："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "",
                                        prop:
                                          "rotationList." + index + ".picName",
                                        rules: [
                                          {
                                            required: true,
                                            validator: _vm.validPicName,
                                            trigger: "blur",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "180px",
                                          "margin-right": "20px",
                                        },
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: rowItem.picName,
                                          callback: function ($$v) {
                                            _vm.$set(rowItem, "picName", $$v)
                                          },
                                          expression: "rowItem.picName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delActiveSlider(index)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }
                      ),
                      _vm._v(" "),
                      _vm.activeSliderModelShow
                        ? _c("div", { staticClass: "row-set" }, [
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("访问页面："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSetUrl(
                                          "rotationList",
                                          -1
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.activeSliderModel.jumpType > 0
                                          ? "已配置"
                                          : "路径配置"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("图片："),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalActiveSliderTap(-1)
                                    },
                                  },
                                },
                                [
                                  _vm.activeSliderModel.pic
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.activeSliderModel.pic,
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("图片名称："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "", prop: "picName" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "180px",
                                        "margin-right": "20px",
                                      },
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: _vm.activeSliderModel.picName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.activeSliderModel,
                                            "picName",
                                            $$v
                                          )
                                        },
                                        expression: "activeSliderModel.picName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.submitActiveSliderRow },
                                  },
                                  [_vm._v("保存")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.activeSliderCancle },
                                  },
                                  [_vm._v("取消")]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm.ruleForm.rotationList.length < 6
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", plain: "" },
                                  on: { click: _vm.addActiveSlider },
                                },
                                [_vm._v("新增图片配置\n            ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cont-block" },
                [
                  _c("div", { staticClass: "cont-t" }, [_vm._v("Tab分区")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属分类：", prop: "name" } },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "hotTypeSel",
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.hotTypeSelVisual },
                          model: {
                            value: _vm.saleType,
                            callback: function ($$v) {
                              _vm.saleType = $$v
                            },
                            expression: "saleType",
                          },
                        },
                        _vm._l(_vm.selectList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tab-box" },
                    _vm._l(
                      _vm.ruleForm.pageTabList,
                      function (saleItem, index) {
                        return _c("div", { key: index }, [
                          _vm.saleType == saleItem.partitionId
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "tab-box-list" },
                                  [
                                    _c("div", { staticClass: "cont-t" }, [
                                      _vm._v("分类Banner"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "图片配置：",
                                          prop: "firm",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#999" } },
                                          [
                                            _vm._v(
                                              "(请上传Banner图，宽度为750px，高度不限，最多可上传6张图片)"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          saleItem.categoryBannerList,
                                          function (rowItem, bannerIndex) {
                                            return _c(
                                              "div",
                                              {
                                                key: bannerIndex,
                                                staticClass: "row-set",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "f-t" },
                                                  [_vm._v("访问页面：")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleSetUrl(
                                                              "categoryBannerList",
                                                              index,
                                                              bannerIndex
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            rowItem.jumpType > 0
                                                              ? "已配置"
                                                              : "路径配置"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "f-t" },
                                                  [_vm._v("图片：")]
                                                ),
                                                _vm._v(" "),
                                                _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "upLoadPicBox",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.modalTabBannerPicTap(
                                                            index,
                                                            bannerIndex
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      rowItem.pic
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pictrue",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: rowItem.pic,
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoad",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-camera cameraIconfont",
                                                              }),
                                                            ]
                                                          ),
                                                    ]
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "f-t" },
                                                  [_vm._v("图片名称：")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "",
                                                          prop:
                                                            "pageTabList." +
                                                            index +
                                                            ".categoryBannerList." +
                                                            bannerIndex +
                                                            ".picName",
                                                          rules: [
                                                            {
                                                              required: true,
                                                              validator:
                                                                _vm.validPicName,
                                                              trigger: "blur",
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "180px",
                                                            "margin-right":
                                                              "20px",
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              "请输入",
                                                          },
                                                          model: {
                                                            value:
                                                              rowItem.picName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                rowItem,
                                                                "picName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "rowItem.picName",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.delTabBabber(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("删除")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _vm.tabBannerModelShow
                                          ? _c(
                                              "div",
                                              { staticClass: "row-set" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "f-t" },
                                                  [_vm._v("访问页面：")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleSetUrl(
                                                              "categoryBannerList",
                                                              -1
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.tabBannerModel
                                                              .jumpType > 0
                                                              ? "已配置"
                                                              : "路径配置"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "f-t" },
                                                  [_vm._v("图片：")]
                                                ),
                                                _vm._v(" "),
                                                _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "upLoadPicBox",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.modalTabBannerPicTap(
                                                            -1,
                                                            -1
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.tabBannerModel.pic
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pictrue",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: _vm
                                                                    .tabBannerModel
                                                                    .pic,
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoad",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-camera cameraIconfont",
                                                              }),
                                                            ]
                                                          ),
                                                    ]
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "f-t" },
                                                  [_vm._v("图片名称：")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "",
                                                          prop: "picName",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "180px",
                                                            "margin-right":
                                                              "20px",
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              "请输入",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.tabBannerModel
                                                                .picName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.tabBannerModel,
                                                                "picName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "tabBannerModel.picName",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.submitTabBannerRow(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("保存")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click:
                                                            _vm.tabBannerCancle,
                                                        },
                                                      },
                                                      [_vm._v("取消")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          [
                                            saleItem.categoryBannerList.length <
                                            6
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      plain: "",
                                                    },
                                                    on: {
                                                      click: _vm.addTabBanner,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "新增图片配置\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "tab-box-list" },
                                  [
                                    _c("div", { staticClass: "cont-t" }, [
                                      _vm._v("产品区"),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      saleItem.productAreaList,
                                      function (proItem, proIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: proIndex,
                                            staticClass: "pro-list",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "产品分区标题配置：",
                                                  prop: "firm",
                                                  "label-width": "140px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#999",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "(请上传分类Banner图，宽度为750)"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  _vm.getShowItems(
                                                    proItem.tabItemList,
                                                    2
                                                  ),
                                                  function (
                                                    rowItem,
                                                    titleIndex
                                                  ) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: titleIndex,
                                                        staticClass: "row-set",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("访问页面：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleSetUrl(
                                                                        "productAreaList",
                                                                        index,
                                                                        proIndex,
                                                                        2,
                                                                        titleIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    rowItem.jumpType >
                                                                      0
                                                                      ? "已配置"
                                                                      : "路径配置"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoadPicBox",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.modalProTitlePicTap(
                                                                      index,
                                                                      proIndex
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              rowItem.pic
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pictrue",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: rowItem.pic,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "upLoad",
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "el-icon-camera cameraIconfont",
                                                                      }),
                                                                    ]
                                                                  ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片名称：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  label: "",
                                                                  prop: "picName",
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "180px",
                                                                    "margin-right":
                                                                      "20px",
                                                                  },
                                                                  attrs: {
                                                                    placeholder:
                                                                      "请输入",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      rowItem.picName,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          rowItem,
                                                                          "picName",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "rowItem.picName",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.delProTitle(
                                                                        index,
                                                                        proIndex,
                                                                        titleIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("删除")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _vm.productTitleModelShow &&
                                                _vm.getShowItems(
                                                  proItem.tabItemList,
                                                  2
                                                ).length == 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "row-set",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("访问页面：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleSetUrl(
                                                                        "productAreaList",
                                                                        -1,
                                                                        0,
                                                                        2
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .productTitleModel
                                                                      .jumpType >
                                                                      0
                                                                      ? "已配置"
                                                                      : "路径配置"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoadPicBox",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.modalProTitlePicTap(
                                                                      -1
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm
                                                                .productTitleModel
                                                                .pic
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pictrue",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: _vm
                                                                                .productTitleModel
                                                                                .pic,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "upLoad",
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "el-icon-camera cameraIconfont",
                                                                      }),
                                                                    ]
                                                                  ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片名称：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  label: "",
                                                                  prop: "picName",
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "180px",
                                                                    "margin-right":
                                                                      "20px",
                                                                  },
                                                                  attrs: {
                                                                    placeholder:
                                                                      "请输入",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .productTitleModel
                                                                        .picName,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.productTitleModel,
                                                                          "picName",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "productTitleModel.picName",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.submitProTitleRow(
                                                                        index,
                                                                        proIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("保存")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.proTitleCancle,
                                                                },
                                                              },
                                                              [_vm._v("取消")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "产品图片配置：",
                                                  prop: "firm",
                                                  "label-width": "140px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#999",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "(请上传产品图，宽度为750px，高度不限，最多可以上传12张图片)"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  _vm.getShowItems(
                                                    proItem.tabItemList,
                                                    1
                                                  ),
                                                  function (
                                                    rowItem,
                                                    imageIndex
                                                  ) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: imageIndex,
                                                        staticClass: "row-set",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("访问页面：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleSetUrl(
                                                                        "productAreaList",
                                                                        index,
                                                                        proIndex,
                                                                        1,
                                                                        imageIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    rowItem.jumpType >
                                                                      0
                                                                      ? "已配置"
                                                                      : "路径配置"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoadPicBox",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.modalProPicTap(
                                                                      index,
                                                                      proIndex,
                                                                      imageIndex
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              rowItem.pic
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pictrue",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: rowItem.pic,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "upLoad",
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "el-icon-camera cameraIconfont",
                                                                      }),
                                                                    ]
                                                                  ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片名称：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  label: "",
                                                                  prop:
                                                                    "pageTabList." +
                                                                    index +
                                                                    ".productAreaList." +
                                                                    proIndex +
                                                                    ".tabItemList." +
                                                                    imageIndex +
                                                                    ".picName",
                                                                  rules: [
                                                                    {
                                                                      required: true,
                                                                      validator:
                                                                        _vm.validPicName,
                                                                      trigger:
                                                                        "blur",
                                                                    },
                                                                  ],
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "180px",
                                                                    "margin-right":
                                                                      "20px",
                                                                  },
                                                                  attrs: {
                                                                    placeholder:
                                                                      "请输入",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      rowItem.picName,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          rowItem,
                                                                          "picName",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "rowItem.picName",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.delProduct(
                                                                        index,
                                                                        proIndex,
                                                                        imageIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("删除")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                proItem.showModel
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "row-set",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("访问页面：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleSetUrl(
                                                                        "productAreaList",
                                                                        -1,
                                                                        proIndex,
                                                                        1
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .productModel
                                                                      .jumpType >
                                                                      0
                                                                      ? "已配置"
                                                                      : "路径配置"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoadPicBox",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.modalProPicTap(
                                                                      -1,
                                                                      proIndex
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm.productModel
                                                                .pic
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pictrue",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: _vm
                                                                                .productModel
                                                                                .pic,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "upLoad",
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "el-icon-camera cameraIconfont",
                                                                      }),
                                                                    ]
                                                                  ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片名称：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  label: "",
                                                                  prop: "picName",
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "180px",
                                                                    "margin-right":
                                                                      "20px",
                                                                  },
                                                                  attrs: {
                                                                    placeholder:
                                                                      "请输入",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .productModel
                                                                        .picName,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.productModel,
                                                                          "picName",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "productModel.picName",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.submitProductRow(
                                                                        index,
                                                                        proIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("保存")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.productCancle(
                                                                        index,
                                                                        proIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("取消")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  [
                                                    proItem.tabItemList.length <
                                                    13
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                              plain: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addProduct(
                                                                  index,
                                                                  proIndex
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "新增一个产品\n                      "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "padding-bottom": "20px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addProArea(index)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "新增一个产品分类\n                  "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        saleItem.productAreaList.length > 0
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delProArea(index)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "删除一个产品分类\n                  "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "tab-box-list" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "cont-t" },
                                      [
                                        _vm._v("解决方案\n                  "),
                                        _c("el-switch", {
                                          attrs: {
                                            "active-color": "#409EFF",
                                            "inactive-color": "#808080",
                                            "active-value": 1,
                                            "inactive-value": 0,
                                            "active-text": "开启",
                                            "inactive-text": "关闭",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.solutionChange(
                                                $event,
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              saleItem.solutionParam
                                                .enableStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                saleItem.solutionParam,
                                                "enableStatus",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "saleItem.solutionParam.enableStatus",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    saleItem.solutionParam.enableStatus == 1
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "解决方案标题配置：",
                                              prop: "firm",
                                              "label-width": "140px",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { color: "#999" },
                                              },
                                              [
                                                _vm._v(
                                                  "(请上传分类Banner图，宽度为750)"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.getShowItems(
                                                saleItem.solutionParam
                                                  .tabItemList,
                                                2
                                              ),
                                              function (rowItem, titleIndex) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: titleIndex,
                                                    staticClass: "row-set",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("访问页面：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleSetUrl(
                                                                  "solutionParam",
                                                                  index,
                                                                  0,
                                                                  2,
                                                                  titleIndex
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                rowItem.jumpType >
                                                                  0
                                                                  ? "已配置"
                                                                  : "路径配置"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoadPicBox",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.modalSolTitlePicTap(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          rowItem.pic
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pictrue",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: rowItem.pic,
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "upLoad",
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-camera cameraIconfont",
                                                                  }),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片名称：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "",
                                                              prop: "picName",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "180px",
                                                                "margin-right":
                                                                  "20px",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入",
                                                              },
                                                              model: {
                                                                value:
                                                                  rowItem.picName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      rowItem,
                                                                      "picName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "rowItem.picName",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.delSolTitle(
                                                                  index,
                                                                  0
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("删除")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            _vm._v(" "),
                                            _vm.solutionTitleModelShow &&
                                            _vm.getShowItems(
                                              saleItem.solutionParam
                                                .tabItemList,
                                              2
                                            ).length == 0
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row-set" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("访问页面：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleSetUrl(
                                                                  "solutionParam",
                                                                  -1,
                                                                  0,
                                                                  2
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .solutionTitleModel
                                                                  .jumpType > 0
                                                                  ? "已配置"
                                                                  : "路径配置"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoadPicBox",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.modalSolTitlePicTap(
                                                                -1
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm.solutionTitleModel
                                                            .pic
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pictrue",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: _vm
                                                                        .solutionTitleModel
                                                                        .pic,
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "upLoad",
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-camera cameraIconfont",
                                                                  }),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片名称：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "",
                                                              prop: "picName",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "180px",
                                                                "margin-right":
                                                                  "20px",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .solutionTitleModel
                                                                    .picName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.solutionTitleModel,
                                                                      "picName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "solutionTitleModel.picName",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.submitSolTitleRow(
                                                                  index,
                                                                  "solutionParam"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("保存")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.solTitleCancle,
                                                            },
                                                          },
                                                          [_vm._v("取消")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    saleItem.solutionParam.enableStatus == 1
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "解决方案入口配置：",
                                              prop: "firm",
                                              "label-width": "140px",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { color: "#999" },
                                              },
                                              [
                                                _vm._v(
                                                  "(请上传产品图，宽度为***px，高度不限，最多可以上传6张图片)"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.getShowItems(
                                                saleItem.solutionParam
                                                  .tabItemList,
                                                1
                                              ),
                                              function (rowItem, imageIndex) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: imageIndex,
                                                    staticClass: "row-set",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("访问页面：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleSetUrl(
                                                                  "solutionParam",
                                                                  index,
                                                                  0,
                                                                  1,
                                                                  imageIndex
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                rowItem.jumpType >
                                                                  0
                                                                  ? "已配置"
                                                                  : "路径配置"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoadPicBox",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.modalSolPicTap(
                                                                index,
                                                                imageIndex
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          rowItem.pic
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pictrue",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: rowItem.pic,
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "upLoad",
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-camera cameraIconfont",
                                                                  }),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片名称：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "",
                                                              prop:
                                                                "pageTabList." +
                                                                index +
                                                                ".solutionParam.tabItemList." +
                                                                imageIndex +
                                                                ".picName",
                                                              rules: [
                                                                {
                                                                  required: true,
                                                                  validator:
                                                                    _vm.validPicName,
                                                                  trigger:
                                                                    "blur",
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "180px",
                                                                "margin-right":
                                                                  "20px",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入",
                                                              },
                                                              model: {
                                                                value:
                                                                  rowItem.picName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      rowItem,
                                                                      "picName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "rowItem.picName",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.delSolution(
                                                                  index,
                                                                  imageIndex
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("删除")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            _vm._v(" "),
                                            _vm.solutionModelShow
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row-set" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("访问页面：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleSetUrl(
                                                                  "solutionParam",
                                                                  -1,
                                                                  0
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .solutionModel
                                                                  .jumpType > 0
                                                                  ? "已配置"
                                                                  : "路径配置"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoadPicBox",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.modalSolPicTap(
                                                                -1
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm.solutionModel.pic
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pictrue",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: _vm
                                                                        .solutionModel
                                                                        .pic,
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "upLoad",
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-camera cameraIconfont",
                                                                  }),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片名称：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "",
                                                              prop: "picName",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "180px",
                                                                "margin-right":
                                                                  "20px",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .solutionModel
                                                                    .picName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.solutionModel,
                                                                      "picName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "solutionModel.picName",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.submitSolutionRow(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("保存")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.solutionCancle,
                                                            },
                                                          },
                                                          [_vm._v("取消")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              [
                                                saleItem.solutionParam
                                                  .tabItemList.length < 7
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                          plain: "",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.addSolution,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "新增图片配置\n                      "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "tab-box-list" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "cont-t" },
                                      [
                                        _vm._v("实战案例\n                  "),
                                        _c("el-switch", {
                                          attrs: {
                                            "active-color": "#409EFF",
                                            "inactive-color": "#808080",
                                            "active-value": 1,
                                            "inactive-value": 0,
                                            "active-text": "开启",
                                            "inactive-text": "关闭",
                                          },
                                          model: {
                                            value:
                                              saleItem.casesParam.enableStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                saleItem.casesParam,
                                                "enableStatus",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "saleItem.casesParam.enableStatus",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    saleItem.casesParam.enableStatus == 1
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "实战案例标题配置：",
                                              prop: "firm",
                                              "label-width": "140px",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { color: "#999" },
                                              },
                                              [
                                                _vm._v(
                                                  "(请上传分类Banner图，宽度为750)"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.getShowItems(
                                                saleItem.casesParam.tabItemList,
                                                2
                                              ),
                                              function (rowItem, titleIndex) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: titleIndex,
                                                    staticClass: "row-set",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("访问页面：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleSetUrl(
                                                                  "casesParam",
                                                                  index,
                                                                  0,
                                                                  2,
                                                                  titleIndex
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                rowItem.jumpType >
                                                                  0
                                                                  ? "已配置"
                                                                  : "路径配置"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoadPicBox",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.modalCaselTitlePicTap(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          rowItem.pic
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pictrue",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: rowItem.pic,
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "upLoad",
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-camera cameraIconfont",
                                                                  }),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片名称：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "",
                                                              prop: "picName",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "180px",
                                                                "margin-right":
                                                                  "20px",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入",
                                                              },
                                                              model: {
                                                                value:
                                                                  rowItem.picName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      rowItem,
                                                                      "picName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "rowItem.picName",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.delCaseTitle(
                                                                  index,
                                                                  0
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("删除")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            _vm._v(" "),
                                            _vm.caseTitleModelShow &&
                                            _vm.getShowItems(
                                              saleItem.casesParam.tabItemList,
                                              2
                                            ).length == 0
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row-set" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("访问页面：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleSetUrl(
                                                                  "casesParam",
                                                                  -1,
                                                                  0,
                                                                  2
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .caseTitleModel
                                                                  .jumpType > 0
                                                                  ? "已配置"
                                                                  : "路径配置"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoadPicBox",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.modalCaselTitlePicTap(
                                                                -1
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm.caseTitleModel.pic
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pictrue",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: _vm
                                                                        .caseTitleModel
                                                                        .pic,
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "upLoad",
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-camera cameraIconfont",
                                                                  }),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片名称：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "",
                                                              prop: "picName",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "180px",
                                                                "margin-right":
                                                                  "20px",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .caseTitleModel
                                                                    .picName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.caseTitleModel,
                                                                      "picName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "caseTitleModel.picName",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.submitCaseTitleRow(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("保存")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.caseTitleCancle,
                                                            },
                                                          },
                                                          [_vm._v("取消")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    saleItem.casesParam.enableStatus == 1
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "实战案例入口配置：",
                                              prop: "firm",
                                              "label-width": "140px",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { color: "#999" },
                                              },
                                              [
                                                _vm._v(
                                                  "(请上传产品图，宽度为***px，高度不限，最多可以上传6张图片)"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.getShowItems(
                                                saleItem.casesParam.tabItemList,
                                                1
                                              ),
                                              function (rowItem, imageIndex) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: imageIndex,
                                                    staticClass: "row-set",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("访问页面：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleSetUrl(
                                                                  "casesParam",
                                                                  index,
                                                                  0,
                                                                  1,
                                                                  imageIndex
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                rowItem.jumpType >
                                                                  0
                                                                  ? "已配置"
                                                                  : "路径配置"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoadPicBox",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.modalCasePicTap(
                                                                index,
                                                                imageIndex
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          rowItem.pic
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pictrue",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: rowItem.pic,
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "upLoad",
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-camera cameraIconfont",
                                                                  }),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片名称：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "",
                                                              prop:
                                                                "pageTabList." +
                                                                index +
                                                                ".casesParam.tabItemList." +
                                                                imageIndex +
                                                                ".picName",
                                                              rules: [
                                                                {
                                                                  required: true,
                                                                  validator:
                                                                    _vm.validPicName,
                                                                  trigger:
                                                                    "blur",
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "180px",
                                                                "margin-right":
                                                                  "20px",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入",
                                                              },
                                                              model: {
                                                                value:
                                                                  rowItem.picName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      rowItem,
                                                                      "picName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "rowItem.picName",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.delCase(
                                                                  index,
                                                                  imageIndex
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("删除")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            _vm._v(" "),
                                            _vm.caseModelShow
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row-set" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("访问页面：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleSetUrl(
                                                                  "casesParam",
                                                                  -1,
                                                                  0
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.caseModel
                                                                  .jumpType > 0
                                                                  ? "已配置"
                                                                  : "路径配置"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoadPicBox",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.modalCasePicTap(
                                                                -1
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm.caseModel.pic
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pictrue",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: _vm
                                                                        .caseModel
                                                                        .pic,
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "upLoad",
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-camera cameraIconfont",
                                                                  }),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "f-t" },
                                                      [_vm._v("图片名称：")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "",
                                                              prop: "picName",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "180px",
                                                                "margin-right":
                                                                  "20px",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.caseModel
                                                                    .picName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.caseModel,
                                                                      "picName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "caseModel.picName",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.submitCaseRow(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("保存")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.caseCancle,
                                                            },
                                                          },
                                                          [_vm._v("取消")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              [
                                                saleItem.casesParam.tabItemList
                                                  .length < 7
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                          plain: "",
                                                        },
                                                        on: {
                                                          click: _vm.addCase,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "新增图片配置\n                      "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "tab-box-list" },
                                  [
                                    _c("div", { staticClass: "cont-t" }, [
                                      _vm._v("内容模块"),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      saleItem.contentModuleList,
                                      function (contentItem, conIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: conIndex,
                                            staticClass: "pro-list",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "模块标题配置：",
                                                  prop: "firm",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#999",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "(请上传分类Banner图，宽度为750)"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  _vm.getShowItems(
                                                    contentItem.tabItemList,
                                                    2
                                                  ),
                                                  function (
                                                    rowItem,
                                                    titleIndex
                                                  ) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: titleIndex,
                                                        staticClass: "row-set",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("访问页面：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleSetUrl(
                                                                        "contentModuleList",
                                                                        index,
                                                                        conIndex,
                                                                        2,
                                                                        titleIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    rowItem.jumpType >
                                                                      0
                                                                      ? "已配置"
                                                                      : "路径配置"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoadPicBox",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.modalConTitlePicTap(
                                                                      index,
                                                                      conIndex
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              rowItem.pic
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pictrue",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: rowItem.pic,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "upLoad",
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "el-icon-camera cameraIconfont",
                                                                      }),
                                                                    ]
                                                                  ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片名称：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  label: "",
                                                                  prop: "picName",
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "180px",
                                                                    "margin-right":
                                                                      "20px",
                                                                  },
                                                                  attrs: {
                                                                    placeholder:
                                                                      "请输入",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      rowItem.picName,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          rowItem,
                                                                          "picName",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "rowItem.picName",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.delConTitle(
                                                                        index,
                                                                        conIndex,
                                                                        titleIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("删除")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _vm.contentTitleModelShow &&
                                                _vm.getShowItems(
                                                  contentItem.tabItemList,
                                                  2
                                                ).length == 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "row-set",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("访问页面：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleSetUrl(
                                                                        "contentModuleList",
                                                                        -1,
                                                                        0,
                                                                        2
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .contentTitleModel
                                                                      .jumpType >
                                                                      0
                                                                      ? "已配置"
                                                                      : "路径配置"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoadPicBox",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.modalConTitlePicTap(
                                                                      -1
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm
                                                                .contentTitleModel
                                                                .pic
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pictrue",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: _vm
                                                                                .contentTitleModel
                                                                                .pic,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "upLoad",
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "el-icon-camera cameraIconfont",
                                                                      }),
                                                                    ]
                                                                  ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片名称：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  label: "",
                                                                  prop: "picName",
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "180px",
                                                                    "margin-right":
                                                                      "20px",
                                                                  },
                                                                  attrs: {
                                                                    placeholder:
                                                                      "请输入",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .contentTitleModel
                                                                        .picName,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.contentTitleModel,
                                                                          "picName",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "contentTitleModel.picName",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.submitConTitleRow(
                                                                        index,
                                                                        conIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("保存")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.conTitleCancle,
                                                                },
                                                              },
                                                              [_vm._v("取消")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "内容封面配置：",
                                                  prop: "firm",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#999",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "(请上传产品图，宽度为750px，高度不限，最多可以上传12张图片)"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  _vm.getShowItems(
                                                    contentItem.tabItemList,
                                                    1
                                                  ),
                                                  function (
                                                    rowItem,
                                                    imageIndex
                                                  ) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: imageIndex,
                                                        staticClass: "row-set",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("访问页面：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleSetUrl(
                                                                        "contentModuleList",
                                                                        index,
                                                                        conIndex,
                                                                        1,
                                                                        imageIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    rowItem.jumpType >
                                                                      0
                                                                      ? "已配置"
                                                                      : "路径配置"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoadPicBox",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.modalConPicTap(
                                                                      index,
                                                                      conIndex,
                                                                      imageIndex
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              rowItem.pic
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pictrue",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: rowItem.pic,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "upLoad",
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "el-icon-camera cameraIconfont",
                                                                      }),
                                                                    ]
                                                                  ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片名称：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  label: "",
                                                                  prop:
                                                                    "pageTabList." +
                                                                    index +
                                                                    ".contentModuleList." +
                                                                    conIndex +
                                                                    ".tabItemList." +
                                                                    imageIndex +
                                                                    ".picName",
                                                                  rules: [
                                                                    {
                                                                      required: true,
                                                                      validator:
                                                                        _vm.validPicName,
                                                                      trigger:
                                                                        "blur",
                                                                    },
                                                                  ],
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "180px",
                                                                    "margin-right":
                                                                      "20px",
                                                                  },
                                                                  attrs: {
                                                                    placeholder:
                                                                      "请输入",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      rowItem.picName,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          rowItem,
                                                                          "picName",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "rowItem.picName",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.delContent(
                                                                        index,
                                                                        conIndex,
                                                                        imageIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("删除")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                contentItem.showModel
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "row-set",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("访问页面：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleSetUrl(
                                                                        "contentModuleList",
                                                                        -1,
                                                                        conIndex,
                                                                        1
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .contentModel
                                                                      .jumpType >
                                                                      0
                                                                      ? "已配置"
                                                                      : "路径配置"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoadPicBox",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.modalConPicTap(
                                                                      -1
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm.contentModel
                                                                .pic
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pictrue",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: _vm
                                                                                .contentModel
                                                                                .pic,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "upLoad",
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "el-icon-camera cameraIconfont",
                                                                      }),
                                                                    ]
                                                                  ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "f-t",
                                                          },
                                                          [_vm._v("图片名称：")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  label: "",
                                                                  prop: "picName",
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "180px",
                                                                    "margin-right":
                                                                      "20px",
                                                                  },
                                                                  attrs: {
                                                                    placeholder:
                                                                      "请输入",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .contentModel
                                                                        .picName,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.contentModel,
                                                                          "picName",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "contentModel.picName",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.submitContentRow(
                                                                        index,
                                                                        conIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("保存")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.contentCancle(
                                                                        index,
                                                                        conIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("取消")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  [
                                                    contentItem.tabItemList
                                                      .length < 13
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                              plain: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addContent(
                                                                  index,
                                                                  conIndex
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "新增一个内容"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "padding-bottom": "20px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addContentArea(index)
                                              },
                                            },
                                          },
                                          [_vm._v("新增一个内容模块")]
                                        ),
                                        _vm._v(" "),
                                        saleItem.contentModuleList.length > 0
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delContentArea(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除一个内容模块")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ])
                            : _vm._e(),
                        ])
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { "label-width": "0" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSubmit },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("page-link", {
        ref: "pageLinkSet",
        attrs: { visible: _vm.pageLinkShow, editData: _vm.pageLinkData },
        on: {
          "update:visible": function ($event) {
            _vm.pageLinkShow = $event
          },
          cancle: _vm.cancleSetUrl,
          save: _vm.updateUrlSet,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
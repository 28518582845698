"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _financial = require("@/api/financial");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'AccountsCapital',
  data: function data() {
    return {
      timeVal: [],
      tableData: {
        data: [],
        total: 0
      },
      userDetailData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        min: '',
        max: '',
        keywords: '',
        page: 1,
        limit: 20
      },
      userTableFrom: {
        page: 1,
        limit: 10,
        dateLimit: ''
      },
      fromList: this.$constants.fromList,
      options: [],
      Visible: false,
      tablistLoading: false,
      userDetail: {},
      timeValUser: []
    };
  },
  mounted: function mounted() {
    // this.getTypes()
    this.getList();
  },
  methods: {
    Close: function Close() {
      this.Visible = false;
    },
    handlerOpenInfo: function handlerOpenInfo(row) {
      this.userDetail = row;
      this.Visible = true;
      this.tablistLoading = true;
      this.userTableFrom.limit = 10;
      this.getUserList();
    },
    getUserList: function getUserList(num) {
      var _this = this;
      this.userTableFrom.page = num ? num : this.userTableFrom.page;
      (0, _financial.monitorListDetailApi)(this.userDetail.uid, this.userTableFrom).then(function (res) {
        _this.userDetailData.data = res.list;
        _this.userDetailData.total = res.total;
        _this.tablistLoading = false;
      }).catch(function (res) {
        _this.tablistLoading = false;
      });
    },
    selectChangeUser: function selectChangeUser(tab) {
      this.userTableFrom.dateLimit = tab;
      this.timeValUser = [];
      this.userTableFrom.page = 1;
      this.getUserList();
    },
    userPageChange: function userPageChange(page) {
      this.userTableFrom.page = page;
      this.getUserList();
    },
    userHandleSizeChange: function userHandleSizeChange(val) {
      this.userTableFrom.limit = val;
      this.getUserList();
    },
    // 具体日期
    onchangeTimeUser: function onchangeTimeUser(e) {
      this.timeValUser = e;
      this.userTableFrom.dateLimit = e ? this.timeValUser.join(',') : '';
      this.userTableFrom.page = 1;
      this.getUserList();
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _financial.brokerageListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    getTypes: function getTypes() {
      var _this3 = this;
      billTypeApi().then(function (res) {
        _this3.options = res.data;
        localStorage.setItem('CashKey', JSON.stringify(res.data));
      }).catch(function (res) {
        _this3.$message.error(res.message);
      });
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "分类管理", name: "first" } },
                [
                  _c("category-list", {
                    attrs: { biztype: _vm.constants.categoryType[4] },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "内容管理", name: "second" } },
                [_c("contentManage")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.infoLoading,
                  expression: "infoLoading",
                },
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "园区生态分类：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.cid,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "cid", $$v)
                        },
                        expression: "ruleForm.cid",
                      },
                    },
                    _vm._l(_vm.cateList, function (option, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          disabled: option.disabled,
                          label: option.name,
                          value: option.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cont-block" },
                [
                  _c("el-form-item", { attrs: { label: "banner图片配置：" } }, [
                    _c("span", { staticStyle: { color: "#999" } }, [
                      _vm._v("(推荐尺寸为：500px X 750px)"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row-set" }, [
                      _c("div", { staticClass: "f-t" }, [_vm._v("访问页面：")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSetUrl },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.ruleForm.jumpType > 0
                                    ? "已配置"
                                    : "路径配置"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "f-t" }, [_vm._v("图片名称：")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "",
                                prop: "picName",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入",
                                    trigger: "blur",
                                  },
                                  {
                                    message: "最多输入15个字符",
                                    max: 15,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "200px",
                                  "margin-right": "20px",
                                },
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.ruleForm.picName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "picName", $$v)
                                  },
                                  expression: "ruleForm.picName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "f-t" }, [_vm._v("图片：")]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            on: { click: _vm.modalFirmPicTap },
                          },
                          [
                            _vm.ruleForm.pic
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: { src: _vm.ruleForm.pic },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "内容编辑：" } },
                [
                  _c("ueditor-from", {
                    attrs: { content: _vm.ruleForm.content },
                    model: {
                      value: _vm.ruleForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "content", $$v)
                      },
                      expression: "ruleForm.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSubmit },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("page-link", {
        ref: "pageLinkSet",
        attrs: { visible: _vm.pageLinkShow, editData: _vm.pageLinkData },
        on: {
          "update:visible": function ($event) {
            _vm.pageLinkShow = $event
          },
          cancle: _vm.cancleSetUrl,
          save: _vm.updateUrlSet,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
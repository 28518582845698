"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    visible: {
      type: Boolean
    },
    editData: {
      type: Object
    },
    viewFlag: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      formModel: {
        appId: "",
        jumpPath: "",
        jumpStatus: false,
        // 跳转是否开启（false：不开启 true：开启）
        jumpType: 0,
        // 1：内部小程序页面 2：外部小程序 3：外部链接
        isJumpLoc: false,
        pageLoc: "",
        locName: "",
        keyWord: ""
      },
      locPages: [{
        url: "/pages/goods_result/index",
        label: "搜索结果页",
        child: [{
          label: "相关内容"
        }, {
          label: "相关商品"
        }, {
          label: "线上沙龙"
        }, {
          label: "解决方案"
        }]
      }],
      locList: []
    };
  },
  mounted: function mounted() {},
  computed: {
    pathGenerate: function pathGenerate() {
      if (this.formModel.jumpType == "1" && this.formModel.isJumpLoc && this.formModel.pageLoc) {
        var params = [];
        if (this.formModel.locName) {
          params.push("tabName=".concat(this.formModel.locName));
        }
        if (this.formModel.keyWord) {
          params.push("searchValue=".concat(this.formModel.keyWord));
        }
        var paramStr = params.join("&");
        return paramStr ? "".concat(this.formModel.pageLoc, "?").concat(paramStr) : this.formModel.pageLoc;
      } else {
        return "";
      }
    }
  },
  watch: {
    "formModel.pageLoc": function formModelPageLoc(val) {
      if (val) {
        var pageCu = this.locPages.find(function (page) {
          return page.url == val;
        });
        this.locList = pageCu.child;
      } else {
        this.locList = [];
      }
    }
  },
  methods: {
    changeType: function changeType(type) {
      var _this = this;
      this.reset();
      this.formModel.jumpType = type;
      this.$nextTick(function () {
        _this.$refs["editPram"].clearValidate();
      });
    },
    changeLocType: function changeLocType() {},
    initEditData: function initEditData() {
      var _this2 = this;
      if (this.editData.jumpType > 0) {
        this.formModel.jumpType = this.editData.jumpType;
        this.formModel.appId = this.editData.appId;
        this.formModel.jumpPath = this.editData.jumpPath;
        this.formModel.jumpStatus = this.editData.jumpStatus;
      }
      if (this.editData.jumpType == 1) {
        if (this.formModel.jumpPath.indexOf("tabName") > -1) {
          //定位
          this.formModel.isJumpLoc = true;
          this.formModel.pageLoc = this.formModel.jumpPath.split("?")[0];
          var paramPairs = this.formModel.jumpPath.split("?")[1].split("&");
          paramPairs.forEach(function (paramStr) {
            var param = paramStr.split("=");
            if (param[0] == "tabName") {
              _this2.formModel.locName = param[1];
            }
            if (param[0] == "searchValue") {
              _this2.formModel.keyWord = param[1];
            }
          });
        } else {
          this.formModel.isJumpLoc = false;
        }
      }
      this.$refs["editPram"].clearValidate();
    },
    submit: function submit() {
      var _this3 = this;
      if (this.formModel.jumpType == 0) {
        this.$message.warning("请选择跳转类型");
        return false;
      }
      this.$refs["editPram"].validate(function (valid) {
        if (valid) {
          var paramForm = Object.assign({}, _this3.formModel);
          if (_this3.formModel.jumpType == "1" && _this3.formModel.isJumpLoc) {
            var params = [];
            if (_this3.formModel.locName) {
              params.push("tabName=".concat(_this3.formModel.locName));
            }
            if (_this3.formModel.keyWord) {
              params.push("searchValue=".concat(_this3.formModel.keyWord));
            }
            var paramStr = params.join("&");
            paramForm.jumpPath = "".concat(_this3.formModel.pageLoc, "?").concat(paramStr);
          }
          var appId = paramForm.appId,
            jumpPath = paramForm.jumpPath,
            jumpStatus = paramForm.jumpStatus,
            jumpType = paramForm.jumpType;
          _this3.$emit("save", {
            appId: appId,
            jumpPath: jumpPath,
            jumpStatus: jumpStatus,
            jumpType: jumpType,
            keyName: _this3.editData.keyName,
            keyIndex: _this3.editData.keyIndex
          });
          _this3.cancle();
        }
      });
    },
    cancle: function cancle() {
      this.$emit("update:visible", false);
      this.$emit("cancle");
      this.reset();
    },
    reset: function reset() {
      this.formModel = {
        appId: "",
        jumpPath: "",
        jumpStatus: false,
        jumpType: 0,
        isJumpLoc: false,
        pageLoc: "",
        locName: "",
        keyWord: ""
      };
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dayjs = _interopRequireDefault(require("dayjs"));
var _live = require("@/api/live");
var _store = require("@/api/store");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      constants: this.$constants,
      listPram: {
        keyword: null,
        page: 1,
        limit: this.$constants.page.limit[0],
        liveCategoryId: ""
      },
      listData: {
        list: [],
        total: 0
      },
      merCateList: [],
      props2: {
        children: "child",
        label: "name",
        value: "id",
        multiple: false,
        emitPath: false
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    var p1 = this.getCategorySelect();
    Promise.all([p1]).then(function (res) {
      _this.handerSearch();
    });
  },
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
  },
  methods: {
    // 同步直播数据
    synchronizationData: function synchronizationData(rows) {
      var _this2 = this;
      (0, _live.synchronizationDataApi)({
        webinarId: rows.vhallLiveId
      }).then(function (res) {
        _this2.handerSearch();
      });
    },
    getCategorySelect: function getCategorySelect() {
      var _this3 = this;
      (0, _store.categoryApi)({
        status: -1,
        type: 9
      }).then(function (res) {
        _this3.merCateList = _this3.filerMerCateList(res);
      });
    },
    filerMerCateList: function filerMerCateList(treeData) {
      // debugger

      return treeData.map(function (item) {
        if (!item.child) {
          item.disabled = true;
        }
        item.label = item.name;
        return item;
      });
    },
    handerSearch: function handerSearch() {
      this.listPram.page = 1;
      this.handlerGetListData(this.listPram);
    },
    handlerGetListData: function handlerGetListData(prams) {
      var _this4 = this;
      (0, _live.LiveList)(prams).then(function (res) {
        res.list.forEach(function (item) {
          if (item.liveCategoryName) return item.liveCategoryName = item.liveCategoryName.split(",");
        });
        _this4.listData = res;
      });
    },
    handlerDelete: function handlerDelete(row) {
      var _this5 = this;
      this.$confirm("确定删除当前数据", "提示").then(function (result) {
        (0, _live.deleteLive)({
          id: [row.id]
        }).then(function (res) {
          // console.log(res)
          _this5.$message.success("删除成功");
          _this5.handlerGetListData(_this5.listPram);
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetListData(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetListData(this.listPram);
    }
  }
};
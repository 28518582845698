var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.formModel.id ? "编辑楼层" : "新增楼层",
            visible: _vm.visible,
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "1000px",
          },
          on: { close: _vm.cancle },
        },
        [
          _c(
            "el-form",
            {
              ref: "editPram",
              attrs: {
                model: _vm.formModel,
                "label-width": "130px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "模板选择",
                    prop: "template",
                    rules: [
                      {
                        required: true,
                        message: "请选择",
                        trigger: ["change"],
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.changeModule },
                      model: {
                        value: _vm.formModel.template,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModel, "template", $$v)
                        },
                        expression: "formModel.template",
                      },
                    },
                    _vm._l(_vm.templateList, function (page, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: page.label, value: page.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formModel.template
                ? _c(
                    "div",
                    { staticClass: "set-block" },
                    [
                      _vm.formModel.template != "6"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "样式展示", prop: "sample" } },
                            [
                              _c("el-image", {
                                staticStyle: { width: "auto", height: "50px" },
                                attrs: {
                                  src: _vm.cuModule.sampleImage,
                                  "preview-src-list": [
                                    _vm.cuModule.sampleImage,
                                  ],
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formModel.template == "3"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "模块标题",
                                prop: "templateTitle",
                                rules: [
                                  {
                                    required: true,
                                    validator: _vm.validCommonName,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: { placeholder: "模块标题" },
                                model: {
                                  value: _vm.formModel.templateTitle,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      "templateTitle",
                                      $$v
                                    )
                                  },
                                  expression: "formModel.templateTitle",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cuModule.styleList
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "样式模板",
                                prop: "styleTemplate",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择样式模板",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.changeStyle },
                                  model: {
                                    value: _vm.formModel.styleTemplate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "styleTemplate",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.styleTemplate",
                                  },
                                },
                                _vm._l(
                                  _vm.cuModule.styleList,
                                  function (styleItem, index) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: index,
                                        attrs: { label: styleItem.value },
                                      },
                                      [_vm._v(_vm._s(styleItem.label))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formModel.template == "1"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "自定义名称",
                                prop: "customName",
                                rules: [
                                  {
                                    required: true,
                                    validator: _vm.validCommonName,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: { placeholder: "自定义名称" },
                                model: {
                                  value: _vm.formModel.customName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formModel, "customName", $$v)
                                  },
                                  expression: "formModel.customName",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formModel.template == "4"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "主标题",
                                prop: "mainTitle",
                                rules: [
                                  {
                                    required: true,
                                    validator: _vm.validCommonName,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: { placeholder: "主标题" },
                                model: {
                                  value: _vm.formModel.mainTitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formModel, "mainTitle", $$v)
                                  },
                                  expression: "formModel.mainTitle",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formModel.template == "4" &&
                      _vm.formModel.styleTemplate == 3
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "副标题",
                                prop: "subtitle",
                                rules: [
                                  {
                                    required: true,
                                    validator: _vm.validCommonName,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: { placeholder: "副标题" },
                                model: {
                                  value: _vm.formModel.subtitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formModel, "subtitle", $$v)
                                  },
                                  expression: "formModel.subtitle",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formModel.template == "1"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "访问路径",
                                prop: "jumpType",
                                rules: [
                                  {
                                    required: true,
                                    validator: _vm.validPageLink,
                                    trigger: ["change"],
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSetUrl("", -1)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formModel.jumpType > 0
                                        ? "已配置"
                                        : "路径配置"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formModel.template == "1"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否企业会员",
                                prop: "jumpType",
                              },
                            },
                            [
                              _c("el-switch", {
                                staticStyle: {
                                  width: "40px",
                                  "margin-right": "20px",
                                },
                                model: {
                                  value: _vm.formModel.isEnterpriseAuth,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      "isEnterpriseAuth",
                                      $$v
                                    )
                                  },
                                  expression: "formModel.isEnterpriseAuth",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formModel.template == "3" ||
                      _vm.formModel.template == "4" ||
                      _vm.formModel.template == "5"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "备注名称",
                                prop: "remarkName",
                                rules: [
                                  {
                                    required: true,
                                    validator: _vm.validCommonName,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: { placeholder: "备注名称" },
                                model: {
                                  value: _vm.formModel.remarkName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formModel, "remarkName", $$v)
                                  },
                                  expression: "formModel.remarkName",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.formModel.template != "6" ? "图片配置" : "",
                            prop: "imgList",
                            rules: [
                              {
                                required: true,
                                validator: _vm.validateImgList,
                                trigger: ["submit"],
                              },
                            ],
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v(_vm._s(_vm.imgTip)),
                          ]),
                          _vm._v(" "),
                          _vm.formModel.template !== 2
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-left": "-130px",
                                    "padding-bottom": "10px",
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.formModel.imgList,
                                    function (rowItem, index) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "row-set" },
                                        [
                                          _c("div", { staticClass: "f-s" }, [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(index + 1) +
                                                "\n              "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _vm.cuModule.rowLink
                                            ? [
                                                _c(
                                                  "div",
                                                  { staticClass: "f-t" },
                                                  [_vm._v("访问页面：")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "",
                                                          prop:
                                                            "imgList." +
                                                            index +
                                                            ".jumpType",
                                                          rules: [
                                                            {
                                                              required: true,
                                                              validator:
                                                                _vm.validRowLink,
                                                              trigger: "change",
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleSetUrl(
                                                                  "imgList",
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                rowItem.jumpType >
                                                                  0
                                                                  ? "已配置"
                                                                  : "路径配置"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "f-t" }, [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.imgTitle) +
                                                "\n              "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    prop:
                                                      "imgList." +
                                                      index +
                                                      ".picName",
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: "请输入",
                                                        // validator: (formModel.template == 1 ?validCommonName:validShortCommonName),
                                                        trigger: "blur",
                                                      },
                                                      {
                                                        max: _vm.imgNameLimit,
                                                        message:
                                                          "不超过" +
                                                          _vm.imgNameLimit +
                                                          "个字",
                                                        trigger: "blur",
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "180px",
                                                      "margin-right": "20px",
                                                    },
                                                    attrs: {
                                                      placeholder: "请输入",
                                                    },
                                                    model: {
                                                      value: rowItem.picName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          rowItem,
                                                          "picName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "rowItem.picName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.formModel.template != 1 &&
                                          _vm.formModel.template != 2
                                            ? _c(
                                                "div",
                                                { staticClass: "f-t" },
                                                [
                                                  _vm._v(
                                                    "\n                是否企业会员：\n              "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.formModel.template != 1 &&
                                          _vm.formModel.template != 2
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "",
                                                        prop:
                                                          "imgList." +
                                                          index +
                                                          ".isEnterpriseAuth",
                                                      },
                                                    },
                                                    [
                                                      _c("el-switch", {
                                                        staticStyle: {
                                                          width: "40px",
                                                          "margin-right":
                                                            "20px",
                                                        },
                                                        model: {
                                                          value:
                                                            rowItem.isEnterpriseAuth,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              rowItem,
                                                              "isEnterpriseAuth",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "rowItem.isEnterpriseAuth",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.formModel.template != 6
                                            ? [
                                                _c(
                                                  "div",
                                                  { staticClass: "f-t" },
                                                  [_vm._v("图片：")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "",
                                                          prop:
                                                            "imgList." +
                                                            index +
                                                            ".pic",
                                                          rules: [
                                                            {
                                                              required: true,
                                                              validator:
                                                                _vm.validPicUrl,
                                                              trigger: "change",
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "upLoadPicBox",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.modalFirmPicTap(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            rowItem.pic
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pictrue",
                                                                  },
                                                                  [
                                                                    _c("img", {
                                                                      attrs: {
                                                                        src: rowItem.pic,
                                                                      },
                                                                    }),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "upLoad",
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-camera cameraIconfont",
                                                                    }),
                                                                  ]
                                                                ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !(
                                            _vm.formModel.template == 5 ||
                                            _vm.formModel.template == 3
                                          )
                                            ? _c(
                                                "div",
                                                { staticClass: "f-t" },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delFirm(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm.formModel.imgList.length <
                                    _vm.imgMaxLen &&
                                  _vm.formModel.template != 5 &&
                                  _vm.formModel.template != 3
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary", plain: "" },
                                          on: { click: _vm.addRow },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formModel.template == 6
                                                ? "新增公告"
                                                : "新增图片配置"
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.formModel.imgList.length <
                                    _vm.imgMaxLen && _vm.formModel.template == 3
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary", plain: "" },
                                          on: { click: _vm.addLine },
                                        },
                                        [_vm._v("新增一行图片配置")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.formModel.imgList.length ==
                                    _vm.imgMaxLen && _vm.formModel.template == 3
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "defalut", plain: "" },
                                          on: { click: _vm.delLine },
                                        },
                                        [_vm._v("删除一行图片配置")]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formModel.template == 2
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "图片",
                                        prop: "pic",
                                        rules: [
                                          {
                                            required: true,
                                            validator: _vm.validPicUrl,
                                            trigger: "change",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "upLoadPicBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.modalFirmPicTap(-1)
                                            },
                                          },
                                        },
                                        [
                                          _vm.formModel.pic
                                            ? _c(
                                                "div",
                                                { staticClass: "pictrue" },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: _vm.formModel.pic,
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                { staticClass: "upLoad" },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-camera cameraIconfont",
                                                  }),
                                                ]
                                              ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancle } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.submit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("page-link", {
        ref: "pageLinkSet",
        attrs: { visible: _vm.pageLinkVisual, editData: _vm.pageLinkData },
        on: {
          "update:visible": function ($event) {
            _vm.pageLinkVisual = $event
          },
          cancle: _vm.cancleSetUrl,
          save: _vm.updateUrlSet,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.formModel.id ? "查看人群分组" : "新增人群分组",
            visible: _vm.visible,
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "560px",
          },
          on: { close: _vm.cancle },
        },
        [
          _c(
            "el-form",
            {
              ref: "editPram",
              attrs: {
                model: _vm.formModel,
                "label-width": "130px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分组名称",
                    prop: "name",
                    rules: [
                      {
                        required: true,
                        message: "请输入",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "15",
                      disabled: _vm.viewFlag,
                      placeholder: "请输入分组名称",
                    },
                    model: {
                      value: _vm.formModel.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModel, "name", $$v)
                      },
                      expression: "formModel.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "说明",
                    prop: "remark",
                    rules: [
                      {
                        required: true,
                        message: "请输入",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      maxlength: "30",
                      disabled: _vm.viewFlag,
                      placeholder: "请输入说明",
                    },
                    model: {
                      value: _vm.formModel.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModel, "remark", $$v)
                      },
                      expression: "formModel.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "图片",
                    prop: "pic",
                    rules: [
                      {
                        required: true,
                        message: "请上传图片",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1")
                        },
                      },
                    },
                    [
                      _vm.formModel.pic
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", { attrs: { src: _vm.formModel.pic } }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "#999" } }, [
                        _vm._v("(推荐尺寸：400 X 400像素)"),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "结束类型", rules: [{ required: true }] } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.viewFlag },
                      model: {
                        value: _vm.isFixedTime,
                        callback: function ($$v) {
                          _vm.isFixedTime = $$v
                        },
                        expression: "isFixedTime",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("无时间"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("时间点"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isFixedTime
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "",
                        prop: "endTime",
                        rules: [
                          {
                            required: true,
                            message: "请选择时间",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          disabled: _vm.viewFlag,
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "结束日期和时间",
                          "picker-options": _vm.pickerOptions,
                          "default-time": "23:59:59",
                        },
                        model: {
                          value: _vm.formModel.endTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModel, "endTime", $$v)
                          },
                          expression: "formModel.endTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "访问路径",
                    prop: "jumpType",
                    rules: [
                      {
                        required: true,
                        validator: _vm.validPageLink,
                        trigger: ["change"],
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSetUrl("", -1)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.formModel.jumpType > 0 ? "已配置" : "路径配置"
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancle } }, [_vm._v("取 消")]),
              _vm._v(" "),
              !_vm.viewFlag
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("page-link", {
        ref: "pageLinkSet",
        attrs: {
          visible: _vm.pageLinkVisual,
          editData: _vm.pageLinkData,
          viewFlag: _vm.viewFlag,
        },
        on: {
          "update:visible": function ($event) {
            _vm.pageLinkVisual = $event
          },
          cancle: _vm.cancleSetUrl,
          save: _vm.updateUrlSet,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "优惠券",
                    prop: "value",
                    "label-width": "130px",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "优惠券" },
                      model: {
                        value: _vm.form.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "value", $$v)
                        },
                        expression: "form.value",
                      },
                    },
                    _vm._l(_vm.couponsList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
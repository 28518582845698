"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _streamManage = require("@/api/streamManage");
var _store = require("@/api/store");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tableData: [],
      totalPage: 0,
      PageSize: 10,
      currentPage2: 1,
      editGoodsDetail: false,
      goodsForm: {
        name: "",
        lowPrice: "",
        priceType: "",
        coverImgUrl: ""
      },
      goodsDialogVisible: false,
      fileList: [],
      fileList1: [],
      selectGoodsDisabled: false,
      dialogVisiblePicture: false,
      productList: [],
      selectValue: "",
      options: [{
        label: "商品名称",
        value: ""
      }],
      chooseNum: 0,
      // 1为新增 2为编辑
      // goodsStatus: 2,
      addOrUp: 0,
      //add时为1，update时为2
      // 0：未审核，1：审核中，2:审核通过，3审核失败
      goodsStatusOptions: [{
        label: "未审核",
        value: 0
      }, {
        label: "审核中",
        value: 1
      }, {
        label: "审核通过",
        value: 2
      }, {
        label: "审核失败",
        value: 3
      }],
      formInline: {
        searchContent: "",
        goodsStatus: 2
      },
      loading: false,
      findContent: "",
      disabledGoods: true
    };
  },
  mounted: function mounted() {
    this.getProductList(2);
    this.getGoodsList();
  },
  computed: {
    uploadDisabled: function uploadDisabled() {
      return this.fileList.length > 0;
    },
    uploadDisabled1: function uploadDisabled1() {
      return this.fileList1.length > 0;
    }
  },
  methods: {
    // 获取直播间商品列表
    getProductList: function getProductList() {
      var that = this;
      var params = {
        offset: this.currentPage2,
        limit: this.PageSize
      };
      var forms = {
        name: this.formInline.searchContent,
        status: this.formInline.goodsStatus
      };
      Object.assign(params, forms);
      (0, _streamManage.getProductList)(params).then(function (res) {
        that.tableData = res ? res.goods : [];
        that.totalPage = res.total * that.PageSize;
      });
    },
    // 获取商品列表数据
    getGoodsList: function getGoodsList(keywords) {
      var _this = this;
      var params = {
        offset: 1,
        limit: 100,
        type: 1,
        status: 2,
        //商品状态，0：未审核。1：审核中，2：审核通过，3：审核驳回
        keywords: keywords
      };
      (0, _store.productLstApi)(params).then(function (res) {
        _this.loading = false;
        _this.productList = [];
        res.list.map(function (item) {
          _this.productList.push({
            name: item.storeName,
            goodsId: item.id,
            coverImgUrl: item.image,
            price: item.price
          });
        });
      });
    },
    // 同步直播间
    handleSizeChange: function handleSizeChange(val) {
      this.PageSize = val;
      this.currentPage2 = 1;
      this.getProductList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getProductList();
    },
    // 删除商品
    handleDelete: function handleDelete(val) {
      var _this2 = this;
      var params = {
        goodsId: val.goodsId
      };
      (0, _streamManage.deleteGoods)(params).then(function (res) {
        _this2.$message.success("删除商品成功");
        _this2.getProductList();
      });
    },
    // 点击编辑商品
    handleEdit: function handleEdit(val1, val) {
      this.chooseNum = val1;
      this.goodsForm = val;
      this.editGoodsDetail = true;
      var arr = [];
      arr.push({
        name: val.name,
        url: val.coverImgUrl
      });
      this.fileList = arr;
      this.fileList1 = arr;
      this.goodsForm.priceType = String(this.goodsForm.priceType);
    },
    httpRequest: function httpRequest() {
      var list = [];
      this.chooseGoodsList.map(function (item) {
        list.push({
          name: item.name,
          url: item.coverImgUrl
        });
      });
      this.fileList = JSON.parse(JSON.stringify(list));
      this.selectGoodsDisabled = false;
      this.goodsForm.picOne = this.fileList[0].url;
    },
    httpRequest1: function httpRequest1(param) {
      var _this3 = this;
      var formData = new FormData();
      formData.append("media", param.file);
      var loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      (0, _streamManage.wechatUploadApi)(formData, {
        type: "image"
      }).then(function (res) {
        loading.close();
        _this3.goodsForm.coverImgUrl = res.mediaId;
      }).catch(function () {
        loading.close();
      });
    },
    uploadChange1: function uploadChange1(val) {
      this.fileList1 = [{
        name: val.name,
        url: val.url
      }];
    },
    openGoodsListDialog: function openGoodsListDialog() {
      this.selectGoodsDisabled = true;
      this.dialogVisiblePicture = true;
      // 为编辑
      if (this.chooseNum == 2) {
        this.getProductList();
      } else {
        // 新增
        this.getGoodsList();
      }
    },
    handleRemove: function handleRemove() {
      this.fileList = [];
    },
    handleRemove1: function handleRemove1() {
      this.fileList1 = [];
    },
    chooseGoods: function chooseGoods(val) {
      this.dialogVisiblePicture = false;
      var arr = [];
      this.chooseGoodsList = [];
      arr.push(val);
      this.chooseGoodsList = JSON.parse(JSON.stringify(arr));
      this.httpRequest();
      // 1为新增
      if (this.chooseNum == 1) {
        this.goodsForm.url = "pages/goods_details/index.html?id=" + val.goodsId;
        this.goodsForm.name = val.name;
        this.goodsForm.price = val.price;
        this.goodsForm.priceType = "1";
      }
    },
    // 编辑商品提交
    submitForm: function submitForm(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          console.log(valid, _this4.goodsForm, "vvvvvv");
          if (_this4.chooseNum == 1) {
            (0, _streamManage.addGoods)(_this4.goodsForm).then(function (res) {
              _this4.$message.success("添加商品成功");
            });
          } else {
            (0, _streamManage.updateGoods)(_this4.goodsForm).then(function (res) {
              _this4.$message.success("编辑商品成功");
            });
          }
          setTimeout(function () {
            _this4.$message.success("请手动刷新页面");
          }, 10000);
          _this4.getProductList();
          _this4.editGoodsDetail = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancelForm: function cancelForm() {
      this.editGoodsDetail = false;
    },
    addGoods: function addGoods(val) {
      this.editGoodsDetail = true;
      this.chooseNum = val;
      this.loading = true;
      this.goodsForm = {
        name: "",
        lowPrice: "",
        priceType: "",
        coverImgUrl: ""
      };
      this.fileList1 = [];
      this.fileList = [];
    },
    searchGoods: function searchGoods() {
      if (this.formInline.goodsStatus == 0 || this.formInline.goodsStatus == 3) {
        this.disabledGoods = false;
      } else {
        this.disabledGoods = true;
      }
      this.getProductList(this.formInline.goodsStatus);
    },
    resetGoods: function resetGoods(formName) {
      this.$refs[formName].resetFields();
    },
    findGoodsList: function findGoodsList() {
      this.getGoodsList(this.findContent);
    }
  }
};
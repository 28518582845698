"use strict";

/*
 * @Author: Zcl84765
 * @Date: 2022-12-30 10:37:32
 * @LastEditors: Zcl84765
 * @LastEditTime: 2023-02-06 15:18:17
 */
var addressOptions = [
// {
// 	value: 1,
// 	label: '上海',
// 	children: [
// 	]
// },
// {
// 	value: 10,
// 	label: '深圳',
// 	children: [{
// 			value: 11,
// 			label: '宝新科技园',

// 		},

// 	]
// },
// {
// 	value: 12,
// 	label: '苏州',
// 	children: [,

// 	]
// },
{
  value: 14,
  label: "北京",
  children: [{
    value: 15,
    label: "阿里巴巴创新中心·优客工场（北京酒仙桥）"
  }, {
    value: 16,
    label: "北京华贸中心·优客工场"
  }, {
    value: 17,
    label: "北京顺义临空·优客工场"
  }, {
    value: 18,
    label: "北京798国辉·优客工场"
  }, {
    value: 19,
    label: "北京阳光100·优客工场"
  }, {
    value: 20,
    label: "北京翠林漫步·优客工场"
  }, {
    value: 21,
    label: "北京房山大学城·优客工场"
  }, {
    value: 22,
    label: "北京鸿坤广场·优客工场"
  }, {
    value: 23,
    label: "北京鸿坤I·优客工场"
  }, {
    value: 24,
    label: "北京黄村清城·优客工场"
  }, {
    value: 25,
    label: "北京金域国际·优客工场"
  }, {
    value: 26,
    label: "北京首创和寓·优客工场"
  }, {
    value: 27,
    label: "北京亚洲金融大厦·优客工场"
  }, {
    value: 28,
    label: "北京五矿幸福里·优客工场"
  }, {
    value: 29,
    label: "北京爱康·优客工场"
  }]
}, {
  value: 30,
  label: "开封",
  children: [{
    value: 31,
    label: "开封大观·优客工场"
  }, {
    value: 32,
    label: "开封大宏广场·优客工场"
  }]
}, {
  value: 33,
  label: "杭州",
  children: [{
    value: 34,
    label: "杭州正泰·优客工场"
  }]
}, {
  value: 35,
  label: "宁波",
  children: [{
    value: 36,
    label: "宁波恒泰大厦·优客工场"
  }, {
    value: 37,
    label: "宁波城投·优客工场"
  }]
}, {
  value: 38,
  label: "武汉",
  children: [{
    value: 39,
    label: "武汉国贸新世界·优客工场"
  }, {
    value: 40,
    label: "武汉壹号天地·优客工场"
  }, {
    value: 41,
    label: "武汉香格里拉中心·优客工场"
  }]
}, {
  value: 42,
  label: "郑州",
  children: [{
    value: 43,
    label: "郑州何方·优客工场"
  }, {
    value: 44,
    label: "郑州裕达国贸·优客工场"
  }]
}, {
  value: 45,
  label: "上海",
  children: [{
    value: 2,
    label: "凤凰园"
  }, {
    value: 3,
    label: "张江微电子港"
  }, {
    value: 4,
    label: "张江高科华虹科技园"
  }, {
    value: 5,
    label: "天华信息科技园"
  }, {
    value: 6,
    label: "新汇园"
  }, {
    value: 7,
    label: "金桥软件园"
  }, {
    value: 8,
    label: "陆家嘴软件园 1号楼"
  }, {
    value: 9,
    label: "松江漕河泾新经济园"
  }, {
    value: 46,
    label: "漕河泾·优客工场"
  }, {
    value: 47,
    label: "金陵大厦·优客工场"
  }, {
    value: 48,
    label: "陆家嘴3F·优客工场"
  }, {
    value: 49,
    label: "凯腾大厦·优客工场"
  }, {
    value: 50,
    label: "城市广场·优客工场"
  }]
}, {
  value: 51,
  label: "扬州",
  children: [{
    value: 52,
    label: "扬州瘦西湖·优客工场"
  }]
}, {
  value: 53,
  label: "苏州",
  children: [{
    value: 54,
    label: "苏州圆融星座·优客工场"
  }, {
    value: 13,
    label: "苏州生物医药产业园"
  }]
}, {
  value: 55,
  label: "南京",
  children: [{
    value: 56,
    label: "南京赛格·优客工场"
  }, {
    value: 57,
    label: "南京金港科创园·优客工场"
  }]
}, {
  value: 58,
  label: "无锡",
  children: [{
    value: 59,
    label: "无锡黄金湾·优客工场"
  }]
}, {
  value: 60,
  label: "常州",
  children: [{
    value: 61,
    label: "常州科创X-SPACE·优客工场"
  }, {
    value: 62,
    label: "双桂坊·优客工场"
  }]
}, {
  value: 63,
  label: "淮安",
  children: [{
    value: 64,
    label: "淮安汇金云谷·优客工场"
  }]
}, {
  value: 65,
  label: "天津",
  children: [{
    value: 66,
    label: "天津库农天下·优客工场"
  }, {
    value: 67,
    label: "天津新华·优客工场"
  }]
}, {
  value: 68,
  label: "大连",
  children: [{
    value: 69,
    label: "大连冰山慧谷·优客工场"
  }, {
    value: 70,
    label: "大连泊寓·优客工场"
  }]
}, {
  value: 71,
  label: "沈阳",
  children: [{
    value: 72,
    label: "沈阳同方·优客工场"
  }, {
    value: 73,
    label: "沈阳五矿·优客工场"
  }]
}, {
  value: 74,
  label: "石家庄",
  children: [{
    value: 75,
    label: "石家庄天和时光·优客工场"
  }, {
    value: 76,
    label: "石家庄众美三期·优客工场"
  }, {
    value: 77,
    label: "石家庄裕彤体育馆·优客工场"
  }, {
    value: 78,
    label: "阿里巴巴鸿昇广场·优客工场"
  }, {
    value: 79,
    label: "河北廊坊荣盛四季花语·优客工场"
  }]
}, {
  value: 80,
  label: "唐山",
  children: [{
    value: 81,
    label: "北京优客工场·盛和众创空间"
  }]
}, {
  value: 82,
  label: "长春",
  children: [{
    value: 83,
    label: "长春CBS·优客工场"
  }, {
    value: 84,
    label: "长春中白科技园·优客工场"
  }, {
    value: 85,
    label: "长春向日草·优客工场"
  }]
}, {
  value: 86,
  label: "呼伦贝尔",
  children: [{
    value: 87,
    label: "尚品众创赋能空间·优客工场"
  }]
}, {
  value: 88,
  label: "太原",
  children: [{
    value: 89,
    label: "太原兴业大厦·优客工场"
  }, {
    value: 90,
    label: "太原迎泽数码·优客工场"
  }]
}, {
  value: 91,
  label: "大同",
  children: [{
    value: 92,
    label: "大同青创城·优客工场"
  }]
}, {
  value: 93,
  label: "青岛",
  children: [{
    value: 94,
    label: "青岛海草·优客工场"
  }, {
    value: 95,
    label: "青岛自贸大厦·优客工场"
  }]
}, {
  value: 96,
  label: "济南",
  children: [{
    value: 97,
    label: "济南新闻大厦·优客工场"
  }]
}, {
  value: 98,
  label: "潍坊",
  children: [{
    value: 99,
    label: "潍坊保税生活广场·优客工场"
  }]
}, {
  value: 100,
  label: "合肥",
  children: [{
    value: 101,
    label: "合肥汉华·优客工场"
  }]
}, {
  value: 102,
  label: "成都",
  children: [{
    value: 103,
    label: "成都天府软件园·优客工场"
  }, {
    value: 104,
    label: "成都天府二街·优客工场"
  }, {
    value: 105,
    label: "成都银泰·优客工场"
  }, {
    value: 106,
    label: "成都银泰9号·优客工场"
  }]
}, {
  value: 107,
  label: "泸州",
  children: [{
    value: 108,
    label: "泸州这有光·优客工场"
  }]
}, {
  value: 109,
  label: "绵阳",
  children: [{
    value: 110,
    label: "绵阳灵创·优客工场"
  }]
}, {
  value: 111,
  label: "西安",
  children: [{
    value: 112,
    label: "西安高速·优客工场"
  }, {
    value: 113,
    label: "西安睿中心·优客工场"
  }]
}, {
  value: 114,
  label: "渭南",
  children: [{
    value: 115,
    label: "渭南新洲时代广场·优客工场"
  }, {
    value: 116,
    label: "渭南钟山风雨体育馆·优客工场"
  }]
}, {
  value: 117,
  label: "昆明",
  children: [{
    value: 118,
    label: "昆明螺蛳湾·优客工场"
  }, {
    value: 119,
    label: "昆明霖岚国际·优客工场"
  }]
}, {
  value: 120,
  label: "拉萨",
  children: [{
    value: 121,
    label: "拉萨柳梧新区·优客工场"
  }]
}, {
  value: 122,
  label: "乌鲁木齐",
  children: [{
    value: 123,
    label: "新疆盘古智库·优客工场"
  }]
}, {
  value: 124,
  label: "深圳",
  children: [{
    value: 125,
    label: "深圳海岸大厦·优客工场"
  }, {
    value: 126,
    label: "深圳yeswedo福保国际·优客工场"
  }, {
    value: 127,
    label: "深圳中天元物流大厦·优客工场"
  }, {
    value: 128,
    label: "深圳新华保险大厦·优客工场"
  }, {
    value: 129,
    label: "深圳锐思·优客工场"
  }, {
    value: 130,
    label: "深圳万科星火·优客工场"
  }, {
    value: 131,
    label: "深圳湾创·优客工场"
  }, {
    value: 132,
    label: "深圳三航科技大厦·优客工场"
  }, {
    value: 133,
    label: "深圳图钉·优客工场"
  }, {
    value: 134,
    label: "溧阳（深圳）创新中心·优客工场"
  }, {
    value: 11,
    label: "宝新科技园"
  }]
}, {
  value: 135,
  label: "东莞",
  children: [{
    value: 136,
    label: "东莞sunny·优客工场"
  }, {
    value: 137,
    label: "东莞七彩虹·优客工场"
  }, {
    value: 138,
    label: "东莞联丰创意谷·优客工场"
  }, {
    value: 139,
    label: "东莞恒兆中心5F/6F·优客工场"
  }]
}, {
  value: 140,
  label: "广州",
  children: [{
    value: 141,
    label: "广州中交汇通中心·优客工场"
  }]
}, {
  value: 142,
  label: "佛山",
  children: [{
    value: 143,
    label: "佛山金安大厦·优客工场"
  }]
}, {
  value: 144,
  label: "珠海",
  children: [{
    value: 145,
    label: "珠海正方·优客工场"
  }, {
    value: 146,
    label: "珠海珠光·优客工场"
  }]
}, {
  value: 147,
  label: "厦门",
  children: [{
    value: 148,
    label: "厦门集美杏林湾·优客工场"
  }, {
    value: 149,
    label: "厦门软件园·优客工场"
  }, {
    value: 150,
    label: "埃森哲厦门集美诚毅国际商务中心·优客工场"
  }]
}, {
  value: 151,
  label: "福州",
  children: [{
    value: 152,
    label: "福州优梦空间·优客工场"
  }]
}, {
  value: 153,
  label: "海口",
  children: [{
    value: 154,
    label: "海口优享社·优客工场"
  }, {
    value: 155,
    label: "海口海孵科创·优客工场"
  }]
}, {
  value: 156,
  label: "香港",
  children: [{
    value: 157,
    label: "香港旺角新世纪广场·优客工场"
  }]
}];
var Oprops = {
  value: "label"
};

//0:待付款 1:待组团 8. 组团失败 9: 组团成功
var statusList = [{
  code: 0,
  name: "等待付款"
}, {
  code: 1,
  name: "等待组团"
}, {
  code: 8,
  name: "组团失败"
}, {
  code: 9,
  name: "组团成功"
}, {
  code: 4,
  name: "退款成功"
}, {
  code: 3,
  name: "付款关闭"
}];
module.exports = {
  addressOptions: addressOptions,
  Oprops: Oprops,
  statusList: statusList
};
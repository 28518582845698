"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _channel = require("@/api/channel");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tableData: [],
      tableFrom: {
        page: 1,
        limit: 20
      },
      listLoading: false,
      total: 0,
      ruleForm: {
        name: "",
        paramList: [{
          name: "",
          dimension: "",
          paraphrase: "",
          chooseType: 1,
          //1是手动输入 2是固定取值
          valueList: [{
            key: ""
          }]
        }]
      },
      editParamsId: "",
      dialogTitle: "新增参数模板",
      dialogFormVisible: false,
      rules: {
        name: [{
          required: true,
          message: "请输入模板名称",
          trigger: "blur"
        }]
      },
      isFlagAdd: true
    };
  },
  created: function created() {
    this.getList();
  },
  watch: {
    dialogTitle: function dialogTitle(val) {
      val == "查看参数模板" ? this.isFlagAdd = false : this.isFlagAdd = true;
    }
  },
  methods: {
    changeEnable: function changeEnable(values) {
      var _this = this;
      var that = this;
      var id = values.id;
      (0, _channel.tempEnable)(id, values.enable).then(function (res) {
        console.log(res, "res");
        that.tableFrom.page = 1;
        _this.getList();
      });
    },
    getList: function getList() {
      var _this2 = this;
      (0, _channel.tempList)(this.tableFrom).then(function (res) {
        console.log(res);
        _this2.tableData = res.list;
        _this2.total = res.total;
        console.log(_this2.tableData, "tableData");
      });
    },
    handleClose: function handleClose() {
      this.$refs["ruleFormV"].clearValidate();
      this.dialogFormVisible = false;
    },
    handleCancel: function handleCancel(formName) {
      this.dialogFormVisible = false;
      this.$refs[formName].clearValidate();
    },
    addParamPop: function addParamPop() {
      this.dialogTitle = "新增参数模板";
      this.dialogFormVisible = true;
      this.ruleForm = {
        name: "",
        paramList: [{
          name: "",
          dimension: "",
          paraphrase: "",
          chooseType: 1,
          //1是手动输入 2是固定取值
          valueList: [{
            key: ""
          }]
        }]
      };
    },
    onEdit: function onEdit(value) {
      var _this3 = this;
      this.dialogTitle = "查看参数模板";
      this.dialogFormVisible = true;
      this.editParamsId = value.id;
      console.log(value, "value");
      var params = {
        name: value.name,
        paramList: value.paramList
      };
      this.$nextTick(function () {
        _this3.ruleForm = Object.assign({}, params);
        console.log(_this3.ruleForm, "ruleForm");
      });
    },
    handleDelete: function handleDelete(id, index) {
      var _this4 = this;
      var that = this;
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _channel.deleteId)(id).then(function (res) {
          _this4.$message({
            type: "success",
            message: "删除成功!"
          });
          that.tableFrom.page = 1;
          that.getList();
        });
      }).catch(function () {
        _this4.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    handleSubmit: function handleSubmit(formName) {
      var that = this;
      console.log(that.ruleForm, "ruleForm");
      var flag = false;
      this.ruleForm.paramList.forEach(function (item) {
        if (item.chooseType == 2) {
          item.valueList.forEach(function (s) {
            if (s.key == "") {
              flag = true;
            }
          });
        } else {
          item.valueList = [];
        }
      });
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (flag) {
            that.$message.error("固定取值不能为空！");
            return;
          }
          that.dealData();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    dealData: function dealData() {
      var that = this;
      if (this.dialogTitle == "新增参数模板") {
        (0, _channel.tempSave)(this.ruleForm).then(function (res) {
          that.dealSuccess();
        });
      } else {
        var params = Object.assign({
          id: this.editParamsId
        }, this.ruleForm);
        (0, _channel.tempUpdate)(params).then(function (res) {
          that.dealSuccess();
        });
      }
    },
    dealSuccess: function dealSuccess() {
      this.$message.success("操作成功");
      this.$refs["ruleFormV"].clearValidate();
      this.dialogFormVisible = false;
      this.tableFrom.page = 1;
      this.getList();
    },
    addValueList: function addValueList(index) {
      this.ruleForm.paramList[index].valueList.push({
        key: ""
      });
    },
    deleteValueList: function deleteValueList(index, indexn) {
      var that = this;
      that.ruleForm.paramList[index].valueList.splice(indexn, 1);
    },
    addParamList: function addParamList() {
      this.ruleForm.paramList.push({
        name: "",
        dimension: "",
        paraphrase: "",
        chooseType: 1,
        //1是手动输入 2是固定取值
        valueList: [{
          key: ""
        }]
      });
    },
    removeDomain: function removeDomain(index) {
      var _this5 = this;
      var that = this;
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        that.ruleForm.paramList.splice(index, 1);
        _this5.$message({
          type: "success",
          message: "删除成功!"
        });
      }).catch(function () {
        _this5.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return _defineProperty(_defineProperty(_defineProperty({
      activeName: "全部",
      tableData: [{
        date: "2016-05-02",
        name: "双十一产品预告短信",
        status: "进行中"
      }, {
        date: "2016-05-04",
        name: "618产品预告短信",
        status: "已停止"
      }, {
        date: "2016-05-01",
        name: "618产品预告短信",
        status: "待启动"
      }, {
        date: "2016-05-03",
        name: "618产品预告短信",
        status: "进行中"
      }]
    }, "activeName", "全部"), "paneList", [{
      name: "全部",
      id: "0"
    }, {
      name: "已停止",
      id: "1"
    }, {
      name: "进行中",
      id: "2"
    }, {
      name: "待启动",
      id: "1"
    }, {
      name: "草稿",
      id: "2"
    }]), "tableFrom", {
      page: 1,
      limit: 20,
      cateId: "",
      keywords: "",
      type: "1"
    });
  },
  methods: {
    handleClick: function handleClick(row) {
      console.log(row);
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      // this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      // this.getList();
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    { attrs: { size: "small", "label-width": "150px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单状态(售前)： " } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: { change: _vm.seachList },
                              model: {
                                value: _vm.tableFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "status", $$v)
                                },
                                expression: "tableFrom.status",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "all" } },
                                [
                                  _vm._v(
                                    "全部\n                " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.all
                                          ? _vm.orderChartType.all
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "unPaid" } },
                                [
                                  _vm._v(
                                    "未支付\n                " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.unPaid
                                          ? _vm.orderChartType.unPaid
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "unConfirmed" } },
                                [
                                  _vm._v(
                                    "待确认\n                " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.unConfirmed
                                          ? _vm.orderChartType.unConfirmed
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "inProduction" } },
                                [
                                  _vm._v(
                                    "生产中\n                " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.inProduction
                                          ? _vm.orderChartType.inProduction
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "spike" } },
                                [
                                  _vm._v(
                                    "待收货\n                " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.spike
                                          ? _vm.orderChartType.spike
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单状态(售后)： " } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: { change: _vm.seachList },
                              model: {
                                value: _vm.tableFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "status", $$v)
                                },
                                expression: "tableFrom.status",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "complete" } },
                                [
                                  _vm._v(
                                    "交易完成\n                " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.complete
                                          ? _vm.orderChartType.complete
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "refunding" } },
                                [
                                  _vm._v(
                                    "退款中\n                " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.refunding
                                          ? _vm.orderChartType.refunding
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "refunded" } },
                                [
                                  _vm._v(
                                    "已退款\n                " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.refunded
                                          ? _vm.orderChartType.refunded
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "close" } },
                                [
                                  _vm._v(
                                    "交易关闭\n                " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.close
                                          ? _vm.orderChartType.close
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "deleted" } },
                                [
                                  _vm._v(
                                    "已删除\n                " +
                                      _vm._s(
                                        "(" + _vm.orderChartType.deleted
                                          ? _vm.orderChartType.deleted
                                          : 0 + ")"
                                      )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "时间选择：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button", size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectChange(
                                    _vm.tableFrom.dateLimit
                                  )
                                },
                              },
                              model: {
                                value: _vm.tableFrom.dateLimit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "dateLimit", $$v)
                                },
                                expression: "tableFrom.dateLimit",
                              },
                            },
                            _vm._l(_vm.fromList.fromTxt, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "220px" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "订单搜索：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入订单号或者GII编号",
                                size: "small",
                                clearable: "",
                              },
                              model: {
                                value: _vm.tableFrom.orderId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "orderId", $$v)
                                },
                                expression: "tableFrom.orderId",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: { click: _vm.seachList },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("cards-data", { attrs: { cardLists: _vm.cardLists } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "商品总价：" } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.row.totalPrice)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "用户备注：" } },
                              [_c("span", [_vm._v(_vm._s(props.row.mark))])]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "商家备注：" } },
                              [_c("span", [_vm._v(_vm._s(props.row.remark))])]
                            ),
                            _vm._v(" "),
                            props.row.shippingType === 2
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "核销码：" } },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(props.row.verifyCode)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单号", "min-width": "190" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: { display: "block" },
                          domProps: { textContent: _vm._s(scope.row.orderId) },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.isDel,
                                expression: "scope.row.isDel",
                              },
                            ],
                            staticStyle: { color: "#ed4014", display: "block" },
                          },
                          [_vm._v("用户已删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单状态", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.refundStatus === 1 ||
                        scope.row.refundStatus === 2
                          ? _c("div", { staticClass: "refunding" }, [
                              _c("b", { staticStyle: { color: "#f124c7" } }, [
                                _vm._v(_vm._s(scope.row.statusStr.value)),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "退款原因：" +
                                    _vm._s(scope.row.refundReasonWap)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "备注说明：" +
                                    _vm._s(scope.row.refundReasonWapExplain)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "退款时间：" +
                                    _vm._s(scope.row.refundReasonTime)
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "acea-row" },
                                [
                                  _vm._v(
                                    "\n              退款凭证：\n              "
                                  ),
                                  scope.row.refundReasonWapImg
                                    ? _vm._l(
                                        scope.row.refundReasonWapImg.split(","),
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass:
                                                "demo-image__preview",
                                              staticStyle: {
                                                width: "35px",
                                                height: "auto",
                                                display: "inline-block",
                                              },
                                            },
                                            [
                                              _c("el-image", {
                                                attrs: {
                                                  src: item,
                                                  "preview-src-list": [item],
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                          },
                                        },
                                        [_vm._v("无")]
                                      ),
                                ],
                                2
                              ),
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(scope.row.statusStr.value)),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "realName",
                  label: "用户信息",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品信息", "min-width": "400" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.productList && scope.row.productList.length
                          ? _c(
                              "div",
                              _vm._l(scope.row.productList, function (val, i) {
                                return _c("div", { key: i }, [
                                  i < 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tabBox acea-row row-middle",
                                          staticStyle: {
                                            "flex-wrap": "inherit",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "demo-image__preview mr10",
                                            },
                                            [
                                              _c("el-image", {
                                                attrs: {
                                                  src: val.info.productInfo
                                                    .image,
                                                  "preview-src-list": [
                                                    val.info.productInfo.image,
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content:
                                                      val.info.productInfo
                                                        .storeName,
                                                    placement: "top-start",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm._f("strFilter")(
                                                            val.info.productInfo
                                                              .storeName
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", [
                                                _vm._v(
                                                  "\n                    规格：" +
                                                    _vm._s(
                                                      val.info.productInfo
                                                        .attrInfo.suk
                                                        ? val.info.productInfo
                                                            .attrInfo.suk
                                                        : "-"
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              val.info.productInfo.attrInfo
                                                .presents
                                                ? _c("span", [_vm._v("赠品：")])
                                                : _vm._e(),
                                              _vm._l(
                                                val.info.productInfo.attrInfo
                                                  .presents,
                                                function (item1) {
                                                  return _c(
                                                    "span",
                                                    { key: item1.id },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item1.name) +
                                                          " |\n                  "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "tabBox_pice" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        "￥" +
                                                          val.info.productInfo
                                                            .attrInfo.price
                                                          ? val.info.productInfo
                                                              .attrInfo.price +
                                                              " x " +
                                                              val.info.cartNum
                                                          : "-"
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.productList &&
                        scope.row.productList.length > 1
                          ? _c(
                              "div",
                              { staticStyle: { "margin-left": "30px" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.onOrderDetails(scope.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v("查看更多")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "payPrice",
                  label: "实际支付",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "orderType",
                  label: "订单类型",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "下单时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "120",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.paid === false &&
                        scope.row.statusStr.key == "unPaid" &&
                        scope.row.statusStr.key != "deleted" &&
                        scope.row.statusStr.key != "close"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.statusStr.key === "inProduction" &&
                        scope.row.shippingType === 1 &&
                        scope.row.refundStatus !== 2
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.sendGoods(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("发送货")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.statusStr.key === "spike" &&
                        scope.row.shippingType === 1 &&
                        scope.row.refundStatus !== 2 &&
                        _vm.sendFilter(scope.row.productList)
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.sendGoods(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("继续发货")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.statusStr.key === "spike" &&
                        scope.row.shippingType === 1 &&
                        scope.row.refundStatus !== 2 &&
                        !_vm.sendFilter(scope.row.productList)
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  disabled: "",
                                },
                              },
                              [_vm._v("发货已完成")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.statusStr.key === "unConfirmed" &&
                        scope.row.shippingType === 1 &&
                        scope.row.refundStatus !== 2
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addGii(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("GII编码")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.shippingType === 2 &&
                        scope.row.statusStr.key === "toBeWrittenOff" &&
                        scope.row.paid == true &&
                        scope.row.refundStatus === 0
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onWriteOff(scope.row)
                                  },
                                },
                              },
                              [_vm._v("立即核销")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          { attrs: { trigger: "click" } },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v("\n              更多"),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.onOrderDetails(scope.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v("订单详情")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.onOrderLog(scope.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v("订单记录")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.onOrderMark(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("订单备注")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.refundStatus === 1,
                                        expression:
                                          "scope.row.refundStatus === 1",
                                      },
                                    ],
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.onOrderRefuse(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("拒绝退款")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.refundStatus === 1,
                                        expression:
                                          "scope.row.refundStatus === 1",
                                      },
                                    ],
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.onOrderRefund(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("立即退款")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          scope.row.statusStr.key === "deleted",
                                        expression:
                                          "scope.row.statusStr.key === 'deleted'",
                                      },
                                    ],
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleDelete(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除订单")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          scope.row.statusStr.key !== "unPaid",
                                        expression:
                                          "scope.row.statusStr.key !== 'unPaid'",
                                      },
                                    ],
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.invoiceInformation(
                                          scope.row.id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("发票信息")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑订单",
            "before-close": _vm.handleClose,
            visible: _vm.dialogVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "editOrderRef",
                  attrs: {
                    model: _vm.editData,
                    "label-width": "120px",
                    rules: _vm.editOrderRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单编号", prop: "orderId" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editData.orderId,
                          callback: function ($$v) {
                            _vm.$set(_vm.editData, "orderId", $$v)
                          },
                          expression: "editData.orderId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品总价" } },
                    [
                      _c("el-input-number", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.editData.totalPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.editData, "totalPrice", $$v)
                          },
                          expression: "editData.totalPrice",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { disabled: true, label: "原始邮费" } },
                    [
                      _c("el-input-number", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.editData.totalPostage,
                          callback: function ($$v) {
                            _vm.$set(_vm.editData, "totalPostage", $$v)
                          },
                          expression: "editData.totalPostage",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "实际支付金额", prop: "payPrice" } },
                    [
                      _c("el-input-number", {
                        model: {
                          value: _vm.editData.payPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.editData, "payPrice", $$v)
                          },
                          expression: "editData.payPrice",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "实际支付邮费" } },
                    [
                      _c("el-input-number", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.editData.payPostage,
                          callback: function ($$v) {
                            _vm.$set(_vm.editData, "payPostage", $$v)
                          },
                          expression: "editData.payPostage",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handlerSubmit()
                            },
                          },
                        },
                        [_vm._v("提交")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作记录",
            visible: _vm.dialogVisibleJI,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleJI = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.LogLoading,
                  expression: "LogLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableDataLog.data },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "oid",
                  align: "center",
                  label: "ID",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "changeMessage",
                  label: "操作记录",
                  align: "center",
                  "min-width": "280",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "操作时间",
                  align: "center",
                  "min-width": "280",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.tableFromLog.limit,
                  "current-page": _vm.tableFromLog.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableDataLog.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeLog,
                  "current-change": _vm.pageChangeLog,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("details-from", {
        ref: "orderDetail",
        attrs: { orderId: _vm.orderId },
      }),
      _vm._v(" "),
      _c("invoice-information", { ref: "invoiceData" }),
      _vm._v(" "),
      _c("giiform", {
        ref: "gii",
        attrs: { orderId: _vm.orderId },
        on: { submitFail: _vm.getList },
      }),
      _vm._v(" "),
      _c("sendgoods", {
        ref: "sendgoods",
        attrs: { orderId: _vm.orderId },
        on: { submitFail: _vm.getList },
      }),
      _vm._v(" "),
      _c("order-send", {
        ref: "send",
        attrs: { orderId: _vm.orderId },
        on: { submitFail: _vm.getList },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "拒绝退款原因",
            visible: _vm.RefuseVisible,
            width: "500px",
            "before-close": _vm.RefusehandleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.RefuseVisible = $event
            },
          },
        },
        [
          _c("zb-parser", {
            attrs: {
              "form-id": 106,
              "is-create": 1,
              "edit-data": _vm.RefuseData,
            },
            on: {
              submit: _vm.RefusehandlerSubmit,
              resetForm: _vm.resetFormRefusehand,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "退款处理",
            visible: _vm.refundVisible,
            width: "500px",
            "before-close": _vm.refundhandleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.refundVisible = $event
            },
          },
        },
        [
          _vm.refundVisible
            ? _c("zb-parser", {
                attrs: {
                  "form-id": 107,
                  "is-create": 1,
                  "edit-data": _vm.refundData,
                },
                on: {
                  submit: _vm.refundhandlerSubmit,
                  resetForm: _vm.resetFormRefundhandler,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
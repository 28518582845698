"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _wxApi = require("@/api/wxApi");
var _ZBParser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/ZBParser"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Templates",
  components: {
    zbParser: _ZBParser.default
  },
  data: function data() {
    return {
      labelPosition: 'right',
      isCreate: 0,
      editData: {},
      dialogVisible: false,
      switchData: this.$constants.switchStatus,
      tableFrom: {
        page: 1,
        limit: 20,
        status: '',
        name: '',
        type: this.$route.params.type
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tempId: null
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    resetForm: function resetForm(formValue) {
      this.dialogVisible = false;
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    // 订单删除
    handleDelete: function handleDelete(row, idx) {
      var _this = this;
      this.$modalSure().then(function () {
        (0, _wxApi.wechatTemplateDeleteApi)(row.id).then(function () {
          _this.$message.success('删除成功');
          _this.tableData.data.splice(idx, 1);
        });
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.editData = {};
    },
    handlerSubmit: function handlerSubmit(formValue) {
      var _this2 = this;
      this.isCreate === 0 ? (0, _wxApi.wechatTemplateSaveApi)(formValue).then(function (data) {
        _this2.$message.success('新增成功');
        _this2.dialogVisible = false;
        _this2.editData = {};
        _this2.getList();
      }) : (0, _wxApi.wechatTemplateUpdateApi)(this.tempId, formValue).then(function (data) {
        _this2.$message.success('编辑成功');
        _this2.dialogVisible = false;
        _this2.getList();
      });
    },
    add: function add() {
      this.dialogVisible = true;
    },
    edit: function edit(row) {
      this.tempId = row.id;
      this.dialogVisible = true;
      this.isCreate = 1;
      this.editData = JSON.parse(JSON.stringify(row));
    },
    // 列表
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _wxApi.wechatTemplateListApi)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.list || [];
        _this3.tableData.total = res.total;
        _this3.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 修改状态
    onchangeIsShow: function onchangeIsShow(row) {
      var _this4 = this;
      (0, _wxApi.wechatTemplateStatusApi)(row.id, {
        status: row.status
      }).then(function () {
        _this4.$message.success('修改成功');
        _this4.getList();
      }).catch(function () {
        row.status = !row.status;
      });
    }
  }
};
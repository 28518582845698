var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "record-wrapper" }, [
    _c("meta", { attrs: { name: "referrer", content: "no-referrer" } }),
    _vm._v(" "),
    _c("div", { staticStyle: { display: "flex", height: "500px" } }, [
      _c(
        "div",
        {
          staticClass: "tabLeft",
          staticStyle: {
            width: "300px",
            "background-color": "#f5f5f5",
            "margin-right": "5px",
            height: "500px",
            "min-width": "300px",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { display: "flex", "margin-bottom": "5px" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { "font-size": "12px" },
                  attrs: {
                    placeholder: "请输入用户昵称或手机号码搜索",
                    clearable: "",
                  },
                  model: {
                    value: _vm.query,
                    callback: function ($$v) {
                      _vm.query = $$v
                    },
                    expression: "query",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: { click: _vm.searchList },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { height: "478px" } },
            _vm._l(_vm.endUserList, function (item, index) {
              return _c("ul", { key: item.index }, [
                _c(
                  "li",
                  {
                    class: [
                      _vm.completeSelectLi === index ? "activeClick" : "",
                    ],
                    staticStyle: {
                      position: "relative",
                      "background-color": "#fff",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.insertComplete(item, index)
                      },
                    },
                  },
                  [
                    _c(
                      "el-badge",
                      { staticClass: "item", attrs: { value: "" } },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "50px",
                            height: "50px",
                            "border-radius": "25px",
                            "margin-left": "30%",
                          },
                          attrs: { src: item.avatar, alt: "" },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "nickNameClass" }, [
                      _vm._v(
                        _vm._s(
                          item.nickname
                            ? item.nickname.length > 7
                              ? item.nickname.substring(0, 7) + "...."
                              : item.nickname
                            : item.phone
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "lastMsgClass" }, [
                      _vm._v(_vm._s(item.createTime)),
                    ]),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                staticStyle: { "text-align": "center" },
                attrs: {
                  "current-page": _vm.currentPage3,
                  "page-size": 8,
                  layout: "prev, next, jumper",
                  total: _vm.hisTotal,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    _vm.currentPage3 = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentPage3 = $event
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "500px", flex: "1", "min-width": "600px" } },
        [
          _c(
            "el-row",
            {
              staticStyle: { "line-height": "45px" },
              attrs: { type: "flex", justify: "end" },
            },
            [
              _c("el-col", { attrs: { span: 1 } }),
              _vm._v(" "),
              _c("el-col", [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "18px", "font-weight": "700" },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.baseInfoForm.nickname
                          ? _vm.baseInfoForm.nickname
                          : _vm.baseInfoForm.phone
                      )
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "infinite-list", staticStyle: { width: "100%" } },
            [
              _c(
                "div",
                {
                  class: { beginTalkingClass: _vm.isBegintalking },
                  staticStyle: {
                    height: "250px",
                    overflow: "auto",
                    padding: "10px",
                  },
                  attrs: { id: "bigDiv" },
                },
                [
                  _vm._l(_vm.chatItems, function (item) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.beginTalking,
                            expression: "!beginTalking",
                          },
                        ],
                        key: item.index,
                      },
                      [
                        item.content.msgtype === "timemark"
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-left": "40%",
                                  "font-size": "14px",
                                  color: "#bbb",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("turnTime")(
                                      item.content.msg,
                                      _vm.$data._this
                                    )
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (item.from == 3 || item.from == 2 || item.from == 1) &&
                        item.content.msgtype !== "timemark"
                          ? _c("div", { staticClass: "msg msg-right" }, [
                              _c(
                                "div",
                                { staticClass: "img-wrapper" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v("机器人"),
                                          _c("br"),
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("turnTime1")(
                                                item.createTime
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      item.from == 1
                                        ? _c("img", {
                                            staticClass: "photoImg",
                                            attrs: { src: _vm.from3 },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(_vm._s(item.kefuname)),
                                          _c("br"),
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("turnTime1")(
                                                item.createTime
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      item.from == 2 || item.from == 3
                                        ? _c("img", {
                                            staticClass: "photoImg",
                                            attrs: { src: _vm.from1 },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "message-wrapper message-wrapper-right",
                                  staticStyle: { "margin-bottom": "10px" },
                                },
                                [
                                  _c("div", {
                                    staticClass: "message",
                                    staticStyle: { "line-height": "20px" },
                                    domProps: {
                                      innerHTML: _vm._s(item.content.msg),
                                    },
                                  }),
                                ]
                              ),
                            ])
                          : _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.content.msgtype !== "timemark",
                                    expression:
                                      "item.content.msgtype !== 'timemark'",
                                  },
                                ],
                                staticClass: "msg msg-left",
                              },
                              [
                                _c("div", { staticClass: "img-wrapper" }, [
                                  _c("img", {
                                    staticClass: "photoImg",
                                    attrs: { src: _vm.from2 },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "message-wrapper message-wrapper-left",
                                    staticStyle: { "margin-bottom": "10px" },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "message",
                                      staticStyle: { "line-height": "20px" },
                                      domProps: {
                                        innerHTML: _vm._s(item.content.msg),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.beginTalking,
                          expression: "beginTalking",
                        },
                      ],
                      staticStyle: {
                        "margin-top": "200px",
                        "margin-left": "45%",
                        "font-size": "16px",
                      },
                    },
                    [_vm._v("暂无会话")]
                  ),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          !_vm.isTalking && !_vm.beginTalking
            ? _c("el-divider", { attrs: { contenteditable: "false" } }, [
                _c("span", [_vm._v("会话已结束")]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.groupDelete = groupDelete;
exports.groupEdit = groupEdit;
exports.groupInfo = groupInfo;
exports.groupList = groupList;
exports.groupSave = groupSave;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function groupDelete(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/system/group/delete',
    method: 'GET',
    params: data
  });
}
function groupInfo(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/system/group/info',
    method: 'GET',
    params: data
  });
}
function groupList(pram) {
  var data = {
    keywords: pram.keywords,
    page: pram.page,
    limit: pram.limit
  };
  return (0, _request.default)({
    url: '/admin/system/group/list',
    method: 'GET',
    params: data
  });
}
function groupSave(pram) {
  var data = {
    formId: pram.formId,
    info: pram.info,
    name: pram.name
  };
  return (0, _request.default)({
    url: '/admin/system/group/save',
    method: 'POST',
    params: data
  });
}
function groupEdit(pram) {
  var data = {
    formId: pram.formId,
    info: pram.info,
    name: pram.name,
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/system/group/update',
    method: 'POST',
    params: data
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _index = _interopRequireDefault(require("@/components/cards/index"));
var _ZBParser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/ZBParser"));
var _orderDetail = _interopRequireDefault(require("./orderDetail"));
var _gii = _interopRequireDefault(require("./gii"));
var _sendgoods = _interopRequireDefault(require("./sendgoods"));
var _invoiceInformation = _interopRequireDefault(require("./invoiceInformation"));
var _orderSend = _interopRequireDefault(require("./orderSend"));
var _storePoint = require("@/api/storePoint");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _utils = require("@/utils");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "orderlistDetails",
  components: {
    cardsData: _index.default,
    zbParser: _ZBParser.default,
    detailsFrom: _orderDetail.default,
    orderSend: _orderSend.default,
    invoiceInformation: _invoiceInformation.default,
    giiform: _gii.default,
    sendgoods: _sendgoods.default
  },
  filters: {
    strFilter: function strFilter(str) {
      return str.slice(0, 32);
    }
  },
  data: function data() {
    return {
      RefuseVisible: false,
      RefuseData: {},
      orderId: 0,
      refundVisible: false,
      refundData: {},
      dialogVisibleJI: false,
      tableDataLog: {
        data: [],
        total: 0
      },
      tableFromLog: {
        page: 1,
        limit: 10,
        oid: null
      },
      LogLoading: false,
      isCreate: 1,
      editData: {},
      dialogVisible: false,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        status: "all",
        dateLimit: "",
        orderId: "",
        page: 1,
        limit: 20
      },
      orderChartType: {},
      timeVal: [],
      fromList: this.$constants.fromList,
      selectionList: [],
      ids: "",
      orderids: "",
      cardLists: [],
      isWriteOff: (0, _utils.isWriteOff)(),
      editOrderRules: {
        orderId: [{
          required: true,
          message: "请输入订单编号",
          trigger: "blur"
        }],
        payPrice: [{
          required: true,
          message: "请输入实际支付金额",
          trigger: "blur"
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    sendFilter: function sendFilter(arr) {
      var i = arr.findIndex(function (res) {
        return res.deliveryId == null || res.deliveryCode == null;
      });
      if (i == -1) {
        return false;
      } else {
        return true;
      }
    },
    sendGoods: function sendGoods(id) {
      this.orderId = id;
      this.$refs.sendgoods.getDetail(id);
      this.$refs.sendgoods.getList();
      this.$refs.sendgoods.dialogVisible = true;
    },
    // 填写gii
    addGii: function addGii(id) {
      this.orderId = id;
      this.$refs.gii.getDetail(id);
      this.$refs.gii.dialogVisible = true;
    },
    resetFormRefundhandler: function resetFormRefundhandler() {
      this.refundVisible = false;
    },
    resetFormRefusehand: function resetFormRefusehand() {
      this.RefuseVisible = false;
    },
    resetForm: function resetForm(formValue) {
      this.dialogVisible = false;
    },
    // 核销订单
    onWriteOff: function onWriteOff(row) {
      var _this = this;
      this.$modalSure("核销订单吗").then(function () {
        (0, _order.writeUpdateApi)(row.verifyCode).then(function () {
          _this.$message.success("核销成功");
          _this.tableFrom.page = 1;
          _this.getList();
        });
      });
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    // 拒绝退款
    RefusehandleClose: function RefusehandleClose() {
      // this.resetForm()
      this.RefuseVisible = false;
    },
    onOrderRefuse: function onOrderRefuse(row) {
      this.orderids = row.id;
      this.RefuseData = {
        orderId: row.orderId,
        reason: ""
      };
      this.RefuseVisible = true;
    },
    RefusehandlerSubmit: function RefusehandlerSubmit(formValue) {
      var _this2 = this;
      (0, _order.orderRefuseApi)({
        id: this.orderids,
        reason: formValue.reason
      }).then(function (data) {
        _this2.$message.success("操作成功");
        _this2.RefuseVisible = false;
        _this2.getList();
      });
    },
    // 立即退款
    refundhandleClose: function refundhandleClose() {
      this.refundVisible = false;
    },
    onOrderRefund: function onOrderRefund(row) {
      this.refundData = {
        orderId: row.orderId,
        amount: row.payPrice,
        type: ""
      };
      this.orderids = row.id;
      this.refundVisible = true;
    },
    refundhandlerSubmit: function refundhandlerSubmit(formValue) {
      var _this3 = this;
      (0, _order.orderRefundApi)({
        amount: formValue.amount,
        orderId: this.orderids,
        type: formValue.type
      }).then(function (data) {
        _this3.$message.success("操作成功");
        _this3.refundVisible = false;
        _this3.getList();
      });
    },
    // 发送
    sendOrder: function sendOrder(row) {
      this.$refs.send.modals = true;
      this.$refs.send.getList();
      this.$refs.send.sheetInfo();
      this.orderId = row.id;
    },
    // 订单删除
    handleDelete: function handleDelete(row, idx) {
      var _this4 = this;
      if (row.isDel) {
        this.$modalSure().then(function () {
          (0, _order.orderDeleteApi)({
            id: row.id
          }).then(function () {
            _this4.$message.success("删除成功");
            _this4.tableData.data.splice(idx, 1);
            _this4.getList();
          });
        });
      } else {
        this.$confirm("您选择的的订单存在用户未删除的订单，无法删除用户未删除的订单！", "提示", {
          confirmButtonText: "确定",
          type: "error"
        });
      }
    },
    invoiceInformation: function invoiceInformation(id) {
      console.log(66666, id);
      this.$refs.invoiceData.getDetail(id);
      this.$refs.invoiceData.logVisible = true;
    },
    // 详情
    onOrderDetails: function onOrderDetails(id) {
      this.orderId = id;
      this.$refs.orderDetail.getDetail(id);
      this.$refs.orderDetail.dialogVisible = true;
    },
    // 订单记录
    onOrderLog: function onOrderLog(id) {
      var _this5 = this;
      this.dialogVisibleJI = true;
      this.LogLoading = true;
      this.tableFromLog.oid = id;
      (0, _order.orderLogApi)(this.tableFromLog).then(function (res) {
        _this5.tableDataLog.data = res.list;
        _this5.tableDataLog.total = res.total;
        _this5.LogLoading = false;
      }).catch(function () {
        _this5.LogLoading = false;
      });
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList();
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      // this.editData = {}
      // this.$refs.editOrder.resetForm()
      console.log(this.editData);
      this.dialogVisible = false;
    },
    // 备注
    onOrderMark: function onOrderMark(row) {
      var _this6 = this;
      this.$prompt("订单备注", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputErrorMessage: "请输入订单备注",
        inputType: "textarea",
        inputValue: row.remark,
        inputPlaceholder: "请输入订单备注",
        inputValidator: function inputValidator(value) {
          if (!value) return "输入不能为空";
        }
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _order.orderMarkApi)({
          mark: value,
          id: row.id
        }).then(function () {
          _this6.$message.success("操作成功");
          _this6.getList();
        });
      }).catch(function () {
        _this6.$message.info("取消输入");
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectionList = val;
      var data = [];
      this.selectionList.map(function (item) {
        data.push(item.id);
      });
      this.ids = data.join(",");
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(",") : "";
      this.tableFrom.page = 1;
      this.getList();
    },
    // 编辑
    edit: function edit(row) {
      this.dialogVisible = true;
      console.log(row, "1234570-");
      this.orderId = row.id;
      this.editData = {
        orderId: row.orderId,
        //订单ID
        totalPrice: row.totalPrice,
        // 商品总价
        totalPostage: row.totalPostage,
        //原始邮费
        payPrice: row.payPrice,
        //实际支付金额
        payPostage: row.payPostage // 实际支付邮费
      };
    },
    handlerSubmit: function handlerSubmit(formValue) {
      var _this7 = this;
      this.$refs.editOrderRef.validate(function (valid) {
        if (valid) {
          var params = {
            orderId: _this7.editData.orderId,
            price: _this7.editData.payPrice
          };
          (0, _order.orderUpdateApi)(params, {
            id: _this7.orderId
          }).then(function (data) {
            _this7.$message.success("编辑数据成功");
            _this7.dialogVisible = false;
            _this7.getList();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 列表
    getList: function getList() {
      var _this8 = this;
      this.listLoading = true;
      (0, _order.orderListApi)(this.tableFrom).then(function (res) {
        _this8.tableData.data = res.list.list || [];
        _this8.tableData.total = res.list.total;
        _this8.orderChartType = res.status;
        var stat = res.top;
        _this8.cardLists = [{
          name: "订单数量",
          count: stat.count
        }, {
          name: "订单金额",
          count: stat.amount
        }, {
          name: "微信支付金额",
          count: stat.weChatAmount
        }
        // { name: '余额支付金额', count: stat.yueAmount }
        ];
        _this8.listLoading = false;
      }).catch(function () {
        _this8.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/layout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; } /*
 * @Author: Zcl84765
 * @Date: 2022-07-22 11:09:24
 * @LastEditors: Zcl84765
 * @LastEditTime: 2023-02-01 10:51:07
 */ /** When your routing table is too long, you can split it into small modules **/
var maintainRouter = {
  path: "/maintain",
  component: _layout.default,
  redirect: "/maintain/devconfiguration/configCategory",
  name: "maintain",
  meta: {
    title: "维护",
    icon: "clipboard"
  },
  children: [{
    path: "devconfiguration",
    name: "devconfiguration",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/maintain"));
      });
    },
    meta: {
      title: "开发配置",
      icon: "clipboard"
    },
    children: [{
      path: "configCategory",
      name: "configCategory",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/maintain/devconfig/configCategroy"));
        });
      },
      meta: {
        title: "配置分类",
        icon: "clipboard"
      }
    }, {
      path: "combineddata",
      name: "combineddata",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/maintain/devconfig/combinedData"));
        });
      },
      meta: {
        title: "组合数据",
        icon: "clipboard"
      }
    }, {
      path: "formConfig",
      name: "formConfig",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/maintain/formConfig/index"));
        });
      },
      meta: {
        title: "表单配置",
        icon: "clipboard"
      }
    }]
  }, {
    path: "user",
    name: "user",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/maintain/user"));
      });
    },
    meta: {
      title: "个人中心",
      icon: "clipboard"
    },
    hidden: true
  }, {
    path: "picture",
    name: "picture",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/maintain/picture"));
      });
    },
    meta: {
      title: "素材管理",
      icon: "clipboard"
    },
    hidden: false
  }, {
    path: "searchKeyWord",
    name: "searchKeyWord",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/maintain/searchKeyword"));
      });
    },
    meta: {
      title: "搜索关键字",
      icon: "clipboard"
    },
    hidden: false
  }]
};
var _default = exports.default = maintainRouter;
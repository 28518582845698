"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteDraft = deleteDraft;
exports.getAdd = getAdd;
exports.getCouponDict = getCouponDict;
exports.getEventDictList = getEventDictList;
exports.getLabelDict = getLabelDict;
exports.getList = getList;
exports.getOperatorDict = getOperatorDict;
exports.getUserDict = getUserDict;
exports.groupList = groupList;
exports.updateStatus = updateStatus;
var _requestAuto = _interopRequireDefault(require("@/utils/requestAuto"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 自动化列表
 * @param pram
 */
function getList(data) {
  return (0, _requestAuto.default)({
    url: '/api/campaign/getList',
    method: 'post',
    data: data
  });
}

// 人群选择
function groupList() {
  return (0, _requestAuto.default)({
    url: '/api/group/getList',
    method: 'get'
  });
}

// 事件选择
function getEventDictList() {
  return (0, _requestAuto.default)({
    url: '/api/dict/getEventDict',
    method: 'get'
  });
}

//标签list
function getLabelDict() {
  return (0, _requestAuto.default)({
    url: '/api/dict/getLabelDict',
    method: 'get'
  });
}

//属性list
function getUserDict() {
  return (0, _requestAuto.default)({
    url: '/api/dict/getUserDict',
    method: 'get'
  });
}

//属性-大于小于
function getOperatorDict() {
  return (0, _requestAuto.default)({
    url: '/api/dict/getOperatorDict',
    method: 'get'
  });
}

// 优惠券

function getCouponDict() {
  return (0, _requestAuto.default)({
    url: '/api/dict/getCouponDict',
    method: 'get'
  });
}

// 优惠券

function getAdd(data) {
  return (0, _requestAuto.default)({
    url: '/api/campaign/add',
    method: 'post',
    data: data
  });
}
// export function getAdd() {
//   return request({
//     url: '/api/campaign/add',
//     method: 'post',
//   })

function updateStatus(params) {
  return (0, _requestAuto.default)({
    url: '/api/campaign/updateStatus',
    params: params
  });
}
function deleteDraft(params) {
  return (0, _requestAuto.default)({
    url: '/api/campaign/delete',
    params: params
  });
}
"use strict";

var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/*
 * @Author: Zcl84765
 * @Date: 2023-03-09 15:13:58
 * @LastEditors: Zcl84765
 * @LastEditTime: 2023-04-13 17:31:15
 */

_vue.default.directive("permissionExcel", {
  componentUpdated: function componentUpdated(el, binding) {
    var rowData = binding.value.actions;
    console.log("first", rowData);
    var JavaInfo = JSON.parse(_jsCookie.default.get("JavaInfo"));
    if (!rowData.includes(JavaInfo.id)) {
      el.parentNode.removeChild(el);
    }
  }
});
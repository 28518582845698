"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _createEnum = _interopRequireDefault(require("@/utils/create-enum"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var dayType = (0, _createEnum.default)({
  NDay: ["0", "每N天"]
  // WORKDay: ["1", "每个工作日(周一到周五)"],
});
var _default = exports.default = _defineProperty(_defineProperty({
  props: {
    dialogStart: {
      type: Boolean,
      default: true
    },
    groupList: {
      type: Array
    },
    getEventDict: {
      type: Array
    },
    form: {
      type: Object
    }
  },
  created: function created() {
    console.log(this.getEventDict, "getEventDict");
  },
  data: function data() {
    var validateEnd = function validateEnd(rule, value, callback) {
      // if (value === "" && this.form.taskCycle !== TASKCYCLE.ONCE) {
      //   callback(new Error("请选择结束时间"));
      // }
      if (value === "") {
        callback(new Error("请选择结束时间"));
      }
      callback();
    };
    return {
      monthList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
      dayType: dayType,
      title: "创建流程",
      timeRepeatList: [{
        name: "按天",
        id: "day"
      }, {
        name: "按周",
        id: "week"
      }, {
        name: "按月",
        id: "month"
      }, {
        name: "按年",
        id: "year"
      }],
      monthDays: [],
      weekDays: [],
      rules: {
        name: [{
          required: true,
          message: "请输入流程名称",
          trigger: "blur"
        }],
        from: [{
          required: true,
          message: "请选择开始时间",
          trigger: "change"
        }],
        isLimit: [{
          required: true,
          message: "请选择",
          trigger: "change"
        }],
        to: [{
          required: true,
          validator: validateEnd,
          trigger: "change"
        }],
        groupId: [{
          required: true,
          message: "请选择",
          trigger: "change"
        }],
        type: [{
          required: true,
          message: "请选择",
          trigger: "change"
        }],
        event: [{
          required: true,
          message: "请选择开始时间",
          trigger: "change"
        }],
        rule: [{
          required: true,
          message: "请选择",
          trigger: "change"
        }],
        assignTime: [{
          required: true,
          message: "请选择开始时间",
          trigger: "change"
        }],
        unit: [{
          required: true,
          message: "请选择",
          trigger: "change"
        }]
      }
    };
  },
  watch: {
    form: function form(old) {
      console.log(old, "old");
      if (old.weekDays) {
        this.weekDays = old.weekDays.split(",");
      }
      if (old.monthDays) {
        this.monthDays = old.monthDays.split(",");
      }
    }
  }
}, "created", function created() {
  console.log(this.form, "start 需要回显");
}), "methods", {
  handleChange: function handleChange() {
    console.log(this.form.unit, "unit");
  },
  handleCancel: function handleCancel() {
    this.$router.push("/marketing/automated/way");
    this.$emit("closeDialog", this.form);
  },
  submitForm: function submitForm(formName) {
    var _this = this;
    this.$refs[formName].validate(function (valid) {
      if (valid) {
        _this.form.monthDays = _this.monthDays.toString();
        _this.form.weekDays = _this.weekDays.toString();
        _this.$emit("closeDialog", _this.form);
        // alert("submit!");
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  },
  // 初始化数据 如果保存过就重置数据
  init: function init(params) {
    console.log(params, "开始");
    var initForm = {
      isLimit: 0,
      from: "",
      // 开始日期
      to: "",
      // 结束日期
      groupId: "",
      event: "",
      rule: "",
      type: "",
      unit: "day",
      dayNumber: "1",
      // 每XX天
      // weekDays: [], // 按周
      // monthDays: [], // 按月
      yearDays: "",
      // 按年
      triggerTime: "08:00:00" // 时间设置的具体时间
    };
    console.log(this.form, "initForminitForminitForm");
    this.form = _objectSpread(_objectSpread({}, initForm), params);
  }
});
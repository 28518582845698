"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemConfig = require("@/api/systemConfig");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ruleForm: {
        tips: ''
      },
      rules: {
        tips: [{
          required: true,
          message: "请输入满意度评价提示语",
          trigger: "blur"
        }]
      },
      num: "0"
    };
  },
  mounted: function mounted() {
    this.getRes();
  },
  methods: {
    getRes: function getRes() {
      var _this = this;
      (0, _systemConfig.configGetUniq)({
        key: "workspace_review_key"
      }).then(function (res) {
        _this.ruleForm.tips = res;
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      this.$refs.form.validate(function (valid) {
        if (!valid) {
          return;
        } else {
          var params = {
            key: "workspace_review_key",
            value: _this2.ruleForm.tips
          };
          (0, _systemConfig.configSaveUniq)(params).then(function (res) {
            // this.ruleForm.tips = '';
            _this2.num = 0;
            _this2.getRes();
            _this2.$message.success("保存成功");
          });
        }
      });
    },
    cancel: function cancel() {}
  },
  watch: {
    "ruleForm.tips": {
      handler: function handler(val, val1) {
        this.num = val.length;
      }
    }
  }
};
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Parser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/Parser"));
var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));
var _systemConfig = require("@/api/systemConfig.js");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Config',
  components: {
    parser: _Parser.default
  },
  data: function data() {
    return {
      // 表单
      formConf: {
        fields: []
      },
      formId: 74,
      formData: {},
      isCreate: 0,
      isShow: false
    };
  },
  created: function created() {
    var _this = this;
    systemFormConfigApi.getFormConfigInfo({
      id: this.formId
    }).then(function (data) {
      _this.formConf = JSON.parse(data.content);
    });
    this.getFormInfo();
  },
  methods: {
    submit: function submit(data) {
      var _this2 = this;
      var tempArr = [];
      for (var key in data) {
        var obj = {};
        obj.name = key;
        obj.title = key;
        obj.value = data[key];
        tempArr.push(obj);
      }
      var _pram = {
        'fields': tempArr,
        'id': this.formId,
        'sort': 0,
        'status': true
      };
      (0, _systemConfig.configSaveForm)(_pram).then(function (res) {
        _this2.getFormInfo();
        _this2.$message.success('操作成功');
      });
    },
    // 获取表单详情
    getFormInfo: function getFormInfo() {
      var _this3 = this;
      (0, _systemConfig.configInfo)({
        id: this.formId
      }).then(function (res) {
        _this3.isShow = false;
        _this3.formData = res;
        _this3.isCreate = 1;
        setTimeout(function () {
          // 让表单重复渲染待编辑数据
          _this3.isShow = true;
        }, 80);
      });
    }
  }
};
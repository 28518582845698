"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var valueAccountApi = _interopRequireWildcard(require("@/api/valueAccount.js"));
var _store = require("@/api/store");
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
var _edit = _interopRequireDefault(require("./edit"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "list",
  components: {
    edit: _edit.default
  },
  data: function data() {
    return {
      constants: this.$constants,
      listPram: {
        solutionName: "",
        page: 1,
        limit: this.$constants.page.limit[0]
      },
      listData: {
        list: [],
        total: 0
      },
      editDialogConfig: {
        visible: false,
        data: {},
        isEdit: 0 // 0=add 1=edit
      },
      merCateList: [],
      props2: {
        children: "child",
        label: "name",
        value: "id",
        multiple: false,
        emitPath: false
      }
    };
  },
  mounted: function mounted() {
    this.getCategorySelect();
    this.handlerGetListData(this.listPram);
  },
  methods: {
    getCategorySelect: function getCategorySelect() {
      var _this = this;
      (0, _store.categoryApi)({
        status: -1,
        type: 10
      }).then(function (res) {
        _this.merCateList = _this.filerMerCateList(res);
      });
    },
    filerMerCateList: function filerMerCateList(treeData) {
      // debugger

      return treeData.map(function (item) {
        // if (!item.child) {
        //   item.disabled = true;
        // }
        item.label = item.name;
        return item;
      });
    },
    onchangeIsShow: function onchangeIsShow(row, flag) {
      var _this2 = this;
      valueAccountApi.updateSolution({
        id: row.id,
        status: flag
      }).then(function (res) {
        _this2.$message.success("操作成功");
        _this2.handlerGetListData(_this2.listPram);
      });
    },
    // // 处理分类tag
    // fitterTag(arr) {
    // },
    handerSearch: function handerSearch() {
      this.listPram.page = 1;
      this.handlerGetListData(this.listPram);
    },
    handlerGetListData: function handlerGetListData(pram) {
      var _this3 = this;
      valueAccountApi.getSolutionList(pram).then(function (data) {
        _this3.listData = data;
      });
    },
    handlerOpenEdit: function handlerOpenEdit(isEdit, editData) {
      // 0=add 1=edit
      if (isEdit === 1) {
        this.editDialogConfig.isEdit = 1;
        this.editDialogConfig.editData = editData;
      } else {
        this.editDialogConfig.isEdit = 0;
      }
      this.editDialogConfig.visible = true;
    },
    handlerHideDialog: function handlerHideDialog() {
      this.handlerGetListData(this.listPram);
      this.editDialogConfig.visible = false;
    },
    handlerDelete: function handlerDelete(rowData) {
      var _this4 = this;
      this.$confirm("确定删除当前数据", "提示").then(function (result) {
        valueAccountApi.updateSolution({
          id: rowData.id,
          delete: 1
        }).then(function (data) {
          _this4.$message.success("删除数据成功");
          _this4.handlerGetListData(_this4.listPram);
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetListData(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetListData(this.listPram);
    }
  }
};
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var landingApi = _interopRequireWildcard(require("@/api/landing.js"));
var _pageLink = _interopRequireDefault(require("./pageLink.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var formDefault = {
  dept: "CSG",
  // banner: [],
  bannerList: [],
  offer: "",
  offerName: "",
  corpAuthList: [],
  product: "",
  classList: [{
    className: "",
    classPic: "",
    productList: []
  }, {
    className: "",
    classPic: "",
    productList: []
  }, {
    className: "",
    classPic: "",
    productList: []
  }],
  customerServiceEntrance: "",
  customerServiceQrCode: "",
  applicationScene: "",
  sceneList: [{
    pic: ""
  }, {
    pic: ""
  }, {
    pic: ""
  }, {
    pic: ""
  }],
  solution: "",
  solutionList: [],
  articleList: [],
  color: null //热卖专区active颜色配置
};
var _default = exports.default = {
  name: "landing",
  components: {
    PageLink: _pageLink.default
  },
  data: function data() {
    return {
      ruleForm: JSON.parse(JSON.stringify(formDefault)),
      rules: {},
      bannerModel: null,
      firmModel: {},
      rowModel: {
        appId: "",
        jumpPath: "",
        jumpStatus: false,
        jumpType: 0,
        pic: "",
        picName: ""
      },
      firmModelShow: false,
      solutionModelShow: false,
      solutionModel: {},
      rowsolutionModel: {
        appId: "",
        jumpPath: "",
        jumpStatus: false,
        jumpType: 0,
        pic: "",
        picName: "",
        tag: ""
      },
      infoLoading: false,
      saleType: 0,
      hotPrdModel: {
        pic: "",
        stayName: ""
      },
      hotPrdModelShow: false,
      pageLinkShow: false,
      pageLinkData: {}
    };
  },
  mounted: function mounted() {
    this.getInfo();
  },
  methods: {
    submitBannerRow: function submitBannerRow() {
      //校验数据
      if (this.bannerModel.pic && this.bannerModel.picName) {
        this.ruleForm.bannerList.push(JSON.parse(JSON.stringify(this.bannerModel)));
        this.bannerCancle();
      } else {
        this.$message.warning("请上传图片,并输入图片名称");
      }
    },
    bannerCancle: function bannerCancle() {
      this.bannerModel = null;
    },
    addBanner: function addBanner() {
      if (!this.bannerModel) {
        this.bannerModel = Object.assign({}, this.rowModel);
      }
    },
    delBanner: function delBanner(index) {
      var _this2 = this;
      this.$confirm("确认删除?", "提示").then(function () {
        _this2.ruleForm.bannerList.splice(index, 1);
      });
    },
    modalBannerPicTap: function modalBannerPicTap(index) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (index > -1) {
          _this.ruleForm.bannerList[index].pic = img[0].sattDir;
        } else {
          _this.bannerModel.pic = img[0].sattDir;
        }
      }, "1", "landing");
    },
    handleRemove: function handleRemove(i) {
      this.ruleForm.bannerList.splice(i, 1);
    },
    // 移动
    handleDragStart: function handleDragStart(e, item) {
      this.dragging = item;
    },
    handleDragEnd: function handleDragEnd(e, item) {
      this.dragging = null;
    },
    handleDragOver: function handleDragOver(e) {
      e.dataTransfer.dropEffect = "move";
    },
    handleDragEnter: function handleDragEnter(e, item) {
      e.dataTransfer.effectAllowed = "move";
      if (item === this.dragging) {
        return;
      }
      var newItems = _toConsumableArray(this.ruleForm.banner);
      var src = newItems.indexOf(this.dragging);
      var dst = newItems.indexOf(item);
      newItems.splice.apply(newItems, [dst, 0].concat(_toConsumableArray(newItems.splice(src, 1))));
      this.ruleForm.banner = newItems;
    },
    //
    changeLike: function changeLike() {
      var that = this;
      var list = [];
      that.$modalArticle(function (row) {
        console.log(row, "row");
        if (row.length > 3 || row.length + that.ruleForm.articleList.length > 3) {
          return that.$message.error("您最多可以选择三篇文章,请重新选择");
        } else {
          // debugger
          var ids = that.ruleForm.articleList.map(function (item) {
            return item.id;
          });
          row.forEach(function (item) {
            if (!ids.includes(item.id)) {
              that.ruleForm.articleList.push(item);
            }
          });
          // that.ruleForm.articleList = JSON.parse(JSON.stringify(row));
          // that.ruleForm.articleList = row.map((item) => {
          //   return {
          //     id: item.id,
          //     title: item.title,
          //   };
          // });
        }
      }, "many");
    },
    removeArticle: function removeArticle(index) {
      this.ruleForm.articleList.splice(index, 1);
    },
    initPageSet: function initPageSet() {
      this.firmModelShow = false;
      this.solutionModelShow = false;
      this.hotPrdModelShow = false;
      this.saleType = 0;
    },
    validPicName: function validPicName(rule, value, callback) {
      if (!value) {
        callback(new Error("请输入名称"));
      } else {
        if (value.length > 10) {
          callback(new Error("不超过10个字符"));
        } else {
          callback();
        }
      }
    },
    validTagName: function validTagName(rule, value, callback) {
      if (!value) {
        callback(new Error("请输入标签"));
      } else {
        if (value.length > 25) {
          callback(new Error("不超过25个字符"));
        } else {
          callback();
        }
      }
    },
    validHotClassName: function validHotClassName(rule, value, callback) {
      if (!value) {
        callback(new Error("请输入名称配置"));
      } else {
        if (/[\u4E00-\u9FA5]+/g.test(value)) {
          if (value.length > 7) {
            callback(new Error("汉字不超过7个字符"));
          } else {
            callback();
          }
        } else {
          if (value.length > 14) {
            callback(new Error("英文不超过14个字符"));
          } else {
            callback();
          }
        }
      }
    },
    validHotStayName: function validHotStayName(rule, value, callback) {
      if (!value) {
        callback(new Error("请输入留资名称"));
      } else {
        if (/[\u4E00-\u9FA5]+/g.test(value)) {
          if (value.length > 10) {
            callback(new Error("汉字不超过10个字符"));
          } else {
            callback();
          }
        } else {
          if (value.length > 20) {
            callback(new Error("英文不超过20个字符"));
          } else {
            callback();
          }
        }
      }
    },
    hotTypeSelVisual: function hotTypeSelVisual(flag) {
      var _this3 = this;
      if (flag) {
        this.$refs["ruleForm"].validate(function (valid, errors) {
          if (!valid) {
            _this3.$refs["hotTypeSel"].blur();
            _this3.$message.warning("请先将此分类配置完整");
          }
        });
      }
    },
    handleSetUrl: function handleSetUrl(keyName, keyIndex) {
      var _this4 = this;
      var pageData = {};
      if (keyIndex > -1 || keyName == "sceneList") {
        pageData = Object.assign({}, this.ruleForm[keyName][keyIndex]);
      } else {
        switch (keyName) {
          case "bannerList":
            pageData = Object.assign({}, this.bannerModel);
            break;
          case "corpAuthList":
            pageData = Object.assign({}, this.firmModel);
            break;
          case "solutionList":
            pageData = Object.assign({}, this.solutionModel);
            break;
        }
      }
      var _pageData = pageData,
        appId = _pageData.appId,
        jumpPath = _pageData.jumpPath,
        jumpStatus = _pageData.jumpStatus,
        jumpType = _pageData.jumpType;
      this.pageLinkData = {
        keyName: keyName,
        keyIndex: keyIndex,
        appId: appId,
        jumpPath: jumpPath,
        jumpStatus: jumpStatus,
        jumpType: jumpType
      };
      this.pageLinkShow = true;
      this.$nextTick(function () {
        _this4.$refs["pageLinkSet"].initEditData();
      });
    },
    updateUrlSet: function updateUrlSet(config) {
      var configNew = {
        appId: config.appId,
        jumpPath: config.jumpPath,
        jumpStatus: config.jumpStatus,
        jumpType: config.jumpType
      };
      if (config.keyIndex > -1 || config.keyName == "sceneList") {
        this.$set(this.ruleForm[config.keyName], config.keyIndex, Object.assign(this.ruleForm[config.keyName][config.keyIndex], configNew));
      } else {
        switch (config.keyName) {
          case "bannerList":
            this.bannerModel = Object.assign(this.bannerModel, configNew);
            break;
          case "corpAuthList":
            this.firmModel = Object.assign(this.firmModel, configNew);
            break;
          case "solutionList":
            this.solutionModel = Object.assign(this.solutionModel, configNew);
            break;
        }
      }
    },
    cancleSetUrl: function cancleSetUrl() {
      this.pageLinkData = {};
    },
    handleSubmit: function handleSubmit() {
      var _this5 = this;
      this.infoLoading = true;
      var param = JSON.parse(JSON.stringify(this.ruleForm));
      param.articleIdList = this.ruleForm.articleList.map(function (item) {
        return item.id;
      });
      delete param["articleList"];
      this.$refs["ruleForm"].validate(function (valid, errors) {
        if (valid) {
          landingApi.saveLanding(param).then(function () {
            _this5.$message.success("保存成功");
            _this5.infoLoading = false;
          }).catch(function () {
            _this5.infoLoading = false;
          });
        } else {
          _this5.infoLoading = false;
          for (var key in errors) {
            if (errors[key].length > 0) {
              _this5.$message.warning(errors[key][0].message);
              return false;
            }
          }
        }
      });
    },
    getInfo: function getInfo() {
      var _this6 = this;
      this.$refs["ruleForm"].clearValidate();
      this.initPageSet();
      this.infoLoading = true;
      landingApi.InfoLanding({
        dept: this.ruleForm.dept
      }).then(function (data) {
        var dataModel = data;
        dataModel.dept = _this6.ruleForm.dept;
        var defaultModel = JSON.parse(JSON.stringify(formDefault));
        _this6.ruleForm = Object.assign({}, defaultModel, dataModel);
        _this6.infoLoading = false;
      }).catch(function () {
        _this6.infoLoading = false;
      });
    },
    modalPicTap: function modalPicTap(field) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (field == 'banner') {
          if (img.length > 6) return this.$message.warning("最多选择6张图片！");
          if (img.length + _this.ruleForm.banner.length > 6) return this.$message.warning("最多选择6张图片！");
          img.map(function (item) {
            _this.ruleForm.banner.push(item.sattDir);
          });
        } else {
          _this.ruleForm[field] = img[0].sattDir;
        }
      }, field == 'banner' ? '2' : "1", "landing");
    },
    modalFirmPicTap: function modalFirmPicTap(index) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (index > -1) {
          _this.ruleForm.corpAuthList[index].pic = img[0].sattDir;
        } else {
          _this.firmModel.pic = img[0].sattDir;
        }
      }, "1", "landing");
    },
    modalSaleTap: function modalSaleTap(index) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (index > -1) {
          _this.ruleForm.classList[index].classPic = img[0].sattDir;
        }
      }, "1", "landing");
    },
    modalSolutionPicTap: function modalSolutionPicTap(index) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (index > -1) {
          _this.ruleForm.solutionList[index].pic = img[0].sattDir;
        } else {
          _this.solutionModel.pic = img[0].sattDir;
        }
      }, "1", "landing");
    },
    modalScenePicTap: function modalScenePicTap(index) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (index > -1) {
          _this.ruleForm.sceneList[index].pic = img[0].sattDir;
        }
      }, "1", "landing");
    },
    addFirm: function addFirm() {
      if (!this.firmModelShow) {
        this.firmModelShow = true;
        this.firmModel = Object.assign({}, this.rowModel);
      }
    },
    submitFirmRow: function submitFirmRow() {
      //校验数据
      if (this.firmModel.pic && this.firmModel.picName) {
        this.ruleForm.corpAuthList.push(JSON.parse(JSON.stringify(this.firmModel)));
        this.firmCancle();
      } else {
        this.$message.warning("请上传图片");
      }
    },
    firmCancle: function firmCancle() {
      this.firmModel = Object.assign({}, this.rowModel);
      this.firmModelShow = false;
    },
    delFirm: function delFirm(index) {
      var _this7 = this;
      this.$confirm("确认删除?", "提示").then(function () {
        _this7.ruleForm.corpAuthList.splice(index, 1);
      });
    },
    delScene: function delScene(index) {
      var _this8 = this;
      this.$confirm("确认清空?", "提示").then(function () {
        _this8.$set(_this8.ruleForm.sceneList, index, Object.assign({}, _this8.rowModel));
      });
      //   this.ruleForm.sceneList[index] = Object.assign({}, this.rowModel);
    },
    addSolution: function addSolution() {
      if (!this.solutionModelShow) {
        this.solutionModelShow = true;
        this.solutionModel = Object.assign({}, this.rowsolutionModel);
      }
    },
    submitSolutionRow: function submitSolutionRow() {
      //校验数据
      if (this.solutionModel.pic && this.solutionModel.picName && this.solutionModel.tag) {
        this.ruleForm.solutionList.push(JSON.parse(JSON.stringify(this.solutionModel)));
        this.solutionCancle();
      } else {
        this.$message.warning("请上传图片、填写图片名称和标签配置");
      }
    },
    solutionCancle: function solutionCancle() {
      this.solutionModel = Object.assign({}, this.rowModel);
      this.solutionModelShow = false;
    },
    delSolution: function delSolution(index) {
      var _this9 = this;
      this.$confirm("确认删除?", "提示").then(function () {
        _this9.ruleForm.solutionList.splice(index, 1);
      });
    },
    addHotPrd: function addHotPrd() {
      if (!this.hotPrdModelShow) {
        this.hotPrdModelShow = true;
        this.hotPrdModel = {
          pic: "",
          stayName: ""
        };
      }
    },
    submitHotPrd: function submitHotPrd(index) {
      //校验数据
      if (this.hotPrdModel.pic && this.hotPrdModel.stayName) {
        this.ruleForm.classList[index].productList.push(JSON.parse(JSON.stringify(this.hotPrdModel)));
        this.hotPrdCancle();
      } else {
        this.$message.warning("请填写完整");
      }
    },
    hotPrdCancle: function hotPrdCancle() {
      this.hotPrdModel = {
        pic: "",
        stayName: ""
      };
      this.hotPrdModelShow = false;
    },
    delHotPrd: function delHotPrd(index, prdIndex) {
      var _this10 = this;
      this.$confirm("确认删除?", "提示").then(function () {
        _this10.ruleForm.classList[index].productList.splice(prdIndex, 1);
      });
    },
    modalHotPrdPicTap: function modalHotPrdPicTap(index, prdIndex) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (index > -1) {
          _this.ruleForm.classList[index].productList[prdIndex].pic = img[0].sattDir;
        } else {
          _this.hotPrdModel.pic = img[0].sattDir;
        }
      }, "1", "landing");
    }
  }
};
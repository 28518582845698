"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));
var _workSpace = require("@/api/workSpace");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tableData: [],
      totalPage: 0,
      PageSize: 10,
      currentPage: 1,
      addOrUp: 0,
      formInline: {
        searchContent: ""
      },
      loading: false,
      findContent: "",
      disabledGoods: true,
      contentDialog: false,
      editPram: {
        cid: []
      },
      treeList: [],
      categoryProps: {
        value: "id",
        label: "name",
        children: "child",
        expandTrigger: "hover",
        checkStrictly: true,
        emitPath: false
      },
      rules: {
        cid: [{
          required: true,
          message: "请选择分类",
          trigger: "blur"
        }],
        content: [{
          required: true,
          message: "请输入文档内容",
          trigger: "blur"
        }]
        // title: [{ required: true, message: "请输入文档标题", trigger: "blur" }],
      },
      dialogTilte: "",
      disgusNum: "",
      //1新增 2修改
      classifyId: ""
    };
  },
  mounted: function mounted() {
    var _this = this;
    var _pram = {
      type: 8,
      status: -1
    };
    categoryApi.treeCategroy(_pram).then(function (data) {
      _this.treeList = data;
      _this.treeList.map(function (item) {
        item.disabled = true;
      });
    });
    this.getAllList();
  },
  computed: {},
  methods: {
    searchGoods: function searchGoods() {
      this.getAllList();
    },
    getAllList: function getAllList() {
      var _this2 = this;
      var param = {
        limit: this.PageSize,
        page: this.currentPage,
        keywords: this.formInline.searchContent
      };
      (0, _workSpace.getContentList)(param).then(function (res) {
        _this2.tableData = res.list;
      });
    },
    getCheckedNodes: function getCheckedNodes(val) {
      try {
        for (var i in this.getCascaderObj(val, this.treeList)) {
          if (this.getCascaderObj(val, this.treeList)[i]) {
            var arr = this.getCascaderObj(val, this.treeList)[i];
          }
        }
        this.classifyId = arr.pid + "," + arr.id;
      } catch (_unused) {}
      console.log(val, arr, this.classifyId, "ssss");
    },
    getCascaderObj: function getCascaderObj(val, opt) {
      // opt 所有的数组 val 选中项的节点id
      return opt.map(function (item) {
        for (var i = 0; i < item.child.length; i++) {
          if (item.child[i].id == val) {
            return item.child[i];
          }
        }
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this3 = this;
      var param = {
        id: row.id,
        status: row.status
      };
      (0, _workSpace.updateStatus)(param).then(function () {
        _this3.$message.success("修改成功");
        _this3.getAllList();
      }).catch(function () {
        row.status = !row.status;
      });
    },
    addContents: function addContents() {
      var _this4 = this;
      this.disgusNum = 1;
      this.dialogTilte = "新增内容";
      this.contentDialog = true;
      this.editPram = {};
      var _pram = {
        type: 8,
        status: -1,
        name: '',
        pid: 0
      };
      categoryApi.treeCategroy(_pram).then(function (data) {
        _this4.treeList = data;
        console.log(_this4.treeList, '1111');
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.PageSize = val;
      this.currentPage = 1;
      this.getAllList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage = val;
      this.getAllList();
    },
    sureAddContent: function sureAddContent() {
      var _this5 = this;
      console.log(this.classifyId, "this.classifyId");
      var param = {
        adminId: this.$store.getters.scoketUserId,
        cid: this.classifyId ? this.classifyId : this.editPram.cid.join(","),
        content: this.editPram.content,
        sort: 0,
        status: 1,
        title: this.editPram.title,
        id: this.editPram.id
      };
      if (this.disgusNum == 1) {
        (0, _workSpace.saveContent)(param).then(function (res) {
          _this5.$message.success("添加成功");
        });
      } else {
        (0, _workSpace.updateContent)(param).then(function (res) {
          _this5.$message.success("修改成功");
        });
      }
      this.getAllList();
      this.contentDialog = false;
    },
    handleEdit: function handleEdit(val) {
      this.dialogTilte = "修改内容";
      this.contentDialog = true;
      this.editPram = val;
      // console.log(val, );
      var arr = JSON.parse(JSON.stringify(val.cid)).split(",");
      var arr1 = [];
      arr.map(function (item) {
        item = parseInt(item);
        arr1.push(item);
        console.log(item, "tttt");
      });
      this.editPram.cid = arr1;
    },
    handleDelete: function handleDelete(val) {
      var _this6 = this;
      (0, _workSpace.deleteContent)({
        id: val.id
      }).then(function (res) {
        _this6.$message.success("删除成功");
        _this6.getAllList();
      });
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _settingMer = _interopRequireDefault(require("@/utils/settingMer"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      previewUrl: ""
    };
  },
  watch: {
    "$route.query.id": {
      immediate: true,
      // 一开始的数据也要当做一种变化
      handler: function handler(newVal, oldVal) {
        if (newVal) {
          var rid = newVal;
          this.previewUrl = process.env.VUE_APP_PREVIEW + "/static/html/pc.html#/";
          setTimeout(function () {
            document.getElementById("preview").contentWindow.postMessage(rid, process.env.VUE_APP_PREVIEW + "/static/html/pc.html#/");
          }, 1000);
        }
      }
    }
    // $route: {
    //   handler() {
    //     let rid = this.$route.query.id;
    //     var iframe = document.getElementById("preview");
    //     if (iframe.attachEvent) {
    //       iframe.attachEvent("onload", function () {
    //         //iframe加载完成后你需要进行的操作
    //         document
    //           .getElementById("preview")
    //           .contentWindow.postMessage(
    //             rid,
    //             "http://localhost:8080/static/html/pc.html#/"
    //           );
    //       });
    //     } else {
    //       iframe.onload = function () {
    //         //iframe加载完成后你需要进行的操作
    //         document
    //           .getElementById("preview")
    //           .contentWindow.postMessage(
    //             rid,
    //             "http://localhost:8080/static/html/pc.html#/"
    //           );
    //       };
    //     }
    //     //深度监听，同时也可监听到param参数变化
    //   },
    //   deep: true,
    // },
  },
  // mounted() {
  //   // console.log(111111111111,window.postMessage)
  //   // window.name = this.$route.query.id
  //   let rid = this.$route.query.id;
  //   console.log(1231231, rid);
  //   // console.log(3333,window)
  //   // setTimeout(() => {
  //   //   document
  //   //     .getElementById("preview")
  //   //     .contentWindow.postMessage(
  //   //       rid,
  //   //       "http://localhost:8081/static/html/pc.html#/"
  //   //     );
  //   // }, 1000);
  //   var iframe = document.getElementById("preview");
  //   if (iframe.attachEvent) {
  //     iframe.attachEvent("onload", function () {
  //       //iframe加载完成后你需要进行的操作
  //       document
  //         .getElementById("preview")
  //         .contentWindow.postMessage(
  //           rid,
  //           "http://localhost:8080/static/html/pc.html#/"
  //         );
  //     });
  //   } else {
  //     iframe.onload = function () {
  //       //iframe加载完成后你需要进行的操作
  //       document
  //         .getElementById("preview")
  //         .contentWindow.postMessage(
  //           rid,
  //           "http://localhost:8080/static/html/pc.html#/"
  //         );
  //     };
  //   }
  //   // this.previewUrl = 'https://workstation.chinaeast2.cloudapp.chinacloudapi.cn:8222/static/html/pc.html#/'
  //   // window.frames[0].postMessage('jogging, reading and writing','https://workstation.chinaeast2.cloudapp.chinacloudapi.cn:8222');
  // },
  methods: {
    goclick: function goclick() {}
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.orderDatalist
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "订单信息",
                visible: _vm.dialogVisible,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "description",
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("用户信息")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "acea-row" }, [
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        "\n          用户昵称：" +
                          _vm._s(
                            _vm.orderDatalist.user
                              ? _vm.orderDatalist.user.nickname
                              : _vm.orderDatalist.realName
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        "\n          绑定电话：" +
                          _vm._s(
                            _vm.orderDatalist.user.phone
                              ? _vm.orderDatalist.user.phone
                              : "无"
                          ) +
                          "\n        "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.orderDatalist.statusStr.key === "toBeWrittenOff"
                            ? "提货信息"
                            : "收货信息"
                        ) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "acea-row" }, [
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.orderDatalist.statusStr.key === "toBeWrittenOff"
                              ? "提货人"
                              : "收货人"
                          ) +
                          "：" +
                          _vm._s(_vm.orderDatalist.realName) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.orderDatalist.statusStr.key === "toBeWrittenOff"
                              ? "提货电话"
                              : "收货电话"
                          ) +
                          "：" +
                          _vm._s(_vm.orderDatalist.userPhone) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.orderDatalist.statusStr.key !== "toBeWrittenOff"
                      ? _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.orderDatalist.statusStr.key ===
                                  "toBeWrittenOff"
                                  ? "提货地址"
                                  : "收货地址"
                              ) +
                              "：" +
                              _vm._s(_vm.orderDatalist.userAddress) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("商品信息")]),
                  _vm._v(" "),
                  _vm._l(_vm.orderDatalist.orderInfo, function (item) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "goods_info" },
                      [
                        _c("div", { staticClass: "goods_info_left" }, [
                          _c("div", { staticClass: "goods_info_image" }, [
                            _c("img", {
                              attrs: { src: item.info.productInfo.image },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "goods_info_right" }, [
                          _c(
                            "div",
                            { staticClass: "goods_main" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: item.info.productInfo.storeName,
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm._f("strFilter")(
                                            item.info.productInfo.storeName
                                          )
                                        )
                                    ),
                                    _c("span", { staticClass: "goodstype" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("goodsFilter")(item.info.type)
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "规格：" +
                                    _vm._s(item.info.productInfo.attrInfo.suk)
                                ),
                              ]),
                              _vm._v(" "),
                              item.info.productInfo.attrInfo.presents
                                ? _c(
                                    "div",
                                    [
                                      _vm._v(
                                        "\n              赠品：\n              "
                                      ),
                                      _vm._l(
                                        item.info.productInfo.attrInfo.presents,
                                        function (item1) {
                                          return _c("span", { key: item1.id }, [
                                            _vm._v(
                                              _vm._s(item1.name) +
                                                " |\n              "
                                            ),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.info.productInfo.price) +
                                " × " +
                                _vm._s(item.info.cartNum) +
                                "\n          "
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("订单信息")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "acea-row" },
                    [
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "\n          订单编号：" +
                            _vm._s(_vm.orderDatalist.orderId) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      (_vm.orderDatalist.statusStr.key == "unConfirmed" ||
                        _vm.orderDatalist.statusStr.key == "inProduction" ||
                        _vm.orderDatalist.statusStr.key == "notShipped" ||
                        _vm.orderDatalist.statusStr.key == "spike" ||
                        _vm.orderDatalist.statusStr.key == "bargain" ||
                        _vm.orderDatalist.statusStr.key == "complete" ||
                        _vm.orderDatalist.statusStr.key == "complete") &&
                      _vm.orderDatalist.gainIntegral > 0
                        ? _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "\n          积分：已获得" +
                                _vm._s(_vm.orderDatalist.gainIntegral) +
                                "点积分\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "description-term",
                          staticStyle: { color: "red" },
                        },
                        [
                          _vm._v(
                            "\n          订单状态：" +
                              _vm._s(_vm.orderDatalist.statusStr.value) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "\n          商品总数：" +
                            _vm._s(_vm.orderDatalist.totalNum) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "\n          商品总价：" +
                            _vm._s(_vm.orderDatalist.totalPrice) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "\n          交付邮费：" +
                            _vm._s(_vm.orderDatalist.payPostage) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "\n          优惠券金额：" +
                            _vm._s(_vm.orderDatalist.couponPrice) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "\n          实际支付：" +
                            _vm._s(_vm.orderDatalist.payPrice) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.orderDatalist.refundPrice
                        ? _c(
                            "div",
                            { staticClass: "description-term fontColor3" },
                            [
                              _vm._v(
                                "\n          退款金额：" +
                                  _vm._s(_vm.orderDatalist.refundPrice) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.orderDatalist.useIntegral
                        ? _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "\n          使用积分：" +
                                _vm._s(_vm.orderDatalist.useIntegral) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.orderDatalist.backIntegral
                        ? _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "\n          退回积分：" +
                                _vm._s(_vm.orderDatalist.backIntegral) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "\n          创建时间：" +
                            _vm._s(_vm.orderDatalist.createTime) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.orderDatalist.shippingType === 2 &&
                      _vm.orderDatalist.statusStr.key === "notShipped"
                        ? _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "\n          门店名称：" +
                                _vm._s(_vm.orderDatalist.storeName) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.orderDatalist.shippingType === 2 &&
                      _vm.orderDatalist.statusStr.key === "notShipped"
                        ? _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "\n          核销码：" +
                                _vm._s(_vm.orderDatalist.user_phone) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "\n          商家备注：" +
                            _vm._s(_vm.orderDatalist.remark) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.orderDatalist.statusStr.key === "toBeWrittenOff" &&
                      _vm.orderDatalist.systemStore
                        ? [
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            提货码：" +
                                  _vm._s(_vm.orderDatalist.verifyCode) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            门店名称：" +
                                  _vm._s(_vm.orderDatalist.systemStore.name) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            门店电话：" +
                                  _vm._s(_vm.orderDatalist.systemStore.phone) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            门店地址：" +
                                  _vm._s(
                                    _vm.orderDatalist.systemStore.address +
                                      _vm.orderDatalist.systemStore
                                        .detailedAddress
                                  ) +
                                  "\n          "
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.orderDatalist.statusStr.key === "spike" ||
                  _vm.orderDatalist.statusStr.key === "complete" ||
                  _vm.orderDatalist.statusStr.key === "bargain"
                    ? [
                        _c("el-divider"),
                        _vm._v(" "),
                        _c("div", { staticClass: "title" }, [
                          _vm._v("物流信息"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.productList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "acea-row" },
                            [
                              item.deliveryCode && item.deliveryId
                                ? _c("div", { staticClass: "goods_item" }, [
                                    _c("div", { staticClass: "goods_msg" }, [
                                      _c(
                                        "div",
                                        { staticClass: "goods_image" },
                                        [
                                          _c("el-image", {
                                            attrs: {
                                              src: item.image,
                                              "preview-src-list": [item.image],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "goods_name" },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: item.storeName,
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("strFilter")(
                                                      item.storeName
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(item.suk ? item.suk : "-")
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _vm._l(
                                            item.presents,
                                            function (item1) {
                                              return _c(
                                                "span",
                                                { key: item1.id },
                                                [
                                                  _vm._v(
                                                    _vm._s(item1.name) +
                                                      " |\n                "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(item.price) +
                                                " x " +
                                                _vm._s(item.cartNum)
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "goods_gii" }, [
                                      _c("div", [
                                        _vm._v(
                                          "GII编码: " + _vm._s(item.giiCode)
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "goods_sends" }, [
                                      _vm._v("已发货"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "goods_sends" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openLogistics(item)
                                              },
                                            },
                                          },
                                          [_vm._v("查询")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderDatalist.deliveryType === "send"
                    ? [
                        _c("el-divider"),
                        _vm._v(" "),
                        _c("div", { staticClass: "title" }, [
                          _vm._v("配送信息"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "acea-row" }, [
                          _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "\n            送货人姓名：" +
                                _vm._s(_vm.orderDatalist.deliveryName) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "\n            送货人电话：" +
                                _vm._s(_vm.orderDatalist.deliveryId) +
                                "\n          "
                            ),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderDatalist.mark
                    ? [
                        _c("el-divider"),
                        _vm._v(" "),
                        _c("div", { staticClass: "title" }, [
                          _vm._v("用户备注"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "acea-row" }, [
                          _c("div", { staticClass: "description-term" }, [
                            _vm._v(_vm._s(_vm.orderDatalist.mark)),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.orderDatalist
        ? _c(
            "el-dialog",
            {
              attrs: { title: "提示", visible: _vm.modal2, width: "30%" },
              on: {
                "update:visible": function ($event) {
                  _vm.modal2 = $event
                },
              },
            },
            [
              _c("div", { staticClass: "logistics acea-row row-top" }, [
                _c("div", { staticClass: "logistics_img" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/imgs/expressi.jpg") },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "logistics_cent" }, [
                  _c("span", { staticClass: "mb10" }, [
                    _vm._v("物流公司：" + _vm._s(_vm.result.expName)),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("物流单号：" + _vm._s(_vm.result.number)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "acea-row row-column-around trees-coadd" },
                [
                  _c(
                    "div",
                    { staticClass: "scollhide" },
                    [
                      _c(
                        "el-timeline",
                        { attrs: { reverse: _vm.reverse } },
                        _vm._l(_vm.result.list, function (item, i) {
                          return _c("el-timeline-item", { key: i }, [
                            _c("p", {
                              staticClass: "time",
                              domProps: { textContent: _vm._s(item.time) },
                            }),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "content",
                              domProps: { textContent: _vm._s(item.status) },
                            }),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.modal2 = false
                        },
                      },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _util = require("util");
var _TreeNodeDialog = _interopRequireDefault(require("./TreeNodeDialog"));
var _index = require("../utils/index");
var _IconsDialog = _interopRequireDefault(require("./IconsDialog"));
var _config = require("@/components/FormGenerator/components/generator/config");
var _db = require("../utils/db");
var _index2 = _interopRequireDefault(require("../../../views/appSetting/wxAccount/wxTemplate/index"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var dateTimeFormat = {
  date: 'yyyy-MM-dd',
  week: 'yyyy 第 WW 周',
  month: 'yyyy-MM',
  year: 'yyyy',
  datetime: 'yyyy-MM-dd HH:mm:ss',
  daterange: 'yyyy-MM-dd',
  monthrange: 'yyyy-MM',
  datetimerange: 'yyyy-MM-dd HH:mm:ss'
};
var _default = exports.default = {
  components: {
    Templates: _index2.default,
    TreeNodeDialog: _TreeNodeDialog.default,
    IconsDialog: _IconsDialog.default
  },
  props: ['showField', 'activeData', 'formConf'],
  data: function data() {
    return {
      currentTab: 'field',
      currentNode: null,
      dialogVisible: false,
      iconsVisible: false,
      currentIconModel: null,
      dateTypeOptions: [{
        label: '日(date)',
        value: 'date'
      }, {
        label: '周(week)',
        value: 'week'
      }, {
        label: '月(month)',
        value: 'month'
      }, {
        label: '年(year)',
        value: 'year'
      }, {
        label: '日期时间(datetime)',
        value: 'datetime'
      }],
      dateRangeTypeOptions: [{
        label: '日期范围(daterange)',
        value: 'daterange'
      }, {
        label: '月范围(monthrange)',
        value: 'monthrange'
      }, {
        label: '日期时间范围(datetimerange)',
        value: 'datetimerange'
      }],
      colorFormatOptions: [{
        label: 'hex',
        value: 'hex'
      }, {
        label: 'rgb',
        value: 'rgb'
      }, {
        label: 'rgba',
        value: 'rgba'
      }, {
        label: 'hsv',
        value: 'hsv'
      }, {
        label: 'hsl',
        value: 'hsl'
      }],
      justifyOptions: [{
        label: 'start',
        value: 'start'
      }, {
        label: 'end',
        value: 'end'
      }, {
        label: 'center',
        value: 'center'
      }, {
        label: 'space-around',
        value: 'space-around'
      }, {
        label: 'space-between',
        value: 'space-between'
      }],
      layoutTreeProps: {
        label: function label(data, node) {
          var config = data.__config__;
          return data.componentName || "".concat(config.label, ": ").concat(data.__vModel__);
        }
      }
    };
  },
  computed: {
    // documentLink() {
    //   return (
    //     this.activeData.__config__.document
    //     || 'https://element.eleme.cn/#/zh-CN/component/installation'
    //   )
    // },
    dateOptions: function dateOptions() {
      if (this.activeData.type !== undefined && this.activeData.__config__.tag === 'el-date-picker') {
        if (this.activeData['start-placeholder'] === undefined) {
          return this.dateTypeOptions;
        }
        return this.dateRangeTypeOptions;
      }
      return [];
    },
    tagList: function tagList() {
      return [{
        label: '输入型组件',
        options: _config.inputComponents
      }, {
        label: '选择型组件',
        options: _config.selectComponents
      }];
    },
    activeTag: function activeTag() {
      return this.activeData.__config__.tag;
    },
    isShowMin: function isShowMin() {
      return ['el-input-number', 'el-slider'].indexOf(this.activeTag) > -1;
    },
    isShowMax: function isShowMax() {
      return ['el-input-number', 'el-slider', 'el-rate'].indexOf(this.activeTag) > -1;
    },
    isShowStep: function isShowStep() {
      return ['el-input-number', 'el-slider'].indexOf(this.activeTag) > -1;
    }
  },
  watch: {
    formConf: {
      handler: function handler(val) {
        (0, _db.saveFormConf)(val);
      },
      deep: true
    }
  },
  mounted: function mounted() {
    (0, _db.saveFormConf)(this.formConf);
  },
  methods: {
    addReg: function addReg() {
      this.activeData.__config__.regList.push({
        pattern: '',
        message: ''
      });
    },
    addSelectItem: function addSelectItem() {
      this.activeData.__slot__.options.push({
        label: '',
        value: ''
      });
    },
    addTreeItem: function addTreeItem() {
      ++this.idGlobal;
      this.dialogVisible = true;
      this.currentNode = this.activeData.options;
    },
    renderContent: function renderContent(h, _ref) {
      var _this = this;
      var node = _ref.node,
        data = _ref.data,
        store = _ref.store;
      return h("div", {
        "class": 'custom-tree-node'
      }, [h("span", [node.label]), h("span", {
        "class": 'node-operation'
      }, [h("i", {
        "on": {
          "click": function click() {
            return _this.append(data);
          }
        },
        "class": 'el-icon-plus',
        "attrs": {
          "title": '添加'
        }
      }), h("i", {
        "on": {
          "click": function click() {
            return _this.remove(node, data);
          }
        },
        "class": 'el-icon-delete',
        "attrs": {
          "title": '删除'
        }
      })])]);
    },
    append: function append(data) {
      if (!data.children) {
        this.$set(data, 'children', []);
      }
      this.dialogVisible = true;
      this.currentNode = data.children;
    },
    remove: function remove(node, data) {
      this.activeData.__config__.defaultValue = []; // 避免删除时报错
      var parent = node.parent;
      var children = parent.data.children || parent.data;
      var index = children.findIndex(function (d) {
        return d.id === data.id;
      });
      children.splice(index, 1);
    },
    addNode: function addNode(data) {
      this.currentNode.push(data);
    },
    setOptionValue: function setOptionValue(item, val) {
      item.value = (0, _index.isNumberStr)(val) ? +val : val;
    },
    setDefaultValue: function setDefaultValue(val) {
      if (Array.isArray(val)) {
        return val.join(',');
      }
      // if (['string', 'number'].indexOf(typeof val) > -1) {
      //   return val
      // }
      if (typeof val === 'boolean') {
        return "".concat(val);
      }
      return val;
    },
    onDefaultValueInput: function onDefaultValueInput(str) {
      if ((0, _util.isArray)(this.activeData.__config__.defaultValue)) {
        // 数组
        this.$set(this.activeData.__config__, 'defaultValue', str.split(',').map(function (val) {
          return (0, _index.isNumberStr)(val) ? +val : val;
        }));
      } else if (['true', 'false'].indexOf(str) > -1) {
        // 布尔
        this.$set(this.activeData.__config__, 'defaultValue', JSON.parse(str));
      } else {
        // 字符串和数字
        this.$set(this.activeData.__config__, 'defaultValue', (0, _index.isNumberStr)(str) ? +str : str);
      }
    },
    onSwitchValueInput: function onSwitchValueInput(val, name) {
      if (['true', 'false'].indexOf(val) > -1) {
        this.$set(this.activeData, name, JSON.parse(val));
      } else {
        this.$set(this.activeData, name, (0, _index.isNumberStr)(val) ? +val : val);
      }
    },
    setTimeValue: function setTimeValue(val, type) {
      var valueFormat = type === 'week' ? dateTimeFormat.date : val;
      this.$set(this.activeData.__config__, 'defaultValue', null);
      this.$set(this.activeData, 'value-format', valueFormat);
      this.$set(this.activeData, 'format', val);
    },
    spanChange: function spanChange(val) {
      this.formConf.span = val;
    },
    multipleChange: function multipleChange(val) {
      this.$set(this.activeData.__config__, 'defaultValue', val ? [] : '');
    },
    dateTypeChange: function dateTypeChange(val) {
      this.setTimeValue(dateTimeFormat[val], val);
    },
    rangeChange: function rangeChange(val) {
      this.$set(this.activeData.__config__, 'defaultValue', val ? [this.activeData.min, this.activeData.max] : this.activeData.min);
    },
    rateTextChange: function rateTextChange(val) {
      if (val) this.activeData['show-score'] = false;
    },
    rateScoreChange: function rateScoreChange(val) {
      if (val) this.activeData['show-text'] = false;
    },
    colorFormatChange: function colorFormatChange(val) {
      this.activeData.__config__.defaultValue = null;
      this.activeData['show-alpha'] = val.indexOf('a') > -1;
      this.activeData.__config__.renderKey = +new Date(); // 更新renderKey,重新渲染该组件
    },
    openIconsDialog: function openIconsDialog(model) {
      this.iconsVisible = true;
      this.currentIconModel = model;
    },
    setIcon: function setIcon(val) {
      this.activeData[this.currentIconModel] = val;
    },
    tagChange: function tagChange(tagIcon) {
      var target = _config.inputComponents.find(function (item) {
        return item.__config__.tagIcon === tagIcon;
      });
      if (!target) target = _config.selectComponents.find(function (item) {
        return item.__config__.tagIcon === tagIcon;
      });
      this.$emit('tag-change', target);
    },
    changeRenderKey: function changeRenderKey() {
      this.activeData.__config__.renderKey = +new Date();
    }
  }
};
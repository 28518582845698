var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: "", size: "small" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "买赠状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: { clearable: "", placeholder: "状态" },
                              on: { change: _vm.handlerGetList },
                              model: {
                                value: _vm.tableFrom.isOpen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "isOpen", $$v)
                                },
                                expression: "tableFrom.isOpen",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: "0", label: "启用" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: "1", label: "停用" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "买赠名称：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入名称",
                                size: "small",
                                clearable: "",
                              },
                              on: { change: _vm.handlerGetList },
                              model: {
                                value: _vm.tableFrom.buyFreeName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "buyFreeName", $$v)
                                },
                                expression: "tableFrom.buyFreeName",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: { click: _vm.handlerGetList },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "买赠方式：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "方式", clearable: "" },
                              on: { change: _vm.handlerGetList },
                              model: {
                                value: _vm.tableFrom.buyFreeWay,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "buyFreeWay", $$v)
                                },
                                expression: "tableFrom.buyFreeWay",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: "0", label: "按最高层赠送" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: "1", label: "按累计赠送" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.addPop },
                },
                [_vm._v("添加买赠")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.handleDelete } },
                [_vm._v("批量删除")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
                "row-key": _vm.getRowKey,
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "55",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "buyFreeName",
                  label: "名称",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "买赠方式", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.buyGiveWay == 0
                                  ? "最高层赠送"
                                  : "累计赠送"
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动时间", "min-width": "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            从" + _vm._s(scope.row.createTime)
                          ),
                          _c("br"),
                          _vm._v(
                            "至" + _vm._s(scope.row.endTime) + "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "适用人群", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.applicablePeople == 0
                                  ? "全部人群"
                                  : "会员"
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "quota",
                  label: "每人最多参与",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "是否开启", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 0,
                            "inactive-value": 1,
                            "active-text": "开启",
                            "inactive-text": "关闭",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onchangeIsShow(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.isOpen,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "isOpen", $$v)
                            },
                            expression: "scope.row.isOpen",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "120", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleReset(
                                  scope.row.id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableFrom.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogFormVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.resetForm,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleFormV",
              staticClass: "dialog-from",
              attrs: {
                model: _vm.ruleForm,
                "label-width": "150px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "买赠名称", prop: "buyFreeName" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.ruleForm.buyFreeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "buyFreeName", $$v)
                      },
                      expression: "ruleForm.buyFreeName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "买赠时间", prop: "termTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "picker-options": _vm.pickerOptions,
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    model: {
                      value: _vm.ruleForm.termTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "termTime", $$v)
                      },
                      expression: "ruleForm.termTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "适用商品", prop: "applicableGoods" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.applicableGoods,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "applicableGoods", $$v)
                        },
                        expression: "ruleForm.applicableGoods",
                      },
                    },
                    [_c("el-radio", { attrs: { label: 0 } }, [_vm._v("全部")])],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.applicableGoods === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择品类：", prop: "primaryKey" } },
                    [
                      _c("el-cascader", {
                        staticClass: "selWidth",
                        attrs: {
                          options: _vm.merCateList,
                          props: _vm.props2,
                          clearable: "",
                          "show-all-levels": false,
                        },
                        model: {
                          value: _vm.ruleForm.primaryKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "primaryKey", $$v)
                          },
                          expression: "ruleForm.primaryKey",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.applicableGoods === 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "商品：", prop: "checked" } },
                    [
                      _c(
                        "div",
                        { staticClass: "acea-row" },
                        [
                          _vm.ruleForm.checked.length
                            ? _vm._l(
                                _vm.ruleForm.checked,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "pictrue" },
                                    [
                                      _c("img", { attrs: { src: item.image } }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-error btndel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(index)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              on: { click: _vm.changeGood },
                            },
                            [
                              _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "买赠方式", prop: "buyGiveWay" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.buyGiveWay,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "buyGiveWay", $$v)
                        },
                        expression: "ruleForm.buyGiveWay",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("按最高层赠送"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("累计赠送"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.ruleForm.giftLevel, function (item, index) {
                return _c(
                  "el-form-item",
                  {
                    key: index,
                    attrs: { prop: "giftLevel", "label-width": "0" },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.ruleForm.buyGiveWay == 0
                              ? "按最高层赠送"
                              : "按累计赠送",
                          prop: "conditionType",
                        },
                      },
                      [
                        _vm._v("\n          满 "),
                        _c("el-input-number", {
                          attrs: { min: 0 },
                          model: {
                            value: item.condition,
                            callback: function ($$v) {
                              _vm.$set(item, "condition", $$v)
                            },
                            expression: "item.condition",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100px" },
                            model: {
                              value: item.conditionType,
                              callback: function ($$v) {
                                _vm.$set(item, "conditionType", $$v)
                              },
                              expression: "item.conditionType",
                            },
                          },
                          _vm._l(_vm.condion, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "120px" },
                            model: {
                              value: item.qualificationType,
                              callback: function ($$v) {
                                _vm.$set(item, "qualificationType", $$v)
                              },
                              expression: "item.qualificationType",
                            },
                          },
                          _vm._l(_vm.qualification, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "el-icon-circle-plus icon-1",
                          on: { click: _vm.addSelectPresent },
                        }),
                        _vm._v(" "),
                        index >= 1
                          ? _c("i", {
                              staticClass: "el-icon-remove icon-1",
                              on: {
                                click: function ($event) {
                                  return _vm.removeSelectPresent(index)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(item.gift, function (itemn, indexn) {
                      return _c(
                        "div",
                        { key: indexn, staticClass: "present-list" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "赠品" + (indexn + 1) + "：",
                                prop: "giftLevel." + index + ".gift",
                                rules: {
                                  required: true,
                                  message: "请选择赠品",
                                  trigger: "blur",
                                },
                              },
                            },
                            [
                              _c("div", { staticStyle: { width: "120px" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(itemn.goodsName) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "赠品件数：" } },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0 },
                                model: {
                                  value: itemn.goodsNumber,
                                  callback: function ($$v) {
                                    _vm.$set(itemn, "goodsNumber", $$v)
                                  },
                                  expression: "itemn.goodsNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "del-btn",
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removePresent(index, itemn)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "add-btn" }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.goSelectPresent(index)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-circle-plus" }),
                          _vm._v("\n            添加赠品"),
                        ]
                      ),
                    ]),
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "适用人群", prop: "applicablePeople" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "状态" },
                      model: {
                        value: _vm.ruleForm.applicablePeople,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "applicablePeople", $$v)
                        },
                        expression: "ruleForm.applicablePeople",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "每人最多参与", prop: "quota" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.ruleForm.quota,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "quota", $$v)
                      },
                      expression: "ruleForm.quota",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel("ruleFormV")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSubmit("ruleFormV")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("present-list", {
        attrs: {
          dialogPresent: _vm.dialogPresent,
          selectPresentList: _vm.selectPresentList,
        },
        on: { closeDialog: _vm.closeDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applicableGoods = applicableGoods;
exports.availableList = availableList;
exports.bargainDeleteApi = bargainDeleteApi;
exports.bargainInfoApi = bargainInfoApi;
exports.bargainListApi = bargainListApi;
exports.bargainListListApi = bargainListListApi;
exports.bargainOrderPinkApi = bargainOrderPinkApi;
exports.bargainSaveApi = bargainSaveApi;
exports.bargainStatusApi = bargainStatusApi;
exports.bargainUpdateApi = bargainUpdateApi;
exports.combinationDeleteApi = combinationDeleteApi;
exports.combinationInfoApi = combinationInfoApi;
exports.combinationListApi = combinationListApi;
exports.combinationSaveApi = combinationSaveApi;
exports.combinationStatusApi = combinationStatusApi;
exports.combinationUpdateApi = combinationUpdateApi;
exports.combineListApi = combineListApi;
exports.combineOrderPinkApi = combineOrderPinkApi;
exports.combineStatisticsApi = combineStatisticsApi;
exports.couponGroupCanSendApi = couponGroupCanSendApi;
exports.couponGroupDelApi = couponGroupDelApi;
exports.couponGroupImportApi = couponGroupImportApi;
exports.couponGroupListApi = couponGroupListApi;
exports.couponGroupSaveApi = couponGroupSaveApi;
exports.couponGroupSelectApi = couponGroupSelectApi;
exports.couponGroupSendApi = couponGroupSendApi;
exports.couponGroupSendedApi = couponGroupSendedApi;
exports.couponGroupUpdateApi = couponGroupUpdateApi;
exports.couponInfoApi = couponInfoApi;
exports.couponIssueStatusApi = couponIssueStatusApi;
exports.couponRecoveryApi = couponRecoveryApi;
exports.couponSaveApi = couponSaveApi;
exports.couponUserApi = couponUserApi;
exports.couponUserExportApi = couponUserExportApi;
exports.couponUserListApi = couponUserListApi;
exports.deleteByIdList = deleteByIdList;
exports.exportBargainApi = exportBargainApi;
exports.exportcombiantionApi = exportcombiantionApi;
exports.findPageAndList = findPageAndList;
exports.integralListApi = integralListApi;
exports.marketingListApi = marketingListApi;
exports.saveOrUpdate = saveOrUpdate;
exports.seckillConfigStatusApi = seckillConfigStatusApi;
exports.seckillDeleteApi = seckillDeleteApi;
exports.seckillInfoApi = seckillInfoApi;
exports.seckillListApi = seckillListApi;
exports.seckillSaveApi = seckillSaveApi;
exports.seckillStoreDeleteApi = seckillStoreDeleteApi;
exports.seckillStoreInfoApi = seckillStoreInfoApi;
exports.seckillStoreListApi = seckillStoreListApi;
exports.seckillStoreSaveApi = seckillStoreSaveApi;
exports.seckillStoreStatusApi = seckillStoreStatusApi;
exports.seckillStoreUpdateApi = seckillStoreUpdateApi;
exports.seckillUpdateApi = seckillUpdateApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 优惠券 列表
 * @param pram
 */
function marketingListApi(params) {
  return (0, _request.default)({
    url: '/admin/marketing/coupon/list',
    method: 'get',
    params: params
  });
}

/**
 * 赠送优惠券 列表
 * @param pram
 */
function availableList(params) {
  return (0, _request.default)({
    url: '/admin/marketing/coupon/availableList',
    method: 'get',
    params: params
  });
}

/**
 * 优惠券 详情
 * @param pram
 */
function couponInfoApi(params) {
  return (0, _request.default)({
    url: '/admin/marketing/coupon/info',
    method: 'post',
    params: params
  });
}

/**
 * 优惠券 发送
 * @param pram
 */
function couponUserApi(params) {
  return (0, _request.default)({
    url: '/admin/marketing/coupon/user/receive',
    method: 'post',
    params: params
  });
}

/**
 * 优惠券 发送
 * @param pram
 */
function couponSaveApi(data) {
  return (0, _request.default)({
    url: '/admin/marketing/coupon/save',
    method: 'post',
    data: data
  });
}

/**
 * 优惠券 修改状态
 * @param pram
 */
function couponIssueStatusApi(params) {
  return (0, _request.default)({
    url: '/admin/marketing/coupon/update/status',
    method: 'post',
    params: params
  });
}

/**
 * 会员领取记录 列表
 * @param pram
 */
function couponUserListApi(params) {
  return (0, _request.default)({
    url: '/admin/marketing/coupon/user/list',
    method: 'get',
    params: params
  });
}

/**
 * 会员领取记录 导出
 * @param pram
 */
function couponUserExportApi(params) {
  return (0, _request.default)({
    url: '/admin/marketing/coupon/user/export',
    method: 'post',
    params: params
  });
}

/**
 * 会员领取记录 恢复核销
 * @param pram
 */
function couponRecoveryApi(params) {
  return (0, _request.default)({
    url: '/admin/marketing/coupon/user/recovery',
    method: 'post',
    params: params
  });
}

/**
 * 优惠券分组 下拉数据
 * @param pram
 */
function couponGroupSelectApi(params) {
  return (0, _request.default)({
    url: '/admin/marketing/group/map',
    method: 'get',
    params: params
  });
}

/**
 * 优惠券分组 列表
 * @param pram
 */
function couponGroupListApi(params) {
  return (0, _request.default)({
    url: '/admin/marketing/group/list',
    method: 'get',
    params: params
  });
}

/**
 * 优惠券分组 导入
 * @param pram
 */
function couponGroupImportApi(data, params) {
  return (0, _request.default)({
    url: '/admin/marketing/group/upload',
    method: 'post',
    data: data,
    params: params
  });
}

/**
 * 优惠券分组 新增
 * @param pram
 */
function couponGroupSaveApi(data) {
  return (0, _request.default)({
    url: '/admin/marketing/group/save',
    method: 'post',
    data: data
  });
}

/**
 * 优惠券分组 更新
 * @param pram
 */
function couponGroupUpdateApi(data) {
  return (0, _request.default)({
    url: '/admin/marketing/group/update',
    method: 'post',
    data: data
  });
}

/**
 * 优惠券分组 删除
 * @param pram
 */
function couponGroupDelApi(data) {
  return (0, _request.default)({
    url: '/admin/marketing/group/delete',
    method: 'delete',
    data: data
  });
}

/**
 * 优惠券分组 可发送列表
 * @param pram
 */
function couponGroupCanSendApi(params) {
  return (0, _request.default)({
    url: '/admin/marketing/coupon/available/list',
    method: 'get',
    params: params
  });
}

/**
 * 优惠券按分组发送
 * @param pram
 */
function couponGroupSendApi(data) {
  return (0, _request.default)({
    url: '/admin/marketing/coupon/send',
    method: 'post',
    data: data
  });
}

/**
 * 优惠券分组 已发送列表
 * @param pram
 */
function couponGroupSendedApi(params) {
  return (0, _request.default)({
    url: '/admin/marketing/coupon/sent/list',
    method: 'get',
    params: params
  });
}

/**
 * 积分日志 列表
 * @param pram
 */
function integralListApi(params, data) {
  return (0, _request.default)({
    url: '/admin/user/integral/list',
    method: 'post',
    params: params,
    data: data
  });
}

/**
 * 秒杀配置 列表
 * @param pram
 */
function seckillListApi(params) {
  return (0, _request.default)({
    url: '/admin/store/seckill/manger/list',
    method: 'get',
    params: params
  });
}

/**
 * 秒杀配置 详情
 * @param pram
 */
function seckillInfoApi(params) {
  return (0, _request.default)({
    url: '/admin/store/seckill/manger/info',
    method: 'get',
    params: params
  });
}

/**
 * 秒杀配置 新增
 * @param pram
 */
function seckillSaveApi(data) {
  return (0, _request.default)({
    url: '/admin/store/seckill/manger/save',
    method: 'post',
    data: data
  });
}

/**
 * 秒杀配置 修改
 * @param pram
 */
function seckillUpdateApi(params, data) {
  return (0, _request.default)({
    url: '/admin/store/seckill/manger/update',
    method: 'post',
    params: params,
    data: data
  });
}

/**
 * 秒杀配置 删除
 * @param pram
 */
function seckillDeleteApi(params) {
  return (0, _request.default)({
    url: '/admin/store/seckill/manger/delete',
    method: 'get',
    params: params
  });
}

/**
 * 秒杀商品 列表
 * @param pram
 */
function seckillStoreListApi(params) {
  return (0, _request.default)({
    url: '/admin/store/seckill/list',
    method: 'get',
    params: params
  });
}

/**
 * 秒杀商品 详情
 * @param pram
 */
function seckillStoreInfoApi(params) {
  return (0, _request.default)({
    url: '/admin/store/seckill/info',
    method: 'get',
    params: params
  });
}

/**
 * 秒杀商品 新增
 * @param pram
 */
function seckillStoreSaveApi(data) {
  return (0, _request.default)({
    url: '/admin/store/seckill/save',
    method: 'post',
    data: data
  });
}

/**
 * 秒杀商品 修改
 * @param pram
 */
function seckillStoreUpdateApi(params, data) {
  return (0, _request.default)({
    url: '/admin/store/seckill/update',
    method: 'post',
    params: params,
    data: data
  });
}

/**
 * 秒杀商品 删除
 * @param pram
 */
function seckillStoreDeleteApi(params) {
  return (0, _request.default)({
    url: '/admin/store/seckill/delete',
    method: 'get',
    params: params
  });
}

/**
 * 秒杀商品 修改状态
 */
function seckillStoreStatusApi(params) {
  return (0, _request.default)({
    url: '/admin/store/seckill/update/status',
    method: 'post',
    params: params
  });
}

/**
 * 秒杀配置 修改状态
 */
function seckillConfigStatusApi(id, params) {
  return (0, _request.default)({
    url: "/admin/store/seckill/manger/update/status/".concat(id),
    method: 'post',
    params: params
  });
}

/**
 * 砍价商品 列表
 */
function bargainListApi(params) {
  return (0, _request.default)({
    url: "/admin/store/bargain/list",
    method: 'get',
    params: params
  });
}

/**
 * 砍价商品 新增
 */
function bargainSaveApi(data) {
  return (0, _request.default)({
    url: "/admin/store/bargain/save",
    method: 'POST',
    data: data
  });
}

/**
 * 砍价商品 详情
 */
function bargainInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/store/bargain/info",
    method: 'get',
    params: params
  });
}

/**
 * 砍价商品 编辑
 */
function bargainUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/admin/store/bargain/update",
    method: 'post',
    params: params,
    data: data
  });
}

/**
 * 砍价商品 删除
 */
function bargainDeleteApi(params) {
  return (0, _request.default)({
    url: "/admin/store/bargain/delete",
    method: 'get',
    params: params
  });
}

/**
 * 砍价列表 详情
 */
function bargainOrderPinkApi(id) {
  return (0, _request.default)({
    url: "/admin/store/bargain/bargain_list/".concat(id),
    method: 'get'
  });
}

/**
 * 砍价列表 列表
 */
function bargainListListApi(params) {
  return (0, _request.default)({
    url: "/admin/store/bargain/bargain_list",
    method: 'get',
    params: params
  });
}

/**
 * 拼团商品 修改拼团状态
 */
function bargainStatusApi(params) {
  return (0, _request.default)({
    url: "/admin/store/bargain/update/status",
    method: 'post',
    params: params
  });
}

/**
 * 拼团商品 列表
 */
function combinationListApi(params) {
  return (0, _request.default)({
    url: "/admin/store/combination/list",
    method: 'get',
    params: params
  });
}

/**
 * 拼团商品 删除
 */
function combinationDeleteApi(params) {
  return (0, _request.default)({
    url: "/admin/store/combination/delete",
    method: 'get',
    params: params
  });
}

/**
 * 拼团商品 新增
 */
function combinationSaveApi(data) {
  return (0, _request.default)({
    url: "/admin/store/combination/save",
    method: 'post',
    data: data
  });
}

/**
 * 拼团商品 修改
 */
function combinationUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/admin/store/combination/update",
    method: 'post',
    params: params,
    data: data
  });
}

/**
 * 拼团商品 详情
 */
function combinationInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/store/combination/info",
    method: 'get',
    params: params
  });
}

/**
 * 拼团商品 修改拼团状态
 */
function combinationStatusApi(params) {
  return (0, _request.default)({
    url: "/admin/store/combination/update/status",
    method: 'post',
    params: params
  });
}

/**
 * 拼团列表 列表
 */
function combineListApi(params) {
  return (0, _request.default)({
    url: "/admin/store/combination/combine/list",
    method: 'get',
    params: params
  });
}

/**
 * 拼团列表 统计
 */
function combineStatisticsApi(params) {
  return (0, _request.default)({
    url: "/admin/store/combination/statistics",
    method: 'get',
    params: params
  });
}

/**
 * 拼团列表 详情
 */
function combineOrderPinkApi(id) {
  return (0, _request.default)({
    url: "/admin/store/combination/order_pink/".concat(id),
    method: 'get'
  });
}

/**
 * 砍价 导出
 */
function exportBargainApi(params) {
  return (0, _request.default)({
    url: "/admin/export/excel/bargain/product",
    method: 'get',
    params: params
  });
}

/**
 * 拼团 导出
 */
function exportcombiantionApi(params) {
  return (0, _request.default)({
    url: "/admin/export/excel/combiantion/product",
    method: 'get',
    params: params
  });
}
function findPageAndList(params) {
  return (0, _request.default)({
    url: "/admin/marketing/buyFree/findPageAndList",
    method: 'post',
    params: params
  });
}
function saveOrUpdate(data) {
  return (0, _request.default)({
    url: "/admin/marketing/buyFree/saveOrUpdate",
    method: 'post',
    data: data
  });
}
function deleteByIdList(data) {
  return (0, _request.default)({
    url: "/admin/marketing/buyFree/deleteByIdList",
    method: 'post',
    data: data
  });
}
function applicableGoods(data) {
  return (0, _request.default)({
    url: "/admin/marketing/applicableGoods",
    method: 'post',
    data: data
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddRepaieSiteService = AddRepaieSiteService;
exports.DelRepaieSiteService = DelRepaieSiteService;
exports.ListRepaieSiteService = ListRepaieSiteService;
exports.OpenRepaieSiteService = OpenRepaieSiteService;
exports.UpdateRepaieSiteService = UpdateRepaieSiteService;
exports.importRepaieSiteService = importRepaieSiteService;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 维修点列表
 */
function ListRepaieSiteService(params) {
  return (0, _request.default)({
    url: '/admin/repair/list',
    method: 'GET',
    params: params
  });
}

/**
* 新增维修点
*/
function AddRepaieSiteService(data) {
  return (0, _request.default)({
    url: '/admin/repair/save',
    method: 'post',
    data: data
  });
}

/**
* 更新维修点
*/
function UpdateRepaieSiteService(data) {
  return (0, _request.default)({
    url: '/admin/repair/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除维修点
 * @param id
 * @constructor
 */
function DelRepaieSiteService(id) {
  return (0, _request.default)({
    url: "/admin/repair/remove",
    method: 'post',
    data: {
      id: id
    }
  });
}

/**
* 维修点开启/关闭
*/
function OpenRepaieSiteService(params) {
  return (0, _request.default)({
    url: "/admin/repair/eidtStatus",
    method: 'get',
    params: params
  });
}

/**
 * 导入维修点
 * @param id
 * @constructor
 */
function importRepaieSiteService(data) {
  return (0, _request.default)({
    url: "/admin/repair/importRepair",
    method: 'post',
    data: data,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
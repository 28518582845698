var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单详情",
            visible: _vm.dialogVisible,
            width: "700px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "description",
            },
            [
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v("姓名：" + _vm._s(_vm.orderInfo.realName)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v("职位：" + _vm._s(_vm.orderInfo.position)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v("手机号：" + _vm._s(_vm.orderInfo.userPhone)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v(
                    "\n          公司名称：" +
                      _vm._s(_vm.orderInfo.corporationName) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v("公司邮箱：" + _vm._s(_vm.orderInfo.email)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v(
                    "\n          地点：" +
                      _vm._s(_vm.orderInfo.serviceAddress) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v(
                    "\n          服务时长：" +
                      _vm._s(_vm.orderInfo.timeLength) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v(
                    "\n          设备数量：" +
                      _vm._s(_vm.orderInfo.deviceNum) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v(
                    "\n          服务内容：" +
                      _vm._s(_vm.orderInfo.serviceContent) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v(
                    "\n          期望时间:" +
                      _vm._s(_vm.orderInfo.expectTime) +
                      " " +
                      _vm._s(_vm.orderInfo.expectTimeInfo) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v("备注：" + _vm._s(_vm.orderInfo.mark)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v(
                    "\n          发票：" +
                      _vm._s(_vm.orderInfo.isNeedInvoice == 0 ? "否" : "是") +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.orderInfo.isNeedInvoice == 1
                ? _c("div", { staticClass: "acea-row" }, [
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        "\n          公司抬头：" +
                          _vm._s(_vm.orderInfo.invoice.corporationName || "") +
                          "\n        "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.orderInfo.isNeedInvoice == 1
                ? _c("div", { staticClass: "acea-row" }, [
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        "\n          企业税号：" +
                          _vm._s(_vm.orderInfo.invoice.corporationTax || "") +
                          "\n        "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.orderInfo.isNeedInvoice == 1
                ? _c("div", { staticClass: "acea-row" }, [
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        "\n          公司电话：" +
                          _vm._s(_vm.orderInfo.invoice.phone || "") +
                          "\n        "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.orderInfo.isNeedInvoice == 1
                ? _c("div", { staticClass: "acea-row" }, [
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        "\n          地址：" +
                          _vm._s(_vm.orderInfo.invoice.address || "") +
                          "\n        "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.orderInfo.isNeedInvoice == 1
                ? _c("div", { staticClass: "acea-row" }, [
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        "\n          开户银行：" +
                          _vm._s(_vm.orderInfo.invoice.bankName || "") +
                          "\n        "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v(
                    "\n          提交时间：" +
                      _vm._s(_vm.orderInfo.createTime) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v(
                    "\n          拼团状态：" +
                      _vm._s(_vm.orderInfo.combinationStatus) +
                      "\n        "
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "GII编号",
            visible: _vm.dialogVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.orderDatalist
            ? _c(
                "div",
                { staticClass: "goods_main" },
                [
                  _c("div", { staticClass: "description-term" }, [
                    _vm._v(
                      "\n        订单编号：" +
                        _vm._s(_vm.orderDatalist.orderId) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      attrs: {
                        "label-position": _vm.labelPosition,
                        model: _vm.goodsForm,
                        "label-width": "70px",
                      },
                    },
                    [
                      _c("div", { staticClass: "goods_header" }, [
                        _c("div", [_vm._v("商品信息")]),
                        _vm._v(" "),
                        _c("div", [_vm._v("GII编码")]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.goodsForm.productList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "goods_item" },
                          [
                            _c("div", { staticClass: "goods_msg" }, [
                              _c(
                                "div",
                                { staticClass: "goods_image" },
                                [
                                  _c("el-image", {
                                    attrs: {
                                      src: item.image,
                                      "preview-src-list": [item.image],
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "goods_name" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: item.storeName,
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("strFilter")(item.storeName)
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(_vm._s(item.suk ? item.suk : "-")),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(item.presents, function (item1) {
                                    return _c("span", { key: item1.id }, [
                                      _vm._v(
                                        _vm._s(item1.name) +
                                          " |\n              "
                                      ),
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(item.price) +
                                        " x " +
                                        _vm._s(item.cartNum)
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "GII编码",
                                  prop: "productList." + index + ".giiCode",
                                  rules: _vm.rules.msg,
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    size: "mini",
                                    maxlength: "9",
                                    "show-word-limit": "",
                                    placeholder: "请输入GII编码",
                                  },
                                  model: {
                                    value: item.giiCode,
                                    callback: function ($$v) {
                                      _vm.$set(item, "giiCode", $$v)
                                    },
                                    expression: "item.giiCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/layout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var appSettingRouter = {
  path: '/appSetting',
  component: _layout.default,
  redirect: '/appSetting/publicAccount/wxMenus',
  name: 'appSetting',
  meta: {
    title: '应用',
    icon: 'clipboard'
  },
  children: [{
    path: 'publicAccount',
    name: 'publicAccount',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/appSetting/wxAccount'));
      });
    },
    meta: {
      title: '公众号',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'wxMenus',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/appSetting/wxAccount/wxMenus'));
        });
      },
      name: 'wxMenus',
      meta: {
        title: '微信菜单',
        icon: ''
      }
    }, {
      path: 'wxReply',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/appSetting/wxAccount/reply/index'));
        });
      },
      name: 'wxReply',
      meta: {
        title: '自动回复',
        icon: ''
      },
      children: [{
        path: 'follow',
        component: function component() {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require('@/views/appSetting/wxAccount/reply/follow'));
          });
        },
        name: 'wxFollow',
        meta: {
          title: '微信关注回复',
          icon: ''
        }
      }, {
        path: 'keyword',
        component: function component() {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require('@/views/appSetting/wxAccount/reply/keyword'));
          });
        },
        name: 'wxKeyword',
        meta: {
          title: '关键字回复',
          icon: ''
        }
      }, {
        path: 'replyIndex',
        component: function component() {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require('@/views/appSetting/wxAccount/reply/follow'));
          });
        },
        name: 'wxReplyIndex',
        meta: {
          title: '无效关键词回复',
          icon: ''
        }
      }, {
        path: 'keyword/save/:id?',
        name: 'wechatKeywordAdd',
        meta: {
          title: '关键字添加',
          noCache: true,
          activeMenu: "/appSetting/publicAccount/wxReply/keyword"
        },
        hidden: true,
        component: function component() {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require('@/views/appSetting/wxAccount/reply/follow'));
          });
        }
      }]
    }, {
      path: 'template/:type?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/appSetting/wxAccount/wxTemplate'));
        });
      },
      name: 'wxTemplate',
      meta: {
        title: '微信模板消息',
        icon: ''
      }
    }, {
      path: 'wxPicMsg',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/appSetting/wxAccount/wxPicMsg'));
        });
      },
      name: 'wxPicMsg',
      meta: {
        title: '素材管理',
        icon: ''
      }
    }]
  }, {
    path: 'publicRoutine',
    name: 'PublicRoutine',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/appSetting/routine'));
      });
    },
    meta: {
      title: '小程序',
      icon: 'clipboard',
      roles: ['admin'],
      hidden: true
    },
    children: [{
      path: 'template/:type?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/appSetting/wxAccount/wxTemplate'));
        });
      },
      name: 'RoutineTemplate',
      meta: {
        title: '小程序订阅消息',
        icon: ''
      }
    }, {
      path: 'routineTemplate',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/appSetting/routine/myTemplate'));
        });
      },
      name: 'RoutineTemplate',
      meta: {
        title: '我的模板',
        icon: ''
      }
    }, {
      path: 'publicRoutineTemplate',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/appSetting/routine/publicTemplate/index.vue'));
        });
      },
      name: 'PublicRoutineTemplate',
      meta: {
        title: '公共模板',
        icon: ''
      }
    }, {
      path: 'creatPublicTemplate/:tid/:id/:myId?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/appSetting/routine/publicTemplate/creatPublicTemplate.vue'));
        });
      },
      name: 'CreatPublicTemplate',
      meta: {
        title: '添加公共模板',
        icon: '',
        activeMenu: "/appSetting/publicRoutine/publicRoutineTemplate"
      },
      hidden: true
    }]
  }]
};
var _default = exports.default = appSettingRouter;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var obj = {
  name: '',
  grade: 1,
  discount: '',
  icon: '',
  image: '',
  isShow: true,
  id: null
};
var _default = exports.default = {
  name: "CreatGrade",
  data: function data() {
    return {
      dialogVisible: false,
      formValidate: Object.assign({}, obj),
      loading: false,
      rules: {
        name: [{
          required: true,
          message: '请输入等级名称',
          trigger: 'blur'
        }],
        grade: [{
          required: true,
          message: '请输入等级',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '等级必须为数字值'
        }],
        discount: [{
          required: true,
          message: '请输入折扣',
          trigger: 'blur'
        }],
        experience: [{
          required: true,
          message: '请输入经验',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '经验必须为数字值'
        }],
        icon: [{
          required: true,
          message: '请上传图标',
          trigger: 'change'
        }]
        // image: [
        //   {  required: true, message: '请上传用户背景', trigger: 'change' }
        // ]
      }
    };
  },
  methods: {
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num) {
      var _this = this;
      this.$modalUpload(function (img) {
        tit === '1' && num === 'icon' ? _this.formValidate.icon = img[0].sattDir : _this.formValidate.image = img[0].sattDir;
      }, tit, 'user');
    },
    info: function info(id) {
      var _this2 = this;
      this.loading = true;
      (0, _user.levelInfoApi)({
        id: id
      }).then(function (res) {
        _this2.formValidate = res;
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.$refs['formValidate'].resetFields();
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          _this3.formValidate.id ? (0, _user.levelUpdateApi)({
            id: _this3.formValidate.id
          }, _this3.formValidate).then(function (res) {
            _this3.$message.success('编辑成功');
            _this3.loading = false;
            _this3.handleClose();
            _this3.formValidate = Object.assign({}, obj);
            _this3.$parent.getList();
          }).catch(function () {
            _this3.loading = false;
          }) : (0, _user.levelSaveApi)(_this3.formValidate).then(function (res) {
            _this3.$message.success('添加成功');
            _this3.loading = false;
            _this3.handleClose();
            _this3.$parent.getList();
          }).catch(function () {
            _this3.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.dialogVisible = false;
      this.$refs[formName].resetFields();
    }
  }
};
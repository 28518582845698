"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = [{
  value: 1,
  label: '北京',
  children: [{
    value: 1,
    label: '东城区'
  }, {
    value: 2,
    label: '西城区'
  }, {
    value: 3,
    label: '崇文区'
  }, {
    value: 4,
    label: '宣武区'
  }, {
    value: 5,
    label: '朝阳区'
  }, {
    value: 6,
    label: '丰台区'
  }, {
    value: 7,
    label: '石景山区'
  }, {
    value: 8,
    label: '海淀区'
  }, {
    value: 9,
    label: '门头沟区'
  }, {
    value: 10,
    label: '房山区'
  }, {
    value: 11,
    label: '通州区'
  }, {
    value: 12,
    label: '顺义区'
  }, {
    value: 13,
    label: '昌平区'
  }, {
    value: 14,
    label: '大兴区'
  }, {
    value: 15,
    label: '怀柔区'
  }, {
    value: 16,
    label: '平谷区'
  }, {
    value: 17,
    label: '密云县'
  }, {
    value: 18,
    label: '延庆县'
  }]
}, {
  value: 2,
  label: '天津',
  children: [{
    value: 19,
    label: '和平区'
  }, {
    value: 20,
    label: '河东区'
  }, {
    value: 21,
    label: '河西区'
  }, {
    value: 22,
    label: '南开区'
  }, {
    value: 23,
    label: '河北区'
  }, {
    value: 24,
    label: '红桥区'
  }, {
    value: 25,
    label: '塘沽区'
  }, {
    value: 26,
    label: '汉沽区'
  }, {
    value: 27,
    label: '大港区'
  }, {
    value: 28,
    label: '东丽区'
  }, {
    value: 29,
    label: '西青区'
  }, {
    value: 30,
    label: '津南区'
  }, {
    value: 31,
    label: '北辰区'
  }, {
    value: 32,
    label: '武清区'
  }, {
    value: 33,
    label: '宝坻区'
  }, {
    value: 34,
    label: '宁河县'
  }, {
    value: 35,
    label: '静海县'
  }, {
    value: 36,
    label: '蓟县'
  }]
}, {
  value: 3,
  label: '河北',
  children: [{
    value: 3,
    label: '石家庄',
    children: [{
      value: 37,
      label: '长安区'
    }, {
      value: 38,
      label: '桥东区'
    }, {
      value: 39,
      label: '桥西区'
    }, {
      value: 40,
      label: '新华区'
    }, {
      value: 41,
      label: '井陉矿区'
    }, {
      value: 42,
      label: '裕华区'
    }, {
      value: 43,
      label: '井陉县'
    }, {
      value: 44,
      label: '正定县'
    }, {
      value: 45,
      label: '栾城县'
    }, {
      value: 46,
      label: '行唐县'
    }, {
      value: 47,
      label: '灵寿县'
    }, {
      value: 48,
      label: '高邑县'
    }, {
      value: 49,
      label: '深泽县'
    }, {
      value: 50,
      label: '赞皇县'
    }, {
      value: 51,
      label: '无极县'
    }, {
      value: 52,
      label: '平山县'
    }, {
      value: 53,
      label: '元氏县'
    }, {
      value: 54,
      label: '赵县'
    }, {
      value: 55,
      label: '辛集'
    }, {
      value: 56,
      label: '藁城'
    }, {
      value: 57,
      label: '晋州'
    }, {
      value: 58,
      label: '新乐'
    }, {
      value: 59,
      label: '鹿泉'
    }]
  }, {
    value: 4,
    label: '唐山',
    children: [{
      value: 60,
      label: '路南区'
    }, {
      value: 61,
      label: '路北区'
    }, {
      value: 62,
      label: '古冶区'
    }, {
      value: 63,
      label: '开平区'
    }, {
      value: 64,
      label: '丰南区'
    }, {
      value: 65,
      label: '丰润区'
    }, {
      value: 66,
      label: '滦县'
    }, {
      value: 67,
      label: '滦南县'
    }, {
      value: 68,
      label: '乐亭县'
    }, {
      value: 69,
      label: '迁西县'
    }, {
      value: 70,
      label: '玉田县'
    }, {
      value: 71,
      label: '唐海县'
    }, {
      value: 72,
      label: '遵化'
    }, {
      value: 73,
      label: '迁安'
    }]
  }, {
    value: 5,
    label: '秦皇岛',
    children: [{
      value: 74,
      label: '海港区'
    }, {
      value: 75,
      label: '山海关区'
    }, {
      value: 76,
      label: '北戴河区'
    }, {
      value: 77,
      label: '青龙满族自治县'
    }, {
      value: 78,
      label: '昌黎县'
    }, {
      value: 79,
      label: '抚宁县'
    }, {
      value: 80,
      label: '卢龙县'
    }]
  }, {
    value: 6,
    label: '邯郸',
    children: [{
      value: 81,
      label: '邯山区'
    }, {
      value: 82,
      label: '丛台区'
    }, {
      value: 83,
      label: '复兴区'
    }, {
      value: 84,
      label: '峰峰矿区'
    }, {
      value: 85,
      label: '邯郸县'
    }, {
      value: 86,
      label: '临漳县'
    }, {
      value: 87,
      label: '成安县'
    }, {
      value: 88,
      label: '大名县'
    }, {
      value: 89,
      label: '涉县'
    }, {
      value: 90,
      label: '磁县'
    }, {
      value: 91,
      label: '肥乡县'
    }, {
      value: 92,
      label: '永年县'
    }, {
      value: 93,
      label: '邱县'
    }, {
      value: 94,
      label: '鸡泽县'
    }, {
      value: 95,
      label: '广平县'
    }, {
      value: 96,
      label: '馆陶县'
    }, {
      value: 97,
      label: '魏县'
    }, {
      value: 98,
      label: '曲周县'
    }, {
      value: 99,
      label: '武安'
    }]
  }, {
    value: 7,
    label: '邢台',
    children: [{
      value: 100,
      label: '桥东区'
    }, {
      value: 101,
      label: '桥西区'
    }, {
      value: 102,
      label: '邢台县'
    }, {
      value: 103,
      label: '临城县'
    }, {
      value: 104,
      label: '内丘县'
    }, {
      value: 105,
      label: '柏乡县'
    }, {
      value: 106,
      label: '隆尧县'
    }, {
      value: 107,
      label: '任县'
    }, {
      value: 108,
      label: '南和县'
    }, {
      value: 109,
      label: '宁晋县'
    }, {
      value: 110,
      label: '巨鹿县'
    }, {
      value: 111,
      label: '新河县'
    }, {
      value: 112,
      label: '广宗县'
    }, {
      value: 113,
      label: '平乡县'
    }, {
      value: 114,
      label: '威县'
    }, {
      value: 115,
      label: '清河县'
    }, {
      value: 116,
      label: '临西县'
    }, {
      value: 117,
      label: '南宫'
    }, {
      value: 118,
      label: '沙河'
    }]
  }, {
    value: 8,
    label: '保定',
    children: [{
      value: 119,
      label: '新区'
    }, {
      value: 120,
      label: '北区'
    }, {
      value: 121,
      label: '南区'
    }, {
      value: 122,
      label: '满城县'
    }, {
      value: 123,
      label: '清苑县'
    }, {
      value: 124,
      label: '涞水县'
    }, {
      value: 125,
      label: '阜平县'
    }, {
      value: 126,
      label: '徐水县'
    }, {
      value: 127,
      label: '定兴县'
    }, {
      value: 128,
      label: '唐县'
    }, {
      value: 129,
      label: '高阳县'
    }, {
      value: 130,
      label: '容城县'
    }, {
      value: 131,
      label: '涞源县'
    }, {
      value: 132,
      label: '望都县'
    }, {
      value: 133,
      label: '安新县'
    }, {
      value: 134,
      label: '易县'
    }, {
      value: 135,
      label: '曲阳县'
    }, {
      value: 136,
      label: '蠡县'
    }, {
      value: 137,
      label: '顺平县'
    }, {
      value: 138,
      label: '博野县'
    }, {
      value: 139,
      label: '雄县'
    }, {
      value: 140,
      label: '涿州'
    }, {
      value: 141,
      label: '定州'
    }, {
      value: 142,
      label: '安国'
    }, {
      value: 143,
      label: '高碑店'
    }]
  }, {
    value: 9,
    label: '张家口',
    children: [{
      value: 144,
      label: '桥东区'
    }, {
      value: 145,
      label: '桥西区'
    }, {
      value: 146,
      label: '宣化区'
    }, {
      value: 147,
      label: '下花园区'
    }, {
      value: 148,
      label: '宣化县'
    }, {
      value: 149,
      label: '张北县'
    }, {
      value: 150,
      label: '康保县'
    }, {
      value: 151,
      label: '沽源县'
    }, {
      value: 152,
      label: '尚义县'
    }, {
      value: 153,
      label: '蔚县'
    }, {
      value: 154,
      label: '阳原县'
    }, {
      value: 155,
      label: '怀安县'
    }, {
      value: 156,
      label: '万全县'
    }, {
      value: 157,
      label: '怀来县'
    }, {
      value: 158,
      label: '涿鹿县'
    }, {
      value: 159,
      label: '赤城县'
    }, {
      value: 160,
      label: '崇礼县'
    }]
  }, {
    value: 10,
    label: '承德',
    children: [{
      value: 161,
      label: '双桥区'
    }, {
      value: 162,
      label: '双滦区'
    }, {
      value: 163,
      label: '鹰手营子矿区'
    }, {
      value: 164,
      label: '承德县'
    }, {
      value: 165,
      label: '兴隆县'
    }, {
      value: 166,
      label: '平泉县'
    }, {
      value: 167,
      label: '滦平县'
    }, {
      value: 168,
      label: '隆化县'
    }, {
      value: 169,
      label: '丰宁满族自治县'
    }, {
      value: 170,
      label: '宽城满族自治县'
    }, {
      value: 171,
      label: '围场满族蒙古族自治县'
    }]
  }, {
    value: 11,
    label: '沧州',
    children: [{
      value: 172,
      label: '新华区'
    }, {
      value: 173,
      label: '运河区'
    }, {
      value: 174,
      label: '沧县'
    }, {
      value: 175,
      label: '青县'
    }, {
      value: 176,
      label: '东光县'
    }, {
      value: 177,
      label: '海兴县'
    }, {
      value: 178,
      label: '盐山县'
    }, {
      value: 179,
      label: '肃宁县'
    }, {
      value: 180,
      label: '南皮县'
    }, {
      value: 181,
      label: '吴桥县'
    }, {
      value: 182,
      label: '献县'
    }, {
      value: 183,
      label: '孟村回族自治县'
    }, {
      value: 184,
      label: '泊头'
    }, {
      value: 185,
      label: '任丘'
    }, {
      value: 186,
      label: '黄骅'
    }, {
      value: 187,
      label: '河间'
    }]
  }, {
    value: 12,
    label: '廊坊',
    children: [{
      value: 188,
      label: '安次区'
    }, {
      value: 189,
      label: '广阳区'
    }, {
      value: 190,
      label: '固安县'
    }, {
      value: 191,
      label: '永清县'
    }, {
      value: 192,
      label: '香河县'
    }, {
      value: 193,
      label: '大城县'
    }, {
      value: 194,
      label: '文安县'
    }, {
      value: 195,
      label: '大厂回族自治县'
    }, {
      value: 196,
      label: '霸州'
    }, {
      value: 197,
      label: '三河'
    }]
  }, {
    value: 13,
    label: '衡水',
    children: [{
      value: 198,
      label: '桃城区'
    }, {
      value: 199,
      label: '枣强县'
    }, {
      value: 200,
      label: '武邑县'
    }, {
      value: 201,
      label: '武强县'
    }, {
      value: 202,
      label: '饶阳县'
    }, {
      value: 203,
      label: '安平县'
    }, {
      value: 204,
      label: '故城县'
    }, {
      value: 205,
      label: '景县'
    }, {
      value: 206,
      label: '阜城县'
    }, {
      value: 207,
      label: '冀州'
    }, {
      value: 208,
      label: '深州'
    }]
  }]
}, {
  value: 4,
  label: '山西',
  children: [{
    value: 14,
    label: '太原',
    children: [{
      value: 209,
      label: '小店区'
    }, {
      value: 210,
      label: '迎泽区'
    }, {
      value: 211,
      label: '杏花岭区'
    }, {
      value: 212,
      label: '尖草坪区'
    }, {
      value: 213,
      label: '万柏林区'
    }, {
      value: 214,
      label: '晋源区'
    }, {
      value: 215,
      label: '清徐县'
    }, {
      value: 216,
      label: '阳曲县'
    }, {
      value: 217,
      label: '娄烦县'
    }, {
      value: 218,
      label: '古交'
    }]
  }, {
    value: 15,
    label: '大同',
    children: [{
      value: 219,
      label: '城区'
    }, {
      value: 220,
      label: '矿区'
    }, {
      value: 221,
      label: '南郊区'
    }, {
      value: 222,
      label: '新荣区'
    }, {
      value: 223,
      label: '阳高县'
    }, {
      value: 224,
      label: '天镇县'
    }, {
      value: 225,
      label: '广灵县'
    }, {
      value: 226,
      label: '灵丘县'
    }, {
      value: 227,
      label: '浑源县'
    }, {
      value: 228,
      label: '左云县'
    }, {
      value: 229,
      label: '大同县'
    }]
  }, {
    value: 16,
    label: '阳泉',
    children: [{
      value: 230,
      label: '城区'
    }, {
      value: 231,
      label: '矿区'
    }, {
      value: 232,
      label: '郊区'
    }, {
      value: 233,
      label: '平定县'
    }, {
      value: 234,
      label: '盂县'
    }]
  }, {
    value: 17,
    label: '长治',
    children: [{
      value: 235,
      label: '城区'
    }, {
      value: 236,
      label: '郊区'
    }, {
      value: 237,
      label: '长治县'
    }, {
      value: 238,
      label: '襄垣县'
    }, {
      value: 239,
      label: '屯留县'
    }, {
      value: 240,
      label: '平顺县'
    }, {
      value: 241,
      label: '黎城县'
    }, {
      value: 242,
      label: '壶关县'
    }, {
      value: 243,
      label: '长子县'
    }, {
      value: 244,
      label: '武乡县'
    }, {
      value: 245,
      label: '沁县'
    }, {
      value: 246,
      label: '沁源县'
    }, {
      value: 247,
      label: '潞城'
    }]
  }, {
    value: 18,
    label: '晋城',
    children: [{
      value: 248,
      label: '城区'
    }, {
      value: 249,
      label: '沁水县'
    }, {
      value: 250,
      label: '阳城县'
    }, {
      value: 251,
      label: '陵川县'
    }, {
      value: 252,
      label: '泽州县'
    }, {
      value: 253,
      label: '高平'
    }]
  }, {
    value: 19,
    label: '朔州',
    children: [{
      value: 254,
      label: '朔城区'
    }, {
      value: 255,
      label: '平鲁区'
    }, {
      value: 256,
      label: '山阴县'
    }, {
      value: 257,
      label: '应县'
    }, {
      value: 258,
      label: '右玉县'
    }, {
      value: 259,
      label: '怀仁县'
    }]
  }, {
    value: 20,
    label: '晋中',
    children: [{
      value: 260,
      label: '榆次区'
    }, {
      value: 261,
      label: '榆社县'
    }, {
      value: 262,
      label: '左权县'
    }, {
      value: 263,
      label: '和顺县'
    }, {
      value: 264,
      label: '昔阳县'
    }, {
      value: 265,
      label: '寿阳县'
    }, {
      value: 266,
      label: '太谷县'
    }, {
      value: 267,
      label: '祁县'
    }, {
      value: 268,
      label: '平遥县'
    }, {
      value: 269,
      label: '灵石县'
    }, {
      value: 270,
      label: '介休'
    }]
  }, {
    value: 21,
    label: '运城',
    children: [{
      value: 271,
      label: '盐湖区'
    }, {
      value: 272,
      label: '临猗县'
    }, {
      value: 273,
      label: '万荣县'
    }, {
      value: 274,
      label: '闻喜县'
    }, {
      value: 275,
      label: '稷山县'
    }, {
      value: 276,
      label: '新绛县'
    }, {
      value: 277,
      label: '绛县'
    }, {
      value: 278,
      label: '垣曲县'
    }, {
      value: 279,
      label: '夏县'
    }, {
      value: 280,
      label: '平陆县'
    }, {
      value: 281,
      label: '芮城县'
    }, {
      value: 282,
      label: '永济'
    }, {
      value: 283,
      label: '河津'
    }]
  }, {
    value: 22,
    label: '忻州',
    children: [{
      value: 284,
      label: '忻府区'
    }, {
      value: 285,
      label: '定襄县'
    }, {
      value: 286,
      label: '五台县'
    }, {
      value: 287,
      label: '代县'
    }, {
      value: 288,
      label: '繁峙县'
    }, {
      value: 289,
      label: '宁武县'
    }, {
      value: 290,
      label: '静乐县'
    }, {
      value: 291,
      label: '神池县'
    }, {
      value: 292,
      label: '五寨县'
    }, {
      value: 293,
      label: '岢岚县'
    }, {
      value: 294,
      label: '河曲县'
    }, {
      value: 295,
      label: '保德县'
    }, {
      value: 296,
      label: '偏关县'
    }, {
      value: 297,
      label: '原平'
    }]
  }, {
    value: 23,
    label: '临汾',
    children: [{
      value: 298,
      label: '尧都区'
    }, {
      value: 299,
      label: '曲沃县'
    }, {
      value: 300,
      label: '翼城县'
    }, {
      value: 301,
      label: '襄汾县'
    }, {
      value: 302,
      label: '洪洞县'
    }, {
      value: 303,
      label: '古县'
    }, {
      value: 304,
      label: '安泽县'
    }, {
      value: 305,
      label: '浮山县'
    }, {
      value: 306,
      label: '吉县'
    }, {
      value: 307,
      label: '乡宁县'
    }, {
      value: 308,
      label: '大宁县'
    }, {
      value: 309,
      label: '隰县'
    }, {
      value: 310,
      label: '永和县'
    }, {
      value: 311,
      label: '蒲县'
    }, {
      value: 312,
      label: '汾西县'
    }, {
      value: 313,
      label: '侯马'
    }, {
      value: 314,
      label: '霍州'
    }]
  }, {
    value: 24,
    label: '吕梁',
    children: [{
      value: 315,
      label: '离石区'
    }, {
      value: 316,
      label: '文水县'
    }, {
      value: 317,
      label: '交城县'
    }, {
      value: 318,
      label: '兴县'
    }, {
      value: 319,
      label: '临县'
    }, {
      value: 320,
      label: '柳林县'
    }, {
      value: 321,
      label: '石楼县'
    }, {
      value: 322,
      label: '岚县'
    }, {
      value: 323,
      label: '方山县'
    }, {
      value: 324,
      label: '中阳县'
    }, {
      value: 325,
      label: '交口县'
    }, {
      value: 326,
      label: '孝义'
    }, {
      value: 327,
      label: '汾阳'
    }]
  }]
}, {
  value: 5,
  label: '内蒙古',
  children: [{
    value: 25,
    label: '呼和浩特',
    children: [{
      value: 328,
      label: '新城区'
    }, {
      value: 329,
      label: '回民区'
    }, {
      value: 330,
      label: '玉泉区'
    }, {
      value: 331,
      label: '赛罕区'
    }, {
      value: 332,
      label: '土默特左旗'
    }, {
      value: 333,
      label: '托克托县'
    }, {
      value: 334,
      label: '和林格尔县'
    }, {
      value: 335,
      label: '清水河县'
    }, {
      value: 336,
      label: '武川县'
    }]
  }, {
    value: 26,
    label: '包头',
    children: [{
      value: 337,
      label: '东河区'
    }, {
      value: 338,
      label: '昆都仑区'
    }, {
      value: 339,
      label: '青山区'
    }, {
      value: 340,
      label: '石拐区'
    }, {
      value: 341,
      label: '白云矿区'
    }, {
      value: 342,
      label: '九原区'
    }, {
      value: 343,
      label: '土默特右旗'
    }, {
      value: 344,
      label: '固阳县'
    }, {
      value: 345,
      label: '达尔罕茂明安联合旗'
    }]
  }, {
    value: 27,
    label: '乌海',
    children: [{
      value: 346,
      label: '海勃湾区'
    }, {
      value: 347,
      label: '海南区'
    }, {
      value: 348,
      label: '乌达区'
    }]
  }, {
    value: 28,
    label: '赤峰',
    children: [{
      value: 349,
      label: '红山区'
    }, {
      value: 350,
      label: '元宝山区'
    }, {
      value: 351,
      label: '松山区'
    }, {
      value: 352,
      label: '阿鲁科尔沁旗'
    }, {
      value: 353,
      label: '巴林左旗'
    }, {
      value: 354,
      label: '巴林右旗'
    }, {
      value: 355,
      label: '林西县'
    }, {
      value: 356,
      label: '克什克腾旗'
    }, {
      value: 357,
      label: '翁牛特旗'
    }, {
      value: 358,
      label: '喀喇沁旗'
    }, {
      value: 359,
      label: '宁城县'
    }, {
      value: 360,
      label: '敖汉旗'
    }]
  }, {
    value: 29,
    label: '通辽',
    children: [{
      value: 361,
      label: '科尔沁区'
    }, {
      value: 362,
      label: '科尔沁左翼中旗'
    }, {
      value: 363,
      label: '科尔沁左翼后旗'
    }, {
      value: 364,
      label: '开鲁县'
    }, {
      value: 365,
      label: '库伦旗'
    }, {
      value: 366,
      label: '奈曼旗'
    }, {
      value: 367,
      label: '扎鲁特旗'
    }, {
      value: 368,
      label: '霍林郭勒'
    }]
  }, {
    value: 30,
    label: '鄂尔多斯',
    children: [{
      value: 369,
      label: '东胜区'
    }, {
      value: 370,
      label: '达拉特旗'
    }, {
      value: 371,
      label: '准格尔旗'
    }, {
      value: 372,
      label: '鄂托克前旗'
    }, {
      value: 373,
      label: '鄂托克旗'
    }, {
      value: 374,
      label: '杭锦旗'
    }, {
      value: 375,
      label: '乌审旗'
    }, {
      value: 376,
      label: '伊金霍洛旗'
    }]
  }, {
    value: 31,
    label: '呼伦贝尔',
    children: [{
      value: 377,
      label: '海拉尔区'
    }, {
      value: 378,
      label: '阿荣旗'
    }, {
      value: 379,
      label: '莫力达瓦达斡尔族自治旗'
    }, {
      value: 380,
      label: '鄂伦春自治旗'
    }, {
      value: 381,
      label: '鄂温克族自治旗'
    }, {
      value: 382,
      label: '陈巴尔虎旗'
    }, {
      value: 383,
      label: '新巴尔虎左旗'
    }, {
      value: 384,
      label: '新巴尔虎右旗'
    }, {
      value: 385,
      label: '满洲里'
    }, {
      value: 386,
      label: '牙克石'
    }, {
      value: 387,
      label: '扎兰屯'
    }, {
      value: 388,
      label: '额尔古纳'
    }, {
      value: 389,
      label: '根河'
    }]
  }, {
    value: 32,
    label: '巴彦淖尔',
    children: [{
      value: 390,
      label: '临河区'
    }, {
      value: 391,
      label: '五原县'
    }, {
      value: 392,
      label: '磴口县'
    }, {
      value: 393,
      label: '乌拉特前旗'
    }, {
      value: 394,
      label: '乌拉特中旗'
    }, {
      value: 395,
      label: '乌拉特后旗'
    }, {
      value: 396,
      label: '杭锦后旗'
    }]
  }, {
    value: 33,
    label: '乌兰察布',
    children: [{
      value: 397,
      label: '集宁区'
    }, {
      value: 398,
      label: '卓资县'
    }, {
      value: 399,
      label: '化德县'
    }, {
      value: 400,
      label: '商都县'
    }, {
      value: 401,
      label: '兴和县'
    }, {
      value: 402,
      label: '凉城县'
    }, {
      value: 403,
      label: '察哈尔右翼前旗'
    }, {
      value: 404,
      label: '察哈尔右翼中旗'
    }, {
      value: 405,
      label: '察哈尔右翼后旗'
    }, {
      value: 406,
      label: '四子王旗'
    }, {
      value: 407,
      label: '丰镇'
    }]
  }, {
    value: 34,
    label: '兴安盟',
    children: [{
      value: 408,
      label: '乌兰浩特'
    }, {
      value: 409,
      label: '阿尔山'
    }, {
      value: 410,
      label: '科尔沁右翼前旗'
    }, {
      value: 411,
      label: '科尔沁右翼中旗'
    }, {
      value: 412,
      label: '扎赉特旗'
    }, {
      value: 413,
      label: '突泉县'
    }]
  }, {
    value: 35,
    label: '锡林郭勒盟',
    children: [{
      value: 414,
      label: '二连浩特'
    }, {
      value: 415,
      label: '锡林浩特'
    }, {
      value: 416,
      label: '阿巴嘎旗'
    }, {
      value: 417,
      label: '苏尼特左旗'
    }, {
      value: 418,
      label: '苏尼特右旗'
    }, {
      value: 419,
      label: '东乌珠穆沁旗'
    }, {
      value: 420,
      label: '西乌珠穆沁旗'
    }, {
      value: 421,
      label: '太仆寺旗'
    }, {
      value: 422,
      label: '镶黄旗'
    }, {
      value: 423,
      label: '正镶白旗'
    }, {
      value: 424,
      label: '正蓝旗'
    }, {
      value: 425,
      label: '多伦县'
    }]
  }, {
    value: 36,
    label: '阿拉善盟',
    children: [{
      value: 426,
      label: '阿拉善左旗'
    }, {
      value: 427,
      label: '阿拉善右旗'
    }, {
      value: 428,
      label: '额济纳旗'
    }]
  }]
}, {
  value: 6,
  label: '辽宁',
  children: [{
    value: 37,
    label: '沈阳',
    children: [{
      value: 429,
      label: '和平区'
    }, {
      value: 430,
      label: '沈河区'
    }, {
      value: 431,
      label: '大东区'
    }, {
      value: 432,
      label: '皇姑区'
    }, {
      value: 433,
      label: '铁西区'
    }, {
      value: 434,
      label: '苏家屯区'
    }, {
      value: 435,
      label: '东陵区'
    }, {
      value: 436,
      label: '新城子区'
    }, {
      value: 437,
      label: '于洪区'
    }, {
      value: 438,
      label: '辽中县'
    }, {
      value: 439,
      label: '康平县'
    }, {
      value: 440,
      label: '法库县'
    }, {
      value: 441,
      label: '新民'
    }]
  }, {
    value: 38,
    label: '大连',
    children: [{
      value: 442,
      label: '中山区'
    }, {
      value: 443,
      label: '西岗区'
    }, {
      value: 444,
      label: '沙河口区'
    }, {
      value: 445,
      label: '甘井子区'
    }, {
      value: 446,
      label: '旅顺口区'
    }, {
      value: 447,
      label: '金州区'
    }, {
      value: 448,
      label: '长海县'
    }, {
      value: 449,
      label: '瓦房店'
    }, {
      value: 450,
      label: '普兰店'
    }, {
      value: 451,
      label: '庄河'
    }]
  }, {
    value: 39,
    label: '鞍山',
    children: [{
      value: 452,
      label: '铁东区'
    }, {
      value: 453,
      label: '铁西区'
    }, {
      value: 454,
      label: '立山区'
    }, {
      value: 455,
      label: '千山区'
    }, {
      value: 456,
      label: '台安县'
    }, {
      value: 457,
      label: '岫岩满族自治县'
    }, {
      value: 458,
      label: '海城'
    }]
  }, {
    value: 40,
    label: '抚顺',
    children: [{
      value: 459,
      label: '新抚区'
    }, {
      value: 460,
      label: '东洲区'
    }, {
      value: 461,
      label: '望花区'
    }, {
      value: 462,
      label: '顺城区'
    }, {
      value: 463,
      label: '抚顺县'
    }, {
      value: 464,
      label: '新宾满族自治县'
    }, {
      value: 465,
      label: '清原满族自治县'
    }]
  }, {
    value: 41,
    label: '本溪',
    children: [{
      value: 466,
      label: '平山区'
    }, {
      value: 467,
      label: '溪湖区'
    }, {
      value: 468,
      label: '明山区'
    }, {
      value: 469,
      label: '南芬区'
    }, {
      value: 470,
      label: '本溪满族自治县'
    }, {
      value: 471,
      label: '桓仁满族自治县'
    }]
  }, {
    value: 42,
    label: '丹东',
    children: [{
      value: 472,
      label: '元宝区'
    }, {
      value: 473,
      label: '振兴区'
    }, {
      value: 474,
      label: '振安区'
    }, {
      value: 475,
      label: '宽甸满族自治县'
    }, {
      value: 476,
      label: '东港'
    }, {
      value: 477,
      label: '凤城'
    }]
  }, {
    value: 43,
    label: '锦州',
    children: [{
      value: 478,
      label: '古塔区'
    }, {
      value: 479,
      label: '凌河区'
    }, {
      value: 480,
      label: '太和区'
    }, {
      value: 481,
      label: '黑山县'
    }, {
      value: 482,
      label: '义县'
    }, {
      value: 483,
      label: '凌海'
    }, {
      value: 484,
      label: '北宁'
    }]
  }, {
    value: 44,
    label: '营口',
    children: [{
      value: 485,
      label: '站前区'
    }, {
      value: 486,
      label: '西区'
    }, {
      value: 487,
      label: '鲅鱼圈区'
    }, {
      value: 488,
      label: '老边区'
    }, {
      value: 489,
      label: '盖州'
    }, {
      value: 490,
      label: '大石桥'
    }]
  }, {
    value: 45,
    label: '阜新',
    children: [{
      value: 491,
      label: '海州区'
    }, {
      value: 492,
      label: '新邱区'
    }, {
      value: 493,
      label: '太平区'
    }, {
      value: 494,
      label: '清河门区'
    }, {
      value: 495,
      label: '细河区'
    }, {
      value: 496,
      label: '阜新蒙古族自治县'
    }, {
      value: 497,
      label: '彰武县'
    }]
  }, {
    value: 46,
    label: '辽阳',
    children: [{
      value: 498,
      label: '白塔区'
    }, {
      value: 499,
      label: '文圣区'
    }, {
      value: 500,
      label: '宏伟区'
    }, {
      value: 501,
      label: '弓长岭区'
    }, {
      value: 502,
      label: '太子河区'
    }, {
      value: 503,
      label: '辽阳县'
    }, {
      value: 504,
      label: '灯塔'
    }]
  }, {
    value: 47,
    label: '盘锦',
    children: [{
      value: 505,
      label: '双台子区'
    }, {
      value: 506,
      label: '兴隆台区'
    }, {
      value: 507,
      label: '大洼县'
    }, {
      value: 508,
      label: '盘山县'
    }]
  }, {
    value: 48,
    label: '铁岭',
    children: [{
      value: 509,
      label: '银州区'
    }, {
      value: 510,
      label: '清河区'
    }, {
      value: 511,
      label: '铁岭县'
    }, {
      value: 512,
      label: '西丰县'
    }, {
      value: 513,
      label: '昌图县'
    }, {
      value: 514,
      label: '调兵山'
    }, {
      value: 515,
      label: '开原'
    }]
  }, {
    value: 49,
    label: '朝阳',
    children: [{
      value: 516,
      label: '双塔区'
    }, {
      value: 517,
      label: '龙城区'
    }, {
      value: 518,
      label: '朝阳县'
    }, {
      value: 519,
      label: '建平县'
    }, {
      value: 520,
      label: '喀喇沁左翼蒙古族自治县'
    }, {
      value: 521,
      label: '北票'
    }, {
      value: 522,
      label: '凌源'
    }]
  }, {
    value: 50,
    label: '葫芦岛',
    children: [{
      value: 523,
      label: '连山区'
    }, {
      value: 524,
      label: '龙港区'
    }, {
      value: 525,
      label: '南票区'
    }, {
      value: 526,
      label: '绥中县'
    }, {
      value: 527,
      label: '建昌县'
    }, {
      value: 528,
      label: '兴城'
    }]
  }]
}, {
  value: 7,
  label: '吉林',
  children: [{
    value: 51,
    label: '长春',
    children: [{
      value: 529,
      label: '南关区'
    }, {
      value: 530,
      label: '宽城区'
    }, {
      value: 531,
      label: '朝阳区'
    }, {
      value: 532,
      label: '二道区'
    }, {
      value: 533,
      label: '绿园区'
    }, {
      value: 534,
      label: '双阳区'
    }, {
      value: 535,
      label: '农安县'
    }, {
      value: 536,
      label: '九台'
    }, {
      value: 537,
      label: '榆树'
    }, {
      value: 538,
      label: '德惠'
    }]
  }, {
    value: 52,
    label: '吉林',
    children: [{
      value: 539,
      label: '昌邑区'
    }, {
      value: 540,
      label: '龙潭区'
    }, {
      value: 541,
      label: '船营区'
    }, {
      value: 542,
      label: '丰满区'
    }, {
      value: 543,
      label: '永吉县'
    }, {
      value: 544,
      label: '蛟河'
    }, {
      value: 545,
      label: '桦甸'
    }, {
      value: 546,
      label: '舒兰'
    }, {
      value: 547,
      label: '磐石'
    }]
  }, {
    value: 53,
    label: '四平',
    children: [{
      value: 548,
      label: '铁西区'
    }, {
      value: 549,
      label: '铁东区'
    }, {
      value: 550,
      label: '梨树县'
    }, {
      value: 551,
      label: '伊通满族自治县'
    }, {
      value: 552,
      label: '公主岭'
    }, {
      value: 553,
      label: '双辽'
    }]
  }, {
    value: 54,
    label: '辽源',
    children: [{
      value: 554,
      label: '龙山区'
    }, {
      value: 555,
      label: '西安区'
    }, {
      value: 556,
      label: '东丰县'
    }, {
      value: 557,
      label: '东辽县'
    }]
  }, {
    value: 55,
    label: '通化',
    children: [{
      value: 558,
      label: '东昌区'
    }, {
      value: 559,
      label: '二道江区'
    }, {
      value: 560,
      label: '通化县'
    }, {
      value: 561,
      label: '辉南县'
    }, {
      value: 562,
      label: '柳河县'
    }, {
      value: 563,
      label: '梅河口'
    }, {
      value: 564,
      label: '集安'
    }]
  }, {
    value: 56,
    label: '白山',
    children: [{
      value: 565,
      label: '八道江区'
    }, {
      value: 566,
      label: '抚松县'
    }, {
      value: 567,
      label: '靖宇县'
    }, {
      value: 568,
      label: '长白朝鲜族自治县'
    }, {
      value: 569,
      label: '江源县'
    }, {
      value: 570,
      label: '临江'
    }]
  }, {
    value: 57,
    label: '松原',
    children: [{
      value: 571,
      label: '宁江区'
    }, {
      value: 572,
      label: '前郭尔罗斯蒙古族自治县'
    }, {
      value: 573,
      label: '长岭县'
    }, {
      value: 574,
      label: '乾安县'
    }, {
      value: 575,
      label: '扶余县'
    }]
  }, {
    value: 58,
    label: '白城',
    children: [{
      value: 576,
      label: '洮北区'
    }, {
      value: 577,
      label: '镇赉县'
    }, {
      value: 578,
      label: '通榆县'
    }, {
      value: 579,
      label: '洮南'
    }, {
      value: 580,
      label: '大安'
    }]
  }, {
    value: 59,
    label: '延边',
    children: [{
      value: 581,
      label: '延吉'
    }, {
      value: 582,
      label: '图们'
    }, {
      value: 583,
      label: '敦化'
    }, {
      value: 584,
      label: '珲春'
    }, {
      value: 585,
      label: '龙井'
    }, {
      value: 586,
      label: '和龙'
    }, {
      value: 587,
      label: '汪清县'
    }, {
      value: 588,
      label: '安图县'
    }]
  }]
}, {
  value: 8,
  label: '黑龙江',
  children: [{
    value: 60,
    label: '哈尔滨',
    children: [{
      value: 589,
      label: '道里区'
    }, {
      value: 590,
      label: '南岗区'
    }, {
      value: 591,
      label: '道外区'
    }, {
      value: 592,
      label: '香坊区'
    }, {
      value: 593,
      label: '动力区'
    }, {
      value: 594,
      label: '平房区'
    }, {
      value: 595,
      label: '松北区'
    }, {
      value: 596,
      label: '呼兰区'
    }, {
      value: 597,
      label: '依兰县'
    }, {
      value: 598,
      label: '方正县'
    }, {
      value: 599,
      label: '宾县'
    }, {
      value: 600,
      label: '巴彦县'
    }, {
      value: 601,
      label: '木兰县'
    }, {
      value: 602,
      label: '通河县'
    }, {
      value: 603,
      label: '延寿县'
    }, {
      value: 604,
      label: '阿城'
    }, {
      value: 605,
      label: '双城'
    }, {
      value: 606,
      label: '尚志'
    }, {
      value: 607,
      label: '五常'
    }]
  }, {
    value: 61,
    label: '齐齐哈尔',
    children: [{
      value: 608,
      label: '龙沙区'
    }, {
      value: 609,
      label: '建华区'
    }, {
      value: 610,
      label: '铁锋区'
    }, {
      value: 611,
      label: '昂昂溪区'
    }, {
      value: 612,
      label: '富拉尔基区'
    }, {
      value: 613,
      label: '碾子山区'
    }, {
      value: 614,
      label: '梅里斯达斡尔族区'
    }, {
      value: 615,
      label: '龙江县'
    }, {
      value: 616,
      label: '依安县'
    }, {
      value: 617,
      label: '泰来县'
    }, {
      value: 618,
      label: '甘南县'
    }, {
      value: 619,
      label: '富裕县'
    }, {
      value: 620,
      label: '克山县'
    }, {
      value: 621,
      label: '克东县'
    }, {
      value: 622,
      label: '拜泉县'
    }, {
      value: 623,
      label: '讷河'
    }]
  }, {
    value: 62,
    label: '鸡西',
    children: [{
      value: 624,
      label: '鸡冠区'
    }, {
      value: 625,
      label: '恒山区'
    }, {
      value: 626,
      label: '滴道区'
    }, {
      value: 627,
      label: '梨树区'
    }, {
      value: 628,
      label: '城子河区'
    }, {
      value: 629,
      label: '麻山区'
    }, {
      value: 630,
      label: '鸡东县'
    }, {
      value: 631,
      label: '虎林'
    }, {
      value: 632,
      label: '密山'
    }]
  }, {
    value: 63,
    label: '鹤岗',
    children: [{
      value: 633,
      label: '向阳区'
    }, {
      value: 634,
      label: '工农区'
    }, {
      value: 635,
      label: '南山区'
    }, {
      value: 636,
      label: '兴安区'
    }, {
      value: 637,
      label: '东山区'
    }, {
      value: 638,
      label: '兴山区'
    }, {
      value: 639,
      label: '萝北县'
    }, {
      value: 640,
      label: '绥滨县'
    }]
  }, {
    value: 64,
    label: '双鸭山',
    children: [{
      value: 641,
      label: '尖山区'
    }, {
      value: 642,
      label: '岭东区'
    }, {
      value: 643,
      label: '四方台区'
    }, {
      value: 644,
      label: '宝山区'
    }, {
      value: 645,
      label: '集贤县'
    }, {
      value: 646,
      label: '友谊县'
    }, {
      value: 647,
      label: '宝清县'
    }, {
      value: 648,
      label: '饶河县'
    }]
  }, {
    value: 65,
    label: '大庆',
    children: [{
      value: 649,
      label: '萨尔图区'
    }, {
      value: 650,
      label: '龙凤区'
    }, {
      value: 651,
      label: '让胡路区'
    }, {
      value: 652,
      label: '红岗区'
    }, {
      value: 653,
      label: '大同区'
    }, {
      value: 654,
      label: '肇州县'
    }, {
      value: 655,
      label: '肇源县'
    }, {
      value: 656,
      label: '林甸县'
    }, {
      value: 657,
      label: '杜尔伯特蒙古族自治县'
    }]
  }, {
    value: 66,
    label: '伊春',
    children: [{
      value: 658,
      label: '伊春区'
    }, {
      value: 659,
      label: '南岔区'
    }, {
      value: 660,
      label: '友好区'
    }, {
      value: 661,
      label: '西林区'
    }, {
      value: 662,
      label: '翠峦区'
    }, {
      value: 663,
      label: '新青区'
    }, {
      value: 664,
      label: '美溪区'
    }, {
      value: 665,
      label: '金山屯区'
    }, {
      value: 666,
      label: '五营区'
    }, {
      value: 667,
      label: '乌马河区'
    }, {
      value: 668,
      label: '汤旺河区'
    }, {
      value: 669,
      label: '带岭区'
    }, {
      value: 670,
      label: '乌伊岭区'
    }, {
      value: 671,
      label: '红星区'
    }, {
      value: 672,
      label: '上甘岭区'
    }, {
      value: 673,
      label: '嘉荫县'
    }, {
      value: 674,
      label: '铁力'
    }]
  }, {
    value: 67,
    label: '佳木斯',
    children: [{
      value: 675,
      label: '永红区'
    }, {
      value: 676,
      label: '向阳区'
    }, {
      value: 677,
      label: '前进区'
    }, {
      value: 678,
      label: '东风区'
    }, {
      value: 679,
      label: '郊区'
    }, {
      value: 680,
      label: '桦南县'
    }, {
      value: 681,
      label: '桦川县'
    }, {
      value: 682,
      label: '汤原县'
    }, {
      value: 683,
      label: '抚远县'
    }, {
      value: 684,
      label: '同江'
    }, {
      value: 685,
      label: '富锦'
    }]
  }, {
    value: 68,
    label: '七台河',
    children: [{
      value: 686,
      label: '新兴区'
    }, {
      value: 687,
      label: '桃山区'
    }, {
      value: 688,
      label: '茄子河区'
    }, {
      value: 689,
      label: '勃利县'
    }]
  }, {
    value: 69,
    label: '牡丹江',
    children: [{
      value: 690,
      label: '东安区'
    }, {
      value: 691,
      label: '阳明区'
    }, {
      value: 692,
      label: '爱民区'
    }, {
      value: 693,
      label: '西安区'
    }, {
      value: 694,
      label: '东宁县'
    }, {
      value: 695,
      label: '林口县'
    }, {
      value: 696,
      label: '绥芬河'
    }, {
      value: 697,
      label: '海林'
    }, {
      value: 698,
      label: '宁安'
    }, {
      value: 699,
      label: '穆棱'
    }]
  }, {
    value: 70,
    label: '黑河',
    children: [{
      value: 700,
      label: '爱辉区'
    }, {
      value: 701,
      label: '嫩江县'
    }, {
      value: 702,
      label: '逊克县'
    }, {
      value: 703,
      label: '孙吴县'
    }, {
      value: 704,
      label: '北安'
    }, {
      value: 705,
      label: '五大连池'
    }]
  }, {
    value: 71,
    label: '绥化',
    children: [{
      value: 706,
      label: '北林区'
    }, {
      value: 707,
      label: '望奎县'
    }, {
      value: 708,
      label: '兰西县'
    }, {
      value: 709,
      label: '青冈县'
    }, {
      value: 710,
      label: '庆安县'
    }, {
      value: 711,
      label: '明水县'
    }, {
      value: 712,
      label: '绥棱县'
    }, {
      value: 713,
      label: '安达'
    }, {
      value: 714,
      label: '肇东'
    }, {
      value: 715,
      label: '海伦'
    }]
  }, {
    value: 72,
    label: '大兴安岭地区',
    children: [{
      value: 716,
      label: '呼玛县'
    }, {
      value: 717,
      label: '塔河县'
    }, {
      value: 718,
      label: '漠河县'
    }]
  }]
}, {
  value: 9,
  label: '上海',
  children: [{
    value: 719,
    label: '黄浦区'
  }, {
    value: 720,
    label: '卢湾区'
  }, {
    value: 721,
    label: '徐汇区'
  }, {
    value: 722,
    label: '长宁区'
  }, {
    value: 723,
    label: '静安区'
  }, {
    value: 724,
    label: '普陀区'
  }, {
    value: 725,
    label: '闸北区'
  }, {
    value: 726,
    label: '虹口区'
  }, {
    value: 727,
    label: '杨浦区'
  }, {
    value: 728,
    label: '闵行区'
  }, {
    value: 729,
    label: '宝山区'
  }, {
    value: 730,
    label: '嘉定区'
  }, {
    value: 731,
    label: '浦东新区'
  }, {
    value: 732,
    label: '金山区'
  }, {
    value: 733,
    label: '松江区'
  }, {
    value: 734,
    label: '青浦区'
  }, {
    value: 735,
    label: '南汇区'
  }, {
    value: 736,
    label: '奉贤区'
  }, {
    value: 737,
    label: '崇明县'
  }]
}, {
  value: 10,
  label: '江苏',
  children: [{
    value: 74,
    label: '南京',
    children: [{
      value: 738,
      label: '玄武区'
    }, {
      value: 739,
      label: '白下区'
    }, {
      value: 740,
      label: '秦淮区'
    }, {
      value: 741,
      label: '建邺区'
    }, {
      value: 742,
      label: '鼓楼区'
    }, {
      value: 743,
      label: '下关区'
    }, {
      value: 744,
      label: '浦口区'
    }, {
      value: 745,
      label: '栖霞区'
    }, {
      value: 746,
      label: '雨花台区'
    }, {
      value: 747,
      label: '江宁区'
    }, {
      value: 748,
      label: '六合区'
    }, {
      value: 749,
      label: '溧水县'
    }, {
      value: 750,
      label: '高淳县'
    }]
  }, {
    value: 75,
    label: '无锡',
    children: [{
      value: 751,
      label: '崇安区'
    }, {
      value: 752,
      label: '南长区'
    }, {
      value: 753,
      label: '北塘区'
    }, {
      value: 754,
      label: '锡山区'
    }, {
      value: 755,
      label: '惠山区'
    }, {
      value: 756,
      label: '滨湖区'
    }, {
      value: 757,
      label: '江阴'
    }, {
      value: 758,
      label: '宜兴'
    }]
  }, {
    value: 76,
    label: '徐州',
    children: [{
      value: 759,
      label: '鼓楼区'
    }, {
      value: 760,
      label: '云龙区'
    }, {
      value: 761,
      label: '九里区'
    }, {
      value: 762,
      label: '贾汪区'
    }, {
      value: 763,
      label: '泉山区'
    }, {
      value: 764,
      label: '丰县'
    }, {
      value: 765,
      label: '沛县'
    }, {
      value: 766,
      label: '铜山县'
    }, {
      value: 767,
      label: '睢宁县'
    }, {
      value: 768,
      label: '新沂'
    }, {
      value: 769,
      label: '邳州'
    }]
  }, {
    value: 77,
    label: '常州',
    children: [{
      value: 770,
      label: '天宁区'
    }, {
      value: 771,
      label: '钟楼区'
    }, {
      value: 772,
      label: '戚墅堰区'
    }, {
      value: 773,
      label: '新北区'
    }, {
      value: 774,
      label: '武进区'
    }, {
      value: 775,
      label: '溧阳'
    }, {
      value: 776,
      label: '金坛'
    }]
  }, {
    value: 78,
    label: '苏州',
    children: [{
      value: 777,
      label: '沧浪区'
    }, {
      value: 778,
      label: '平江区'
    }, {
      value: 779,
      label: '金阊区'
    }, {
      value: 780,
      label: '虎丘区'
    }, {
      value: 781,
      label: '吴中区'
    }, {
      value: 782,
      label: '相城区'
    }, {
      value: 783,
      label: '常熟'
    }, {
      value: 784,
      label: '张家港'
    }, {
      value: 785,
      label: '昆山'
    }, {
      value: 786,
      label: '吴江'
    }, {
      value: 787,
      label: '太仓'
    }]
  }, {
    value: 79,
    label: '南通',
    children: [{
      value: 788,
      label: '崇川区'
    }, {
      value: 789,
      label: '港闸区'
    }, {
      value: 790,
      label: '海安县'
    }, {
      value: 791,
      label: '如东县'
    }, {
      value: 792,
      label: '启东'
    }, {
      value: 793,
      label: '如皋'
    }, {
      value: 794,
      label: '通州'
    }, {
      value: 795,
      label: '海门'
    }]
  }, {
    value: 80,
    label: '连云港',
    children: [{
      value: 796,
      label: '连云区'
    }, {
      value: 797,
      label: '新浦区'
    }, {
      value: 798,
      label: '海州区'
    }, {
      value: 799,
      label: '赣榆县'
    }, {
      value: 800,
      label: '东海县'
    }, {
      value: 801,
      label: '灌云县'
    }, {
      value: 802,
      label: '灌南县'
    }]
  }, {
    value: 81,
    label: '淮安',
    children: [{
      value: 803,
      label: '清河区'
    }, {
      value: 804,
      label: '楚州区'
    }, {
      value: 805,
      label: '淮阴区'
    }, {
      value: 806,
      label: '清浦区'
    }, {
      value: 807,
      label: '涟水县'
    }, {
      value: 808,
      label: '洪泽县'
    }, {
      value: 809,
      label: '盱眙县'
    }, {
      value: 810,
      label: '金湖县'
    }]
  }, {
    value: 82,
    label: '盐城',
    children: [{
      value: 811,
      label: '亭湖区'
    }, {
      value: 812,
      label: '盐都区'
    }, {
      value: 813,
      label: '响水县'
    }, {
      value: 814,
      label: '滨海县'
    }, {
      value: 815,
      label: '阜宁县'
    }, {
      value: 816,
      label: '射阳县'
    }, {
      value: 817,
      label: '建湖县'
    }, {
      value: 818,
      label: '东台'
    }, {
      value: 819,
      label: '大丰'
    }]
  }, {
    value: 83,
    label: '扬州',
    children: [{
      value: 820,
      label: '广陵区'
    }, {
      value: 821,
      label: '邗江区'
    }, {
      value: 822,
      label: '维扬区'
    }, {
      value: 823,
      label: '宝应县'
    }, {
      value: 824,
      label: '仪征'
    }, {
      value: 825,
      label: '高邮'
    }, {
      value: 826,
      label: '江都'
    }]
  }, {
    value: 84,
    label: '镇江',
    children: [{
      value: 827,
      label: '京口区'
    }, {
      value: 828,
      label: '润州区'
    }, {
      value: 829,
      label: '丹徒区'
    }, {
      value: 830,
      label: '丹阳'
    }, {
      value: 831,
      label: '扬中'
    }, {
      value: 832,
      label: '句容'
    }]
  }, {
    value: 85,
    label: '泰州',
    children: [{
      value: 833,
      label: '海陵区'
    }, {
      value: 834,
      label: '高港区'
    }, {
      value: 835,
      label: '兴化'
    }, {
      value: 836,
      label: '靖江'
    }, {
      value: 837,
      label: '泰兴'
    }, {
      value: 838,
      label: '姜堰'
    }]
  }, {
    value: 86,
    label: '宿迁',
    children: [{
      value: 839,
      label: '宿城区'
    }, {
      value: 840,
      label: '宿豫区'
    }, {
      value: 841,
      label: '沭阳县'
    }, {
      value: 842,
      label: '泗阳县'
    }, {
      value: 843,
      label: '泗洪县'
    }]
  }]
}, {
  value: 11,
  label: '浙江',
  children: [{
    value: 87,
    label: '杭州',
    children: [{
      value: 844,
      label: '上城区'
    }, {
      value: 845,
      label: '下城区'
    }, {
      value: 846,
      label: '江干区'
    }, {
      value: 847,
      label: '拱墅区'
    }, {
      value: 848,
      label: '西湖区'
    }, {
      value: 849,
      label: '滨江区'
    }, {
      value: 850,
      label: '萧山区'
    }, {
      value: 851,
      label: '余杭区'
    }, {
      value: 852,
      label: '桐庐县'
    }, {
      value: 853,
      label: '淳安县'
    }, {
      value: 854,
      label: '建德'
    }, {
      value: 855,
      label: '富阳'
    }, {
      value: 856,
      label: '临安'
    }]
  }, {
    value: 88,
    label: '宁波',
    children: [{
      value: 857,
      label: '海曙区'
    }, {
      value: 858,
      label: '江东区'
    }, {
      value: 859,
      label: '江北区'
    }, {
      value: 860,
      label: '北仑区'
    }, {
      value: 861,
      label: '镇海区'
    }, {
      value: 862,
      label: '鄞州区'
    }, {
      value: 863,
      label: '象山县'
    }, {
      value: 864,
      label: '宁海县'
    }, {
      value: 865,
      label: '余姚'
    }, {
      value: 866,
      label: '慈溪'
    }, {
      value: 867,
      label: '奉化'
    }]
  }, {
    value: 89,
    label: '温州',
    children: [{
      value: 868,
      label: '鹿城区'
    }, {
      value: 869,
      label: '龙湾区'
    }, {
      value: 870,
      label: '瓯海区'
    }, {
      value: 871,
      label: '洞头县'
    }, {
      value: 872,
      label: '永嘉县'
    }, {
      value: 873,
      label: '平阳县'
    }, {
      value: 874,
      label: '苍南县'
    }, {
      value: 875,
      label: '文成县'
    }, {
      value: 876,
      label: '泰顺县'
    }, {
      value: 877,
      label: '瑞安'
    }, {
      value: 878,
      label: '乐清'
    }]
  }, {
    value: 90,
    label: '嘉兴',
    children: [{
      value: 879,
      label: '秀城区'
    }, {
      value: 880,
      label: '秀洲区'
    }, {
      value: 881,
      label: '嘉善县'
    }, {
      value: 882,
      label: '海盐县'
    }, {
      value: 883,
      label: '海宁'
    }, {
      value: 884,
      label: '平湖'
    }, {
      value: 885,
      label: '桐乡'
    }]
  }, {
    value: 91,
    label: '湖州',
    children: [{
      value: 886,
      label: '吴兴区'
    }, {
      value: 887,
      label: '南浔区'
    }, {
      value: 888,
      label: '德清县'
    }, {
      value: 889,
      label: '长兴县'
    }, {
      value: 890,
      label: '安吉县'
    }]
  }, {
    value: 92,
    label: '绍兴',
    children: [{
      value: 891,
      label: '越城区'
    }, {
      value: 892,
      label: '绍兴县'
    }, {
      value: 893,
      label: '新昌县'
    }, {
      value: 894,
      label: '诸暨'
    }, {
      value: 895,
      label: '上虞'
    }, {
      value: 896,
      label: '嵊州'
    }]
  }, {
    value: 93,
    label: '金华',
    children: [{
      value: 897,
      label: '婺城区'
    }, {
      value: 898,
      label: '金东区'
    }, {
      value: 899,
      label: '武义县'
    }, {
      value: 900,
      label: '浦江县'
    }, {
      value: 901,
      label: '磐安县'
    }, {
      value: 902,
      label: '兰溪'
    }, {
      value: 903,
      label: '义乌'
    }, {
      value: 904,
      label: '东阳'
    }, {
      value: 905,
      label: '永康'
    }]
  }, {
    value: 94,
    label: '衢州',
    children: [{
      value: 906,
      label: '柯城区'
    }, {
      value: 907,
      label: '衢江区'
    }, {
      value: 908,
      label: '常山县'
    }, {
      value: 909,
      label: '开化县'
    }, {
      value: 910,
      label: '龙游县'
    }, {
      value: 911,
      label: '江山'
    }]
  }, {
    value: 95,
    label: '舟山',
    children: [{
      value: 912,
      label: '定海区'
    }, {
      value: 913,
      label: '普陀区'
    }, {
      value: 914,
      label: '岱山县'
    }, {
      value: 915,
      label: '嵊泗县'
    }]
  }, {
    value: 96,
    label: '台州',
    children: [{
      value: 916,
      label: '椒江区'
    }, {
      value: 917,
      label: '黄岩区'
    }, {
      value: 918,
      label: '路桥区'
    }, {
      value: 919,
      label: '玉环县'
    }, {
      value: 920,
      label: '三门县'
    }, {
      value: 921,
      label: '天台县'
    }, {
      value: 922,
      label: '仙居县'
    }, {
      value: 923,
      label: '温岭'
    }, {
      value: 924,
      label: '临海'
    }]
  }, {
    value: 97,
    label: '丽水',
    children: [{
      value: 925,
      label: '莲都区'
    }, {
      value: 926,
      label: '青田县'
    }, {
      value: 927,
      label: '缙云县'
    }, {
      value: 928,
      label: '遂昌县'
    }, {
      value: 929,
      label: '松阳县'
    }, {
      value: 930,
      label: '云和县'
    }, {
      value: 931,
      label: '庆元县'
    }, {
      value: 932,
      label: '景宁畲族自治县'
    }, {
      value: 933,
      label: '龙泉'
    }]
  }]
}, {
  value: 12,
  label: '安徽',
  children: [{
    value: 98,
    label: '合肥',
    children: [{
      value: 934,
      label: '瑶海区'
    }, {
      value: 935,
      label: '庐阳区'
    }, {
      value: 936,
      label: '蜀山区'
    }, {
      value: 937,
      label: '包河区'
    }, {
      value: 938,
      label: '长丰县'
    }, {
      value: 939,
      label: '肥东县'
    }, {
      value: 940,
      label: '肥西县'
    }]
  }, {
    value: 99,
    label: '芜湖',
    children: [{
      value: 941,
      label: '镜湖区'
    }, {
      value: 942,
      label: '马塘区'
    }, {
      value: 943,
      label: '新芜区'
    }, {
      value: 944,
      label: '鸠江区'
    }, {
      value: 945,
      label: '芜湖县'
    }, {
      value: 946,
      label: '繁昌县'
    }, {
      value: 947,
      label: '南陵县'
    }]
  }, {
    value: 100,
    label: '蚌埠',
    children: [{
      value: 948,
      label: '龙子湖区'
    }, {
      value: 949,
      label: '蚌山区'
    }, {
      value: 950,
      label: '禹会区'
    }, {
      value: 951,
      label: '淮上区'
    }, {
      value: 952,
      label: '怀远县'
    }, {
      value: 953,
      label: '五河县'
    }, {
      value: 954,
      label: '固镇县'
    }]
  }, {
    value: 101,
    label: '淮南',
    children: [{
      value: 955,
      label: '大通区'
    }, {
      value: 956,
      label: '田家庵区'
    }, {
      value: 957,
      label: '谢家集区'
    }, {
      value: 958,
      label: '八公山区'
    }, {
      value: 959,
      label: '潘集区'
    }, {
      value: 960,
      label: '凤台县'
    }]
  }, {
    value: 102,
    label: '马鞍山',
    children: [{
      value: 961,
      label: '金家庄区'
    }, {
      value: 962,
      label: '花山区'
    }, {
      value: 963,
      label: '雨山区'
    }, {
      value: 964,
      label: '当涂县'
    }]
  }, {
    value: 103,
    label: '淮北',
    children: [{
      value: 965,
      label: '杜集区'
    }, {
      value: 966,
      label: '相山区'
    }, {
      value: 967,
      label: '烈山区'
    }, {
      value: 968,
      label: '濉溪县'
    }]
  }, {
    value: 104,
    label: '铜陵',
    children: [{
      value: 969,
      label: '铜官山区'
    }, {
      value: 970,
      label: '狮子山区'
    }, {
      value: 971,
      label: '郊区'
    }, {
      value: 972,
      label: '铜陵县'
    }]
  }, {
    value: 105,
    label: '安庆',
    children: [{
      value: 973,
      label: '迎江区'
    }, {
      value: 974,
      label: '大观区'
    }, {
      value: 975,
      label: '郊区'
    }, {
      value: 976,
      label: '怀宁县'
    }, {
      value: 977,
      label: '枞阳县'
    }, {
      value: 978,
      label: '潜山县'
    }, {
      value: 979,
      label: '太湖县'
    }, {
      value: 980,
      label: '宿松县'
    }, {
      value: 981,
      label: '望江县'
    }, {
      value: 982,
      label: '岳西县'
    }, {
      value: 983,
      label: '桐城'
    }]
  }, {
    value: 106,
    label: '黄山',
    children: [{
      value: 984,
      label: '屯溪区'
    }, {
      value: 985,
      label: '黄山区'
    }, {
      value: 986,
      label: '徽州区'
    }, {
      value: 987,
      label: '歙县'
    }, {
      value: 988,
      label: '休宁县'
    }, {
      value: 989,
      label: '黟县'
    }, {
      value: 990,
      label: '祁门县'
    }]
  }, {
    value: 107,
    label: '滁州',
    children: [{
      value: 991,
      label: '琅琊区'
    }, {
      value: 992,
      label: '南谯区'
    }, {
      value: 993,
      label: '来安县'
    }, {
      value: 994,
      label: '全椒县'
    }, {
      value: 995,
      label: '定远县'
    }, {
      value: 996,
      label: '凤阳县'
    }, {
      value: 997,
      label: '天长'
    }, {
      value: 998,
      label: '明光'
    }]
  }, {
    value: 108,
    label: '阜阳',
    children: [{
      value: 999,
      label: '颍州区'
    }, {
      value: 1000,
      label: '颍东区'
    }, {
      value: 1001,
      label: '颍泉区'
    }, {
      value: 1002,
      label: '临泉县'
    }, {
      value: 1003,
      label: '太和县'
    }, {
      value: 1004,
      label: '阜南县'
    }, {
      value: 1005,
      label: '颍上县'
    }, {
      value: 1006,
      label: '界首'
    }]
  }, {
    value: 109,
    label: '宿州',
    children: [{
      value: 1007,
      label: '埇桥区'
    }, {
      value: 1008,
      label: '砀山县'
    }, {
      value: 1009,
      label: '萧县'
    }, {
      value: 1010,
      label: '灵璧县'
    }, {
      value: 1011,
      label: '泗县'
    }]
  }, {
    value: 110,
    label: '巢湖',
    children: [{
      value: 1012,
      label: '居巢区'
    }, {
      value: 1013,
      label: '庐江县'
    }, {
      value: 1014,
      label: '无为县'
    }, {
      value: 1015,
      label: '含山县'
    }, {
      value: 1016,
      label: '和县'
    }]
  }, {
    value: 111,
    label: '六安',
    children: [{
      value: 1017,
      label: '金安区'
    }, {
      value: 1018,
      label: '裕安区'
    }, {
      value: 1019,
      label: '寿县'
    }, {
      value: 1020,
      label: '霍邱县'
    }, {
      value: 1021,
      label: '舒城县'
    }, {
      value: 1022,
      label: '金寨县'
    }, {
      value: 1023,
      label: '霍山县'
    }]
  }, {
    value: 112,
    label: '亳州',
    children: [{
      value: 1024,
      label: '谯城区'
    }, {
      value: 1025,
      label: '涡阳县'
    }, {
      value: 1026,
      label: '蒙城县'
    }, {
      value: 1027,
      label: '利辛县'
    }]
  }, {
    value: 113,
    label: '池州',
    children: [{
      value: 1028,
      label: '贵池区'
    }, {
      value: 1029,
      label: '东至县'
    }, {
      value: 1030,
      label: '石台县'
    }, {
      value: 1031,
      label: '青阳县'
    }]
  }, {
    value: 114,
    label: '宣城',
    children: [{
      value: 1032,
      label: '宣州区'
    }, {
      value: 1033,
      label: '郎溪县'
    }, {
      value: 1034,
      label: '广德县'
    }, {
      value: 1035,
      label: '泾县'
    }, {
      value: 1036,
      label: '绩溪县'
    }, {
      value: 1037,
      label: '旌德县'
    }, {
      value: 1038,
      label: '宁国'
    }]
  }]
}, {
  value: 13,
  label: '福建',
  children: [{
    value: 115,
    label: '福州',
    children: [{
      value: 1039,
      label: '鼓楼区'
    }, {
      value: 1040,
      label: '台江区'
    }, {
      value: 1041,
      label: '仓山区'
    }, {
      value: 1042,
      label: '马尾区'
    }, {
      value: 1043,
      label: '晋安区'
    }, {
      value: 1044,
      label: '闽侯县'
    }, {
      value: 1045,
      label: '连江县'
    }, {
      value: 1046,
      label: '罗源县'
    }, {
      value: 1047,
      label: '闽清县'
    }, {
      value: 1048,
      label: '永泰县'
    }, {
      value: 1049,
      label: '平潭县'
    }, {
      value: 1050,
      label: '福清'
    }, {
      value: 1051,
      label: '长乐'
    }]
  }, {
    value: 116,
    label: '厦门',
    children: [{
      value: 1052,
      label: '思明区'
    }, {
      value: 1053,
      label: '海沧区'
    }, {
      value: 1054,
      label: '湖里区'
    }, {
      value: 1055,
      label: '集美区'
    }, {
      value: 1056,
      label: '同安区'
    }, {
      value: 1057,
      label: '翔安区'
    }]
  }, {
    value: 117,
    label: '莆田',
    children: [{
      value: 1058,
      label: '城厢区'
    }, {
      value: 1059,
      label: '涵江区'
    }, {
      value: 1060,
      label: '荔城区'
    }, {
      value: 1061,
      label: '秀屿区'
    }, {
      value: 1062,
      label: '仙游县'
    }]
  }, {
    value: 118,
    label: '三明',
    children: [{
      value: 1063,
      label: '梅列区'
    }, {
      value: 1064,
      label: '三元区'
    }, {
      value: 1065,
      label: '明溪县'
    }, {
      value: 1066,
      label: '清流县'
    }, {
      value: 1067,
      label: '宁化县'
    }, {
      value: 1068,
      label: '大田县'
    }, {
      value: 1069,
      label: '尤溪县'
    }, {
      value: 1070,
      label: '沙县'
    }, {
      value: 1071,
      label: '将乐县'
    }, {
      value: 1072,
      label: '泰宁县'
    }, {
      value: 1073,
      label: '建宁县'
    }, {
      value: 1074,
      label: '永安'
    }]
  }, {
    value: 119,
    label: '泉州',
    children: [{
      value: 1075,
      label: '鲤城区'
    }, {
      value: 1076,
      label: '丰泽区'
    }, {
      value: 1077,
      label: '洛江区'
    }, {
      value: 1078,
      label: '泉港区'
    }, {
      value: 1079,
      label: '惠安县'
    }, {
      value: 1080,
      label: '安溪县'
    }, {
      value: 1081,
      label: '永春县'
    }, {
      value: 1082,
      label: '德化县'
    }, {
      value: 1083,
      label: '金门县'
    }, {
      value: 1084,
      label: '石狮'
    }, {
      value: 1085,
      label: '晋江'
    }, {
      value: 1086,
      label: '南安'
    }]
  }, {
    value: 120,
    label: '漳州',
    children: [{
      value: 1087,
      label: '芗城区'
    }, {
      value: 1088,
      label: '龙文区'
    }, {
      value: 1089,
      label: '云霄县'
    }, {
      value: 1090,
      label: '漳浦县'
    }, {
      value: 1091,
      label: '诏安县'
    }, {
      value: 1092,
      label: '长泰县'
    }, {
      value: 1093,
      label: '东山县'
    }, {
      value: 1094,
      label: '南靖县'
    }, {
      value: 1095,
      label: '平和县'
    }, {
      value: 1096,
      label: '华安县'
    }, {
      value: 1097,
      label: '龙海'
    }]
  }, {
    value: 121,
    label: '南平',
    children: [{
      value: 1098,
      label: '延平区'
    }, {
      value: 1099,
      label: '顺昌县'
    }, {
      value: 1100,
      label: '浦城县'
    }, {
      value: 1101,
      label: '光泽县'
    }, {
      value: 1102,
      label: '松溪县'
    }, {
      value: 1103,
      label: '政和县'
    }, {
      value: 1104,
      label: '邵武'
    }, {
      value: 1105,
      label: '武夷山'
    }, {
      value: 1106,
      label: '建瓯'
    }, {
      value: 1107,
      label: '建阳'
    }]
  }, {
    value: 122,
    label: '龙岩',
    children: [{
      value: 1108,
      label: '新罗区'
    }, {
      value: 1109,
      label: '长汀县'
    }, {
      value: 1110,
      label: '永定县'
    }, {
      value: 1111,
      label: '上杭县'
    }, {
      value: 1112,
      label: '武平县'
    }, {
      value: 1113,
      label: '连城县'
    }, {
      value: 1114,
      label: '漳平'
    }]
  }, {
    value: 123,
    label: '宁德',
    children: [{
      value: 1115,
      label: '蕉城区'
    }, {
      value: 1116,
      label: '霞浦县'
    }, {
      value: 1117,
      label: '古田县'
    }, {
      value: 1118,
      label: '屏南县'
    }, {
      value: 1119,
      label: '寿宁县'
    }, {
      value: 1120,
      label: '周宁县'
    }, {
      value: 1121,
      label: '柘荣县'
    }, {
      value: 1122,
      label: '福安'
    }, {
      value: 1123,
      label: '福鼎'
    }]
  }]
}, {
  value: 14,
  label: '江西',
  children: [{
    value: 124,
    label: '南昌',
    children: [{
      value: 1124,
      label: '东湖区'
    }, {
      value: 1125,
      label: '西湖区'
    }, {
      value: 1126,
      label: '青云谱区'
    }, {
      value: 1127,
      label: '湾里区'
    }, {
      value: 1128,
      label: '青山湖区'
    }, {
      value: 1129,
      label: '南昌县'
    }, {
      value: 1130,
      label: '新建县'
    }, {
      value: 1131,
      label: '安义县'
    }, {
      value: 1132,
      label: '进贤县'
    }]
  }, {
    value: 125,
    label: '景德镇',
    children: [{
      value: 1133,
      label: '昌江区'
    }, {
      value: 1134,
      label: '珠山区'
    }, {
      value: 1135,
      label: '浮梁县'
    }, {
      value: 1136,
      label: '乐平'
    }]
  }, {
    value: 126,
    label: '萍乡',
    children: [{
      value: 1137,
      label: '安源区'
    }, {
      value: 1138,
      label: '湘东区'
    }, {
      value: 1139,
      label: '莲花县'
    }, {
      value: 1140,
      label: '上栗县'
    }, {
      value: 1141,
      label: '芦溪县'
    }]
  }, {
    value: 127,
    label: '九江',
    children: [{
      value: 1142,
      label: '庐山区'
    }, {
      value: 1143,
      label: '浔阳区'
    }, {
      value: 1144,
      label: '九江县'
    }, {
      value: 1145,
      label: '武宁县'
    }, {
      value: 1146,
      label: '修水县'
    }, {
      value: 1147,
      label: '永修县'
    }, {
      value: 1148,
      label: '德安县'
    }, {
      value: 1149,
      label: '星子县'
    }, {
      value: 1150,
      label: '都昌县'
    }, {
      value: 1151,
      label: '湖口县'
    }, {
      value: 1152,
      label: '彭泽县'
    }, {
      value: 1153,
      label: '瑞昌'
    }]
  }, {
    value: 128,
    label: '新余',
    children: [{
      value: 1154,
      label: '渝水区'
    }, {
      value: 1155,
      label: '分宜县'
    }]
  }, {
    value: 129,
    label: '鹰潭',
    children: [{
      value: 1156,
      label: '月湖区'
    }, {
      value: 1157,
      label: '余江县'
    }, {
      value: 1158,
      label: '贵溪'
    }]
  }, {
    value: 130,
    label: '赣州',
    children: [{
      value: 1159,
      label: '章贡区'
    }, {
      value: 1160,
      label: '赣县'
    }, {
      value: 1161,
      label: '信丰县'
    }, {
      value: 1162,
      label: '大余县'
    }, {
      value: 1163,
      label: '上犹县'
    }, {
      value: 1164,
      label: '崇义县'
    }, {
      value: 1165,
      label: '安远县'
    }, {
      value: 1166,
      label: '龙南县'
    }, {
      value: 1167,
      label: '定南县'
    }, {
      value: 1168,
      label: '全南县'
    }, {
      value: 1169,
      label: '宁都县'
    }, {
      value: 1170,
      label: '于都县'
    }, {
      value: 1171,
      label: '兴国县'
    }, {
      value: 1172,
      label: '会昌县'
    }, {
      value: 1173,
      label: '寻乌县'
    }, {
      value: 1174,
      label: '石城县'
    }, {
      value: 1175,
      label: '瑞金'
    }, {
      value: 1176,
      label: '南康'
    }]
  }, {
    value: 131,
    label: '吉安',
    children: [{
      value: 1177,
      label: '吉州区'
    }, {
      value: 1178,
      label: '青原区'
    }, {
      value: 1179,
      label: '吉安县'
    }, {
      value: 1180,
      label: '吉水县'
    }, {
      value: 1181,
      label: '峡江县'
    }, {
      value: 1182,
      label: '新干县'
    }, {
      value: 1183,
      label: '永丰县'
    }, {
      value: 1184,
      label: '泰和县'
    }, {
      value: 1185,
      label: '遂川县'
    }, {
      value: 1186,
      label: '万安县'
    }, {
      value: 1187,
      label: '安福县'
    }, {
      value: 1188,
      label: '永新县'
    }, {
      value: 1189,
      label: '井冈山'
    }]
  }, {
    value: 132,
    label: '宜春',
    children: [{
      value: 1190,
      label: '袁州区'
    }, {
      value: 1191,
      label: '奉新县'
    }, {
      value: 1192,
      label: '万载县'
    }, {
      value: 1193,
      label: '上高县'
    }, {
      value: 1194,
      label: '宜丰县'
    }, {
      value: 1195,
      label: '靖安县'
    }, {
      value: 1196,
      label: '铜鼓县'
    }, {
      value: 1197,
      label: '丰城'
    }, {
      value: 1198,
      label: '樟树'
    }, {
      value: 1199,
      label: '高安'
    }]
  }, {
    value: 133,
    label: '抚州',
    children: [{
      value: 1200,
      label: '临川区'
    }, {
      value: 1201,
      label: '南城县'
    }, {
      value: 1202,
      label: '黎川县'
    }, {
      value: 1203,
      label: '南丰县'
    }, {
      value: 1204,
      label: '崇仁县'
    }, {
      value: 1205,
      label: '乐安县'
    }, {
      value: 1206,
      label: '宜黄县'
    }, {
      value: 1207,
      label: '金溪县'
    }, {
      value: 1208,
      label: '资溪县'
    }, {
      value: 1209,
      label: '东乡县'
    }, {
      value: 1210,
      label: '广昌县'
    }]
  }, {
    value: 134,
    label: '上饶',
    children: [{
      value: 1211,
      label: '信州区'
    }, {
      value: 1212,
      label: '上饶县'
    }, {
      value: 1213,
      label: '广丰县'
    }, {
      value: 1214,
      label: '玉山县'
    }, {
      value: 1215,
      label: '铅山县'
    }, {
      value: 1216,
      label: '横峰县'
    }, {
      value: 1217,
      label: '弋阳县'
    }, {
      value: 1218,
      label: '余干县'
    }, {
      value: 1219,
      label: '鄱阳县'
    }, {
      value: 1220,
      label: '万年县'
    }, {
      value: 1221,
      label: '婺源县'
    }, {
      value: 1222,
      label: '德兴'
    }]
  }]
}, {
  value: 15,
  label: '山东',
  children: [{
    value: 135,
    label: '济南',
    children: [{
      value: 1223,
      label: '历下区'
    }, {
      value: 1224,
      label: '中区'
    }, {
      value: 1225,
      label: '槐荫区'
    }, {
      value: 1226,
      label: '天桥区'
    }, {
      value: 1227,
      label: '历城区'
    }, {
      value: 1228,
      label: '长清区'
    }, {
      value: 1229,
      label: '平阴县'
    }, {
      value: 1230,
      label: '济阳县'
    }, {
      value: 1231,
      label: '商河县'
    }, {
      value: 1232,
      label: '章丘'
    }]
  }, {
    value: 136,
    label: '青岛',
    children: [{
      value: 1233,
      label: '南区'
    }, {
      value: 1234,
      label: '北区'
    }, {
      value: 1235,
      label: '四方区'
    }, {
      value: 1236,
      label: '黄岛区'
    }, {
      value: 1237,
      label: '崂山区'
    }, {
      value: 1238,
      label: '李沧区'
    }, {
      value: 1239,
      label: '城阳区'
    }, {
      value: 1240,
      label: '胶州'
    }, {
      value: 1241,
      label: '即墨'
    }, {
      value: 1242,
      label: '平度'
    }, {
      value: 1243,
      label: '胶南'
    }, {
      value: 1244,
      label: '莱西'
    }]
  }, {
    value: 137,
    label: '淄博',
    children: [{
      value: 1245,
      label: '淄川区'
    }, {
      value: 1246,
      label: '张店区'
    }, {
      value: 1247,
      label: '博山区'
    }, {
      value: 1248,
      label: '临淄区'
    }, {
      value: 1249,
      label: '周村区'
    }, {
      value: 1250,
      label: '桓台县'
    }, {
      value: 1251,
      label: '高青县'
    }, {
      value: 1252,
      label: '沂源县'
    }]
  }, {
    value: 138,
    label: '枣庄',
    children: [{
      value: 1253,
      label: '中区'
    }, {
      value: 1254,
      label: '薛城区'
    }, {
      value: 1255,
      label: '峄城区'
    }, {
      value: 1256,
      label: '台儿庄区'
    }, {
      value: 1257,
      label: '山亭区'
    }, {
      value: 1258,
      label: '滕州'
    }]
  }, {
    value: 139,
    label: '东营',
    children: [{
      value: 1259,
      label: '东营区'
    }, {
      value: 1260,
      label: '河口区'
    }, {
      value: 1261,
      label: '垦利县'
    }, {
      value: 1262,
      label: '利津县'
    }, {
      value: 1263,
      label: '广饶县'
    }]
  }, {
    value: 140,
    label: '烟台',
    children: [{
      value: 1264,
      label: '芝罘区'
    }, {
      value: 1265,
      label: '福山区'
    }, {
      value: 1266,
      label: '牟平区'
    }, {
      value: 1267,
      label: '莱山区'
    }, {
      value: 1268,
      label: '长岛县'
    }, {
      value: 1269,
      label: '龙口'
    }, {
      value: 1270,
      label: '莱阳'
    }, {
      value: 1271,
      label: '莱州'
    }, {
      value: 1272,
      label: '蓬莱'
    }, {
      value: 1273,
      label: '招远'
    }, {
      value: 1274,
      label: '栖霞'
    }, {
      value: 1275,
      label: '海阳'
    }]
  }, {
    value: 141,
    label: '潍坊',
    children: [{
      value: 1276,
      label: '潍城区'
    }, {
      value: 1277,
      label: '寒亭区'
    }, {
      value: 1278,
      label: '坊子区'
    }, {
      value: 1279,
      label: '奎文区'
    }, {
      value: 1280,
      label: '临朐县'
    }, {
      value: 1281,
      label: '昌乐县'
    }, {
      value: 1282,
      label: '青州'
    }, {
      value: 1283,
      label: '诸城'
    }, {
      value: 1284,
      label: '寿光'
    }, {
      value: 1285,
      label: '安丘'
    }, {
      value: 1286,
      label: '高密'
    }, {
      value: 1287,
      label: '昌邑'
    }]
  }, {
    value: 142,
    label: '济宁',
    children: [{
      value: 1288,
      label: '中区'
    }, {
      value: 1289,
      label: '任城区'
    }, {
      value: 1290,
      label: '微山县'
    }, {
      value: 1291,
      label: '鱼台县'
    }, {
      value: 1292,
      label: '金乡县'
    }, {
      value: 1293,
      label: '嘉祥县'
    }, {
      value: 1294,
      label: '汶上县'
    }, {
      value: 1295,
      label: '泗水县'
    }, {
      value: 1296,
      label: '梁山县'
    }, {
      value: 1297,
      label: '曲阜'
    }, {
      value: 1298,
      label: '兖州'
    }, {
      value: 1299,
      label: '邹城'
    }]
  }, {
    value: 143,
    label: '泰安',
    children: [{
      value: 1300,
      label: '泰山区'
    }, {
      value: 1301,
      label: '岱岳区'
    }, {
      value: 1302,
      label: '宁阳县'
    }, {
      value: 1303,
      label: '东平县'
    }, {
      value: 1304,
      label: '新泰'
    }, {
      value: 1305,
      label: '肥城'
    }]
  }, {
    value: 144,
    label: '威海',
    children: [{
      value: 1306,
      label: '环翠区'
    }, {
      value: 1307,
      label: '文登'
    }, {
      value: 1308,
      label: '荣成'
    }, {
      value: 1309,
      label: '乳山'
    }]
  }, {
    value: 145,
    label: '日照',
    children: [{
      value: 1310,
      label: '东港区'
    }, {
      value: 1311,
      label: '岚山区'
    }, {
      value: 1312,
      label: '五莲县'
    }, {
      value: 1313,
      label: '莒县'
    }]
  }, {
    value: 146,
    label: '莱芜',
    children: [{
      value: 1314,
      label: '莱城区'
    }, {
      value: 1315,
      label: '钢城区'
    }]
  }, {
    value: 147,
    label: '临沂',
    children: [{
      value: 1316,
      label: '兰山区'
    }, {
      value: 1317,
      label: '罗庄区'
    }, {
      value: 1318,
      label: '河东区'
    }, {
      value: 1319,
      label: '沂南县'
    }, {
      value: 1320,
      label: '郯城县'
    }, {
      value: 1321,
      label: '沂水县'
    }, {
      value: 1322,
      label: '苍山县'
    }, {
      value: 1323,
      label: '费县'
    }, {
      value: 1324,
      label: '平邑县'
    }, {
      value: 1325,
      label: '莒南县'
    }, {
      value: 1326,
      label: '蒙阴县'
    }, {
      value: 1327,
      label: '临沭县'
    }]
  }, {
    value: 148,
    label: '德州',
    children: [{
      value: 1328,
      label: '德城区'
    }, {
      value: 1329,
      label: '陵县'
    }, {
      value: 1330,
      label: '宁津县'
    }, {
      value: 1331,
      label: '庆云县'
    }, {
      value: 1332,
      label: '临邑县'
    }, {
      value: 1333,
      label: '齐河县'
    }, {
      value: 1334,
      label: '平原县'
    }, {
      value: 1335,
      label: '夏津县'
    }, {
      value: 1336,
      label: '武城县'
    }, {
      value: 1337,
      label: '乐陵'
    }, {
      value: 1338,
      label: '禹城'
    }]
  }, {
    value: 149,
    label: '聊城',
    children: [{
      value: 1339,
      label: '东昌府区'
    }, {
      value: 1340,
      label: '阳谷县'
    }, {
      value: 1341,
      label: '莘县'
    }, {
      value: 1342,
      label: '茌平县'
    }, {
      value: 1343,
      label: '东阿县'
    }, {
      value: 1344,
      label: '冠县'
    }, {
      value: 1345,
      label: '高唐县'
    }, {
      value: 1346,
      label: '临清'
    }]
  }, {
    value: 150,
    label: '滨州',
    children: [{
      value: 1347,
      label: '滨城区'
    }, {
      value: 1348,
      label: '惠民县'
    }, {
      value: 1349,
      label: '阳信县'
    }, {
      value: 1350,
      label: '无棣县'
    }, {
      value: 1351,
      label: '沾化县'
    }, {
      value: 1352,
      label: '博兴县'
    }, {
      value: 1353,
      label: '邹平县'
    }]
  }, {
    value: 151,
    label: '荷泽',
    children: [{
      value: 1354,
      label: '牡丹区'
    }, {
      value: 1355,
      label: '曹县'
    }, {
      value: 1356,
      label: '单县'
    }, {
      value: 1357,
      label: '成武县'
    }, {
      value: 1358,
      label: '巨野县'
    }, {
      value: 1359,
      label: '郓城县'
    }, {
      value: 1360,
      label: '鄄城县'
    }, {
      value: 1361,
      label: '定陶县'
    }, {
      value: 1362,
      label: '东明县'
    }]
  }]
}, {
  value: 16,
  label: '河南',
  children: [{
    value: 152,
    label: '郑州',
    children: [{
      value: 1363,
      label: '中原区'
    }, {
      value: 1364,
      label: '二七区'
    }, {
      value: 1365,
      label: '管城回族区'
    }, {
      value: 1366,
      label: '金水区'
    }, {
      value: 1367,
      label: '上街区'
    }, {
      value: 1368,
      label: '惠济区'
    }, {
      value: 1369,
      label: '中牟县'
    }, {
      value: 1370,
      label: '巩义'
    }, {
      value: 1371,
      label: '荥阳'
    }, {
      value: 1372,
      label: '新密'
    }, {
      value: 1373,
      label: '新郑'
    }, {
      value: 1374,
      label: '登封'
    }]
  }, {
    value: 153,
    label: '开封',
    children: [{
      value: 1375,
      label: '龙亭区'
    }, {
      value: 1376,
      label: '顺河回族区'
    }, {
      value: 1377,
      label: '鼓楼区'
    }, {
      value: 1378,
      label: '南关区'
    }, {
      value: 1379,
      label: '郊区'
    }, {
      value: 1380,
      label: '杞县'
    }, {
      value: 1381,
      label: '通许县'
    }, {
      value: 1382,
      label: '尉氏县'
    }, {
      value: 1383,
      label: '开封县'
    }, {
      value: 1384,
      label: '兰考县'
    }]
  }, {
    value: 154,
    label: '洛阳',
    children: [{
      value: 1385,
      label: '老城区'
    }, {
      value: 1386,
      label: '西工区'
    }, {
      value: 1387,
      label: '廛河回族区'
    }, {
      value: 1388,
      label: '涧西区'
    }, {
      value: 1389,
      label: '吉利区'
    }, {
      value: 1390,
      label: '洛龙区'
    }, {
      value: 1391,
      label: '孟津县'
    }, {
      value: 1392,
      label: '新安县'
    }, {
      value: 1393,
      label: '栾川县'
    }, {
      value: 1394,
      label: '嵩县'
    }, {
      value: 1395,
      label: '汝阳县'
    }, {
      value: 1396,
      label: '宜阳县'
    }, {
      value: 1397,
      label: '洛宁县'
    }, {
      value: 1398,
      label: '伊川县'
    }, {
      value: 1399,
      label: '偃师'
    }]
  }, {
    value: 155,
    label: '平顶山',
    children: [{
      value: 1400,
      label: '新华区'
    }, {
      value: 1401,
      label: '卫东区'
    }, {
      value: 1402,
      label: '石龙区'
    }, {
      value: 1403,
      label: '湛河区'
    }, {
      value: 1404,
      label: '宝丰县'
    }, {
      value: 1405,
      label: '叶县'
    }, {
      value: 1406,
      label: '鲁山县'
    }, {
      value: 1407,
      label: '郏县'
    }, {
      value: 1408,
      label: '舞钢'
    }, {
      value: 1409,
      label: '汝州'
    }]
  }, {
    value: 156,
    label: '安阳',
    children: [{
      value: 1410,
      label: '文峰区'
    }, {
      value: 1411,
      label: '北关区'
    }, {
      value: 1412,
      label: '殷都区'
    }, {
      value: 1413,
      label: '龙安区'
    }, {
      value: 1414,
      label: '安阳县'
    }, {
      value: 1415,
      label: '汤阴县'
    }, {
      value: 1416,
      label: '滑县'
    }, {
      value: 1417,
      label: '内黄县'
    }, {
      value: 1418,
      label: '林州'
    }]
  }, {
    value: 157,
    label: '鹤壁',
    children: [{
      value: 1419,
      label: '鹤山区'
    }, {
      value: 1420,
      label: '山城区'
    }, {
      value: 1421,
      label: '淇滨区'
    }, {
      value: 1422,
      label: '浚县'
    }, {
      value: 1423,
      label: '淇县'
    }]
  }, {
    value: 158,
    label: '新乡',
    children: [{
      value: 1424,
      label: '红旗区'
    }, {
      value: 1425,
      label: '卫滨区'
    }, {
      value: 1426,
      label: '凤泉区'
    }, {
      value: 1427,
      label: '牧野区'
    }, {
      value: 1428,
      label: '新乡县'
    }, {
      value: 1429,
      label: '获嘉县'
    }, {
      value: 1430,
      label: '原阳县'
    }, {
      value: 1431,
      label: '延津县'
    }, {
      value: 1432,
      label: '封丘县'
    }, {
      value: 1433,
      label: '长垣县'
    }, {
      value: 1434,
      label: '卫辉'
    }, {
      value: 1435,
      label: '辉县'
    }]
  }, {
    value: 159,
    label: '焦作',
    children: [{
      value: 1436,
      label: '解放区'
    }, {
      value: 1437,
      label: '中站区'
    }, {
      value: 1438,
      label: '马村区'
    }, {
      value: 1439,
      label: '山阳区'
    }, {
      value: 1440,
      label: '修武县'
    }, {
      value: 1441,
      label: '博爱县'
    }, {
      value: 1442,
      label: '武陟县'
    }, {
      value: 1443,
      label: '温县'
    }, {
      value: 1444,
      label: '济源'
    }, {
      value: 1445,
      label: '沁阳'
    }, {
      value: 1446,
      label: '孟州'
    }]
  }, {
    value: 160,
    label: '濮阳',
    children: [{
      value: 1447,
      label: '华龙区'
    }, {
      value: 1448,
      label: '清丰县'
    }, {
      value: 1449,
      label: '南乐县'
    }, {
      value: 1450,
      label: '范县'
    }, {
      value: 1451,
      label: '台前县'
    }, {
      value: 1452,
      label: '濮阳县'
    }]
  }, {
    value: 161,
    label: '许昌',
    children: [{
      value: 1453,
      label: '魏都区'
    }, {
      value: 1454,
      label: '许昌县'
    }, {
      value: 1455,
      label: '鄢陵县'
    }, {
      value: 1456,
      label: '襄城县'
    }, {
      value: 1457,
      label: '禹州'
    }, {
      value: 1458,
      label: '长葛'
    }]
  }, {
    value: 162,
    label: '漯河',
    children: [{
      value: 1459,
      label: '源汇区'
    }, {
      value: 1460,
      label: '郾城区'
    }, {
      value: 1461,
      label: '召陵区'
    }, {
      value: 1462,
      label: '舞阳县'
    }, {
      value: 1463,
      label: '临颍县'
    }]
  }, {
    value: 163,
    label: '三门峡',
    children: [{
      value: 1464,
      label: '辖区'
    }, {
      value: 1465,
      label: '湖滨区'
    }, {
      value: 1466,
      label: '渑池县'
    }, {
      value: 1467,
      label: '陕县'
    }, {
      value: 1468,
      label: '卢氏县'
    }, {
      value: 1469,
      label: '义马'
    }, {
      value: 1470,
      label: '灵宝'
    }]
  }, {
    value: 164,
    label: '南阳',
    children: [{
      value: 1471,
      label: '宛城区'
    }, {
      value: 1472,
      label: '卧龙区'
    }, {
      value: 1473,
      label: '南召县'
    }, {
      value: 1474,
      label: '方城县'
    }, {
      value: 1475,
      label: '西峡县'
    }, {
      value: 1476,
      label: '镇平县'
    }, {
      value: 1477,
      label: '内乡县'
    }, {
      value: 1478,
      label: '淅川县'
    }, {
      value: 1479,
      label: '社旗县'
    }, {
      value: 1480,
      label: '唐河县'
    }, {
      value: 1481,
      label: '新野县'
    }, {
      value: 1482,
      label: '桐柏县'
    }, {
      value: 1483,
      label: '邓州'
    }]
  }, {
    value: 165,
    label: '商丘',
    children: [{
      value: 1484,
      label: '梁园区'
    }, {
      value: 1485,
      label: '睢阳区'
    }, {
      value: 1486,
      label: '民权县'
    }, {
      value: 1487,
      label: '睢县'
    }, {
      value: 1488,
      label: '宁陵县'
    }, {
      value: 1489,
      label: '柘城县'
    }, {
      value: 1490,
      label: '虞城县'
    }, {
      value: 1491,
      label: '夏邑县'
    }, {
      value: 1492,
      label: '永城'
    }]
  }, {
    value: 166,
    label: '信阳',
    children: [{
      value: 1493,
      label: '浉河区'
    }, {
      value: 1494,
      label: '平桥区'
    }, {
      value: 1495,
      label: '罗山县'
    }, {
      value: 1496,
      label: '光山县'
    }, {
      value: 1497,
      label: '新县'
    }, {
      value: 1498,
      label: '商城县'
    }, {
      value: 1499,
      label: '固始县'
    }, {
      value: 1500,
      label: '潢川县'
    }, {
      value: 1501,
      label: '淮滨县'
    }, {
      value: 1502,
      label: '息县'
    }]
  }, {
    value: 167,
    label: '周口',
    children: [{
      value: 1503,
      label: '川汇区'
    }, {
      value: 1504,
      label: '扶沟县'
    }, {
      value: 1505,
      label: '西华县'
    }, {
      value: 1506,
      label: '商水县'
    }, {
      value: 1507,
      label: '沈丘县'
    }, {
      value: 1508,
      label: '郸城县'
    }, {
      value: 1509,
      label: '淮阳县'
    }, {
      value: 1510,
      label: '太康县'
    }, {
      value: 1511,
      label: '鹿邑县'
    }, {
      value: 1512,
      label: '项城'
    }]
  }, {
    value: 168,
    label: '驻马店',
    children: [{
      value: 1513,
      label: '驿城区'
    }, {
      value: 1514,
      label: '西平县'
    }, {
      value: 1515,
      label: '上蔡县'
    }, {
      value: 1516,
      label: '平舆县'
    }, {
      value: 1517,
      label: '正阳县'
    }, {
      value: 1518,
      label: '确山县'
    }, {
      value: 1519,
      label: '泌阳县'
    }, {
      value: 1520,
      label: '汝南县'
    }, {
      value: 1521,
      label: '遂平县'
    }, {
      value: 1522,
      label: '新蔡县'
    }]
  }]
}, {
  value: 17,
  label: '湖北',
  children: [{
    value: 169,
    label: '武汉',
    children: [{
      value: 1523,
      label: '江岸区'
    }, {
      value: 1524,
      label: '江汉区'
    }, {
      value: 1525,
      label: '硚口区'
    }, {
      value: 1526,
      label: '汉阳区'
    }, {
      value: 1527,
      label: '武昌区'
    }, {
      value: 1528,
      label: '青山区'
    }, {
      value: 1529,
      label: '洪山区'
    }, {
      value: 1530,
      label: '东西湖区'
    }, {
      value: 1531,
      label: '汉南区'
    }, {
      value: 1532,
      label: '蔡甸区'
    }, {
      value: 1533,
      label: '江夏区'
    }, {
      value: 1534,
      label: '黄陂区'
    }, {
      value: 1535,
      label: '新洲区'
    }]
  }, {
    value: 170,
    label: '黄石',
    children: [{
      value: 1536,
      label: '黄石港区'
    }, {
      value: 1537,
      label: '西塞山区'
    }, {
      value: 1538,
      label: '下陆区'
    }, {
      value: 1539,
      label: '铁山区'
    }, {
      value: 1540,
      label: '阳新县'
    }, {
      value: 1541,
      label: '大冶'
    }]
  }, {
    value: 171,
    label: '十堰',
    children: [{
      value: 1542,
      label: '茅箭区'
    }, {
      value: 1543,
      label: '张湾区'
    }, {
      value: 1544,
      label: '郧县'
    }, {
      value: 1545,
      label: '郧西县'
    }, {
      value: 1546,
      label: '竹山县'
    }, {
      value: 1547,
      label: '竹溪县'
    }, {
      value: 1548,
      label: '房县'
    }, {
      value: 1549,
      label: '丹江口'
    }]
  }, {
    value: 172,
    label: '宜昌',
    children: [{
      value: 1550,
      label: '西陵区'
    }, {
      value: 1551,
      label: '伍家岗区'
    }, {
      value: 1552,
      label: '点军区'
    }, {
      value: 1553,
      label: '猇亭区'
    }, {
      value: 1554,
      label: '夷陵区'
    }, {
      value: 1555,
      label: '远安县'
    }, {
      value: 1556,
      label: '兴山县'
    }, {
      value: 1557,
      label: '秭归县'
    }, {
      value: 1558,
      label: '长阳土家族自治县'
    }, {
      value: 1559,
      label: '五峰土家族自治县'
    }, {
      value: 1560,
      label: '宜都'
    }, {
      value: 1561,
      label: '当阳'
    }, {
      value: 1562,
      label: '枝江'
    }]
  }, {
    value: 173,
    label: '襄樊',
    children: [{
      value: 1563,
      label: '襄城区'
    }, {
      value: 1564,
      label: '樊城区'
    }, {
      value: 1565,
      label: '襄阳区'
    }, {
      value: 1566,
      label: '南漳县'
    }, {
      value: 1567,
      label: '谷城县'
    }, {
      value: 1568,
      label: '保康县'
    }, {
      value: 1569,
      label: '老河口'
    }, {
      value: 1570,
      label: '枣阳'
    }, {
      value: 1571,
      label: '宜城'
    }]
  }, {
    value: 174,
    label: '鄂州',
    children: [{
      value: 1572,
      label: '梁子湖区'
    }, {
      value: 1573,
      label: '华容区'
    }, {
      value: 1574,
      label: '鄂城区'
    }]
  }, {
    value: 175,
    label: '荆门',
    children: [{
      value: 1575,
      label: '东宝区'
    }, {
      value: 1576,
      label: '掇刀区'
    }, {
      value: 1577,
      label: '京山县'
    }, {
      value: 1578,
      label: '沙洋县'
    }, {
      value: 1579,
      label: '钟祥'
    }]
  }, {
    value: 176,
    label: '孝感',
    children: [{
      value: 1580,
      label: '孝南区'
    }, {
      value: 1581,
      label: '孝昌县'
    }, {
      value: 1582,
      label: '大悟县'
    }, {
      value: 1583,
      label: '云梦县'
    }, {
      value: 1584,
      label: '应城'
    }, {
      value: 1585,
      label: '安陆'
    }, {
      value: 1586,
      label: '汉川'
    }]
  }, {
    value: 177,
    label: '荆州',
    children: [{
      value: 1587,
      label: '沙区'
    }, {
      value: 1588,
      label: '荆州区'
    }, {
      value: 1589,
      label: '公安县'
    }, {
      value: 1590,
      label: '监利县'
    }, {
      value: 1591,
      label: '江陵县'
    }, {
      value: 1592,
      label: '石首'
    }, {
      value: 1593,
      label: '洪湖'
    }, {
      value: 1594,
      label: '松滋'
    }]
  }, {
    value: 178,
    label: '黄冈',
    children: [{
      value: 1595,
      label: '黄州区'
    }, {
      value: 1596,
      label: '团风县'
    }, {
      value: 1597,
      label: '红安县'
    }, {
      value: 1598,
      label: '罗田县'
    }, {
      value: 1599,
      label: '英山县'
    }, {
      value: 1600,
      label: '浠水县'
    }, {
      value: 1601,
      label: '蕲春县'
    }, {
      value: 1602,
      label: '黄梅县'
    }, {
      value: 1603,
      label: '麻城'
    }, {
      value: 1604,
      label: '武穴'
    }]
  }, {
    value: 179,
    label: '咸宁',
    children: [{
      value: 1605,
      label: '咸安区'
    }, {
      value: 1606,
      label: '嘉鱼县'
    }, {
      value: 1607,
      label: '通城县'
    }, {
      value: 1608,
      label: '崇阳县'
    }, {
      value: 1609,
      label: '通山县'
    }, {
      value: 1610,
      label: '赤壁'
    }]
  }, {
    value: 180,
    label: '随州',
    children: [{
      value: 1611,
      label: '曾都区'
    }, {
      value: 1612,
      label: '广水'
    }]
  }, {
    value: 181,
    label: '恩施',
    children: [{
      value: 1613,
      label: '恩施'
    }, {
      value: 1614,
      label: '利川'
    }, {
      value: 1615,
      label: '建始县'
    }, {
      value: 1616,
      label: '巴东县'
    }, {
      value: 1617,
      label: '宣恩县'
    }, {
      value: 1618,
      label: '咸丰县'
    }, {
      value: 1619,
      label: '来凤县'
    }, {
      value: 1620,
      label: '鹤峰县'
    }]
  }, {
    value: 182,
    label: '神农架',
    children: [{
      value: 1621,
      label: '仙桃'
    }, {
      value: 1622,
      label: '潜江'
    }, {
      value: 1623,
      label: '天门'
    }, {
      value: 1624,
      label: '神农架林区'
    }]
  }]
}, {
  value: 18,
  label: '湖南',
  children: [{
    value: 183,
    label: '长沙',
    children: [{
      value: 1625,
      label: '芙蓉区'
    }, {
      value: 1626,
      label: '天心区'
    }, {
      value: 1627,
      label: '岳麓区'
    }, {
      value: 1628,
      label: '开福区'
    }, {
      value: 1629,
      label: '雨花区'
    }, {
      value: 1630,
      label: '长沙县'
    }, {
      value: 1631,
      label: '望城县'
    }, {
      value: 1632,
      label: '宁乡县'
    }, {
      value: 1633,
      label: '浏阳'
    }]
  }, {
    value: 184,
    label: '株洲',
    children: [{
      value: 1634,
      label: '荷塘区'
    }, {
      value: 1635,
      label: '芦淞区'
    }, {
      value: 1636,
      label: '石峰区'
    }, {
      value: 1637,
      label: '天元区'
    }, {
      value: 1638,
      label: '株洲县'
    }, {
      value: 1639,
      label: '攸县'
    }, {
      value: 1640,
      label: '茶陵县'
    }, {
      value: 1641,
      label: '炎陵县'
    }, {
      value: 1642,
      label: '醴陵'
    }]
  }, {
    value: 185,
    label: '湘潭',
    children: [{
      value: 1643,
      label: '雨湖区'
    }, {
      value: 1644,
      label: '岳塘区'
    }, {
      value: 1645,
      label: '湘潭县'
    }, {
      value: 1646,
      label: '湘乡'
    }, {
      value: 1647,
      label: '韶山'
    }]
  }, {
    value: 186,
    label: '衡阳',
    children: [{
      value: 1648,
      label: '珠晖区'
    }, {
      value: 1649,
      label: '雁峰区'
    }, {
      value: 1650,
      label: '石鼓区'
    }, {
      value: 1651,
      label: '蒸湘区'
    }, {
      value: 1652,
      label: '南岳区'
    }, {
      value: 1653,
      label: '衡阳县'
    }, {
      value: 1654,
      label: '衡南县'
    }, {
      value: 1655,
      label: '衡山县'
    }, {
      value: 1656,
      label: '衡东县'
    }, {
      value: 1657,
      label: '祁东县'
    }, {
      value: 1658,
      label: '耒阳'
    }, {
      value: 1659,
      label: '常宁'
    }]
  }, {
    value: 187,
    label: '邵阳',
    children: [{
      value: 1660,
      label: '双清区'
    }, {
      value: 1661,
      label: '大祥区'
    }, {
      value: 1662,
      label: '北塔区'
    }, {
      value: 1663,
      label: '邵东县'
    }, {
      value: 1664,
      label: '新邵县'
    }, {
      value: 1665,
      label: '邵阳县'
    }, {
      value: 1666,
      label: '隆回县'
    }, {
      value: 1667,
      label: '洞口县'
    }, {
      value: 1668,
      label: '绥宁县'
    }, {
      value: 1669,
      label: '新宁县'
    }, {
      value: 1670,
      label: '城步苗族自治县'
    }, {
      value: 1671,
      label: '武冈'
    }]
  }, {
    value: 188,
    label: '岳阳',
    children: [{
      value: 1672,
      label: '岳阳楼区'
    }, {
      value: 1673,
      label: '云溪区'
    }, {
      value: 1674,
      label: '君山区'
    }, {
      value: 1675,
      label: '岳阳县'
    }, {
      value: 1676,
      label: '华容县'
    }, {
      value: 1677,
      label: '湘阴县'
    }, {
      value: 1678,
      label: '平江县'
    }, {
      value: 1679,
      label: '汨罗'
    }, {
      value: 1680,
      label: '临湘'
    }]
  }, {
    value: 189,
    label: '常德',
    children: [{
      value: 1681,
      label: '武陵区'
    }, {
      value: 1682,
      label: '鼎城区'
    }, {
      value: 1683,
      label: '安乡县'
    }, {
      value: 1684,
      label: '汉寿县'
    }, {
      value: 1685,
      label: '澧县'
    }, {
      value: 1686,
      label: '临澧县'
    }, {
      value: 1687,
      label: '桃源县'
    }, {
      value: 1688,
      label: '石门县'
    }, {
      value: 1689,
      label: '津'
    }]
  }, {
    value: 190,
    label: '张家界',
    children: [{
      value: 1690,
      label: '永定区'
    }, {
      value: 1691,
      label: '武陵源区'
    }, {
      value: 1692,
      label: '慈利县'
    }, {
      value: 1693,
      label: '桑植县'
    }]
  }, {
    value: 191,
    label: '益阳',
    children: [{
      value: 1694,
      label: '资阳区'
    }, {
      value: 1695,
      label: '赫山区'
    }, {
      value: 1696,
      label: '南县'
    }, {
      value: 1697,
      label: '桃江县'
    }, {
      value: 1698,
      label: '安化县'
    }, {
      value: 1699,
      label: '沅江'
    }]
  }, {
    value: 192,
    label: '郴州',
    children: [{
      value: 1700,
      label: '北湖区'
    }, {
      value: 1701,
      label: '苏仙区'
    }, {
      value: 1702,
      label: '桂阳县'
    }, {
      value: 1703,
      label: '宜章县'
    }, {
      value: 1704,
      label: '永兴县'
    }, {
      value: 1705,
      label: '嘉禾县'
    }, {
      value: 1706,
      label: '临武县'
    }, {
      value: 1707,
      label: '汝城县'
    }, {
      value: 1708,
      label: '桂东县'
    }, {
      value: 1709,
      label: '安仁县'
    }, {
      value: 1710,
      label: '资兴'
    }]
  }, {
    value: 193,
    label: '永州',
    children: [{
      value: 1711,
      label: '芝山区'
    }, {
      value: 1712,
      label: '冷水滩区'
    }, {
      value: 1713,
      label: '祁阳县'
    }, {
      value: 1714,
      label: '东安县'
    }, {
      value: 1715,
      label: '双牌县'
    }, {
      value: 1716,
      label: '道县'
    }, {
      value: 1717,
      label: '江永县'
    }, {
      value: 1718,
      label: '宁远县'
    }, {
      value: 1719,
      label: '蓝山县'
    }, {
      value: 1720,
      label: '新田县'
    }, {
      value: 1721,
      label: '江华瑶族自治县'
    }]
  }, {
    value: 194,
    label: '怀化',
    children: [{
      value: 1722,
      label: '鹤城区'
    }, {
      value: 1723,
      label: '中方县'
    }, {
      value: 1724,
      label: '沅陵县'
    }, {
      value: 1725,
      label: '辰溪县'
    }, {
      value: 1726,
      label: '溆浦县'
    }, {
      value: 1727,
      label: '会同县'
    }, {
      value: 1728,
      label: '麻阳苗族自治县'
    }, {
      value: 1729,
      label: '新晃侗族自治县'
    }, {
      value: 1730,
      label: '芷江侗族自治县'
    }, {
      value: 1731,
      label: '靖州苗族侗族自治县'
    }, {
      value: 1732,
      label: '通道侗族自治县'
    }, {
      value: 1733,
      label: '洪江'
    }]
  }, {
    value: 195,
    label: '娄底',
    children: [{
      value: 1734,
      label: '娄星区'
    }, {
      value: 1735,
      label: '双峰县'
    }, {
      value: 1736,
      label: '新化县'
    }, {
      value: 1737,
      label: '冷水江'
    }, {
      value: 1738,
      label: '涟源'
    }]
  }, {
    value: 196,
    label: '湘西',
    children: [{
      value: 1739,
      label: '吉首'
    }, {
      value: 1740,
      label: '泸溪县'
    }, {
      value: 1741,
      label: '凤凰县'
    }, {
      value: 1742,
      label: '花垣县'
    }, {
      value: 1743,
      label: '保靖县'
    }, {
      value: 1744,
      label: '古丈县'
    }, {
      value: 1745,
      label: '永顺县'
    }, {
      value: 1746,
      label: '龙山县'
    }]
  }]
}, {
  value: 19,
  label: '广东',
  children: [{
    value: 197,
    label: '广州',
    children: [{
      value: 1747,
      label: '东山区'
    }, {
      value: 1748,
      label: '荔湾区'
    }, {
      value: 1749,
      label: '越秀区'
    }, {
      value: 1750,
      label: '海珠区'
    }, {
      value: 1751,
      label: '天河区'
    }, {
      value: 1752,
      label: '芳村区'
    }, {
      value: 1753,
      label: '白云区'
    }, {
      value: 1754,
      label: '黄埔区'
    }, {
      value: 1755,
      label: '番禺区'
    }, {
      value: 1756,
      label: '花都区'
    }, {
      value: 1757,
      label: '增城'
    }, {
      value: 1758,
      label: '从化'
    }]
  }, {
    value: 198,
    label: '韶关',
    children: [{
      value: 1759,
      label: '武江区'
    }, {
      value: 1760,
      label: '浈江区'
    }, {
      value: 1761,
      label: '曲江区'
    }, {
      value: 1762,
      label: '始兴县'
    }, {
      value: 1763,
      label: '仁化县'
    }, {
      value: 1764,
      label: '翁源县'
    }, {
      value: 1765,
      label: '乳源瑶族自治县'
    }, {
      value: 1766,
      label: '新丰县'
    }, {
      value: 1767,
      label: '乐昌'
    }, {
      value: 1768,
      label: '南雄'
    }]
  }, {
    value: 199,
    label: '深圳',
    children: [{
      value: 1769,
      label: '罗湖区'
    }, {
      value: 1770,
      label: '福田区'
    }, {
      value: 1771,
      label: '南山区'
    }, {
      value: 1772,
      label: '宝安区'
    }, {
      value: 1773,
      label: '龙岗区'
    }, {
      value: 1774,
      label: '盐田区'
    }]
  }, {
    value: 200,
    label: '珠海',
    children: [{
      value: 1775,
      label: '香洲区'
    }, {
      value: 1776,
      label: '斗门区'
    }, {
      value: 1777,
      label: '金湾区'
    }]
  }, {
    value: 201,
    label: '汕头',
    children: [{
      value: 1778,
      label: '龙湖区'
    }, {
      value: 1779,
      label: '金平区'
    }, {
      value: 1780,
      label: '濠江区'
    }, {
      value: 1781,
      label: '潮阳区'
    }, {
      value: 1782,
      label: '潮南区'
    }, {
      value: 1783,
      label: '澄海区'
    }, {
      value: 1784,
      label: '南澳县'
    }]
  }, {
    value: 202,
    label: '佛山',
    children: [{
      value: 1785,
      label: '禅城区'
    }, {
      value: 1786,
      label: '南海区'
    }, {
      value: 1787,
      label: '顺德区'
    }, {
      value: 1788,
      label: '三水区'
    }, {
      value: 1789,
      label: '高明区'
    }]
  }, {
    value: 203,
    label: '江门',
    children: [{
      value: 1790,
      label: '蓬江区'
    }, {
      value: 1791,
      label: '江海区'
    }, {
      value: 1792,
      label: '新会区'
    }, {
      value: 1793,
      label: '台山'
    }, {
      value: 1794,
      label: '开平'
    }, {
      value: 1795,
      label: '鹤山'
    }, {
      value: 1796,
      label: '恩平'
    }]
  }, {
    value: 204,
    label: '湛江',
    children: [{
      value: 1797,
      label: '赤坎区'
    }, {
      value: 1798,
      label: '霞山区'
    }, {
      value: 1799,
      label: '坡头区'
    }, {
      value: 1800,
      label: '麻章区'
    }, {
      value: 1801,
      label: '遂溪县'
    }, {
      value: 1802,
      label: '徐闻县'
    }, {
      value: 1803,
      label: '廉江'
    }, {
      value: 1804,
      label: '雷州'
    }, {
      value: 1805,
      label: '吴川'
    }]
  }, {
    value: 205,
    label: '茂名',
    children: [{
      value: 1806,
      label: '茂南区'
    }, {
      value: 1807,
      label: '茂港区'
    }, {
      value: 1808,
      label: '电白县'
    }, {
      value: 1809,
      label: '高州'
    }, {
      value: 1810,
      label: '化州'
    }, {
      value: 1811,
      label: '信宜'
    }]
  }, {
    value: 206,
    label: '肇庆',
    children: [{
      value: 1812,
      label: '端州区'
    }, {
      value: 1813,
      label: '鼎湖区'
    }, {
      value: 1814,
      label: '广宁县'
    }, {
      value: 1815,
      label: '怀集县'
    }, {
      value: 1816,
      label: '封开县'
    }, {
      value: 1817,
      label: '德庆县'
    }, {
      value: 1818,
      label: '高要'
    }, {
      value: 1819,
      label: '四会'
    }]
  }, {
    value: 207,
    label: '惠州',
    children: [{
      value: 1820,
      label: '惠城区'
    }, {
      value: 1821,
      label: '惠阳区'
    }, {
      value: 1822,
      label: '博罗县'
    }, {
      value: 1823,
      label: '惠东县'
    }, {
      value: 1824,
      label: '龙门县'
    }]
  }, {
    value: 208,
    label: '梅州',
    children: [{
      value: 1825,
      label: '梅江区'
    }, {
      value: 1826,
      label: '梅县'
    }, {
      value: 1827,
      label: '大埔县'
    }, {
      value: 1828,
      label: '丰顺县'
    }, {
      value: 1829,
      label: '五华县'
    }, {
      value: 1830,
      label: '平远县'
    }, {
      value: 1831,
      label: '蕉岭县'
    }, {
      value: 1832,
      label: '兴宁'
    }]
  }, {
    value: 209,
    label: '汕尾',
    children: [{
      value: 1833,
      label: '城区'
    }, {
      value: 1834,
      label: '海丰县'
    }, {
      value: 1835,
      label: '陆河县'
    }, {
      value: 1836,
      label: '陆丰'
    }]
  }, {
    value: 210,
    label: '河源',
    children: [{
      value: 1837,
      label: '源城区'
    }, {
      value: 1838,
      label: '紫金县'
    }, {
      value: 1839,
      label: '龙川县'
    }, {
      value: 1840,
      label: '连平县'
    }, {
      value: 1841,
      label: '和平县'
    }, {
      value: 1842,
      label: '东源县'
    }]
  }, {
    value: 211,
    label: '阳江',
    children: [{
      value: 1843,
      label: '江城区'
    }, {
      value: 1844,
      label: '阳西县'
    }, {
      value: 1845,
      label: '阳东县'
    }, {
      value: 1846,
      label: '阳春'
    }]
  }, {
    value: 212,
    label: '清远',
    children: [{
      value: 1847,
      label: '清城区'
    }, {
      value: 1848,
      label: '佛冈县'
    }, {
      value: 1849,
      label: '阳山县'
    }, {
      value: 1850,
      label: '连山壮族瑶族自治县'
    }, {
      value: 1851,
      label: '连南瑶族自治县'
    }, {
      value: 1852,
      label: '清新县'
    }, {
      value: 1853,
      label: '英德'
    }, {
      value: 1854,
      label: '连州'
    }]
  }, {
    value: 213,
    label: '东莞',
    children: []
  }, {
    value: 214,
    label: '中山',
    children: []
  }, {
    value: 215,
    label: '潮州',
    children: [{
      value: 1855,
      label: '湘桥区'
    }, {
      value: 1856,
      label: '潮安县'
    }, {
      value: 1857,
      label: '饶平县'
    }]
  }, {
    value: 216,
    label: '揭阳',
    children: [{
      value: 1858,
      label: '榕城区'
    }, {
      value: 1859,
      label: '揭东县'
    }, {
      value: 1860,
      label: '揭西县'
    }, {
      value: 1861,
      label: '惠来县'
    }, {
      value: 1862,
      label: '普宁'
    }]
  }, {
    value: 217,
    label: '云浮',
    children: [{
      value: 1863,
      label: '云城区'
    }, {
      value: 1864,
      label: '新兴县'
    }, {
      value: 1865,
      label: '郁南县'
    }, {
      value: 1866,
      label: '云安县'
    }, {
      value: 1867,
      label: '罗定'
    }]
  }]
}, {
  value: 20,
  label: '广西',
  children: [{
    value: 218,
    label: '南宁',
    children: [{
      value: 1868,
      label: '兴宁区'
    }, {
      value: 1869,
      label: '青秀区'
    }, {
      value: 1870,
      label: '江南区'
    }, {
      value: 1871,
      label: '西乡塘区'
    }, {
      value: 1872,
      label: '良庆区'
    }, {
      value: 1873,
      label: '邕宁区'
    }, {
      value: 1874,
      label: '武鸣县'
    }, {
      value: 1875,
      label: '隆安县'
    }, {
      value: 1876,
      label: '马山县'
    }, {
      value: 1877,
      label: '上林县'
    }, {
      value: 1878,
      label: '宾阳县'
    }, {
      value: 1879,
      label: '横县'
    }]
  }, {
    value: 219,
    label: '柳州',
    children: [{
      value: 1880,
      label: '城中区'
    }, {
      value: 1881,
      label: '鱼峰区'
    }, {
      value: 1882,
      label: '柳南区'
    }, {
      value: 1883,
      label: '柳北区'
    }, {
      value: 1884,
      label: '柳江县'
    }, {
      value: 1885,
      label: '柳城县'
    }, {
      value: 1886,
      label: '鹿寨县'
    }, {
      value: 1887,
      label: '融安县'
    }, {
      value: 1888,
      label: '融水苗族自治县'
    }, {
      value: 1889,
      label: '三江侗族自治县'
    }]
  }, {
    value: 220,
    label: '桂林',
    children: [{
      value: 1890,
      label: '秀峰区'
    }, {
      value: 1891,
      label: '叠彩区'
    }, {
      value: 1892,
      label: '象山区'
    }, {
      value: 1893,
      label: '七星区'
    }, {
      value: 1894,
      label: '雁山区'
    }, {
      value: 1895,
      label: '阳朔县'
    }, {
      value: 1896,
      label: '临桂县'
    }, {
      value: 1897,
      label: '灵川县'
    }, {
      value: 1898,
      label: '全州县'
    }, {
      value: 1899,
      label: '兴安县'
    }, {
      value: 1900,
      label: '永福县'
    }, {
      value: 1901,
      label: '灌阳县'
    }, {
      value: 1902,
      label: '龙胜各族自治县'
    }, {
      value: 1903,
      label: '资源县'
    }, {
      value: 1904,
      label: '平乐县'
    }, {
      value: 1905,
      label: '荔蒲县'
    }, {
      value: 1906,
      label: '恭城瑶族自治县'
    }]
  }, {
    value: 221,
    label: '梧州',
    children: [{
      value: 1907,
      label: '万秀区'
    }, {
      value: 1908,
      label: '蝶山区'
    }, {
      value: 1909,
      label: '长洲区'
    }, {
      value: 1910,
      label: '苍梧县'
    }, {
      value: 1911,
      label: '藤县'
    }, {
      value: 1912,
      label: '蒙山县'
    }, {
      value: 1913,
      label: '岑溪'
    }]
  }, {
    value: 222,
    label: '北海',
    children: [{
      value: 1914,
      label: '海城区'
    }, {
      value: 1915,
      label: '银海区'
    }, {
      value: 1916,
      label: '铁山港区'
    }, {
      value: 1917,
      label: '合浦县'
    }]
  }, {
    value: 223,
    label: '防城港',
    children: [{
      value: 1918,
      label: '港口区'
    }, {
      value: 1919,
      label: '防城区'
    }, {
      value: 1920,
      label: '上思县'
    }, {
      value: 1921,
      label: '东兴'
    }]
  }, {
    value: 224,
    label: '钦州',
    children: [{
      value: 1922,
      label: '钦南区'
    }, {
      value: 1923,
      label: '钦北区'
    }, {
      value: 1924,
      label: '灵山县'
    }, {
      value: 1925,
      label: '浦北县'
    }]
  }, {
    value: 225,
    label: '贵港',
    children: [{
      value: 1926,
      label: '港北区'
    }, {
      value: 1927,
      label: '港南区'
    }, {
      value: 1928,
      label: '覃塘区'
    }, {
      value: 1929,
      label: '平南县'
    }, {
      value: 1930,
      label: '桂平'
    }]
  }, {
    value: 226,
    label: '玉林',
    children: [{
      value: 1931,
      label: '玉州区'
    }, {
      value: 1932,
      label: '容县'
    }, {
      value: 1933,
      label: '陆川县'
    }, {
      value: 1934,
      label: '博白县'
    }, {
      value: 1935,
      label: '兴业县'
    }, {
      value: 1936,
      label: '北流'
    }]
  }, {
    value: 227,
    label: '百色',
    children: [{
      value: 1937,
      label: '右江区'
    }, {
      value: 1938,
      label: '田阳县'
    }, {
      value: 1939,
      label: '田东县'
    }, {
      value: 1940,
      label: '平果县'
    }, {
      value: 1941,
      label: '德保县'
    }, {
      value: 1942,
      label: '靖西县'
    }, {
      value: 1943,
      label: '那坡县'
    }, {
      value: 1944,
      label: '凌云县'
    }, {
      value: 1945,
      label: '乐业县'
    }, {
      value: 1946,
      label: '田林县'
    }, {
      value: 1947,
      label: '西林县'
    }, {
      value: 1948,
      label: '隆林各族自治县'
    }]
  }, {
    value: 228,
    label: '贺州',
    children: [{
      value: 1949,
      label: '八步区'
    }, {
      value: 1950,
      label: '昭平县'
    }, {
      value: 1951,
      label: '钟山县'
    }, {
      value: 1952,
      label: '富川瑶族自治县'
    }]
  }, {
    value: 229,
    label: '河池',
    children: [{
      value: 1953,
      label: '金城江区'
    }, {
      value: 1954,
      label: '南丹县'
    }, {
      value: 1955,
      label: '天峨县'
    }, {
      value: 1956,
      label: '凤山县'
    }, {
      value: 1957,
      label: '东兰县'
    }, {
      value: 1958,
      label: '罗城仫佬族自治县'
    }, {
      value: 1959,
      label: '环江毛南族自治县'
    }, {
      value: 1960,
      label: '巴马瑶族自治县'
    }, {
      value: 1961,
      label: '都安瑶族自治县'
    }, {
      value: 1962,
      label: '大化瑶族自治县'
    }, {
      value: 1963,
      label: '宜州'
    }]
  }, {
    value: 230,
    label: '来宾',
    children: [{
      value: 1964,
      label: '兴宾区'
    }, {
      value: 1965,
      label: '忻城县'
    }, {
      value: 1966,
      label: '象州县'
    }, {
      value: 1967,
      label: '武宣县'
    }, {
      value: 1968,
      label: '金秀瑶族自治县'
    }, {
      value: 1969,
      label: '合山'
    }]
  }, {
    value: 231,
    label: '崇左',
    children: [{
      value: 1970,
      label: '江洲区'
    }, {
      value: 1971,
      label: '扶绥县'
    }, {
      value: 1972,
      label: '宁明县'
    }, {
      value: 1973,
      label: '龙州县'
    }, {
      value: 1974,
      label: '大新县'
    }, {
      value: 1975,
      label: '天等县'
    }, {
      value: 1976,
      label: '凭祥'
    }]
  }]
}, {
  value: 21,
  label: '海南',
  children: [{
    value: 232,
    label: '海口',
    children: [{
      value: 1977,
      label: '秀英区'
    }, {
      value: 1978,
      label: '龙华区'
    }, {
      value: 1979,
      label: '琼山区'
    }, {
      value: 1980,
      label: '美兰区'
    }]
  }, {
    value: 233,
    label: '三亚',
    children: [{
      value: 1981,
      label: '五指山'
    }, {
      value: 1982,
      label: '琼海'
    }, {
      value: 1983,
      label: '儋州'
    }, {
      value: 1984,
      label: '文昌'
    }, {
      value: 1985,
      label: '万宁'
    }, {
      value: 1986,
      label: '东方'
    }, {
      value: 1987,
      label: '定安县'
    }, {
      value: 1988,
      label: '屯昌县'
    }, {
      value: 1989,
      label: '澄迈县'
    }, {
      value: 1990,
      label: '临高县'
    }, {
      value: 1991,
      label: '白沙黎族自治县'
    }, {
      value: 1992,
      label: '昌江黎族自治县'
    }, {
      value: 1993,
      label: '乐东黎族自治县'
    }, {
      value: 1994,
      label: '陵水黎族自治县'
    }, {
      value: 1995,
      label: '保亭黎族苗族自治县'
    }, {
      value: 1996,
      label: '琼中黎族苗族自治县'
    }, {
      value: 1997,
      label: '西沙群岛'
    }, {
      value: 1998,
      label: '南沙群岛'
    }, {
      value: 1999,
      label: '中沙群岛的岛礁及其海域'
    }]
  }]
}, {
  value: 22,
  label: '重庆',
  children: [{
    value: 2000,
    label: '万州区'
  }, {
    value: 2001,
    label: '涪陵区'
  }, {
    value: 2002,
    label: '渝中区'
  }, {
    value: 2003,
    label: '大渡口区'
  }, {
    value: 2004,
    label: '江北区'
  }, {
    value: 2005,
    label: '沙坪坝区'
  }, {
    value: 2006,
    label: '九龙坡区'
  }, {
    value: 2007,
    label: '南岸区'
  }, {
    value: 2008,
    label: '北碚区'
  }, {
    value: 2009,
    label: '万盛区'
  }, {
    value: 2010,
    label: '双桥区'
  }, {
    value: 2011,
    label: '渝北区'
  }, {
    value: 2012,
    label: '巴南区'
  }, {
    value: 2013,
    label: '黔江区'
  }, {
    value: 2014,
    label: '长寿区'
  }, {
    value: 2015,
    label: '綦江县'
  }, {
    value: 2016,
    label: '潼南县'
  }, {
    value: 2017,
    label: '铜梁县'
  }, {
    value: 2018,
    label: '大足县'
  }, {
    value: 2019,
    label: '荣昌县'
  }, {
    value: 2020,
    label: '璧山县'
  }, {
    value: 2021,
    label: '梁平县'
  }, {
    value: 2022,
    label: '城口县'
  }, {
    value: 2023,
    label: '丰都县'
  }, {
    value: 2024,
    label: '垫江县'
  }, {
    value: 2025,
    label: '武隆县'
  }, {
    value: 2026,
    label: '忠县'
  }, {
    value: 2027,
    label: '开县'
  }, {
    value: 2028,
    label: '云阳县'
  }, {
    value: 2029,
    label: '奉节县'
  }, {
    value: 2030,
    label: '巫山县'
  }, {
    value: 2031,
    label: '巫溪县'
  }, {
    value: 2032,
    label: '石柱土家族自治县'
  }, {
    value: 2033,
    label: '秀山土家族苗族自治县'
  }, {
    value: 2034,
    label: '酉阳土家族苗族自治县'
  }, {
    value: 2035,
    label: '彭水苗族土家族自治县'
  }, {
    value: 2036,
    label: '江津'
  }, {
    value: 2037,
    label: '合川'
  }, {
    value: 2038,
    label: '永川'
  }, {
    value: 2039,
    label: '南川'
  }]
}, {
  value: 23,
  label: '四川',
  children: [{
    value: 235,
    label: '成都',
    children: [{
      value: 2040,
      label: '锦江区'
    }, {
      value: 2041,
      label: '青羊区'
    }, {
      value: 2042,
      label: '金牛区'
    }, {
      value: 2043,
      label: '武侯区'
    }, {
      value: 2044,
      label: '成华区'
    }, {
      value: 2045,
      label: '龙泉驿区'
    }, {
      value: 2046,
      label: '青白江区'
    }, {
      value: 2047,
      label: '新都区'
    }, {
      value: 2048,
      label: '温江区'
    }, {
      value: 2049,
      label: '金堂县'
    }, {
      value: 2050,
      label: '双流县'
    }, {
      value: 2051,
      label: '郫县'
    }, {
      value: 2052,
      label: '大邑县'
    }, {
      value: 2053,
      label: '蒲江县'
    }, {
      value: 2054,
      label: '新津县'
    }, {
      value: 2055,
      label: '都江堰'
    }, {
      value: 2056,
      label: '彭州'
    }, {
      value: 2057,
      label: '邛崃'
    }, {
      value: 2058,
      label: '崇州'
    }]
  }, {
    value: 236,
    label: '自贡',
    children: [{
      value: 2059,
      label: '自流井区'
    }, {
      value: 2060,
      label: '贡井区'
    }, {
      value: 2061,
      label: '大安区'
    }, {
      value: 2062,
      label: '沿滩区'
    }, {
      value: 2063,
      label: '荣县'
    }, {
      value: 2064,
      label: '富顺县'
    }]
  }, {
    value: 237,
    label: '攀枝花',
    children: [{
      value: 2065,
      label: '东区'
    }, {
      value: 2066,
      label: '西区'
    }, {
      value: 2067,
      label: '仁和区'
    }, {
      value: 2068,
      label: '米易县'
    }, {
      value: 2069,
      label: '盐边县'
    }]
  }, {
    value: 238,
    label: '泸州',
    children: [{
      value: 2070,
      label: '江阳区'
    }, {
      value: 2071,
      label: '纳溪区'
    }, {
      value: 2072,
      label: '龙马潭区'
    }, {
      value: 2073,
      label: '泸县'
    }, {
      value: 2074,
      label: '合江县'
    }, {
      value: 2075,
      label: '叙永县'
    }, {
      value: 2076,
      label: '古蔺县'
    }]
  }, {
    value: 239,
    label: '德阳',
    children: [{
      value: 2077,
      label: '旌阳区'
    }, {
      value: 2078,
      label: '中江县'
    }, {
      value: 2079,
      label: '罗江县'
    }, {
      value: 2080,
      label: '广汉'
    }, {
      value: 2081,
      label: '什邡'
    }, {
      value: 2082,
      label: '绵竹'
    }]
  }, {
    value: 240,
    label: '绵阳',
    children: [{
      value: 2083,
      label: '涪城区'
    }, {
      value: 2084,
      label: '游仙区'
    }, {
      value: 2085,
      label: '三台县'
    }, {
      value: 2086,
      label: '盐亭县'
    }, {
      value: 2087,
      label: '安县'
    }, {
      value: 2088,
      label: '梓潼县'
    }, {
      value: 2089,
      label: '北川羌族自治县'
    }, {
      value: 2090,
      label: '平武县'
    }, {
      value: 2091,
      label: '江油'
    }]
  }, {
    value: 241,
    label: '广元',
    children: [{
      value: 2092,
      label: '中区'
    }, {
      value: 2093,
      label: '元坝区'
    }, {
      value: 2094,
      label: '朝天区'
    }, {
      value: 2095,
      label: '旺苍县'
    }, {
      value: 2096,
      label: '青川县'
    }, {
      value: 2097,
      label: '剑阁县'
    }, {
      value: 2098,
      label: '苍溪县'
    }]
  }, {
    value: 242,
    label: '遂宁',
    children: [{
      value: 2099,
      label: '船山区'
    }, {
      value: 2100,
      label: '安居区'
    }, {
      value: 2101,
      label: '蓬溪县'
    }, {
      value: 2102,
      label: '射洪县'
    }, {
      value: 2103,
      label: '大英县'
    }]
  }, {
    value: 243,
    label: '内江',
    children: [{
      value: 2104,
      label: '中区'
    }, {
      value: 2105,
      label: '东兴区'
    }, {
      value: 2106,
      label: '威远县'
    }, {
      value: 2107,
      label: '资中县'
    }, {
      value: 2108,
      label: '隆昌县'
    }]
  }, {
    value: 244,
    label: '乐山',
    children: [{
      value: 2109,
      label: '中区'
    }, {
      value: 2110,
      label: '沙湾区'
    }, {
      value: 2111,
      label: '五通桥区'
    }, {
      value: 2112,
      label: '金口河区'
    }, {
      value: 2113,
      label: '犍为县'
    }, {
      value: 2114,
      label: '井研县'
    }, {
      value: 2115,
      label: '夹江县'
    }, {
      value: 2116,
      label: '沐川县'
    }, {
      value: 2117,
      label: '峨边彝族自治县'
    }, {
      value: 2118,
      label: '马边彝族自治县'
    }, {
      value: 2119,
      label: '峨眉山'
    }]
  }, {
    value: 245,
    label: '南充',
    children: [{
      value: 2120,
      label: '顺庆区'
    }, {
      value: 2121,
      label: '高坪区'
    }, {
      value: 2122,
      label: '嘉陵区'
    }, {
      value: 2123,
      label: '南部县'
    }, {
      value: 2124,
      label: '营山县'
    }, {
      value: 2125,
      label: '蓬安县'
    }, {
      value: 2126,
      label: '仪陇县'
    }, {
      value: 2127,
      label: '西充县'
    }, {
      value: 2128,
      label: '阆中'
    }]
  }, {
    value: 246,
    label: '眉山',
    children: [{
      value: 2129,
      label: '东坡区'
    }, {
      value: 2130,
      label: '仁寿县'
    }, {
      value: 2131,
      label: '彭山县'
    }, {
      value: 2132,
      label: '洪雅县'
    }, {
      value: 2133,
      label: '丹棱县'
    }, {
      value: 2134,
      label: '青神县'
    }]
  }, {
    value: 247,
    label: '宜宾',
    children: [{
      value: 2135,
      label: '翠屏区'
    }, {
      value: 2136,
      label: '宜宾县'
    }, {
      value: 2137,
      label: '南溪县'
    }, {
      value: 2138,
      label: '江安县'
    }, {
      value: 2139,
      label: '长宁县'
    }, {
      value: 2140,
      label: '高县'
    }, {
      value: 2141,
      label: '珙县'
    }, {
      value: 2142,
      label: '筠连县'
    }, {
      value: 2143,
      label: '兴文县'
    }, {
      value: 2144,
      label: '屏山县'
    }]
  }, {
    value: 248,
    label: '广安',
    children: [{
      value: 2145,
      label: '广安区'
    }, {
      value: 2146,
      label: '岳池县'
    }, {
      value: 2147,
      label: '武胜县'
    }, {
      value: 2148,
      label: '邻水县'
    }, {
      value: 2149,
      label: '华蓥'
    }]
  }, {
    value: 249,
    label: '达州',
    children: [{
      value: 2150,
      label: '通川区'
    }, {
      value: 2151,
      label: '达县'
    }, {
      value: 2152,
      label: '宣汉县'
    }, {
      value: 2153,
      label: '开江县'
    }, {
      value: 2154,
      label: '大竹县'
    }, {
      value: 2155,
      label: '渠县'
    }, {
      value: 2156,
      label: '万源'
    }]
  }, {
    value: 250,
    label: '雅安',
    children: [{
      value: 2157,
      label: '雨城区'
    }, {
      value: 2158,
      label: '名山县'
    }, {
      value: 2159,
      label: '荥经县'
    }, {
      value: 2160,
      label: '汉源县'
    }, {
      value: 2161,
      label: '石棉县'
    }, {
      value: 2162,
      label: '天全县'
    }, {
      value: 2163,
      label: '芦山县'
    }, {
      value: 2164,
      label: '宝兴县'
    }]
  }, {
    value: 251,
    label: '巴中',
    children: [{
      value: 2165,
      label: '巴州区'
    }, {
      value: 2166,
      label: '通江县'
    }, {
      value: 2167,
      label: '南江县'
    }, {
      value: 2168,
      label: '平昌县'
    }]
  }, {
    value: 252,
    label: '资阳',
    children: [{
      value: 2169,
      label: '雁江区'
    }, {
      value: 2170,
      label: '安岳县'
    }, {
      value: 2171,
      label: '乐至县'
    }, {
      value: 2172,
      label: '简阳'
    }]
  }, {
    value: 253,
    label: '阿坝',
    children: [{
      value: 2173,
      label: '汶川县'
    }, {
      value: 2174,
      label: '理县'
    }, {
      value: 2175,
      label: '茂县'
    }, {
      value: 2176,
      label: '松潘县'
    }, {
      value: 2177,
      label: '九寨沟县'
    }, {
      value: 2178,
      label: '金川县'
    }, {
      value: 2179,
      label: '小金县'
    }, {
      value: 2180,
      label: '黑水县'
    }, {
      value: 2181,
      label: '马尔康县'
    }, {
      value: 2182,
      label: '壤塘县'
    }, {
      value: 2183,
      label: '阿坝县'
    }, {
      value: 2184,
      label: '若尔盖县'
    }, {
      value: 2185,
      label: '红原县'
    }]
  }, {
    value: 254,
    label: '甘孜',
    children: [{
      value: 2186,
      label: '康定县'
    }, {
      value: 2187,
      label: '泸定县'
    }, {
      value: 2188,
      label: '丹巴县'
    }, {
      value: 2189,
      label: '九龙县'
    }, {
      value: 2190,
      label: '雅江县'
    }, {
      value: 2191,
      label: '道孚县'
    }, {
      value: 2192,
      label: '炉霍县'
    }, {
      value: 2193,
      label: '甘孜县'
    }, {
      value: 2194,
      label: '新龙县'
    }, {
      value: 2195,
      label: '德格县'
    }, {
      value: 2196,
      label: '白玉县'
    }, {
      value: 2197,
      label: '石渠县'
    }, {
      value: 2198,
      label: '色达县'
    }, {
      value: 2199,
      label: '理塘县'
    }, {
      value: 2200,
      label: '巴塘县'
    }, {
      value: 2201,
      label: '乡城县'
    }, {
      value: 2202,
      label: '稻城县'
    }, {
      value: 2203,
      label: '得荣县'
    }]
  }, {
    value: 255,
    label: '凉山',
    children: [{
      value: 2204,
      label: '西昌'
    }, {
      value: 2205,
      label: '木里藏族自治县'
    }, {
      value: 2206,
      label: '盐源县'
    }, {
      value: 2207,
      label: '德昌县'
    }, {
      value: 2208,
      label: '会理县'
    }, {
      value: 2209,
      label: '会东县'
    }, {
      value: 2210,
      label: '宁南县'
    }, {
      value: 2211,
      label: '普格县'
    }, {
      value: 2212,
      label: '布拖县'
    }, {
      value: 2213,
      label: '金阳县'
    }, {
      value: 2214,
      label: '昭觉县'
    }, {
      value: 2215,
      label: '喜德县'
    }, {
      value: 2216,
      label: '冕宁县'
    }, {
      value: 2217,
      label: '越西县'
    }, {
      value: 2218,
      label: '甘洛县'
    }, {
      value: 2219,
      label: '美姑县'
    }, {
      value: 2220,
      label: '雷波县'
    }]
  }]
}, {
  value: 24,
  label: '贵州',
  children: [{
    value: 256,
    label: '贵阳',
    children: [{
      value: 2221,
      label: '南明区'
    }, {
      value: 2222,
      label: '云岩区'
    }, {
      value: 2223,
      label: '花溪区'
    }, {
      value: 2224,
      label: '乌当区'
    }, {
      value: 2225,
      label: '白云区'
    }, {
      value: 2226,
      label: '小河区'
    }, {
      value: 2227,
      label: '开阳县'
    }, {
      value: 2228,
      label: '息烽县'
    }, {
      value: 2229,
      label: '修文县'
    }, {
      value: 2230,
      label: '清镇'
    }]
  }, {
    value: 257,
    label: '六盘水',
    children: [{
      value: 2231,
      label: '钟山区'
    }, {
      value: 2232,
      label: '六枝特区'
    }, {
      value: 2233,
      label: '水城县'
    }, {
      value: 2234,
      label: '盘县'
    }]
  }, {
    value: 258,
    label: '遵义',
    children: [{
      value: 2235,
      label: '红花岗区'
    }, {
      value: 2236,
      label: '汇川区'
    }, {
      value: 2237,
      label: '遵义县'
    }, {
      value: 2238,
      label: '桐梓县'
    }, {
      value: 2239,
      label: '绥阳县'
    }, {
      value: 2240,
      label: '正安县'
    }, {
      value: 2241,
      label: '道真仡佬族苗族自治县'
    }, {
      value: 2242,
      label: '务川仡佬族苗族自治县'
    }, {
      value: 2243,
      label: '凤冈县'
    }, {
      value: 2244,
      label: '湄潭县'
    }, {
      value: 2245,
      label: '余庆县'
    }, {
      value: 2246,
      label: '习水县'
    }, {
      value: 2247,
      label: '赤水'
    }, {
      value: 2248,
      label: '仁怀'
    }]
  }, {
    value: 259,
    label: '安顺',
    children: [{
      value: 2249,
      label: '西秀区'
    }, {
      value: 2250,
      label: '平坝县'
    }, {
      value: 2251,
      label: '普定县'
    }, {
      value: 2252,
      label: '镇宁布依族苗族自治县'
    }, {
      value: 2253,
      label: '关岭布依族苗族自治县'
    }, {
      value: 2254,
      label: '紫云苗族布依族自治县'
    }]
  }, {
    value: 260,
    label: '铜仁地区',
    children: [{
      value: 2255,
      label: '铜仁'
    }, {
      value: 2256,
      label: '江口县'
    }, {
      value: 2257,
      label: '玉屏侗族自治县'
    }, {
      value: 2258,
      label: '石阡县'
    }, {
      value: 2259,
      label: '思南县'
    }, {
      value: 2260,
      label: '印江土家族苗族自治县'
    }, {
      value: 2261,
      label: '德江县'
    }, {
      value: 2262,
      label: '沿河土家族自治县'
    }, {
      value: 2263,
      label: '松桃苗族自治县'
    }, {
      value: 2264,
      label: '万山特区'
    }]
  }, {
    value: 261,
    label: '黔西',
    children: [{
      value: 2265,
      label: '兴义'
    }, {
      value: 2266,
      label: '兴仁县'
    }, {
      value: 2267,
      label: '普安县'
    }, {
      value: 2268,
      label: '晴隆县'
    }, {
      value: 2269,
      label: '贞丰县'
    }, {
      value: 2270,
      label: '望谟县'
    }, {
      value: 2271,
      label: '册亨县'
    }, {
      value: 2272,
      label: '安龙县'
    }]
  }, {
    value: 262,
    label: '毕节地区',
    children: [{
      value: 2273,
      label: '毕节'
    }, {
      value: 2274,
      label: '大方县'
    }, {
      value: 2275,
      label: '黔西县'
    }, {
      value: 2276,
      label: '金沙县'
    }, {
      value: 2277,
      label: '织金县'
    }, {
      value: 2278,
      label: '纳雍县'
    }, {
      value: 2279,
      label: '威宁彝族回族苗族自治县'
    }, {
      value: 2280,
      label: '赫章县'
    }]
  }, {
    value: 263,
    label: '黔东',
    children: [{
      value: 2281,
      label: '凯里'
    }, {
      value: 2282,
      label: '黄平县'
    }, {
      value: 2283,
      label: '施秉县'
    }, {
      value: 2284,
      label: '三穗县'
    }, {
      value: 2285,
      label: '镇远县'
    }, {
      value: 2286,
      label: '岑巩县'
    }, {
      value: 2287,
      label: '天柱县'
    }, {
      value: 2288,
      label: '锦屏县'
    }, {
      value: 2289,
      label: '剑河县'
    }, {
      value: 2290,
      label: '台江县'
    }, {
      value: 2291,
      label: '黎平县'
    }, {
      value: 2292,
      label: '榕江县'
    }, {
      value: 2293,
      label: '从江县'
    }, {
      value: 2294,
      label: '雷山县'
    }, {
      value: 2295,
      label: '麻江县'
    }, {
      value: 2296,
      label: '丹寨县'
    }]
  }, {
    value: 264,
    label: '黔南',
    children: [{
      value: 2297,
      label: '都匀'
    }, {
      value: 2298,
      label: '福泉'
    }, {
      value: 2299,
      label: '荔波县'
    }, {
      value: 2300,
      label: '贵定县'
    }, {
      value: 2301,
      label: '瓮安县'
    }, {
      value: 2302,
      label: '独山县'
    }, {
      value: 2303,
      label: '平塘县'
    }, {
      value: 2304,
      label: '罗甸县'
    }, {
      value: 2305,
      label: '长顺县'
    }, {
      value: 2306,
      label: '龙里县'
    }, {
      value: 2307,
      label: '惠水县'
    }, {
      value: 2308,
      label: '三都水族自治县'
    }]
  }]
}, {
  value: 25,
  label: '云南',
  children: [{
    value: 265,
    label: '昆明',
    children: [{
      value: 2309,
      label: '五华区'
    }, {
      value: 2310,
      label: '盘龙区'
    }, {
      value: 2311,
      label: '官渡区'
    }, {
      value: 2312,
      label: '西山区'
    }, {
      value: 2313,
      label: '东川区'
    }, {
      value: 2314,
      label: '呈贡县'
    }, {
      value: 2315,
      label: '晋宁县'
    }, {
      value: 2316,
      label: '富民县'
    }, {
      value: 2317,
      label: '宜良县'
    }, {
      value: 2318,
      label: '石林彝族自治县'
    }, {
      value: 2319,
      label: '嵩明县'
    }, {
      value: 2320,
      label: '禄劝彝族苗族自治县'
    }, {
      value: 2321,
      label: '寻甸回族彝族自治县'
    }, {
      value: 2322,
      label: '安宁'
    }]
  }, {
    value: 266,
    label: '曲靖',
    children: [{
      value: 2323,
      label: '麒麟区'
    }, {
      value: 2324,
      label: '马龙县'
    }, {
      value: 2325,
      label: '陆良县'
    }, {
      value: 2326,
      label: '师宗县'
    }, {
      value: 2327,
      label: '罗平县'
    }, {
      value: 2328,
      label: '富源县'
    }, {
      value: 2329,
      label: '会泽县'
    }, {
      value: 2330,
      label: '沾益县'
    }, {
      value: 2331,
      label: '宣威'
    }]
  }, {
    value: 267,
    label: '玉溪',
    children: [{
      value: 2332,
      label: '红塔区'
    }, {
      value: 2333,
      label: '江川县'
    }, {
      value: 2334,
      label: '澄江县'
    }, {
      value: 2335,
      label: '通海县'
    }, {
      value: 2336,
      label: '华宁县'
    }, {
      value: 2337,
      label: '易门县'
    }, {
      value: 2338,
      label: '峨山彝族自治县'
    }, {
      value: 2339,
      label: '新平彝族傣族自治县'
    }, {
      value: 2340,
      label: '元江哈尼族彝族傣族自治县'
    }]
  }, {
    value: 268,
    label: '保山',
    children: [{
      value: 2341,
      label: '隆阳区'
    }, {
      value: 2342,
      label: '施甸县'
    }, {
      value: 2343,
      label: '腾冲县'
    }, {
      value: 2344,
      label: '龙陵县'
    }, {
      value: 2345,
      label: '昌宁县'
    }]
  }, {
    value: 269,
    label: '昭通',
    children: [{
      value: 2346,
      label: '昭阳区'
    }, {
      value: 2347,
      label: '鲁甸县'
    }, {
      value: 2348,
      label: '巧家县'
    }, {
      value: 2349,
      label: '盐津县'
    }, {
      value: 2350,
      label: '大关县'
    }, {
      value: 2351,
      label: '永善县'
    }, {
      value: 2352,
      label: '绥江县'
    }, {
      value: 2353,
      label: '镇雄县'
    }, {
      value: 2354,
      label: '彝良县'
    }, {
      value: 2355,
      label: '威信县'
    }, {
      value: 2356,
      label: '水富县'
    }]
  }, {
    value: 270,
    label: '丽江',
    children: [{
      value: 2357,
      label: '古城区'
    }, {
      value: 2358,
      label: '玉龙纳西族自治县'
    }, {
      value: 2359,
      label: '永胜县'
    }, {
      value: 2360,
      label: '华坪县'
    }, {
      value: 2361,
      label: '宁蒗彝族自治县'
    }]
  }, {
    value: 271,
    label: '思茅',
    children: [{
      value: 2362,
      label: '翠云区'
    }, {
      value: 2363,
      label: '普洱哈尼族彝族自治县'
    }, {
      value: 2364,
      label: '墨江哈尼族自治县'
    }, {
      value: 2365,
      label: '景东彝族自治县'
    }, {
      value: 2366,
      label: '景谷傣族彝族自治县'
    }, {
      value: 2367,
      label: '镇沅彝族哈尼族拉祜族自治县'
    }, {
      value: 2368,
      label: '江城哈尼族彝族自治县'
    }, {
      value: 2369,
      label: '孟连傣族拉祜族佤族自治县'
    }, {
      value: 2370,
      label: '澜沧拉祜族自治县'
    }, {
      value: 2371,
      label: '西盟佤族自治县'
    }]
  }, {
    value: 272,
    label: '临沧',
    children: [{
      value: 2372,
      label: '临翔区'
    }, {
      value: 2373,
      label: '凤庆县'
    }, {
      value: 2374,
      label: '云县'
    }, {
      value: 2375,
      label: '永德县'
    }, {
      value: 2376,
      label: '镇康县'
    }, {
      value: 2377,
      label: '双江拉祜族佤族布朗族傣族自治县'
    }, {
      value: 2378,
      label: '耿马傣族佤族自治县'
    }, {
      value: 2379,
      label: '沧源佤族自治县'
    }]
  }, {
    value: 273,
    label: '楚雄',
    children: [{
      value: 2380,
      label: '楚雄'
    }, {
      value: 2381,
      label: '双柏县'
    }, {
      value: 2382,
      label: '牟定县'
    }, {
      value: 2383,
      label: '南华县'
    }, {
      value: 2384,
      label: '姚安县'
    }, {
      value: 2385,
      label: '大姚县'
    }, {
      value: 2386,
      label: '永仁县'
    }, {
      value: 2387,
      label: '元谋县'
    }, {
      value: 2388,
      label: '武定县'
    }, {
      value: 2389,
      label: '禄丰县'
    }]
  }, {
    value: 274,
    label: '红河',
    children: [{
      value: 2390,
      label: '个旧'
    }, {
      value: 2391,
      label: '开远'
    }, {
      value: 2392,
      label: '蒙自县'
    }, {
      value: 2393,
      label: '屏边苗族自治县'
    }, {
      value: 2394,
      label: '建水县'
    }, {
      value: 2395,
      label: '石屏县'
    }, {
      value: 2396,
      label: '弥勒县'
    }, {
      value: 2397,
      label: '泸西县'
    }, {
      value: 2398,
      label: '元阳县'
    }, {
      value: 2399,
      label: '红河县'
    }, {
      value: 2400,
      label: '金平苗族瑶族傣族自治县'
    }, {
      value: 2401,
      label: '绿春县'
    }, {
      value: 2402,
      label: '河口瑶族自治县'
    }]
  }, {
    value: 275,
    label: '文山',
    children: [{
      value: 2403,
      label: '文山县'
    }, {
      value: 2404,
      label: '砚山县'
    }, {
      value: 2405,
      label: '西畴县'
    }, {
      value: 2406,
      label: '麻栗坡县'
    }, {
      value: 2407,
      label: '马关县'
    }, {
      value: 2408,
      label: '丘北县'
    }, {
      value: 2409,
      label: '广南县'
    }, {
      value: 2410,
      label: '富宁县'
    }]
  }, {
    value: 276,
    label: '西双版纳',
    children: [{
      value: 2411,
      label: '景洪'
    }, {
      value: 2412,
      label: '勐海县'
    }, {
      value: 2413,
      label: '勐腊县'
    }]
  }, {
    value: 277,
    label: '大理',
    children: [{
      value: 2414,
      label: '大理'
    }, {
      value: 2415,
      label: '漾濞彝族自治县'
    }, {
      value: 2416,
      label: '祥云县'
    }, {
      value: 2417,
      label: '宾川县'
    }, {
      value: 2418,
      label: '弥渡县'
    }, {
      value: 2419,
      label: '南涧彝族自治县'
    }, {
      value: 2420,
      label: '巍山彝族回族自治县'
    }, {
      value: 2421,
      label: '永平县'
    }, {
      value: 2422,
      label: '云龙县'
    }, {
      value: 2423,
      label: '洱源县'
    }, {
      value: 2424,
      label: '剑川县'
    }, {
      value: 2425,
      label: '鹤庆县'
    }]
  }, {
    value: 278,
    label: '德宏',
    children: [{
      value: 2426,
      label: '瑞丽'
    }, {
      value: 2427,
      label: '潞西'
    }, {
      value: 2428,
      label: '梁河县'
    }, {
      value: 2429,
      label: '盈江县'
    }, {
      value: 2430,
      label: '陇川县'
    }]
  }, {
    value: 279,
    label: '怒江',
    children: [{
      value: 2431,
      label: '泸水县'
    }, {
      value: 2432,
      label: '福贡县'
    }, {
      value: 2433,
      label: '贡山独龙族怒族自治县'
    }, {
      value: 2434,
      label: '兰坪白族普米族自治县'
    }]
  }, {
    value: 280,
    label: '迪庆',
    children: [{
      value: 2435,
      label: '香格里拉县'
    }, {
      value: 2436,
      label: '德钦县'
    }, {
      value: 2437,
      label: '维西傈僳族自治县'
    }]
  }]
}, {
  value: 26,
  label: '西藏',
  children: [{
    value: 281,
    label: '拉萨',
    children: [{
      value: 2438,
      label: '城关区'
    }, {
      value: 2439,
      label: '林周县'
    }, {
      value: 2440,
      label: '当雄县'
    }, {
      value: 2441,
      label: '尼木县'
    }, {
      value: 2442,
      label: '曲水县'
    }, {
      value: 2443,
      label: '堆龙德庆县'
    }, {
      value: 2444,
      label: '达孜县'
    }, {
      value: 2445,
      label: '墨竹工卡县'
    }]
  }, {
    value: 282,
    label: '昌都地区',
    children: [{
      value: 2446,
      label: '昌都县'
    }, {
      value: 2447,
      label: '江达县'
    }, {
      value: 2448,
      label: '贡觉县'
    }, {
      value: 2449,
      label: '类乌齐县'
    }, {
      value: 2450,
      label: '丁青县'
    }, {
      value: 2451,
      label: '察雅县'
    }, {
      value: 2452,
      label: '八宿县'
    }, {
      value: 2453,
      label: '左贡县'
    }, {
      value: 2454,
      label: '芒康县'
    }, {
      value: 2455,
      label: '洛隆县'
    }, {
      value: 2456,
      label: '边坝县'
    }]
  }, {
    value: 283,
    label: '山南地区',
    children: [{
      value: 2457,
      label: '乃东县'
    }, {
      value: 2458,
      label: '扎囊县'
    }, {
      value: 2459,
      label: '贡嘎县'
    }, {
      value: 2460,
      label: '桑日县'
    }, {
      value: 2461,
      label: '琼结县'
    }, {
      value: 2462,
      label: '曲松县'
    }, {
      value: 2463,
      label: '措美县'
    }, {
      value: 2464,
      label: '洛扎县'
    }, {
      value: 2465,
      label: '加查县'
    }, {
      value: 2466,
      label: '隆子县'
    }, {
      value: 2467,
      label: '错那县'
    }, {
      value: 2468,
      label: '浪卡子县'
    }]
  }, {
    value: 284,
    label: '日喀则地区',
    children: [{
      value: 2469,
      label: '日喀则'
    }, {
      value: 2470,
      label: '南木林县'
    }, {
      value: 2471,
      label: '江孜县'
    }, {
      value: 2472,
      label: '定日县'
    }, {
      value: 2473,
      label: '萨迦县'
    }, {
      value: 2474,
      label: '拉孜县'
    }, {
      value: 2475,
      label: '昂仁县'
    }, {
      value: 2476,
      label: '谢通门县'
    }, {
      value: 2477,
      label: '白朗县'
    }, {
      value: 2478,
      label: '仁布县'
    }, {
      value: 2479,
      label: '康马县'
    }, {
      value: 2480,
      label: '定结县'
    }, {
      value: 2481,
      label: '仲巴县'
    }, {
      value: 2482,
      label: '亚东县'
    }, {
      value: 2483,
      label: '吉隆县'
    }, {
      value: 2484,
      label: '聂拉木县'
    }, {
      value: 2485,
      label: '萨嘎县'
    }, {
      value: 2486,
      label: '岗巴县'
    }]
  }, {
    value: 285,
    label: '那曲地区',
    children: [{
      value: 2487,
      label: '那曲县'
    }, {
      value: 2488,
      label: '嘉黎县'
    }, {
      value: 2489,
      label: '比如县'
    }, {
      value: 2490,
      label: '聂荣县'
    }, {
      value: 2491,
      label: '安多县'
    }, {
      value: 2492,
      label: '申扎县'
    }, {
      value: 2493,
      label: '索县'
    }, {
      value: 2494,
      label: '班戈县'
    }, {
      value: 2495,
      label: '巴青县'
    }, {
      value: 2496,
      label: '尼玛县'
    }]
  }, {
    value: 286,
    label: '阿里地区',
    children: [{
      value: 2497,
      label: '普兰县'
    }, {
      value: 2498,
      label: '札达县'
    }, {
      value: 2499,
      label: '噶尔县'
    }, {
      value: 2500,
      label: '日土县'
    }, {
      value: 2501,
      label: '革吉县'
    }, {
      value: 2502,
      label: '改则县'
    }, {
      value: 2503,
      label: '措勤县'
    }]
  }, {
    value: 287,
    label: '林芝地区',
    children: [{
      value: 2504,
      label: '林芝县'
    }, {
      value: 2505,
      label: '工布江达县'
    }, {
      value: 2506,
      label: '米林县'
    }, {
      value: 2507,
      label: '墨脱县'
    }, {
      value: 2508,
      label: '波密县'
    }, {
      value: 2509,
      label: '察隅县'
    }, {
      value: 2510,
      label: '朗县'
    }]
  }]
}, {
  value: 27,
  label: '陕西',
  children: [{
    value: 288,
    label: '西安',
    children: [{
      value: 2511,
      label: '新城区'
    }, {
      value: 2512,
      label: '碑林区'
    }, {
      value: 2513,
      label: '莲湖区'
    }, {
      value: 2514,
      label: '灞桥区'
    }, {
      value: 2515,
      label: '未央区'
    }, {
      value: 2516,
      label: '雁塔区'
    }, {
      value: 2517,
      label: '阎良区'
    }, {
      value: 2518,
      label: '临潼区'
    }, {
      value: 2519,
      label: '长安区'
    }, {
      value: 2520,
      label: '蓝田县'
    }, {
      value: 2521,
      label: '周至县'
    }, {
      value: 2522,
      label: '户县'
    }, {
      value: 2523,
      label: '高陵县'
    }]
  }, {
    value: 289,
    label: '铜川',
    children: [{
      value: 2524,
      label: '王益区'
    }, {
      value: 2525,
      label: '印台区'
    }, {
      value: 2526,
      label: '耀州区'
    }, {
      value: 2527,
      label: '宜君县'
    }]
  }, {
    value: 290,
    label: '宝鸡',
    children: [{
      value: 2528,
      label: '渭滨区'
    }, {
      value: 2529,
      label: '金台区'
    }, {
      value: 2530,
      label: '陈仓区'
    }, {
      value: 2531,
      label: '凤翔县'
    }, {
      value: 2532,
      label: '岐山县'
    }, {
      value: 2533,
      label: '扶风县'
    }, {
      value: 2534,
      label: '眉县'
    }, {
      value: 2535,
      label: '陇县'
    }, {
      value: 2536,
      label: '千阳县'
    }, {
      value: 2537,
      label: '麟游县'
    }, {
      value: 2538,
      label: '凤县'
    }, {
      value: 2539,
      label: '太白县'
    }]
  }, {
    value: 291,
    label: '咸阳',
    children: [{
      value: 2540,
      label: '秦都区'
    }, {
      value: 2541,
      label: '杨凌区'
    }, {
      value: 2542,
      label: '渭城区'
    }, {
      value: 2543,
      label: '三原县'
    }, {
      value: 2544,
      label: '泾阳县'
    }, {
      value: 2545,
      label: '乾县'
    }, {
      value: 2546,
      label: '礼泉县'
    }, {
      value: 2547,
      label: '永寿县'
    }, {
      value: 2548,
      label: '彬县'
    }, {
      value: 2549,
      label: '长武县'
    }, {
      value: 2550,
      label: '旬邑县'
    }, {
      value: 2551,
      label: '淳化县'
    }, {
      value: 2552,
      label: '武功县'
    }, {
      value: 2553,
      label: '兴平'
    }]
  }, {
    value: 292,
    label: '渭南',
    children: [{
      value: 2554,
      label: '临渭区'
    }, {
      value: 2555,
      label: '华县'
    }, {
      value: 2556,
      label: '潼关县'
    }, {
      value: 2557,
      label: '大荔县'
    }, {
      value: 2558,
      label: '合阳县'
    }, {
      value: 2559,
      label: '澄城县'
    }, {
      value: 2560,
      label: '蒲城县'
    }, {
      value: 2561,
      label: '白水县'
    }, {
      value: 2562,
      label: '富平县'
    }, {
      value: 2563,
      label: '韩城'
    }, {
      value: 2564,
      label: '华阴'
    }]
  }, {
    value: 293,
    label: '延安',
    children: [{
      value: 2565,
      label: '宝塔区'
    }, {
      value: 2566,
      label: '延长县'
    }, {
      value: 2567,
      label: '延川县'
    }, {
      value: 2568,
      label: '子长县'
    }, {
      value: 2569,
      label: '安塞县'
    }, {
      value: 2570,
      label: '志丹县'
    }, {
      value: 2571,
      label: '吴旗县'
    }, {
      value: 2572,
      label: '甘泉县'
    }, {
      value: 2573,
      label: '富县'
    }, {
      value: 2574,
      label: '洛川县'
    }, {
      value: 2575,
      label: '宜川县'
    }, {
      value: 2576,
      label: '黄龙县'
    }, {
      value: 2577,
      label: '黄陵县'
    }]
  }, {
    value: 294,
    label: '汉中',
    children: [{
      value: 2578,
      label: '汉台区'
    }, {
      value: 2579,
      label: '南郑县'
    }, {
      value: 2580,
      label: '城固县'
    }, {
      value: 2581,
      label: '洋县'
    }, {
      value: 2582,
      label: '西乡县'
    }, {
      value: 2583,
      label: '勉县'
    }, {
      value: 2584,
      label: '宁强县'
    }, {
      value: 2585,
      label: '略阳县'
    }, {
      value: 2586,
      label: '镇巴县'
    }, {
      value: 2587,
      label: '留坝县'
    }, {
      value: 2588,
      label: '佛坪县'
    }]
  }, {
    value: 295,
    label: '榆林',
    children: [{
      value: 2589,
      label: '榆阳区'
    }, {
      value: 2590,
      label: '神木县'
    }, {
      value: 2591,
      label: '府谷县'
    }, {
      value: 2592,
      label: '横山县'
    }, {
      value: 2593,
      label: '靖边县'
    }, {
      value: 2594,
      label: '定边县'
    }, {
      value: 2595,
      label: '绥德县'
    }, {
      value: 2596,
      label: '米脂县'
    }, {
      value: 2597,
      label: '佳县'
    }, {
      value: 2598,
      label: '吴堡县'
    }, {
      value: 2599,
      label: '清涧县'
    }, {
      value: 2600,
      label: '子洲县'
    }]
  }, {
    value: 296,
    label: '安康',
    children: [{
      value: 2601,
      label: '汉滨区'
    }, {
      value: 2602,
      label: '汉阴县'
    }, {
      value: 2603,
      label: '石泉县'
    }, {
      value: 2604,
      label: '宁陕县'
    }, {
      value: 2605,
      label: '紫阳县'
    }, {
      value: 2606,
      label: '岚皋县'
    }, {
      value: 2607,
      label: '平利县'
    }, {
      value: 2608,
      label: '镇坪县'
    }, {
      value: 2609,
      label: '旬阳县'
    }, {
      value: 2610,
      label: '白河县'
    }]
  }, {
    value: 297,
    label: '商洛',
    children: [{
      value: 2611,
      label: '商州区'
    }, {
      value: 2612,
      label: '洛南县'
    }, {
      value: 2613,
      label: '丹凤县'
    }, {
      value: 2614,
      label: '商南县'
    }, {
      value: 2615,
      label: '山阳县'
    }, {
      value: 2616,
      label: '镇安县'
    }, {
      value: 2617,
      label: '柞水县'
    }]
  }]
}, {
  value: 28,
  label: '甘肃',
  children: [{
    value: 298,
    label: '兰州',
    children: [{
      value: 2618,
      label: '城关区'
    }, {
      value: 2619,
      label: '七里河区'
    }, {
      value: 2620,
      label: '西固区'
    }, {
      value: 2621,
      label: '安宁区'
    }, {
      value: 2622,
      label: '红古区'
    }, {
      value: 2623,
      label: '永登县'
    }, {
      value: 2624,
      label: '皋兰县'
    }, {
      value: 2625,
      label: '榆中县'
    }]
  }, {
    value: 299,
    label: '嘉峪关',
    children: []
  }, {
    value: 300,
    label: '金昌',
    children: [{
      value: 2626,
      label: '金川区'
    }, {
      value: 2627,
      label: '永昌县'
    }]
  }, {
    value: 301,
    label: '白银',
    children: [{
      value: 2628,
      label: '白银区'
    }, {
      value: 2629,
      label: '平川区'
    }, {
      value: 2630,
      label: '靖远县'
    }, {
      value: 2631,
      label: '会宁县'
    }, {
      value: 2632,
      label: '景泰县'
    }]
  }, {
    value: 302,
    label: '天水',
    children: [{
      value: 2633,
      label: '秦城区'
    }, {
      value: 2634,
      label: '北道区'
    }, {
      value: 2635,
      label: '清水县'
    }, {
      value: 2636,
      label: '秦安县'
    }, {
      value: 2637,
      label: '甘谷县'
    }, {
      value: 2638,
      label: '武山县'
    }, {
      value: 2639,
      label: '张家川回族自治县'
    }]
  }, {
    value: 303,
    label: '武威',
    children: [{
      value: 2640,
      label: '凉州区'
    }, {
      value: 2641,
      label: '民勤县'
    }, {
      value: 2642,
      label: '古浪县'
    }, {
      value: 2643,
      label: '天祝藏族自治县'
    }]
  }, {
    value: 304,
    label: '张掖',
    children: [{
      value: 2644,
      label: '甘州区'
    }, {
      value: 2645,
      label: '肃南裕固族自治县'
    }, {
      value: 2646,
      label: '民乐县'
    }, {
      value: 2647,
      label: '临泽县'
    }, {
      value: 2648,
      label: '高台县'
    }, {
      value: 2649,
      label: '山丹县'
    }]
  }, {
    value: 305,
    label: '平凉',
    children: [{
      value: 2650,
      label: '崆峒区'
    }, {
      value: 2651,
      label: '泾川县'
    }, {
      value: 2652,
      label: '灵台县'
    }, {
      value: 2653,
      label: '崇信县'
    }, {
      value: 2654,
      label: '华亭县'
    }, {
      value: 2655,
      label: '庄浪县'
    }, {
      value: 2656,
      label: '静宁县'
    }]
  }, {
    value: 306,
    label: '酒泉',
    children: [{
      value: 2657,
      label: '肃州区'
    }, {
      value: 2658,
      label: '金塔县'
    }, {
      value: 2659,
      label: '安西县'
    }, {
      value: 2660,
      label: '肃北蒙古族自治县'
    }, {
      value: 2661,
      label: '阿克塞哈萨克族自治县'
    }, {
      value: 2662,
      label: '玉门'
    }, {
      value: 2663,
      label: '敦煌'
    }]
  }, {
    value: 307,
    label: '庆阳',
    children: [{
      value: 2664,
      label: '西峰区'
    }, {
      value: 2665,
      label: '庆城县'
    }, {
      value: 2666,
      label: '环县'
    }, {
      value: 2667,
      label: '华池县'
    }, {
      value: 2668,
      label: '合水县'
    }, {
      value: 2669,
      label: '正宁县'
    }, {
      value: 2670,
      label: '宁县'
    }, {
      value: 2671,
      label: '镇原县'
    }]
  }, {
    value: 308,
    label: '定西',
    children: [{
      value: 2672,
      label: '安定区'
    }, {
      value: 2673,
      label: '通渭县'
    }, {
      value: 2674,
      label: '陇西县'
    }, {
      value: 2675,
      label: '渭源县'
    }, {
      value: 2676,
      label: '临洮县'
    }, {
      value: 2677,
      label: '漳县'
    }, {
      value: 2678,
      label: '岷县'
    }]
  }, {
    value: 309,
    label: '陇南',
    children: [{
      value: 2679,
      label: '武都区'
    }, {
      value: 2680,
      label: '成县'
    }, {
      value: 2681,
      label: '文县'
    }, {
      value: 2682,
      label: '宕昌县'
    }, {
      value: 2683,
      label: '康县'
    }, {
      value: 2684,
      label: '西和县'
    }, {
      value: 2685,
      label: '礼县'
    }, {
      value: 2686,
      label: '徽县'
    }, {
      value: 2687,
      label: '两当县'
    }]
  }, {
    value: 310,
    label: '临夏',
    children: [{
      value: 2688,
      label: '临夏'
    }, {
      value: 2689,
      label: '临夏县'
    }, {
      value: 2690,
      label: '康乐县'
    }, {
      value: 2691,
      label: '永靖县'
    }, {
      value: 2692,
      label: '广河县'
    }, {
      value: 2693,
      label: '和政县'
    }, {
      value: 2694,
      label: '东乡族自治县'
    }, {
      value: 2695,
      label: '积石山保安族东乡族撒拉族自治县'
    }]
  }, {
    value: 311,
    label: '甘南',
    children: [{
      value: 2696,
      label: '合作'
    }, {
      value: 2697,
      label: '临潭县'
    }, {
      value: 2698,
      label: '卓尼县'
    }, {
      value: 2699,
      label: '舟曲县'
    }, {
      value: 2700,
      label: '迭部县'
    }, {
      value: 2701,
      label: '玛曲县'
    }, {
      value: 2702,
      label: '碌曲县'
    }, {
      value: 2703,
      label: '夏河县'
    }]
  }]
}, {
  value: 29,
  label: '青海',
  children: [{
    value: 312,
    label: '西宁',
    children: [{
      value: 2704,
      label: '城东区'
    }, {
      value: 2705,
      label: '城中区'
    }, {
      value: 2706,
      label: '城西区'
    }, {
      value: 2707,
      label: '城北区'
    }, {
      value: 2708,
      label: '大通回族土族自治县'
    }, {
      value: 2709,
      label: '湟中县'
    }, {
      value: 2710,
      label: '湟源县'
    }]
  }, {
    value: 313,
    label: '海东地区',
    children: [{
      value: 2711,
      label: '平安县'
    }, {
      value: 2712,
      label: '民和回族土族自治县'
    }, {
      value: 2713,
      label: '乐都县'
    }, {
      value: 2714,
      label: '互助土族自治县'
    }, {
      value: 2715,
      label: '化隆回族自治县'
    }, {
      value: 2716,
      label: '循化撒拉族自治县'
    }]
  }, {
    value: 314,
    label: '海北',
    children: [{
      value: 2717,
      label: '门源回族自治县'
    }, {
      value: 2718,
      label: '祁连县'
    }, {
      value: 2719,
      label: '海晏县'
    }, {
      value: 2720,
      label: '刚察县'
    }]
  }, {
    value: 315,
    label: '黄南',
    children: [{
      value: 2721,
      label: '同仁县'
    }, {
      value: 2722,
      label: '尖扎县'
    }, {
      value: 2723,
      label: '泽库县'
    }, {
      value: 2724,
      label: '河南蒙古族自治县'
    }]
  }, {
    value: 316,
    label: '海南',
    children: [{
      value: 2725,
      label: '共和县'
    }, {
      value: 2726,
      label: '同德县'
    }, {
      value: 2727,
      label: '贵德县'
    }, {
      value: 2728,
      label: '兴海县'
    }, {
      value: 2729,
      label: '贵南县'
    }]
  }, {
    value: 317,
    label: '果洛',
    children: [{
      value: 2730,
      label: '玛沁县'
    }, {
      value: 2731,
      label: '班玛县'
    }, {
      value: 2732,
      label: '甘德县'
    }, {
      value: 2733,
      label: '达日县'
    }, {
      value: 2734,
      label: '久治县'
    }, {
      value: 2735,
      label: '玛多县'
    }]
  }, {
    value: 318,
    label: '玉树',
    children: [{
      value: 2736,
      label: '玉树县'
    }, {
      value: 2737,
      label: '杂多县'
    }, {
      value: 2738,
      label: '称多县'
    }, {
      value: 2739,
      label: '治多县'
    }, {
      value: 2740,
      label: '囊谦县'
    }, {
      value: 2741,
      label: '曲麻莱县'
    }]
  }, {
    value: 319,
    label: '海西',
    children: [{
      value: 2742,
      label: '格尔木'
    }, {
      value: 2743,
      label: '德令哈'
    }, {
      value: 2744,
      label: '乌兰县'
    }, {
      value: 2745,
      label: '都兰县'
    }, {
      value: 2746,
      label: '天峻县'
    }]
  }]
}, {
  value: 30,
  label: '宁夏',
  children: [{
    value: 320,
    label: '银川',
    children: [{
      value: 2747,
      label: '兴庆区'
    }, {
      value: 2748,
      label: '西夏区'
    }, {
      value: 2749,
      label: '金凤区'
    }, {
      value: 2750,
      label: '永宁县'
    }, {
      value: 2751,
      label: '贺兰县'
    }, {
      value: 2752,
      label: '灵武'
    }]
  }, {
    value: 321,
    label: '石嘴山',
    children: [{
      value: 2753,
      label: '大武口区'
    }, {
      value: 2754,
      label: '惠农区'
    }, {
      value: 2755,
      label: '平罗县'
    }]
  }, {
    value: 322,
    label: '吴忠',
    children: [{
      value: 2756,
      label: '利通区'
    }, {
      value: 2757,
      label: '盐池县'
    }, {
      value: 2758,
      label: '同心县'
    }, {
      value: 2759,
      label: '青铜峡'
    }]
  }, {
    value: 323,
    label: '固原',
    children: [{
      value: 2760,
      label: '原州区'
    }, {
      value: 2761,
      label: '西吉县'
    }, {
      value: 2762,
      label: '隆德县'
    }, {
      value: 2763,
      label: '泾源县'
    }, {
      value: 2764,
      label: '彭阳县'
    }]
  }, {
    value: 324,
    label: '中卫',
    children: [{
      value: 2765,
      label: '沙坡头区'
    }, {
      value: 2766,
      label: '中宁县'
    }, {
      value: 2767,
      label: '海原县'
    }]
  }]
}, {
  value: 31,
  label: '新疆',
  children: [{
    value: 325,
    label: '乌鲁木齐',
    children: [{
      value: 2768,
      label: '天山区'
    }, {
      value: 2769,
      label: '沙依巴克区'
    }, {
      value: 2770,
      label: '新区'
    }, {
      value: 2771,
      label: '水磨沟区'
    }, {
      value: 2772,
      label: '头屯河区'
    }, {
      value: 2773,
      label: '达坂城区'
    }, {
      value: 2774,
      label: '东山区'
    }, {
      value: 2775,
      label: '乌鲁木齐县'
    }]
  }, {
    value: 326,
    label: '克拉玛依',
    children: [{
      value: 2776,
      label: '独山子区'
    }, {
      value: 2777,
      label: '克拉玛依区'
    }, {
      value: 2778,
      label: '白碱滩区'
    }, {
      value: 2779,
      label: '乌尔禾区'
    }]
  }, {
    value: 327,
    label: '吐鲁番地区',
    children: [{
      value: 2780,
      label: '吐鲁番'
    }, {
      value: 2781,
      label: '鄯善县'
    }, {
      value: 2782,
      label: '托克逊县'
    }]
  }, {
    value: 328,
    label: '哈密地区',
    children: [{
      value: 2783,
      label: '哈密'
    }, {
      value: 2784,
      label: '巴里坤哈萨克自治县'
    }, {
      value: 2785,
      label: '伊吾县'
    }]
  }, {
    value: 329,
    label: '昌吉',
    children: [{
      value: 2786,
      label: '昌吉'
    }, {
      value: 2787,
      label: '阜康'
    }, {
      value: 2788,
      label: '米泉'
    }, {
      value: 2789,
      label: '呼图壁县'
    }, {
      value: 2790,
      label: '玛纳斯县'
    }, {
      value: 2791,
      label: '奇台县'
    }, {
      value: 2792,
      label: '吉木萨尔县'
    }, {
      value: 2793,
      label: '木垒哈萨克自治县'
    }]
  }, {
    value: 330,
    label: '博尔塔拉',
    children: [{
      value: 2794,
      label: '博乐'
    }, {
      value: 2795,
      label: '精河县'
    }, {
      value: 2796,
      label: '温泉县'
    }]
  }, {
    value: 331,
    label: '巴音郭楞',
    children: [{
      value: 2797,
      label: '库尔勒'
    }, {
      value: 2798,
      label: '轮台县'
    }, {
      value: 2799,
      label: '尉犁县'
    }, {
      value: 2800,
      label: '若羌县'
    }, {
      value: 2801,
      label: '且末县'
    }, {
      value: 2802,
      label: '焉耆回族自治县'
    }, {
      value: 2803,
      label: '和静县'
    }, {
      value: 2804,
      label: '和硕县'
    }, {
      value: 2805,
      label: '博湖县'
    }]
  }, {
    value: 332,
    label: '阿克苏地区',
    children: [{
      value: 2806,
      label: '阿克苏'
    }, {
      value: 2807,
      label: '温宿县'
    }, {
      value: 2808,
      label: '库车县'
    }, {
      value: 2809,
      label: '沙雅县'
    }, {
      value: 2810,
      label: '新和县'
    }, {
      value: 2811,
      label: '拜城县'
    }, {
      value: 2812,
      label: '乌什县'
    }, {
      value: 2813,
      label: '阿瓦提县'
    }, {
      value: 2814,
      label: '柯坪县'
    }]
  }, {
    value: 333,
    label: '克孜勒苏柯尔克孜',
    children: [{
      value: 2815,
      label: '阿图什'
    }, {
      value: 2816,
      label: '阿克陶县'
    }, {
      value: 2817,
      label: '阿合奇县'
    }, {
      value: 2818,
      label: '乌恰县'
    }]
  }, {
    value: 334,
    label: '喀什地区',
    children: [{
      value: 2819,
      label: '喀什'
    }, {
      value: 2820,
      label: '疏附县'
    }, {
      value: 2821,
      label: '疏勒县'
    }, {
      value: 2822,
      label: '英吉沙县'
    }, {
      value: 2823,
      label: '泽普县'
    }, {
      value: 2824,
      label: '莎车县'
    }, {
      value: 2825,
      label: '叶城县'
    }, {
      value: 2826,
      label: '麦盖提县'
    }, {
      value: 2827,
      label: '岳普湖县'
    }, {
      value: 2828,
      label: '伽师县'
    }, {
      value: 2829,
      label: '巴楚县'
    }, {
      value: 2830,
      label: '塔什库尔干塔吉克自治县'
    }]
  }, {
    value: 335,
    label: '和田地区',
    children: [{
      value: 2831,
      label: '和田'
    }, {
      value: 2832,
      label: '和田县'
    }, {
      value: 2833,
      label: '墨玉县'
    }, {
      value: 2834,
      label: '皮山县'
    }, {
      value: 2835,
      label: '洛浦县'
    }, {
      value: 2836,
      label: '策勒县'
    }, {
      value: 2837,
      label: '于田县'
    }, {
      value: 2838,
      label: '民丰县'
    }]
  }, {
    value: 336,
    label: '伊犁哈萨克',
    children: [{
      value: 2839,
      label: '伊宁'
    }, {
      value: 2840,
      label: '奎屯'
    }, {
      value: 2841,
      label: '伊宁县'
    }, {
      value: 2842,
      label: '察布查尔锡伯自治县'
    }, {
      value: 2843,
      label: '霍城县'
    }, {
      value: 2844,
      label: '巩留县'
    }, {
      value: 2845,
      label: '新源县'
    }, {
      value: 2846,
      label: '昭苏县'
    }, {
      value: 2847,
      label: '特克斯县'
    }, {
      value: 2848,
      label: '尼勒克县'
    }]
  }, {
    value: 337,
    label: '塔城地区',
    children: [{
      value: 2849,
      label: '塔城'
    }, {
      value: 2850,
      label: '乌苏'
    }, {
      value: 2851,
      label: '额敏县'
    }, {
      value: 2852,
      label: '沙湾县'
    }, {
      value: 2853,
      label: '托里县'
    }, {
      value: 2854,
      label: '裕民县'
    }, {
      value: 2855,
      label: '和布克赛尔蒙古自治县'
    }]
  }, {
    value: 338,
    label: '阿勒泰地区',
    children: [{
      value: 2856,
      label: '阿勒泰'
    }, {
      value: 2857,
      label: '布尔津县'
    }, {
      value: 2858,
      label: '富蕴县'
    }, {
      value: 2859,
      label: '福海县'
    }, {
      value: 2860,
      label: '哈巴河县'
    }, {
      value: 2861,
      label: '青河县'
    }, {
      value: 2862,
      label: '吉木乃县'
    }]
  }, {
    value: 339,
    label: '石河子',
    children: []
  }, {
    value: 340,
    label: '阿拉尔',
    children: []
  }, {
    value: 341,
    label: '图木舒克',
    children: []
  }, {
    value: 342,
    label: '五家渠',
    children: []
  }]
}, {
  value: 32,
  label: '香港'
}, {
  value: 33,
  label: '澳门'
}, {
  value: 34,
  label: '台湾'
}];
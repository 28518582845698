"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddressListApi = AddressListApi;
exports.addAddresstApi = addAddresstApi;
exports.deleteAddressApi = deleteAddressApi;
exports.editAddressApi = editAddressApi;
exports.editAddressStatusApi = editAddressStatusApi;
exports.exportOrderApi = exportOrderApi;
exports.importAddressApi = importAddressApi;
exports.serviceOrderApi = serviceOrderApi;
exports.serviceOrderDetailApi = serviceOrderDetailApi;
exports.uploadMoneyBookApi = uploadMoneyBookApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/*
 * @Author: Zcl84765
 * @Date: 2022-12-14 09:38:36
 * @LastEditors: Zcl84765
 * @LastEditTime: 2023-02-22 17:31:06
 */

/**
 *
 * @param {*} params  订单列表
 * @returns
 */
function serviceOrderApi(params) {
  return (0, _request.default)({
    url: "/admin/serveorder/list",
    method: "get",
    params: params
  });
}

/**
 *
 * @param {*} params 导出订单
 */
function exportOrderApi(params) {
  return (0, _request.default)({
    url: "admin/serveorder/exportServeOrder",
    method: "get",
    params: params
  });
}

/**
 *
 * @param {*} params    订单详情
 * @returns
 */
function serviceOrderDetailApi(params) {
  return (0, _request.default)({
    url: "admin/serveorder/getDetailInfo",
    method: "get",
    params: params
  });
}

/**
 *
 * @param {*} data 上传发票
 */
function uploadMoneyBookApi(data) {
  return (0, _request.default)({
    url: "admin/serveorder/uploadInvoice",
    method: "post",
    data: data
  });
}

/**
 *
 * @param {*} params  获取地点列表
 */
function AddressListApi(params) {
  return (0, _request.default)({
    url: "admin/serve/address/list",
    method: "get",
    params: params
  });
}

/**
 *
 * @param {*} params  删除地点
 * @returns
 */

function deleteAddressApi(data) {
  return (0, _request.default)({
    url: "admin/serve/address/delete",
    method: "post",
    data: data
  });
}

/**
 *
 * @param {*} data 新增地址
 * @returns
 */
function addAddresstApi(data) {
  return (0, _request.default)({
    url: "admin/serve/address/add",
    method: "post",
    data: data
  });
}

/**
 * 
 * @param {*} data   编辑地址
 * @returns 
 */
function editAddressApi(data) {
  return (0, _request.default)({
    url: "admin/serve/address/edit",
    method: "post",
    data: data
  });
}

/**
 *
 * @param {*} data 导入地点
 * @returns
 */
function importAddressApi(data) {
  return (0, _request.default)({
    url: "admin/serve/address/importAdress",
    method: "post",
    data: data
  });
}

/**
 * 
 * @param {*} params  修改地点状态
 * @returns 
 */
function editAddressStatusApi(params) {
  return (0, _request.default)({
    url: "admin/serve/address/editStatus",
    method: "get",
    params: params
  });
}
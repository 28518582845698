"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddArticle = AddArticle;
exports.DelArticle = DelArticle;
exports.InfoArticle = InfoArticle;
exports.ListArticle = ListArticle;
exports.UpdateArticle = UpdateArticle;
exports.bindProduct = bindProduct;
exports.updateHide = updateHide;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * 绑定产品
 * @param pram
 */
function bindProduct(pram) {
  var data = {
    id: pram.id,
    productId: pram.productId
  };
  return (0, _request.default)({
    url: "/admin/article/bind/product",
    method: "POST",
    params: data
  });
}

/**
 * 删除文章
 * @param id
 * @constructor
 */
function DelArticle(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: "/admin/article/delete",
    method: "GET",
    params: data
  });
}

/**
 * 文章详情
 * @param id
 * @constructor
 */
function InfoArticle(id) {
  var data = {
    id: id
  };
  return (0, _request.default)({
    url: "/admin/article/info",
    method: "get",
    params: data
  });
}

/**
 * 文章列表
 * @param pram
 * @constructor
 */
function ListArticle(pram) {
  var data = {
    keywords: pram.keywords,
    cid: pram.cid,
    page: pram.page,
    limit: pram.limit,
    sortType: 0,
    hide: pram.hide,
    recommendType: pram.recommendType,
    articleType: pram.articleType == 9 ? "" : pram.articleType
  };
  return (0, _request.default)({
    url: "/admin/article/list",
    method: "GET",
    params: data
  });
}

/**
 * 新增文章
 * @param pram
 * @constructor
 */
function AddArticle(pram) {
  var _data;
  var data = (_data = {
    articleContentList: pram.articleContentList,
    articleRecommendList: pram.articleRecommendList,
    label: pram.label,
    articleType: pram.articleType,
    author: pram.author,
    cidList: pram.cidList,
    content: pram.content,
    imageInput: pram.imageInput,
    bannerImage: pram.bannerImage,
    isBanner: pram.isBanner,
    isHot: pram.isHot,
    shareSynopsis: pram.shareSynopsis,
    shareTitle: pram.shareTitle,
    sort: pram.sort,
    title: pram.title,
    url: pram.url,
    isRecommend: pram.isRecommend
  }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_data, "sort", pram.sort), "subtitle", pram.subtitle), "coverImage", pram.coverImage), "isEnterpriseService", pram.isEnterpriseService), "isParkFirst", pram.isParkFirst), "parkCoverImage", pram.parkCoverImage), "parkTag", pram.parkTag), "clLabel", pram.clLabel), "stayTitle", pram.stayTitle), "stayHeadImage", pram.stayHeadImage), _defineProperty(_data, "describeStr", pram.describeStr));
  return (0, _request.default)({
    url: "/admin/article/save",
    method: "post",
    data: data
  });
}

/**
 * 更新文章
 * @param pram
 * @constructor
 */
function UpdateArticle(pram) {
  var _data2;
  var data = (_data2 = {
    articleContentList: pram.articleContentList,
    articleRecommendList: pram.articleRecommendList,
    label: pram.label,
    articleType: pram.articleType,
    author: pram.author,
    cidList: pram.cidList,
    content: pram.content,
    imageInput: pram.imageInput,
    bannerImage: pram.bannerImage,
    isBanner: pram.isBanner,
    isHot: pram.isHot,
    shareSynopsis: pram.shareSynopsis,
    shareTitle: pram.shareTitle,
    sort: pram.sort,
    title: pram.title,
    url: pram.url,
    isRecommend: pram.isRecommend
  }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_data2, "sort", pram.sort), "subtitle", pram.subtitle), "coverImage", pram.coverImage), "isEnterpriseService", pram.isEnterpriseService), "isParkFirst", pram.isParkFirst), "parkCoverImage", pram.parkCoverImage), "parkTag", pram.parkTag), "clLabel", pram.clLabel), "stayTitle", pram.stayTitle), "stayHeadImage", pram.stayHeadImage), _defineProperty(_data2, "describeStr", pram.describeStr));
  return (0, _request.default)({
    url: "/admin/article/update",
    method: "post",
    params: {
      id: pram.id
    },
    data: data
  });
}

/**
 * 上下架
 * @param id
 * @constructor
 */
function updateHide(id) {
  return (0, _request.default)({
    url: "/admin/article/updateHide/".concat(id),
    method: "GET"
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _settingMer = _interopRequireDefault(require("@/utils/settingMer"));
var _wechat = require("@/libs/wechat");
var _md = _interopRequireDefault(require("../../src/utils/md5.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// import md5l from 'js-md5';

var service = _axios.default.create({
  baseURL: _settingMer.default.apiBaseURL,
  timeout: 50000 * 10 // 过期时间
});

// request interceptor
service.interceptors.request.use(function (config) {
  // 发送请求之前做的
  var token = !_store.default.getters.token ? sessionStorage.getItem('token') : _store.default.getters.token;
  if (token) {
    config.headers['Authori-zation'] = token;
  }
  if (/get/i.test(config.method)) {
    config.params = config.params || {};
    config.params.temp = Date.parse(new Date()) / 1000;
  }
  var appId = "web_admin_program";
  var version = "1.0";
  var appSecret = "6bdf681dd72146b490785d130605fde4";
  var timestamp = parseInt(new Date().getTime() / 1000);
  var nonce = Math.floor(Math.random() * 8999) + 1000;
  var origin = appId + appSecret + timestamp + nonce + version;
  var sign = _md.default.hex_md5(origin);
  config.headers.appId = appId;
  config.headers.timestamp = timestamp;
  config.headers.sign = sign;
  config.headers.nonce = nonce;
  return config;
}, function (error) {
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  // console.log(response.data);
  // if(!res.data)
  // {
  //   return res;
  // }
  // if the custom code is not 20000, it is judged as an error.
  if (res.code === 401) {
    // to re-login
    _elementUi.MessageBox.confirm('您已经登出，您可以取消停留在这个页面，或重新登录', '退出', {
      confirmButtonText: '或重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      _store.default.dispatch('user/resetToken').then(function () {
        location.reload();
      });
    });
  }
  if (res.code && res.code !== 200 && res.code !== 401) {
    if (response.config.url.indexOf('/admin/marketing/saleFairDistribution/importFairDistributionByExcel') > -1) {
      if (res.code == '503') {
        return Promise.reject(res);
      }
    }
    if ((0, _wechat.isPhone)()) {
      //移动端
      return Promise.reject(res || 'Error');
    }
    (0, _elementUi.Message)({
      message: res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject();
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    // return Promise.reject(new Error(res.message || 'Error'))
  } else if (!res.code) {
    console.log('返回结果', res);
    return res;
  } else {
    return res.data;
  }
}, function (error) {
  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = exports.default = service;
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "node",
      class: _vm.nodeContainerClass,
      style: _vm.nodeContainerStyle,
      on: {
        dblclick: _vm.dblclickNode,
        click: _vm.clickNode,
        mouseup: _vm.changeNodeSite,
        contextmenu: function ($event) {
          $event.preventDefault()
          return _vm.nodeRightMenu($event)
        },
      },
    },
    [
      _c("div", { staticClass: "ef-node-left-ico flow-node-drag" }, [
        _c(
          "div",
          { staticClass: "ef-node-move", attrs: { title: _vm.node.name } },
          [
            _c("svg-icon", { attrs: { "icon-class": _vm.nodeIcoClass } }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ef-node-text",
                attrs: { "show-overflow-tooltip": true },
              },
              [_vm._v("\n        " + _vm._s(_vm.node.name) + "\n      ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/layout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var stayRouter = {
  path: '/stay',
  component: _layout.default,
  redirect: '/stay/stayManage',
  name: 'stay',
  alwaysShow: true,
  meta: {
    title: '留资管理',
    icon: 'clipboard'
  },
  children: [{
    path: 'stayManage',
    name: 'stayManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/stay/stayManage'));
      });
    },
    meta: {
      title: '留资管理',
      icon: 'clipboard'
    }
  }, {
    path: 'enterprise',
    name: 'enterPrise',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/stay/enterprise'));
      });
    },
    meta: {
      title: '企业信息查询',
      icon: 'clipboard'
    }
  }]
};
var _default = exports.default = stayRouter; // import stayRouter from '@/layout'
// const orderRouter = {
//   path: '/order',
//   component: Layout,
//   redirect: '/order/index',
//   name: 'Order',
//   alwaysShow: true,
//   meta: {
//     title: '订单',
//     icon: 'clipboard'
//   },
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/order/index'),
//       name: 'OrderIndex',
//       meta: { title: '订单' }
//     },
//   ]
// }
// export default stayRouter
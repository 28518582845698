"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

0;
var _default = exports.default = {
  name: "invoiceInformation",
  data: function data() {
    return {
      logVisible: false,
      loading: false,
      orderDatalist: {}
    };
  },
  mounted: function mounted() {},
  methods: {
    getDetail: function getDetail(id) {
      var _this = this;
      this.loading = true;
      (0, _order.orderDetailApi)({
        id: id
      }).then(function (res) {
        _this.orderDatalist = res;
        console.log(4444, res);
        _this.loading = false;
      }).catch(function () {
        _this.orderDatalist = null;
        _this.loading = false;
      });
    }
  }
};
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _edit = _interopRequireDefault(require("./edit/edit.vue"));
var enterpriseApi = _interopRequireWildcard(require("@/api/enterprise.js"));
var _auth = require("@/utils/auth");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    edit: _edit.default
  },
  data: function data() {
    return {
      constants: this.$constants,
      listPram: {
        name: null,
        page: 1,
        limit: this.$constants.page.limit[0],
        area: "1"
      },
      headeNum: [{
        name: "戴尔用户专区",
        type: 1
      }, {
        name: "跨品牌服务专区",
        type: 2
      }, {
        name: "活动表单",
        type: 3
      }],
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      },
      listData: {
        list: [],
        total: 0
      },
      editDialogVisual: false
    };
  },
  filters: {
    areaFilter: function areaFilter(val) {
      return val == 1 ? "戴尔用户专区" : val == 2 ? "跨品牌服务专区" : '活动表单';
    }
  },
  computed: {},
  mounted: function mounted() {
    this.handerSearch();
  },
  beforeDestroy: function beforeDestroy() {},
  methods: {
    handleAdd: function handleAdd(row) {
      var _this = this;
      this.editDialogVisual = true;
      if (row) {
        this.$nextTick(function () {
          _this.$refs["editForm"].init(row);
        });
      }
    },
    onchangeIsShow: function onchangeIsShow(row, flag) {
      var _this2 = this;
      enterpriseApi.OpenEnterpriseType({
        id: row.id,
        status: flag
      }).then(function (res) {
        _this2.$message.success("操作成功");
        _this2.handlerGetListData(_this2.listPram);
      });
    },
    handlerDel: function handlerDel(rowData) {
      var _this3 = this;
      this.$confirm("确定删除当前数据", "提示").then(function (result) {
        enterpriseApi.DelEnterpriseType(rowData.id).then(function () {
          _this3.$message.success("删除数据成功");
          _this3.handlerGetListData(_this3.listPram);
        });
      });
    },
    handerSearch: function handerSearch() {
      this.listPram.page = 1;
      this.handlerGetListData(this.listPram);
    },
    handlerGetListData: function handlerGetListData(prams) {
      var _this4 = this;
      enterpriseApi.ListEnterpriseType(prams).then(function (res) {
        _this4.listData = res;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetListData(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetListData(this.listPram);
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _wxForm = _interopRequireDefault(require("../common/wx-form"));
var _generalMixin = require("./general-mixin");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    wxFlowForm: _wxForm.default
  },
  mixins: [_generalMixin.generalMixin],
  methods: {}
};
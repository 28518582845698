"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterIsPromoter = filterIsPromoter;
exports.levelFilter = levelFilter;
exports.typeFilter = typeFilter;
//会员过滤器

/**
 * 等级
 */
function levelFilter(status) {
  if (!status) {
    return '';
  }
  var arrayList = JSON.parse(localStorage.getItem('levelKey'));
  var array = arrayList.filter(function (item) {
    return status === item.id;
  });
  if (array.length) {
    return array[0].name;
  } else {
    return '';
  }
}

/**
 * 用户类型
 */
function typeFilter(status) {
  var statusMap = {
    'wechat': '微信用户',
    'routine': '小程序用户',
    'h5': 'H5用户'
  };
  return statusMap[status];
}

/**
 * 用户类型
 */
function filterIsPromoter(status) {
  var statusMap = {
    true: '推广员',
    false: '普通用户'
  };
  return statusMap[status];
}
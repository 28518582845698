var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "优惠劵名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      maxlength: "12",
                      "show-word-limit": "",
                      placeholder: "请输入优惠券名称",
                    },
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠劵类型" } },
                [
                  _c(
                    "el-tooltip",
                    { staticClass: "tooltip1", attrs: { placement: "bottom" } },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v("\n            通用券：全场可用"),
                          _c("br"),
                          _vm._v("商品券：适用于部分商品"),
                          _c("br"),
                          _vm._v("品类券：适用于指定分类\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c("i", { staticClass: "el-icon-question" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.useType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "useType", $$v)
                        },
                        expression: "ruleForm.useType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("通用券"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.belongToType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "belongToType", $$v)
                        },
                        expression: "ruleForm.belongToType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("DA COUPON"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("其他")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.useType === 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择品类：", prop: "primaryKey" } },
                    [
                      _c("el-cascader", {
                        staticClass: "selWidth",
                        attrs: {
                          options: _vm.merCateList,
                          props: _vm.props2,
                          clearable: "",
                          "show-all-levels": false,
                        },
                        model: {
                          value: _vm.ruleForm.primaryKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "primaryKey", $$v)
                          },
                          expression: "ruleForm.primaryKey",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.useType === 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "商品：", prop: "checked" } },
                    [
                      _c(
                        "div",
                        { staticClass: "acea-row" },
                        [
                          _vm.ruleForm.checked.length
                            ? _vm._l(
                                _vm.ruleForm.checked,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "pictrue" },
                                    [
                                      _c("img", { attrs: { src: item.image } }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-error btndel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(index)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              on: { click: _vm.changeGood },
                            },
                            [
                              _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "折扣类型", prop: "discountType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeDiscount },
                      model: {
                        value: _vm.ruleForm.discountType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "discountType", $$v)
                        },
                        expression: "ruleForm.discountType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("面值金额"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("折扣")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.discountType == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "面值金额", prop: "money" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 1, label: "描述文字" },
                        model: {
                          value: _vm.ruleForm.money,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "money", $$v)
                          },
                          expression: "ruleForm.money",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.discountType == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "折扣", prop: "money" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 1, max: 100, label: "描述文字" },
                        model: {
                          value: _vm.ruleForm.money,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "money", $$v)
                          },
                          expression: "ruleForm.money",
                        },
                      }),
                      _c("span", { staticClass: "f-unit" }, [_vm._v(" %")]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用门槛" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.threshold,
                        callback: function ($$v) {
                          _vm.threshold = $$v
                        },
                        expression: "threshold",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("无门槛"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("有门槛"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.threshold
                ? _c(
                    "el-form-item",
                    { attrs: { label: "优惠券最低消费", prop: "minPrice" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 1, label: "描述文字" },
                        model: {
                          value: _vm.ruleForm.minPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "minPrice", $$v)
                          },
                          expression: "ruleForm.minPrice",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用有效期" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.isFixedTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isFixedTime", $$v)
                        },
                        expression: "ruleForm.isFixedTime",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("天数"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("时间段"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.ruleForm.isFixedTime
                ? _c(
                    "el-form-item",
                    { attrs: { label: "使用有效期限（天）", prop: "day" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, label: "描述文字" },
                        model: {
                          value: _vm.ruleForm.day,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "day", $$v)
                          },
                          expression: "ruleForm.day",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.isFixedTime
                ? _c(
                    "el-form-item",
                    { attrs: { label: "使用有效期限", prop: "resource" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "550px" },
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "至",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "start-placeholder": "开始日期",
                          "picker-options": _vm.pickerOptions,
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.termTime,
                          callback: function ($$v) {
                            _vm.termTime = $$v
                          },
                          expression: "termTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "领取是否限时", prop: "isForever" } },
                [
                  _c(
                    "el-tooltip",
                    { staticClass: "tooltip2", attrs: { placement: "bottom" } },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v("\n            限时：设定优惠券发放时间"),
                          _c("br"),
                          _vm._v(
                            "不限时：开启状态的优惠 券永久发放\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("i", { staticClass: "el-icon-question" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.isForever,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isForever", $$v)
                        },
                        expression: "ruleForm.isForever",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("限时"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("不限时"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.isForever
                ? _c(
                    "el-form-item",
                    { attrs: { label: "领取时间" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "550px" },
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "至",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "picker-options": _vm.pickerOptions,
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        on: { blur: _vm.handleTimestamp },
                        model: {
                          value: _vm.isForeverTime,
                          callback: function ($$v) {
                            _vm.isForeverTime = $$v
                          },
                          expression: "isForeverTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "领取方式", prop: "resource" } },
                [
                  _c(
                    "el-tooltip",
                    { staticClass: "tooltip3", attrs: { placement: "bottom" } },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            "\n            手动领取：活动期间向符 合条件的人群开放优惠券\n            领取入口，活动结束后领 取入口消失"
                          ),
                          _c("br"),
                          _vm._v(
                            "新人券：用户入会后发放\n            的优惠券"
                          ),
                          _c("br"),
                          _vm._v(
                            "赠送券：购买指定商品后， 用户可领取的优惠券\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("i", { staticClass: "el-icon-question" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "type", $$v)
                        },
                        expression: "ruleForm.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("手动领取"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("赠送券"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "适用身份", prop: "applyType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.applyType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "applyType", $$v)
                        },
                        expression: "ruleForm.applyType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("企业身份"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("园区身份"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "用户分组", prop: "isGroup" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.groupDisable },
                      model: {
                        value: _vm.isGroup,
                        callback: function ($$v) {
                          _vm.isGroup = $$v
                        },
                        expression: "isGroup",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("无分组"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("有分组"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isGroup
                ? _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "storeGroupId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selWidth",
                          attrs: {
                            multiple: "",
                            placeholder: "请选择分组",
                            clearable: "",
                          },
                          model: {
                            value: _vm.ruleForm.storeGroupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "storeGroupId", $$v)
                            },
                            expression: "ruleForm.storeGroupId",
                          },
                        },
                        _vm._l(_vm.groupList, function (group, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: group.name, value: group.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否有券码", prop: "couponCodeType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.couponCodeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "couponCodeType", $$v)
                        },
                        expression: "ruleForm.couponCodeType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("线上码"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否限量", prop: "isLimited" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.limiteDisable },
                      model: {
                        value: _vm.ruleForm.isLimited,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isLimited", $$v)
                        },
                        expression: "ruleForm.isLimited",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("限量"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("不限量"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.isLimited
                ? _c(
                    "el-form-item",
                    { attrs: { label: "发布数量", prop: "total" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 1, label: "排序" },
                        model: {
                          value: _vm.ruleForm.total,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "total", $$v)
                          },
                          expression: "ruleForm.total",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c(
                    "el-tooltip",
                    { staticClass: "tooltip4", attrs: { placement: "bottom" } },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [_vm._v("优惠券列表的排列顺序")]
                      ),
                      _vm._v(" "),
                      _c("i", { staticClass: "el-icon-question" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-input-number", {
                    attrs: { min: 0, label: "排序" },
                    model: {
                      value: _vm.ruleForm.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "sort", $$v)
                      },
                      expression: "ruleForm.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("开启"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用详情", prop: "hasUseInfo" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.hasUseInfo,
                        callback: function ($$v) {
                          _vm.hasUseInfo = $$v
                        },
                        expression: "hasUseInfo",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("不填写"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("填写"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasUseInfo
                    ? _c(
                        "div",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 4,
                              placeholder: "请输入使用详情",
                            },
                            model: {
                              value: _vm.ruleForm.usageDetails,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "usageDetails", $$v)
                              },
                              expression: "ruleForm.usageDetails",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        loading: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("立即创建")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
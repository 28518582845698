"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    dialogPresent: {
      type: Boolean,
      default: false
    },
    selectPresentList: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      multipleSelection: [],
      listLoading: true,
      tableFrom: {
        page: 1,
        limit: 10,
        cateId: "",
        keywords: "",
        type: "1",
        isPresent: true
      },
      tableData: {
        data: [],
        total: 0
      },
      selectValue: ""
    };
  },
  watch: {
    selectPresentList: function selectPresentList(val) {
      var _this = this;
      if (val.length > 0) {
        console.log(val, "selectPresentList");
        val.forEach(function (item) {
          _this.tableData.data.forEach(function (n, index) {
            console.log(n);
            if (item.goodsId == n.id) {
              console.log("hahhah", n.id);
              _this.$nextTick(function () {
                _this.$refs.multipleTable.toggleRowSelection(_this.tableData.data[index], true);
              });
            }
          });
        });
      }
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getRowKey: function getRowKey(row) {
      return row.id;
    },
    unique: function unique(arr, val) {
      var res = new Map();
      return arr.filter(function (item) {
        return !res.has(item[val]) && res.set(item[val], 1);
      });
    },
    handleSelectionChange: function handleSelectionChange(rows) {
      var _this2 = this;
      this.multipleSelection = [];
      if (rows) {
        rows = this.unique(rows, "id");
        // console.log(rows, "rowssssss");
        rows.forEach(function (row) {
          _this2.multipleSelection.push(row);
        });
      }
      console.log(this.multipleSelection, "去重*************");
    },
    handleSubmit: function handleSubmit() {
      // this.multipleSelection = new Set(this.multipleSelection);
      this.$emit("closeDialog", this.multipleSelection);
      this.$refs.multipleTable.clearSelection();
    },
    handleCancel: function handleCancel() {
      this.$emit("closeDialog", this.multipleSelection);
      this.$refs.multipleTable.clearSelection();
    },
    getList: function getList() {
      var _this3 = this;
      (0, _store.productLstApi)(this.tableFrom).then(function (res) {
        console.log(res, "res");
        console.log(_this3.selectPresentList);
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.listLoading = false;
        if (_this3.selectPresentList.length == 0) return;
        _this3.selectPresentList.forEach(function (item) {
          _this3.tableData.data.forEach(function (n, index) {
            // console.log(n);
            if (item.goodsId == n.id) {
              _this3.$nextTick(function () {
                console.log("hahhah", n.id);
                _this3.$refs.multipleTable.toggleRowSelection(_this3.tableData.data[index], true);
              });
            }
          });
        });
      }).catch(function (res) {
        _this3.listLoading = false;
        _this3.$message.error(res.message);
      });
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    }
  }
};
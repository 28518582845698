var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "访问路径配置",
        visible: _vm.visible,
        "destroy-on-close": "",
        "close-on-click-modal": false,
        width: "660px",
      },
      on: { close: _vm.cancle },
    },
    [
      _c(
        "el-form",
        {
          ref: "editPram",
          attrs: {
            model: _vm.formModel,
            "label-width": "130px",
            "label-position": "left",
          },
        },
        [
          _c(
            "div",
            { staticClass: "set-block" },
            [
              _c(
                "div",
                {
                  staticStyle: { "font-weight": "600", "line-height": "50px" },
                },
                [_vm._v("路径配置")]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "跳转类型",
                    prop: "jumpType",
                    rules: [
                      {
                        required: true,
                        message: "请选择跳转类型",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.viewFlag },
                      on: { change: _vm.changeType },
                      model: {
                        value: _vm.formModel.jumpType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModel, "jumpType", $$v)
                        },
                        expression: "formModel.jumpType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("小程序页面"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("小程序appid"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("外部链接"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formModel.jumpType == "1"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否定位跳转",
                        prop: "isJumpLoc",
                        rules: [
                          {
                            required: true,
                            message: "请选择",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.viewFlag },
                          on: { change: _vm.changeLocType },
                          model: {
                            value: _vm.formModel.isJumpLoc,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "isJumpLoc", $$v)
                            },
                            expression: "formModel.isJumpLoc",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("否"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("是"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formModel.jumpType == "1" && _vm.formModel.isJumpLoc
                ? _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "选择页面",
                            prop: "pageLoc",
                            rules: [
                              {
                                required: true,
                                message: "请选择",
                                trigger: ["change"],
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.viewFlag,
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.formModel.pageLoc,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formModel, "pageLoc", $$v)
                                },
                                expression: "formModel.pageLoc",
                              },
                            },
                            _vm._l(_vm.locPages, function (page, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: page.label, value: page.url },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "选择定位位置",
                            prop: "locName",
                            rules: [
                              {
                                required: true,
                                message: "请选择",
                                trigger: ["change"],
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.viewFlag,
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.formModel.locName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formModel, "locName", $$v)
                                },
                                expression: "formModel.locName",
                              },
                            },
                            _vm._l(_vm.locList, function (location, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: location.label,
                                  value: location.label,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "关键字",
                            prop: "keyWord",
                            rules: [
                              {
                                required: true,
                                message: "请输入关键字",
                                trigger: ["blur"],
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.viewFlag,
                              placeholder: "关键字",
                            },
                            model: {
                              value: _vm.formModel.keyWord,
                              callback: function ($$v) {
                                _vm.$set(_vm.formModel, "keyWord", $$v)
                              },
                              expression: "formModel.keyWord",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "生成定位路径", prop: "path" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.pathGenerate) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formModel.jumpType == "2"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "appId",
                        prop: "appId",
                        rules: [
                          {
                            required: true,
                            message: "请输入appId",
                            trigger: ["blur"],
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.viewFlag, placeholder: "appId" },
                        model: {
                          value: _vm.formModel.appId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModel, "appId", $$v)
                          },
                          expression: "formModel.appId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formModel.jumpType > 0 &&
              _vm.formModel.jumpType != "2" &&
              !_vm.formModel.isJumpLoc
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.formModel.jumpType == "1" ? "页面路径" : "链接",
                        prop: "jumpPath",
                        rules: [
                          {
                            required: true,
                            message: "请配置",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.viewFlag,
                          placeholder: "请配置",
                        },
                        model: {
                          value: _vm.formModel.jumpPath,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModel, "jumpPath", $$v)
                          },
                          expression: "formModel.jumpPath",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formModel.jumpType == "2" && !_vm.formModel.isJumpLoc
                ? _c(
                    "el-form-item",
                    { attrs: { label: "指定路径", prop: "jumpPath" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.viewFlag,
                          placeholder: "请配置",
                        },
                        model: {
                          value: _vm.formModel.jumpPath,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModel, "jumpPath", $$v)
                          },
                          expression: "formModel.jumpPath",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "other-block" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否开启",
                    prop: "jumpStatus",
                    rules: [
                      {
                        required: true,
                        message: "请选择",
                        trigger: ["blur", "change"],
                      },
                    ],
                  },
                },
                [
                  _c("el-switch", {
                    attrs: {
                      disabled: _vm.viewFlag,
                      "active-text": "开启",
                      "inactive-text": "关闭",
                      "active-value": true,
                      "inactive-value": false,
                    },
                    model: {
                      value: _vm.formModel.jumpStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModel, "jumpStatus", $$v)
                      },
                      expression: "formModel.jumpStatus",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          !_vm.viewFlag
            ? _c("el-button", { on: { click: _vm.reset } }, [_vm._v("重置")])
            : _vm._e(),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.cancle } }, [_vm._v("取 消")]),
          _vm._v(" "),
          !_vm.viewFlag
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.submit },
                },
                [_vm._v("确 定")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _messageForm = _interopRequireDefault(require("@/views/marketing/automated/Flow/common/message-form.vue"));
var _generalMixin = require("./general-mixin");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    messageFlowForm: _messageForm.default
  },
  mixins: [_generalMixin.generalMixin]
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonMixin = require("./common-mixin");
var _branchMixin = require("./branch-mixin");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  mixins: [_commonMixin.commonMixin, _branchMixin.branchMixin],
  data: function data() {
    return {
      branchGroupRules: {
        branchGroupDate: [{
          required: true,
          message: "请选择日期时间",
          trigger: "change"
        }]
      }
    };
  },
  methods: {
    dateChange: function dateChange(val, index) {
      this.$set(this.branchForm.branchList[index], "name", val);
    }
  }
};
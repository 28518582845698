"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _user = require("@/api/user");
var _index = _interopRequireDefault(require("@/components/cards/index"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    cardsData: _index.default
  },
  data: function data() {
    return {
      loading: false,
      options: [],
      fromList: this.$constants.fromList,
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        dateLimit: '',
        keywords: ''
      },
      timeVal: [],
      values: []
    };
  },
  mounted: function mounted() {
    this.getList();
    // this.getUserList()
  },
  methods: {
    // remoteMethod(query) {
    //   if (query !== '') {
    //     this.loading = true;
    //     setTimeout(() => {
    //       this.loading = false;
    //       userListApi({keywords: query, page: 1, limit: 10}).then(res => {
    //         this.options = res.list
    //       })
    //     }, 200);
    //   } else {
    //     this.options = [];
    //   }
    // },
    // seachList() {
    //   this.tableFrom.page = 1
    //   this.getList()
    // },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.dateLimit = tab;
      this.tableFrom.page = 1;
      this.timeVal = [];
      this.getList();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
    },
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      var obj = {
        limit: this.tableFrom.limit,
        page: this.tableFrom.page,
        keywords: this.tableFrom.keywords.toString(),
        dateLimit: this.tableFrom.dateLimit
      };
      (0, _marketing.integralListApi)({
        limit: this.tableFrom.limit,
        page: this.tableFrom.page
      }, obj).then(function (res) {
        res.list.map(function (item) {
          if (item.type == 1) {
            item.integral = '+' + item.integral;
          } else {
            item.integral = '-' + item.integral;
          }
        });
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/contentGoods/index"));
var _index2 = _interopRequireDefault(require("@/components/Tinymce/index"));
var _store = require("@/api/store");
var caseApi = _interopRequireWildcard(require("@/api/case.js"));
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
var _systemSetting = require("@/api/systemSetting");
var _auth = require("@/utils/auth");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 导入组件
var _default = exports.default = {
  // name: "edit",
  components: {
    Tinymce: _index2.default,
    contentGoods: _index.default
  },
  data: function data() {
    return {
      loading: false,
      constants: this.$constants,
      merCateList: [],
      props2: {
        children: "child",
        label: "name",
        value: "id",
        multiple: true,
        emitPath: false
      },
      pram: {
        id: null,
        name: "",
        description: "",
        tag: "",
        categoryId: [],
        coverPath: "",
        // previewPdfPath: "",
        wholePdfPath: "",
        sliderImages: [],
        clLabel: "",
        stayTitle: "",
        stayHeadImage: ""
      },
      editData: {},
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      },
      infoLoading: false,
      dragging: null
    };
  },
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
    this.getCategorySelect();
  },
  mounted: function mounted() {
    if (this.$route.params.id) {
      this.getInfo();
      this.setTagsViewTitle();
    }
  },
  watch: {
    'pram.coverPath': function pramCoverPath() {
      this.$refs['pram'].validateField('coverPath');
    },
    'pram.sliderImages': function pramSliderImages() {
      this.$refs['pram'].validateField('sliderImages');
    },
    'pram.wholePdfPath': function pramWholePdfPath() {
      this.$refs['pram'].validateField('wholePdfPath');
    }
  },
  methods: {
    // 移动
    handleDragStart: function handleDragStart(e, item) {
      this.dragging = item;
    },
    handleDragEnd: function handleDragEnd(e, item) {
      this.dragging = null;
    },
    handleDragOver: function handleDragOver(e) {
      e.dataTransfer.dropEffect = "move";
    },
    handleDragEnter: function handleDragEnter(e, item) {
      e.dataTransfer.effectAllowed = "move";
      if (item === this.dragging) {
        return;
      }
      var newItems = _toConsumableArray(this.pram.sliderImages);
      var src = newItems.indexOf(this.dragging);
      var dst = newItems.indexOf(item);
      newItems.splice.apply(newItems, [dst, 0].concat(_toConsumableArray(newItems.splice(src, 1))));
      this.pram.sliderImages = newItems;
    },
    handleRemove: function handleRemove(i) {
      this.pram.sliderImages.splice(i, 1);
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit === "1" && !num) {
          _this.pram.coverPath = img[0].sattDir;
        }
        if (tit === "2" && !num) {
          if (img.length > 4) return this.$message.warning("最多选择4张图片！");
          if (img.length + _this.pram.sliderImages.length > 4) return this.$message.warning("最多选择4张图片！");
          img.map(function (item) {
            _this.pram.sliderImages.push(item.sattDir);
          });
        }
      }, tit, "content");
    },
    // 留资封面
    modalPicTapStay: function modalPicTapStay(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.pram.stayHeadImage = img[0].sattDir;
      }, tit, "content");
    },
    getCategorySelect: function getCategorySelect() {
      var _this2 = this;
      (0, _store.categoryApi)({
        status: -1,
        type: 10
      }).then(function (res) {
        _this2.merCateList = _this2.filerMerCateList(res);
      });
    },
    filerMerCateList: function filerMerCateList(treeData) {
      // debugger

      return treeData.map(function (item) {
        if (!item.status) {
          item.disabled = true;
        }
        item.label = item.name;
        return item;
      });
    },
    // 上传视频
    getVideo: function getVideo(url, field) {
      this.pram[field] = url;
    },
    getInfo: function getInfo() {
      var _this3 = this;
      this.infoLoading = true;
      caseApi.InfoCase(this.$route.params.id).then(function (data) {
        _this3.editData = data;
        _this3.editData.categoryId = data.categoryId ? data.categoryId.split(",") : [];
        _this3.editData.sliderImages = data.previewPdfPathList;
        _this3.hadlerInitEditData();
      }).catch(function () {
        _this3.infoLoading = false;
      });
    },
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this4 = this;
      var formData = new FormData();
      formData.append("media", param.file);
      var loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      (0, _systemSetting.fileImageApi)(formData, {
        type: "image"
      }).then(function (res) {
        loading.close();
        _this4.pram.mediaId = res.mediaId;

        // this.formValidate.contents.mediaId = res.mediaId
        // this.formValidate.contents.srcUrl = res.url
        _this4.$message.success("上传成功");
      }).catch(function () {
        loading.close();
      });
    },
    // 图文封面
    // modalPicTap(tit) {
    //   const _this = this;
    //   this.$modalUpload(
    //     function (img) {
    //       _this.pram.coverPath = img[0].sattDir;
    //     },
    //     tit,
    //     "content"
    //   );
    // },
    // 修改赋值
    hadlerInitEditData: function hadlerInitEditData() {
      if (!this.$route.params.id) return;
      var _this$editData = this.editData,
        categoryId = _this$editData.categoryId,
        coverPath = _this$editData.coverPath,
        description = _this$editData.description,
        name = _this$editData.name,
        id = _this$editData.id,
        wholePdfPath = _this$editData.wholePdfPath,
        tag = _this$editData.tag,
        sliderImages = _this$editData.sliderImages,
        stayHeadImage = _this$editData.stayHeadImage,
        stayTitle = _this$editData.stayTitle,
        clLabel = _this$editData.clLabel;
      console.log(1111111111, this.editData);
      this.pram.categoryId = categoryId;
      this.pram.coverPath = coverPath;
      this.pram.description = description;
      this.pram.name = name;
      // this.pram.previewPdfPath = previewPdfPath;
      this.pram.wholePdfPath = wholePdfPath;
      this.pram.tag = tag;
      this.pram.id = id;
      this.pram.sliderImages = sliderImages;
      this.pram.stayHeadImage = stayHeadImage;
      this.pram.clLabel = clLabel;
      this.pram.stayTitle = stayTitle;
      this.infoLoading = false;
    },
    handerSubmit: function handerSubmit(form) {
      var _this5 = this;
      this.$refs[form].validate(function (valid) {
        if (!valid) return;
        if (!_this5.$route.params.id) {
          _this5.handlerSave();
        } else {
          _this5.handlerUpdate();
        }
      });
    },
    handlerUpdate: function handlerUpdate() {
      var _this6 = this;
      this.loading = true;
      var conInfo = Object.assign({}, this.pram);
      conInfo.categoryId = this.pram.categoryId.join(",");
      conInfo.previewPdfPath = this.pram.sliderImages.join(",");
      delete conInfo["sliderImages"];
      caseApi.UpdateCase(conInfo).then(function (data) {
        _this6.$message.success("修改解决方案成功");
        _this6.loading = false;
        _this6.$router.push({
          path: "/content/caseManager"
        });
      }).catch(function () {
        _this6.loading = false;
      });
    },
    // 新增种草
    handlerSave: function handlerSave() {
      var _this7 = this;
      this.loading = true;
      var conInfo = Object.assign({}, this.pram);
      conInfo.categoryId = this.pram.categoryId.join(",");
      conInfo.previewPdfPath = this.pram.sliderImages.join(",");
      delete conInfo["sliderImages"];
      caseApi.AddCase(conInfo).then(function (data) {
        _this7.$message.success("新增解决方案成功");
        _this7.loading = false;
        _this7.$router.push({
          path: "/content/caseManager"
        });
      }).catch(function () {
        _this7.loading = false;
      });
    },
    setTagsViewTitle: function setTagsViewTitle() {
      var title = "编辑解决方案";
      var route = Object.assign({}, this.tempRoute, {
        title: "".concat(title, "-").concat(this.$route.params.id)
      });
      this.$store.dispatch("tagsView/updateVisitedView", route);
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "record-wrapper", attrs: { id: "serviceWork" } },
    [
      _c("meta", { attrs: { name: "referrer", content: "no-referrer" } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            height: "550px",
            "overflow-x": "scroll",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "tabLeft",
              staticStyle: {
                width: "300px",
                "background-color": "#f5f5f5",
                "margin-right": "5px",
                height: "550px",
                "min-width": "300px",
                "overflow-y": "scroll",
              },
            },
            [
              _c(
                "el-collapse",
                {
                  staticClass: "leftList",
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: _vm.waitLabel, name: "1" } },
                    _vm._l(_vm.unReadList, function (item) {
                      return _c("ul", { key: item.index }, [
                        _c(
                          "li",
                          {
                            staticClass: "collapseLi",
                            staticStyle: { position: "relative" },
                          },
                          [
                            _c(
                              "el-badge",
                              {
                                staticClass: "item",
                                attrs: {
                                  value:
                                    item.msgCount == 0 ? "" : item.msgCount,
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px",
                                    "border-radius": "25px",
                                    "margin-left": "30%",
                                  },
                                  attrs: { src: item.avatar, alt: "" },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "nickNameClass" }, [
                              _vm._v(
                                _vm._s(
                                  item.nickname
                                    ? item.nickname.length > 7
                                      ? item.nickname.substring(0, 7) + "...."
                                      : item.nickname
                                    : item.phone
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "lastMsgClass" }, [
                              _vm._v(_vm._s(item.lastMsg.msg)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "btnClass" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.insertMsg(item, "unRead")
                                      },
                                    },
                                  },
                                  [_vm._v("接入")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  left: "77%",
                                },
                              },
                              [_vm._v(_vm._s(item.createtime))]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "el-collapse-item",
                    { attrs: { title: _vm.accessLabel, name: "2" } },
                    _vm._l(_vm.alReadList, function (item, index) {
                      return _c("ul", { key: item.index }, [
                        _c(
                          "li",
                          {
                            class: [
                              _vm.selectLi === item.userid ? "activeClick" : "",
                            ],
                            staticStyle: { position: "relative" },
                            attrs: {
                              id: [_vm.insertSelectLi ? "activeClick" : ""],
                            },
                            on: {
                              click: function ($event) {
                                return _vm.insertMsg(item, index)
                              },
                            },
                          },
                          [
                            _c(
                              "el-badge",
                              { staticClass: "item", attrs: { value: "" } },
                              [
                                item.msgStatus == 0
                                  ? _c("div", {
                                      staticStyle: {
                                        width: "10px",
                                        height: "10px",
                                        background: "red",
                                        position: "absolute",
                                        left: "130%",
                                        "border-radius": "10px",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("img", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px",
                                    "border-radius": "25px",
                                    "margin-left": "30%",
                                  },
                                  attrs: { src: item.avatar, alt: "" },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "nickNameClass" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    item.nickname
                                      ? item.nickname.length > 7
                                        ? item.nickname.substring(0, 7) + "...."
                                        : item.nickname
                                      : item.phone
                                  ) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "lastMsgClass" }, [
                              _vm._v(
                                _vm._s(item.lastMsg ? item.lastMsg.msg : "")
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "lastMsgClass1",
                                staticStyle: { top: "10px" },
                              },
                              [_vm._v(_vm._s(item.createtime))]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "el-collapse-item",
                    { attrs: { title: _vm.completeLabel, name: "4" } },
                    _vm._l(_vm.endUserList, function (item, index) {
                      return _c("ul", { key: item.index }, [
                        _c(
                          "li",
                          {
                            class: [
                              _vm.completeSelectLi === index
                                ? "activeClick"
                                : "",
                            ],
                            staticStyle: { position: "relative" },
                            on: {
                              click: function ($event) {
                                return _vm.insertComplete(item, index)
                              },
                            },
                          },
                          [
                            _c(
                              "el-badge",
                              { staticClass: "item", attrs: { value: "" } },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px",
                                    "border-radius": "25px",
                                    "margin-left": "30%",
                                  },
                                  attrs: { src: item.avatar, alt: "" },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "nickNameClass" }, [
                              _vm._v(
                                _vm._s(
                                  item.nickname
                                    ? item.nickname.length > 7
                                      ? item.nickname.substring(0, 7) + "...."
                                      : item.nickname
                                    : item.phone
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "lastMsgClass" }, [
                              _vm._v(_vm._s(item.createtime)),
                            ]),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { height: "550px", flex: "1", "min-width": "600px" },
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "line-height": "45px" },
                  attrs: { type: "flex", justify: "end" },
                },
                [
                  _c("el-col", { attrs: { span: 1 } }),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.beginTalking,
                          expression: "!beginTalking",
                        },
                      ],
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "18px",
                            "font-weight": "700",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.baseInfoForm.nickname
                                ? _vm.baseInfoForm.nickname
                                : _vm.baseInfoForm.phone
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.closeSocket,
                          expression: "closeSocket",
                        },
                      ],
                      staticStyle: { "margin-right": "10px" },
                      attrs: { span: 4 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.trunKefu },
                        },
                        [_vm._v("转接到")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.closeSocket,
                          expression: "closeSocket",
                        },
                      ],
                      attrs: { span: 4 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.closeTalking },
                        },
                        [_vm._v("结束对话")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ul",
                {
                  staticClass: "infinite-list",
                  staticStyle: { width: "100%" },
                },
                [
                  _c(
                    "div",
                    {
                      class: { beginTalkingClass: _vm.isBegintalking },
                      staticStyle: {
                        height: "350px",
                        overflow: "auto",
                        padding: "10px",
                      },
                      attrs: { id: "bigDiv" },
                    },
                    [
                      _vm._l(_vm.chatItems, function (item) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.beginTalking,
                                expression: "!beginTalking",
                              },
                            ],
                            key: item.index,
                          },
                          [
                            item.content.msgtype === "timemark"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-left": "40%",
                                      "font-size": "14px",
                                      color: "#bbb",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("turnTime")(
                                            item.content.msg,
                                            _vm.$data._this
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            (item.from == 3 ||
                              item.from == 2 ||
                              item.from == 1) &&
                            item.content.msgtype !== "timemark"
                              ? _c("div", { staticClass: "msg msg-right" }, [
                                  _c("div", { staticClass: "img-wrapper" }, [
                                    item.from == 1
                                      ? _c("img", {
                                          staticClass: "photoImg",
                                          attrs: { src: _vm.from3 },
                                        })
                                      : _c("img", {
                                          staticClass: "photoImg",
                                          attrs: { src: _vm.from1 },
                                        }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "message-wrapper message-wrapper-right",
                                      staticStyle: { "margin-bottom": "10px" },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "message",
                                        staticStyle: { "line-height": "20px" },
                                        domProps: {
                                          innerHTML: _vm._s(item.content.msg),
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          item.content.msgtype !== "timemark",
                                        expression:
                                          "item.content.msgtype !== 'timemark'",
                                      },
                                    ],
                                    staticClass: "msg msg-left",
                                  },
                                  [
                                    _c("div", { staticClass: "img-wrapper" }, [
                                      _c("img", {
                                        staticClass: "photoImg",
                                        attrs: { src: _vm.from2 },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "message-wrapper message-wrapper-left",
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "message",
                                          staticStyle: {
                                            "line-height": "20px",
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(item.content.msg),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.beginTalking,
                              expression: "beginTalking",
                            },
                          ],
                          staticStyle: {
                            "margin-top": "200px",
                            "margin-left": "45%",
                            "font-size": "16px",
                          },
                        },
                        [_vm._v("\n            暂无会话\n          ")]
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.isTalking
                    ? _c("el-divider", { attrs: { contenteditable: "false" } })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.closeSocket
                    ? _c("div", { staticStyle: { display: "flex" } }, [
                        _c("div", { staticStyle: { "margin-left": "20px" } }, [
                          _c(
                            "span",
                            {
                              staticClass: "iconfont",
                              staticStyle: { cursor: "pointer" },
                              on: { click: _vm.clickEmotion },
                            },
                            [_vm._v("")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "18px",
                              "margin-right": "10px",
                              height: "19px",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "upload-demo mr10",
                                attrs: {
                                  action: "#",
                                  "file-list": _vm.fileList,
                                  "http-request": _vm.handleUploadForm,
                                  accept:
                                    ".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.GIF",
                                },
                              },
                              [
                                _c("span", {
                                  staticClass: "iconfont icon-fengjing",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              "margin-right": "16px",
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont icon-shangpin-tianchong",
                              on: { click: _vm.goodsDetailClick },
                            }),
                            _c(
                              "span",
                              { on: { click: _vm.goodsDetailClick } },
                              [_vm._v("商品卡片")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              "margin-right": "16px",
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont icon-wuliuqiache2",
                              on: { click: _vm.wuliuClick },
                            }),
                            _c("span", { on: { click: _vm.wuliuClick } }, [
                              _vm._v("物流卡片"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              "margin-right": "16px",
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont icon-16",
                              on: { click: _vm.articleClick },
                            }),
                            _c("span", { on: { click: _vm.articleClick } }, [
                              _vm._v("种草文章"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              "margin-right": "16px",
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont icon-youhuiquan",
                              on: { click: _vm.couponClick },
                            }),
                            _c("span", { on: { click: _vm.couponClick } }, [
                              _vm._v("优惠券"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { cursor: "pointer" } }, [
                          _c("span", {
                            staticClass: "iconfont icon-xingxing",
                            on: { click: _vm.manyiClick },
                          }),
                          _c("span", { on: { click: _vm.manyiClick } }, [
                            _vm._v("满意度评价"),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showEmotion
                    ? _c(
                        "div",
                        {
                          staticClass: "emotion-box",
                          staticStyle: { height: "200px" },
                          attrs: { disabled: "" },
                        },
                        _vm._l(_vm.list, function (line, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "emotion-box-line" },
                            _vm._l(line, function (item, i) {
                              return _c(
                                "emotion",
                                {
                                  key: i,
                                  staticClass: "emotion-item",
                                  attrs: { emotion: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.clickHandler(item)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.closeSocket,
                        expression: "closeSocket",
                      },
                    ],
                    ref: "inputContent",
                    staticStyle: {
                      border: "1px solid #ccc",
                      margin: "auto",
                      display: "inline-block",
                    },
                    attrs: { id: "inputId", contenteditable: "true" },
                    domProps: { innerHTML: _vm._s(_vm.inputContent) },
                    on: { input: _vm.inputContentClick },
                  }),
                  _vm._v(" "),
                  _vm.closeSocket
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              class: { activeBtn: _vm.isActive },
                              staticStyle: {
                                float: "right",
                                "margin-right": "20px",
                              },
                              attrs: {
                                size: "small",
                                tabindex: "1",
                                type: "info",
                                plain: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.keyup_submit(13)
                                },
                              },
                            },
                            [_vm._v("发送")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isTalking && !_vm.beginTalking
                ? _c("el-divider", { attrs: { contenteditable: "false" } }, [
                    _c("span", [_vm._v("会话已结束")]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isTalking,
                  expression: "isTalking",
                },
              ],
              ref: "tabRight",
              staticClass: "tabRight",
              staticStyle: { width: "350px", "min-width": "300px" },
            },
            [
              _c(
                "el-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isTalking,
                      expression: "isTalking",
                    },
                  ],
                },
                [
                  _c(
                    "el-tabs",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.rightCard,
                          expression: "rightCard",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      model: {
                        value: _vm.editableTabsValue,
                        callback: function ($$v) {
                          _vm.editableTabsValue = $$v
                        },
                        expression: "editableTabsValue",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        {
                          staticClass: "firstTab",
                          staticStyle: {
                            "text-align": "center",
                            "font-size": "14px",
                          },
                          attrs: { label: "用户资料", name: "first" },
                        },
                        [
                          _c(
                            "el-tabs",
                            {
                              staticClass: "smallTab",
                              model: {
                                value: _vm.childTabsValue,
                                callback: function ($$v) {
                                  _vm.childTabsValue = $$v
                                },
                                expression: "childTabsValue",
                              },
                            },
                            [
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "基本资料", name: "one" } },
                                [
                                  _c(
                                    "el-card",
                                    { staticClass: "box-card" },
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          ref: "baseInfoForm",
                                          staticClass: "baseInfoFormClass",
                                          staticStyle: {
                                            "padding-bottom": "30px",
                                          },
                                          attrs: {
                                            model: _vm.baseInfoForm,
                                            "label-width": "50px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "",
                                                "label-width": "0px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 4 } },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          width: "70px",
                                                          height: "70px",
                                                          "border-radius":
                                                            "70px",
                                                        },
                                                        attrs: {
                                                          src: _vm.baseInfoForm
                                                            .avatar,
                                                          alt: "",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                {
                                                  staticStyle: {
                                                    "text-align": "left",
                                                    "margin-left": "13px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "16px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.baseInfoForm
                                                              .nickname
                                                              ? _vm.baseInfoForm
                                                                  .nickname
                                                              : _vm.baseInfoForm
                                                                  .phone
                                                          ) +
                                                          "\n                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "等级" } },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "text-align": "left",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.baseInfoForm
                                                        .isAuthorityCorporate
                                                        ? "企业会员"
                                                        : "个人会员"
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "分组" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.baseInfoForm.groupName
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "标签" } },
                                            [
                                              _vm._l(
                                                _vm.baseInfoForm.userTagList,
                                                function (item, index) {
                                                  return _c(
                                                    "el-tag",
                                                    {
                                                      key: index,
                                                      staticStyle: {
                                                        "margin-left": "5px",
                                                      },
                                                      attrs: { closable: "" },
                                                      on: {
                                                        close: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleClose(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(item.name))]
                                                  )
                                                }
                                              ),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-circle-plus-outline",
                                                staticStyle: {
                                                  color: "#409eff",
                                                  "font-size": "18px",
                                                },
                                                on: { click: _vm.addTags },
                                              }),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "姓名" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.baseInfoForm.nickname
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "性别" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.baseInfoForm.sex == 1
                                                      ? "男"
                                                      : "女"
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "生日" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.baseInfoForm.birthday
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "地区" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.baseInfoForm.province
                                                        ? _vm.baseInfoForm
                                                            .province
                                                        : ""
                                                    ) +
                                                    "\n                        " +
                                                    _vm._s(
                                                      _vm.baseInfoForm.city
                                                        ? _vm.baseInfoForm.city
                                                        : ""
                                                    ) +
                                                    "\n                        " +
                                                    _vm._s(
                                                      _vm.baseInfoForm.district
                                                        ? _vm.baseInfoForm
                                                            .district
                                                        : ""
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "手机" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.baseInfoForm.phone)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "订单信息", name: "two" } },
                                [
                                  _c(
                                    "el-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.showDetail,
                                          expression: "!showDetail",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { display: "flex" } },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                placeholder:
                                                  "请输入订单号或者GII编号",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.orderInfo,
                                                callback: function ($$v) {
                                                  _vm.orderInfo = $$v
                                                },
                                                expression: "orderInfo",
                                              },
                                            },
                                            [
                                              _c("el-button", {
                                                attrs: {
                                                  slot: "append",
                                                  icon: "el-icon-search",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: _vm.searchOrderInfo,
                                                },
                                                slot: "append",
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.orderForm,
                                        function (item, index) {
                                          return _c(
                                            "el-card",
                                            {
                                              key: index,
                                              class:
                                                index ===
                                                _vm.orderForm.length - 1
                                                  ? "orderFormClass"
                                                  : "",
                                              staticStyle: {
                                                "margin-top": "20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.goOrderDetails(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "justify-content":
                                                          "space-between",
                                                      },
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c("span", [
                                                          _vm._v(
                                                            "订单号：" +
                                                              _vm._s(
                                                                item.orderId
                                                              )
                                                          ),
                                                        ]),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("div", [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.statusStr
                                                                .value
                                                            )
                                                          ),
                                                        ]),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("el-divider"),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-row",
                                                    {
                                                      staticClass: "rowClass",
                                                      staticStyle: {
                                                        "margin-top": "10px",
                                                        display: "flex",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            position:
                                                              "relative",
                                                          },
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticStyle: {
                                                              width: "50px",
                                                              height: "50px",
                                                              top: "50%",
                                                              "margin-top":
                                                                "-25px",
                                                            },
                                                            attrs: {
                                                              src: item
                                                                .productList[0]
                                                                .info
                                                                .productInfo
                                                                .image,
                                                              fit: "fill",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "5px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "5px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-tooltip",
                                                                {
                                                                  staticClass:
                                                                    "item",
                                                                  attrs: {
                                                                    effect:
                                                                      "dark",
                                                                    content:
                                                                      item
                                                                        .productList[0]
                                                                        .info
                                                                        .productInfo
                                                                        .storeName,
                                                                    placement:
                                                                      "top-start",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "14px !important",
                                                                          "text-align":
                                                                            "left",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            item
                                                                              .productList[0]
                                                                              .info
                                                                              .productInfo
                                                                              .storeName
                                                                              .length >
                                                                              13
                                                                              ? item.productList[0].info.productInfo.storeName.substring(
                                                                                  0,
                                                                                  13
                                                                                ) +
                                                                                  "....."
                                                                              : item
                                                                                  .productList[0]
                                                                                  .info
                                                                                  .productInfo
                                                                                  .storeName
                                                                          ) +
                                                                          "\n                            "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "5px",
                                                                "font-size":
                                                                  "10px",
                                                                color:
                                                                  "#9e9e9e",
                                                                "text-align":
                                                                  "left",
                                                                "line-height":
                                                                  "17px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    item
                                                                      .productList[0]
                                                                      .info
                                                                      .productInfo
                                                                      .attrInfo
                                                                      .suk
                                                                      .length >
                                                                      30
                                                                      ? item.productList[0].info.productInfo.attrInfo.suk.substring(
                                                                          0,
                                                                          30
                                                                        ) +
                                                                          "......"
                                                                      : item
                                                                          .productList[0]
                                                                          .info
                                                                          .productInfo
                                                                          .attrInfo
                                                                          .suk
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                color: "red",
                                                                "margin-top":
                                                                  "5px",
                                                                "font-size":
                                                                  "13px",
                                                                "text-align":
                                                                  "left",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    item
                                                                      .productList[0]
                                                                      .info
                                                                      .productInfo
                                                                      .attrInfo
                                                                      .price
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "thirdClass",
                                                          staticStyle: {
                                                            "margin-left": "0%",
                                                            top: "15px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        X" +
                                                              _vm._s(
                                                                item
                                                                  .productList[0]
                                                                  .info.cartNum
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "10px",
                                                        display: "flex",
                                                        "justify-content":
                                                          "space-between",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "line-height":
                                                              "22px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.createTime
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "right",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#409eff",
                                                              },
                                                            },
                                                            [_vm._v("支付")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#409eff",
                                                                "font-size":
                                                                  "18px",
                                                                "margin-left":
                                                                  "3px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.payPrice
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        {
                          staticClass: "smallTab",
                          attrs: { label: "快捷回复", name: "second" },
                        },
                        [
                          _c(
                            "el-row",
                            {
                              staticStyle: {
                                display: "flex",
                                "margin-bottom": "10px",
                                "margin-top": "10px",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: {
                                    placeholder: "输入关键词搜索",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.replyContent,
                                    callback: function ($$v) {
                                      _vm.replyContent = $$v
                                    },
                                    expression: "replyContent",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: { click: _vm.searchEnterFun },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-card",
                            { staticClass: "smallTab" },
                            [
                              _c("el-tree", {
                                attrs: {
                                  data: _vm.replyOptions,
                                  props: _vm.categoryProps,
                                  load: _vm.loadNode,
                                  lazy: "",
                                },
                                on: { "node-click": _vm.treeClick },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "结束会话", visible: _vm.manyiDialog, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.manyiDialog = $event
            },
          },
        },
        [
          _c("span", [_vm._v("确认发送满意度评价")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.manyiDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sendSatis } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发送商品详情页",
            visible: _vm.goodsDetailDialog,
            width: "50%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.goodsDetailDialog = $event
            },
          },
        },
        [
          _c(
            "el-input",
            {
              staticStyle: { width: "50%", "margin-bottom": "5px" },
              attrs: { placeholder: "", clearable: "" },
              model: {
                value: _vm.goodsDetailForm.keywords,
                callback: function ($$v) {
                  _vm.$set(_vm.goodsDetailForm, "keywords", $$v)
                },
                expression: "goodsDetailForm.keywords",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: { click: _vm.searchGoods },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.goodsLoading,
                  expression: "goodsLoading",
                },
              ],
              ref: "goodsDetailTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.goodsDetailTable,
                "tooltip-effect": "dark",
                "highlight-current-row": "",
              },
              on: { "current-change": _vm.goodsCurrentChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "ID",
                  width: "50",
                  prop: "id",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "商品图", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "storeName",
                  label: "商品名称",
                  width: "180",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "price", label: "商品售价", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sales", label: "销量", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "stock", label: "库存", align: "center" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.goodsDetailForm.limit,
              "current-page": _vm.goodsDetailForm.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.goodsPageTotal,
            },
            on: {
              "size-change": _vm.goodsSizeChange,
              "current-change": _vm.goodsPageChange,
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.goodsDetailDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.chooseGoodsList },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发送物流状态",
            visible: _vm.wuliuDialog,
            width: "75%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.wuliuDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c("span", [_vm._v("订单编号")]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "40%", "margin-bottom": "5px" },
                attrs: {
                  placeholder: "请输入订单号或者GII编号",
                  clearable: "",
                },
                model: {
                  value: _vm.wuliuForm.orderId,
                  callback: function ($$v) {
                    _vm.$set(_vm.wuliuForm, "orderId", $$v)
                  },
                  expression: "wuliuForm.orderId",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.searchWuliu },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.wuliuLoading,
                  expression: "wuliuLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.wuliuTableData,
                "tooltip-effect": "dark",
                "highlight-current-row": _vm.highlight,
                align: "center",
                "row-style": _vm.selectedstyle,
              },
              on: { "current-change": _vm.wuliuCurrentChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "orderCode",
                  label: "订单编号",
                  width: "250",
                  align: "center",
                  disabled: "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "price",
                  label: "商品名称",
                  align: "center",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticStyle: { "text-align": "center" } }, [
                          _vm._v(_vm._s(scope.row.info.productInfo.storeName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "giiCode",
                  label: "GII编码",
                  width: "150",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "deliveryName",
                  label: "快递公司",
                  align: "center",
                  width: "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "deliveryId",
                  label: "快递单号",
                  align: "center",
                  width: "200",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.wuliuForm.limit,
              "current-page": _vm.wuliuForm.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.wuliuPageTotal,
            },
            on: {
              "size-change": _vm.wuliuSizeChange,
              "current-change": _vm.wuliuPageChange,
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.wuliuDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.wuliuDisabled },
                  on: { click: _vm.sendWuliuMessage },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发送直播页面",
            visible: _vm.streamDialog,
            width: "80%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.streamDialog = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.streamLoading,
                  expression: "streamLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%", "font-size": "12px" },
              attrs: {
                data: _vm.streamTableData,
                "tooltip-effect": "dark",
                "highlight-current-row": "",
              },
              on: { "current-change": _vm.streamCurrentChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "直播间id",
                  width: "80",
                  prop: "roomid",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "直播间标题",
                  width: "120",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "cover_img", label: "背景图", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: { src: scope.row.cover_img },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "分享图片",
                  prop: "share_img",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: { src: scope.row.share_img },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "live_status",
                  label: "直播间状态",
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-tag", [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.live_status) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "start_time",
                  label: "开始时间",
                  width: "180",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "end_time",
                  label: "预计结束时间",
                  width: "180",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "anchor_name",
                  label: "主播昵称",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.streamDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sendStream } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发送种草文章",
            visible: _vm.articleDialog,
            width: "60%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.articleDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c("el-input", {
                staticStyle: { width: "40%", "margin-bottom": "5px" },
                attrs: { placeholder: "输入关键词搜索", clearable: "" },
                model: {
                  value: _vm.articleForm.keywords,
                  callback: function ($$v) {
                    _vm.$set(_vm.articleForm, "keywords", $$v)
                  },
                  expression: "articleForm.keywords",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.searchArticle },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.articleLoading,
                  expression: "articleLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%", "font-size": "12px" },
              attrs: {
                data: _vm.articleTableData,
                "tooltip-effect": "dark",
                "highlight-current-row": "",
              },
              on: { "current-change": _vm.articleCurrentChange },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图片", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.imageInput,
                                "preview-src-list": [scope.row.imageInput],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "title", label: "标题", "min-width": "180" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "visit", label: "浏览量", "min-width": "180" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "author", label: "作者", "min-width": "180" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "synopsis",
                  label: "种草简介",
                  "show-overflow-tooltip": "",
                  "min-width": "250",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "shareTitle",
                  label: "分享标题",
                  "show-overflow-tooltip": "",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "updateTime",
                  label: "更新时间",
                  "min-width": "180",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.articleForm.limit,
              "current-page": _vm.articleForm.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.articlePageTotal,
            },
            on: {
              "size-change": _vm.articleSizeChange,
              "current-change": _vm.articlePageChange,
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.articleDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sendArticle } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发送优惠券",
            visible: _vm.couponDialog,
            width: "60%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.couponDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c("el-input", {
                staticStyle: { width: "40%", "margin-bottom": "5px" },
                attrs: { placeholder: "输入关键词搜索", clearable: "" },
                model: {
                  value: _vm.counponForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.counponForm, "name", $$v)
                  },
                  expression: "counponForm.name",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.searchCoupon },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%", "font-size": "12px" },
              attrs: {
                data: _vm.couponTableData,
                "tooltip-effect": "dark",
                "highlight-current-row": "",
              },
              on: { "current-change": _vm.couponCurrentChange },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "名称", "min-width": "130" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "类型", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("couponUserTypeFilter")(row.useType))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "money", label: "面值", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "领取方式", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("couponTypeFilter")(row.type))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "180", label: "领取日期" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.receiveEndTime
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.receiveStartTime) +
                                  " - " +
                                  _vm._s(row.receiveEndTime) +
                                  "\n          "
                              ),
                            ])
                          : _c("span", [_vm._v("不限时")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "260",
                  label: "使用时间",
                  aling: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.day
                          ? _c("div", [_vm._v(_vm._s(row.day) + "天")])
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(row.useStartTime) +
                                  " - " +
                                  _vm._s(row.useEndTime) +
                                  " "
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "100", label: "发布数量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        !row.isLimited
                          ? _c("span", [_vm._v("不限量")])
                          : _c("div", [
                              _c("span", { staticClass: "fa" }, [
                                _vm._v("发布：" + _vm._s(row.total)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "sheng" }, [
                                _vm._v("剩余：" + _vm._s(row.lastTotal)),
                              ]),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.counponForm.limit,
              "current-page": _vm.counponForm.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.couponPageTotal,
            },
            on: {
              "size-change": _vm.couponSizeChange,
              "current-change": _vm.couponPageChange,
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.couponDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sendCoupon } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传图片",
            visible: _vm.pictureDialogVisiable,
            width: "896px",
            modal: false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.pictureDialogVisiable = $event
            },
          },
        },
        [
          _vm.pictureDialogVisiable
            ? _c("uploadPic", {
                attrs: { checkedMore: _vm.imageList, isMore: _vm.isMore },
                on: { getImage: _vm.getImage },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增标签",
            visible: _vm.tagDialogVisiable,
            width: "30%",
            modal: false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.tagDialogVisiable = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "tagForm",
                  attrs: { model: _vm.tagForm, rules: _vm.tagFormRules },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "tagInput" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入标签名称", clearable: "" },
                        model: {
                          value: _vm.tagForm.tagInput,
                          callback: function ($$v) {
                            _vm.$set(_vm.tagForm, "tagInput", $$v)
                          },
                          expression: "tagForm.tagInput",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.tagDialogVisiable = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sureAddTag } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "结束对话",
            visible: _vm.closeDialogVisiable,
            width: "30%",
            modal: false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.closeDialogVisiable = $event
            },
          },
        },
        [
          _c("span", [_vm._v(" 确认结束对话 ")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.closeDialogVisiable = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.closeTalkingClick },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "转接",
            visible: _vm.kefuDialogVisiable,
            width: "30%",
            modal: false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.kefuDialogVisiable = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
            _vm._v("只能选择一位客服转接"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            _vm._l(_vm.kefuList, function (item, index) {
              return _c(
                "el-radio-group",
                {
                  key: index,
                  on: { change: _vm.chooseKefuChange },
                  model: {
                    value: _vm.bindKefu,
                    callback: function ($$v) {
                      _vm.bindKefu = $$v
                    },
                    expression: "bindKefu",
                  },
                },
                [
                  _c(
                    "el-radio",
                    {
                      staticStyle: { "margin-left": "5px" },
                      attrs: { label: item.id },
                      model: {
                        value: item.id,
                        callback: function ($$v) {
                          _vm.$set(item, "id", $$v)
                        },
                        expression: "item.id",
                      },
                    },
                    [_vm._v(_vm._s(item.realName))]
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.kefuDialogVisiable = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sureTurnKufu } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("el-image", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showBigPic,
            expression: "showBigPic",
          },
        ],
        staticStyle: { width: "500px", height: "500px" },
        attrs: { src: _vm.bigPic, "preview-src-list": _vm.srcList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
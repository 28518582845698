var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-tabs",
                {
                  on: {
                    "tab-click": function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.loginType,
                    callback: function ($$v) {
                      _vm.loginType = $$v
                    },
                    expression: "loginType",
                  },
                },
                _vm._l(_vm.headeNum, function (item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: { label: item.name, name: item.type.toString() },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "userFrom",
                      attrs: {
                        inline: "",
                        size: "small",
                        model: _vm.userFrom,
                        "label-position": _vm.labelPosition,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 },
                            },
                            [
                              _c(
                                "el-col",
                                _vm._b({}, "el-col", _vm.grid, false),
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "用户搜索：" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "selWidth",
                                        attrs: {
                                          placeholder:
                                            "请输入用户昵称或手机号码搜索",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.userFrom.keywords,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.userFrom,
                                              "keywords",
                                              $$v
                                            )
                                          },
                                          expression: "userFrom.keywords",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.collapse
                            ? [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "年龄:" } },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 10 } },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    type: "number",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.userFrom.startYear,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.userFrom,
                                                        "startYear",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "userFrom.startYear",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 1, offset: 1 } },
                                              [_vm._v("-")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 10 } },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    type: "number",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value: _vm.userFrom.endYear,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.userFrom,
                                                        "endYear",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "userFrom.endYear",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "地区" } },
                                      [
                                        _c("el-cascader", {
                                          staticStyle: { width: "98%" },
                                          attrs: {
                                            options: _vm.cityList,
                                            props: _vm.propsObj,
                                            clearable: "",
                                          },
                                          on: { change: _vm.changeRegion },
                                          model: {
                                            value: _vm.city_ids,
                                            callback: function ($$v) {
                                              _vm.city_ids = $$v
                                            },
                                            expression: "city_ids",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "用户类型：" } },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            model: {
                                              value: _vm.userFrom.customerType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.userFrom,
                                                  "customerType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "userFrom.customerType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: "0" } },
                                              [_vm._v("全部")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: "2" } },
                                              [_vm._v("企业会员")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: "1" } },
                                              [_vm._v("个人会员")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: "3" } },
                                              [_vm._v("戴尔销售")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "企业认证：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder:
                                                  "请输入企业名称关键词",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.userFrom.corporationName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.userFrom,
                                                    "corporationName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "userFrom.corporationName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "用户身份：" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                model: {
                                                  value:
                                                    _vm.userFrom.identityType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "identityType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userFrom.identityType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.identityTypeList,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.name,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "消费次数：" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "selWidth",
                                                attrs: {
                                                  placeholder: "请选择",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.userFrom.payCount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "payCount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userFrom.payCount",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "",
                                                    label: "全部",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "-1",
                                                    label: "0",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "0",
                                                    label: "1+",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "1",
                                                    label: "2+",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "2",
                                                    label: "3+",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "3",
                                                    label: "4+",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "4",
                                                    label: "5+",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "timeBox",
                                            attrs: { label: "注册时间：" },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "selWidth",
                                              attrs: {
                                                align: "right",
                                                "unlink-panels": "",
                                                "value-format": "yyyy-MM-dd",
                                                format: "yyyy-MM-dd",
                                                size: "small",
                                                type: "daterange",
                                                placement: "bottom-end",
                                                placeholder: "自定义时间",
                                                "picker-options":
                                                  _vm.pickerOptions,
                                              },
                                              on: { change: _vm.onchangeTime },
                                              model: {
                                                value: _vm.timeVal,
                                                callback: function ($$v) {
                                                  _vm.timeVal = $$v
                                                },
                                                expression: "timeVal",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "text-right userFrom" },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      attrs: {
                                        type: "primary",
                                        icon: "ios-search",
                                        label: "default",
                                        size: "small",
                                      },
                                      on: { click: _vm.userSearchs },
                                    },
                                    [_vm._v("搜索")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ResetSearch mr10",
                                      attrs: { size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reset("userFrom")
                                        },
                                      },
                                    },
                                    [_vm._v("重置")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "ivu-ml-8",
                                      on: {
                                        click: function ($event) {
                                          _vm.collapse = !_vm.collapse
                                        },
                                      },
                                    },
                                    [
                                      !_vm.collapse
                                        ? [
                                            _vm._v(
                                              "\n                    展开高级筛选 "
                                            ),
                                            _c("i", {
                                              staticClass: "el-icon-arrow-down",
                                            }),
                                          ]
                                        : [
                                            _vm._v(
                                              "\n                    收起高级筛选 "
                                            ),
                                            _c("i", {
                                              staticClass: "el-icon-arrow-up",
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: { size: "small" },
                  on: { click: _vm.onSend },
                },
                [_vm._v("发送优惠券")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.setBatch("group")
                    },
                  },
                },
                [_vm._v("批量设置分组")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.setBatch("label")
                    },
                  },
                },
                [_vm._v("批量设置标签")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: { loading: _vm.exportLoading, size: "small" },
                  on: { click: _vm.exportUserExcel },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.resetDiaLog = true
                    },
                  },
                },
                [_vm._v("重置匹配关系")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.onSelectTab },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c("el-form-item", { attrs: { label: "身份：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterIsPromoter")(
                                      props.row.isPromoter
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "首次访问：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.createTime
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "近次访问：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.updateTime
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "身份证号：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.cardId)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "手机号：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.phone)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "真实姓名：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.realName)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "标签：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(props.row.tagName)
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "生日：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(props.row.birthday)
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "地址：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(props.row.addres)
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: {
                                  width: "100%",
                                  display: "flex",
                                  "margin-right": "10px",
                                },
                                attrs: { label: "备注：" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.mark)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "uid", label: "ID", "min-width": "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "uinonId", label: "uinonId", width: "220" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticClass: "image-avater",
                              attrs: {
                                src: scope.row.avatar,
                                "preview-src-list": [scope.row.avatar],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "昵称", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.nickname
                                ? scope.row.nickname
                                : "匿名用户"
                            ) + "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "手机号", "min-width": "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.phone) + " ")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户类型", "min-width": "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.isSales
                                ? "戴尔销售"
                                : scope.row.isAuthorityCorporate
                                ? "企业会员"
                                : "个人会员"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户身份", "min-width": "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.identityType == 0 ? "其他" : "园区"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "注册时间", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.createTime) + " "),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "userUtmSource",
                  prop: "userUtmSource",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "userUtmMedium",
                  prop: "userUtmMedium",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "userUtmCampaign",
                  prop: "userUtmCampaign",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "userUtmContent",
                  prop: "userUtmContent",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "userUtmTerm",
                  prop: "userUtmTerm",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "生日", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(scope.row.birthday) + " "),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "地区", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.province ? scope.row.province : ""
                            ) +
                            "\n          " +
                            _vm._s(scope.row.city ? scope.row.city : "") +
                            "\n          " +
                            _vm._s(
                              scope.row.district ? scope.row.district : ""
                            ) +
                            "\n          "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "邮箱", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(scope.row.email) + " "),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "企业认证", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "corporation-name",
                            on: {
                              click: function ($event) {
                                return _vm.handleCorporationInfo(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.corporationName)
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "企业认证时间",
                  prop: "AuthorityCorporateTime",
                  width: "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "utmSource", label: "utmSource", width: "220" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "utmMedium", label: "utmMedium", width: "220" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "utmCampaign",
                  label: "utmCampaign",
                  width: "220",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "utmContent",
                  label: "utmContent",
                  width: "220",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "utmTerm", label: "utmTerm", width: "220" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "130",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.editUser(scope.row.uid)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          { attrs: { trigger: "click" } },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v("\n              更多"),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.onDetails(scope.row.uid)
                                      },
                                    },
                                  },
                                  [_vm._v("账户详情")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.editPoint(scope.row.uid)
                                      },
                                    },
                                  },
                                  [_vm._v("设置积分")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.setBatch("group", scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("设置分组")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.setBatch("label", scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("设置标签")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [15, 30, 45, 60],
                  "page-size": _vm.userFrom.limit,
                  "current-page": _vm.userFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改推广人",
            visible: _vm.extensionVisible,
            width: "500px",
            "before-close": _vm.handleCloseExtension,
          },
          on: {
            "update:visible": function ($event) {
              _vm.extensionVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "formExtension",
              staticClass: "formExtension mt20",
              attrs: {
                model: _vm.formExtension,
                rules: _vm.ruleInline,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户头像：", prop: "image" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: { click: _vm.modalPicTap },
                    },
                    [
                      _vm.formExtension.image
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: { src: _vm.formExtension.image },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubExtension("formExtension")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户列表",
            visible: _vm.userVisible,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible = $event
            },
          },
        },
        [
          _vm.userVisible
            ? _c("user-list", { on: { getTemplateRow: _vm.getTemplateRow } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置",
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "dynamicValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.dynamicValidateForm, "label-width": "100px" },
            },
            [
              _vm.batchName === "group"
                ? _c(
                    "el-form-item",
                    {
                      key: "1",
                      attrs: {
                        prop: "groupId",
                        label: "用户分组",
                        rules: [
                          {
                            required: true,
                            message: "请选择用户分组",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80%" },
                          attrs: { placeholder: "请选择分组", filterable: "" },
                          model: {
                            value: _vm.dynamicValidateForm.groupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dynamicValidateForm, "groupId", $$v)
                            },
                            expression: "dynamicValidateForm.groupId",
                          },
                        },
                        _vm._l(_vm.groupList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.id, label: item.groupName },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "groupId",
                        label: "用户标签",
                        rules: [
                          {
                            required: true,
                            message: "请选择用户标签",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80%" },
                          attrs: {
                            placeholder: "请选择标签",
                            filterable: "",
                            multiple: "",
                          },
                          model: {
                            value: _vm.dynamicValidateForm.groupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dynamicValidateForm, "groupId", $$v)
                            },
                            expression: "dynamicValidateForm.groupId",
                          },
                        },
                        _vm._l(_vm.labelLists, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.id, label: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("dynamicValidateForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.visible, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _vm.visible
            ? _c("edit-from", {
                attrs: { uid: _vm.uid },
                on: { resetForm: _vm.resetForm },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "积分",
            visible: _vm.VisiblePoint,
            width: "500px",
            "close-on-click-modal": false,
            "before-close": _vm.handlePointClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.VisiblePoint = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingPoint,
                  expression: "loadingPoint",
                },
              ],
              ref: "PointValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.PointValidateForm, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "修改积分", required: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.PointValidateForm.integralType,
                        callback: function ($$v) {
                          _vm.$set(_vm.PointValidateForm, "integralType", $$v)
                        },
                        expression: "PointValidateForm.integralType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("增加")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("减少")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "积分", required: "" } },
                [
                  _c("el-input-number", {
                    attrs: { type: "text", "step-strictly": "", max: 999999 },
                    model: {
                      value: _vm.PointValidateForm.integralValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.PointValidateForm, "integralValue", $$v)
                      },
                      expression: "PointValidateForm.integralValue",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handlePointClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingBtn },
                  on: {
                    click: function ($event) {
                      return _vm.submitPointForm("PointValidateForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.uid
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "用户详情",
                visible: _vm.Visible,
                width: "1100px",
                "before-close": _vm.Close,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.Visible = $event
                },
              },
            },
            [
              _vm.Visible
                ? _c("user-details", {
                    ref: "userDetails",
                    attrs: { uid: _vm.uid },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "企业信息",
            visible: _vm.dialogCorporation,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogCorporation = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "row-line", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", [_vm._v("企业名：")]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(_vm._s(_vm.corporationItem.corporationName)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", [_vm._v("注册资本：")]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.corporationItem.regCapital))]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "row-line", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", [_vm._v("人员规模：")]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.corporationItem.staffNumRange))]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", [_vm._v("行业：")]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.corporationItem.industry))]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "row-line", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", [_vm._v("是否是小微企业：")]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.corporationItem.isMicroEnt == 0 ? "不是" : "是")
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", [_vm._v("企业类型：")]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(_vm._s(_vm.corporationItem.corporationType)),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "row-line", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", [_vm._v("统一社会信用代码：")]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.corporationItem.corporationTax))]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.resetDiaLog,
            width: "600px",
            "custom-class": "no-head-line",
          },
          on: {
            "update:visible": function ($event) {
              _vm.resetDiaLog = $event
            },
          },
        },
        [
          _c("div", [
            _vm._v(
              "是否确定重置匹配关系？一但重置，当前所有匹配关系都会根据CEP现有数据重置"
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.resetDiaLog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.resetLoading,
                    size: "small",
                  },
                  on: { click: _vm.submitReMatch },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
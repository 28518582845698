var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("div", { staticClass: "filter-container container" }, [
                _c(
                  "div",
                  { staticClass: "demo-input-suffix acea-row" },
                  [
                    _c(
                      "el-form",
                      { attrs: { inline: "", size: "small" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "状态：" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item selWidth mr20",
                                attrs: { placeholder: "请选择", clearable: "" },
                                on: { change: _vm.seachList },
                                model: {
                                  value: _vm.tableFrom.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tableFrom, "status", $$v)
                                  },
                                  expression: "tableFrom.status",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "未开启", value: 0 },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "开启", value: 1 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "适用身份：" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item selWidth mr20",
                                attrs: { placeholder: "请选择", clearable: "" },
                                on: { change: _vm.seachList },
                                model: {
                                  value: _vm.tableFrom.applyType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tableFrom, "applyType", $$v)
                                  },
                                  expression: "tableFrom.applyType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "企业身份", value: 1 },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "园区身份", value: 2 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "优惠券名称：" } },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "selWidth",
                                attrs: {
                                  placeholder: "请输入优惠券名称",
                                  clearable: "",
                                },
                                on: { change: _vm.seachList },
                                model: {
                                  value: _vm.tableFrom.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tableFrom, "name", $$v)
                                  },
                                  expression: "tableFrom.name",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticStyle: { height: "26px" },
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-search",
                                    size: "small",
                                  },
                                  on: { click: _vm.seachList },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { path: "/marketing/coupon/list/save" } } },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("添加优惠劵")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "mini" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "名称", "min-width": "180" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "类型", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("couponUserTypeFilter")(row.useType))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "money", label: "面值", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(row.money) +
                              _vm._s(row.discountType == 1 ? "" : "%")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "使用门槛", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(row.minPrice > 0 ? row.minPrice : "无门槛")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "150", label: "领取日期" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.receiveEndTime
                          ? _c(
                              "div",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.receiveStartTime) +
                                    " -"
                                ),
                                _c("br"),
                                _vm._v(
                                  " " +
                                    _vm._s(row.receiveEndTime) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c("span", [_vm._v("不限时")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "150", label: "使用时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.day
                          ? _c("div", [_vm._v(_vm._s(row.day) + "天")])
                          : _c("span", [
                              _vm._v(" " + _vm._s(row.useStartTime) + " -"),
                              _c("br"),
                              _vm._v(" " + _vm._s(row.useEndTime) + " "),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "领取方式", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("couponTypeFilter")(row.type))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "usetype",
                  label: "适用身份",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("couponApplyFilter")(row.applyType))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "storeGroupIdStr",
                  label: "用户分组",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "hasCode",
                  label: "是否券码",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("couponCodeFilter")(row.couponCodeType)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "100", label: "发布数量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        !row.isLimited
                          ? _c("span", [_vm._v("不限量")])
                          : _c("div", [
                              _c("span", { staticClass: "fa" }, [
                                _vm._v("发布：" + _vm._s(row.total)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "sheng" }, [
                                _vm._v("剩余：" + _vm._s(row.lastTotal)),
                              ]),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "是否开启", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": true,
                            "inactive-value": false,
                            "active-text": "开启",
                            "inactive-text": "关闭",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onchangeIsShow(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.status,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "190", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.receive(scope.row)
                              },
                            },
                          },
                          [_vm._v("领取记录")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.showUseInfo(scope.row)
                              },
                            },
                          },
                          [_vm._v("使用详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path:
                                  "/marketing/coupon/list/save/" + scope.row.id,
                              },
                            },
                          },
                          [
                            scope.row.status
                              ? _c(
                                  "el-button",
                                  { attrs: { type: "text", size: "small" } },
                                  [_vm._v("复制")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "领取记录",
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.Loading,
                  expression: "Loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.issueData.data },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "nickname",
                  label: "用户名",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户头像", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c(
                              "el-image",
                              {
                                staticStyle: { width: "36px", height: "36px" },
                                attrs: {
                                  src: scope.row.avatar,
                                  "preview-src-list": [scope.row.avatar],
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-picture-outline",
                                      staticStyle: { "font-size": "36px" },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "领取时间",
                  "min-width": "180",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.tableFromIssue.limit,
                  "current-page": _vm.tableFromIssue.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.issueData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeIssue,
                  "current-change": _vm.pageChangeIssue,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "使用详情",
            visible: _vm.useDialogVisible,
            width: "500px",
            "before-close": _vm.handleCloseUse,
          },
          on: {
            "update:visible": function ($event) {
              _vm.useDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "min-height": "100px", "white-space": "pre-wrap" },
            },
            [_vm._v(_vm._s(_vm.useInfoRow))]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox store-page" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.currentTab,
                    "align-center": "",
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", { attrs: { title: "表单样式" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "表单配置" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                model: _vm.formValidate,
                "label-width": "110px",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _vm.currentTab === 0
                ? _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "表单标题：",
                                prop: "formTitle",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入表单标题",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { "max-width": "500px" },
                                attrs: {
                                  maxlength: "7",
                                  placeholder: "请输入表单标题",
                                },
                                model: {
                                  value: _vm.formValidate.formTitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "formTitle", $$v)
                                  },
                                  expression: "formValidate.formTitle",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "分享图片：",
                                prop: "shareImage",
                                rules: [
                                  {
                                    required: true,
                                    message: "请上传分享图片",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap("1")
                                    },
                                  },
                                },
                                [
                                  _vm.formValidate.shareImage
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.formValidate.shareImage,
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#999" } },
                                    [
                                      _vm._v(
                                        "(推荐尺寸：400 X 320像素，200dpi)"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "表单上图：" } },
                            [
                              _c("MyModel", {
                                attrs: {
                                  modleList: _vm.formValidate.upperList,
                                  type: "UPPER",
                                  onchange: _vm.changeList1,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "表单下图：" } },
                            [
                              _c("MyModel", {
                                attrs: {
                                  modleList: _vm.formValidate.lowerList,
                                  type: "LOWER",
                                  onchange: _vm.changeList2,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否需要弹窗：",
                                rules: [{ required: true }],
                                "label-width": "130px",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.formValidate.isPopup,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "isPopup", $$v)
                                    },
                                    expression: "formValidate.isPopup",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("不需要"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("需要"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formValidate.isPopup
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-left": "120px",
                                      "margin-bottom": "20px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          "true-label": 1,
                                          "false-label": 0,
                                        },
                                        model: {
                                          value:
                                            _vm.formValidate.popupRequest.isPre,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate.popupRequest,
                                              "isPre",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formValidate.popupRequest.isPre",
                                        },
                                      },
                                      [_vm._v("前置弹窗")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      { attrs: { "label-width": "0" } },
                                      [
                                        _vm.formValidate.popupRequest.isPre == 1
                                          ? _c(
                                              "el-radio-group",
                                              {
                                                model: {
                                                  value:
                                                    _vm.formValidate
                                                      .popupRequest.preType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formValidate
                                                        .popupRequest,
                                                      "preType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formValidate.popupRequest.preType",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 0 } },
                                                  [_vm._v("二维码弹窗")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 1 } },
                                                  [_vm._v("按钮弹窗")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.formValidate.popupRequest.isPre == 1 &&
                                    _vm.formValidate.popupRequest.preType == 1
                                      ? _c("MyModel", {
                                          attrs: {
                                            modleList: _vm.prePathRequestList,
                                            type: "popUp",
                                            showAddBtn: false,
                                            bannerShow: _vm.preModelShow,
                                            onchange: _vm.changeList3,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.formValidate.popupRequest.isPre == 1 &&
                                    _vm.formValidate.popupRequest.preType == 0
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "图片：",
                                              prop: "image",
                                              "label-width": "70px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "upLoadPicBox",
                                                staticStyle: {
                                                  "margin-top": "20px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.modalPicTapImg(
                                                      "preImage"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm.formValidate.popupRequest
                                                  .preImage
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "pictrue",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: _vm
                                                              .formValidate
                                                              .popupRequest
                                                              .preImage,
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      { staticClass: "upLoad" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#999",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "(推荐尺寸：400 X 400像素，200dpi)"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-left": "120px",
                                      "margin-bottom": "20px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          "true-label": 1,
                                          "false-label": 0,
                                        },
                                        model: {
                                          value:
                                            _vm.formValidate.popupRequest
                                              .isSubmit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate.popupRequest,
                                              "isSubmit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formValidate.popupRequest.isSubmit",
                                        },
                                      },
                                      [_vm._v("后置弹窗")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      { attrs: { "label-width": "0" } },
                                      [
                                        _vm.formValidate.popupRequest
                                          .isSubmit == 1
                                          ? _c(
                                              "el-radio-group",
                                              {
                                                model: {
                                                  value:
                                                    _vm.formValidate
                                                      .popupRequest.subType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formValidate
                                                        .popupRequest,
                                                      "subType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formValidate.popupRequest.subType",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 0 } },
                                                  [_vm._v("二维码弹窗")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 1 } },
                                                  [_vm._v("按钮弹窗")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.formValidate.popupRequest.isSubmit ==
                                      1 &&
                                    _vm.formValidate.popupRequest.subType == 1
                                      ? _c("MyModel", {
                                          attrs: {
                                            modleList:
                                              _vm.submitPathRequestList,
                                            type: "popUp",
                                            showAddBtn: false,
                                            bannerShow: _vm.subModelShow,
                                            onchange: _vm.changeList4,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.formValidate.popupRequest.isSubmit ==
                                      1 &&
                                    _vm.formValidate.popupRequest.subType == 0
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "图片：",
                                              prop: "image",
                                              "label-width": "70px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "upLoadPicBox",
                                                staticStyle: {
                                                  "margin-top": "20px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.modalPicTapImg(
                                                      "submitImage"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm.formValidate.popupRequest
                                                  .submitImage
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "pictrue",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: _vm
                                                              .formValidate
                                                              .popupRequest
                                                              .submitImage,
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      { staticClass: "upLoad" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#999",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "(推荐尺寸：400 X 400像素，200dpi)"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "数据查看权限",
                                prop: "downloadPerson",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择权限",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    multiple: "",
                                    readonly: _vm.editView,
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.formValidate.downloadPerson,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "downloadPerson",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.downloadPerson",
                                  },
                                },
                                _vm._l(_vm.adminUserList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.realName,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentTab === 1
                ? _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "div",
                            { staticClass: "questionsList" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "提交按钮图片：",
                                    prop: "submitImage",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "upLoadPicBox",
                                      staticStyle: {
                                        display: "flex",
                                        width: "300px",
                                        "justify-content": "space-between",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalPicTap("2")
                                        },
                                      },
                                    },
                                    [
                                      _vm.formValidate.submitImage
                                        ? _c(
                                            "div",
                                            { staticClass: "pictrue" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.formValidate
                                                    .submitImage,
                                                },
                                              }),
                                            ]
                                          )
                                        : _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.deleteimage2("2")
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#999" } },
                                    [_vm._v("(推荐尺寸：宽为690px)")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.formValidate.questionsList,
                                function (question, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "question-item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "question-c" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label:
                                                  "问题" + (index + 1) + "：",
                                                prop:
                                                  "questionsList." +
                                                  index +
                                                  ".qtitle",
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: "请输入问题",
                                                    trigger: "blur",
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticStyle: {
                                                  "max-width": "500px",
                                                },
                                                attrs: {
                                                  readonly: _vm.editView,
                                                  maxlength: "5",
                                                  placeholder: "请输入问题",
                                                },
                                                model: {
                                                  value: question.qtitle,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      question,
                                                      "qtitle",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "question.qtitle",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "option-box" },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "0px",
                                                  },
                                                  attrs: {
                                                    label: "提示语：",
                                                    prop:
                                                      "questionsList." +
                                                      index +
                                                      ".tipsStr",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      "max-width": "300px",
                                                    },
                                                    attrs: {
                                                      readonly: _vm.editView,
                                                      maxlength: "8 ",
                                                      placeholder:
                                                        "请输入提示语",
                                                    },
                                                    model: {
                                                      value: question.tipsStr,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          question,
                                                          "tipsStr",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "question.tipsStr",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "0px",
                                                  },
                                                  attrs: {
                                                    label: "是否必填：",
                                                    prop:
                                                      "questionsList." +
                                                      index +
                                                      ".required",
                                                    rules: [{ required: true }],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      attrs: {
                                                        disabled: _vm.editView,
                                                      },
                                                      model: {
                                                        value:
                                                          question.required,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            question,
                                                            "required",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "question.required",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        { attrs: { label: 1 } },
                                                        [_vm._v("必填")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-radio",
                                                        { attrs: { label: 0 } },
                                                        [_vm._v("非必填")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "0px",
                                                  },
                                                  attrs: {
                                                    label: "题型：",
                                                    prop:
                                                      "questionsList." +
                                                      index +
                                                      ".type",
                                                    rules: [{ required: true }],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      attrs: {
                                                        disabled: _vm.editView,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeQuestionType(
                                                            index,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: question.type,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            question,
                                                            "type",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "question.type",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        { attrs: { label: 1 } },
                                                        [_vm._v("填空")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-radio",
                                                        { attrs: { label: 2 } },
                                                        [_vm._v("单选")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-radio",
                                                        { attrs: { label: 3 } },
                                                        [_vm._v("多选")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              question.type > 0
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      question.options,
                                                      function (
                                                        option,
                                                        oIndex
                                                      ) {
                                                        return _c(
                                                          "el-form-item",
                                                          {
                                                            key: oIndex,
                                                            attrs: {
                                                              label:
                                                                "选项" +
                                                                _vm.optionLabel[
                                                                  oIndex
                                                                ] +
                                                                "：",
                                                              "label-width":
                                                                "80px",
                                                              prop:
                                                                "questionsList." +
                                                                index +
                                                                ".options." +
                                                                oIndex +
                                                                ".title",
                                                              rules: [
                                                                {
                                                                  required: true,
                                                                  validator:
                                                                    _vm.validateOptions,
                                                                  trigger:
                                                                    "blur",
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "option-row",
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  attrs: {
                                                                    maxlength:
                                                                      "15",
                                                                    readonly:
                                                                      _vm.editView,
                                                                    placeholder:
                                                                      "请输入问题",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      option.title,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          option,
                                                                          "title",
                                                                          typeof $$v ===
                                                                            "string"
                                                                            ? $$v.trim()
                                                                            : $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "option.title",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                !_vm.editView
                                                                  ? _c("i", {
                                                                      staticClass:
                                                                        "el-icon-circle-plus-outline option-p",
                                                                      class: {
                                                                        disabled:
                                                                          question
                                                                            .options
                                                                            .length ==
                                                                          _vm
                                                                            .optionLabel
                                                                            .length,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.addOption(
                                                                              index,
                                                                              oIndex
                                                                            )
                                                                          },
                                                                      },
                                                                    })
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                !_vm.editView
                                                                  ? _c("i", {
                                                                      staticClass:
                                                                        "el-icon-remove-outline option-p",
                                                                      class: {
                                                                        disabled:
                                                                          question
                                                                            .options
                                                                            .length ==
                                                                          1,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.delOption(
                                                                              index,
                                                                              oIndex
                                                                            )
                                                                          },
                                                                      },
                                                                    })
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      !_vm.editView
                                        ? _c(
                                            "div",
                                            { staticClass: "question-p" },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    plain: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addQuestion(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("新增问题")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    default: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delQuestion(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除问题")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          !_vm.editView &&
                          _vm.formValidate.questionsList &&
                          _vm.formValidate.questionsList.length == 0
                            ? _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", plain: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addQuestion(-1)
                                        },
                                      },
                                    },
                                    [_vm._v("新增问题")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "special" }, [
                            _c("div", { staticClass: "specialTitle" }, [
                              _vm._v("特殊题型"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "specialItem" },
                              [
                                _c("el-form-item", {
                                  staticStyle: { "margin-bottom": "0px" },
                                  attrs: {
                                    label: "企业名称",
                                    rules: [{ required: true }],
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-switch", {
                                  attrs: {
                                    "active-text": "开启",
                                    "active-value": 1,
                                    "inactive-value": 0,
                                    "inactive-text": "关闭",
                                    disabled: _vm.editView,
                                  },
                                  model: {
                                    value:
                                      _vm.formValidate.companyNameQt.isOpen,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate.companyNameQt,
                                        "isOpen",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formValidate.companyNameQt.isOpen",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-radio-group",
                                  {
                                    staticStyle: { "margin-left": "80px" },
                                    attrs: { disabled: _vm.editView },
                                    model: {
                                      value:
                                        _vm.formValidate.companyNameQt.required,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate.companyNameQt,
                                          "required",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formValidate.companyNameQt.required",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("必填"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("非必填"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "specialItem" },
                              [
                                _c("el-form-item", {
                                  staticStyle: { "margin-bottom": "0px" },
                                  attrs: {
                                    label: "手机号",
                                    rules: [{ required: true }],
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-switch", {
                                  attrs: {
                                    "active-text": "开启",
                                    "active-value": 1,
                                    "inactive-value": 0,
                                    "inactive-text": "关闭",
                                    disabled: _vm.editView,
                                  },
                                  model: {
                                    value: _vm.formValidate.mobileQt.isOpen,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate.mobileQt,
                                        "isOpen",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.mobileQt.isOpen",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-radio-group",
                                  {
                                    staticStyle: { "margin-left": "80px" },
                                    attrs: { disabled: _vm.editView },
                                    model: {
                                      value: _vm.formValidate.mobileQt.required,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate.mobileQt,
                                          "required",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formValidate.mobileQt.required",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("必填"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("非必填"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "specialItem" },
                              [
                                _c("el-form-item", {
                                  staticStyle: { "margin-bottom": "0px" },
                                  attrs: {
                                    label: "邮箱",
                                    rules: [{ required: true }],
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-switch", {
                                  attrs: {
                                    "active-text": "开启",
                                    "active-value": 1,
                                    "inactive-value": 0,
                                    "inactive-text": "关闭",
                                    disabled: _vm.editView,
                                  },
                                  model: {
                                    value:
                                      _vm.formValidate.companyEmailQt.isOpen,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate.companyEmailQt,
                                        "isOpen",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formValidate.companyEmailQt.isOpen",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-radio-group",
                                  {
                                    staticStyle: { "margin-left": "80px" },
                                    attrs: { disabled: _vm.editView },
                                    model: {
                                      value:
                                        _vm.formValidate.companyEmailQt
                                          .required,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate.companyEmailQt,
                                          "required",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formValidate.companyEmailQt.required",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("必填"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("非必填"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        [
                          _vm.currentTab === 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.nextStep },
                                },
                                [_vm._v("下一步")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentTab === 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.submitLoading,
                                  },
                                  on: { click: _vm.submit },
                                },
                                [_vm._v("\n              提交")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    isShow: {
      type: Boolean,
      default: true
    },
    accessListObj: String
  },
  created: function created() {
    console.log(this.isShow, "isshow");
  }
};
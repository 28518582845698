"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddCase = AddCase;
exports.DelCase = DelCase;
exports.InfoCase = InfoCase;
exports.ListCase = ListCase;
exports.UpdateCase = UpdateCase;
exports.updateHide = updateHide;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 删除方案
 * @param id
 * @constructor
 */
function DelCase(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/solution/delete',
    method: 'delete',
    params: data
  });
}

/**
 * 方案详情
 * @param id
 * @constructor
 */
function InfoCase(id) {
  var data = {
    id: id
  };
  return (0, _request.default)({
    url: '/admin/solution/info',
    method: 'get',
    params: data
  });
}

/**
 * 解决方案列表
 * @param pram
 * @constructor
 */
function ListCase(pram) {
  var data = {
    keyword: pram.keywords,
    categoryId: pram.categoryId,
    page: pram.page,
    limit: pram.limit
  };
  return (0, _request.default)({
    url: '/admin/solution/list',
    method: 'GET',
    params: data
  });
}

/**
 * 新增方案
 * @param pram
 * @constructor
 */
function AddCase(data) {
  return (0, _request.default)({
    url: '/admin/solution/save',
    method: 'post',
    data: data
  });
}

/**
 * 更新方案
 * @param pram
 * @constructor
 */
function UpdateCase(data) {
  return (0, _request.default)({
    url: '/admin/solution/update',
    method: 'post',
    data: data
  });
}

/**
 * 上下架
 * @param id
 * @constructor
 */
function updateHide(params) {
  return (0, _request.default)({
    url: "/admin/solution/update/status",
    method: 'post',
    params: params
  });
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticClass: "btn" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.addParamPop },
                },
                [_vm._v("新增参数模板")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "参数模板名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "quota", label: "是否开启" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": true,
                            "inactive-value": false,
                            "active-text": "开启",
                            "inactive-text": "关闭",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeEnable(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.enable,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "enable", $$v)
                            },
                            expression: "scope.row.enable",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogFormVisible,
            width: "690px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "box-wrap" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleFormV",
                  staticClass: "dialog-from",
                  attrs: {
                    model: _vm.ruleForm,
                    "label-width": "70px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "模板名称：",
                        prop: "name",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: !_vm.isFlagAdd,
                          placeholder: "请输入名称，字符不超过10个",
                          maxlength: "10",
                        },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "scroll-height" },
                    _vm._l(_vm.ruleForm.paramList, function (domain, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "param-inner" },
                        [
                          _c("div", { staticClass: "title-1" }, [
                            _vm._v(
                              "\n              参数" +
                                _vm._s(index + 1) +
                                "\n              "
                            ),
                            _vm.isFlagAdd
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.ruleForm.paramList.length > 1,
                                        expression:
                                          "ruleForm.paramList.length > 1",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeDomain(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-delete" }),
                                    _vm._v(
                                      "\n                删除\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "名称：",
                                prop: "paramList." + index + ".name",
                                rules: {
                                  required: true,
                                  message: "名称不能为空",
                                  trigger: "blur",
                                },
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: !_vm.isFlagAdd,
                                  placeholder: "请输入参数定义。例：广告名称",
                                },
                                model: {
                                  value: domain.name,
                                  callback: function ($$v) {
                                    _vm.$set(domain, "name", $$v)
                                  },
                                  expression: "domain.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "维度：",
                                prop: "paramList." + index + ".dimension",
                                rules: {
                                  required: true,
                                  message: "维度不能为空",
                                  trigger: "blur",
                                },
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  domain.dimension = domain.dimension.replace(
                                    /[^\w\.\/]/gi,
                                    ""
                                  )
                                },
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: !_vm.isFlagAdd,
                                  placeholder: "例：utm_source",
                                },
                                model: {
                                  value: domain.dimension,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      domain,
                                      "dimension",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "domain.dimension",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "释义：",
                                prop: "paramList." + index + ".paraphrase",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: !_vm.isFlagAdd,
                                  placeholder:
                                    "例：广告投放的平台，如：sina、bai",
                                },
                                model: {
                                  value: domain.paraphrase,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      domain,
                                      "paraphrase",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "domain.paraphrase",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "选取值：",
                                prop: "paramList." + index + ".chooseType",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: domain.chooseType,
                                    callback: function ($$v) {
                                      _vm.$set(domain, "chooseType", $$v)
                                    },
                                    expression: "domain.chooseType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        label: 1,
                                        disabled: !_vm.isFlagAdd,
                                      },
                                    },
                                    [_vm._v("手动填写")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        label: 2,
                                        disabled: !_vm.isFlagAdd,
                                      },
                                    },
                                    [_vm._v("固定取值")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          domain.chooseType == 2
                            ? _c(
                                "div",
                                [
                                  _vm._l(
                                    domain.valueList,
                                    function (item, indexn) {
                                      return _c(
                                        "el-form-item",
                                        {
                                          key: indexn,
                                          staticClass: "value-box",
                                          attrs: {
                                            prop:
                                              "paramList." +
                                              index +
                                              ".valueList",
                                            rules: {
                                              required: true,
                                              message: "请输入",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: !_vm.isFlagAdd,
                                              placeholder: "请输入",
                                            },
                                            model: {
                                              value: item.key,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "key",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "item.key",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.isFlagAdd
                                            ? _c("span", [
                                                domain.valueList.length > 1
                                                  ? _c("i", {
                                                      staticClass:
                                                        "el-icon-delete delete-btn",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteValueList(
                                                            index,
                                                            indexn
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ])
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled: !_vm.isFlagAdd,
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addValueList(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-circle-plus-outline",
                                      }),
                                      _vm._v(
                                        "\n                新增取值\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.isFlagAdd
                    ? _c(
                        "div",
                        {
                          staticClass: "btn",
                          staticStyle: { "margin-top": "20px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.addParamList },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-circle-plus-outline",
                              }),
                              _vm._v("\n            新增一个参数\n          "),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel("ruleFormV")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSubmit("ruleFormV")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    index: {
      type: Number,
      default: false
    },
    articleContentList: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      articleContent: {
        content: "",
        articleProductList: []
      }
    };
  },
  watch: {
    articleContentList: function articleContentList(old) {
      console.log('old111111', old);
    }
  },
  methods: {
    deleteItem: function deleteItem() {
      this.$emit("removeItem", this.index);
    },
    handleRemove: function handleRemove(i) {
      this.articleContent.articleProductList.splice(i, 1);
    },
    changeGood: function changeGood() {
      var _this = this;
      this.$modalGoodList(function (row) {
        var obj = row.map(function (element) {
          return {
            productAttrUnique: '',
            productId: element.id,
            image: element.image
          };
        });
        _this.$emit('handleData', obj);
        //  _this.articleContent.articleProductList = obj;

        //  console.log("类型",_this.contentType)
      }, "many", _this.articleContent.articleProductList);
    }
  }
};
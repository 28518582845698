"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.commonMixin = void 0;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var commonMixin = exports.commonMixin = {
  data: function data() {
    return {
      /**
       * 连线参数
       */
      jsplumbConnectOptions: {
        isSource: true,
        isTarget: true,
        // 动态锚点、提供了4个方向 Continuous、AutoDefault
        anchor: "Continuous",
        // 设置连线上面的label样式
        labelStyle: {
          cssClass: "flowLabel"
        },
        // 修改了jsplumb 源码，支持label 为空传入自定义style
        emptyLabelStyle: {
          cssClass: "emptyFlowLabel"
        }
      },
      /**
       * 源点配置参数
       */
      jsplumbSourceOptions: {
        // 设置可以拖拽的类名，只要鼠标移动到该类名上的DOM，就可以拖拽连线
        filter: ".flow-node-drag",
        filterExclude: false,
        anchor: "Continuous",
        // 是否允许自己连接自己
        allowLoopback: false,
        maxConnections: -1,
        onMaxConnections: function onMaxConnections(info, e) {
          // console.log(`超过了最大值连线: ${info.maxConnections}`)
        }
      },
      jsplumbTargetOptions: {
        // 设置可以拖拽的类名，只要鼠标移动到该类名上的DOM，就可以拖拽连线
        filter: ".flow-node-drag",
        filterExclude: false,
        // 是否允许自己连接自己
        anchor: "Continuous",
        allowLoopback: false,
        dropOptions: {
          hoverClass: "ef-drop-hover"
        }
      }
    };
  },
  methods: {
    getUUID: function getUUID() {
      // 后端需要的ID需要以字母开头
      return "Flow".concat(Math.random().toString(36).substr(3, 10));
    },
    /**
     *  删除的是分支节点，把子节点一并删除掉
     *  删除对应的formSetting
     *  warn:如果删除的是分支节点，请先删除子分支节点后再删除父分支节点，防止断开连线后，找不多对应子分支节点
     * @param deleteBranchNode 是否删除当前节点相关连线，（直接通过删除按进行删除，删除对应连线，通过分支节点设置的删除，保留父分支节点与上一级的连线关系）
     */
    deleteBranchNode: function deleteBranchNode(nodeId) {
      var _this = this;
      var _deleteBranchNode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var childIdArr = this.getChildId(nodeId);
      this.data.nodeList = this.data.nodeList.filter(function (_ref) {
        var id = _ref.id;
        return !childIdArr.includes(id);
      });
      childIdArr.forEach(function (childId) {
        _this.jsPlumb.removeAllEndpoints(childId);
        _this.$delete(_this.formSetting, childId);
      });
      if (_deleteBranchNode) {
        this.jsPlumb.removeAllEndpoints(nodeId);
      }
      this.$delete(this.formSetting, nodeId);
    },
    // 当前节点下的所有子节点Id
    getChildId: function getChildId(parentId) {
      var lineList = this.data.lineList;
      var childIdArr = [];
      lineList.forEach(function (_ref2) {
        var from = _ref2.from,
          to = _ref2.to;
        if (from === parentId) {
          childIdArr.push(to);
        }
      });
      return childIdArr;
    },
    /**
    * 整个节点作为source或者target
    * 规则:
    * 开始节点只能作为输入节点，并且只能连接群组节点
    * 父分支节点只能有一个入口
    * 子分支节点不能连接子分支节点
    * 父分支节点只能作为target节点，无法直接连接其他任何节点，其子分支节点是通过操控JS实现连接。
    * 子分支节点只能接受当前分支节点输入，不能接受其它任何节点输入，其子分支节点是通过操控JS实现与父节点的连接。
    */
    makeSourceTarget: function makeSourceTarget(_ref3) {
      var id = _ref3.id,
        type = _ref3.type;
      var sourceConnectOneArr = ['start', "tenant", 'tenantChild', 'copyChild', 'userChild', 'eventChild', 'randomChild', 'propertyChild', 'timerChild'];
      var targetConnectOneArr = ['copy', 'user', 'event', 'random', 'timer', 'property'];
      if (sourceConnectOneArr.includes(type)) {
        this.jsPlumb.makeSource(id, _objectSpread(_objectSpread({}, this.jsplumbSourceOptions), {
          maxConnections: 1
        })); // 设置源点，可以拖出线连接其他节点
      } else if (targetConnectOneArr.includes(type)) {
        this.jsPlumb.makeTarget(id, _objectSpread(_objectSpread({}, this.jsplumbTargetOptions), {
          maxConnections: 1
        })); // 设置目标点，其他源点拖出的线可以连接该节点
      } else if (type === 'end') {
        this.jsPlumb.makeTarget(id, this.jsplumbTargetOptions);
      } else {
        this.jsPlumb.makeSource(id, this.jsplumbSourceOptions);
        this.jsPlumb.makeTarget(id, this.jsplumbTargetOptions);
      }
      this.jsPlumb.draggable(id, {
        containment: 'parent',
        stop: function stop(el) {
          // console.log("停止拖拽", el);
        }
      });
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.formModel.id ? "编辑分类" : "新增分类",
            visible: _vm.visible,
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "560px",
          },
          on: { close: _vm.cancle },
        },
        [
          _c(
            "el-form",
            {
              ref: "editPram",
              attrs: {
                model: _vm.formModel,
                "label-width": "130px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分类名称",
                    prop: "name",
                    rules: [
                      {
                        required: true,
                        message: "请输入",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "15", placeholder: "请输入分类名称" },
                    model: {
                      value: _vm.formModel.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModel, "name", $$v)
                      },
                      expression: "formModel.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input-number", {
                    attrs: { step: 1, min: 0 },
                    model: {
                      value: _vm.formModel.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModel, "sort", $$v)
                      },
                      expression: "formModel.sort",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancle } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.submit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
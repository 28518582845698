"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _图标 = _interopRequireDefault(require("@/assets/imgs/\u56FE\u6807.png"));
var _errImg = _interopRequireDefault(require("@/assets/errImg.png"));
var _emotion = _interopRequireDefault(require("../emotion/emotion.vue"));
var _dayjs = _interopRequireDefault(require("dayjs"));
var _workSpace = require("@/api/workSpace");
var _index = _interopRequireDefault(require("@/components/uploadPicture/index.vue"));
var _auth = require("@/utils/auth");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Emotion: _emotion.default,
    uploadPic: _index.default
  },
  data: function data() {
    return {
      query: '',
      beginTalking: true,
      //页面初始化进入
      isTalking: false,
      selectLi: -1,
      message: "第一次测试",
      from2: "",
      from1: 'https://dellapp.oss-cn-beijing.aliyuncs.com/ecomimage/maintain/2022/01/11/5d9c995e3fdb4c8fac3877e268a6cc8fqwee2ztlki.png',
      from: 1,
      from3: 'https://dellapp.oss-cn-beijing.aliyuncs.com/ecomimage/maintain/2022/01/11/b26d1c7ebfde4de6bd779a3c0ca7acd02an5wbe3bh.png',
      chatItems: [],
      list: [["微笑", "撇嘴", "色", "发呆", "得意", "流泪", "害羞", "闭嘴"], ["睡", "大哭", "尴尬", "发怒", "调皮", "呲牙", "惊讶", "难过"][("酷", "冷汗", "抓狂", "吐", "偷笑", "可爱", "白眼", "傲慢")], ["饥饿", "困", "惊恐", "流汗", "憨笑", "大兵", "奋斗", "咒骂"], ["疑问", "嘘", "晕", "折磨", "衰", "骷髅", "敲打", "再见"], ["擦汗", "抠鼻", "鼓掌", "糗大了", "坏笑", "左哼哼", "右哼哼", "哈欠"], ["鄙视", "委屈", "快哭了", "阴险", "亲亲", "吓", "可怜", "菜刀"], ["西瓜", "啤酒", "篮球", "乒乓", "咖啡", "饭", "猪头", "玫瑰"], ["凋谢", "示爱", "爱心", "心碎", "蛋糕", "闪电", "炸弹", "刀"], ["足球", "瓢虫", "便便", "月亮", "太阳", "礼物", "拥抱", "强"], ["弱", "握手", "胜利", "抱拳", "勾引", "拳头", "差劲", "爱你"], ["NO", "OK", "爱情", "飞吻", "跳跳", "发抖", "怄火", "转圈"], ["磕头", "回头", "跳绳", "挥手", "激动", "街舞", "左太极", "右太极"]],
      list1: ["微笑", "撇嘴", "色", "发呆", "得意", "流泪", "害羞", "闭嘴", "睡", "大哭", "尴尬", "发怒", "调皮", "呲牙", "惊讶", "难过", "酷", "冷汗", "抓狂", "吐", "偷笑", "可爱", "白眼", "傲慢", "饥饿", "困", "惊恐", "流汗", "憨笑", "大兵", "奋斗", "咒骂", "疑问", "嘘", "晕", "折磨", "衰", "骷髅", "敲打", "再见", "擦汗", "抠鼻", "鼓掌", "糗大了", "坏笑", "左哼哼", "右哼哼", "哈欠", "鄙视", "委屈", "快哭了", "阴险", "亲亲", "吓", "可怜", "菜刀", "西瓜", "啤酒", "篮球", "乒乓", "咖啡", "饭", "猪头", "玫瑰", "凋谢", "示爱", "爱心", "心碎", "蛋糕", "闪电", "炸弹", "刀", "足球", "瓢虫", "便便", "月亮", "太阳", "礼物", "拥抱", "强", "弱", "握手", "胜利", "抱拳", "勾引", "拳头", "差劲", "爱你", "NO", "OK", "爱情", "飞吻", "跳跳", "发抖", "怄火", "转圈", "磕头", "回头", "跳绳", "挥手", "激动", "街舞", "左太极", "右太极"],
      websocket: null,
      lastPer: "",
      insertWebsocket: null,
      //点击接入时建立websocket链接
      baseInfoForm: {},
      //基本资料表单
      orderForm: [],
      //订单信息表单
      orderPage: 1,
      orderUid: "",
      env: "",
      isMore: "",
      active: 1,
      showDetail: false,
      inputUrl: "",
      bigPic: "",
      showBigPic: false,
      srcList: [],
      endUserList: [],
      // 已完成列表
      isActive: false,
      isBegintalking: false,
      completeUser: {},
      arrHostioryList: [],
      pageNum: 1,
      insertSelectLi: false,
      account: this.$store.getters.name,
      completeSelectLi: -1,
      _this: this,
      currentPage3: 1,
      pageHisNum: 1,
      hisTotal: 0,
      hisPageSize: 0
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getHostoryList();
    // 监听滚动事件
    document.getElementById('bigDiv').addEventListener('scroll', this.handleScroll, true);
  },
  filters: {
    turnTime: function turnTime(val, vue) {
      // console.log(vue, this);
      if (vue.isToday(val)) {
        val = (0, _dayjs.default)(val).format("HH:mm");
      } else {
        val = (0, _dayjs.default)(val).format("YYYY-MM-DD HH:mm");
      }
      return val;
    },
    turnTime1: function turnTime1(val) {
      val = (0, _dayjs.default)(val).format("YYYY-MM-DD HH:mm");
      return val;
    },
    levelTurn: function levelTurn(e) {
      var options = [{
        name: '普通会员',
        value: 1
      }, {
        name: '银卡会员',
        value: 2
      }, {
        name: '金卡会员',
        value: 3
      }, {
        name: '钻卡会员',
        value: 4
      }];
      e = options.filter(function (val) {
        if (e == val.value) return val.name;
      });
      if (e && e.length !== 0) {
        return e[0].name;
      }
    }
  },
  methods: {
    searchList: function searchList() {
      this.getHostoryList();
    },
    resetQuery: function resetQuery() {
      this.query = '';
      this.getHostoryList();
    },
    getHostoryList: function getHostoryList() {
      var that = this;
      var params = {
        keywords: this.query,
        page: this.pageHisNum,
        limit: 8
      };
      (0, _workSpace.getCustomerUserList)(params).then(function (res) {
        console.log(res.list, 'rrrr');
        that.hisTotal = res.total;
        that.handleData(res.list);
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pageHisNum = val;
      this.getHostoryList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      console.log(val, 'vvv');
      this.pageHisNum = val;
      this.getHostoryList();
    },
    isToday: function isToday(str) {
      return new Date().getTime() - new Date(str).getTime() < 86400000;
    },
    handleScroll: function handleScroll() {
      var _this = this;
      var that = this;
      var sh = document.getElementById('bigDiv').scrollHeight; // 滚动条高度
      var st = document.getElementById('bigDiv').scrollTop; // 滚动条距离顶部的距离
      var ch = document.getElementById('bigDiv').clientHeight; // 滚动条外容器的高度
      if (st + ch >= sh) {
        that.pageNum += 1;
        if (that.completeTotalPage >= that.pageNum) {
          (0, _workSpace.getCompleteMessage)({
            page: that.pageNum,
            limit: 30,
            userid: that.completeUser.userid
          }).then(function (res) {
            res.list.map(function (item) {
              that.arrHostioryList.push(item);
            });
            that.handleChatItem(_this.arrHostioryList);
          });
        }
      }
    },
    handleData: function handleData(evt) {
      var that = this;
      try {
        // 已完成
        // if (JSON.parse(evt)) {
        console.log(evt, "JSON.parse(evt)");
        var endUserList = [];
        endUserList = evt;
        endUserList.map(function (item) {
          // item.
          if (!item.avatar) {
            item.avatar = _errImg.default;
          }
          console.log(item.createTime, 'item.createtime');
          if (item.createTime && typeof item.createTime === 'number') {
            item.createTime = (0, _dayjs.default)(item.createTime).format("MM-DD HH:mm");
          }
        });
        console.log(endUserList, 'endUserList');
        this.endUserList = endUserList;
        // }
      } catch (_unused) {}
    },
    leftPicClick: function leftPicClick(val) {
      console.log(val, "leftttttt");
      this.bigPic = val;
      this.srcList = [val];
      this.showBigPic = true;
    },
    insertComplete: function insertComplete(val, index) {
      var _this2 = this;
      this.selectLi = -1;
      console.log(index, 'gggg');
      // document.getElementById('#bigDiv').style
      this.completeSelectLi = index;
      console.log(this.completeSelectLi === index);
      this.chatItems = [];
      this.lastPer = val.userid;
      this.beginTalking = false;
      this.isTalking = false;
      // 控制样式
      this.isBegintalking = true;
      var that = this;
      this.completeUser = val;
      this.from2 = val.avatar;
      this.arrHostioryList = [];
      // this.getCardMessage();
      (0, _workSpace.getCompleteMessage)({
        page: 1,
        limit: 30,
        userid: val.userid
      }).then(function (res) {
        _this2.arrHostioryList = res.list;
        that.completeTotalPage = res.totalPage;
        that.handleChatItem(res.list);
      });
      this.$nextTick(function () {
        setTimeout(function () {
          var scrollDiv = document.getElementById("bigDiv");
          console.log(scrollDiv.scrollHeight, 'scrollDiv.scrollHeightscrollDiv.scrollHeight');
          scrollDiv.scrollTop = scrollDiv.scrollHeight;
        }, 1500);
      });
    },
    handleChatItem: function handleChatItem(val) {
      // console.log(val, 'vvvvv')
      var that = this;
      val.map(function (item) {
        if (!item.avatar) {
          item.avatar = _errImg.default;
        }
        // if (item.from !== 1) {
        if (item.content && item.content.msg === undefined) {
          item.content = JSON.parse(item.content);
        }
        // 文本消息
        // console.log(item.content, 'item.content.msg');

        if (item.content.msg && item.content.msgtype === "text") {
          if (item.content.msg.indexOf("/") !== -1) {
            var str = item.content.msg.substring(item.content.msg.indexOf("/"), 3);
            str = str.substring(1, 3);
            if (that.list1.indexOf(str) !== -1) {
              var index = that.list1.indexOf(str);
              item.content.msg = item.content.msg.replace("/" + str, "<img src= \"https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/".concat(index, ".gif\">"));
            }
            if (item.content.msg.indexOf('</a>') !== -1) {
              console.log(item.content.msg, 11111);
              var eles = bigDiv.getElementsByTagName("a");
              setTimeout(function () {
                for (var i = 0; i < eles.length; i++) {
                  eles[i].setAttribute("target", "_blank");
                  eles[i].setAttribute("id", "eleId");
                }
              }, 1000);
            }
          }
        }
        // 判断图片
        if (item.content.msg && item.content.msg.msgtype == "image") {
          var picurl = item.content.msg.picurl;
          item.content.msg = "<div  @click=\"leftPicClick(".concat(picurl, ")\"><img src=\"").concat(picurl, "\"  width=\"100%\" height=\"100%\" ></div>");
        }
        // 判断小程序卡片
        if (item.content.msg && item.content.msg.msgtype == "miniprogrampage") {
          item.content.msg = "Dell\u5C0F\u4F01\u4E1A\u5546\u57CE:</br><div style=\"height:20px;\">".concat(item.content.msg.title, "</div></br><div style=\"width:200px;height:168px\"><img src='").concat(item.content.msg.thumb_url, "' width=\"100%\" height=\"100%\" class=\"cardClass\" id=\"cardClass\"></div><el-divider></el-divider></br><img src=\"").concat(_图标.default, "\" width=\"15px\" height=\"15px\" style=\"margin-top:5px\"/><span style=\"margin-left:5px\">Dell\u5C0F\u4F01\u4E1A\u5546\u57CE</span>");
        }
        // 判断错误信息
        if (item.content.msgtype == "unknow") {
          if (JSON.parse(item.content.query).image) {
            item.content.msg = "<img src='".concat(JSON.parse(item.content.query).image.picurl, "' class=\"picClass\">");
          }
        }
        // 判断是否是客户发的图片
        // if (item.content.mediaid) {
        //   console.log(item.content,item.content.msg.image, 'item.content.msgitem.content.msg');
        //   console.log(JSON.parse(item.content.msg).image,33333);
        //   if (item.content.msg.image === undefined && JSON.parse(item.content.msg).image) {
        //     console.log(555555555555);
        //     let picurl = JSON.parse(item.content.msg).image.picurl;
        //     item.content.msg = `<div  id="kehuPic" ><img src="${picurl}"  width="100%" height="100%" ></div>`;
        //     // if (document.getElementById("kehuPic")) {
        //     // }
        //   }
        //   // else {
        //   //    let picurl = item.content.msg.image.picurl;
        //   //   item.content.msg = `<div  id="kehuPic" ><img src="${picurl}"  width="100%" height="100%" ></div>`;
        //   // }
        // }
        // }
      });
      this.chatItems = val;
      console.log(val, 'vvvv');
    }
  }
};
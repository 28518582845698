"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAddNotes = getAddNotes;
exports.stayInfoExport = stayInfoExport;
exports.stayInfoList = stayInfoList;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// list
function stayInfoList(data) {
  return (0, _request.default)({
    url: '/admin/stayInfo/list',
    method: 'post',
    data: data
  });
}

// 导出
function stayInfoExport(data) {
  return (0, _request.default)({
    url: 'admin/stayInfo/export',
    method: 'post',
    data: data
  });
}

// 添加备注
function getAddNotes(data) {
  return (0, _request.default)({
    url: 'admin/stayInfo/addNotes',
    method: 'post',
    data: data
  });
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 30 } },
            [
              _c(
                "el-col",
                _vm._b(
                  { staticClass: "left mb15 ml40" },
                  "el-col",
                  _vm.grid,
                  false
                ),
                [
                  _c("div", [
                    _c("img", {
                      staticClass: "top",
                      attrs: { src: require("@/assets/imgs/mobilehead.png") },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "bottom",
                      attrs: { src: require("@/assets/imgs/mobilefoot.png") },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticStyle: {
                        background: "#F4F5F9",
                        "min-height": "438px",
                        position: "absolute",
                        top: "63px",
                        width: "320px",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "textbot" },
                      [
                        _vm._l(_vm.list, function (item, indx) {
                          return _c(
                            "div",
                            {
                              key: indx,
                              staticClass: "li",
                              class: { active: item === _vm.formValidate },
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "add",
                                    on: {
                                      click: function ($event) {
                                        return _vm.add(item, indx)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-plus" }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "arrow" }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "tianjia" },
                                  _vm._l(item.sub_button, function (j, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "addadd menuBox",
                                        class: {
                                          active: j === _vm.formValidate,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.gettem(j, index, indx)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: j.name,
                                              placement: "top-start",
                                            },
                                          },
                                          [
                                            _c("el-button", [
                                              _vm._v(
                                                _vm._s(j.name || "二级菜单")
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "text menuBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.gettem(item, indx, null)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: item.name,
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _c("el-button", [
                                        _vm._v(_vm._s(item.name || "一级菜单")),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.list.length < 3,
                                expression: "list.length < 3",
                              },
                            ],
                            staticClass: "li",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "text",
                                on: { click: _vm.addtext },
                              },
                              [_c("i", { staticClass: "el-icon-plus" })]
                            ),
                          ]
                        ),
                      ],
                      2
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xl: 11, lg: 12, md: 22, sm: 22, xs: 22 } },
                [
                  _vm.checkedMenuId !== null
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "dividerTitle acea-row row-between row-bottom",
                            },
                            [
                              _c("span", { staticClass: "title" }, [
                                _vm._v("菜单信息"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "extra",
                                    size: "small",
                                    type: "danger",
                                  },
                                  on: { click: _vm.deltMenus },
                                  slot: "extra",
                                },
                                [_vm._v("删除")]
                              ),
                              _vm._v(" "),
                              _c("el-divider"),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "userAlert", attrs: { span: 24 } },
                            [
                              _c(
                                "div",
                                { staticClass: "box-card right" },
                                [
                                  _c("el-alert", {
                                    staticClass: "mb15",
                                    attrs: {
                                      title: "已添加子菜单，仅可设置菜单名称",
                                      type: "success",
                                      "show-icon": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-form",
                                    {
                                      ref: "formValidate",
                                      staticClass: "mt20",
                                      attrs: {
                                        model: _vm.formValidate,
                                        rules: _vm.ruleValidate,
                                        "label-width": "100px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "菜单名称",
                                            prop: "name",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "spwidth",
                                            attrs: {
                                              placeholder: "请填写菜单名称",
                                            },
                                            model: {
                                              value: _vm.formValidate.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formValidate,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "formValidate.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "规则状态",
                                            prop: "type",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "spwidth",
                                              attrs: {
                                                placeholder: "请选择规则状态",
                                              },
                                              model: {
                                                value: _vm.formValidate.type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formValidate,
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression: "formValidate.type",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-option",
                                                {
                                                  attrs: {
                                                    value: "click",
                                                    label: "关键字",
                                                  },
                                                },
                                                [_vm._v("关键字")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-option",
                                                {
                                                  attrs: {
                                                    value: "view",
                                                    label: "跳转网页",
                                                  },
                                                },
                                                [_vm._v("跳转网页")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-option",
                                                {
                                                  attrs: {
                                                    value: "miniprogram",
                                                    label: "小程序",
                                                  },
                                                },
                                                [_vm._v("小程序")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.formValidate.type === "click"
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "关键字",
                                                    prop: "key",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticClass: "spwidth",
                                                    attrs: {
                                                      placeholder:
                                                        "请填写关键字",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formValidate.key,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formValidate,
                                                          "key",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formValidate.key",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.formValidate.type === "miniprogram"
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "appid",
                                                    prop: "appid",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticClass: "spwidth",
                                                    attrs: {
                                                      placeholder:
                                                        "请填写appid",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formValidate.appid,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formValidate,
                                                          "appid",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formValidate.appid",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "备用网页",
                                                    prop: "pagepath",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticClass: "spwidth",
                                                    attrs: {
                                                      placeholder:
                                                        "请填写备用网页",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formValidate
                                                          .pagepath,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formValidate,
                                                          "pagepath",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formValidate.pagepath",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "小程序路径",
                                                    prop: "url",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticClass: "spwidth",
                                                    attrs: {
                                                      placeholder:
                                                        "请填写小程序路径",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formValidate.url,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formValidate,
                                                          "url",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formValidate.url",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.formValidate.type === "view"
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "跳转地址",
                                                    prop: "url",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticClass: "spwidth",
                                                    attrs: {
                                                      placeholder:
                                                        "请填写跳转地址",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formValidate.url,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formValidate,
                                                          "url",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formValidate.url",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isTrue
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                display: "block",
                                margin: "10px auto",
                              },
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submenus("formValidate")
                                },
                              },
                            },
                            [_vm._v("保存并发布")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
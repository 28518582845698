"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formConfig = _interopRequireDefault(require("@/views/maintain/formConfig"));
var systemGroupApi = _interopRequireWildcard(require("@/api/systemGroup"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "combinedDataEdit"
  components: {
    formConfigList: _formConfig.default
  },
  props: {
    isCreate: {
      type: Number,
      default: 0
    },
    editData: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      editPram: {
        formId: null,
        info: null,
        name: null,
        id: null
      },
      selectedFormConfigData: {},
      selectFormDialogConfig: {
        visible: false
      }
    };
  },
  mounted: function mounted() {
    this.handlerInitEditData();
  },
  methods: {
    handlerInitEditData: function handlerInitEditData() {
      if (this.isCreate !== 1) return;
      var _this$editData = this.editData,
        id = _this$editData.id,
        name = _this$editData.name,
        info = _this$editData.info,
        formId = _this$editData.formId,
        createTime = _this$editData.createTime,
        updateTime = _this$editData.updateTime;
      this.editPram.id = id;
      this.editPram.name = name;
      this.editPram.info = info;
      this.editPram.formId = formId;
    },
    handlerSelectedRowData: function handlerSelectedRowData(rowData) {
      this.selectedFormConfigData = rowData;
      this.editPram.formId = this.selectedFormConfigData.id;
      this.selectFormDialogConfig.visible = false;
    },
    handlerSubmit: function handlerSubmit(form) {
      var _this = this;
      this.$refs[form].validate(function (result) {
        if (!result) return;
        _this.isCreate === 0 ? _this.handlerSave(_this.editPram) : _this.handlerEdit(_this.editPram);
      });
    },
    handlerSave: function handlerSave(pram) {
      var _this2 = this;
      systemGroupApi.groupSave(pram).then(function (data) {
        _this2.$message.success('添加组合数据成功');
        _this2.$emit('hideDialog');
      });
    },
    handlerEdit: function handlerEdit(pram) {
      var _this3 = this;
      systemGroupApi.groupEdit(pram).then(function (data) {
        _this3.$message.success('编辑组合数据成功');
        _this3.$emit('hideDialog');
      });
    }
  }
};
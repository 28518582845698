"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _creatGrade = _interopRequireDefault(require("./creatGrade"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Grade',
  filters: {
    typeFilter: function typeFilter(status) {
      var statusMap = {
        'wechat': '微信用户',
        'routine': '小程序你用户',
        'h5': 'H5用户'
      };
      return statusMap[status];
    }
  },
  components: {
    creatGrade: _creatGrade.default
  },
  data: function data() {
    return {
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        isShow: '',
        name: '',
        page: 1,
        limit: 20,
        isDel: 0
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    add: function add() {
      this.$refs.grades.dialogVisible = true;
    },
    edit: function edit(id) {
      this.$refs.grades.dialogVisible = true;
      this.$refs.grades.info(id);
    },
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _user.levelListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this2 = this;
      this.$modalSure('删除吗？所有用户已经关联的数据都会清除').then(function () {
        (0, _user.levelDeleteApi)({
          id: id
        }).then(function () {
          _this2.$message.success('删除成功');
          _this2.tableData.data.splice(idx, 1);
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this3 = this;
      (0, _user.levelUseApi)({
        id: row.id,
        value: row.isShow
      }).then(function () {
        _this3.$message.success('修改成功');
        _this3.getList();
      }).catch(function () {
        row.isShow = !row.isShow;
      });
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adminAdd = adminAdd;
exports.adminDel = adminDel;
exports.adminInfo = adminInfo;
exports.adminList = adminList;
exports.adminUpdate = adminUpdate;
exports.getMenu = getMenu;
exports.updateIsSmsApi = updateIsSmsApi;
exports.updateStatusApi = updateStatusApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getMenu() {
  return (0, _request.default)({
    url: '/admin/system/role/testMenu',
    method: 'GET'
  });
}
function adminDel(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/system/admin/delete',
    method: 'GET',
    params: data
  });
}
function adminInfo(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/system/admin/info',
    method: 'GET',
    params: data
  });
}
function adminList(params) {
  return (0, _request.default)({
    url: '/admin/system/admin/list',
    method: 'GET',
    params: params
  });
}
function adminAdd(pram) {
  var data = {
    account: pram.account,
    level: pram.level,
    pwd: pram.pwd,
    phone: pram.phone,
    realName: pram.realName,
    roles: pram.roles.join(','),
    status: pram.status
  };
  return (0, _request.default)({
    url: '/admin/system/admin/save',
    method: 'POST',
    params: data
  });
}
function adminUpdate(pram) {
  // const data = {
  //   account: pram.account,
  //   level: pram.level,
  //   pwd: pram.pwd,
  //   realName: pram.realName,
  //   roles: pram.roles.join(','),
  //   status: pram.status,
  //   id: pram.id,
  //   isDel: pram.isDel
  // }
  return (0, _request.default)({
    url: '/admin/system/admin/update',
    method: 'POST',
    params: pram
  });
}

/**
 * 修改状态
 * @param pram
 */
function updateStatusApi(params) {
  return (0, _request.default)({
    url: "/admin/system/admin/updateStatus",
    method: 'get',
    params: params
  });
}

/**
 * 修改后台管理员是否接收状态
 * @param pram
 */
function updateIsSmsApi(params) {
  return (0, _request.default)({
    url: "/admin/system/admin/update/isSms",
    method: 'get',
    params: params
  });
}
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var enterpriseApi = _interopRequireWildcard(require("@/api/enterprise.js"));
var _channel = require("@/api/channel");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "EnterpriseServeAdd",
  data: function data() {
    return {
      currentTab: 0,
      formValidate: {
        catagoryId: "",
        title: "",
        tags: "",
        priceType: 1,
        price: 0,
        chinesePrice: "",
        image: "",
        formTitle: "",
        formImage: "",
        questions: [],
        downloadPerson: []
      },
      questionItem: {
        qtitle: "",
        required: 1,
        //0否1是
        type: 1,
        // 1填空 2单选 3多选
        options: [] // 选择题选项
      },
      categoryList: [],
      fullscreenLoading: false,
      tempRoute: {},
      optionLabel: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "G"],
      submitLoading: false,
      formTypeDesable: false,
      adminUserList: []
    };
  },
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
  },
  mounted: function mounted() {
    this.getServeType();
    this.getadminUserList();
    if (this.$route.params.id) {
      this.setTagsViewTitle();
      this.getInfo();
    }
  },
  computed: {
    editView: function editView() {
      return !!this.$route.params.id ? true : false;
    },
    formTypeFlag: function formTypeFlag() {
      var flag = this.formTypeDesable;
      return flag = this.tempRoute.query.type == 3 ? false : true;
    }
  },
  // 刷新校验规则
  watch: {
    "tempRoute.query.type": {
      deep: true,
      handler: function handler(val) {
        var _this2 = this;
        if (val == 3) {
          this.$nextTick(function () {
            _this2.$refs.formValidate.clearValidate();
          });
        }
      }
    }
  },
  methods: {
    getadminUserList: function getadminUserList() {
      var _this3 = this;
      (0, _channel.getAdminListForSelectApi)().then(function (res) {
        res.forEach(function (item) {
          item.id = item.id + "";
        });
        _this3.adminUserList = res;
      });
    },
    validateOptions: function validateOptions(rule, value, callback) {
      if (!value) {
        callback(new Error("请输入选项内容"));
      } else {
        var qIndex = rule.field.split(".")[1];
        var exitsOption = this.formValidate.questions[qIndex].options.filter(function (item) {
          return item.title == value;
        });
        if (exitsOption.length > 1) {
          callback(new Error("存在重复的选项内容"));
        } else {
          callback();
        }
      }
    },
    nextStep: function nextStep() {
      var _this4 = this;
      this.$refs["formValidate"].validate(function (valid) {
        if (valid) {
          _this4.currentTab = 1;
        }
      });
    },
    submit: function submit() {
      var _this5 = this;
      this.$refs["formValidate"].validate(function (valid) {
        if (valid) {
          _this5.submitLoading = true;
          var params = JSON.parse(JSON.stringify(_this5.formValidate));
          //
          params.area = _this5.$route.query.type;
          params.downloadPerson = params.downloadPerson ? params.downloadPerson.toString() : "";
          if (_this5.formValidate.priceType == 2) {
            params.price = _this5.formValidate.chinesePrice;
          } else {
            params.price = params.price.toString();
          }
          params.questions.forEach(function (questionItem) {
            questionItem.options = questionItem.options.map(function (option) {
              return option.title;
            });
          });
          //
          var api = _this5.formValidate.id ? enterpriseApi.UpdateEnterpriseService : enterpriseApi.AddEnterpriseService;
          api(params).then(function (data) {
            _this5.submitLoading = false;
            _this5.$message("操作成功");
            _this5.$router.push({
              path: "/enterprise/serve",
              query: {
                type: _this5.$route.query.type
              }
            });
          }).catch(function () {
            _this5.submitLoading = false;
          });
        }
      });
    },
    getInfo: function getInfo() {
      var _this6 = this;
      enterpriseApi.InfoEnterpriseService({
        id: this.$route.params.id
      }).then(function (data) {
        var formModel = Object.assign({}, data);
        if (formModel.downloadPerson) {
          formModel.downloadPerson = formModel.downloadPerson.split(",");
        }
        if (formModel.priceType == 2) {
          formModel.chinesePrice = data.price;
        } else {
          formModel.price = Number(data.price);
        }
        formModel.questions.forEach(function (questionItem) {
          questionItem.options = questionItem.options.map(function (option) {
            return {
              title: option
            };
          });
        });
        _this6.formValidate = formModel;
      });
    },
    getServeType: function getServeType() {
      var _this7 = this;
      enterpriseApi.ListEnterpriseType({
        area: this.$route.query.type,
        page: 1,
        limit: 100
      }).then(function (data) {
        _this7.categoryList = data.list || [];
      });
    },
    changeQuestionType: function changeQuestionType(index, val) {
      if (val > 1) {
        this.formValidate.questions[index].options = [{
          title: ""
        }];
      } else {
        this.formValidate.questions[index].options = [];
      }
    },
    addQuestion: function addQuestion(index) {
      if (index > -1) {
        this.formValidate.questions.splice(index + 1, 0, JSON.parse(JSON.stringify(this.questionItem)));
      } else {
        this.formValidate.questions.push(JSON.parse(JSON.stringify(this.questionItem)));
      }
    },
    delQuestion: function delQuestion(index) {
      var _this8 = this;
      this.$confirm("确定删除当前问题", "提示").then(function () {
        _this8.formValidate.questions.splice(index, 1);
      });
    },
    addOption: function addOption(index, oIndex) {
      this.formValidate.questions[index].options.splice(oIndex + 1, 0, {
        title: ""
      });
    },
    delOption: function delOption(index, oIndex) {
      var _this9 = this;
      this.$confirm("确定删除当前选项", "提示").then(function () {
        _this9.formValidate.questions[index].options.splice(oIndex, 1);
      });
    },
    setTagsViewTitle: function setTagsViewTitle() {
      var title = "编辑企业服务配置";
      var route = Object.assign({}, this.tempRoute, {
        title: "".concat(title, "-").concat(this.$route.params.id)
      });
      this.$store.dispatch("tagsView/updateVisitedView", route);
    },
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == "1") {
          _this.formValidate.image = img[0].sattDir;
          _this.$refs["formValidate"].validateField("image");
        } else if (tit == "2") {
          _this.formValidate.formImage = img[0].sattDir;
          _this.$refs["formValidate"].validateField("formImage");
        }
      }, tit, "content");
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "divBox" }, [
    _c(
      "div",
      { staticClass: "components-container" },
      [
        _c(
          "el-form",
          { ref: "pram", attrs: { "label-width": "150px", model: _vm.pram } },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "标题",
                  prop: "solutionName",
                  rules: [
                    {
                      required: true,
                      message: "请填写标题",
                      trigger: ["blur", "change"],
                    },
                  ],
                },
              },
              [
                _c("el-input", {
                  attrs: { placeholder: "标题", maxlength: "100" },
                  model: {
                    value: _vm.pram.solutionName,
                    callback: function ($$v) {
                      _vm.$set(_vm.pram, "solutionName", $$v)
                    },
                    expression: "pram.solutionName",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "预览图片",
                  prop: "sliderImages",
                  rules: [
                    {
                      required: true,
                      type: "array",
                      min: "1",
                      max: "4",
                      message: "请上传预览图片",
                      trigger: "change",
                    },
                  ],
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "acea-row" },
                  [
                    _vm._l(_vm.pram.sliderImages, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "pictrue",
                          attrs: { draggable: "true" },
                          on: {
                            dragstart: function ($event) {
                              return _vm.handleDragStart($event, item)
                            },
                            dragover: function ($event) {
                              $event.preventDefault()
                              return _vm.handleDragOver($event, item)
                            },
                            dragenter: function ($event) {
                              return _vm.handleDragEnter($event, item)
                            },
                            dragend: function ($event) {
                              return _vm.handleDragEnd($event, item)
                            },
                          },
                        },
                        [
                          _c("img", { attrs: { src: item } }),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "el-icon-error btndel",
                            on: {
                              click: function ($event) {
                                return _vm.handleRemove(index)
                              },
                            },
                          }),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm.pram.sliderImages.length < 4
                      ? _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("2")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "upLoad" }, [
                              _c("i", {
                                staticClass: "el-icon-camera cameraIconfont",
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "brief", staticStyle: { color: "#999" } },
                  [_vm._v("(请上传解决方案图片，宽度为682px)")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "全量pdf",
                  prop: "pdfUrl",
                  rules: [
                    {
                      required: true,
                      message: "请上传全量pdf",
                      trigger: "change",
                    },
                  ],
                },
              },
              [
                _c(
                  "div",
                  {},
                  [
                    !_vm.infoLoading
                      ? _c("upload-file", {
                          attrs: { value: _vm.pram.pdfUrl, fileType: "pdf" },
                          on: {
                            input: function ($event) {
                              return _vm.getVideo($event, "pdfUrl")
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "#999" } }, [
                      _vm._v("(格式:pdf)"),
                    ]),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function ($event) {
                        return _vm.handerSubmit("pram")
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
                _vm._v(" "),
                _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var moudleType = [{
  label: "新客首单福利",
  value: 1,
  sampleImage: "https://dellsb.oss-cn-beijing.aliyuncs.com/ecomimage/maintain/2022/08/29/a2da738a92ec4305804a903d078360c6k3wk7rur3z.png",
  imgTip: "(请上传图片,尺寸为520px X 312px，最多可上传8张)",
  rowLink: false,
  //图片是否设置路径
  imgMax: 8
}, {
  label: "优惠券",
  value: 2,
  sampleImage: "https://dellsb.oss-cn-beijing.aliyuncs.com/ecomimage/maintain/2022/08/29/9990181b9fa04f81be9fd2082002b0fdwaoxtgcdn4.png",
  imgTip: "(请上传图片,尺寸为682px X 170px)",
  rowLink: false,
  imgMax: 0
}, {
  label: "快捷入口",
  value: 3,
  sampleImage: "https://dellsbprod.oss-cn-beijing.aliyuncs.com/ecomimage/maintain/2024/05/17/24f1f2803b9844acbfd5939536373852hl6ko3ab79.jpg",
  imgTip: "(请上传图片,尺寸为160px X 208px，图片为必填、链接为必填)",
  rowLink: true,
  styleList: [{
    label: "一行三个",
    value: 1,
    imgTip: "(请上传图片,尺寸为160px X 208px，图片为必填、链接为必填)",
    imgMax: 6,
    rowLen: 3
  }, {
    label: "一行四个",
    value: 2,
    imgTip: "(请上传图片,尺寸为148px X 160px，图片为必填、链接为必填)",
    imgMax: 8,
    rowLen: 4
  }]
}, {
  label: "内容展示",
  value: 4,
  sampleImage: "https://dellsbprod.oss-cn-beijing.aliyuncs.com/ecomimage/maintain/2024/05/17/2f3eb0eb2b2a4abb9c4e75d8360bd218mgz5ppw9o9.jpg",
  imgTip: "(请上传图片,尺寸为280px X 440px，最多配置6个，图片为必填、链接为非必填)",
  rowLink: true,
  styleList: [{
    label: "模板样式1",
    value: 3,
    imgTip: "(请上传图片,尺寸为270px X 240px，最多配置6个，图片为必填、链接为非必填)",
    imgMax: 6
  }, {
    label: "模板样式2",
    value: 4,
    imgTip: "(请上传图片,尺寸为280px X 440px，最多配置6个，图片为必填、链接为非必填)",
    imgMax: 6
  }, {
    label: "模板样式3",
    value: 5,
    imgTip: "(请上传图片,尺寸为206px X 480px，最多配置6个，图片为必填、链接为非必填)",
    imgMax: 6
  }]
}, {
  label: "活动跳转",
  value: 5,
  sampleImage: "https://dellsb.oss-cn-beijing.aliyuncs.com/ecomimage/maintain/2022/08/29/7c56b60f87844e58858357a969ba8e1awwnzkhxjpp.png",
  imgTip: "(请上传图片,尺寸为206px X 750px，图片为必填、链接为非必填)",
  rowLink: true,
  styleList: [{
    label: "一行一个",
    value: 6,
    imgTip: "(请上传图片,尺寸为206px X 750px，图片为必填、链接为非必填)",
    imgMax: 1
  }, {
    label: "一行两个",
    value: 7,
    imgTip: "(请上传图片,尺寸为206px X 326px，图片必填两张、链接为非必填)",
    imgMax: 2
  }]
}, {
  label: "滚动公告",
  value: 6,
  sampleImage: "",
  imgTip: "",
  rowLink: true,
  imgMax: 10
}];
var _default = exports.default = moudleType;
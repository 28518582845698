"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/contentGoods/index"));
var _index2 = _interopRequireDefault(require("@/components/Tinymce/index"));
var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));
var articleApi = _interopRequireWildcard(require("@/api/article.js"));
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
var _systemSetting = require("@/api/systemSetting");
var _auth = require("@/utils/auth");
var _pageLink = _interopRequireDefault(require("@/views/beautify/landing/pageLink.vue"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 导入组件
var _default = exports.default = {
  // name: "edit",
  components: {
    Tinymce: _index2.default,
    contentGoods: _index.default,
    PageLink: _pageLink.default
  },
  data: function data() {
    return {
      loading: false,
      constants: this.$constants,
      categoryTreeData: [],
      categoryProps: {
        value: "id",
        label: "name",
        children: "child",
        expandTrigger: "hover",
        checkStrictly: true,
        emitPath: false
      },
      pram: {
        articleType: null,
        author: null,
        cidList: [],
        content: null,
        imageInput: "",
        bannerImage: "",
        isBanner: false,
        isHot: null,
        isRecommend: false,
        shareSynopsis: null,
        shareTitle: null,
        sort: 0,
        title: null,
        url: null,
        id: null,
        label: "",
        articleContentList: [],
        articleRecommendList: [],
        subtitle: "",
        //副标题
        isEnterpriseService: false,
        //企业服务
        coverImage: "",
        // 封面配置
        isParkFirst: false,
        // 是否园区首页
        parkCoverImage: "",
        //园区首页封面图
        parkTag: "",
        //园区相关属性，标签配置
        clLabel: "",
        stayTitle: "",
        stayHeadImage: "",
        describeStr: "" //描述
        // mediaId: null
      },
      editData: {},
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      },
      items: [{
        nowTime: new Date().getTime()
      }],
      articleContent: [{
        content: "",
        articleProductList: [],
        sceneList: [],
        firmModel: null,
        phoneList: [],
        phoneTemModel: null
      }],
      srcList: ["https://dellapp.oss-cn-beijing.aliyuncs.com/ecomimage/maintain/2022/01/06/e92b12cda5904caa9c378487b112a425iv9txtvcvz.jpg"],
      phoneTemModel: {},
      phoneModel: {
        phone: ""
      },
      firmModel: {},
      rowModel: {
        appId: "",
        jumpPath: "",
        jumpStatus: false,
        jumpType: 0,
        pic: "",
        picName: ""
      },
      pageLinkShow: false,
      pageLinkData: {}
    };
  },
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
  },
  mounted: function mounted() {
    if (this.$route.params.id) {
      this.getInfo();
      this.setTagsViewTitle();
    } else {
      this.pram.articleType = 0;
    }
    this.handlerGetCategoryTreeData();
  },
  methods: {
    //新增电话
    submitPhoneRow: function submitPhoneRow(index) {
      //正则校验电话或手机号数据
      //并去重
      var cuVal = this.articleContent[index].phoneTemModel.phone;
      if (!cuVal) {
        this.$message.warning("请填写手机号码或者座机号码");
        return false;
      }
      var telReg = /^(\+|00)?[0-9|-]{5,}$/;
      // var phoneReg = /^1[0-9]{10}$/;
      if (telReg.test(cuVal)) {
        this.articleContent[index].phoneList.push(JSON.parse(JSON.stringify(this.articleContent[index].phoneTemModel)));
        this.phoneCancle(index);
      } else {
        this.$message.warning("请填写正确的手机号码或者座机号码");
      }
    },
    phoneCancle: function phoneCancle(index) {
      this.articleContent[index].phoneTemModel = null;
    },
    addPhone: function addPhone(index) {
      if (!this.articleContent[index].phoneTemModel) {
        this.articleContent[index].phoneTemModel = Object.assign({}, this.phoneModel);
      }
    },
    delPhone: function delPhone(index, indexa) {
      var _this2 = this;
      this.$confirm("确认删除?", "提示").then(function () {
        _this2.articleContent[indexa].phoneList.splice(index, 1);
      });
    },
    //新增图片配置
    delFirm: function delFirm(index, indexa) {
      var _this3 = this;
      this.$confirm("确认删除?", "提示").then(function () {
        _this3.articleContent[indexa].sceneList.splice(index, 1);
      });
    },
    handleSetUrl: function handleSetUrl(keyName, keyIndex) {
      var _this4 = this;
      var pageData = {};
      if (keyIndex > -1) {
        pageData = Object.assign({}, this.articleContent[keyName].sceneList[keyIndex]);
      } else {
        pageData = Object.assign({}, this.articleContent[keyName].firmModel);
      }
      var _pageData = pageData,
        appId = _pageData.appId,
        jumpPath = _pageData.jumpPath,
        jumpStatus = _pageData.jumpStatus,
        jumpType = _pageData.jumpType;
      this.pageLinkData = {
        keyName: keyName,
        keyIndex: keyIndex,
        appId: appId,
        jumpPath: jumpPath,
        jumpStatus: jumpStatus,
        jumpType: jumpType
      };
      this.pageLinkShow = true;
      this.$nextTick(function () {
        _this4.$refs["pageLinkSet"].initEditData();
      });
    },
    updateUrlSet: function updateUrlSet(config) {
      var configNew = {
        appId: config.appId,
        jumpPath: config.jumpPath,
        jumpStatus: config.jumpStatus,
        jumpType: config.jumpType
      };
      if (config.keyIndex > -1) {
        this.$set(this.articleContent[config.keyName].sceneList, config.keyIndex, Object.assign(this.articleContent[config.keyName].sceneList[config.keyIndex], configNew));
      } else {
        Object.assign(this.articleContent[config.keyName].firmModel, configNew);
      }
    },
    cancleSetUrl: function cancleSetUrl() {
      this.pageLinkData = {};
    },
    modalFirmPicTap: function modalFirmPicTap(index, indexa) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (index > -1) {
          _this.articleContent[indexa].sceneList[index].pic = img[0].sattDir;
        } else {
          _this.articleContent[indexa].firmModel.pic = img[0].sattDir;
        }
      }, "1", "article");
    },
    submitFirmRow: function submitFirmRow(index) {
      //校验数据
      if (this.articleContent[index].firmModel.pic) {
        this.articleContent[index].sceneList.push(JSON.parse(JSON.stringify(this.articleContent[index].firmModel)));
        this.firmCancle(index);
      } else {
        this.$message.warning("请上传图片");
      }
    },
    firmCancle: function firmCancle(index) {
      this.articleContent[index].firmModel = null;
    },
    addFirm: function addFirm(index) {
      if (!this.articleContent[index].firmModel) {
        this.articleContent[index].firmModel = Object.assign({}, this.rowModel);
      }
    },
    // 上传视频
    getVideo: function getVideo(url) {
      this.articleContent[0].content = url;
    },
    handleRemove: function handleRemove(indexa, index) {
      this.articleContent[indexa].articleProductList.splice(index, 1);
    },
    handleRemoveVideo: function handleRemoveVideo() {
      this.articleContent[0].content = "";
    },
    changeGood: function changeGood(indexa) {
      var _this = this;
      if (this.pram.articleType == 0) {
        this.$modalGoodList(function (row) {
          var obj = row.map(function (element) {
            return {
              productAttrUnique: "",
              productId: element.id,
              image: element.image,
              id: element.id
            };
          });
          _this.articleContent[indexa].articleProductList = obj;
        }, "many", _this.articleContent[indexa].articleProductList);
      } else {
        this.$modalGoodList(function (row) {
          var obj = row.map(function (element) {
            return {
              productAttrUnique: "",
              productId: element.id,
              image: element.image,
              id: element.id
            };
          });
          _this.articleContent[0].articleProductList = obj;
        }, "many", _this.articleContent[0].articleProductList);
      }
    },
    changeLike: function changeLike() {
      var _this = this;
      this.$modalArticle(function (row) {
        row.map(function (item) {
          _this.pram.articleRecommendList.push({
            recommendArticleId: item.id,
            recommendArticleSorting: item.sort,
            title: item.title
          });
          return;
        });
      }, "many", _this.pram.articleRecommendList);
    },
    // 删除
    removeArticle: function removeArticle(index) {
      this.pram.articleRecommendList.splice(index, 1);
    },
    onChangeSpec: function onChangeSpec(lab) {
      this.pram.articleType = lab;
      this.pram.imageInput = "";
      this.articleContent = [{
        content: "",
        articleProductList: [],
        sceneList: [],
        firmModel: null,
        phoneList: [],
        phoneTemModel: null
      }];
    },
    // 增加
    addContentGoods: function addContentGoods() {
      this.articleContent.push({
        content: "",
        articleProductList: [],
        sceneList: [],
        firmModel: null,
        phoneList: [],
        phoneTemModel: null
      });
    },
    // 删除
    removeItem: function removeItem(e) {
      this.articleContent.splice(e, 1);
    },
    // submitForm() {
    //   this.$refs.child.forEach((item) => {
    //     this.pram.articleContentList.push(item.articleContentList)
    //   });
    //   console.log(333333,this.pram.articleContentList)
    // },
    getInfo: function getInfo() {
      var _this5 = this;
      categoryApi.articleInfoApi({
        articleId: this.$route.params.id
      }).then(function (data) {
        _this5.editData = data;
        var arr = _this5.editData.articleCategoryList.map(function (item) {
          return parseInt(item.cid);
        });
        _this5.editData.articleCategoryList = arr;
        _this5.hadlerInitEditData();
      });
    },
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this6 = this;
      var formData = new FormData();
      formData.append("media", param.file);
      var loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      (0, _systemSetting.fileImageApi)(formData, {
        type: "image"
      }).then(function (res) {
        loading.close();
        _this6.pram.mediaId = res.mediaId;

        // this.formValidate.contents.mediaId = res.mediaId
        // this.formValidate.contents.srcUrl = res.url
        _this6.$message.success("上传成功");
      }).catch(function () {
        loading.close();
      });
    },
    // 图文封面
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.pram.imageInput = img[0].sattDir;
      }, tit, "content");
    },
    // 留资封面
    modalPicTapStay: function modalPicTapStay(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.pram.stayHeadImage = img[0].sattDir;
      }, tit, "content");
    },
    // 封面配置
    modalPicTapTwo: function modalPicTapTwo(tit) {
      var that = this;
      that.$modalUpload(function (img) {
        that.pram.coverImage = img[0].sattDir;
      }, tit, "content");
    },
    modalPicTapThree: function modalPicTapThree(tit) {
      var that = this;
      that.$modalUpload(function (img) {
        that.pram.parkCoverImage = img[0].sattDir;
      }, tit, "content");
    },
    // 轮播封面
    modalPicTapBanner: function modalPicTapBanner(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.pram.bannerImage = img[0].sattDir;
      }, tit, "content");
    },
    // 修改赋值
    hadlerInitEditData: function hadlerInitEditData() {
      if (!this.$route.params.id) return;
      var _this$editData = this.editData,
        articleType = _this$editData.articleType,
        author = _this$editData.author,
        cidList = _this$editData.cidList,
        articleCategoryList = _this$editData.articleCategoryList,
        articleRecommendList = _this$editData.articleRecommendList,
        articleContentList = _this$editData.articleContentList,
        content = _this$editData.content,
        imageInput = _this$editData.imageInput,
        bannerImage = _this$editData.bannerImage,
        isBanner = _this$editData.isBanner,
        isHot = _this$editData.isHot,
        isRecommend = _this$editData.isRecommend,
        shareSynopsis = _this$editData.shareSynopsis,
        shareTitle = _this$editData.shareTitle,
        sort = _this$editData.sort,
        title = _this$editData.title,
        url = _this$editData.url,
        id = _this$editData.id,
        label = _this$editData.label,
        subtitle = _this$editData.subtitle,
        coverImage = _this$editData.coverImage,
        isEnterpriseService = _this$editData.isEnterpriseService,
        isParkFirst = _this$editData.isParkFirst,
        parkCoverImage = _this$editData.parkCoverImage,
        parkTag = _this$editData.parkTag,
        stayTitle = _this$editData.stayTitle,
        stayHeadImage = _this$editData.stayHeadImage,
        clLabel = _this$editData.clLabel,
        describeStr = _this$editData.describeStr;
      console.log(1111111111, this.editData);
      this.pram.articleType = articleType;
      this.articleContent[0].content = articleContentList[0].content;
      this.pram.author = author;
      this.pram.cidList = articleCategoryList;
      this.pram.articleRecommendList = articleRecommendList;
      this.articleContent = articleContentList.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          sceneList: item.sceneList || [],
          firmModel: null,
          phoneList: item.phoneList && item.phoneList.map(function (phoneItem) {
            return {
              phone: phoneItem
            };
          }) || [],
          phoneTemModel: null
        });
      });
      this.pram.content = content;
      this.pram.imageInput = imageInput;
      this.pram.bannerImage = bannerImage;
      this.pram.label = label;
      this.pram.isBanner = isBanner;
      this.pram.isHot = isHot;
      this.pram.isRecommend = isRecommend;
      this.pram.shareSynopsis = shareSynopsis;
      this.pram.shareTitle = shareTitle;
      this.pram.sort = sort;
      this.pram.title = title;
      this.pram.url = url;
      this.pram.id = id;
      this.pram.subtitle = subtitle;
      this.pram.coverImage = coverImage;
      this.pram.isEnterpriseService = isEnterpriseService;
      this.pram.isParkFirst = isParkFirst;
      this.pram.parkCoverImage = parkCoverImage;
      this.pram.parkTag = parkTag;
      this.pram.clLabel = clLabel;
      this.pram.stayTitle = stayTitle;
      this.pram.stayHeadImage = stayHeadImage;
      this.pram.describeStr = describeStr;
      // this.pram.mediaId = mediaId
    },
    handlerGetCategoryTreeData: function handlerGetCategoryTreeData() {
      var _this7 = this;
      categoryApi.listCategroy({
        type: 3,
        status: ""
      }).then(function (data) {
        _this7.categoryTreeData = data.list;
        localStorage.setItem("articleClass", JSON.stringify(data.list));
      });
    },
    handerSubmit: function handerSubmit(form) {
      var _this8 = this;
      this.$refs[form].validate(function (valid) {
        if (!valid) return;
        if (!_this8.$route.params.id) {
          _this8.handlerSave();
        } else {
          _this8.handlerUpdate();
        }
      });
    },
    handlerUpdate: function handlerUpdate() {
      var _this9 = this;
      this.loading = true;
      this.pram.cid = Array.isArray(this.pram.cid) && this.pram.cid;
      this.pram.shareTitle = this.pram.title;
      var arr = [];
      this.articleContent.forEach(function (element, index) {
        var param = [];
        element.articleProductList.map(function (item) {
          if (item.id) {
            param.push({
              productAttrUnique: "",
              productId: item.id,
              image: item.image
            });
          } else {
            param.push({
              productAttrUnique: "",
              productId: item.productId,
              image: item.image
            });
          }
        });
        var phoneList = element.phoneList.map(function (item) {
          return item.phone;
        });
        arr.push({
          content: element.content,
          articleProductList: param,
          sceneList: element.sceneList,
          phoneList: phoneList
        });
      });
      this.pram.articleContentList = arr;
      console.log("提交的表单信息", this.pram);
      articleApi.UpdateArticle(this.pram).then(function (data) {
        _this9.$message.success("修改文章成功");
        _this9.loading = false;
        _this9.$router.push({
          path: "/content/articleManager"
        });
      }).catch(function () {
        _this9.loading = false;
      });
    },
    // 新增种草
    handlerSave: function handlerSave() {
      var _this10 = this;
      this.loading = true;
      this.pram.cidList = Array.isArray(this.pram.cidList) && this.pram.cidList;
      this.pram.shareTitle = this.pram.title;
      var articleContent = JSON.parse(JSON.stringify(this.articleContent));
      articleContent.forEach(function (element) {
        element.phoneList = element.phoneList.map(function (item) {
          return item.phone;
        });
      });
      this.pram.articleContentList = articleContent;
      // this.$refs.child.forEach((item) => {
      //   this.pram.articleContentList.push(item.articleContentList);
      // });
      var conInfo = this.pram;
      console.log(conInfo);
      articleApi.AddArticle(conInfo).then(function (data) {
        _this10.$message.success("新增种草成功");
        _this10.loading = false;
        _this10.$router.push({
          path: "/content/articleManager"
        });
      }).catch(function () {
        _this10.loading = false;
      });
    },
    setTagsViewTitle: function setTagsViewTitle() {
      var title = "编辑内容";
      var route = Object.assign({}, this.tempRoute, {
        title: "".concat(title, "-").concat(this.$route.params.id)
      });
      this.$store.dispatch("tagsView/updateVisitedView", route);
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c("div", { staticClass: "acea-row roomBox" }, [
          _c("div", { staticClass: "room-left" }, [
            _c("div", { staticClass: "acea-row room-left-list" }, [
              _c("div", { staticClass: "userHead mr10" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../../assets/imgs/mobilehead.png"),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "userName" }, [
                _c(
                  "span",
                  { staticClass: "sp1", attrs: { title: "小红帽的外婆家" } },
                  [_vm._v("小红帽的外婆家")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "sp2" }, [_vm._v("是的你好")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "acea-row room-left-list" }, [
              _c("div", { staticClass: "userHead mr10" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../../assets/imgs/mobilehead.png"),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "userName" }, [
                _c(
                  "span",
                  { staticClass: "sp1", attrs: { title: "小红帽的外婆家" } },
                  [_vm._v("小红帽的外婆家")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "sp2" }, [_vm._v("是的你好")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "acea-row room-left-list" }, [
              _c("div", { staticClass: "userHead mr10" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../../assets/imgs/mobilehead.png"),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "userName" }, [
                _c(
                  "span",
                  { staticClass: "sp1", attrs: { title: "小红帽的外婆家" } },
                  [_vm._v("小红帽的外婆家")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "sp2" }, [_vm._v("是的你好")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "acea-row room-left-list" }, [
              _c("div", { staticClass: "userHead mr10" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../../assets/imgs/mobilehead.png"),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "userName" }, [
                _c(
                  "span",
                  { staticClass: "sp1", attrs: { title: "小红帽的外婆家" } },
                  [_vm._v("小红帽的外婆家")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "sp2" }, [_vm._v("是的你好")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "acea-row room-left-list" }, [
              _c("div", { staticClass: "userHead mr10" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../../assets/imgs/mobilehead.png"),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "userName" }, [
                _c(
                  "span",
                  { staticClass: "sp1", attrs: { title: "小红帽的外婆家" } },
                  [_vm._v("小红帽的外婆家")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "sp2" }, [_vm._v("是的你好")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "acea-row room-left-list" }, [
              _c("div", { staticClass: "userHead mr10" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../../assets/imgs/mobilehead.png"),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "userName" }, [
                _c(
                  "span",
                  { staticClass: "sp1", attrs: { title: "小红帽的外婆家" } },
                  [_vm._v("小红帽的外婆家")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "sp2" }, [_vm._v("是的你好")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "acea-row room-left-list" }, [
              _c("div", { staticClass: "userHead mr10" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../../assets/imgs/mobilehead.png"),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "userName" }, [
                _c(
                  "span",
                  { staticClass: "sp1", attrs: { title: "小红帽的外婆家" } },
                  [_vm._v("小红帽的外婆家")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "sp2" }, [_vm._v("是的你好")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "acea-row room-left-list" }, [
              _c("div", { staticClass: "userHead mr10" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../../assets/imgs/mobilehead.png"),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "userName" }, [
                _c(
                  "span",
                  { staticClass: "sp1", attrs: { title: "小红帽的外婆家" } },
                  [_vm._v("小红帽的外婆家")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "sp2" }, [_vm._v("是的你好")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "acea-row room-left-list" }, [
              _c("div", { staticClass: "userHead mr10" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../../assets/imgs/mobilehead.png"),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "userName" }, [
                _c(
                  "span",
                  { staticClass: "sp1", attrs: { title: "小红帽的外婆家" } },
                  [_vm._v("小红帽的外婆家")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "sp2" }, [_vm._v("是的你好")]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "room-med" }),
          _vm._v(" "),
          _c("div", { staticClass: "room-right" }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
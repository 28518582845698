"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddBeautyHome = AddBeautyHome;
exports.AddPushHome = AddPushHome;
exports.DelBeautyHome = DelBeautyHome;
exports.InfoBeautyHome = InfoBeautyHome;
exports.InfoEcology = InfoEcology;
exports.InfoLanding = InfoLanding;
exports.InfoPushHome = InfoPushHome;
exports.ListBeautyHome = ListBeautyHome;
exports.ListPushHome = ListPushHome;
exports.PublicBeautyHome = PublicBeautyHome;
exports.PushHomeGroupImport = PushHomeGroupImport;
exports.PushHomeGroupStart = PushHomeGroupStart;
exports.PushHomeGroupStop = PushHomeGroupStop;
exports.SortBeautyHome = SortBeautyHome;
exports.UpdateBeautyHome = UpdateBeautyHome;
exports.saveEcology = saveEcology;
exports.saveLanding = saveLanding;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * landing详情
 * @param
 * @constructor
 */
function InfoLanding(params) {
  return (0, _request.default)({
    url: '/admin/loading/page/info',
    method: 'get',
    params: params
  });
}

/**
 * 保存landing设置
 * @param pram
 * @constructor
 */
function saveLanding(data) {
  return (0, _request.default)({
    url: '/admin/loading/page/save',
    method: 'post',
    data: data
  });
}

/**
 * 园区生态详情
 * @param
 * @constructor
 */
function InfoEcology(params) {
  return (0, _request.default)({
    url: '/admin/park/ecology/info',
    method: 'get',
    params: params
  });
}

/**
 * 保存园区生态设置
 * @param pram
 * @constructor
 */
function saveEcology(data) {
  return (0, _request.default)({
    url: '/admin/park/ecology/save',
    method: 'post',
    data: data
  });
}

/**
 * 首页装修模块详情
 * @param
 * @constructor
 */
function InfoBeautyHome(params) {
  return (0, _request.default)({
    url: '/admin/home/activity/area/info',
    method: 'get',
    params: params
  });
}

/**
 * 首页装修模块列表
 */
function ListBeautyHome() {
  return (0, _request.default)({
    url: '/admin/home/activity/area/list',
    method: 'GET'
  });
}

/**
 * 删除首页装修模块
 * @param id
 * @constructor
 */
function DelBeautyHome(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/home/activity/area/delete',
    method: 'delete',
    params: data
  });
}

/**
 * 新增首页装修模块
 */
function AddBeautyHome(data) {
  return (0, _request.default)({
    url: '/admin/home/activity/area/save',
    method: 'post',
    data: data
  });
}

/**
 * 更新首页装修模块
 */
function UpdateBeautyHome(data) {
  return (0, _request.default)({
    url: '/admin/home/activity/area/update',
    method: 'post',
    data: data
  });
}

/**
 * 排序首页装修模块
 */
function SortBeautyHome(data) {
  return (0, _request.default)({
    url: '/admin/home/activity/area/update/sort',
    method: 'post',
    data: data
  });
}

/**
 * 发布首页装修模块
 */
function PublicBeautyHome(data) {
  return (0, _request.default)({
    url: '/admin/home/activity/area/update/status',
    method: 'post',
    data: data
  });
}

/**
 * 首页推送人群分组列表
 */
function ListPushHome(params) {
  return (0, _request.default)({
    url: '/admin/home/push/group/list',
    method: 'GET',
    params: params
  });
}

/**
 * 新增推送人群分组
 */
function AddPushHome(data) {
  return (0, _request.default)({
    url: '/admin/home/push/group/save',
    method: 'post',
    data: data
  });
}

/**
 * 推送人群分组 导入
 * @param pram
 */
function PushHomeGroupImport(data, params) {
  return (0, _request.default)({
    url: "/admin/home/push/group/import/".concat(params.id),
    method: 'post',
    data: data
  });
}

/**
 * 推送人群分组详情
 * @param
 * @constructor
 */
function InfoPushHome(params) {
  return (0, _request.default)({
    url: '/admin/home/push/group/info',
    method: 'get',
    params: params
  });
}

/**
 * 人群分组推送
 * @param pram
 */
function PushHomeGroupStart(params) {
  return (0, _request.default)({
    url: "/admin/home/push/group/push/".concat(params.id),
    method: 'post'
  });
}
/**
 * 人群分组停止推送
 * @param pram
 */
function PushHomeGroupStop(params) {
  return (0, _request.default)({
    url: "/admin/home/push/group/stop/".concat(params.id),
    method: 'post'
  });
}
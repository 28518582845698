"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var landingApi = _interopRequireWildcard(require("@/api/landing.js"));
var _pageLink = _interopRequireDefault(require("../../landing/pageLink.vue"));
var _store = require("@/api/store");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var formDefault = {
  cid: "",
  content: "",
  appId: "",
  jumpPath: "",
  jumpStatus: false,
  jumpType: 0,
  pic: "",
  picName: ""
};
var _default = exports.default = {
  name: "parkEcology",
  components: {
    PageLink: _pageLink.default
  },
  data: function data() {
    return {
      ruleForm: JSON.parse(JSON.stringify(formDefault)),
      rules: {},
      pageLinkShow: false,
      pageLinkData: {},
      infoLoading: false,
      cateList: []
    };
  },
  watch: {
    'ruleForm.cid': function ruleFormCid(val) {
      this.getInfo();
    }
  },
  mounted: function mounted() {
    this.getCategorySelect();
  },
  methods: {
    initPageSet: function initPageSet() {},
    getInfo: function getInfo() {
      var _this2 = this;
      this.$refs["ruleForm"].clearValidate();
      this.initPageSet();
      this.infoLoading = true;
      landingApi.InfoEcology({
        cid: this.ruleForm.cid
      }).then(function (data) {
        var dataModel = data;
        dataModel.cid = _this2.ruleForm.cid;
        var defaultModel = JSON.parse(JSON.stringify(formDefault));
        _this2.ruleForm = Object.assign({}, defaultModel, dataModel);
        _this2.infoLoading = false;
      }).catch(function () {
        _this2.infoLoading = false;
      });
    },
    handleSetUrl: function handleSetUrl() {
      var _this3 = this;
      // let pageData = {};
      // if (keyIndex > -1 || keyName == "sceneList") {
      //   pageData = Object.assign({}, this.ruleForm[keyName][keyIndex]);
      // } else {
      //   switch (keyName) {
      //     case "corpAuthList":
      //       pageData = Object.assign({}, this.firmModel);
      //       break;
      //     case "solutionList":
      //       pageData = Object.assign({}, this.solutionModel);
      //       break;
      //   }
      // }
      var _this$ruleForm = this.ruleForm,
        appId = _this$ruleForm.appId,
        jumpPath = _this$ruleForm.jumpPath,
        jumpStatus = _this$ruleForm.jumpStatus,
        jumpType = _this$ruleForm.jumpType;
      this.pageLinkData = {
        // keyName,
        // keyIndex,
        appId: appId,
        jumpPath: jumpPath,
        jumpStatus: jumpStatus,
        jumpType: jumpType
      };
      this.pageLinkShow = true;
      this.$nextTick(function () {
        _this3.$refs["pageLinkSet"].initEditData();
      });
    },
    updateUrlSet: function updateUrlSet(config) {
      var configNew = {
        appId: config.appId,
        jumpPath: config.jumpPath,
        jumpStatus: config.jumpStatus,
        jumpType: config.jumpType
      };
      // if (config.keyIndex > -1 || config.keyName == "sceneList") {
      //   this.$set(
      //     this.ruleForm[config.keyName],
      //     config.keyIndex,
      //     Object.assign(
      //       this.ruleForm[config.keyName][config.keyIndex],
      //       configNew
      //     )
      //   );
      // } else {
      //   switch (config.keyName) {
      //     case "corpAuthList":
      //       this.firmModel = Object.assign(this.firmModel, configNew);
      //       break;
      //     case "solutionList":
      //       this.solutionModel = Object.assign(this.solutionModel, configNew);
      //       break;
      //   }
      // }
      this.ruleForm = Object.assign(this.ruleForm, configNew);
    },
    cancleSetUrl: function cancleSetUrl() {
      this.pageLinkData = {};
    },
    handleSubmit: function handleSubmit() {
      var _this4 = this;
      this.infoLoading = true;
      var param = JSON.parse(JSON.stringify(this.ruleForm));
      this.$refs["ruleForm"].validate(function (valid, errors) {
        if (valid) {
          landingApi.saveEcology(param).then(function () {
            _this4.$message.success("保存成功");
            _this4.infoLoading = false;
          }).catch(function () {
            _this4.infoLoading = false;
          });
        } else {
          _this4.infoLoading = false;
          for (var key in errors) {
            if (errors[key].length > 0) {
              _this4.$message.warning(errors[key][0].message);
              return false;
            }
          }
        }
      });
    },
    modalFirmPicTap: function modalFirmPicTap() {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.ruleForm.pic = img[0].sattDir;
      }, "1", "parkEcology");
    },
    getCategorySelect: function getCategorySelect() {
      var _this5 = this;
      (0, _store.categoryApi)({
        status: -1,
        type: 11
      }).then(function (res) {
        _this5.cateList = _this5.filerMerCateList(res);
        var usefullCate = _this5.cateList.filter(function (item) {
          return !item.disabled;
        });
        if (usefullCate.length > 0) {
          _this5.ruleForm.cid = usefullCate[0].id;
        }
      });
    },
    filerMerCateList: function filerMerCateList(treeData) {
      return treeData.map(function (item) {
        if (!item.status) {
          item.disabled = true;
        }
        item.label = item.name;
        return item;
      });
    }
  }
};
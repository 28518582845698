"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    orderId: {
      type: Number,
      default: 0
    }
  },
  filters: {
    strFilter: function strFilter(str) {
      return str.slice(0, 20);
    }
  },
  data: function data() {
    return {
      labelPosition: "left",
      dialogVisible: false,
      orderDatalist: null,
      rules: {
        msg: [{
          required: true,
          message: "请输入GII编码",
          trigger: "blur"
        }, {
          min: 9,
          max: 9,
          message: "长度在9 个字符",
          trigger: "blur"
        }]
      },
      goodsForm: {
        productList: []
      }
    };
  },
  methods: {
    getDetail: function getDetail(id) {
      var _this = this;
      (0, _order.orderDetailApi)({
        id: id
      }).then(function (res) {
        console.log(res, "1234567890");
        _this.orderDatalist = res;
        var productList = res.orderInfo.map(function (item) {
          return {
            orderId: item.orderId,
            productId: item.productId,
            unique: item.unique,
            storeName: item.info.productInfo.storeName,
            suk: item.info.productInfo.attrInfo.suk,
            image: item.info.productInfo.image,
            giiCode: item.giiCode,
            presents: item.info.productInfo.attrInfo.presents || [],
            price: item.info.productInfo.price,
            cartNum: item.info.cartNum
          };
        });
        _this.goodsForm.productList = productList;
        console.log(_this.goodsForm.productList, "1234567");
      }).catch(function () {
        _this.orderDatalist = null;
      });
    },
    submitForm: function submitForm(ruleForm) {
      var _this2 = this;
      this.$refs[ruleForm].validate(function (valid) {
        if (valid) {
          var params = _this2.goodsForm.productList.map(function (item) {
            return {
              orderId: item.orderId,
              productId: item.productId,
              unique: item.unique,
              giiCode: item.giiCode
            };
          });
          console.log(params);
          (0, _order.updateGiiApi)(params).then(function (res) {
            console.log(res, "res");
            _this2.$message.success("更新成功");
            _this2.dialogVisible = false;
            _this2.$emit("submitFail");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
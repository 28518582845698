"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
var _article = require("@/api/article.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    articleIdList: {
      type: Array
    }
  },
  data: function data() {
    return {
      visible: false,
      templateRadio: 0,
      merCateList: [],
      props: {
        children: "child",
        label: "name",
        value: "id",
        emitPath: false
      },
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 10,
        cid: null,
        keywords: ""
      },
      imgList: [],
      multipleSelection: [],
      checkedPage: [],
      isChecked: false,
      isIndex: 0,
      checkBox: [],
      checkedIds: [] // 订单当前页选中的数据
    };
  },
  methods: {
    ok: function ok() {
      console.log(this.checkBox);
      if (this.checkBox.length > 3) {
        return this.$message.error("您最多选中3项");
      } else {
        this.$emit("chooseContent", this.checkBox);
        this.visible = false;
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.checkBox = val;
    },
    handleClose: function handleClose() {
      this.visible = false;
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _article.ListArticle)(this.tableFrom).then(function (res) {
        console.log(1212121, res);
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.tableData.data.map(function (item) {
          _this.imgList.push(item.image);
        });
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        _this.$message.error(res.message);
      });
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("meta", { attrs: { name: "referrer", content: "no-referrer" } }),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "formInline",
              staticStyle: { "margin-left": "5px" },
              attrs: { inline: true, model: _vm.formInline },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "searchContent" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品名称" },
                    model: {
                      value: _vm.formInline.searchContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "searchContent", $$v)
                      },
                      expression: "formInline.searchContent",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "13%" },
                  attrs: { label: "", prop: "searchGoodsName" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择审核状态" },
                      model: {
                        value: _vm.formInline.goodsStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "goodsStatus", $$v)
                        },
                        expression: "formInline.goodsStatus",
                      },
                    },
                    _vm._l(_vm.goodsStatusOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.searchGoods("formInline")
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.resetGoods("formInline")
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { margin: "5px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addGoods(1)
                        },
                      },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%", "font-size": "12px" },
              attrs: { data: _vm.tableData, "tooltip-effect": "dark" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "直播商品id",
                  width: "120",
                  prop: "goodsId",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "商品名称",
                  width: "120",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "cover_img",
                  label: "关联商品id",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "商品图片",
                  prop: "share_img",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: { src: scope.row.coverImgUrl },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "url",
                  label: "商品小程序路径",
                  align: "center",
                  width: "120",
                },
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    prop: "priceType",
                    label: "价格类型",
                    align: "center",
                  },
                },
                [[_c("el-tag", [_vm._v("一口价")])]],
                2
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    prop: "end_time",
                    label: "审核状态",
                    align: "center",
                  },
                },
                [
                  [
                    _c("el-tag", [
                      _vm._v(
                        _vm._s(
                          _vm.formInline.goodsStatus == 0
                            ? "未审核"
                            : _vm.formInline.goodsStatus == 1
                            ? "审核中"
                            : _vm.formInline.goodsStatus == 2
                            ? "审核通过"
                            : "审核驳回"
                        )
                      ),
                    ]),
                  ],
                ],
                2
              ),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "anchor_name",
                  label: "添加途径",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-tag", [
                          _vm._v(
                            _vm._s(
                              scope.row.thirdPartyTag == 0 ? "API" : "控制台"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  label: "操作",
                  width: "150",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "text",
                              disabled: _vm.disabledGoods,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(2, scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage2,
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.PageSize,
                  layout: "sizes, prev, pager, next",
                  total: _vm.totalPage,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    _vm.currentPage2 = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentPage2 = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.editGoodsDetail
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.editGoodsDetail,
                    title: "编辑直播商品",
                    width: "50%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.editGoodsDetail = $event
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "goodsForm",
                      attrs: { model: _vm.goodsForm, "label-width": "120px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择商品" } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              class: { disabled: _vm.uploadDisabled },
                              attrs: {
                                action: "#",
                                "list-type": "picture-card",
                                "on-remove": _vm.handleRemove,
                                "http-request": _vm.httpRequest,
                                "file-list": _vm.fileList,
                                disabled: _vm.selectGoodsDisabled,
                              },
                            },
                            [
                              _c(
                                "div",
                                { on: { click: _vm.openGoodsListDialog } },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-plus",
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品封面图" } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              class: { disabled: _vm.uploadDisabled1 },
                              attrs: {
                                action: "#",
                                "list-type": "picture-card",
                                "on-remove": _vm.handleRemove1,
                                "http-request": _vm.httpRequest1,
                                "file-list": _vm.fileList1,
                                "on-change": _vm.uploadChange1,
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-plus",
                                staticStyle: { width: "100%", height: "100%" },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("图片规则：图片尺寸最大300像素*300像素；"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品小程序路径" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.goodsForm.url,
                              callback: function ($$v) {
                                _vm.$set(_vm.goodsForm, "url", $$v)
                              },
                              expression: "goodsForm.url",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品名称", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "14",
                              type: "text",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.goodsForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.goodsForm, "name", $$v)
                              },
                              expression: "goodsForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "价格类型", prop: "priceType" } },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "1" },
                                  model: {
                                    value: _vm.goodsForm.priceType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.goodsForm, "priceType", $$v)
                                    },
                                    expression: "goodsForm.priceType",
                                  },
                                },
                                [_vm._v("一口价")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "2" },
                                  model: {
                                    value: _vm.goodsForm.priceType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.goodsForm, "priceType", $$v)
                                    },
                                    expression: "goodsForm.priceType",
                                  },
                                },
                                [_vm._v("价格区间")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "3" },
                                  model: {
                                    value: _vm.goodsForm.priceType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.goodsForm, "priceType", $$v)
                                    },
                                    expression: "goodsForm.priceType",
                                  },
                                },
                                [_vm._v("显示折扣价")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { width: "50%" },
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "最低价格",
                                        prop: "price2",
                                      },
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          precision: 2,
                                          step: 1,
                                          min: 0,
                                        },
                                        model: {
                                          value: _vm.goodsForm.price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.goodsForm,
                                              "price",
                                              $$v
                                            )
                                          },
                                          expression: "goodsForm.price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { width: "50%" },
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "最高价格" } },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          precision: 2,
                                          step: 1,
                                          min: 0,
                                        },
                                        model: {
                                          value: _vm.goodsForm.price2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.goodsForm,
                                              "price2",
                                              $$v
                                            )
                                          },
                                          expression: "goodsForm.price2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { width: "50%" },
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "一口价", prop: "price" },
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          precision: 2,
                                          step: 1,
                                          min: 0,
                                        },
                                        model: {
                                          value: _vm.goodsForm.price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.goodsForm,
                                              "price",
                                              $$v
                                            )
                                          },
                                          expression: "goodsForm.price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { width: "50%" },
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "市场价", prop: "price" },
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          precision: 2,
                                          step: 1,
                                          min: 0,
                                        },
                                        model: {
                                          value: _vm.goodsForm.price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.goodsForm,
                                              "price",
                                              $$v
                                            )
                                          },
                                          expression: "goodsForm.price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { width: "50%" },
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "现价" } },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          precision: 2,
                                          step: 1,
                                          min: 0,
                                        },
                                        model: {
                                          value: _vm.goodsForm.price2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.goodsForm,
                                              "price2",
                                              $$v
                                            )
                                          },
                                          expression: "goodsForm.price2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm("goodsForm")
                                },
                              },
                            },
                            [_vm._v("确认")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.cancelForm("ruleForm")
                                },
                              },
                            },
                            [_vm._v("取消")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogVisiblePicture },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisiblePicture = $event
                },
              },
            },
            [
              _c(
                "el-row",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-right": "5px" },
                      attrs: { span: 8 },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入搜索内容" },
                        model: {
                          value: _vm.findContent,
                          callback: function ($$v) {
                            _vm.findContent = $$v
                          },
                          expression: "findContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.selectValue,
                            callback: function ($$v) {
                              _vm.selectValue = $$v
                            },
                            expression: "selectValue",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "margin-left": "15px",
                        "margin-top": "0.5%",
                      },
                      attrs: { span: 3 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: {
                            icon: "el-icon-search",
                            size: "mini",
                            type: "success",
                          },
                          on: { click: _vm.findGoodsList },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "goodsListTable",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.productList, "tooltip-effect": "dark" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "直播商品id",
                      width: "120",
                      prop: "goodsId",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "商品名称",
                      width: "120",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cover_img",
                      label: "关联商品id",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品图片",
                      prop: "coverImgUrl",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-image", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: { src: scope.row.coverImgUrl },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseGoods(scope.row)
                                  },
                                },
                              },
                              [_vm._v("选择")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
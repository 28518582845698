var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "market_header" },
        [
          _c("el-form", {
            ref: "searchForm",
            attrs: {
              size: "small",
              inline: true,
              model: _vm.searchForm,
              rules: _vm.rules,
              "label-width": "80px",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  type: "text",
                  icon: "el-icon-delete",
                  size: "large",
                  disabled: _vm.delBtnDisabled,
                },
                on: { click: _vm.deleteElement },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.changeLineStyle },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.lineStyle === "Bezier" ? "直线" : "曲线") +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isDraft == 2 || _vm.isDraft == 6
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.notWatch,
                          expression: "notWatch",
                        },
                      ],
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.saveDraft },
                    },
                    [_vm._v("保存草稿")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.notWatch,
                      expression: "notWatch",
                    },
                  ],
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.saveInfo },
                },
                [_vm._v("保存发布")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.goBack },
                },
                [_vm._v("返回列表")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.easyFlowVisible
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.saveLoading,
                  expression: "saveLoading",
                },
              ],
              staticClass: "jsplumb",
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "230px",
                    "border-right": "1px solid #dce3e8",
                  },
                },
                [
                  _c("node-menu", {
                    ref: "nodeMenu",
                    on: { addNode: _vm.addNode },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [{ name: "flowDrag", rawName: "v-flowDrag" }],
                  ref: "efContainer",
                  staticClass: "container",
                  attrs: { id: "efContainer" },
                },
                [
                  _vm._l(_vm.data.nodeList, function (node) {
                    return [
                      _c("flow-node", {
                        key: node.id,
                        attrs: {
                          id: node.id,
                          node: node,
                          "active-element": _vm.activeElement,
                        },
                        on: {
                          changeNodeSite: _vm.changeNodeSite,
                          clickNode: _vm.clickNode,
                          dblclickNode: _vm.dblclickNode,
                        },
                      }),
                    ]
                  }),
                  _vm._v(" "),
                  _vm._m(0),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("flow-node-union", {
        ref: "copyForm",
        attrs: {
          visible: _vm.unionVisible,
          data: _vm.data,
          jsPlumb: _vm.jsPlumb,
          node: _vm.comNodeObj,
          "form-setting": _vm.formSetting,
        },
        on: {
          "update:visible": function ($event) {
            _vm.unionVisible = $event
          },
          repaintEverything: _vm.repaintEverything,
          reloadCanvas: _vm.reloadCanvas,
        },
      }),
      _vm._v(" "),
      _c("flow-node-user", {
        ref: "userForm",
        attrs: {
          visible: _vm.userVisible,
          data: _vm.data,
          jsPlumb: _vm.jsPlumb,
          node: _vm.comNodeObj,
          "form-setting": _vm.formSetting,
        },
        on: {
          "update:visible": function ($event) {
            _vm.userVisible = $event
          },
          repaintEverything: _vm.repaintEverything,
          reloadCanvas: _vm.reloadCanvas,
        },
      }),
      _vm._v(" "),
      _c("flow-node-event", {
        ref: "userForm",
        attrs: {
          visible: _vm.eventVisible,
          data: _vm.data,
          jsPlumb: _vm.jsPlumb,
          node: _vm.comNodeObj,
          "form-setting": _vm.formSetting,
        },
        on: {
          "update:visible": function ($event) {
            _vm.eventVisible = $event
          },
          repaintEverything: _vm.repaintEverything,
          reloadCanvas: _vm.reloadCanvas,
        },
      }),
      _vm._v(" "),
      _c("flow-node-coupons", {
        ref: "couponsForm",
        attrs: {
          visible: _vm.couponsVisible,
          data: _vm.data,
          jsPlumb: _vm.jsPlumb,
          node: _vm.comNodeObj,
          "form-setting": _vm.formSetting,
        },
        on: {
          "update:visible": function ($event) {
            _vm.couponsVisible = $event
          },
          repaintEverything: _vm.repaintEverything,
        },
      }),
      _vm._v(" "),
      _c("flow-node-timer", {
        ref: "timerForm",
        attrs: {
          visible: _vm.timerVisible,
          data: _vm.data,
          jsPlumb: _vm.jsPlumb,
          node: _vm.comNodeObj,
          "form-setting": _vm.formSetting,
        },
        on: {
          "update:visible": function ($event) {
            _vm.timerVisible = $event
          },
          repaintEverything: _vm.repaintEverything,
          reloadCanvas: _vm.reloadCanvas,
        },
      }),
      _vm._v(" "),
      _c("flow-node-random", {
        ref: "randomForm",
        attrs: {
          visible: _vm.randomVisible,
          data: _vm.data,
          jsPlumb: _vm.jsPlumb,
          node: _vm.comNodeObj,
          "form-setting": _vm.formSetting,
        },
        on: {
          "update:visible": function ($event) {
            _vm.randomVisible = $event
          },
          repaintEverything: _vm.repaintEverything,
          reloadCanvas: _vm.reloadCanvas,
        },
      }),
      _vm._v(" "),
      _c("flow-node-wx", {
        ref: "wxForm",
        attrs: {
          visible: _vm.wxVisible,
          data: _vm.data,
          jsPlumb: _vm.jsPlumb,
          node: _vm.comNodeObj,
          "form-setting": _vm.formSetting,
        },
        on: {
          "update:visible": function ($event) {
            _vm.wxVisible = $event
          },
          repaintEverything: _vm.repaintEverything,
        },
      }),
      _vm._v(" "),
      _c("flow-node-start", {
        ref: "startForm",
        attrs: {
          visible: _vm.startVisible,
          data: _vm.data,
          jsPlumb: _vm.jsPlumb,
          node: _vm.comNodeObj,
          "form-setting": _vm.formSetting,
        },
        on: {
          "update:visible": function ($event) {
            _vm.startVisible = $event
          },
          repaintEverything: _vm.repaintEverything,
        },
      }),
      _vm._v(" "),
      _c("flow-node-except", {
        ref: "tenantForm",
        attrs: {
          visible: _vm.exceptVisible,
          data: _vm.data,
          jsPlumb: _vm.jsPlumb,
          node: _vm.comNodeObj,
          "form-setting": _vm.formSetting,
        },
        on: {
          "update:visible": function ($event) {
            _vm.exceptVisible = $event
          },
          reloadCanvas: _vm.reloadCanvas,
          repaintEverything: _vm.repaintEverything,
        },
      }),
      _vm._v(" "),
      _c("flow-node-message", {
        ref: "messageForm",
        attrs: {
          visible: _vm.messageVisible,
          data: _vm.data,
          jsPlumb: _vm.jsPlumb,
          node: _vm.comNodeObj,
          "form-setting": _vm.formSetting,
        },
        on: {
          "update:visible": function ($event) {
            _vm.messageVisible = $event
          },
          repaintEverything: _vm.repaintEverything,
        },
      }),
      _vm._v(" "),
      _c("flow-node-lable", {
        ref: "waitForm",
        attrs: {
          visible: _vm.lableVisible,
          data: _vm.data,
          jsPlumb: _vm.jsPlumb,
          node: _vm.comNodeObj,
          "form-setting": _vm.formSetting,
        },
        on: {
          "update:visible": function ($event) {
            _vm.lableVisible = $event
          },
          repaintEverything: _vm.repaintEverything,
        },
      }),
      _vm._v(" "),
      _c("flow-node-wait", {
        ref: "waitForm",
        attrs: {
          visible: _vm.waitVisible,
          groupList: _vm.groupList,
          data: _vm.data,
          jsPlumb: _vm.jsPlumb,
          node: _vm.comNodeObj,
          "form-setting": _vm.formSetting,
        },
        on: {
          "update:visible": function ($event) {
            _vm.waitVisible = $event
          },
          repaintEverything: _vm.repaintEverything,
        },
      }),
      _vm._v(" "),
      _c("flow-node-exclusion", {
        ref: "exclusionForm",
        attrs: {
          visible: _vm.exclusionVisible,
          data: _vm.data,
          jsPlumb: _vm.jsPlumb,
          node: _vm.comNodeObj,
          "form-setting": _vm.formSetting,
        },
        on: {
          "update:visible": function ($event) {
            _vm.exclusionVisible = $event
          },
          repaintEverything: _vm.repaintEverything,
          reloadCanvas: _vm.reloadCanvas,
        },
      }),
      _vm._v(" "),
      _c("start-dialog-pop", {
        attrs: {
          groupList: _vm.groupList,
          getEventDict: _vm.getEventDict,
          dialogStart: _vm.dialogStart,
          form: _vm.form,
        },
        on: { closeDialog: _vm.closeDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { position: "absolute", top: "2000px", left: "2000px" } },
      [_c("span", { staticStyle: { visibility: "hidden" } }, [_vm._v("1")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./coupons-form.vue?vue&type=template&id=93145aee&scoped=true"
import script from "./coupons-form.vue?vue&type=script&lang=js"
export * from "./coupons-form.vue?vue&type=script&lang=js"
import style0 from "./coupons-form.vue?vue&type=style&index=0&id=93145aee&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93145aee",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\VscodeProject\\admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('93145aee')) {
      api.createRecord('93145aee', component.options)
    } else {
      api.reload('93145aee', component.options)
    }
    module.hot.accept("./coupons-form.vue?vue&type=template&id=93145aee&scoped=true", function () {
      api.rerender('93145aee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/marketing/automated/Flow/common/coupons-form.vue"
export default component.exports
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _live = require("@/api/live");
var _store = require("@/api/store");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ruleForm: {
        liveTitle: "",
        liveSubtitle: "",
        startTime: "",
        endTime: "",
        liveUrl: "",
        bannerUrl: "",
        imageUrl: "",
        liveInfo: "",
        isBanner: false,
        liveCategoryId: [],
        searchKeywords: "",
        vhallLiveId: "",
        //直播间ID
        videoType: "0",
        // 视频类型  0 回放 1 直播
        clLabel: "",
        stayTitle: "",
        stayHeadImage: ""
      },
      value1: "",
      startTime: "",
      endTime: "",
      merCateList: [],
      props2: {
        children: "child",
        label: "name",
        value: "id",
        multiple: true,
        emitPath: false
      }
    };
  },
  mounted: function mounted() {
    if (this.$route.params.id) {
      console.log(this.$route.params.id);
      this.getInfo();
      this.setTagsViewTitle();
    }
  },
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
    this.getCategorySelect();
  },
  methods: {
    // 商品分类；
    getCategorySelect: function getCategorySelect() {
      var _this2 = this;
      (0, _store.categoryApi)({
        status: -1,
        type: 9
      }).then(function (res) {
        _this2.merCateList = _this2.filerMerCateList(res);
      });
    },
    filerMerCateList: function filerMerCateList(treeData) {
      // debugger

      return treeData.map(function (item) {
        if (!item.child) {
          item.disabled = true;
        }
        item.label = item.name;
        return item;
      });
    },
    getInfo: function getInfo() {
      var _this3 = this;
      (0, _live.getLiveDetail)({
        id: this.$route.params.id
      }).then(function (res) {
        // console.log(res);
        var obj = res;
        _this3.startTime = res.startTime;
        _this3.endTime = res.endTime;
        if (obj.startTime && obj.endTime) {
          _this3.value1 = [new Date(obj.startTime), new Date(obj.endTime)];
        }
        if (obj.startTime && obj.endTime) {
          obj.startTime = new Date(obj.startTime);
          obj.endTime = new Date(obj.endTime);
        }
        if (obj.liveCategoryId) {
          _this3.ruleForm.liveCategoryId = obj.liveCategoryId.split(",");
        }
        _this3.ruleForm.bannerUrl = obj.bannerUrl;
        // this.ruleForm.startTime = obj.startTime;
        // this.ruleForm.endTime = obj.endTime;
        _this3.ruleForm.imageUrl = obj.imageUrl;
        _this3.ruleForm.isBanner = obj.isBanner;
        _this3.ruleForm.liveInfo = obj.liveInfo;
        _this3.ruleForm.liveSubtitle = obj.liveSubtitle;
        _this3.ruleForm.liveTitle = obj.liveTitle;
        _this3.ruleForm.liveUrl = obj.liveUrl;
        _this3.ruleForm.searchKeywords = obj.searchKeywords;
        _this3.ruleForm.vhallLiveId = obj.vhallLiveId + "";
        _this3.ruleForm.videoType = obj.videoType + "";
        _this3.ruleForm.clLabel = obj.clLabel;
        _this3.ruleForm.stayTitle = obj.stayTitle;
        _this3.ruleForm.stayHeadImage = obj.stayHeadImage;

        // this.ruleForm = obj;
      });
    },
    timFun: function timFun(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      var second = date.getSeconds();
      second = minute < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this4.$route.params.id) {
            console.log("编辑");
            var Form = Object.assign({}, _this4.ruleForm); // 深拷贝 否则变量污染

            Form.id = _this4.$route.params.id;
            if (_this4.value1) {
              Form.startTime = _this4.timFun(_this4.value1[0]);
              Form.endTime = _this4.timFun(_this4.value1[1]);
            }
            Form.liveCategoryId = Form.liveCategoryId.join(",");
            Form.videoType = Number(Form.videoType);
            Form.vhallLiveId = Number(Form.vhallLiveId);
            (0, _live.addLive)(Form).then(function (res) {
              _this4.$message.success("修改成功");
              _this4.$router.push({
                path: "/living/live"
              });
            });
          } else {
            var _Form = Object.assign({}, _this4.ruleForm); // 深拷贝 否则变量污染

            if (_this4.value1) {
              _Form.startTime = _this4.timFun(_this4.value1[0]);
              _Form.endTime = _this4.timFun(_this4.value1[1]);
            }
            _Form.liveCategoryId = _Form.liveCategoryId.join(",");
            _Form.videoType = Number(_Form.videoType);
            _Form.vhallLiveId = Number(_Form.vhallLiveId);
            (0, _live.addLive)(_Form).then(function (res) {
              _this4.$message.success("创建成功");
              _this4.$router.push({
                path: "/living/live"
              });
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    modalPicTap: function modalPicTap(tit, num) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit === "1" && num == "bannerImage") {
          _this.ruleForm.bannerUrl = img[0].sattDir;
        } else if (tit == "1" && num == "ListImage") {
          _this.ruleForm.imageUrl = img[0].sattDir;
        }
      }, tit, "content");
    },
    // 留资封面
    modalPicTapStay: function modalPicTapStay(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.ruleForm.stayHeadImage = img[0].sattDir;
      }, tit, "content");
    },
    setTagsViewTitle: function setTagsViewTitle() {
      var title = "编辑直播间";
      var route = Object.assign({}, this.tempRoute, {
        title: "".concat(title, "-").concat(this.$route.params.id)
      });
      this.$store.dispatch("tagsView/updateVisitedView", route);
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _streamManage = require("@/api/streamManage");
var _dayjs = _interopRequireDefault(require("dayjs"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      //  pickerOptions: {
      //     shortcuts: [{
      //       text: '此刻',
      //       onClick(picker) {
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * (13/60));
      //         picker.$emit('pick', start);
      //       }
      //     }]
      //   },
      typeDisabled: false,
      submitFlag: false,
      openVideoDialog: false,
      videoImage: '',
      secretKey: '',
      pushFlowVisible: false,
      tableData: [],
      totalPage: 0,
      PageSize: 10,
      currentPage2: 1,
      dialogVisibleAdd: false,
      ruleForm: {
        name: "",
        coverImg: "",
        shareImg: "",
        start_time: "",
        end_time: "",
        anchor_name: "",
        anchor_wechat: "",
        type: "",
        close_like: "0",
        close_goods: "0",
        close_comment: "0",
        close_replay: "0",
        close_share: "0",
        close_kf: "1",
        feedsImg: "",
        live_type: "0"
        // anchorWechat: ''
      },
      rules: {
        name: [{
          required: true,
          message: "请输入直播间标题",
          trigger: "blur"
        }],
        anchor_name: [{
          required: true,
          message: "请输入主播昵称",
          trigger: "blur"
        }],
        anchor_wechat: [{
          required: true,
          message: "请输入主播微信号",
          trigger: "blur"
        }],
        type: [{
          required: true,
          message: "请选择直播间类型",
          trigger: "change"
        }],
        close_like: [{
          required: true,
          message: "请选择是否关闭点赞",
          trigger: "change"
        }],
        close_comment: [{
          required: true,
          message: "请选择是否关闭评论",
          trigger: "change"
        }],
        close_replay: [{
          required: true,
          message: "请选择是否关闭回放",
          trigger: "change"
        }],
        close_kf: [{
          required: true,
          message: "请选择是否关闭客服",
          trigger: "change"
        }],
        start_time: [{
          required: true,
          message: "请选择开始时间",
          trigger: "blur"
        }],
        end_time: [{
          required: true,
          message: "请选择结束时间",
          trigger: "blur"
        }]
      },
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      },
      dialogVisible: false,
      goodsDialogVisible: false,
      dialogVisiblePicture: false,
      dialogImageUrl: "",
      productList: [],
      options: [{
        label: "商品名称",
        value: ""
      }],
      selectValue: "",
      chooseGoodsList: [],
      chooseGoodsRoomIds: "",
      uploadVisable: false,
      fileList: [],
      selectGoodsDisabled: true,
      fileList2: [],
      fileList3: [],
      fileList4: [],
      chooseNum: 0,
      //1 为新增 2为编辑
      liveStatusOptions: [{
        label: "直播中",
        value: 101
      }, {
        label: "未开始",
        value: 102
      }, {
        label: "已结束",
        value: 103
      }, {
        label: "禁播",
        value: 104
      }, {
        label: "暂停",
        value: 105
      }, {
        label: "异常",
        value: 106
      }, {
        label: "已过期",
        value: 107
      }],
      imageRoomid: '',
      imageForm: {
        shareImg: '',
        coverImg: '',
        feedsImg: ''
      }
    };
  },
  mounted: function mounted() {
    this.getVedioList();
  },
  computed: {
    uploadDisabled: function uploadDisabled() {
      return this.fileList2.length > 0;
    },
    uploadDisabled1: function uploadDisabled1() {
      console.log(this.fileList3.length, 'this.fileList3.length');
      return this.fileList3.length > 0;
    },
    uploadDisabled2: function uploadDisabled2() {
      return this.fileList4.length > 0;
    }
  },
  methods: {
    // startChange(val){
    //   console.log(dayjs(new Date()).valueOf() + 3600 * 1000 * (13/60), new Date(),'val');
    //   // this.ruleForm.start_time = dayjs(val).valueOf() + 3600 * 1000 * (13/60)
    // },
    pushFlowClose: function pushFlowClose() {
      this.pushFlowVisible = false;
    },
    openVideoClose: function openVideoClose() {
      this.openVideoDialog = false;
    },
    alertPic: function alertPic() {
      this.openVideoDialog = true;
    },
    // 直播间推流
    getPushFlow: function getPushFlow(val) {
      var _this2 = this;
      var params = {
        roomId: val.roomid
      };
      (0, _streamManage.liveGetPushUrl)(params).then(function (res) {
        _this2.pushFlowVisible = true;
        var len = 'rtmp://wxalivepush.weixin.qq.com/live/'.length;
        _this2.secretKey = res.pushAddr.substring(len, res.pushAddr.length);
        console.log(_this2.secretKey, 'rrrrr');
      });
    },
    // 直播间背景图
    handleUploadForm: function handleUploadForm(param) {
      var _this3 = this;
      console.log(param, "param");
      var formData = new FormData();
      formData.append("media", param.file);
      var loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      // return
      (0, _streamManage.wechatUploadApi)(formData, {
        type: "image"
      }).then(function (res) {
        loading.close();
        _this3.ruleForm.cover_img = res.mediaId;
        _this3.imageForm.coverImg = res.url;
        _this3.fileList2 = [{
          name: res.name,
          url: res.url
        }];
        _this3.$message.success("上传成功");
      }).catch(function () {
        loading.close();
      });
    },
    uploadChange1: function uploadChange1(val, val1) {
      // this.fileList2 = [
      //   {
      //     name: val.name,
      //     url: val.url,
      //   },
      // ];
    },
    // 直播间分享图
    handleUploadForm1: function handleUploadForm1(param) {
      var _this4 = this;
      var formData = new FormData();
      formData.append("media", param.file);
      var loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      // return
      (0, _streamManage.wechatUploadApi)(formData, {
        type: "image"
      }).then(function (res) {
        loading.close();
        _this4.ruleForm.share_img = res.mediaId;
        _this4.imageForm.shareImg = res.url;
        _this4.fileList3 = [{
          name: res.name,
          url: res.url
        }];
        _this4.$message.success("上传成功");
      }).catch(function () {
        loading.close();
      });
    },
    // 直播间图片封面图
    handleUploadForm2: function handleUploadForm2(param) {
      var _this5 = this;
      var formData = new FormData();
      formData.append("media", param.file);
      var loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      // return
      (0, _streamManage.wechatUploadApi)(formData, {
        type: "image"
      }).then(function (res) {
        loading.close();
        _this5.ruleForm.feeds_img = res.mediaId;
        _this5.imageForm.feedsImg = res.url;
        _this5.fileList4 = [{
          name: res.name,
          url: res.url
        }];
        console.log(_this5.ruleForm, "ggggg");
        _this5.$message.success("上传成功");
      }).catch(function () {
        loading.close();
      });
    },
    uploadChange2: function uploadChange2(val) {
      console.log(this.fileList3, val, '直播间图片上传');
      // this.fileList3 = [
      //   {
      //     name: val.name,
      //     url: val.url,
      //   },
      // ];
    },
    uploadChange3: function uploadChange3(val, val1) {
      console.log(val, "ffffff");
      // this.fileList4 = [
      //   {
      //     name: val.name,
      //     url: val.url
      //   },
      // ];
    },
    // 获取列表
    getVedioList: function getVedioList() {
      var _this6 = this;
      var that = this;
      var params = {
        start: this.currentPage2,
        // 从第几个房间开始拉取
        limit: this.PageSize // 每次拉取的数量
      };
      (0, _streamManage.getStreamList)(params).then(function (res) {
        var tableData = res.room_info;
        _this6.totalPage = res.total * _this6.PageSize;
        var idList = [];
        // 直播间图片替换成长期有效的图片
        tableData.map(function (item) {
          item.feedsImg = '';
          idList.push(item.roomid);
          item.start_time = (0, _dayjs.default)(item.start_time * 1000).format("YYYY-MM-DD HH:mm");
          item.end_time = (0, _dayjs.default)(item.end_time * 1000).format("YYYY-MM-DD HH:mm");
          item.live_status = _this6.liveStatusOptions.filter(function (val) {
            return val.value == item.live_status;
          })[0].label;
        });
        if (idList.length !== 0) {
          idList = idList.join(',');
        }
        (0, _streamManage.findImageListByIds)({
          liveIds: idList
        }).then(function (res) {
          var imgList = [];
          imgList = res;
          that.$nextTick(function () {
            tableData.map(function (item) {
              imgList.map(function (item1) {
                if (item.roomid == item1.liveId) {
                  item.feedsImg = item1.feedsImg;
                  item.anchor_wechat = item1.anchorWechat;
                }
              });
            });
            tableData.map(function (item) {
              if (!item.feedsImg) {
                item.feedsImg = 'https://workstation.chinaeast2.cloudapp.chinacloudapi.cn:8221/ecomimage/setting/2021/12/01/a1943abdbeaa412892f86660d7d4f298aer1iefbds.jpg';
              }
            });
            that.tableData = tableData;
          });
        });
      });
    },
    summitVideo: function summitVideo(ruleRef) {
      var _this7 = this;
      var _this = this;
      // this.ruleForm.anchorWechat = this.ruleForm.anchor_wechat
      this.$refs[ruleRef].validate(function (valid) {
        if (valid) {
          _this.submitFlag = true;
          var ruleForm = JSON.parse(JSON.stringify(_this7.ruleForm));
          ruleForm.start_time = JSON.parse(JSON.stringify((0, _dayjs.default)(_this7.ruleForm.start_time).valueOf() / 1000));
          ruleForm.end_time = JSON.parse(JSON.stringify((0, _dayjs.default)(_this7.ruleForm.end_time).valueOf() / 1000));
          if (_this7.chooseNum == 1) {
            (0, _streamManage.addLive)(ruleForm).then(function (res) {
              _this7.dialogVisibleAdd = false;
              _this.submitFlag = false;
              _this7.imageRoomid = res.roomId;
              console.log();
              _this7.$message.success("创建直播间成功");
              // 成功之后另存图片
              var data = {
                feedsImg: _this.imageForm.feedsImg,
                coverImg: _this.imageForm.coverImg,
                createTime: "",
                updateTime: "",
                id: "",
                liveId: _this.imageRoomid,
                shareImg: _this.imageForm.shareImg,
                anchorWechat: _this.ruleForm.anchor_wechat
              };
              (0, _streamManage.saveImage)(data).then(function (res) {});
            }).catch(function (err) {
              _this.submitFlag = false;
            });
          } else {
            (0, _streamManage.editLive)(ruleForm).then(function (res) {
              _this7.submitFlag = false;
              _this7.dialogVisibleAdd = false;
              var data = {
                feedsImg: _this.imageForm.feedsImg,
                coverImg: _this.imageForm.coverImg,
                createTime: "",
                updateTime: "",
                id: "",
                liveId: _this.imageRoomid,
                shareImg: _this.imageForm.shareImg
              };
              (0, _streamManage.modifyImage)(data).then(function (res) {});
              _this7.$message.success("编辑直播间成功");
            }).catch(function (err) {
              _this.submitFlag = false;
            });
          }
          // 刷新列表
          setTimeout(function () {
            _this.getVedioList();
          }, 5000);
        } else {
          return false;
        }
      });
    },
    // 新增直播间
    addRoom: function addRoom() {
      this.dialogVisibleAdd = true;
      this.typeDisabled = false;
      this.chooseNum = 1;
      this.fileList2 = [];
      this.fileList3 = [];
      this.fileList4 = [];
      this.ruleForm = {
        name: "",
        coverImg: "",
        shareImg: "",
        start_time: "",
        end_time: "",
        anchor_name: "",
        anchor_wechat: "",
        type: "",
        close_like: "0",
        close_goods: "0",
        close_comment: "0",
        close_replay: "0",
        close_share: "0",
        close_kf: "0",
        feedsImg: "",
        live_type: "0"
      };
    },
    // 导出直播间
    //  addRoom(){},
    // 同步直播间
    handleSizeChange: function handleSizeChange(val) {
      this.PageSize = val;
      this.currentPage2 = 1;
      this.getVedioList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getVedioList();
    },
    // 编辑直播间
    handleEdit: function handleEdit(val1, val) {
      this.typeDisabled = true;
      this.dialogVisibleAdd = true;
      this.imageRoomid = val.roomid;
      for (var i in val) {
        val[i] = String(val[i]);
      }
      this.chooseNum = val1;
      this.ruleForm = val;
      this.ruleForm.goods = [];
      this.fileList2 = [{
        name: val.name,
        url: val.cover_img
      }];
      this.fileList3 = [{
        name: val.name,
        url: val.share_img
      }];
      this.fileList4 = [{
        name: val.name,
        url: val.feeds_img
      }];
      this.imageForm.shareImg = val.share_img;
      this.imageForm.feedsImg = val.feeds_img;
      this.imageForm.coverImg = val.cover_img;
    },
    // 删除直播间
    handleDelete: function handleDelete(val) {
      var _this8 = this;
      var params = {
        id: val.roomid
      };
      this.imageRoomid = val.roomid;
      (0, _streamManage.deleteLive)(params).then(function (res) {
        var _this = _this8;
        _this8.getVedioList();
        // 删除图片
        var data = {
          liveId: _this.imageRoomid
        };
        (0, _streamManage.delImage)(data).then(function (res) {});
      });
    },
    handleClose: function handleClose() {
      this.goodsDialogVisible = false;
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.uploadVisable = true;
      this.dialogImageUrl = file.url;
    },
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleRemove1: function handleRemove1() {
      this.fileList2 = [];
    },
    handleRemove2: function handleRemove2() {
      this.fileList3 = [];
    },
    handleRemove3: function handleRemove3() {
      this.fileList4 = [];
    },
    handleSelectionChange: function handleSelectionChange() {},
    // 直播间导入商品
    handleAdd: function handleAdd(val) {
      this.goodsDialogVisible = true;
      this.chooseGoodsRoomIds = val.roomid;
      // 每次打开弹框，都要重置文件列表
      this.fileList = [];
    },
    openGoodsListDialog: function openGoodsListDialog() {
      var _this9 = this;
      this.selectGoodsDisabled = true;
      this.dialogVisiblePicture = true;
      var params = {
        limit: 100,
        offset: 1,
        status: 2 // 商品状态，0：未审核。1：审核中，2：审核通过，3：审核驳回
      };
      (0, _streamManage.getProductList)(params).then(function (res) {
        _this9.productList = res.goods;
        _this9.$nextTick(function () {
          _this9.productList.forEach(function (item) {
            _this9.chooseGoodsList.forEach(function (item1) {
              if (item.goodsId == item1.goodsId) {
                _this9.$refs.goodsListTable.toggleRowSelection(item, true); // 回显
              }
            });
          });
        });
      });
    },
    // 选择商品，点击确认
    goodsDialogClose: function goodsDialogClose() {
      this.dialogVisiblePicture = false;
      this.chooseGoodsList = JSON.parse(JSON.stringify(this.$refs.goodsListTable.selection));
      this.httpRequest();
    },
    confirmAddProduct: function confirmAddProduct() {
      var _this10 = this;
      var ids = [];
      this.chooseGoodsList.map(function (item) {
        ids.push(item.goodsId);
      });
      var data = {
        ids: ids,
        // 数组列表，可传入多个，里面填写 商品 ID
        roomId: this.chooseGoodsRoomIds
      };
      (0, _streamManage.goodsIntoLive)(data).then(function (res) {
        _this10.$message.success("直播间导入商品成功");
      });
      this.goodsDialogVisible = false;
    },
    httpRequest: function httpRequest() {
      var list = [];
      this.chooseGoodsList.map(function (item) {
        list.push({
          name: item.name,
          url: item.coverImgUrl
        });
      });
      this.fileList = JSON.parse(JSON.stringify(list));
      this.selectGoodsDisabled = false;
    }
  }
};
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var logistics = _interopRequireWildcard(require("@/api/logistics"));
var _elementUi = require("element-ui");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var defaultRole = {
  name: '',
  type: 1,
  appoint: false,
  sort: 0,
  region: [{
    first: 0,
    firstPrice: 0,
    renewal: 0,
    renewalPrice: 0,
    city_ids: []
  }],
  undelivery: 0,
  free: [],
  undelives: {},
  city_id3: []
};
var kg = '重量（kg）';
var m = '体积（m³）';
var statusMap = [{
  title: '首件',
  title2: '续件',
  title3: '包邮件数'
}, {
  title: "\u9996\u4EF6".concat(kg),
  title2: "\u7EED\u4EF6".concat(kg),
  title3: "\u5305\u90AE".concat(kg)
}, {
  title: "\u9996\u4EF6".concat(m),
  title2: "\u7EED\u4EF6".concat(m),
  title3: "\u5305\u90AE".concat(m)
}];
var _default = exports.default = {
  name: 'CreatTemplates',
  components: {},
  data: function data() {
    return {
      loading: false,
      rules: {
        name: [{
          required: true,
          message: '请输入模板名称',
          trigger: 'blur'
        }],
        free: [{
          type: 'array',
          required: true,
          message: '请至少添加一个地区',
          trigger: 'change'
        }],
        appoint: [{
          required: true,
          message: '请选择是否指定包邮',
          trigger: 'change'
        }],
        undelivery: [{
          required: true,
          message: '请选择是否指定区域不配送',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: '请选择计费方式',
          trigger: 'change'
        }],
        region: [{
          required: true,
          message: '请选择活动区域',
          trigger: 'change'
        }],
        city_id3: [{
          type: 'array',
          required: true,
          message: '请至少选择一个地区',
          trigger: 'change'
        }],
        first: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        renewal: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        firstPrice: [{
          required: true,
          message: '请输入运费',
          trigger: 'blur'
        }],
        renewalPrice: [{
          required: true,
          message: '请输入续费',
          trigger: 'blur'
        }]
      },
      nodeKey: 'city_id',
      props: {
        children: 'child',
        label: 'name',
        value: 'cityId',
        multiple: true
      },
      dialogVisible: false,
      ruleForm: Object.assign({}, defaultRole),
      listLoading: false,
      cityList: [],
      columns: {
        title: '首件',
        title2: '续件',
        title3: '包邮件数'
      },
      tempId: 0,
      type: 0 // 0添加 1编辑
    };
  },
  mounted: function mounted() {},
  methods: {
    changType: function changType(type) {
      this.type = type;
    },
    onClose: function onClose(formName) {
      this.dialogVisible = false;
      this.$refs[formName].resetFields();
    },
    confirmEdit: function confirmEdit(row, index) {
      row.splice(index, 1);
    },
    popoverHide: function popoverHide() {},
    handleClose: function handleClose() {
      // this.$refs['ruleForm'].resetFields()
      this.dialogVisible = false;
      this.ruleForm = {
        name: '',
        type: 1,
        appoint: false,
        sort: 0,
        region: [{
          first: 0,
          firstPrice: 0,
          renewal: 0,
          renewalPrice: 0,
          city_ids: []
        }],
        undelivery: 0,
        free: [],
        undelives: {},
        city_id3: []
      };
    },
    changeRegion: function changeRegion(value) {
      console.log(value);
    },
    changeRadio: function changeRadio(num) {
      this.columns = Object.assign({}, statusMap[num - 1]);
    },
    // 添加配送区域
    addRegion: function addRegion(region) {
      region.push(Object.assign({}, {
        first: 0,
        firstPrice: 0,
        renewal: 0,
        renewalPrice: 0,
        city_ids: []
      }));
    },
    addFree: function addFree(Free) {
      Free.push(Object.assign({}, {
        city_id: [],
        number: 1,
        price: 1,
        city_ids: []
      }));
    },
    /**
     * 详情
     * id 模板id
     * appoint true包邮 false不包邮
    **/
    getInfo: function getInfo(id, appoint) {
      var _this = this;
      this.tempId = id;
      var loadingInstance = _elementUi.Loading.service({
        fullscreen: true
      });
      logistics.templateDetailApi({
        id: id
      }).then(function (res) {
        _this.dialogVisible = true;
        var info = res;
        _this.ruleForm = Object.assign(_this.ruleForm, {
          name: info.name,
          type: info.type,
          appoint: info.appoint,
          sort: info.sort
        });
        _this.columns = Object.assign({}, statusMap[_this.ruleForm.type - 1]);
        _this.$nextTick(function () {
          loadingInstance.close();
        });
        // 不包邮地区
        _this.shippingRegion();
        // 包邮地区
        if (info.appoint) {
          _this.shippingFree();
        }
      }).catch(function (res) {
        // console.integralLog(res)
        _this.$message.error(res.message);
        _this.$nextTick(function () {
          loadingInstance.close();
        });
      });
    },
    // 不包邮
    shippingRegion: function shippingRegion() {
      var _this2 = this;
      logistics.shippingRegion({
        tempId: this.tempId
      }).then(function (res) {
        res.forEach(function (item, index) {
          item.title = JSON.parse(item.title);
          item.city_ids = item.title;
        });
        _this2.ruleForm.region = res;
      });
    },
    // 包邮
    shippingFree: function shippingFree() {
      var _this3 = this;
      logistics.shippingFree({
        tempId: this.tempId
      }).then(function (res) {
        res.forEach(function (item, index) {
          item.title = JSON.parse(item.title);
          item.city_ids = item.title;
        });
        _this3.ruleForm.free = res;
      });
    },
    // 列表
    getCityList: function getCityList() {
      var _this4 = this;
      logistics.cityListTree().then(function (res) {
        // console.integralLog(res, 'getCityList')
        res.forEach(function (el, index) {
          el.child.forEach(function (cel, j) {
            delete cel.child;
          });
        });
        _this4.cityList = res;
      }).catch(function (res) {
        _this4.$message.error(res.message);
      });
    },
    change: function change(idBox) {
      idBox.map(function (item) {
        var ids = [];
        item.city_ids.map(function (j) {
          j.splice(0, 1);
          ids.push(j[0]);
        });
        item.city_id = ids;
      });
      return idBox;
    },
    changeOne: function changeOne(idBox) {
      var city_ids = [];
      idBox.map(function (item) {
        item.splice(0, 1);
        city_ids.push(item[0]);
      });
      return city_ids;
    },
    onsubmit: function onsubmit(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this5.loading = true;
          var param = {
            appoint: _this5.ruleForm.appoint,
            name: _this5.ruleForm.name,
            sort: _this5.ruleForm.sort,
            type: _this5.ruleForm.type
            // 配送区域及运费
            // shippingTemplatesRegionRequestList: [],
            // // 指定包邮设置
            // shippingTemplatesFreeRequestList: []
          };
          _this5.ruleForm.region.forEach(function (el, index) {
            el.title = el.city_ids.length > 0 ? JSON.stringify(el.city_ids) : JSON.stringify([[0, 0]]);
            for (var i = 0; i < el.city_ids.length; i++) {
              el.city_ids[i].shift();
            }
            el.cityId = el.city_ids.length > 0 ? el.city_ids.join(',') : 'all';
          });
          param.shippingTemplatesRegionRequestList = _this5.ruleForm.region;
          param.shippingTemplatesRegionRequestList.forEach(function (el, index) {
            // delete el.city_ids
            // delete el.city_id
          });
          if (_this5.ruleForm.appoint) {
            _this5.ruleForm.free.forEach(function (el, index) {
              el.title = el.city_ids.length > 0 ? JSON.stringify(el.city_ids) : JSON.stringify([[0, 0]]);
              for (var i = 0; i < el.city_ids.length; i++) {
                el.city_ids[i].shift();
              }
              el.cityId = el.city_ids.length > 0 ? el.city_ids.join(',') : 'all';
            });
            param.shippingTemplatesFreeRequestList = _this5.ruleForm.free;
            param.shippingTemplatesFreeRequestList.forEach(function (el, index) {
              // delete el.city_ids
              // delete el.city_id
            });
          }
          if (_this5.type === 0) {
            logistics.shippingSave(param).then(function (res) {
              _this5.$message.success('操作成功');
              _this5.handleClose();
              _this5.$nextTick(function () {
                _this5.dialogVisible = false;
              });
              setTimeout(function () {
                _this5.$emit('getList');
              }, 600);
              _this5.loading = false;
            });
          } else {
            logistics.shippingUpdate(param, {
              id: _this5.tempId
            }).then(function (res) {
              _this5.$message.success('操作成功');
              setTimeout(function () {
                _this5.$emit('getList');
                _this5.handleClose();
              }, 600);
              _this5.$nextTick(function () {
                _this5.dialogVisible = false;
              });
              _this5.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    clear: function clear() {
      this.ruleForm.name = '';
      this.ruleForm.sort = 0;
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    node: {
      type: Object,
      default: function _default() {}
    },
    activeElement: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    nodeContainerClass: function nodeContainerClass() {
      return {
        "ef-node-container": true,
        "ef-node-active": this.activeElement.nodeType === "node" ? this.activeElement.nodeId === this.node.id : false
      };
    },
    // 节点容器样式
    nodeContainerStyle: function nodeContainerStyle() {
      return {
        top: this.node.top,
        left: this.node.left
      };
    },
    // 拖拽的端点class，
    nodePointClass: function nodePointClass() {
      var nodePointClass = {};
      // 添加该class可以推拽连线出来
      nodePointClass["flow-node-drag"] = true;
      // nodePointClass["flow-node-point"] = true;
      return nodePointClass;
    },
    nodeIcoClass: function nodeIcoClass() {
      var nodeIcoClass = {};
      nodeIcoClass['ico'] = this.node.ico;
      // console.log(this.node,'this.node');
      // console.log(this.node.ico,'this.node.icon');
      return this.node.ico;
    }
  },
  methods: {
    // 点击节点
    clickNode: function clickNode() {
      this.$emit("clickNode", this.node);
    },
    // 双击节点
    dblclickNode: function dblclickNode() {
      this.$emit("dblclickNode", this.node);
    },
    // 右击节点
    nodeRightMenu: function nodeRightMenu() {
      this.$emit("nodeRightMenu", this.node);
    },
    // 鼠标移动后抬起
    changeNodeSite: function changeNodeSite() {
      // 避免抖动
      if (this.node.left === this.$refs.node.style.left && this.node.top === this.$refs.node.style.top) {
        return;
      }
      this.$emit("changeNodeSite", {
        nodeId: this.node.id,
        left: this.$refs.node.style.left,
        top: this.$refs.node.style.top
      });
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "CreatAttr",
  props: {
    currentRow: {
      type: Object,
      default: null
    },
    keyNum: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      loadingBtn: false,
      loading: false,
      dialogVisible: false,
      inputVisible: false,
      inputValue: "",
      spinShow: false,
      grid: {
        xl: 3,
        lg: 3,
        md: 12,
        sm: 24,
        xs: 24
      },
      modal: false,
      index: 1,
      rules: {
        ruleName: [{
          required: true,
          message: "请输入规格名称",
          trigger: "blur"
        }]
      },
      formDynamic: {
        ruleName: "",
        ruleValue: []
      },
      attrsName: "",
      attrsVal: "",
      attrPopupImage: "",
      attrDesc: "",
      isBtn: false,
      results: [],
      result: [],
      ids: 0
    };
  },
  watch: {
    currentRow: {
      handler: function handler(val, oldVal) {
        this.formDynamic = val;
      },
      immediate: true
    },
    keyNum: {
      deep: true,
      handler: function handler(val) {
        if (val > 0) this.clear();
      }
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.formDynamic.ruleValue.map(function (item) {
      _this2.$set(item, "inputVisible", false);
    });
  },
  methods: {
    handlePic: function handlePic(tit, num) {
      if (tit == 1) {
        this.attrPopupImage = "";
      } else {
        this.formDynamic.ruleValue[num].attrPopupImage = "";
      }
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == 1) {
          _this.attrPopupImage = img[0].sattDir;
        } else {
          _this.formDynamic.ruleValue[num].attrPopupImage = img[0].sattDir;
        }
        _this.$forceUpdate();
      }, tit, "store");
    },
    resetForm: function resetForm(formName) {
      this.$msgbox.close();
      this.clear();
      this.$refs[formName].resetFields();
    },
    // 添加按钮
    addBtn: function addBtn() {
      this.isBtn = true;
    },
    handleClose: function handleClose(item, index) {
      item.splice(index, 1);
    },
    // 取消
    offAttrName: function offAttrName() {
      this.isBtn = false;
    },
    // 删除
    handleRemove: function handleRemove(index) {
      this.formDynamic.ruleValue.splice(index, 1);
    },
    // 添加规则名称
    createAttrName: function createAttrName() {
      if (this.attrsName && this.attrsVal) {
        var data = {
          value: this.attrsName,
          detail: [this.attrsVal],
          attrDesc: this.attrDesc,
          attrPopupImage: this.attrPopupImage
        };
        this.formDynamic.ruleValue.push(data);
        var hash = {};
        this.formDynamic.ruleValue = this.formDynamic.ruleValue.reduce(function (item, next) {
          /* eslint-disable */
          hash[next.value] ? "" : hash[next.value] = true && item.push(next);
          return item;
        }, []);
        this.attrsName = "";
        this.attrsVal = "";
        this.attrDesc = "";
        this.attrPopupImage = "";
        this.isBtn = false;
      } else {
        this.$message.warning("规格或者规格值不能为空！");
      }
    },
    // 添加属性
    createAttr: function createAttr(num, idx) {
      if (num) {
        this.formDynamic.ruleValue[idx].detail.push(num);
        var hash = {};
        this.formDynamic.ruleValue[idx].detail = this.formDynamic.ruleValue[idx].detail.reduce(function (item, next) {
          /* eslint-disable */
          hash[next] ? "" : hash[next] = true && item.push(next);
          return item;
        }, []);
        this.formDynamic.ruleValue[idx].inputVisible = false;
      } else {
        this.$message.warning("请添加属性");
      }
    },
    showInput: function showInput(item) {
      this.$set(item, "inputVisible", true);
    },
    // 提交
    handleSubmit: function handleSubmit(name) {
      var _this3 = this;
      var data = {
        id: this.currentRow.id || 0,
        ruleName: this.formDynamic.ruleName,
        ruleValue: JSON.stringify(this.formDynamic.ruleValue)
      };
      this.$refs[name].validate(function (valid) {
        if (valid) {
          if (_this3.formDynamic.ruleValue.length === 0) {
            return _this3.$message.warning("请至少添加一条属性规格！");
          }
          _this3.loadingBtn = true;
          _this3.loading = true;
          setTimeout(function () {
            _this3.currentRow.id ? (0, _store.attrEditApi)(data).then(function (res) {
              _this3.$message.success("提交成功");
              _this3.$msgbox.close();
              _this3.clear();
              _this3.$emit("getList");
              _this3.loading = false;
              _this3.loadingBtn = false;
            }).catch(function () {
              _this3.loading = false;
              _this3.loadingBtn = false;
            }) : (0, _store.attrCreatApi)(data).then(function (res) {
              _this3.$message.success("提交成功");
              _this3.$msgbox.close();
              _this3.$emit("getList");
              _this3.clear();
              _this3.loading = false;
              _this3.loadingBtn = false;
            }).catch(function () {
              _this3.loading = false;
              _this3.loadingBtn = false;
            });
          }, 1200);
        } else {
          _this3.loading = false;
          _this3.loadingBtn = false;
          return false;
        }
      });
    },
    clear: function clear() {
      this.$refs["formDynamic"].resetFields();
      this.formDynamic.ruleValue = [];
      this.formDynamic.ruleName = "";
      // this.formDynamic.attrDesc = "";
      // this.formDynamic.attrPopupImage = "";
      this.isBtn = false;
      this.attrsName = "";
      this.attrsVal = "";
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _wxApi = require("@/api/wxApi");
var _vueQuillEditor = require("vue-quill-editor");
require("quill/dist/quill.core.css");
require("quill/dist/quill.snow.css");
require("quill/dist/quill.bubble.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 导入富文本
var _default = exports.default = {
  components: {
    quillEditor: _vueQuillEditor.quillEditor // 富文本编辑器
  },
  data: function data() {
    return {
      materialType: "image",
      dialogNewsVisible: false,
      current: 0,
      picdata: {
        picList: [],
        total: 0
      },
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      },
      formRule: {
        title: [{
          required: true,
          message: "请输入标题",
          trigger: "blur"
        }, {
          min: 0,
          max: 64,
          message: "长度在 0 到 64 个字符",
          trigger: "blur"
        }],
        content: [{
          required: true,
          message: "请输入内容",
          trigger: "blur"
        }],
        contentSourceUrl: [{
          required: true,
          message: "请输入原文链接",
          trigger: "blur"
        }]
      },
      formdata: {
        title: "",
        //标题
        author: "",
        //作者
        content: "",
        //内容
        digest: "",
        //摘要
        thumbUrl: "",
        // 图片封面URL
        thumbMediaId: "",
        //封面mediaid
        contentSourceUrl: "",
        //原文地址
        showCoverPic: true,
        //是否显示封面，true为显示，false为不显示.
        onlyFansCanComment: "0",
        needOpenComment: "1"
      },
      // 选择图片dia
      chooseFlag: false,
      // 图片分页信息
      picForm: {
        type: "image",
        limit: 10,
        page: 1
      },
      //图文分页信息
      newsForm: {
        type: "news",
        limit: 10,
        page: 1
      },
      newsdata: {
        picList: [],
        total: 0
      }
    };
  },
  created: function created() {
    this.getPhotoList();
  },
  watch: {
    leftData: {
      handler: function handler(val) {
        console.log(val);
      },
      immediate: false,
      deep: true
    }
  },
  methods: {
    handleClick: function handleClick(tab, event) {
      console.log(tab.name);
      if (tab.nmae == "image") {
        this.getPhotoList();
      } else if (tab.name == "news") {
        this.getNewsList();
      }
    },
    handleMsg: function handleMsg(inx) {
      console.log(inx);
      this.current = inx;
      this.formdata = this.leftData[inx];
    },
    //   新增图文
    handleAddNews: function handleAddNews() {
      this.dialogNewsVisible = true;
    },
    dialogNewsClose: function dialogNewsClose() {
      this.dialogNewsVisible = false;
      this.formdata = {};
    },
    // 上传图片
    handleUploadForm: function handleUploadForm(param) {
      // debugger;
      var that = this;
      var formData = new FormData();
      formData.append("media", param.file);
      // console.log(formData, "1234567890-");
      var loading = that.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      // return
      (0, _wxApi.uploadPicApi)(formData, {
        type: "thumb"
      }).then(function (res) {
        console.log(res, "1234589");
        // debugger
        if (that.dialogNewsVisible) {
          that.formdata.thumbUrl = res.url;
          that.formdata.thumbMediaId = res.mediaId;
        } else {
          that.getPhotoList();
        }
        loading.close();
        that.$message.success("上传成功");
      }).catch(function () {
        loading.close();
      });
    },
    handleUploadForm1: function handleUploadForm1(param) {
      console.log(param);
    },
    choosePic: function choosePic() {
      this.chooseFlag = true;
    },
    // 提交图文
    onSubmit: function onSubmit(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          alert("submit!");
          _this.addMsgpic();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addMsgpic: function addMsgpic() {
      var obj = {
        articles: [this.formdata]
      };
      console.log(obj, "12345678");
      (0, _wxApi.addMsgpic)(obj).then(function (res) {
        console.log(res, "1234567");
      });
    },
    // 获取图片列表
    getPhotoList: function getPhotoList() {
      var _this2 = this;
      (0, _wxApi.getPiclist)(this.picForm).then(function (res) {
        console.log(res, "znzxcvbnm");
        _this2.picdata.picList = res.item;
        _this2.picdata.total = res.totalCount;
      });
    },
    // 获取图文列表
    getNewsList: function getNewsList() {
      var _this3 = this;
      (0, _wxApi.getPiclist)(this.newsForm).then(function (res) {
        console.log(res, "znzxcvbnm");
        _this3.newsdata.picList = res.item;
        _this3.newsdata.total = res.totalCount;
      });
    },
    handleSizeChange1: function handleSizeChange1(val) {
      this.picForm.limit = val;
      this.getPhotoList();
    },
    pageChange1: function pageChange1(page) {
      this.picForm.page = page;
      this.getPhotoList();
    },
    handleSizeChange2: function handleSizeChange2(val) {
      this.newsForm.limit = val;
      this.getNewsList();
    },
    pageChange2: function pageChange2(page) {
      this.newsForm.page = page;
      this.getNewsList();
    },
    chooseFlagHandle: function chooseFlagHandle() {
      this.chooseFlag = false;
    },
    // 选择图片
    choosePicitem: function choosePicitem(item) {
      console.log(item, "item");
      this.formdata.thumbMediaId = item.mediaId;
      this.formdata.thumbUrl = item.url;
      this.chooseFlag = false;
    },
    // 删除图片库中的图片
    delMaterial: function delMaterial(id) {
      var _this4 = this;
      console.log(id);

      // return;
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this4.deleteMsgnews(id);
      }).catch(function () {
        _this4.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    // 删除图文
    deleteMsgnews: function deleteMsgnews(id) {
      var _this5 = this;
      (0, _wxApi.deleteMsg)(id).then(function (res) {
        _this5.$message({
          type: "success",
          message: "删除成功!"
        });
        _this5.getPhotoList();
        _this5.getNewsList();
      });
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { attrs: { name: "fileinfo" } }, [
    _c("label", { staticClass: "top" }, [
      _vm._v("请选择图片"),
      _c("input", {
        ref: "file",
        staticStyle: { display: "none" },
        attrs: {
          id: "inpu",
          name: "files",
          type: "file",
          multiple: "",
          accept: "image/*",
        },
        on: {
          change: function ($event) {
            return _vm.fileChange($event)
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
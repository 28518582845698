var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "router-link",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$route.path.indexOf("keyword") !== -1,
                  expression: "$route.path.indexOf('keyword') !== -1",
                },
              ],
              attrs: {
                to: { path: "/appSetting/publicAccount/wxReply/keyword" },
              },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "mr20 mb20",
                  attrs: { size: "mini", icon: "el-icon-back" },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { gutter: 30 },
            },
            [
              _c(
                "el-col",
                _vm._b({ staticClass: "acea-row" }, "el-col", _vm.grid, false),
                [
                  _c("div", { staticClass: "left mb15 ml40" }, [
                    _c("img", {
                      staticClass: "top",
                      attrs: { src: require("@/assets/imgs/mobilehead.png") },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "bottom",
                      attrs: { src: require("@/assets/imgs/mobilefoot.png") },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "centent" }, [
                      _c("div", { staticClass: "time-wrapper" }, [
                        _c("span", { staticClass: "time" }, [_vm._v("9:36")]),
                      ]),
                      _vm._v(" "),
                      _vm.formValidate.type !== "news"
                        ? _c(
                            "div",
                            { staticClass: "view-item text-box clearfix" },
                            [
                              _c("div", { staticClass: "avatar fl" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/imgs/head.gif"),
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "box-content fl" }, [
                                _vm.formValidate.type === "text"
                                  ? _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.formValidate.contents.content
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.formValidate.contents.mediaId
                                  ? _c(
                                      "div",
                                      { staticClass: "box-content_pic" },
                                      [
                                        _vm.formValidate.type === "image"
                                          ? _c("img", {
                                              attrs: {
                                                src: _vm.formValidate.contents
                                                  .srcUrl,
                                              },
                                            })
                                          : _c("i", {
                                              staticClass: "el-icon-service",
                                            }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.type === "news"
                        ? _c("div", [
                            _c("div", { staticClass: "newsBox" }, [
                              _c("div", {
                                staticClass: "news_pic mb15",
                                style: {
                                  backgroundImage:
                                    "url(" +
                                    (_vm.formValidate.contents.articleData
                                      .imageInput
                                      ? _vm.formValidate.contents.articleData
                                          .imageInput[0]
                                      : "") +
                                    ")",
                                  backgroundSize: "100% 100%",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "news_sp" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formValidate.contents.articleData.title
                                  )
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xl: 11, lg: 12, md: 14, sm: 22, xs: 22 } },
                [
                  _c(
                    "div",
                    { staticClass: "box-card right ml50" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "formValidate",
                          staticClass: "mt20",
                          attrs: {
                            model: _vm.formValidate,
                            rules: _vm.ruleValidate,
                            "label-width": "100px",
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _vm.$route.path.indexOf("keyword") !== -1
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "关键字：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "arrbox_ip",
                                    staticStyle: { width: "90%" },
                                    attrs: {
                                      size: "mini",
                                      placeholder: "输入后回车",
                                    },
                                    model: {
                                      value: _vm.formValidate.keywords,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate,
                                          "keywords",
                                          $$v
                                        )
                                      },
                                      expression: "formValidate.keywords",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "规则状态：" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.formValidate.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "status", $$v)
                                    },
                                    expression: "formValidate.status",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("启用"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("禁用"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "消息类型：", prop: "type" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "90%" },
                                  attrs: { placeholder: "请选择规则状态" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.RuleFactor(
                                        _vm.formValidate.type
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.formValidate.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "type", $$v)
                                    },
                                    expression: "formValidate.type",
                                  },
                                },
                                [
                                  _c(
                                    "el-option",
                                    {
                                      attrs: {
                                        label: "文字消息",
                                        value: "text",
                                      },
                                    },
                                    [_vm._v("文字消息")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-option",
                                    {
                                      attrs: {
                                        label: "图片消息",
                                        value: "image",
                                      },
                                    },
                                    [_vm._v("图片消息")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-option",
                                    {
                                      attrs: {
                                        label: "声音消息",
                                        value: "voice",
                                      },
                                    },
                                    [_vm._v("声音消息")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formValidate.type === "text"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "规则内容：",
                                    prop: "content",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "90%" },
                                    attrs: { placeholder: "请填写规则内容" },
                                    on: {
                                      input: function ($event) {
                                        return _vm.change($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.formValidate.contents.content,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate.contents,
                                          "content",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formValidate.contents.content",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formValidate.type === "news"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "选取图文：" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: { click: _vm.changePic },
                                    },
                                    [_vm._v("选择图文消息")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formValidate.type === "image" ||
                          _vm.formValidate.type === "voice"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      _vm.formValidate.type === "image"
                                        ? "图片地址："
                                        : "语音地址：",
                                    prop: "mediaId",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "acea-row row-middle" },
                                    [
                                      _c("el-input", {
                                        staticClass: "mr10",
                                        staticStyle: { width: "75%" },
                                        attrs: {
                                          readonly: "readonly",
                                          placeholder: "default size",
                                        },
                                        model: {
                                          value:
                                            _vm.formValidate.contents.mediaId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate.contents,
                                              "mediaId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formValidate.contents.mediaId",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-upload",
                                        {
                                          staticClass: "upload-demo mr10",
                                          attrs: {
                                            action: "",
                                            "http-request":
                                              _vm.handleUploadForm,
                                            headers: _vm.myHeaders,
                                            "show-file-list": false,
                                            multiple: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                              },
                                            },
                                            [_vm._v("点击上传")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.formValidate.type === "image",
                                          expression:
                                            "formValidate.type === 'image'",
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        "文件最大2Mb，支持bmp/png/jpeg/jpg/gif格式"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.formValidate.type === "voice",
                                          expression:
                                            "formValidate.type === 'voice'",
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        "文件最大2Mb，支持mp3/wma/wav/amr格式,播放长度不超过60s"
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "acea-row row-center" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "ml50",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.submenus("formValidate")
                              },
                            },
                          },
                          [_vm._v("保存并发布\n            ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applyBalanceApi = applyBalanceApi;
exports.applyListApi = applyListApi;
exports.applyStatusApi = applyStatusApi;
exports.applyUpdateApi = applyUpdateApi;
exports.balanceApi = balanceApi;
exports.brokerageListApi = brokerageListApi;
exports.monitorListApi = monitorListApi;
exports.monitorListDetailApi = monitorListDetailApi;
exports.monitorListOptionApi = monitorListOptionApi;
exports.refundApi = refundApi;
exports.topUpLogDeleteApi = topUpLogDeleteApi;
exports.topUpLogListApi = topUpLogListApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 提现申请 列表
 * @param pram
 */
function applyListApi(params) {
  return (0, _request.default)({
    url: '/admin/finance/apply/list',
    method: 'get',
    params: params
  });
}

/**
 * 提现申请 金额
 * @param pram
 */
function applyBalanceApi(params) {
  return (0, _request.default)({
    url: '/admin/finance/apply/balance',
    method: 'post',
    params: params
  });
}

/**
 * 提现申请 修改
 * @param pram
 */
function applyUpdateApi(params) {
  return (0, _request.default)({
    url: '/admin/finance/apply/update',
    method: 'post',
    params: params
  });
}

/**
 * 提现申请 审核
 * @param pram
 */
function applyStatusApi(params, data) {
  return (0, _request.default)({
    url: '/admin/finance/apply/apply',
    method: 'post',
    params: params,
    data: data
  });
}

/**
 * 充值 列表
 * @param pram
 */
function topUpLogListApi(params) {
  return (0, _request.default)({
    url: '/admin/user/topUpLog/list',
    method: 'get',
    params: params
  });
}

/**
 * 充值 金额
 * @param pram
 */
function balanceApi() {
  return (0, _request.default)({
    url: '/admin/user/topUpLog/balance',
    method: 'post'
  });
}

/**
 * 充值 删除
 * @param pram
 */
function topUpLogDeleteApi(params) {
  return (0, _request.default)({
    url: '/admin/user/topUpLog/delete',
    method: 'get',
    params: params
  });
}

/**
 * 充值 退款
 * @param pram
 */
function refundApi(data) {
  return (0, _request.default)({
    url: '/admin/user/topUpLog/refund',
    method: 'post',
    data: data
  });
}

/**
 * 资金监控 列表
 * @param pram
 */
function monitorListApi(params) {
  return (0, _request.default)({
    url: '/admin/finance/founds/monitor/list',
    method: 'get',
    params: params
  });
}

/**
 * 资金监控 佣金详细记录
 * @param pram
 */
function monitorListDetailApi(userId, params) {
  return (0, _request.default)({
    url: "/admin/finance/founds/monitor/list/user/detail/".concat(userId),
    method: 'get',
    params: params
  });
}

/**
 * 资金监控 明细类型
 * @param pram
 */
function monitorListOptionApi() {
  return (0, _request.default)({
    url: "/admin/finance/founds/monitor/list/option",
    method: 'get'
  });
}

/**
 * 佣金记录 列表
 * @param pram
 */
function brokerageListApi(params) {
  return (0, _request.default)({
    url: '/admin/finance/founds/monitor/list/user',
    method: 'get',
    params: params
  });
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "formDynamic",
          staticClass: "attrFrom mb20",
          attrs: {
            size: "small",
            model: _vm.formDynamic,
            rules: _vm.rules,
            "label-width": "100px",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格名称：", prop: "ruleName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "20",
                          placeholder: "请输入标题名称",
                        },
                        model: {
                          value: _vm.formDynamic.ruleName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formDynamic, "ruleName", $$v)
                          },
                          expression: "formDynamic.ruleName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.formDynamic.ruleValue, function (item, index) {
                return _c(
                  "el-col",
                  { key: index, staticClass: "noForm", attrs: { span: 24 } },
                  [
                    _c("el-form-item", [
                      _c(
                        "div",
                        { staticClass: "acea-row row-middle" },
                        [
                          _c("span", { staticClass: "mr5" }, [
                            _vm._v(_vm._s(item.value)),
                          ]),
                          _c("i", {
                            staticClass: "el-icon-circle-close",
                            on: {
                              click: function ($event) {
                                return _vm.handleRemove(index)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "input-new-tag",
                            staticStyle: { width: "60%" },
                            attrs: { size: "small" },
                            model: {
                              value: item.attrDesc,
                              callback: function ($$v) {
                                _vm.$set(item, "attrDesc", $$v)
                              },
                              expression: "item.attrDesc",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "rulesBox" },
                        [
                          _vm._l(item.detail, function (j, indexn) {
                            return _c(
                              "el-tag",
                              {
                                key: indexn,
                                staticClass: "mb5 mr10",
                                attrs: {
                                  closable: "",
                                  size: "medium",
                                  "disable-transitions": false,
                                },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(item.detail, indexn)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(j) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          item.inputVisible
                            ? _c("el-input", {
                                ref: "saveTagInput",
                                refInFor: true,
                                staticClass: "input-new-tag",
                                attrs: { size: "small" },
                                on: {
                                  blur: function ($event) {
                                    return _vm.createAttr(
                                      item.detail.attrsVal,
                                      index
                                    )
                                  },
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.createAttr(
                                      item.detail.attrsVal,
                                      index
                                    )
                                  },
                                },
                                model: {
                                  value: item.detail.attrsVal,
                                  callback: function ($$v) {
                                    _vm.$set(item.detail, "attrsVal", $$v)
                                  },
                                  expression: "item.detail.attrsVal",
                                },
                              })
                            : _c(
                                "el-button",
                                {
                                  staticClass: "button-new-tag",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showInput(item)
                                    },
                                  },
                                },
                                [_vm._v("+ 添加")]
                              ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "picture-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("2", index)
                              },
                            },
                          },
                          [
                            item.attrPopupImage
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: { src: item.attrPopupImage },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        item.attrPopupImage
                          ? _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.handlePic("2", index)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm.isBtn
                ? _c(
                    "el-col",
                    {
                      staticClass: "mt10",
                      staticStyle: {
                        "padding-left": "0",
                        "padding-right": "0",
                      },
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "规格：" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入规格" },
                                model: {
                                  value: _vm.attrsName,
                                  callback: function ($$v) {
                                    _vm.attrsName = $$v
                                  },
                                  expression: "attrsName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "规格注释：" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入规格注释" },
                                model: {
                                  value: _vm.attrDesc,
                                  callback: function ($$v) {
                                    _vm.attrDesc = $$v
                                  },
                                  expression: "attrDesc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("el-form-item", { attrs: { label: "帮我选择" } }, [
                            _c("div", { staticClass: "picture-box" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap("1")
                                    },
                                  },
                                },
                                [
                                  _vm.attrPopupImage
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: { src: _vm.attrPopupImage },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                  _vm._v(" "),
                                  _vm.attrPopupImage
                                    ? _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handlePic("1")
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "规格值：" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入规格值" },
                                model: {
                                  value: _vm.attrsVal,
                                  callback: function ($$v) {
                                    _vm.attrsVal = $$v
                                  },
                                  expression: "attrsVal",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "mr10",
                              attrs: { type: "primary" },
                              on: { click: _vm.createAttrName },
                            },
                            [_vm._v("确定")]
                          ),
                          _vm._v(" "),
                          _c("el-button", { on: { click: _vm.offAttrName } }, [
                            _vm._v("取消"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.spinShow
                ? _c("Spin", { attrs: { size: "large", fix: "" } })
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          !_vm.isBtn
            ? _c(
                "el-button",
                {
                  staticClass: "ml40 mt10",
                  attrs: { type: "primary", icon: "md-add" },
                  on: { click: _vm.addBtn },
                },
                [_vm._v("添加新规格")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "footer acea-row" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resetForm("formDynamic")
                },
              },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loadingBtn },
              on: {
                click: function ($event) {
                  return _vm.handleSubmit("formDynamic")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
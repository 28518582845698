"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var mousePosition = {
  left: -1,
  top: -1
};
var _default = exports.default = {
  components: {
    draggable: _vuedraggable.default
  },
  data: function data() {
    return {
      activeNames: "1",
      // draggable配置参数参考 https://www.cnblogs.com/weixin186/p/10108679.html
      draggableOptions: {
        preventOnFilter: false,
        sort: false,
        disabled: false,
        ghostClass: "tt",
        // 不使用H5原生的配置
        forceFallback: true
        // 拖拽的时候样式
        // fallbackClass: 'flow-node-draggable'
      },
      // 默认打开的左侧菜单的id
      defaultOpeneds: ["1", "2", "3"],
      menuList: [{
        id: "1",
        type: "group",
        name: "营销动作",
        open: true,
        children: [{
          id: "11",
          type: "message",
          name: "短信发送",
          ico: "market-msg",
          icon: "el-icon-edit",
          // 自定义覆盖样式
          style: {}
        }, {
          id: "12",
          type: "coupons",
          name: "优惠券发送",
          ico: "market-coupons",
          icon: "el-icon-edit",
          style: {}
        }, {
          id: "13",
          type: "except",
          name: "邮件发送",
          ico: "market-copy",
          icon: "el-icon-edit",
          style: {}
        }]
      }, {
        id: "21",
        type: "group",
        name: "逻辑操作",
        open: true,
        children: [{
          id: "44",
          type: "exclusion",
          name: "用户筛选",
          ico: "market-user",
          icon: "el-icon-edit",
          style: {}
        },
        // {
        //   id: "42",
        //   type: "event",
        //   name: "事件分支",
        //   ico: "market-union",
        //   icon: "el-icon-edit",
        //   style: {},
        // },
        // {
        //   id: "21",
        //   type: "timer",
        //   name: "时间分支",
        //   ico: "market-timer",
        //   icon: "el-icon-edit",
        //   style: {},
        // },
        // {
        //   id: "23",
        //   type: "copy",
        //   name: "复制分支",
        //   ico: "market-copy",
        //   icon: "el-icon-edit",
        //   style: {},
        // },
        {
          id: "24",
          type: "random",
          name: "分流器",
          ico: "market-random",
          icon: "el-icon-edit",
          style: {}
        }]
      },
      // {
      //   id: "4",
      //   type: "group",
      //   name: "逻辑操作",
      //   ico: "+",
      //   open: true,
      //   children: [
      //     {
      //       id: "41",
      //       type: "merge",
      //       name: "合并",
      //       ico: "market-merge",
      //       icon: "el-icon-edit",
      //       style: {},
      //     },
      //     {
      //       id: "42",
      //       type: "union",
      //       name: "交集",
      //       ico: "market-union",
      //       icon: "el-icon-edit",
      //       style: {},
      //     },
      //     {
      //       id: "43",
      //       type: "except",
      //       name: "差集",
      //       ico: "market-except",
      //       icon: "el-icon-edit",
      //       style: {},
      //     },
      //     {
      //       id: "44",
      //       type: "exclusion",
      //       name: "排重",
      //       ico: "market-exclusion",
      //       icon: "el-icon-edit",
      //       style: {},
      //     },
      //   ],
      // },
      {
        id: "4",
        type: "group",
        name: "数据操作",
        open: true,
        children: [{
          id: "42",
          type: "union",
          name: "修改属性",
          ico: "market-union",
          icon: "el-icon-edit",
          style: {}
        }, {
          id: "43",
          type: "wait",
          name: "编辑群组",
          ico: "market-except",
          icon: "el-icon-edit",
          style: {}
        }, {
          id: "40",
          type: "lable",
          name: "编辑标签",
          ico: "market-lable",
          icon: "el-icon-edit",
          style: {}
        }]
      }
      // {
      //   id: "3",
      //   type: "group",
      //   name: "流程控制",
      //   ico: "+",
      //   open: true,
      //   children: [
      //     {
      //       id: "32",
      //       type: "end",
      //       name: "流程结束",
      //       ico: "market-end",
      //       icon: "el-icon-edit",
      //       style: {},
      //     },
      //     {
      //       id: "33",
      //       type: "wait",
      //       name: "等待",
      //       ico: "market-wait",
      //       icon: "el-icon-edit",
      //       style: {},
      //     },
      //   ],
      // },
      ],
      nodeMenu: {}
    };
  },
  created: function created() {
    /**
     * 以下是为了解决在火狐浏览器上推拽时弹出tab页到搜索问题
     * @param event
     */
    if (this.isFirefox()) {
      document.body.ondrop = function (event) {
        // 解决火狐浏览器无法获取鼠标拖拽结束的坐标问题
        mousePosition.left = event.layerX;
        mousePosition.top = event.clientY - 50;
        event.preventDefault();
        event.stopPropagation();
      };
    }
  },
  methods: {
    // 根据类型获取左侧菜单对象
    getMenuByType: function getMenuByType(type) {
      for (var i = 0; i < this.menuList.length; i++) {
        var children = this.menuList[i].children;
        for (var j = 0; j < children.length; j++) {
          if (children[j].type === type) {
            return children[j];
          }
        }
      }
    },
    // 拖拽开始时触发
    move: function move(evt, a, b, c) {
      var type = evt.item.attributes.type.nodeValue;
      this.nodeMenu = this.getMenuByType(type);
    },
    // 拖拽结束时触发
    end: function end(evt, e) {
      // console.log(e, "拖拽结束时触发");
      this.$emit("addNode", evt, this.nodeMenu, mousePosition);
    },
    // 是否是火狐浏览器
    isFirefox: function isFirefox() {
      var userAgent = navigator.userAgent;
      if (userAgent.indexOf("Firefox") > -1) {
        return true;
      }
      return false;
    }
  }
};
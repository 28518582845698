var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-dialog", { attrs: { title: "访问数据", width: "70%" } }, [
        _c("div", [_vm._v(_vm._s(_vm.accessListObj.name))]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
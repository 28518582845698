"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var serviceApi = _interopRequireWildcard(require("@/api/inspection"));
var _detail = _interopRequireDefault(require("./components/detail.vue"));
var _options = require("./options.js");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    detail: _detail.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        0: "待付款",
        1: "待组团",
        3: '付款关闭',
        8: "组团失败",
        9: "组团成功",
        4: "退款成功"
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      constants: this.$constants,
      listPram: {
        keyword: "",
        page: 1,
        limit: this.$constants.page.limit[0],
        startTime: "",
        endTime: "",
        serviceAddress: "",
        combinationStatus: ""
      },
      listData: {
        list: [],
        total: 0
      },
      timeVal: [],
      addressOptions: _options.addressOptions,
      Oprops: _options.Oprops,
      statusList: _options.statusList
    };
  },
  mounted: function mounted() {
    this.handerSearch();
  },
  created: function created() {},
  methods: {
    uploadMoney: function uploadMoney(info, tit) {
      var that = this;
      this.$modalUpload(function (img) {
        // console.log(info, img[0].sattDir);
        var params = {
          id: info.id,
          invoiceUrl: img[0].sattDir
        };
        serviceApi.uploadMoneyBookApi(params).then(function (res) {
          that.$message.success("上传成功");
        });
      }, tit, "content");
    },
    handleChange: function handleChange(e) {
      console.log(e);
      this.listPram.serviceAddress = e.join(",");
      this.listPram.page = 1;
      this.handlerGetListData(this.listPram);
    },
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      if (this.timeVal) {
        this.listPram.startTime = this.timeVal[0];
        this.listPram.endTime = this.timeVal[1];
      } else {
        this.listPram.startTime = "";
        this.listPram.endTime = "";
      }
      this.listPram.page = 1;
      this.handlerGetListData(this.listPram);
    },
    seedetail: function seedetail(row) {
      this.$refs.detailRef.dialogVisible = true;
      this.$refs.detailRef.getOrderDetail(row.id);
    },
    handerSearch: function handerSearch() {
      this.listPram.page = 1;
      this.handlerGetListData(this.listPram);
    },
    handlerGetListData: function handlerGetListData(prams) {
      var _this = this;
      serviceApi.serviceOrderApi(prams).then(function (res) {
        _this.listData = res;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetListData(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetListData(this.listPram);
    },
    exportOrder: function exportOrder() {
      var data = {};
      serviceApi.exportOrderApi(data).then(function (res) {
        window.open(res.fileName);
      });
    }
  }
};
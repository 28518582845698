var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: { model: _vm.ruleForm, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "直播标题",
                    prop: "liveTitle",
                    rules: [
                      {
                        required: true,
                        message: "请输入直播标题",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "推荐输入13个字，超出一行显示..." },
                    model: {
                      value: _vm.ruleForm.liveTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "liveTitle", $$v)
                      },
                      expression: "ruleForm.liveTitle",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "直播副标题",
                    prop: "liveSubtitle",
                    rules: [
                      {
                        required: true,
                        message: "请输入直播副标题",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "推荐输入13个字，超出一行显示...",
                    },
                    model: {
                      value: _vm.ruleForm.liveSubtitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "liveSubtitle", $$v)
                      },
                      expression: "ruleForm.liveSubtitle",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "直播分类：",
                    prop: "liveCategoryId",
                    rules: [
                      {
                        required: true,
                        message: "请选择直播分类",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("el-cascader", {
                    ref: "cascader",
                    staticClass: "selWidth",
                    attrs: {
                      options: _vm.merCateList,
                      clearable: "",
                      props: _vm.props2,
                      "show-all-levels": false,
                      "node-key": "id",
                    },
                    model: {
                      value: _vm.ruleForm.liveCategoryId,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "liveCategoryId", $$v)
                      },
                      expression: "ruleForm.liveCategoryId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "直播搜索关键词", prop: "searchKeywords" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "多个关键词使用 ' , ' 隔开" },
                    model: {
                      value: _vm.ruleForm.searchKeywords,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "searchKeywords", $$v)
                      },
                      expression: "ruleForm.searchKeywords",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "后台标签",
                    prop: "clLabel",
                    rules: [
                      {
                        required: true,
                        message: "请填写标签",
                        trigger: ["blur", "change"],
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "仅传输回convertlab，以英文逗号隔开",
                      maxlength: "255",
                    },
                    model: {
                      value: _vm.ruleForm.clLabel,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "clLabel", $$v)
                      },
                      expression: "ruleForm.clLabel",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "视频类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.$route.params.id ? true : false },
                      model: {
                        value: _vm.ruleForm.videoType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "videoType", $$v)
                        },
                        expression: "ruleForm.videoType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("回放"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("直播"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.videoType == 1
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "des-info demo-like",
                              attrs: { placement: "bottom" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [_vm._v("非直播视频可不填写开始/结束时间。")]
                              ),
                              _vm._v(" "),
                              _c("i", { staticClass: "el-icon-question" }),
                            ]
                          ),
                          _vm._v("\n          直播时间:"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        attrs: {
                          disabled: _vm.$route.params.id ? true : false,
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.value1,
                          callback: function ($$v) {
                            _vm.value1 = $$v
                          },
                          expression: "value1",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "直播/回放链接",
                    prop: "liveUrl",
                    rules: [
                      {
                        required: true,
                        message: "请输入直播/回放链接",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.liveUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "liveUrl", $$v)
                      },
                      expression: "ruleForm.liveUrl",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "直播间ID",
                    prop: "vhallLiveId",
                    rules: [
                      {
                        required: true,
                        message: "请输入直播ID",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.vhallLiveId,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "vhallLiveId", $$v)
                      },
                      expression: "ruleForm.vhallLiveId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "banner图",
                    prop: "bannerUrl",
                    rules: [
                      {
                        required: true,
                        message: "请上传banner图",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1", "bannerImage")
                        },
                      },
                    },
                    [
                      _vm.ruleForm.bannerUrl
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: { src: _vm.ruleForm.bannerUrl },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "#999" } }, [
                        _vm._v("(推荐尺寸：750 X 360像素)"),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "列表图",
                    prop: "imageUrl",
                    rules: [
                      {
                        required: true,
                        message: "请上传列表图",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1", "ListImage")
                        },
                      },
                    },
                    [
                      _vm.ruleForm.imageUrl
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: { src: _vm.ruleForm.imageUrl },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "#999" } }, [
                        _vm._v("(推荐尺寸：250 X 250像素)"),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "直播详情" } },
                [
                  _c("ueditor-from", {
                    attrs: { content: _vm.ruleForm.liveInfo },
                    model: {
                      value: _vm.ruleForm.liveInfo,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "liveInfo", $$v)
                      },
                      expression: "ruleForm.liveInfo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否banner", prop: "isBanner" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.ruleForm.isBanner,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "isBanner", $$v)
                      },
                      expression: "ruleForm.isBanner",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "des-info demo-like",
                      attrs: { placement: "bottom" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [_vm._v("直播列表页展示，最多可配置5张")]
                      ),
                      _vm._v(" "),
                      _c("i", { staticClass: "el-icon-question" }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "留资标题",
                    prop: "stayTitle",
                    rules: [
                      {
                        required: false,
                        message: "请填写留资标题",
                        trigger: ["blur", "change"],
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "表单头图标题，最多可输入7个字",
                      maxlength: "7",
                    },
                    model: {
                      value: _vm.ruleForm.stayTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "stayTitle", $$v)
                      },
                      expression: "ruleForm.stayTitle",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "留资头图",
                    prop: "stayHeadImage",
                    rules: [
                      {
                        required: false,
                        message: "请上传图文封面",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTapStay("1")
                        },
                      },
                    },
                    [
                      _vm.ruleForm.stayHeadImage
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: { src: _vm.ruleForm.stayHeadImage },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "#999" } }, [
                        _vm._v("(推荐尺寸：380 X 750像素)"),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$route.params.id ? "保存" : "立即创建"))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
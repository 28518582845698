"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _addPoint = _interopRequireDefault(require("./addPoint"));
var _storePoint = require("@/api/storePoint");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Point',
  components: {
    systemStore: _addPoint.default
  },
  data: function data() {
    return {
      artFrom: {
        page: 1,
        limit: 20,
        status: '1',
        keywords: ''
      },
      loading: false,
      tableData: [],
      total: 0,
      headerCount: {}
    };
  },
  created: function created() {
    this.storeGetCount();
    this.tableList();
  },
  methods: {
    //头部数量显示；
    storeGetCount: function storeGetCount() {
      var that = this;
      (0, _storePoint.storeGetCountApi)().then(function (res) {
        that.headerCount = res;
      });
    },
    //表格列表
    tableList: function tableList() {
      var that = this;
      that.loading = true;
      (0, _storePoint.storeListApi)(that.artFrom).then(function (res) {
        that.loading = false;
        that.tableData = res.list;
        that.total = res.total;
      });
    },
    //切换页数
    pageChange: function pageChange(index) {
      this.artFrom.page = index;
      this.tableList();
    },
    //切换显示条数
    sizeChange: function sizeChange(index) {
      this.artFrom.limit = index;
      this.tableList();
    },
    //头部切换
    onClickTab: function onClickTab() {
      this.artFrom.keywords = '';
      this.tableList();
    },
    //搜索
    search: function search() {
      this.artFrom.page = 1;
      this.tableList();
    },
    //是否显示
    onchangeIsShow: function onchangeIsShow(id, isShow) {
      var that = this;
      (0, _storePoint.storeUpdateStatusApi)({
        id: id,
        status: isShow
      }).then(function () {
        that.$message.success("操作成功");
        that.tableList();
        that.storeGetCount();
      }).catch(function () {
        row.isShow = !row.isShow;
      });
    },
    // 恢复
    storeRecovery: function storeRecovery(id) {
      var _this = this;
      this.$modalSure('恢复提货吗').then(function () {
        (0, _storePoint.storeRecoveryApi)({
          id: id
        }).then(function () {
          _this.$message.success('恢复成功');
          _this.storeGetCount();
          _this.tableList();
        });
      });
    },
    //刪除
    storeDelete: function storeDelete(id) {
      var that = this;
      that.$modalSure('删除提货点吗？').then(function () {
        (0, _storePoint.storeDeleteApi)({
          id: id
        }).then(function () {
          that.$message.success('删除成功');
          that.storeGetCount();
          that.tableList();
        });
      });
    },
    allDelete: function allDelete(id) {
      var _this2 = this;
      this.$modalSure().then(function () {
        (0, _storePoint.allDeleteApi)({
          id: id
        }).then(function () {
          _this2.$message.success('删除成功');
          _this2.storeGetCount();
          _this2.tableList();
        });
      });
    },
    //添加
    add: function add() {
      this.$refs.template.dialogFormVisible = true;
    },
    //编辑
    edit: function edit(id) {
      this.$refs.template.dialogFormVisible = true;
      this.$refs.template.getInfo(id);
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddForm = AddForm;
exports.addFormApi = addFormApi;
exports.deleteDefForm = deleteDefForm;
exports.deleteFormApi = deleteFormApi;
exports.editForm = editForm;
exports.editStatus = editStatus;
exports.editStatusApi = editStatusApi;
exports.exportForm = exportForm;
exports.formList = formList;
exports.getEditFormApi = getEditFormApi;
exports.getFormListApi = getFormListApi;
exports.getInfo = getInfo;
exports.getformInfoApi = getformInfoApi;
exports.importFormApi = importFormApi;
exports.postForm = postForm;
var _request = _interopRequireDefault(require("@/utils/request"));
var _qs = _interopRequireDefault(require("qs"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/*
 * @Author: Zcl84765
 * @Date: 2023-02-20 14:17:04
 * @LastEditors: Zcl84765
 * @LastEditTime: 2023-03-02 14:20:56
 */

/**
 * 详情
 * @param
 * @constructor
 */
function getInfo(params) {
  return (0, _request.default)({
    url: "/admin/custom/form/getInfo",
    method: "get",
    params: params
  });
}

/**
 * 列表
 */
function formList(params) {
  return (0, _request.default)({
    url: "/admin/custom/form/list",
    method: "GET",
    params: params
  });
}

/**
 * 新增
 */
function AddForm(data) {
  return (0, _request.default)({
    url: "/admin/custom/form/add",
    method: "post",
    data: data
  });
}

/**
 * 更新
 */
function editForm(data) {
  return (0, _request.default)({
    url: "/admin/custom/form/edit",
    method: "post",
    data: data
  });
}

/**
 * 删除
 */
function deleteDefForm(params) {
  return (0, _request.default)({
    url: "/admin/custom/form/delete",
    method: "get",
    params: params
  });
}

/**
 * 开启/关闭
 */
function editStatus(params) {
  return (0, _request.default)({
    url: "/admin/custom/form/editStatus",
    method: "get",
    params: params
  });
}

/**
 * 导出
 * @param id
 * @constructor
 */
function exportForm(params) {
  return (0, _request.default)({
    url: "/admin/custom/form/exportForm",
    // url: `/admin/export/excel/download`,
    method: "get",
    params: params
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    // },
    // responseType: "blob"
  });
}

/**
 * 获取文件流
 * @param id
 * @constructor
 */
// export function postForm(params) {
//   return request({
//     url: `/admin/export/excel/download`,
//     // url: `/admin/export/excel/download`,
//     method: "post",
//     params,
//     responseType: "blob",

//   });
// }
function postForm(params) {
  return (0, _request.default)({
    url: "/admin/export/excel/download",
    method: "post",
    data: params,
    responseType: "blob",
    // responseType: "arraybuffer",
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // },
    // headers: {
    //   'Content-Type': "multipart/form-data",
    //   'dataType': "file"
    // },
    transformRequest: [function (data) {
      return _qs.default.stringify(data);
    }]
  });
}

/**
 *
 * @param {*} params  获取表单列表
 * @returns
 */

function getFormListApi(params) {
  return (0, _request.default)({
    url: "/admin/custom/newForm/list",
    method: "get",
    params: params
  });
}

/**
 *
 * @param {*} params 获取详情
 * @returns
 */
function getformInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/custom/newForm/getInfo",
    method: "get",
    params: params
  });
}

/**
 *
 * @param {*} data 表单编辑
 * @returns
 */
function getEditFormApi(data) {
  return (0, _request.default)({
    url: "/admin/custom/newForm/edit",
    method: "post",
    data: data
  });
}

/**
 *
 * @param {*} params   删除表单
 * @returns
 */
function deleteFormApi(params) {
  return (0, _request.default)({
    url: "/admin/custom/newForm/delete",
    method: "get",
    params: params
  });
}

/**
 *
 * @param {*} params   导出表单
 * @returns
 */
function importFormApi(params) {
  return (0, _request.default)({
    url: "/admin/custom/newForm/exportForm",
    method: "get",
    params: params
  });
}

/**
 *
 * @param {*} data 新增表单
 * @returns
 */
function addFormApi(data) {
  return (0, _request.default)({
    url: "/admin/custom/newForm/add",
    method: "post",
    data: data
  });
}

/**
 *
 * @param {*} params 修改状态
 * @returns
 */
function editStatusApi(params) {
  return (0, _request.default)({
    url: "/admin/custom/newForm/editStatus",
    method: "get",
    params: params
  });
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _c("el-col", _vm._b({}, "el-col", _vm.grid, false), [
            _c("div", { staticClass: "Nav" }, [
              _c(
                "div",
                { staticClass: "input" },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "选择分类",
                      "prefix-icon": "el-icon-search",
                      clearable: "",
                    },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "trees-coadd" }, [
                _c("div", { staticClass: "scollhide" }, [
                  _c(
                    "div",
                    {
                      staticClass: "trees",
                      style: {
                        maxHeight: !_vm.pictureType ? "345px" : "700px",
                      },
                    },
                    [
                      _c("el-tree", {
                        ref: "tree",
                        attrs: {
                          data: _vm.treeData2,
                          "filter-node-method": _vm.filterNode,
                          props: _vm.defaultProps,
                          "highlight-current": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var node = ref.node
                              var data = ref.data
                              return _c(
                                "div",
                                {
                                  staticClass: "custom-tree-node",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleNodeClick(data)
                                    },
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "custom-tree-node-label",
                                        attrs: { title: node.label },
                                      },
                                      [_vm._v(_vm._s(node.label))]
                                    ),
                                    _vm._v(" "),
                                    data.space_property_name
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "11px",
                                              color: "#3889b1",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "（" + _vm._s(data.name) + "）"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "el-ic" },
                                    [
                                      _c(
                                        "el-dropdown",
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "el-dropdown-link" },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-more",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              _c(
                                                "el-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.onAdd(data.id)
                                                    },
                                                  },
                                                },
                                                [_vm._v("添加分类")]
                                              ),
                                              _vm._v(" "),
                                              node.label !== "全部分类"
                                                ? _c(
                                                    "el-dropdown-item",
                                                    {
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onEdit(
                                                            data.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("编辑分类")]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              node.label !== "全部分类"
                                                ? _c(
                                                    "el-dropdown-item",
                                                    {
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleDelete(
                                                            data.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除分类")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            _vm._b({ staticClass: "colLeft" }, "el-col", _vm.grid2, false),
            [
              _c("div", { staticClass: "conter mb15" }, [
                _c(
                  "div",
                  { staticClass: "bnt" },
                  [
                    !_vm.pictureType
                      ? _c(
                          "el-button",
                          {
                            staticClass: "mr20 mb20",
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.checkPics },
                          },
                          [_vm._v("使用选中图片")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mr20 mb20" },
                      [
                        _c(
                          "el-button-group",
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "上传图片",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c(
                                  "el-upload",
                                  {
                                    ref: "imageListUpload",
                                    staticClass: "upload-demo",
                                    attrs: {
                                      action: "",
                                      "http-request": _vm.handleUploadForm,
                                      "on-change": _vm.imgSaveToUrl,
                                      headers: _vm.myHeaders,
                                      "show-file-list": true,
                                      "on-success": _vm.handleSuccess,
                                      multiple: "",
                                      accept:
                                        ".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.GIF",
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticStyle: { "font-size": "15px" },
                                      attrs: {
                                        icon: "el-icon-upload2",
                                        size: "mini",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "添加分类",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticStyle: { "font-size": "15px" },
                                  attrs: {
                                    icon: "el-icon-circle-plus-outline",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onAdd(10000)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "删除图片",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticStyle: { "font-size": "15px" },
                                  attrs: {
                                    icon: "el-icon-delete",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.editPicList("图片")
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "mb20",
                        attrs: { placeholder: "图片移动至", size: "small" },
                        model: {
                          value: _vm.sleOptions.attachment_category_name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.sleOptions,
                              "attachment_category_name",
                              $$v
                            )
                          },
                          expression: "sleOptions.attachment_category_name",
                        },
                      },
                      [
                        _c(
                          "el-option",
                          {
                            staticClass: "demo",
                            staticStyle: {
                              "max-width": "560px",
                              height: "200px",
                              overflow: "auto",
                              "background-color": "#fff",
                            },
                            attrs: {
                              label: _vm.sleOptions.attachment_category_name,
                              value: _vm.sleOptions.attachment_category_id,
                            },
                          },
                          [
                            _c("el-tree", {
                              ref: "tree2",
                              attrs: {
                                data: _vm.treeData2,
                                "filter-node-method": _vm.filterNode,
                                props: _vm.defaultProps,
                                "highlight-current": "",
                              },
                              on: { "node-click": _vm.handleSelClick },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "pictrueList acea-row" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShowPic,
                          expression: "isShowPic",
                        },
                      ],
                      staticClass: "imagesNo",
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-picture",
                        staticStyle: {
                          "font-size": "60px",
                          color: "rgb(219, 219, 219)",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "imagesNo_sp" }, [
                        _vm._v("图片库为空"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "conters scrollbarAll",
                      style: {
                        maxHeight: !_vm.pictureType ? "296px" : "700px",
                      },
                    },
                    _vm._l(_vm.pictrueList.list, function (item, index) {
                      return _c("div", { key: index, staticClass: "gridPic" }, [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: item.sattDir ? item.sattDir : _vm.localImg,
                              expression:
                                "item.sattDir ? item.sattDir : localImg",
                            },
                          ],
                          class: item.isSelect ? "on" : "",
                          on: {
                            click: function ($event) {
                              return _vm.changImage(
                                item,
                                index,
                                _vm.pictrueList.list
                              )
                            },
                          },
                        }),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": !_vm.pictureType
                        ? [10, 20, 30, 40]
                        : [30, 60, 90, 120],
                      "page-size": _vm.tableData.limit,
                      "current-page": _vm.tableData.page,
                      "pager-count": 5,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pictrueList.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.bizTitle,
            visible: _vm.visible,
            "destroy-on-close": "",
            "close-on-click-modal": false,
            modal: _vm.modals,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: _vm.closeModel,
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "editPram",
              attrs: { model: _vm.editPram, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "上级分类",
                    prop: "pid",
                    rules: [
                      {
                        type: "number",
                        required: true,
                        message: "请选择上级分类",
                        trigger: ["blur", "change"],
                      },
                    ],
                  },
                },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: { options: _vm.treeData2, props: _vm.categoryProps },
                    model: {
                      value: _vm.editPram.pid,
                      callback: function ($$v) {
                        _vm.$set(_vm.editPram, "pid", $$v)
                      },
                      expression: "editPram.pid",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分类名称",
                    prop: "name",
                    rules: [
                      {
                        required: true,
                        message: "请输入分类名称",
                        trigger: ["blur", "change"],
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "分类名称" },
                    model: {
                      value: _vm.editPram.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.editPram, "name", $$v)
                      },
                      expression: "editPram.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input-number", {
                    model: {
                      value: _vm.editPram.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.editPram, "sort", $$v)
                      },
                      expression: "editPram.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handlerSubmit("editPram")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
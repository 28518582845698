"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "MessageForm",
  data: function data() {
    return {
      templateList: [{
        label: "520短信模板",
        value: "1"
      }, {
        label: "618短信模板",
        value: "2"
      }, {
        label: "双11短信模板",
        value: "3"
      }],
      signList: [{
        label: "百胜软件",
        value: "1"
      }, {
        label: "百胜营销",
        value: "2"
      }],
      form: _defineProperty(_defineProperty({
        name: "",
        // 节点名称
        type: "1",
        // 短信类型
        templateId: "",
        // 短信模板
        sign: "",
        // 短信签名
        content: "",
        // 短信内容
        phone: ""
      }, "type", "action"), "actionType", "sms"),
      rules: {
        templateId: [{
          required: true,
          message: "请选择短信模板",
          trigger: "change"
        }],
        sign: [{
          required: true,
          message: "请选择短信签名",
          trigger: "change"
        }],
        content: [{
          required: true,
          message: "请填写短信内容",
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    init: function init(params) {
      console.log(params, "params");
      var initForm = _defineProperty(_defineProperty({
        type: "1",
        templateId: "",
        // 短信模板
        sign: "",
        // 短信签名
        content: "",
        // 短信签名
        phone: ""
      }, "type", "action"), "actionType", "sms");
      console.log(initForm, "initForm");
      this.form = _objectSpread(_objectSpread({}, initForm), params);
      console.log(this.form, "form");
    },
    previewContent: function previewContent() {
      var message = "".concat(this.form.content, "\u3010\u80DC\u5238\u3011\u9000\u8BA2TD");
      this.$alert(message);
    },
    testConnect: function testConnect() {
      if (!/^1[3456789]\d{9}$/.test(this.form.phone)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "error"
        });
        return false;
      }
      this.$message({
        message: "发送成功",
        type: "success"
      });
    }
  }
};
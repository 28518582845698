"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var defFormApi = _interopRequireWildcard(require("@/api/defForm.js"));
var _myModel = _interopRequireDefault(require("./myModel.vue"));
var _channel = require("@/api/channel");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "defFormEdit",
  components: {
    MyModel: _myModel.default
  },
  data: function data() {
    return {
      pageLinkShow: false,
      pageLinkData: {},
      currentTab: 0,
      rowModel: {
        appId: "",
        jumpPath: "",
        jumpStatus: false,
        jumpType: 0,
        pic: ""
      },
      formValidate: {
        companyEmailQt: {
          isOpen: 0,
          required: 0
          // sortNum: 0,
        },
        companyNameQt: {
          isOpen: 0,
          required: 0
          // sortNum: 0,
        },
        downloadPerson: [],
        formTitle: "",
        isPopup: false,
        lowerList: [],
        mobileQt: {
          isOpen: 0,
          required: 0
          // sortNum: 0,
        },
        popupRequest: {
          preType: 1,
          subType: 1,
          isPre: 0,
          isSubmit: 0,
          preImage: "",
          submitImage: "",
          prePathRequest: {
            appId: "",
            jumpPath: "",
            jumpStatus: true,
            jumpType: 0,
            pic: ""
          },
          submitPathRequest: {
            appId: "",
            jumpPath: "",
            jumpStatus: true,
            jumpType: 0,
            pic: ""
          }
        },
        questionsList: [],
        shareImage: "",
        status: false,
        submitImage: "",
        upperList: []
      },
      questionItem: {
        qtitle: "",
        tipsStr: "",
        required: 1,
        //0否1是
        type: 1,
        // 1填空 2单选 3多选
        options: [] // 选择题选项
      },
      categoryList: [],
      fullscreenLoading: false,
      tempRoute: {},
      optionLabel: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "G"],
      submitLoading: false,
      formTypeDesable: false,
      bannerModel: null,
      prePathRequestList: [],
      submitPathRequestList: [],
      preModelShow: true,
      subModelShow: true,
      adminUserList: []
    };
  },
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
  },
  mounted: function mounted() {
    console.log(this.$route);
    this.getadminUserList();
    if (this.$route.query.id) {
      this.getInfo();
    }
  },
  computed: {
    editView: function editView() {
      return !!this.$route.query.id ? true : false;
    }
  },
  // 刷新校验规则
  watch: {},
  methods: {
    getadminUserList: function getadminUserList() {
      var _this2 = this;
      (0, _channel.getAdminListForSelectApi)().then(function (res) {
        res.forEach(function (item) {
          item.id = item.id + "";
        });
        _this2.adminUserList = res;
      });
    },
    changeList1: function changeList1(list) {
      this.formValidate.upperList = list;
    },
    changeList2: function changeList2(list) {
      this.formValidate.lowerList = list;
    },
    changeList3: function changeList3(list) {
      this.preModelShow = false;
      this.prePathRequestList = list;
      if (list.length > 0) {
        this.formValidate.popupRequest.prePathRequest = list[0];
        this.formValidate.popupRequest.preImage = list[0].pic;
      } else {
        this.preModelShow = true;
        this.formValidate.popupRequest.preImage = "";
      }
    },
    changeList4: function changeList4(list) {
      this.subModelShow = false;
      this.submitPathRequestList = list;
      if (list.length > 0) {
        this.formValidate.popupRequest.submitPathRequest = list[0];
        this.formValidate.popupRequest.submitImage = list[0].pic;
      } else {
        this.subModelShow = true;
        this.formValidate.popupRequest.submitImage = "";
      }
    },
    validateOptions: function validateOptions(rule, value, callback) {
      if (!value) {
        callback(new Error("请输入选项内容"));
      } else {
        var qIndex = rule.field.split(".")[1];
        var exitsOption = this.formValidate.questionsList[qIndex].options.filter(function (item) {
          return item.title == value;
        });
        if (exitsOption.length > 1) {
          callback(new Error("存在重复的选项内容"));
        } else {
          callback();
        }
      }
    },
    nextStep: function nextStep() {
      var _this3 = this;
      this.$refs["formValidate"].validate(function (valid) {
        if (valid) {
          _this3.currentTab = 1;
        }
      });
    },
    submit: function submit() {
      var _this4 = this;
      this.$refs["formValidate"].validate(function (valid) {
        if (valid) {
          _this4.submitLoading = true;
          var params = JSON.parse(JSON.stringify(_this4.formValidate));
          params.downloadPerson = params.downloadPerson ? params.downloadPerson.toString() : "";
          //
          params.questionsList && params.questionsList.forEach(function (questionItem) {
            questionItem.options = questionItem.options.map(function (option) {
              return option.title;
            });
          });
          console.log(_this4.formValidate);
          //
          var api = _this4.formValidate.id ? defFormApi.editForm : defFormApi.AddForm;
          api(params).then(function (data) {
            _this4.submitLoading = false;
            _this4.$message("操作成功");
            _this4.$router.push({
              path: "/defForm/diyform"
            });
          }).catch(function () {
            _this4.submitLoading = false;
          });
        }
      });
    },
    getInfo: function getInfo() {
      var _this5 = this;
      defFormApi.getInfo({
        id: this.$route.query.id
      }).then(function (data) {
        var formModel = Object.assign({}, data);
        formModel.questions && formModel.questions.forEach(function (questionItem) {
          questionItem.options = questionItem.options.map(function (option) {
            return {
              title: option
            };
          });
        });
        if (formModel.downloadPerson) {
          formModel.downloadPerson = formModel.downloadPerson.split(",");
        }
        if (formModel.popupRequest) {
          if (formModel.popupRequest.prePathRequest) {
            _this5.prePathRequestList.push(formModel.popupRequest.prePathRequest);
          } else {
            _this5.prePathRequestList.push(_this5.rowModel);
          }
          _this5.prePathRequestList[0].pic = formModel.popupRequest.preImage;
          if (formModel.popupRequest.submitPathRequest) {
            _this5.submitPathRequestList.push(formModel.popupRequest.submitPathRequest);
          } else {
            _this5.submitPathRequestList.push(_this5.rowModel);
          }
          _this5.submitPathRequestList[0].pic = formModel.popupRequest.submitImage;
          if (!formModel.popupRequest.preType) {
            formModel.popupRequest.preType = 0;
          }
          if (!formModel.popupRequest.subType) {
            formModel.popupRequest.subType = 0;
          }
        }
        _this5.preModelShow = _this5.prePathRequestList.length > 0 ? false : true;
        _this5.subModelShow = _this5.submitPathRequestList.length > 0 ? false : true;
        formModel.questionsList = formModel.questions;
        console.log("formModel==", formModel);
        delete formModel.questions;
        _this5.formValidate = Object.assign(_this5.formValidate, formModel);
      });
    },
    changeQuestionType: function changeQuestionType(index, val) {
      if (val > 1) {
        this.formValidate.questionsList[index].options = [{
          title: ""
        }];
      } else {
        this.formValidate.questionsList[index].options = [];
      }
    },
    addQuestion: function addQuestion(index) {
      if (index > -1) {
        this.formValidate.questionsList.splice(index + 1, 0, JSON.parse(JSON.stringify(this.questionItem)));
      } else {
        this.formValidate.questionsList.push(JSON.parse(JSON.stringify(this.questionItem)));
      }
    },
    delQuestion: function delQuestion(index) {
      var _this6 = this;
      this.$confirm("确定删除当前问题", "提示").then(function () {
        _this6.formValidate.questionsList.splice(index, 1);
      });
    },
    addOption: function addOption(index, oIndex) {
      this.formValidate.questionsList[index].options.splice(oIndex + 1, 0, {
        title: ""
      });
    },
    delOption: function delOption(index, oIndex) {
      var _this7 = this;
      this.$confirm("确定删除当前选项", "提示").then(function () {
        _this7.formValidate.questionsList[index].options.splice(oIndex, 1);
      });
    },
    deleteimage2: function deleteimage2() {
      this.formValidate.submitImage = "";
    },
    modalPicTapImg: function modalPicTapImg(type) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.formValidate.popupRequest[type] = img[0].sattDir;
        _this.$refs["formValidate"].validateField("popupRequest." + type);
        if (type == "preImage") {
          _this.formValidate.popupRequest.prePathRequest.pic = img[0].sattDir;
        } else {
          _this.formValidate.popupRequest.submitPathRequest.pic = img[0].sattDir;
        }
      }, type, "content");
    },
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == "1") {
          _this.formValidate.shareImage = img[0].sattDir;
          _this.$refs["formValidate"].validateField("shareImage");
        } else if (tit == "2") {
          _this.formValidate.submitImage = img[0].sattDir;
          _this.$refs["formValidate"].validateField("submitImage");
        }
      }, tit, "content");
    }
  }
};
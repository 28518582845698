"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Catereset = Catereset;
exports.ResetInfo = ResetInfo;
exports.addMsgpic = addMsgpic;
exports.cacheReset = cacheReset;
exports.categoryApi = categoryApi;
exports.deleteMsg = deleteMsg;
exports.getPiclist = getPiclist;
exports.getWeChatKeywordsByTidApi = getWeChatKeywordsByTidApi;
exports.getWechatConfig = getWechatConfig;
exports.keywordsInfoApi = keywordsInfoApi;
exports.menuCreate = menuCreate;
exports.menuDelete = menuDelete;
exports.menuInfo = menuInfo;
exports.myTempInfoApi = myTempInfoApi;
exports.myTempListApi = myTempListApi;
exports.myTempSaveApi = myTempSaveApi;
exports.myTempStatusApi = myTempStatusApi;
exports.myTempTypeApi = myTempTypeApi;
exports.myTempUpdateApi = myTempUpdateApi;
exports.publicTempInfoApi = publicTempInfoApi;
exports.publicTempListApi = publicTempListApi;
exports.replyDeleteApi = replyDeleteApi;
exports.replyInfoApi = replyInfoApi;
exports.replyListApi = replyListApi;
exports.replySaveApi = replySaveApi;
exports.replyStatusApi = replyStatusApi;
exports.replyUpdateApi = replyUpdateApi;
exports.resetAddressApi = resetAddressApi;
exports.tempAsyncApi = tempAsyncApi;
exports.unbindApi = unbindApi;
exports.uploadPicApi = uploadPicApi;
exports.wechatAuth = wechatAuth;
exports.wechatMenuAddApi = wechatMenuAddApi;
exports.wechatMenuApi = wechatMenuApi;
exports.wechatTemplateDeleteApi = wechatTemplateDeleteApi;
exports.wechatTemplateInfoApi = wechatTemplateInfoApi;
exports.wechatTemplateListApi = wechatTemplateListApi;
exports.wechatTemplateSaveApi = wechatTemplateSaveApi;
exports.wechatTemplateStatusApi = wechatTemplateStatusApi;
exports.wechatTemplateUpdateApi = wechatTemplateUpdateApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// TODO 微信沟通难度大暂放 呵呵

function ResetInfo(data) {
  return (0, _request.default)({
    url: "/admin/system/admin/homepage/reset",
    method: "post",
    params: data
  });
}
function Catereset(data) {
  return (0, _request.default)({
    url: "/admin/system/admin/category/reset",
    method: "post",
    params: data
  });
}
function cacheReset(data) {
  return (0, _request.default)({
    url: "/admin/system/admin/homepage/resource/reset",
    method: "post",
    params: data
  });
}
function menuCreate(data) {
  return (0, _request.default)({
    url: "/admin/wechat/menu/public/create",
    method: "post",
    params: data
  });
}
function menuDelete(data) {
  return (0, _request.default)({
    url: "/admin/wechat/menu/public/delete",
    method: "post",
    params: data
  });
}
function menuInfo(pram) {
  var data = {
    isAsync: pram.isAsync
  };
  return (0, _request.default)({
    url: "/admin/wechat/menu/public/get",
    method: "post",
    params: data
  });
}

/**
 * 微信模板消息 列表
 * @param pram
 */
function wechatTemplateListApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/template/list",
    method: "get",
    params: params
  });
}

/**
 * 微信模板消息 新增
 * @param pram
 */
function wechatTemplateSaveApi(data) {
  return (0, _request.default)({
    url: "/admin/wechat/template/save",
    method: "post",
    data: data
  });
}

/**
 * 微信模板消息 编辑
 * @param pram
 */
function wechatTemplateUpdateApi(id, data) {
  return (0, _request.default)({
    url: "/admin/wechat/template/update/".concat(id),
    method: "post",
    data: data
  });
}

/**
 * 微信模板消息 详情
 * @param pram
 */
function wechatTemplateInfoApi(id) {
  return (0, _request.default)({
    url: "/admin/wechat/template/info/".concat(id),
    method: "get"
  });
}

/**
 * 微信模板消息 修改状态
 * @param pram
 */
function wechatTemplateStatusApi(id, params) {
  return (0, _request.default)({
    url: "/admin/wechat/template/update/status/".concat(id),
    method: "post",
    params: params
  });
}

/**
 * 微信模板消息 删除
 * @param pram
 */
function wechatTemplateDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/wechat/template/delete/".concat(id),
    method: "get"
  });
}

/**
 * 关键字回复 列表
 * @param pram
 */
function replyListApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/keywords/reply/list",
    method: "get",
    params: params
  });
}

/**
 * 关键字回复 新增
 * @param pram
 */
function replySaveApi(data) {
  return (0, _request.default)({
    url: "/admin/wechat/keywords/reply/save",
    method: "post",
    data: data
  });
}

/**
 * 关键字回复 修改状态
 * @param pram
 */
function replyStatusApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/keywords/reply/status",
    method: "post",
    params: params
  });
}
/**
 * 关键字回复 编辑
 * @param pram
 */
function replyUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/admin/wechat/keywords/reply/update",
    method: "post",
    params: params,
    data: data
  });
}

/**
 * 关键字回复 详情
 * @param pram
 */
function replyInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/keywords/reply/info",
    method: "get",
    params: params
  });
}

/**
 * 关键字回复 删除
 * @param pram
 */
function replyDeleteApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/keywords/reply/delete",
    method: "get",
    params: params
  });
}

/**
 * 关键字查询数据
 * @param pram
 */
function keywordsInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/keywords/reply/info/keywords",
    method: "get",
    params: params
  });
}

/**
 * 微信菜单 获取数据
 * @param pram
 */
function wechatMenuApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/menu/public/get",
    method: "get",
    params: params
  });
}

/**
 * 微信菜单 新增
 * @param pram
 */
function wechatMenuAddApi(data) {
  return (0, _request.default)({
    url: "/admin/wechat/menu/public/create",
    method: "post",
    data: data
  });
}

/**
 * 小程序 公共模板列表
 */
function publicTempListApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/program/public/temp/list",
    method: "get",
    params: params
  });
}

/**
 * 小程序 模版所属类目
 */
function categoryApi() {
  return (0, _request.default)({
    url: "/admin/wechat/program/category",
    method: "get"
  });
}

/**
 * 小程序 通过微信模板tid获取关键字列表
 */
function getWeChatKeywordsByTidApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/program/getWeChatKeywordsByTid",
    method: "get",
    params: params
  });
}

/**
 * 小程序 模板详情，主要是获取左侧标题
 */
function publicTempInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/program/public/temp/info",
    method: "get",
    params: params
  });
}

/**
 * 小程序 我的模板列表
 */
function myTempListApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/program/my/temp/list",
    method: "get",
    params: params
  });
}

/**
 * 小程序 我的模板详情
 */
function myTempInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/program/my/temp/info",
    method: "get",
    params: params
  });
}

/**
 * 小程序 模板新增
 */
function myTempSaveApi(data) {
  return (0, _request.default)({
    url: "/admin/wechat/program/my/temp/save",
    method: "post",
    data: data
  });
}

/**
 * 小程序 模板修改
 */
function myTempUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/admin/wechat/program/my/temp/update",
    method: "post",
    params: params,
    data: data
  });
}

/**
 * 小程序 我的模板修改状态
 */
function myTempStatusApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/program/my/temp/update/status",
    method: "get",
    params: params
  });
}

/**
 * 小程序 我的模板修改应用场景
 */
function myTempTypeApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/program/my/temp/update/type",
    method: "get",
    params: params
  });
}

/**
 * 获取微信sdk配置
 * @returns {*}
 */
function getWechatConfig() {
  return (0, _request.default)({
    url: "/admin/wechat/config",
    method: "get",
    params: {
      url: encodeURIComponent(location.href.split("#")[0])
    } // for Test
  });
}

/**
 * 微信授权登录
 * @returns {*}
 */
function wechatAuth(code) {
  return (0, _request.default)({
    url: "/admin/authorize/login",
    method: "get",
    params: {
      code: code
    }
  });
}

/**
 * 与微信解绑账号
 */
function unbindApi() {
  return (0, _request.default)({
    url: "/admin/unbind",
    method: "get"
  });
}

/**
 * 一键同步我的模板到小程序
 */
function tempAsyncApi() {
  return (0, _request.default)({
    url: "/admin/wechat/program/my/temp/async",
    method: "get"
  });
}

/**
 * 公众号上传图片模板
 */

function uploadPicApi(data, params) {
  return (0, _request.default)({
    url: "/admin/wechat/media/upload",
    method: "post",
    data: data,
    params: params
  });
}

/**
 * 公众号查询图片列表
 */

function getPiclist(data) {
  return (0, _request.default)({
    url: "/admin/wechat/material/list",
    method: "get",
    params: data
  });
}

/**
 * 删除图文
 */

function deleteMsg(data) {
  return (0, _request.default)({
    url: "/admin/wechat/media/delete/".concat(data),
    method: "get"
  });
}

/**
 * 新增图文
 */

function addMsgpic(data) {
  return (0, _request.default)({
    url: "/admin/wechat/material/create",
    method: "post",
    data: data
  });
}
function resetAddressApi(data) {
  return (0, _request.default)({
    url: "/admin/serve/address/resetCache",
    method: "post",
    data: data
  });
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "components-container" },
          [
            _c(
              "el-form",
              {
                ref: "pram",
                attrs: { "label-width": "150px", model: _vm.pram },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "标题",
                      prop: "name",
                      rules: [
                        {
                          required: true,
                          message: "请填写标题",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "标题", maxlength: "100" },
                      model: {
                        value: _vm.pram.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "name", $$v)
                        },
                        expression: "pram.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "分类",
                      prop: "categoryId",
                      rules: [
                        {
                          required: true,
                          type: "array",
                          min: 1,
                          message: "请选择分类",
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-cascader", {
                      ref: "cascader",
                      staticClass: "selWidth",
                      attrs: {
                        options: _vm.merCateList,
                        clearable: "",
                        props: _vm.props2,
                        "show-all-levels": false,
                        "node-key": "id",
                      },
                      model: {
                        value: _vm.pram.categoryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "categoryId", $$v)
                        },
                        expression: "pram.categoryId",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "标签",
                      prop: "tag",
                      rules: [
                        {
                          required: true,
                          message: "请填写标签",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder:
                          "请输入内容标签,以英文状态下的逗号隔开,最多16个字符",
                        maxlength: "16",
                      },
                      model: {
                        value: _vm.pram.tag,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "tag", $$v)
                        },
                        expression: "pram.tag",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "后台标签",
                      prop: "clLabel",
                      rules: [
                        {
                          required: true,
                          message: "请填写标签",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "仅传输回convertlab，以英文逗号隔开",
                        maxlength: "255",
                      },
                      model: {
                        value: _vm.pram.clLabel,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "clLabel", $$v)
                        },
                        expression: "pram.clLabel",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "描述",
                      prop: "description",
                      rules: [
                        {
                          required: true,
                          message: "请填描述",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        placeholder: "描述",
                        maxlength: "255",
                      },
                      model: {
                        value: _vm.pram.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "description", $$v)
                        },
                        expression: "pram.description",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "图文封面",
                      prop: "coverPath",
                      rules: [
                        {
                          required: true,
                          message: "请上传图文封面",
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "upLoadPicBox",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap("1")
                          },
                        },
                      },
                      [
                        _vm.pram.coverPath
                          ? _c("div", { staticClass: "pictrue" }, [
                              _c("img", { attrs: { src: _vm.pram.coverPath } }),
                            ])
                          : _c("div", { staticClass: "upLoad" }, [
                              _c("i", {
                                staticClass: "el-icon-camera cameraIconfont",
                              }),
                            ]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { color: "#999" } }, [
                          _vm._v("(推荐尺寸：682 X 320px,200dpi)"),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "预览图片",
                      prop: "sliderImages",
                      rules: [
                        {
                          required: true,
                          type: "array",
                          min: "1",
                          max: "4",
                          message: "请上传预览图片",
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "acea-row" },
                      [
                        _vm._l(_vm.pram.sliderImages, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "pictrue",
                              attrs: { draggable: "true" },
                              on: {
                                dragstart: function ($event) {
                                  return _vm.handleDragStart($event, item)
                                },
                                dragover: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleDragOver($event, item)
                                },
                                dragenter: function ($event) {
                                  return _vm.handleDragEnter($event, item)
                                },
                                dragend: function ($event) {
                                  return _vm.handleDragEnd($event, item)
                                },
                              },
                            },
                            [
                              _c("img", { attrs: { src: item } }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-error btndel",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRemove(index)
                                  },
                                },
                              }),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.pram.sliderImages.length < 4
                          ? _c(
                              "div",
                              {
                                staticClass: "upLoadPicBox",
                                on: {
                                  click: function ($event) {
                                    return _vm.modalPicTap("2")
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "brief", staticStyle: { color: "#999" } },
                      [_vm._v("(请上传解决方案图片，宽度为682px)")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "全量pdf",
                      prop: "wholePdfPath",
                      rules: [
                        {
                          required: true,
                          message: "请上传全量pdf",
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "div",
                      {},
                      [
                        !_vm.infoLoading
                          ? _c("upload-file", {
                              attrs: {
                                value: _vm.pram.wholePdfPath,
                                fileType: "pdf",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.getVideo($event, "wholePdfPath")
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticStyle: { color: "#999" } }, [
                          _vm._v("(格式:pdf)"),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "留资标题",
                      prop: "stayTitle",
                      rules: [
                        {
                          required: false,
                          message: "请填写留资标题",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "表单头图标题，最多可输入7个字",
                        maxlength: "7",
                      },
                      model: {
                        value: _vm.pram.stayTitle,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "stayTitle", $$v)
                        },
                        expression: "pram.stayTitle",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "留资头图",
                      prop: "stayHeadImage",
                      rules: [
                        {
                          required: false,
                          message: "请上传留资头图",
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "upLoadPicBox",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTapStay("1")
                          },
                        },
                      },
                      [
                        _vm.pram.stayHeadImage
                          ? _c("div", { staticClass: "pictrue" }, [
                              _c("img", {
                                attrs: { src: _vm.pram.stayHeadImage },
                              }),
                            ])
                          : _c("div", { staticClass: "upLoad" }, [
                              _c("i", {
                                staticClass: "el-icon-camera cameraIconfont",
                              }),
                            ]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { color: "#999" } }, [
                          _vm._v("(推荐尺寸：380 X 750像素,1：1)"),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loading },
                        on: {
                          click: function ($event) {
                            return _vm.handerSubmit("pram")
                          },
                        },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
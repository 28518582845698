"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserGroup',
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    info: function info() {},
    onAdd: function onAdd(row) {
      var _this = this;
      this.$prompt(this.$route.path.indexOf('group') !== -1 ? '分组名称' : '标签名称', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: this.$route.path.indexOf('group') !== -1 ? '请输入分组名称' : '请输入标签名称',
        inputType: 'text',
        inputValue: row ? this.$route.path.indexOf('group') !== -1 ? row.groupName : row.name : '',
        inputPlaceholder: this.$route.path.indexOf('group') !== -1 ? '请输入分组名称' : '请输入标签名称',
        inputValidator: function inputValidator(value) {
          if (!value) return '输入不能为空';
        }
      }).then(function (_ref) {
        var value = _ref.value;
        if (_this.$route.path.indexOf('group') !== -1) {
          row ? (0, _user.groupUpdateApi)({
            id: row.id
          }, {
            groupName: value
          }).then(function () {
            _this.$message.success('编辑成功');
            _this.getList();
          }) : (0, _user.groupSaveApi)({
            groupName: value
          }).then(function () {
            _this.$message.success('新增成功');
            _this.getList();
          });
        } else {
          row ? (0, _user.tagUpdateApi)({
            id: row.id
          }, {
            name: value
          }).then(function () {
            _this.$message.success('编辑成功');
            _this.getList();
          }) : (0, _user.tagSaveApi)({
            name: value
          }).then(function () {
            _this.$message.success('新增成功');
            _this.getList();
          });
        }
      }).catch(function () {
        _this.$message.info('取消输入');
      });
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      this.$route.path.indexOf('group') !== -1 ? (0, _user.groupListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
      }) : (0, _user.tagListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this3 = this;
      this.$modalSure('删除吗？所有用户已经关联的数据都会清除').then(function () {
        _this3.$route.path.indexOf('group') !== -1 ? (0, _user.groupDeleteApi)({
          id: id
        }).then(function () {
          _this3.$message.success('删除成功');
          _this3.tableData.data.splice(idx, 1);
        }) : (0, _user.tagDeleteApi)({
          id: id
        }).then(function () {
          _this3.$message.success('删除成功');
          _this3.tableData.data.splice(idx, 1);
        });
      });
    }
  }
};
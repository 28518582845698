"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configApi = configApi;
exports.configUpdateApi = configUpdateApi;
exports.promoterListApi = promoterListApi;
exports.spreadClearApi = spreadClearApi;
exports.spreadListApi = spreadListApi;
exports.spreadOrderListApi = spreadOrderListApi;
exports.spreadStatisticsApi = spreadStatisticsApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * @description 分销设置 -- 详情
 */
function configApi() {
  return (0, _request.default)({
    url: '/admin/store/retail/spread/manage/get',
    method: 'get'
  });
}

/**
 * @description 分销设置 -- 表单提交
 */
function configUpdateApi(data) {
  return (0, _request.default)({
    url: '/admin/store/retail/spread/manage/set',
    method: 'post',
    data: data
  });
}

/**
 * @description 分销员 -- 列表
 */
function promoterListApi(params) {
  return (0, _request.default)({
    url: '/admin/store/retail/list',
    method: 'get',
    params: params
  });
}

/**
 * @description 推广人 -- 列表
 */
function spreadListApi(params, data) {
  return (0, _request.default)({
    url: '/admin/store/retail/spread/userlist',
    method: 'post',
    params: params,
    data: data
  });
}

/**
 * @description 推广人订单 -- 列表
 */
function spreadOrderListApi(params, data) {
  return (0, _request.default)({
    url: '/admin/store/retail/spread/orderlist',
    method: 'post',
    params: params,
    data: data
  });
}

/**
 * @description 推广人 -- 清除上级推广人
 */
function spreadClearApi(id) {
  return (0, _request.default)({
    url: "/admin/store/retail/spread/clean/".concat(id),
    method: 'get'
  });
}

/**
 * @description 分销统计
 */
function spreadStatisticsApi(params) {
  return (0, _request.default)({
    url: "/admin/store/retail/statistics",
    method: 'get',
    params: params
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _throttleDebounce = require("throttle-debounce");
var _fileSaver = require("file-saver");
var _clipboard = _interopRequireDefault(require("clipboard"));
var _render = _interopRequireDefault(require("@/components/FormGenerator/components/render/render"));
var _FormDrawer = _interopRequireDefault(require("./FormDrawer"));
var _JsonDrawer = _interopRequireDefault(require("./JsonDrawer"));
var _RightPanel = _interopRequireDefault(require("./RightPanel"));
var _config = require("@/components/FormGenerator/components/generator/config");
var _index = require("../utils/index");
var _html = require("@/components/FormGenerator/components/generator/html");
var _js = require("@/components/FormGenerator/components/generator/js");
var _css = require("@/components/FormGenerator/components/generator/css");
var _drawingDefalut = _interopRequireDefault(require("@/components/FormGenerator/components/generator/drawingDefalut"));
var _CodeTypeDialog = _interopRequireDefault(require("./CodeTypeDialog"));
var _DraggableItem = _interopRequireDefault(require("./DraggableItem"));
var _db = require("../utils/db");
var _loadBeautifier = _interopRequireDefault(require("../utils/loadBeautifier"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import logo from '@/assets/logo.png'
var beautifier;
var emptyActiveData = {
  style: {},
  autosize: {}
};
var oldActiveId;
var tempActiveData;
var drawingListInDB = (0, _db.getDrawingList)();
var formConfInDB = (0, _db.getFormConf)();
var idGlobal = (0, _db.getIdGlobal)();
var _default = exports.default = {
  components: {
    draggable: _vuedraggable.default,
    render: _render.default,
    FormDrawer: _FormDrawer.default,
    JsonDrawer: _JsonDrawer.default,
    RightPanel: _RightPanel.default,
    CodeTypeDialog: _CodeTypeDialog.default,
    DraggableItem: _DraggableItem.default
  },
  props: {
    editData: {
      type: Object,
      default: {}
    },
    isCreate: {
      type: Number,
      default: 0 // 0=创建，1=编辑
    }
  },
  data: function data() {
    return {
      // logo,
      idGlobal: idGlobal,
      formConf: _config.formConf,
      inputComponents: _config.inputComponents,
      selectComponents: _config.selectComponents,
      layoutComponents: _config.layoutComponents,
      labelWidth: 100,
      drawingList: _drawingDefalut.default,
      drawingData: {},
      activeId: _drawingDefalut.default[0].formId,
      drawerVisible: false,
      formData: {},
      dialogVisible: false,
      jsonDrawerVisible: false,
      generateConf: null,
      showFileName: false,
      activeData: _drawingDefalut.default[0],
      saveDrawingListDebounce: (0, _throttleDebounce.debounce)(340, _db.saveDrawingList),
      saveIdGlobalDebounce: (0, _throttleDebounce.debounce)(340, _db.saveIdGlobal),
      leftComponents: [{
        title: '输入型组件',
        list: _config.inputComponents
      }, {
        title: '选择型组件',
        list: _config.selectComponents
      }, {
        title: '布局型组件',
        list: _config.layoutComponents
      }],
      selfForm: {
        name: null,
        info: null,
        id: null
      }
    };
  },
  computed: {},
  watch: {
    // eslint-disable-next-line func-names
    'activeData.__config__.label': function activeData__config__Label(val, oldVal) {
      if (this.activeData.placeholder === undefined || !this.activeData.__config__.tag || oldActiveId !== this.activeId) {
        return;
      }
      this.activeData.placeholder = this.activeData.placeholder.replace(oldVal, '') + val;
    },
    activeId: {
      handler: function handler(val) {
        oldActiveId = val;
      },
      immediate: true
    },
    drawingList: {
      handler: function handler(val) {
        this.saveDrawingListDebounce(val);
        if (val.length === 0) this.idGlobal = 100;
      },
      deep: true
    },
    idGlobal: {
      handler: function handler(val) {
        this.saveIdGlobalDebounce(val);
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (this.editData.content) {
      var _this$editData = this.editData,
        id = _this$editData.id,
        name = _this$editData.name,
        info = _this$editData.info,
        content = _this$editData.content;
      this.selfForm.name = name;
      this.selfForm.id = id;
      this.selfForm.info = info;
      content = JSON.parse(content);
      this.drawingList = content.fields;
      var _content = JSON.parse(JSON.stringify(content));
      delete _content.fields;
      this.formConf = _content;
    }
    // if (Array.isArray(drawingListInDB) && drawingListInDB.length > 0) {
    //   this.drawingList = drawingListInDB
    // } else {
    //   this.drawingList = drawingDefalut
    // }
    this.activeFormItem(this.drawingList[0]);
    // if (formConfInDB) {
    //   this.formConf = formConfInDB
    // }
    (0, _loadBeautifier.default)(function (btf) {
      beautifier = btf;
    });
    var clipboard = new _clipboard.default('#copyNode', {
      text: function text(trigger) {
        var codeStr = _this.generateCode();
        _this.$notify({
          title: '成功',
          message: '代码已复制到剪切板，可粘贴。',
          type: 'success'
        });
        return codeStr;
      }
    });
    clipboard.on('error', function (e) {
      _this.$message.error('代码复制失败');
    });
  },
  methods: {
    activeFormItem: function activeFormItem(element) {
      this.activeData = element;
      this.activeId = element.__config__.formId;
    },
    onEnd: function onEnd(obj) {
      if (obj.from !== obj.to) {
        this.activeData = tempActiveData;
        this.activeId = this.idGlobal;
      }
    },
    addComponent: function addComponent(item) {
      var clone = this.cloneComponent(item);
      this.drawingList.push(clone);
      this.activeFormItem(clone);
    },
    cloneComponent: function cloneComponent(origin) {
      var clone = JSON.parse(JSON.stringify(origin));
      var config = clone.__config__;
      config.formId = ++this.idGlobal;
      config.span = this.formConf.span;
      config.renderKey = +new Date(); // 改变renderKey后可以实现强制更新组件
      if (config.layout === 'colFormItem') {
        clone.__vModel__ = "field".concat(this.idGlobal);
        clone.placeholder !== undefined && (clone.placeholder += config.label);
      } else if (config.layout === 'rowFormItem') {
        config.componentName = "row".concat(this.idGlobal);
        config.gutter = this.formConf.gutter;
      }
      tempActiveData = clone;
      return tempActiveData;
    },
    AssembleFormData: function AssembleFormData() {
      this.formData = _objectSpread({
        fields: JSON.parse(JSON.stringify(this.drawingList))
      }, this.formConf);
    },
    generate: function generate(data) {
      var func = this["exec".concat((0, _index.titleCase)(this.operationType))];
      this.generateConf = data;
      func && func(data);
    },
    execRun: function execRun(data) {
      this.AssembleFormData();
      this.drawerVisible = true;
    },
    execDownload: function execDownload(data) {
      var codeStr = this.generateCode();
      var blob = new Blob([codeStr], {
        type: 'text/plain;charset=utf-8'
      });
      (0, _fileSaver.saveAs)(blob, data.fileName);
    },
    execCopy: function execCopy(data) {
      document.getElementById('copyNode').click();
    },
    empty: function empty() {
      var _this2 = this;
      this.$confirm('确定要清空所有组件吗？', '提示', {
        type: 'warning'
      }).then(function () {
        _this2.drawingList = [];
        _this2.idGlobal = 100;
      });
    },
    drawingItemCopy: function drawingItemCopy(item, parent) {
      var clone = JSON.parse(JSON.stringify(item));
      clone = this.createIdAndKey(clone);
      parent.push(clone);
      this.activeFormItem(clone);
    },
    createIdAndKey: function createIdAndKey(item) {
      var _this3 = this;
      var config = item.__config__;
      config.formId = ++this.idGlobal;
      config.renderKey = +new Date();
      if (config.layout === 'colFormItem') {
        item.__vModel__ = "field".concat(this.idGlobal);
      } else if (config.layout === 'rowFormItem') {
        config.componentName = "row".concat(this.idGlobal);
      }
      if (Array.isArray(config.children)) {
        config.children = config.children.map(function (childItem) {
          return _this3.createIdAndKey(childItem);
        });
      }
      return item;
    },
    drawingItemDelete: function drawingItemDelete(index, parent) {
      var _this4 = this;
      parent.splice(index, 1);
      this.$nextTick(function () {
        var len = _this4.drawingList.length;
        if (len) {
          _this4.activeFormItem(_this4.drawingList[len - 1]);
        }
      });
    },
    generateCode: function generateCode() {
      var type = this.generateConf.type;
      this.AssembleFormData();
      var script = (0, _html.vueScript)((0, _js.makeUpJs)(this.formData, type));
      var html = (0, _html.vueTemplate)((0, _html.makeUpHtml)(this.formData, type));
      var css = (0, _html.cssStyle)((0, _css.makeUpCss)(this.formData));
      return beautifier.html(html + script + css, _index.beautifierConf.html);
    },
    showJson: function showJson() {
      this.AssembleFormData();
      this.jsonDrawerVisible = true;
    },
    handlerSaveJSON: function handlerSaveJSON(form) {
      var _this5 = this;
      // this.AssembleFormData()
      // loadBeautifier(btf => {
      //   beautifier = btf
      //   let jsonStr = JSON.stringify(this.formData)
      //   this.beautifierJson = beautifier.js(jsonStr, beautifierConf.js)
      //
      // })
      this.$refs[form].validate(function (result) {
        if (!result) return;
        var formConfig = (0, _db.getFormConfSelf)();
        if (formConfig.fields.length === 0) {
          _this5.$message.error('表单配置数据不能为空');
          return;
        }
        _this5.selfForm.content = JSON.stringify(formConfig);
        _this5.$emit('getFormConfigDataResult', _this5.selfForm);
      });
    },
    download: function download() {
      this.dialogVisible = true;
      this.showFileName = true;
      this.operationType = 'download';
    },
    run: function run() {
      this.dialogVisible = true;
      this.showFileName = false;
      this.operationType = 'run';
    },
    copy: function copy() {
      this.dialogVisible = true;
      this.showFileName = false;
      this.operationType = 'copy';
    },
    tagChange: function tagChange(newTag) {
      var _this6 = this;
      newTag = this.cloneComponent(newTag);
      var config = newTag.__config__;
      newTag.__vModel__ = this.activeData.__vModel__;
      config.formId = this.activeId;
      config.span = this.activeData.__config__.span;
      this.activeData.__config__.tag = config.tag;
      this.activeData.__config__.tagIcon = config.tagIcon;
      this.activeData.__config__.document = config.document;
      if (_typeof(this.activeData.__config__.defaultValue) === _typeof(config.defaultValue)) {
        config.defaultValue = this.activeData.__config__.defaultValue;
      }
      Object.keys(newTag).forEach(function (key) {
        if (_this6.activeData[key] !== undefined) {
          newTag[key] = _this6.activeData[key];
        }
      });
      this.activeData = newTag;
      this.updateDrawingList(newTag, this.drawingList);
    },
    updateDrawingList: function updateDrawingList(newTag, list) {
      var _this7 = this;
      var index = list.findIndex(function (item) {
        return item.__config__.formId === _this7.activeId;
      });
      if (index > -1) {
        list.splice(index, 1, newTag);
      } else {
        list.forEach(function (item) {
          if (Array.isArray(item.__config__.children)) _this7.updateDrawingList(newTag, item.__config__.children);
        });
      }
    },
    refreshJson: function refreshJson(data) {
      this.drawingList = JSON.parse(JSON.stringify(data.fields));
      delete data.fields;
      this.formConf = data;
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c("iframe", {
          staticClass: "embed-responsive-item",
          attrs: {
            id: "preview",
            width: "100%",
            height: "510px",
            type: "text/html",
            src: _vm.previewUrl,
            frameborder: "0",
            allowfullscreen: "",
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
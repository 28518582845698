var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingAll,
              expression: "loadingAll",
            },
          ],
          staticClass: "box-card",
        },
        [
          _c("el-alert", {
            attrs: {
              closable: false,
              title:
                "你可用该标题的模板搭配不同的关键词使用，配置提交后关键词种类和顺序将不能修改",
              type: "warning",
            },
          }),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { xs: 24, sm: 24, md: 8, lg: 8, xl: 8 } }, [
                _c("div", { staticClass: "tmplmsg-box" }, [
                  _c(
                    "div",
                    { staticClass: "tmplmsg-preview" },
                    [
                      _c("div", {
                        staticClass: "tmplmsg-preview-title mb35",
                        domProps: { textContent: _vm._s(_vm.form.title) },
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.KeywordCheck, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: item.kid,
                            staticClass:
                              "acea-row row-middle tmplmsg-preview-cont mb10",
                          },
                          [
                            _c("label", {
                              domProps: { textContent: _vm._s(item.name) },
                            }),
                            _vm._v(" "),
                            _c("span", {
                              domProps: { textContent: _vm._s(item.example) },
                            }),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 16, lg: 16, xl: 16 } },
                [
                  _c(
                    "div",
                    { staticClass: "tmplmsg-form" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: {
                            model: _vm.form,
                            rules: _vm.rules,
                            "label-width": "100px",
                            size: "mini",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "配置关键词", prop: "checkList" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "tmplmsg-form-cont" },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      attrs: { max: 5 },
                                      on: { change: _vm.handleChecked },
                                      model: {
                                        value: _vm.form.checkList,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "checkList", $$v)
                                        },
                                        expression: "form.checkList",
                                      },
                                    },
                                    _vm._l(_vm.KeywordList, function (item) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: item.kid,
                                          attrs: { label: item.kid },
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  "已选择(" + _vm.KeywordCheck.length + "/5)",
                              },
                            },
                            [
                              _vm.KeywordCheck.length === 0
                                ? _c("span", [_vm._v("请先从上方选择关键词")])
                                : _c(
                                    "div",
                                    { staticClass: "tmplmsg-form-check" },
                                    _vm._l(
                                      _vm.KeywordCheck,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.kid,
                                            staticClass:
                                              "tmplmsg-form-check-list mb10 acea-row row-between",
                                            attrs: { draggable: "true" },
                                            on: {
                                              dragstart: function ($event) {
                                                return _vm.handleDragStart(
                                                  $event,
                                                  item
                                                )
                                              },
                                              dragover: [
                                                function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.handleDragOver(
                                                    $event,
                                                    item
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.handleDragEnter(
                                                    $event,
                                                    item
                                                  )
                                                },
                                              ],
                                              dragend: function ($event) {
                                                return _vm.handleDragEnd(
                                                  $event,
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(item.name),
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("i", {
                                              staticClass: "el-icon-close",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.closeCheck(index)
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "场景说明", prop: "sceneDesc" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 2,
                                  placeholder: "请输入场景说明",
                                },
                                model: {
                                  value: _vm.form.sceneDesc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sceneDesc", $$v)
                                  },
                                  expression: "form.sceneDesc",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSubmit("form")
                                    },
                                  },
                                },
                                [_vm._v("提交")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
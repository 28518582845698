"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import startFlowForm from "../common/start-form.vue";
// import { generalMixin } from "./general-mixin";
var _default = exports.default = {
  // components: {
  //   startFlowForm
  // },
  // mixins: [generalMixin]
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _channel = require("@/api/channel");
var _stay = require("@/api/stay");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tableData: [],
      tableFrom: {
        page: 1,
        limit: 20,
        keywords: "",
        startTime: "",
        endTime: "",
        ownerList: []
      },
      timeVal: [],
      currentObj: {
        notes: "",
        id: ""
      },
      listLoading: false,
      dialogVisible: false,
      total: 0,
      ruleForm: {
        name: "",
        paramList: [{
          name: "",
          dimension: "",
          paraphrase: ""
        }]
      },
      editParamsId: "",
      dialogTitle: "新增参数模板",
      upateLoading: false,
      adminUserList: [],
      isShow: null
    };
  },
  created: function created() {
    this.getadminUserList();
    this.getList();
    this.getRoleInfo();
  },
  watch: {
    dialogTitle: function dialogTitle(val) {
      val == "查看参数模板" ? this.isFlagAdd = false : this.isFlagAdd = true;
    }
  },
  methods: {
    getRoleInfo: function getRoleInfo() {
      var _this = this;
      (0, _user.getInfo)(this.$store.state.token).then(function (res) {
        var arr = res.roles.split(",").map(function (item) {
          return Number(item);
        });
        console.log(arr);
        var i = arr.indexOf(1);
        _this.isShow = i == -1 ? false : true;
      });
    },
    // 获取管理员列表
    getadminUserList: function getadminUserList() {
      var _this2 = this;
      (0, _channel.getAdminListForSelectApi)().then(function (res) {
        res.forEach(function (item) {
          item.id = item.id + "";
        });
        _this2.adminUserList = res;
      });
    },
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      console.log(this.timeVal, "timeVal");
      if (this.timeVal) {
        this.tableFrom.startTime = this.timeVal[0];
        this.tableFrom.endTime = this.timeVal[1];
      } else {
        this.tableFrom.startTime = "";
        this.tableFrom.endTime = "";
      }
      this.tableFrom.page = 1;
      this.getList();
    },
    handleAddNotes: function handleAddNotes() {
      var _this3 = this;
      var that = this;
      (0, _stay.getAddNotes)(this.currentObj).then(function (res) {
        console.log("12222");
        _this3.$message({
          type: "success",
          message: "操作成功！"
        });
        _this3.dialogVisible = false;
        that.tableFrom.page = 1;
        that.getList();
      });
    },
    onEdit: function onEdit(row) {
      this.dialogVisible = true;
      this.currentObj.notes = row.notes ? row.notes : "";
      this.currentObj.id = row.id;
    },
    handleExport: function handleExport() {
      var _this4 = this;
      var that = this;
      this.$confirm("是否确认导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        that.submitExport();
      }).catch(function () {
        _this4.$message({
          type: "info",
          message: "已取消导出"
        });
      });
    },
    submitExport: function submitExport() {
      var _this5 = this;
      var Form = Object.assign({}, this.tableFrom);
      var arr = Form.ownerList.map(function (item) {
        return Number(item);
      });
      var parmas = {
        keywords: this.tableFrom.keywords,
        startTime: this.tableFrom.startTime,
        endTime: this.tableFrom.endTime,
        ownerList: arr
      };
      this.upateLoading = true;
      (0, _stay.stayInfoExport)(parmas).then(function (res) {
        _this5.upateLoading = false;
        console.log(res, "ssssces");
        window.open(res.fileName);
      }).catch(function (res) {
        _this5.upateLoading = false;
      });
    },
    getList: function getList() {
      var _this6 = this;
      var Form = Object.assign({}, this.tableFrom);
      var arr = Form.ownerList.map(function (item) {
        return Number(item);
      });
      Form.ownerList = arr;
      (0, _stay.stayInfoList)(Form).then(function (res) {
        console.log(res);
        _this6.tableData = res.list;
        _this6.total = res.total;
        console.log(_this6.tableData, "tableData");
      });
    },
    handleDelete: function handleDelete(id, index) {
      var _this7 = this;
      var that = this;
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        deleteId(id).then(function (res) {
          _this7.$message({
            type: "success",
            message: "删除成功!"
          });
          that.tableFrom.page = 1;
          that.getList();
        });
      }).catch(function () {
        _this7.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    removeDomain: function removeDomain(index) {
      var _this8 = this;
      var that = this;
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        that.ruleForm.paramList.splice(index, 1);
        _this8.$message({
          type: "success",
          message: "删除成功!"
        });
      }).catch(function () {
        _this8.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox store-page" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.currentTab,
                    "align-center": "",
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", { attrs: { title: "服务配置" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "表单配置" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                model: _vm.formValidate,
                "label-width": "110px",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _vm.currentTab === 0
                ? _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "分类选择：",
                                prop: "catagoryId",
                                rules: [
                                  {
                                    required: _vm.formTypeFlag,
                                    message: "请选择分类",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    "max-width": "500px",
                                    width: "100%",
                                  },
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.formValidate.catagoryId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "catagoryId",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.catagoryId",
                                  },
                                },
                                _vm._l(
                                  _vm.categoryList,
                                  function (page, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        disabled: page.status == 0,
                                        label: page.name,
                                        value: page.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "标题：",
                                prop: "title",
                                rules: [
                                  {
                                    required: _vm.formTypeFlag,
                                    message: "请输入标题",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { "max-width": "500px" },
                                attrs: {
                                  maxlength: "20",
                                  placeholder: "请输入标题",
                                },
                                model: {
                                  value: _vm.formValidate.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "title", $$v)
                                  },
                                  expression: "formValidate.title",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "标签：",
                                prop: "tags",
                                rules: [
                                  {
                                    required: _vm.formTypeFlag,
                                    message: "请输入标题",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { "max-width": "500px" },
                                attrs: {
                                  placeholder:
                                    "请输入标签,以英文状态下的逗号隔开,最多16个字符",
                                  maxlength: "16",
                                },
                                model: {
                                  value: _vm.formValidate.tags,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "tags", $$v)
                                  },
                                  expression: "formValidate.tags",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "价格类型：",
                                rules: [{ required: _vm.formTypeFlag }],
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.formValidate.priceType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "priceType",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.priceType",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("起始价"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("中文"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formValidate.priceType == 1
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "价格：",
                                    prop: "price",
                                    rules: [
                                      {
                                        required: _vm.formTypeFlag,
                                        message: "请输入价格",
                                        trigger: "blur",
                                      },
                                      {
                                        type: "number",
                                        min: 0,
                                        message: "不能为负数",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { "max-width": "500px" },
                                    attrs: {
                                      type: "number",
                                      min: "0",
                                      placeholder: "请输入价格",
                                    },
                                    model: {
                                      value: _vm.formValidate.price,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate,
                                          "price",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "formValidate.price",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "中文价格：",
                                    prop: "chinesePrice",
                                    rules: [
                                      {
                                        required: _vm.formTypeFlag,
                                        message: "请输入价格",
                                        trigger: "blur",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { "max-width": "500px" },
                                    attrs: {
                                      placeholder: "请输入中文价格：最多10个字",
                                      maxlength: "10",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.formValidate.chinesePrice,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate,
                                          "chinesePrice",
                                          $$v
                                        )
                                      },
                                      expression: "formValidate.chinesePrice",
                                    },
                                  }),
                                ],
                                1
                              ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "列表图片：",
                                prop: "image",
                                rules: [
                                  {
                                    required: _vm.formTypeFlag,
                                    message: "请上传图片",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap("1")
                                    },
                                  },
                                },
                                [
                                  _vm.formValidate.image
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.formValidate.image,
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#999" } },
                                    [_vm._v("(推荐尺寸：400 X 400像素)")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "表单标题：",
                                prop: "formTitle",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入表单标题",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { "max-width": "500px" },
                                attrs: {
                                  maxlength: "7",
                                  placeholder: "请输入表单标题",
                                },
                                model: {
                                  value: _vm.formValidate.formTitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "formTitle", $$v)
                                  },
                                  expression: "formValidate.formTitle",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "表单头图：",
                                prop: "formImage",
                                rules: [
                                  {
                                    required: true,
                                    message: "请上传图片",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap("2")
                                    },
                                  },
                                },
                                [
                                  _vm.formValidate.formImage
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.formValidate.formImage,
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#999" } },
                                    [
                                      _vm._v(
                                        "(推荐尺寸：宽度为750px，长度自定义，200dpi)"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "数据查看权限",
                                prop: "downloadPerson",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择权限",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    multiple: "",
                                    readonly: _vm.editView,
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.formValidate.downloadPerson,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "downloadPerson",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.downloadPerson",
                                  },
                                },
                                _vm._l(_vm.adminUserList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.realName,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentTab === 1
                ? _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "div",
                            { staticClass: "questions" },
                            _vm._l(
                              _vm.formValidate.questions,
                              function (question, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "question-item" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "question-c" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label:
                                                "问题" + (index + 6) + "：",
                                              prop:
                                                "questions." +
                                                index +
                                                ".qtitle",
                                              rules: [
                                                {
                                                  required: true,
                                                  message: "请输入问题",
                                                  trigger: "blur",
                                                },
                                              ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: {
                                                "max-width": "500px",
                                              },
                                              attrs: {
                                                readonly: _vm.editView,
                                                maxlength: "7",
                                                placeholder: "请输入问题",
                                              },
                                              model: {
                                                value: question.qtitle,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    question,
                                                    "qtitle",
                                                    $$v
                                                  )
                                                },
                                                expression: "question.qtitle",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "option-box" },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "0px",
                                                },
                                                attrs: {
                                                  label: "是否必填：",
                                                  prop:
                                                    "questions." +
                                                    index +
                                                    ".required",
                                                  rules: [{ required: true }],
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    attrs: {
                                                      disabled: _vm.editView,
                                                    },
                                                    model: {
                                                      value: question.required,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          question,
                                                          "required",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "question.required",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("必填")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio",
                                                      { attrs: { label: 0 } },
                                                      [_vm._v("非必填")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "0px",
                                                },
                                                attrs: {
                                                  label: "题型：",
                                                  prop:
                                                    "questions." +
                                                    index +
                                                    ".type",
                                                  rules: [{ required: true }],
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    attrs: {
                                                      disabled: _vm.editView,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.changeQuestionType(
                                                          index,
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: question.type,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          question,
                                                          "type",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "question.type",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("填空")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("单选")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio",
                                                      { attrs: { label: 3 } },
                                                      [_vm._v("多选")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            question.type > 0
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    question.options,
                                                    function (option, oIndex) {
                                                      return _c(
                                                        "el-form-item",
                                                        {
                                                          key: oIndex,
                                                          attrs: {
                                                            label:
                                                              "选项" +
                                                              _vm.optionLabel[
                                                                oIndex
                                                              ] +
                                                              "：",
                                                            "label-width":
                                                              "80px",
                                                            prop:
                                                              "questions." +
                                                              index +
                                                              ".options." +
                                                              oIndex +
                                                              ".title",
                                                            rules: [
                                                              {
                                                                required: true,
                                                                validator:
                                                                  _vm.validateOptions,
                                                                trigger: "blur",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "option-row",
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  maxlength:
                                                                    "15",
                                                                  readonly:
                                                                    _vm.editView,
                                                                  placeholder:
                                                                    "请输入问题",
                                                                },
                                                                model: {
                                                                  value:
                                                                    option.title,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        option,
                                                                        "title",
                                                                        typeof $$v ===
                                                                          "string"
                                                                          ? $$v.trim()
                                                                          : $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "option.title",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              !_vm.editView
                                                                ? _c("i", {
                                                                    staticClass:
                                                                      "el-icon-circle-plus-outline option-p",
                                                                    class: {
                                                                      disabled:
                                                                        question
                                                                          .options
                                                                          .length ==
                                                                        _vm
                                                                          .optionLabel
                                                                          .length,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.addOption(
                                                                            index,
                                                                            oIndex
                                                                          )
                                                                        },
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              !_vm.editView
                                                                ? _c("i", {
                                                                    staticClass:
                                                                      "el-icon-remove-outline option-p",
                                                                    class: {
                                                                      disabled:
                                                                        question
                                                                          .options
                                                                          .length ==
                                                                        1,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.delOption(
                                                                            index,
                                                                            oIndex
                                                                          )
                                                                        },
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    !_vm.editView
                                      ? _c(
                                          "div",
                                          { staticClass: "question-p" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addQuestion(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("新增问题")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  default: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delQuestion(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除问题")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          !_vm.editView &&
                          _vm.formValidate.questions &&
                          _vm.formValidate.questions.length == 0
                            ? _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", plain: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addQuestion(-1)
                                        },
                                      },
                                    },
                                    [_vm._v("新增问题")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        [
                          _vm.currentTab === 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.nextStep },
                                },
                                [_vm._v("下一步")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentTab === 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.submitLoading,
                                  },
                                  on: { click: _vm.submit },
                                },
                                [_vm._v("\n              提交")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
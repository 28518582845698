"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListArticle = ListArticle;
exports.deleteContent = deleteContent;
exports.deletePic = deletePic;
exports.deleteUserTag = deleteUserTag;
exports.findInfo = findInfo;
exports.findPageAndList = findPageAndList;
exports.getArticleList = getArticleList;
exports.getCategory = getCategory;
exports.getCompleteMessage = getCompleteMessage;
exports.getContentList = getContentList;
exports.getCustomer = getCustomer;
exports.getCustomerList = getCustomerList;
exports.getCustomerUserList = getCustomerUserList;
exports.getFaqList = getFaqList;
exports.getLogisticsList = getLogisticsList;
exports.getOrderDetail = getOrderDetail;
exports.getOrderMessage = getOrderMessage;
exports.getPicList = getPicList;
exports.onlineUserList = onlineUserList;
exports.saveContent = saveContent;
exports.saveOrUpdate = saveOrUpdate;
exports.saveUserTag = saveUserTag;
exports.updateContent = updateContent;
exports.updateStatus = updateStatus;
exports.uploadPic = uploadPic;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 获取用户信息
 * @param pram
 */
function getCustomer(openid) {
  var data = {
    openid: openid
  };
  return (0, _request.default)({
    url: '/public/thirdkefu/message/customer',
    method: 'get',
    params: data
  });
}
// 快捷回复获取父级分类
function getCategory() {
  return (0, _request.default)({
    url: '/public/thirdkefu/message/category',
    method: 'get'
  });
}
// 保存快捷回复信息
function saveContent(data) {
  return (0, _request.default)({
    url: '/admin/faq/save',
    method: 'post',
    data: data
  });
}
// 获取快捷回复信息列表
function getContentList(data) {
  return (0, _request.default)({
    url: '/admin/faq/list',
    method: 'get',
    params: data
  });
}

// 删除快捷回复内容
function deleteContent(data) {
  return (0, _request.default)({
    url: '/admin/faq/delete',
    method: 'get',
    params: data
  });
}

// 修改快捷回复内容
function updateContent(data) {
  return (0, _request.default)({
    url: '/admin/faq/update',
    method: 'post',
    data: data
  });
}
// 快捷回复内容修改状态
function updateStatus(data) {
  return (0, _request.default)({
    url: '/admin/faq/updateStatus',
    method: 'get',
    params: data
  });
}
// 
function getFaqList(data) {
  return (0, _request.default)({
    url: '/public/thirdkefu/message/faqList',
    method: 'get',
    params: data
  });
}

// 种草列表
function getArticleList(data) {
  return (0, _request.default)({
    url: '/public/thirdkefu/message/articleList',
    method: 'get',
    params: data
  });
}
// 公众号图片库
function getPicList(data) {
  return (0, _request.default)({
    url: '/admin/wechat/media/list',
    method: 'get',
    params: data
  });
}
// 上传图片
function uploadPic(data, params) {
  return (0, _request.default)({
    url: '/admin/wechat/media/upload',
    method: 'post',
    params: params,
    data: data
  });
}
// 删除图片
function deletePic(id) {
  return (0, _request.default)({
    url: "/admin/wechat/media/delete/".concat(id),
    method: 'get'
  });
}
// 删除标签
function deleteUserTag(data) {
  return (0, _request.default)({
    url: "/public/thirdkefu/message/deleteUserTag",
    method: 'post',
    data: data
  });
}
// 新增标签
function saveUserTag(data) {
  return (0, _request.default)({
    url: "/public/thirdkefu/message/saveUserTag",
    method: 'post',
    data: data
  });
}
// 查询备注
function findInfo(data) {
  return (0, _request.default)({
    url: "/admin/markname/info",
    method: 'get',
    params: data
  });
}
// 新增备注
function saveOrUpdate(data) {
  return (0, _request.default)({
    url: "/admin/markname/saveOrUpdate",
    method: 'post',
    data: data
  });
}

// 选择客服列表
function getCustomerList() {
  return (0, _request.default)({
    url: "/public/thirdkefu/message/customerList",
    method: 'get'
  });
}

// 获取订单信息列表
function getOrderMessage(data) {
  return (0, _request.default)({
    url: '/admin/store/order/list',
    method: 'get',
    params: data
  });
}
// 获取订单详情 
function getOrderDetail(data) {
  return (0, _request.default)({
    url: "/public/thirdkefu/message/orderDetail/".concat(data),
    method: 'get'
  });
}
// 判断用户是否重复登陆 
function onlineUserList(data) {
  return (0, _request.default)({
    url: "/public/thirdkefu/message/loginCount/".concat(data),
    method: 'get'
  });
}
// 发送直播时获取直播间图片
function findPageAndList(data) {
  return (0, _request.default)({
    url: "/admin/liveImage/liveImageFindById",
    method: 'get',
    params: data
  });
}
// 在已完成列表点击用户
function getCompleteMessage(data) {
  return (0, _request.default)({
    url: "/public/thirdkefu/message/getMessageList",
    method: 'get',
    params: data
  });
}

// 获取文章列表
function ListArticle(data) {
  return (0, _request.default)({
    url: '/admin/article/list',
    method: 'GET',
    params: data
  });
}
// leader获取已完成聊天记录
function getCustomerUserList(data) {
  return (0, _request.default)({
    url: '/public/thirdkefu/message/getCustomerUserList',
    method: 'GET',
    params: data
  });
}

// 获取物流信息接口
function getLogisticsList(data) {
  return (0, _request.default)({
    url: '/admin/store/order/logisticsList',
    method: 'GET',
    params: data
  });
}
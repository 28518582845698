"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = _interopRequireDefault(require("element-ui"));
require("@/styles/element-variables.scss");
var _index = _interopRequireDefault(require("./index.vue"));
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size
});
var couponFrom = {};
couponFrom.install = function (Vue, options) {
  var ToastConstructor = Vue.extend(_index.default);
  // 生成一个该子类的实例
  var instance = new ToastConstructor();
  instance.$mount(document.createElement('div'));
  document.body.appendChild(instance.$el);
  Vue.prototype.$modalCoupon = function (handle, keyNum) {
    var coupons = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    var callback = arguments.length > 3 ? arguments[3] : undefined;
    var userIds = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
    instance.visible = true;
    instance.handle = handle;
    instance.keyNum = keyNum;
    instance.coupons = coupons;
    instance.userIds = userIds;
    instance.callback = callback;
  };
};
var _default = exports.default = couponFrom;
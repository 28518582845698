"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.captchaApi = captchaApi;
exports.exportUserExcel = exportUserExcel;
exports.foundsApi = foundsApi;
exports.getInfo = getInfo;
exports.getLoginPicApi = getLoginPicApi;
exports.groupDeleteApi = groupDeleteApi;
exports.groupInfoApi = groupInfoApi;
exports.groupListApi = groupListApi;
exports.groupPiApi = groupPiApi;
exports.groupSaveApi = groupSaveApi;
exports.groupUpdateApi = groupUpdateApi;
exports.importSaleFirmApi = importSaleFirmApi;
exports.infobyconditionApi = infobyconditionApi;
exports.levelDeleteApi = levelDeleteApi;
exports.levelInfoApi = levelInfoApi;
exports.levelListApi = levelListApi;
exports.levelSaveApi = levelSaveApi;
exports.levelUpdateApi = levelUpdateApi;
exports.levelUseApi = levelUseApi;
exports.login = login;
exports.logout = logout;
exports.resetSaleMatchApi = resetSaleMatchApi;
exports.tagDeleteApi = tagDeleteApi;
exports.tagInfoApi = tagInfoApi;
exports.tagListApi = tagListApi;
exports.tagPiApi = tagPiApi;
exports.tagSaveApi = tagSaveApi;
exports.tagUpdateApi = tagUpdateApi;
exports.topdetailApi = topdetailApi;
exports.updateSpreadApi = updateSpreadApi;
exports.userDeleteApi = userDeleteApi;
exports.userInfoApi = userInfoApi;
exports.userListApi = userListApi;
exports.userNewSaleApi = userNewSaleApi;
exports.userUpdateApi = userUpdateApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function login(data) {
  return (0, _request.default)({
    url: '/admin/login',
    method: 'post',
    data: data
  });
}
function getInfo(token) {
  return (0, _request.default)({
    url: '/admin/getAdminInfoByToken',
    method: 'get',
    params: {
      token: token
    }
  });
}
function logout() {
  return (0, _request.default)({
    url: '/admin/logout',
    method: 'get'
  });
}

/**
 * 会员管理 列表
 * @param pram
 */
function userListApi(params) {
  return (0, _request.default)({
    url: "/admin/user/list",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 修改
 * @param pram
 */
function userUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/admin/user/update",
    method: 'post',
    params: params,
    data: data
  });
}

/**
 * 会员管理 新增为销售
 * @param data
 */
function userNewSaleApi(data) {
  return (0, _request.default)({
    url: "/admin/marketing/saleFairDistribution/newSales",
    method: 'post',
    data: data
  });
}

/**
 * 会员管理 详情
 * @param pram
 */
function userInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/user/info",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 账户详情
 * @param pram
 */
function infobyconditionApi(params) {
  return (0, _request.default)({
    url: "/admin/user/infobycondition",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 账户详情top数据
 * @param pram
 */
function topdetailApi(params) {
  return (0, _request.default)({
    url: "/admin/user/topdetail",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 批量设置分组
 * @param pram
 */
function groupPiApi(params) {
  return (0, _request.default)({
    url: "/admin/user/group",
    method: 'post',
    params: params
  });
}

/**
 * 会员管理 批量设置标签
 * @param pram
 */
function tagPiApi(params) {
  return (0, _request.default)({
    url: "/admin/user/tag",
    method: 'post',
    params: params
  });
}

/**
 * 会员管理 积分余额
 * @param pram
 */
function foundsApi(params) {
  return (0, _request.default)({
    url: "/admin/user/operate/founds",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 删除
 * @param pram
 */
function userDeleteApi(params) {
  return (0, _request.default)({
    url: "/admin/user/delete",
    method: 'get',
    params: params
  });
}

/**
 * 会员等级 列表
 * @param pram
 */
function levelListApi(params) {
  return (0, _request.default)({
    url: "/admin/system/user/level/list",
    method: 'get',
    params: params
  });
}

/**
 * 会员等级 新增
 * @param pram
 */
function levelSaveApi(data) {
  return (0, _request.default)({
    url: "/admin/system/user/level/save",
    method: 'post',
    data: data
  });
}

/**
 * 会员等级 编辑
 * @param pram
 */
function levelUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/admin/system/user/level/update",
    method: 'post',
    params: params,
    data: data
  });
}

/**
 * 会员等级 详情
 * @param pram
 */
function levelInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/system/user/level/info",
    method: 'get',
    params: params
  });
}

/**
 * 会员等级 删除
 * @param pram
 */
function levelDeleteApi(params) {
  return (0, _request.default)({
    url: "/admin/system/user/level/delete",
    method: 'get',
    params: params
  });
}

/**
 * 会员等级 是否显示
 * @param pram
 */
function levelUseApi(params) {
  return (0, _request.default)({
    url: "/admin/system/user/level/use",
    method: 'get',
    params: params
  });
}

/**
 * 会员标签 列表
 * @param pram
 */
function tagListApi(params) {
  return (0, _request.default)({
    url: "/admin/user/tag/list",
    method: 'get',
    params: params
  });
}

/**
 * 会员标签 新增
 * @param pram
 */
function tagSaveApi(data) {
  return (0, _request.default)({
    url: "/admin/user/tag/save",
    method: 'post',
    data: data
  });
}

/**
 * 会员标签 编辑
 * @param pram
 */
function tagUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/admin/user/tag/update",
    method: 'post',
    params: params,
    data: data
  });
}

/**
 * 会员标签 详情
 * @param pram
 */
function tagInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/user/tag/info",
    method: 'get',
    params: params
  });
}

/**
 * 会员标签 删除
 * @param pram
 */
function tagDeleteApi(params) {
  return (0, _request.default)({
    url: "/admin/user/tag/delete",
    method: 'get',
    params: params
  });
}

/**
 * 会员分组 列表
 * @param pram
 */
function groupListApi(params) {
  return (0, _request.default)({
    url: "/admin/user/group/list",
    method: 'get',
    params: params
  });
}

/**
 * 会员分组 新增
 * @param pram
 */
function groupSaveApi(data) {
  return (0, _request.default)({
    url: "/admin/user/group/save",
    method: 'post',
    data: data
  });
}

/**
 * 会员分组 编辑
 * @param pram
 */
function groupUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/admin/user/group/update",
    method: 'post',
    params: params,
    data: data
  });
}

/**
 * 会员分组 详情
 * @param pram
 */
function groupInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/user/group/info",
    method: 'get',
    params: params
  });
}

/**
 * 会员分组 删除
 * @param pram
 */
function groupDeleteApi(params) {
  return (0, _request.default)({
    url: "/admin/user/group/delete",
    method: 'get',
    params: params
  });
}

/**
 *获取登录页图片
 */
function getLoginPicApi() {
  return (0, _request.default)({
    url: "/admin/getLoginPic",
    method: 'get'
  });
}

/**
 * @description 验证码
 */
function captchaApi() {
  return (0, _request.default)({
    url: "/admin/validate/code/get",
    method: 'get'
  });
}

/**
 * @description 修改上级推广人
 */
function updateSpreadApi(data) {
  return (0, _request.default)({
    url: "/admin/user/update/spread",
    method: 'post',
    data: data
  });
}

/**
 *
 * 用户信息导出
 */

function exportUserExcel(params) {
  return (0, _request.default)({
    url: '/admin/user/exportUser',
    method: 'get',
    params: params
  });
}

/**
 * @description 导入销售与企业信息
 */
function importSaleFirmApi(data) {
  return (0, _request.default)({
    url: "/admin/marketing/saleFairDistribution/importFairDistributionByExcel",
    method: 'post',
    data: data
  });
}

/**
 *
 * 重置匹配关系
 */

function resetSaleMatchApi() {
  return (0, _request.default)({
    url: '/admin/marketing/saleFairDistribution/initFairDistributionFromCL',
    method: 'get'
  });
}
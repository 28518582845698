"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'AppMain',
  data: function data() {
    return {
      links: [
        // {
        //   title: '官网',
        //   key: '1',
        //   href: 'https://www.pactera.com',
        //   blankTarget: true
        // },
        // {
        //   title: '社区',
        //   key: '2',
        //   href: 'http://www.pactera.com',
        //   blankTarget: true
        // },
        // {
        //   title: '文档',
        //   key: '3',
        //   href: 'https://www.pactera.com',
        //   blankTarget: true
        // }
      ]
    };
  },
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    }
  }
};
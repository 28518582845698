var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.psInfo
        ? _c("div", { staticClass: "acea-row row-middle" }, [
            _c("div", { staticClass: "avatar mr15" }, [
              _c("img", { attrs: { src: _vm.psInfo.user.avatar } }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dashboard-workplace-header-tip" }, [
              _c("p", {
                staticClass: "dashboard-workplace-header-tip-title",
                domProps: {
                  textContent: _vm._s(_vm.psInfo.user.nickname || "-"),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dashboard-workplace-header-tip-desc" },
                [
                  _c(
                    "span",
                    { staticClass: "dashboard-workplace-header-tip-desc-sp" },
                    [_vm._v("积分: " + _vm._s(_vm.psInfo.integralCount))]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "dashboard-workplace-header-tip-desc-sp" },
                    [_vm._v("来源渠道:")]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "span",
                      { staticClass: "dashboard-workplace-header-tip-desc-sp" },
                      [
                        _vm._v(
                          "累计下单次数: " + _vm._s(_vm.psInfo.allOrderCount)
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "dashboard-workplace-header-tip-desc-sp" },
                      [
                        _vm._v(
                          " 累计消费金额: " + _vm._s(_vm.psInfo.allConsumeCount)
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "dashboard-workplace-header-tip-desc-sp" },
                      [
                        _vm._v(
                          " 本月订单: " + _vm._s(_vm.psInfo.mothOrderCount)
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "dashboard-workplace-header-tip-desc-sp" },
                      [
                        _vm._v(
                          "本月消费金额: " + _vm._s(_vm.psInfo.mothConsumeCount)
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "ivu-mt mt20", attrs: { align: "middle", gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-vertical-demo",
                  attrs: { "default-active": "0" },
                  on: { select: _vm.changeType },
                },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "el-menu-item",
                    { key: index, attrs: { name: item.val, index: item.val } },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(item.label)),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 20 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "tabNumWidth",
                  attrs: {
                    data: _vm.tableData.data,
                    size: "mini",
                    "max-height": "400",
                  },
                },
                _vm._l(_vm.columns, function (item, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: item.key,
                      label: item.title,
                      width: "item.minWidth",
                    },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [6, 12, 18, 24],
                      "page-size": _vm.tableFrom.limit,
                      "current-page": _vm.tableFrom.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
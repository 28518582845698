"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _channel = require("@/api/channel");
var _clipboard = _interopRequireDefault(require("clipboard"));
var _accessPop = _interopRequireDefault(require("./accessPop"));
var systemGroupDataApi = _interopRequireWildcard(require("@/api/systemGroupData.js"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    accessPop: _accessPop.default
  },
  data: function data() {
    return {
      isDown: false,
      numDown: 1,
      total: 0,
      tableData: [],
      tableFrom: {
        page: 1,
        limit: 20,
        tempId: "",
        keywords: ""
      },
      optionList: [],
      optionLists: [],
      //所有的
      showOptionList: [],
      listLoading: false,
      ruleForm: {
        path: "",
        name: "",
        parentId: "",
        paramValueList: [],
        ownerList: []
      },
      editParamsId: "",
      dialogTitle: "新增投放链接",
      dialogFormVisible: false,
      isFlagAdd: true,
      url: "",
      rules: {
        name: [{
          required: true,
          message: "请输入投放链接名称",
          trigger: "blur"
        }],
        path: [{
          required: true,
          message: "请选择小程序路径",
          trigger: "blur"
        }],
        parentId: [{
          required: true,
          message: "请选择参数模板",
          trigger: ["blur", "change"]
        }],
        ownerList: [{
          required: true,
          message: "请选择活动区域",
          trigger: "change"
        }]
      },
      editRules: {
        ownerList: [{
          required: true,
          message: "请选择活动区域",
          trigger: "change"
        }]
      },
      pathLists: [
        // {
        //   path: "pages/index/index",
        // },
        // {
        //   path: "pages/goods_cate/goods_cate",
        // },
        // {
        //   path: "pages/goods_list/index",
        // },
        // {
        //   path: "pages/goods_details/index",
        // },
        // { path: "pages/leaveInfoForm/index" },
        // { path: "pages/live_list/live_list" },
        // {
        //   path: "pages/linkPath/linkPath",
        // },
        // { path: "pages/landing_page/index" },
        // { path: "pages/users/park/park" },
      ],
      isShow: false,
      // accessListObj: {
      //   name: "eeee",
      // },
      accessLists: [],
      accessFrom: {
        page: 1,
        limit: 10,
        linkId: ""
      },
      accessTotal: 0,
      currentId: "",
      adminUserList: [],
      isEditShow: false,
      editForm: {
        ownerList: [],
        name: ""
      }
    };
  },
  watch: {
    dialogTitle: function dialogTitle(val) {
      val == "查看投放链接" ? this.isFlagAdd = false : this.isFlagAdd = true;
      val == "查看投放链接" ? this.showOptionList = this.optionLists : this.showOptionList = this.optionList;
    }
  },
  created: function created() {
    var _this = this;
    // 获取模板
    var params = {
      limit: 100,
      page: 1,
      enable: true
    };
    (0, _channel.tempList)(params).then(function (res) {
      _this.optionList = res.list;
      _this.showOptionList = _this.optionList;
    });
    var paramss = {
      limit: 400,
      page: 1
    };
    (0, _channel.tempList)(paramss).then(function (res) {
      _this.optionLists = res.list;
    });
    this.getadminUserList();
    this.getList();
    this.getPagePathList();
  },
  methods: {
    // 获取小程序列表页面
    getPagePathList: function getPagePathList() {
      var _this2 = this;
      console.log("first");
      var pram = {
        gid: 135,
        limit: 1000
      };
      systemGroupDataApi.groupDataList(pram).then(function (data) {
        console.log(data);
        var _selfList = [];
        data.list.forEach(function (_lItem) {
          _lItem.value = JSON.parse(_lItem.value);
          var _fields = _lItem.value.fields;
          var _rowData = {};
          _fields.map(function (item) {
            _rowData[item.name] = item.value;
          });
          _rowData.id = _lItem.id;
          _rowData.sort = _lItem.sort;
          _rowData.status = _lItem.status;
          _selfList.push(_rowData);
        });
        _this2.pathLists = _selfList;
      });
    },
    // 获取管理员列表
    getadminUserList: function getadminUserList() {
      var _this3 = this;
      (0, _channel.getAdminListForSelectApi)().then(function (res) {
        res.forEach(function (item) {
          item.id = item.id + "";
        });
        _this3.adminUserList = res;
      });
    },
    handleDownClose: function handleDownClose() {
      this.isDown = false;
    },
    handleDown: function handleDown(row) {
      this.isDown = true;
      this.currentId = row.id;
      this.numDown = 1;
    },
    handleSubmitDown: function handleSubmitDown() {
      var _this4 = this;
      if (!this.numDown) {
        this.$message.warning("请输入下载数量");
        return;
      }
      (0, _channel.batchLink)(this.currentId, this.numDown).then(function (res) {
        window.open(res.fileName);
        _this4.isDown = false;
      });
    },
    handleAccessClose: function handleAccessClose() {
      this.isShow = false;
    },
    handleAccess: function handleAccess(row) {
      this.accessFrom.linkId = row.id;
      this.accessFrom.page = 1;
      this.isShow = true;
      this.getAccess();
    },
    getAccess: function getAccess() {
      var that = this;
      (0, _channel.promotionList)(this.accessFrom).then(function (res) {
        console.log(res);
        that.accessLists = res.list;
        that.accessTotal = res.total;
      });
    },
    handleAccessSize: function handleAccessSize(val) {
      this.accessFrom.limit = val;
      this.getAccess();
    },
    pageAccessChange: function pageAccessChange(page) {
      this.accessFrom.page = page;
      this.getAccess();
    },
    goLinkPage: function goLinkPage() {
      this.isLinkPage = true;
    },
    getTempName: function getTempName(val) {
      var id = val;
      var arr = [];
      arr = this.optionLists.filter(function (item) {
        return item.id == id;
      });
      if (arr.length == 0) {
        return "";
      } else {
        return arr[0].name;
      }
    },
    getList: function getList() {
      var _this5 = this;
      (0, _channel.contentList)(this.tableFrom).then(function (res) {
        _this5.tableData = res.list;
        _this5.total = res.total;
      });
    },
    handleChangeDetail: function handleChangeDetail(id) {
      var _this6 = this;
      var that = this;
      this.optionList.forEach(function (item) {
        if (item.id == id) {
          that.ruleForm.paramValueList = item.paramList;
          console.log(that.ruleForm.paramValueList, "that.ruleForm.paramValueList");
          that.ruleForm.paramValueList.forEach(function (m) {
            console.log(m, "mmmmm");
            _this6.$set(m, "value", "");
          });
        }
      });
    },
    handleClose: function handleClose() {
      this.$refs["ruleFormV"].resetFields();
      this.dialogFormVisible = false;
    },
    handleCancel: function handleCancel(formName) {
      this.dialogFormVisible = false;
      this.$refs[formName].resetFields();
    },
    addParamPop: function addParamPop() {
      this.dialogTitle = "新增投放链接";
      this.dialogFormVisible = true;
      this.ruleForm = {
        name: "",
        path: "",
        parentId: "",
        paramValueList: []
      };
    },
    handleEdit: function handleEdit(value) {
      console.log("value", value);
      this.isEditShow = true;
      this.editParamsId = value.id;
      this.editForm.name = value.name;
      this.editForm.ownerList = value.ownersList.split(",");
    },
    handleEditCancel: function handleEditCancel() {
      this.isEditShow = false;
      this.editForm = {};
      this.editParamsId = "";
    },
    handleEditSubmit: function handleEditSubmit(formName) {
      var _this7 = this;
      console.log(formName);
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          console.log("valid", valid);
          var data = _objectSpread(_objectSpread({}, _this7.editForm), {}, {
            id: _this7.editParamsId
          });
          (0, _channel.contentUpdate)(data).then(function (res) {
            _this7.isEditShow = false;
            _this7.getList();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleEditClose: function handleEditClose() {
      this.isEditShow = false;
      this.editForm = {};
      this.editParamsId = "";
    },
    onEdit: function onEdit(value) {
      var _this8 = this;
      var that = this;
      this.dialogTitle = "查看投放链接";
      this.dialogFormVisible = true;
      console.log(value, "value");
      console.log(value.ownersList.split(","));
      this.$nextTick(function () {
        _this8.ruleForm.name = value.name;
        _this8.ruleForm.path = value.paramContent.generateParam.path;
        _this8.ruleForm.parentId = value.parentId;
        _this8.ruleForm.paramValueList = value.paramContent.paramValueList;
        _this8.ruleForm.ownerList = value.ownersList.split(",");
        console.log(_this8.ruleForm, "this.ruleForm");
        // this.ruleForm.path = Object.assign({}, value.paramContent.generateParam.path);
      });
    },
    handleQrcode: function handleQrcode(value) {
      (0, _channel.getQrCode)(value.id).then(function (res) {
        console.log(res, "resQrcode");
        if (res.code) {
          var imgUrl = res.code;
          if (window.navigator.msSaveOrOpenBlob) {
            var bstr = atob(imgUrl.split(",")[1]);
            var n = bstr.length;
            var u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            var blob = new Blob([u8arr]);
            window.navigator.msSaveOrOpenBlob(blob, "dell-download" + "." + "png");
          } else {
            // 这里就按照chrome等新版浏览器来处理
            var a = document.createElement("a");
            a.href = imgUrl;
            a.setAttribute("download", "dell-download");
            a.click();
          }
        } else {
          var _imgUrl = res.qrCodeUrl;
          if (window.navigator.msSaveOrOpenBlob) {
            var _bstr = atob(_imgUrl.split(",")[1]);
            var _n = _bstr.length;
            var _u8arr = new Uint8Array(_n);
            while (_n--) {
              _u8arr[_n] = _bstr.charCodeAt(_n);
            }
            var _blob = new Blob([_u8arr]);
            window.navigator.msSaveOrOpenBlob(_blob, "dell-download" + "." + "png");
          } else {
            // 这里就按照chrome等新版浏览器来处理
            var _a = document.createElement("a");
            _a.href = _imgUrl;
            _a.setAttribute("download", "dell-download");
            _a.click();
          }
        }
        // return
        // const imgUrl = res.code;
        // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
      }).catch(function (res) {
        console.log(res, "error");
      });
    },
    handleCopy1: function handleCopy1(value) {
      var _this9 = this;
      var that = this;
      (0, _channel.getCopyUrl)(value.id).then(function (res) {
        console.log(res);
        _this9.url = res.promotionUrl;
        setTimeout(function () {
          //模拟点击真正复制链接的按钮
          _this9.$refs.copy.click();
        }, 100);
      });
    },
    handleCopy: function handleCopy(value) {
      var _this10 = this;
      var that = this;
      (0, _channel.getUrlLink)(value.id).then(function (res) {
        console.log(res, "res");
        _this10.url = res.url_link;
        setTimeout(function () {
          //模拟点击真正复制链接的按钮
          _this10.$refs.copy.click();
        }, 100);
      });
    },
    copy: function copy(evnet) {
      var _this11 = this;
      evnet.preventDefault();
      var clipboard = new _clipboard.default(".copyU");
      clipboard.on("success", function (e) {
        _this11.$message.success("复制成功！");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", function (e) {
        // 不支持复制
        _this11.$message.success("复制失败！");
        // 释放内存
        clipboard.destroy();
      });
    },
    handleDelete: function handleDelete(id, index) {
      var _this12 = this;
      var that = this;
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _channel.deleteId)(id).then(function (res) {
          _this12.$message({
            type: "success",
            message: "删除成功!"
          });
          that.tableFrom.page = 1;
          that.getList();
        });
      }).catch(function () {
        _this12.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    handleSubmit: function handleSubmit(formName) {
      var _this13 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this13.dealData();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    dealData: function dealData() {
      var that = this;
      if (this.dialogTitle == "新增投放链接") {
        var params = {
          name: this.ruleForm.name,
          parentId: this.ruleForm.parentId,
          paramContent: {
            generateParam: {
              path: this.ruleForm.path
            },
            paramValueList: this.ruleForm.paramValueList
          },
          ownerList: this.ruleForm.ownerList
        };
        (0, _channel.contentSave)(params).then(function (res) {
          that.dealSuccess();
        });
      } else {}
    },
    dealSuccess: function dealSuccess() {
      this.$message.success("操作成功");
      this.$refs["ruleFormV"].resetFields();
      this.dialogFormVisible = false;
      this.tableFrom.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addGoods = addGoods;
exports.addLive = addLive;
exports.delImage = delImage;
exports.deleteGoods = deleteGoods;
exports.deleteLive = deleteLive;
exports.editLive = editLive;
exports.findImageListByIds = findImageListByIds;
exports.getProductList = getProductList;
exports.getStreamList = getStreamList;
exports.goodsIntoLive = goodsIntoLive;
exports.liveGetPushUrl = liveGetPushUrl;
exports.modifyImage = modifyImage;
exports.saveImage = saveImage;
exports.updateGoods = updateGoods;
exports.wechatUploadApi = wechatUploadApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 获取直播列表
 * @param pram
 */
function getStreamList(data) {
  return (0, _request.default)({
    url: '/admin/live/getLiveInfo',
    method: 'post',
    data: data
  });
}
// 直播间上传图片
function wechatUploadApi(data, params) {
  return (0, _request.default)({
    url: "/admin/wechat/media/tempUpload",
    method: 'post',
    data: data,
    params: params
  });
}
// 获取商品列表
function getProductList(data) {
  return (0, _request.default)({
    url: '/admin/liveGoods/getapproved',
    method: 'get',
    params: data
  });
}
// 删除直播间
function deleteLive(data) {
  return (0, _request.default)({
    url: '/admin/live/deleteLive',
    method: 'post',
    data: data
  });
}
// 直播间推流
function liveGetPushUrl(data) {
  return (0, _request.default)({
    url: '/admin/live/liveGetPushUrl',
    method: 'get',
    params: data
  });
}
// 直播间导入商品
function goodsIntoLive(data) {
  return (0, _request.default)({
    url: '/admin/live/goodsIntoLive',
    method: 'post',
    data: data
  });
}
// 直播间商品管理页面删除商品
function deleteGoods(data) {
  return (0, _request.default)({
    url: '/admin/liveGoods/delete',
    method: 'post',
    data: data
  });
}

// 直播间商品管理页面更新商品
function updateGoods(data) {
  return (0, _request.default)({
    url: '/admin/liveGoods/update',
    method: 'post',
    data: data
  });
}

// 直播间商品管理页面新增商品
function addGoods(data) {
  return (0, _request.default)({
    url: '/admin/liveGoods/add',
    method: 'post',
    data: data
  });
}
// 新增直播间
function addLive(data) {
  return (0, _request.default)({
    url: '/admin/live/createLive',
    method: 'post',
    data: data
  });
}
// 编辑直播间
function editLive(data) {
  return (0, _request.default)({
    url: '/admin/live/updateLive',
    method: 'post',
    data: data
  });
}
// 直播间图片另存 
// 保存
function saveImage(data) {
  return (0, _request.default)({
    url: '/admin/liveImage/save',
    method: 'post',
    data: data
  });
}
// 修改/api/admin/liveImage/update
function modifyImage(data) {
  return (0, _request.default)({
    url: '/admin/liveImage/update',
    method: 'post',
    data: data
  });
}
// 删除
function delImage(data) {
  return (0, _request.default)({
    url: '/admin/liveImage/delete',
    method: 'post',
    params: data
  });
}
// 获取直播间图片
function findImageListByIds(data) {
  return (0, _request.default)({
    url: '/admin/live/findImageListByIds',
    method: 'get',
    params: data
  });
}
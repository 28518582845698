"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _pageLink = _interopRequireDefault(require("./pageLink.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: "myModel",
  components: {
    PageLink: _pageLink.default
  },
  data: function data() {
    return {
      pageLinkShow: false,
      pageLinkData: {},
      rowModel: {
        appId: "",
        jumpPath: "",
        jumpStatus: false,
        jumpType: 0,
        pic: ""
      },
      bannerModel: {},
      bannerModelShow: this.bannerShow
    };
  },
  props: {
    modleList: {
      type: Array,
      default: []
    },
    onchange: {
      type: Function,
      default: function _default() {}
    },
    type: {
      type: String,
      default: ""
    },
    showAddBtn: {
      type: Boolean,
      default: true
    },
    bannerShow: {
      type: Boolean,
      default: false
    }
  },
  created: function created() {
    if (this.modleList.length == 0 && this.bannerShow) {
      this.bannerModelShow = this.bannerShow;
    } else {
      this.bannerModelShow = false;
    }
    this.bannerModel = Object.assign({}, this.rowModel);
  },
  watch: {
    "bannerShow": function bannerShow(val, oldVal) {
      this.bannerModelShow = val;
    }
  },
  methods: {
    handleSetUrl: function handleSetUrl(keyName, keyIndex) {
      var _this2 = this;
      var pageData = {};
      if (keyIndex > -1 || keyName == "sceneList") {
        pageData = Object.assign({}, this.modleList[keyIndex]);
      } else {
        switch (keyName) {
          case "bannerList":
            pageData = Object.assign({}, this.bannerModel);
            break;
          case "corpAuthList":
            pageData = Object.assign({}, this.firmModel);
            break;
          case "solutionList":
            pageData = Object.assign({}, this.solutionModel);
            break;
        }
      }
      var _pageData = pageData,
        appId = _pageData.appId,
        jumpPath = _pageData.jumpPath,
        jumpStatus = _pageData.jumpStatus,
        jumpType = _pageData.jumpType;
      this.pageLinkData = {
        keyName: keyName,
        keyIndex: keyIndex,
        appId: appId,
        jumpPath: jumpPath,
        jumpStatus: jumpStatus,
        jumpType: jumpType
      };
      this.pageLinkShow = true;
      this.$nextTick(function () {
        _this2.$refs["pageLinkSet"].initEditData();
      });
    },
    cancleSetUrl: function cancleSetUrl() {
      this.pageLinkData = {};
    },
    updateUrlSet: function updateUrlSet(config) {
      var configNew = {
        appId: config.appId,
        jumpPath: config.jumpPath,
        jumpStatus: config.jumpStatus,
        jumpType: config.jumpType
      };
      if (config.keyIndex > -1 || config.keyName == "sceneList") {
        var arr = this.modleList;
        arr[config.keyIndex] = Object.assign(arr[config.keyIndex], configNew);
        this.onchange(arr);
      } else {
        switch (config.keyName) {
          case "bannerList":
            this.bannerModel = Object.assign(this.bannerModel, configNew);
            break;
          case "corpAuthList":
            this.firmModel = Object.assign(this.firmModel, configNew);
            break;
          case "solutionList":
            this.solutionModel = Object.assign(this.solutionModel, configNew);
            break;
        }
      }
    },
    submitBannerRow: function submitBannerRow() {
      //校验数据
      if (this.bannerModel.pic) {
        var arr = [].concat(_toConsumableArray(this.modleList), [JSON.parse(JSON.stringify(this.bannerModel))]);
        this.onchange(arr);
        this.bannerCancle();
      } else {
        this.$message.warning("请上传图片");
      }
    },
    bannerCancle: function bannerCancle() {
      // this.bannerModel = null;
      this.bannerModelShow = false;
      this.bannerModel = Object.assign({}, this.rowModel);
    },
    addBanner: function addBanner() {
      if (!this.bannerModelShow) {
        this.bannerModelShow = true;
        this.bannerModel = Object.assign({}, _objectSpread(_objectSpread({}, this.rowModel), {}, {
          type: this.type
        }));
      }
    },
    delBanner: function delBanner(index) {
      var _this3 = this;
      this.$confirm("确认删除?", "提示").then(function () {
        var arr = _this3.modleList.filter(function (item, i) {
          return i !== index;
        });
        _this3.onchange(arr);
      });
    },
    modalBannerPicTap: function modalBannerPicTap(index) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (index > -1) {
          var arr = _this.modleList;
          arr[index].pic = img[0].sattDir;
          _this.onchange(arr);
        } else {
          _this.bannerModel.pic = img[0].sattDir;
          console.log(_this.bannerModel.pic);
        }
      }, "1", "landing");
    }
  }
};
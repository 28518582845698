var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules, "label-width": "80px" },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "短信类型", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "medium" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("胜券短信"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("阿里大于"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "短信模板", prop: "templateId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择短信模板" },
                      model: {
                        value: _vm.form.templateId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "templateId", $$v)
                        },
                        expression: "form.templateId",
                      },
                    },
                    _vm._l(_vm.templateList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "短信签名", prop: "sign" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择短信模板" },
                      model: {
                        value: _vm.form.sign,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sign", $$v)
                        },
                        expression: "form.sign",
                      },
                    },
                    _vm._l(_vm.signList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "短信内容", prop: "content" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 5,
                      placeholder:
                        "请商家以56个字编辑短信内容，系统默认包含8个字：【胜券】退订TD",
                      maxlength: "56",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "短信测试", prop: "type" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入测试手机号" },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.testConnect },
                    },
                    [_vm._v(" 链接测试 ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.previewContent },
                    },
                    [_vm._v("\n          内容预览\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogStart,
            width: "70%",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogStart = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "流程名称", prop: "name" } },
                        [
                          _c("el-input", {
                            staticClass: "set-input",
                            attrs: { placeholder: "请输入流程名称" },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "开启时间", prop: "from" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              type: "datetime",
                              placeholder: "选择开启时间",
                            },
                            model: {
                              value: _vm.form.from,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "from", $$v)
                              },
                              expression: "form.from",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "流程类型", prop: "isLimit" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.isLimit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isLimit", $$v)
                                },
                                expression: "form.isLimit",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("不限"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("限定"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.form.isLimit == 1
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "流程结束", prop: "to" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: "选择结束时间",
                                },
                                model: {
                                  value: _vm.form.to,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "to", $$v)
                                  },
                                  expression: "form.to",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "人群选择", prop: "groupId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.groupId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "groupId", $$v)
                                },
                                expression: "form.groupId",
                              },
                            },
                            _vm._l(_vm.groupList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.description,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "流程开启", prop: "type" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "event" },
                              model: {
                                value: _vm.form.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "type", $$v)
                                },
                                expression: "form.type",
                              },
                            },
                            [_vm._v("从事件开启")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "datetime" },
                              model: {
                                value: _vm.form.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "type", $$v)
                                },
                                expression: "form.type",
                              },
                            },
                            [_vm._v("按时间开始")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _vm.form.type == "event"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "开始事件", prop: "event" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.form.event,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "event", $$v)
                                    },
                                    expression: "form.event",
                                  },
                                },
                                _vm._l(_vm.getEventDict, function (item) {
                                  return _c("el-option", {
                                    key: item.name,
                                    attrs: {
                                      label: item.name,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.form.type == "datetime"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "执行时间", prop: "rule" } },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "atOnce" },
                                  model: {
                                    value: _vm.form.rule,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "rule", $$v)
                                    },
                                    expression: "form.rule",
                                  },
                                },
                                [_vm._v("立刻")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "assign" },
                                  model: {
                                    value: _vm.form.rule,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "rule", $$v)
                                    },
                                    expression: "form.rule",
                                  },
                                },
                                [_vm._v("特定时间")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "repeat" },
                                  model: {
                                    value: _vm.form.rule,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "rule", $$v)
                                    },
                                    expression: "form.rule",
                                  },
                                },
                                [_vm._v("重复时间")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.form.rule == "assign" && _vm.form.type == "datetime"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "特定时间", prop: "assignTime" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: "选择时间",
                                },
                                model: {
                                  value: _vm.form.assignTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "assignTime", $$v)
                                  },
                                  expression: "form.assignTime",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.form.rule == "repeat" && _vm.form.type == "datetime"
                        ? _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "时间设置",
                                        prop: "unit",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          attrs: { size: "mini" },
                                          on: { change: _vm.handleChange },
                                          model: {
                                            value: _vm.form.unit,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "unit", $$v)
                                            },
                                            expression: "form.unit",
                                          },
                                        },
                                        _vm._l(
                                          _vm.timeRepeatList,
                                          function (item) {
                                            return _c(
                                              "el-radio-button",
                                              {
                                                key: item.id,
                                                attrs: { label: item.id },
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.form.unit == "day" &&
                                            _vm.form.type == "datetime",
                                          expression:
                                            "form.unit == 'day' && form.type == 'datetime'",
                                        },
                                      ],
                                      attrs: { prop: "dayNumber" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                每\n                "
                                      ),
                                      _c("el-input-number", {
                                        attrs: {
                                          min: 1,
                                          size: "mini",
                                          precision: 0,
                                          "controls-position": "right",
                                        },
                                        model: {
                                          value: _vm.form.dayNumber,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "dayNumber", $$v)
                                          },
                                          expression: "form.dayNumber",
                                        },
                                      }),
                                      _vm._v(
                                        "\n                天\n              "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.form.unit == "week" &&
                                            _vm.form.type == "datetime",
                                          expression:
                                            "form.unit == 'week' && form.type == 'datetime'",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          model: {
                                            value: _vm.weekDays,
                                            callback: function ($$v) {
                                              _vm.weekDays = $$v
                                            },
                                            expression: "weekDays",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-checkbox",
                                            { attrs: { label: "1" } },
                                            [_vm._v("周一")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-checkbox",
                                            { attrs: { label: "2" } },
                                            [_vm._v("周二")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-checkbox",
                                            { attrs: { label: "3" } },
                                            [_vm._v("周三")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-checkbox",
                                            { attrs: { label: "4" } },
                                            [_vm._v("周四")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-checkbox",
                                            { attrs: { label: "5" } },
                                            [_vm._v("周五")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-checkbox",
                                            { attrs: { label: "6" } },
                                            [_vm._v("周六")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-checkbox",
                                            { attrs: { label: "0" } },
                                            [_vm._v("周七")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.form.unit == "month" &&
                                            _vm.form.type == "datetime",
                                          expression:
                                            "form.unit == 'month' && form.type == 'datetime'",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          staticClass: "monthGroup",
                                          model: {
                                            value: _vm.monthDays,
                                            callback: function ($$v) {
                                              _vm.monthDays = $$v
                                            },
                                            expression: "monthDays",
                                          },
                                        },
                                        _vm._l(_vm.monthList, function (month) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: month,
                                              attrs: { label: month },
                                            },
                                            [_vm._v(_vm._s(month) + "号")]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.form.unit == "year" &&
                                            _vm.form.type == "datetime",
                                          expression:
                                            "form.unit == 'year' && form.type == 'datetime'",
                                        },
                                      ],
                                      attrs: { prop: "yearDays" },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "date",
                                          "value-format": "MM-dd",
                                          format: "MM-dd",
                                          placeholder: "选择日期",
                                        },
                                        model: {
                                          value: _vm.form.yearDays,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "yearDays", $$v)
                                          },
                                          expression: "form.yearDays",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "triggerTime" } },
                                    [
                                      _c("el-time-picker", {
                                        attrs: {
                                          "value-format": "HH:mm:ss",
                                          clearable: false,
                                          placeholder: "选择时间",
                                        },
                                        model: {
                                          value: _vm.form.triggerTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "triggerTime",
                                              $$v
                                            )
                                          },
                                          expression: "form.triggerTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("返 回"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
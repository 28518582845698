"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.groupDataDelete = groupDataDelete;
exports.groupDataEdit = groupDataEdit;
exports.groupDataInfo = groupDataInfo;
exports.groupDataList = groupDataList;
exports.groupDataSave = groupDataSave;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function groupDataDelete(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/system/group/data/delete',
    method: 'GET',
    params: data
  });
}
function groupDataInfo(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/system/group/data/info',
    method: 'GET',
    params: data
  });
}
function groupDataList(pram) {
  var data = {
    gid: pram.gid,
    keywords: pram.keywords,
    status: pram.status,
    // 1=开启 2=关闭
    page: pram.page,
    limit: pram.limit
  };
  return (0, _request.default)({
    url: '/admin/system/group/data/list',
    method: 'GET',
    params: data
  });
}
function groupDataSave(pram) {
  return (0, _request.default)({
    url: '/admin/system/group/data/save',
    method: 'POST',
    data: pram
  });
}
function groupDataEdit(pram, id) {
  return (0, _request.default)({
    url: '/admin/system/group/data/update',
    method: 'POST',
    data: pram,
    params: {
      id: id
    }
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import UploadIndex from '@/components/uploadPicture/index.vue'
var _default = exports.default = {
  name: 'UploadFroms',
  // components: { UploadIndex },
  data: function data() {
    return {
      visible: false,
      callback: function callback() {},
      isMore: '',
      modelName: '',
      ISmodal: false
    };
  },
  watch: {
    // show() {
    //   this.visible = this.show
    // }
  },
  methods: {
    handleClose: function handleClose() {
      this.visible = false;
    },
    getImage: function getImage(img) {
      this.callback(img);
      this.visible = false;
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addArea = addArea;
exports.addSolution = addSolution;
exports.delArea = delArea;
exports.getAreaSelectList = getAreaSelectList;
exports.getList = getList;
exports.getPageInfo = getPageInfo;
exports.getSolutionList = getSolutionList;
exports.getTabInfo = getTabInfo;
exports.infoSolution = infoSolution;
exports.pageSave = pageSave;
exports.updateArea = updateArea;
exports.updateHide = updateHide;
exports.updateSolution = updateSolution;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 分区列表
 * @param pram
 */
function getList(pram) {
  var data = {
    limit: pram.limit,
    page: pram.page
  };
  return (0, _request.default)({
    url: "/admin/value/account/partition/list",
    method: "GET",
    params: data
  });
}

/**
 * 分区下拉
 * @param pram
 */
function getAreaSelectList() {
  return (0, _request.default)({
    url: "/admin/value/account/partition/select",
    method: "GET"
  });
}

/**
 * 删除分区
 * @param id
 * @constructor
 */
function delArea(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: "/admin/value/account/partition/delete",
    method: "POST",
    data: data
  });
}

/**
 * 新增分区
 * @param pram
 * @constructor
 */
function addArea(data) {
  return (0, _request.default)({
    url: "/admin/value/account/partition/save",
    method: "post",
    data: data
  });
}

/**
 * 更新分区
 * @param pram
 * @constructor
 */
function updateArea(data) {
  return (0, _request.default)({
    url: "/admin/value/account/partition/update",
    method: "post",
    data: data
  });
}

/**
 * 分区是否启用
 * @param id
 * @constructor
 */
function updateHide(pram) {
  return (0, _request.default)({
    url: "/admin/value/account/partition/enable",
    method: "POST",
    data: pram
  });
}

/**
 * 解决方案列表
 * @param pram
 */
function getSolutionList(pram) {
  var data = {
    limit: pram.limit,
    page: pram.page
  };
  if (pram.solutionName) {
    data.solutionName = pram.solutionName;
  }
  return (0, _request.default)({
    url: "/admin/value-account-solution/value-account-solution-list",
    method: "GET",
    params: data
  });
}
/**
 * 新增解决方案
 * @param pram
 * @constructor
 */
function addSolution(data) {
  return (0, _request.default)({
    url: "/admin/value-account-solution/value-account-solution",
    method: "post",
    data: data
  });
}

/**
 * 更新解决方案
 * @param pram
 * @constructor
 */
function updateSolution(data) {
  return (0, _request.default)({
    url: "/admin/value-account-solution/value-account-solution",
    method: "put",
    data: data
  });
}
/**
 * 更新解决方案
 * @param pram
 * @constructor
 */
function infoSolution(data) {
  return (0, _request.default)({
    url: "/admin/value-account-solution/value-account-solution",
    method: "GET",
    params: data
  });
}

/**
 * 更新解决方案
 * @param pram
 * @constructor
 */
function getPageInfo() {
  return (0, _request.default)({
    url: "/admin/value/account/page/info",
    method: "GET"
  });
}
/**
 * Tab配置详情
 * @param pram
 * @constructor
 */
function getTabInfo(data) {
  return (0, _request.default)({
    url: "/admin/value/account/page/tabInfo",
    method: "GET",
    params: data
  });
}
/**
 * 保存页面配置
 * @param pram
 * @constructor
 */
function pageSave(data) {
  return (0, _request.default)({
    url: "/admin/value/account/page/save",
    method: "POST",
    data: data
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/layout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; } /** When your routing table is too long, you can split it into small modules **/
var enterpriseRouter = {
  path: '/enterprise',
  component: _layout.default,
  redirect: '/enterprise/serve',
  name: 'enterprise',
  meta: {
    title: '企业服务',
    icon: 'clipboard'
  },
  children: [{
    path: 'serve',
    name: 'enterpriseServe',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/enterprise/serve/list.vue'));
      });
    },
    meta: {
      title: '企业服务配置'
    }
  }, {
    path: 'serve-add/:id?',
    name: 'enterpriseServeAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/enterprise/serve/edit'));
      });
    },
    meta: {
      title: '编辑企业服务配置'
    }
  }, {
    path: 'type',
    name: 'enterpriseType',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/enterprise/type'));
      });
    },
    meta: {
      title: '企业服务分类'
    }
  }]
};
var _default = exports.default = enterpriseRouter;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchLink = batchLink;
exports.callInterfaceToTYCApi = callInterfaceToTYCApi;
exports.contentId = contentId;
exports.contentList = contentList;
exports.contentSave = contentSave;
exports.contentUpdate = contentUpdate;
exports.deleteId = deleteId;
exports.exportExcleApi = exportExcleApi;
exports.getAdminListForSelectApi = getAdminListForSelectApi;
exports.getCopyUrl = getCopyUrl;
exports.getFileNameListApi = getFileNameListApi;
exports.getQrCode = getQrCode;
exports.getUrlLink = getUrlLink;
exports.importFileApi = importFileApi;
exports.promotionList = promotionList;
exports.tempEnable = tempEnable;
exports.tempId = tempId;
exports.tempList = tempList;
exports.tempSave = tempSave;
exports.tempUpdate = tempUpdate;
exports.urlLink = urlLink;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// 投放链接list
function contentList(params) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/content/list",
    method: "get",
    params: params
  });
}

// 添加投放链接
function contentSave(data) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/content/save",
    method: "post",
    data: data
  });
}

// 更新投放链接
function contentUpdate(data) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/content/update",
    method: "post",
    data: data
  });
}

// 获取模板
function contentId(id) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/content/".concat(id),
    method: "get"
  });
}

// 删除投放链接
function deleteId(id) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/delete/".concat(id),
    method: "post"
  });
}

// 投放链接二维码
function getQrCode(id) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/qrCode/".concat(id),
    method: "get"
  });
}

// 复制链接
function getUrlLink(id) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/urlLink/".concat(id),
    method: "get"
  });
}

/**
 *
 * @param {*} id
 * @returns
 */

function getCopyUrl(id) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/promotion/Link/".concat(id),
    method: "get"
  });
}

// // 投放链接二维码
// export function getQrCode(id) {
//   return request({
//     url: `/admin/marketing/ad/popularize/qrCode/${id}`,
//     method: 'get',
//   })
// }

// 开启禁用
function tempEnable(id, enable) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/temp/enable/".concat(id) + "?enable=".concat(enable),
    method: "post"
  });
}

// 模板
function tempList(params) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/temp/list",
    method: "get",
    params: params
  });
}

// 添加参数
function tempSave(data) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/temp/save",
    method: "post",
    data: data
  });
}

// 更新参数
function tempUpdate(data) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/temp/update",
    method: "post",
    data: data
  });
}

// 获取模板
function tempId(id) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/temp/".concat(id),
    method: "get"
  });
}

// 复制链接
function urlLink(id) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/urlLink/".concat(id),
    method: "get"
  });
}
function promotionList(params) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/promotion/list",
    method: "get",
    params: params
  });
}

// qudao
function batchLink(id, count) {
  return (0, _request.default)({
    url: "/admin/marketing/ad/popularize/urlLink/batch/".concat(id, "/").concat(count),
    method: "get"
  });
}

/***
 *
 * 上产企业信息
 */

function importFileApi(data) {
  return (0, _request.default)({
    url: "/admin/company/importCompanyName",
    method: "post",
    data: data
  });
}

/**
 *
 * 查询企业信息列表
 */

function getFileNameListApi(params) {
  return (0, _request.default)({
    url: "/admin/company/getFileNameList",
    method: "get",
    params: params
  });
}

/**
 *
 * 下载企业信息
 */

function exportExcleApi(id) {
  return (0, _request.default)({
    url: "admin/company/export/".concat(id),
    method: "get"
  });
}

/**
 *
 *  调用天眼查接口
 */

function callInterfaceToTYCApi(id) {
  return (0, _request.default)({
    url: "admin/company/callInterfaceToTYC/".concat(id),
    method: "get"
  });
}

/**
 *
 * 获取后台管理员用于投放连接所属人
 */

function getAdminListForSelectApi() {
  return (0, _request.default)({
    url: "admin/system/admin/getAdminListForSelect",
    method: "get"
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _channel = require("@/api/channel");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      constants: this.$constants,
      listPram: {
        keyWords: null,
        categoryName: null,
        page: 1,
        limit: this.$constants.page.limit[0]
      },
      listData: {
        list: [],
        total: 0
      },
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      },
      timer: "",
      urlImage: ["https://dellsbprod.oss-cn-beijing.aliyuncs.com/ecomimage/maintain/2022/06/20/86ca370b095040c2ae049b0773da1ff6ynmvdlj49n.jpg"]
    };
  },
  created: function created() {
    // this.handerSearch();
  },
  mounted: function mounted() {
    this.handerSearch();
    // this.timer = setInterval(() => {
    //   this.handlerGetListData(this.listPram);
    // }, 5000);
  },
  beforeDestroy: function beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {
    startDomond: function startDomond(row) {
      var _this = this;
      (0, _channel.callInterfaceToTYCApi)(row.id).then(function (res) {
        if (res.sucess == "sucess") {
          setTimeout(function () {
            _this.handerSearch();
          }, 2000);
        }
      });
    },
    handleExport: function handleExport(row) {
      (0, _channel.exportExcleApi)(row.id).then(function (res) {
        window.open(res.fileName);
      });
    },
    handerSearch: function handerSearch() {
      this.listPram.page = 1;
      this.handlerGetListData(this.listPram);
    },
    handlerGetListData: function handlerGetListData(prams) {
      var _this2 = this;
      (0, _channel.getFileNameListApi)(prams).then(function (res) {
        _this2.listData = res;
      });
    },
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this3 = this;
      var formData = new FormData();
      formData.append("file", param.file);
      console.log(formData, "formData");
      var loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      (0, _channel.importFileApi)(formData).then(function (res) {
        loading.close();
        console.log(res);
        _this3.handerSearch();
      }).catch(function (res) {
        loading.close();
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetListData(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetListData(this.listPram);
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.editPriceApi = editPriceApi;
exports.getLogisticsInfoApi = getLogisticsInfoApi;
exports.orderDeleteApi = orderDeleteApi;
exports.orderDetailApi = orderDetailApi;
exports.orderListApi = orderListApi;
exports.orderLogApi = orderLogApi;
exports.orderMarkApi = orderMarkApi;
exports.orderRefundApi = orderRefundApi;
exports.orderRefuseApi = orderRefuseApi;
exports.orderSendApi = orderSendApi;
exports.orderStatisticsApi = orderStatisticsApi;
exports.orderTimeApi = orderTimeApi;
exports.orderUpdateApi = orderUpdateApi;
exports.sendProductApi = sendProductApi;
exports.sheetInfoApi = sheetInfoApi;
exports.statisticsDataApi = statisticsDataApi;
exports.updateGiiApi = updateGiiApi;
exports.writeConfirmApi = writeConfirmApi;
exports.writeUpdateApi = writeUpdateApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 订单 列表
 * @param pram
 */
function orderListApi(params) {
  return (0, _request.default)({
    url: "/admin/store/order/list",
    method: "get",
    params: params
  });
}

/**
 * 订单 删除
 * @param pram
 */
function orderDeleteApi(params) {
  return (0, _request.default)({
    url: "/admin/store/order/delete",
    method: "get",
    params: params
  });
}

/**
 * 订单 编辑
 * @param pram
 */
// export function orderUpdateApi(data, params) {
//   return request({
//     url: '/admin/store/order/update',
//     method: 'post',
//     data,
//     params
//   })
// }

function orderUpdateApi(data, params) {
  return (0, _request.default)({
    url: "/admin/store/order/editPrice",
    method: "post",
    data: data,
    params: params
  });
}

/**
 * 订单 记录
 * @param pram
 */
function orderLogApi(params) {
  return (0, _request.default)({
    url: "/admin/store/order/status/list",
    method: "get",
    params: params
  });
}

/**
 * 订单 详情
 * @param pram
 */
function orderDetailApi(params) {
  return (0, _request.default)({
    url: "/admin/store/order/info",
    method: "get",
    params: params
  });
}

/**
 * 订单 备注
 * @param pram
 */
function orderMarkApi(params) {
  return (0, _request.default)({
    url: "/admin/store/order/mark",
    method: "post",
    params: params
  });
}

/**
 * 订单 发货
 * @param pram
 */
function orderSendApi(data) {
  return (0, _request.default)({
    url: "/admin/store/order/send",
    method: "post",
    data: data
  });
}

/**
 * 订单 拒绝退款
 * @param pram
 */
function orderRefuseApi(params) {
  return (0, _request.default)({
    url: "/admin/store/order/refund/refuse",
    method: "get",
    params: params
  });
}

/**
 * 订单 立即退款
 * @param pram
 */
function orderRefundApi(params) {
  return (0, _request.default)({
    url: "/admin/store/order/refund",
    method: "get",
    params: params
  });
}

/**
 * 订单 核销订单
 * @param pram
 */
function writeUpdateApi(vCode) {
  return (0, _request.default)({
    url: "/admin/store/order/writeUpdate/".concat(vCode),
    method: "get"
  });
}

/**
 * 订单 核销码查询待核销订单
 * @param pram
 */
function writeConfirmApi(vCode) {
  return (0, _request.default)({
    url: "/admin/store/order/writeConfirm/".concat(vCode),
    method: "get"
  });
}

/**
 * 订单 统计 头部数据
 */
function orderStatisticsApi() {
  return (0, _request.default)({
    url: "/admin/store/order/statistics",
    method: "get"
  });
}

/**
 * 核销订单 月列表数据
 */
function statisticsDataApi(params) {
  return (0, _request.default)({
    url: "/admin/store/order/statisticsData",
    method: "get",
    params: params
  });
}

/**
 * 一键改价
 */
function editPriceApi(data, params) {
  return (0, _request.default)({
    url: "/admin/store/order/editPrice",
    method: "post",
    data: data,
    params: params
  });
}

/**
 *订单统计详情
 */
function orderTimeApi(params) {
  return (0, _request.default)({
    url: "/admin/store/order/time",
    method: "get",
    params: params
  });
}

/**
 *面单默认配置信息
 */
function sheetInfoApi() {
  return (0, _request.default)({
    url: "/admin/store/order/sheet/info",
    method: "get"
  });
}

/**
 *面单默认配置信息
 */
function getLogisticsInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/store/order/getLogisticsInfo",
    method: "get",
    params: params
  });
}

/**
 *确认提交GII
 */

function updateGiiApi(data, params) {
  return (0, _request.default)({
    url: "/admin/store/order/updateGii",
    method: "post",
    data: data,
    params: params
  });
}

/**
 * 发货以及更新GII编号
 */

function sendProductApi(data, params) {
  return (0, _request.default)({
    url: "/admin/store/order/sendProduct",
    method: "post",
    data: data,
    params: params
  });
}
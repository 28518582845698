"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/layout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var operationRouter = {
  path: '/operation',
  component: _layout.default,
  redirect: '/operation/setting',
  name: 'Operation',
  meta: {
    title: '设置',
    icon: 'clipboard',
    roles: ['admin']
  },
  children: [{
    path: 'setting',
    name: 'setting',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/systemSetting/setting'));
      });
    },
    meta: {
      title: '系统设置',
      icon: 'clipboard'
    }
  }, {
    path: 'workSpace',
    name: 'workSpace',
    // redirect: '/workSpace/qucikReply',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/systemSetting/workSpace'));
      });
    },
    meta: {
      title: '工作台配置',
      icon: 'clipboard'
    },
    children: [{
      path: 'qucikReply',
      name: 'qucikReply',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/workSpace/qucikReply/index'));
        });
      },
      meta: {
        title: '快捷回复',
        icon: 'clipboard'
      }
    }, {
      path: 'evalSatis',
      name: 'evalSatis',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/workSpace/evalSatis'));
        });
      },
      meta: {
        title: '满意度评价',
        icon: 'clipboard'
      }
    }]
  }, {
    path: 'onePass',
    name: 'onePass',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sms/smsConfig'));
      });
    },
    meta: {
      title: '一号通',
      icon: 'clipboard'
    }
  }, {
    path: 'roleManager',
    name: 'RoleManager',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/systemSetting/administratorAuthority'));
      });
    },
    meta: {
      title: '管理权限',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'identityManager',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/administratorAuthority/identityManager'));
        });
      },
      name: 'identityManager',
      meta: {
        title: '身份管理',
        icon: ''
      }
    }, {
      path: 'adminList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/administratorAuthority/adminList'));
        });
      },
      name: 'adminList',
      meta: {
        title: '管理员列表',
        icon: ''
      }
    }, {
      path: 'promiseRules',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/administratorAuthority/permissionRules'));
        });
      },
      name: 'promiseRules',
      meta: {
        title: '权限规则',
        icon: ''
      }
    }]
  }, {
    path: 'logistics',
    name: 'Logistics',
    alwaysShow: true,
    redirect: '/logistics/cityList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/systemSetting/administratorAuthority'));
      });
    },
    meta: {
      title: '物流设置',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'cityList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/logistics/cityList'));
        });
      },
      name: 'cityList',
      meta: {
        title: '城市数据',
        icon: ''
      }
    }, {
      path: 'companyList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/logistics/companyList'));
        });
      },
      name: 'companyList',
      meta: {
        title: '物流公司',
        icon: ''
      }
    }, {
      path: 'shippingTemplates',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/logistics/shippingTemplates'));
        });
      },
      name: 'shippingTemplates',
      meta: {
        title: '运费模板',
        icon: ''
      }
    }, {
      path: 'logisticsConfig',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/logistics/config'));
        });
      },
      name: 'logisticsConfig',
      meta: {
        title: '物流配置',
        icon: ''
      }
    }]
  }, {
    path: 'systemStore',
    name: 'SystemStore',
    alwaysShow: true,
    redirect: '/systemStore/point',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/systemSetting/systemStore'));
      });
    },
    meta: {
      title: '提货点设置',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'point',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/systemStore/point'));
        });
      },
      name: 'point',
      meta: {
        title: '提货点',
        icon: ''
      }
    }, {
      path: 'clerkList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/systemStore/clerkList'));
        });
      },
      name: 'clerkList',
      meta: {
        title: '核销员',
        icon: ''
      }
    }, {
      path: 'order',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/systemStore/order'));
        });
      },
      name: 'order',
      meta: {
        title: '核销订单',
        icon: ''
      }
    }]
  }, {
    path: 'systemSms',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sms'));
      });
    },
    name: 'systemSms',
    meta: {
      title: '短信设置',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'config',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sms/smsConfig'));
        });
      },
      name: 'SmsConfig',
      meta: {
        title: '短信账户',
        noCache: true
      }
    }, {
      path: 'template',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sms/smsTemplate'));
        });
      },
      name: 'SmsTemplate',
      meta: {
        title: '短信模板',
        noCache: true,
        activeMenu: "/operation/onePass"
      }
    }, {
      path: 'pay',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sms/smsPay'));
        });
      },
      name: 'SmsPay',
      meta: {
        title: '短信购买',
        noCache: true,
        activeMenu: "/operation/onePass"
      }
    }, {
      path: 'message',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sms/smsMessage'));
        });
      },
      name: 'SmsMessage',
      meta: {
        title: '短信开关',
        noCache: true
      }
    }]
  }, {
    path: 'storeService',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/systemSetting/storeService'));
      });
    },
    name: 'StoreService',
    meta: {
      title: '客服管理',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/storeService/chatRoom'));
        });
      },
      name: 'StoreServiceList',
      meta: {
        title: '客服列表',
        noCache: true
      }
    }, {
      path: 'chatRoom',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/systemSetting/storeService/list'));
        });
      },
      name: 'ChatRoom',
      meta: {
        title: '聊天室',
        noCache: true
      }
    }]
  }]
};
var _default = exports.default = operationRouter;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Parser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/Parser"));
var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));
var logistics = _interopRequireWildcard(require("@/api/logistics.js"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "CompanyList",
  components: {
    parser: _Parser.default
  },
  data: function data() {
    return _defineProperty({
      constants: this.$constants,
      // 表单
      formConf: {
        fields: []
      },
      form: {
        keywords: ""
      },
      tableData: {},
      page: 1,
      limit: 20,
      loading: false,
      dialogVisible: false,
      fromType: "add",
      formData: {
        status: false
      },
      isCreate: 0,
      formShow: false,
      editId: 0,
      rules: {
        sort: [{
          required: true,
          message: "请输入排序",
          trigger: "blur"
        }],
        account: [{
          required: true,
          message: "请输入月结账号",
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: "请输入月结密码",
          trigger: "blur"
        }],
        netName: [{
          required: true,
          message: "请输入网点名称",
          trigger: "blur"
        }]
      },
      show1: false,
      ruleForm: {
        name: "",
        code: "",
        partnerId: "",
        partnerKey: "",
        net: ""
      }
    }, "rules", {
      name: [{
        required: true,
        message: "请输入物流公司名称",
        trigger: "blur"
      }],
      code: [{
        required: true,
        message: "请输入物流公司编码",
        trigger: "blur"
      }],
      partnerId: [{
        required: true,
        message: "请选择是否月结账号",
        trigger: "change"
      }],
      partnerKey: [{
        required: true,
        message: "请选择是否需要月结密码",
        trigger: "change"
      }],
      net: [{
        required: true,
        message: "请选择是否需要取件网点",
        trigger: "change"
      }]
    });
  },
  created: function created() {
    this.getExpressList();
  },
  methods: {
    addcompaty: function addcompaty() {
      this.show1 = true;
    },
    handleClose1: function handleClose1() {
      this.show1 = false;
    },
    cancel: function cancel() {},
    // 提交
    confirm: function confirm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.handleconfirm();
        } else {}
      });
    },
    handleconfirm: function handleconfirm() {
      var _this2 = this;
      console.log(this.ruleForm);
      logistics.addWuliu({
        name: this.ruleForm.name,
        code: this.ruleForm.name,
        partnerId: Number(this.ruleForm.partnerId),
        partnerKey: Number(this.ruleForm.partnerKey),
        net: Number(this.ruleForm.net)
      }).then(function (res) {
        _this2.$message.success("添加成功");
        _this2.show1 = false;
        _this2.getExpressList();
      });
    },
    handlerSearch: function handlerSearch() {
      this.page = 1;
      this.getExpressList();
    },
    //  获取物流公司列表
    getExpressList: function getExpressList() {
      var _this3 = this;
      this.loading = true;
      logistics.expressList({
        page: this.page,
        limit: this.limit,
        keywords: this.form.keywords
      }).then(function (res) {
        _this3.loading = false;
        _this3.tableData = res;
      }).catch(function () {
        _this3.loading = false;
      });
    },
    // 物流开关
    bindStatus: function bindStatus(item) {
      var _this4 = this;
      logistics.expressUpdateShow({
        account: item.account,
        code: item.code,
        id: item.id,
        isShow: item.isShow,
        name: item.name,
        sort: item.sort
      }).then(function (res) {
        _this4.$message.success("操作成功");
        _this4.getExpressList();
      }).catch(function () {
        item.isShow = !item.isShow;
      });
    },
    // 分页
    pageChange: function pageChange(e) {
      this.page = e;
      this.getExpressList();
    },
    handleSizeChange: function handleSizeChange(e) {
      this.limit = e;
      this.getExpressList();
    },
    // 添加物流公司
    addExpress: function addExpress() {
      var _this5 = this;
      logistics.expressSyncApi().then(function (data) {
        _this5.page = 1;
        _this5.getExpressList();
      });
    },
    // 删除物流公司
    bindDelete: function bindDelete(item) {
      var _this6 = this;
      this.$modalSure().then(function () {
        logistics.expressDelete({
          id: item.id
        }).then(function (res) {
          _this6.$message.success("删除成功");
          _this6.getExpressList();
        });
      });
    },
    // 表单提交
    submit: function submit(formName) {
      var _this7 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          logistics.expressUpdate(_this7.formData).then(function (res) {
            _this7.$message.success("操作成功");
            _this7.handleClose();
            _this7.getExpressList();
          });
        } else {
          return false;
        }
      });
    },
    //  关闭模态框
    handleClose: function handleClose(done) {
      this.formShow = false;
      // this.formData = {}
      this.formConf.fields = [];
      this.dialogVisible = false;
      this.isCreate = 0;
    },
    // 编辑
    bindEdit: function bindEdit(item) {
      var _this8 = this;
      this.dialogVisible = true;
      this.editId = item.id;
      logistics.expressInfo({
        id: item.id
      }).then(function (res) {
        _this8.formData = res;
      });
    }
  }
};
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var roleApi = _interopRequireWildcard(require("@/api/role.js"));
var categroyApi = _interopRequireWildcard(require("@/api/categoryApi.js"));
var _edit = _interopRequireDefault(require("./edit"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "index"
  components: {
    edit: _edit.default
  },
  data: function data() {
    return {
      constants: this.$constants,
      listData: {
        list: []
      },
      listPram: {
        createTime: null,
        updateTime: null,
        level: null,
        page: 1,
        limit: this.$constants.page.limit[0],
        roleName: null,
        rules: null,
        status: null
      },
      menuList: [],
      editDialogConfig: {
        visible: false,
        isCreate: 0,
        // 0=创建，1=编辑
        editData: {}
      }
    };
  },
  mounted: function mounted() {
    this.handleGetRoleList();
  },
  methods: {
    handlerOpenDel: function handlerOpenDel(rowData) {
      var _this = this;
      this.$confirm('确认删除当前数据').then(function () {
        roleApi.delRole(rowData).then(function (data) {
          _this.$message.success('删除数据成功');
          _this.handleGetRoleList();
        });
      });
    },
    handleGetRoleList: function handleGetRoleList() {
      var _this2 = this;
      roleApi.getRoleList(this.listPram).then(function (data) {
        _this2.listData = data;
        _this2.handlerGetMenuList();
      });
    },
    handlerOpenEdit: function handlerOpenEdit(isCreate, editDate) {
      isCreate === 1 ? this.editDialogConfig.editData = editDate : this.editDialogConfig.editData = {};
      this.editDialogConfig.isCreate = isCreate;
      this.editDialogConfig.visible = true;
    },
    handlerGetMenuList: function handlerGetMenuList() {
      var _this3 = this;
      // 获取菜单全部数据后做menu翻译使用
      categroyApi.listCategroy({
        page: 1,
        limit: 999,
        type: 5
      }).then(function (data) {
        _this3.menuList = data.list;
        _this3.listData.list.forEach(function (item) {
          var _muneText = [];
          var menuids = item.rules.split(',');
          menuids.map(function (muid) {
            _this3.menuList.filter(function (menu) {
              if (menu.id == muid) {
                _muneText.push(menu.name);
              }
            });
          });
          item.rulesView = _muneText.join(',');
          _this3.$set(item, 'rulesViews', item.rulesView);
        });
      });
    },
    hideEditDialog: function hideEditDialog() {
      this.editDialogConfig.visible = false;
      this.handleGetRoleList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handleGetRoleList(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handleGetRoleList(this.listPram);
    }
  }
};
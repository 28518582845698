"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    dialogPresent: {
      type: Boolean,
      default: false
    },
    selectPresentList: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      multipleSelection: [],
      listLoading: true,
      tableFrom: {
        page: 1,
        limit: 10,
        cateId: "",
        keywords: "",
        type: "1"
        // isPresent: true,
      },
      tableData: {
        data: [],
        total: 0
      },
      selectValue: "",
      merCateList: [],
      props: {
        children: "child",
        label: "name",
        value: "id",
        emitPath: false
      }
    };
  },
  watch: {
    selectPresentList: function selectPresentList(val) {
      var _this = this;
      if (val.length > 0) {
        val.forEach(function (item) {
          _this.tableData.data.forEach(function (n, index) {
            if (item.relationProductId == n.id) {
              _this.$nextTick(function () {
                _this.$refs.multipleTable.toggleRowSelection(_this.tableData.data[index], true);
              });
            }
          });
        });
      }
    }
  },
  created: function created() {
    this.getList();
    this.getCategorySelect();
  },
  methods: {
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this2 = this;
      (0, _store.categoryApi)({
        status: -1,
        type: 1
      }).then(function (res) {
        _this2.merCateList = res;
      }).catch(function (res) {
        _this2.$message.error(res.message);
        _this2.$message.error(res.message);
      });
    },
    getRowKey: function getRowKey(row) {
      return row.id;
    },
    unique: function unique(arr, val) {
      var res = new Map();
      return arr.filter(function (item) {
        return !res.has(item[val]) && res.set(item[val], 1);
      });
    },
    handleSelectionChange: function handleSelectionChange(rows) {
      var _this3 = this;
      this.multipleSelection = [];
      if (rows) {
        rows = this.unique(rows, "id");
        // console.log(rows, "rowssssss");
        rows.forEach(function (row) {
          _this3.multipleSelection.push(row);
        });
      }
    },
    handleSubmit: function handleSubmit() {
      // this.multipleSelection = new Set(this.multipleSelection);
      this.$emit("closeDialogStore", this.multipleSelection);
      this.$refs.multipleTable.clearSelection();
    },
    handleCancel: function handleCancel() {
      this.$emit("closeDialogStore", this.multipleSelection);
      this.$refs.multipleTable.clearSelection();
    },
    getList: function getList() {
      var _this4 = this;
      (0, _store.productLstApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.list;
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
        //   if (this.selectPresentList.length == 0) return;

        //   this.selectPresentList.forEach((item) => {
        //     this.tableData.data.forEach((n, index) => {
        //       // console.log(n);
        //       if (item.goodsId == n.id) {
        //         console.log("hahhah", n.id);
        //         this.$refs.multipleTable.toggleRowSelection(
        //           this.tableData.data[index],
        //           true
        //         );
        //       }
        //     });
        //   });
      }).catch(function (res) {
        _this4.listLoading = false;
        _this4.$message.error(res.message);
      });
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dayjs = _interopRequireDefault(require("dayjs"));
var _live = require("@/api/live");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      constants: this.$constants,
      listPram: {
        keyword: null,
        page: 1,
        limit: this.$constants.page.limit[0]
      },
      listData: {
        list: [],
        total: 0
      }
    };
  },
  mounted: function mounted() {
    this.handerSearch();
  },
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
  },
  methods: {
    exportExcel: function exportExcel() {
      (0, _live.exportSignUpform)(this.listPram).then(function (res) {
        window.open(res.fileName);
      });
    },
    handerSearch: function handerSearch() {
      this.listPram.page = 1;
      this.handlerGetListData(this.listPram);
    },
    handlerGetListData: function handlerGetListData(prams) {
      var _this = this;
      (0, _live.getSignUpPageList)(prams).then(function (res) {
        _this.listData = res;
      });
    },
    handlerDelete: function handlerDelete(row) {
      var _this2 = this;
      this.$confirm("确定删除当前数据", "提示").then(function (result) {
        deleteLive({
          id: [row.id]
        }).then(function (res) {
          // console.log(res)
          _this2.$message.success("删除成功");
          _this2.handlerGetListData(_this2.listPram);
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetListData(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetListData(this.listPram);
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header",
          },
          [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c(
                  "el-form",
                  { attrs: { inline: "", size: "small" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "关键词：" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "请输入关键词",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.listPram.keyword,
                              callback: function ($$v) {
                                _vm.$set(_vm.listPram, "keyword", $$v)
                              },
                              expression: "listPram.keyword",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: { click: _vm.handerSearch },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "时间选择：" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "220px" },
                          attrs: {
                            "value-format": "yyyy-MM-dd",
                            format: "yyyy-MM-dd",
                            size: "small",
                            type: "daterange",
                            placement: "bottom-end",
                            placeholder: "自定义时间",
                          },
                          on: { change: _vm.onchangeTime },
                          model: {
                            value: _vm.timeVal,
                            callback: function ($$v) {
                              _vm.timeVal = $$v
                            },
                            expression: "timeVal",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "地点" } },
                      [
                        _c("el-cascader", {
                          attrs: {
                            clearable: "",
                            props: _vm.Oprops,
                            options: _vm.addressOptions,
                          },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.listPram.serviceAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.listPram, "serviceAddress", $$v)
                            },
                            expression: "listPram.serviceAddress",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "拼团状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择" },
                            on: { change: _vm.handerSearch },
                            model: {
                              value: _vm.listPram.combinationStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.listPram, "combinationStatus", $$v)
                              },
                              expression: "listPram.combinationStatus",
                            },
                          },
                          _vm._l(_vm.statusList, function (item) {
                            return _c("el-option", {
                              key: item.code,
                              attrs: { label: item.name, value: item.code },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "mr10",
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.exportOrder },
              },
              [_vm._v("导出订单")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                staticClass: "table",
                attrs: {
                  data: _vm.listData.list,
                  size: "mini",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "id", label: "ID", "min-width": "50" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "realName", label: "姓名" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "userPhone",
                    label: "手机号",
                    "min-width": "110",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "serviceAddress", label: "地点" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "expectTime", label: "期望时间" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "timeLength", label: "服务时长" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "deviceNum", label: " 服务数量" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "createTime", label: "提交时间" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "拼团状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm._f("statusFilter")(
                                scope.row.combinationStatus
                              )
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    "min-width": "100",
                    fixed: "right",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              staticClass: "mr10",
                              attrs: { size: "small", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.seedetail(scope.row)
                                },
                              },
                            },
                            [_vm._v("详情")]
                          ),
                          _vm._v(" "),
                          scope.row.combinationStatus == 9 &&
                          scope.row.isNeedInvoice == 1
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "mr10",
                                  attrs: { size: "small", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.uploadMoney(scope.row, "1")
                                    },
                                  },
                                },
                                [_vm._v("上传发票")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.listPram.page,
                "page-sizes": _vm.constants.page.limit,
                layout: _vm.constants.page.layout,
                total: _vm.listData.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("detail", { ref: "detailRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
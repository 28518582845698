var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _vm.isShow
            ? _c("parser", {
                staticClass: "formBox",
                attrs: {
                  "form-conf": _vm.formConf,
                  "form-edit-data": _vm.formData,
                  "is-edit": _vm.isCreate === 1,
                },
                on: { submit: _vm.submit },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddEnterpriseService = AddEnterpriseService;
exports.AddEnterpriseType = AddEnterpriseType;
exports.DelEnterpriseService = DelEnterpriseService;
exports.DelEnterpriseType = DelEnterpriseType;
exports.InfoEnterpriseService = InfoEnterpriseService;
exports.InfoEnterpriseType = InfoEnterpriseType;
exports.ListEnterpriseService = ListEnterpriseService;
exports.ListEnterpriseType = ListEnterpriseType;
exports.OpenEnterpriseService = OpenEnterpriseService;
exports.OpenEnterpriseType = OpenEnterpriseType;
exports.UpdateEnterpriseService = UpdateEnterpriseService;
exports.UpdateEnterpriseType = UpdateEnterpriseType;
exports.exportEnterpriseService = exportEnterpriseService;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 企业服务详情
 * @param
 * @constructor
 */
function InfoEnterpriseService(params) {
  return (0, _request.default)({
    url: '/admin/company/service/model/info',
    method: 'get',
    params: params
  });
}

/**
 * 企业服务列表
 */
function ListEnterpriseService(params) {
  return (0, _request.default)({
    url: '/admin/company/service/model/list',
    method: 'GET',
    params: params
  });
}

/**
* 新增企业服务
*/
function AddEnterpriseService(data) {
  return (0, _request.default)({
    url: '/admin/company/service/model/add',
    method: 'post',
    data: data
  });
}

/**
* 更新企业服务
*/
function UpdateEnterpriseService(data) {
  return (0, _request.default)({
    url: '/admin/company/service/model/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除企业服务
 * @param id
 * @constructor
 */
function DelEnterpriseService(id) {
  return (0, _request.default)({
    url: "/admin/company/service/model/remove/".concat(id),
    method: 'get'
  });
}

/**
* 企业服务开启/关闭
*/
function OpenEnterpriseService(params) {
  return (0, _request.default)({
    url: "/admin/company/service/model/status/".concat(params.id, "/").concat(params.status),
    method: 'post'
  });
}

/**
 * 导出企业服务
 * @param id
 * @constructor
 */
function exportEnterpriseService(id) {
  return (0, _request.default)({
    url: "/admin/company/service/model/export/".concat(id),
    method: 'get'
  });
}

/**
 * 企业服务分类详情
 * @param
 * @constructor
 */
function InfoEnterpriseType(params) {
  return (0, _request.default)({
    url: '/admin/company/service/catagory/info',
    method: 'get',
    params: params
  });
}

/**
 * 企业服务分类列表
 */
function ListEnterpriseType(params) {
  return (0, _request.default)({
    url: '/admin/company/service/catagory/list',
    method: 'GET',
    params: params
  });
}

/**
* 新增企业服务分类
*/
function AddEnterpriseType(data) {
  return (0, _request.default)({
    url: '/admin/company/service/catagory/add',
    method: 'post',
    data: data
  });
}

/**
* 更新企业服务分类
*/
function UpdateEnterpriseType(data) {
  return (0, _request.default)({
    url: '/admin/company/service/catagory/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除企业服务分类
 * @param id
 * @constructor
 */
function DelEnterpriseType(id) {
  return (0, _request.default)({
    url: "/admin/company/service/catagory/remove/".concat(id),
    method: 'post'
  });
}

/**
* 企业服务分类开启/关闭
*/
function OpenEnterpriseType(params) {
  return (0, _request.default)({
    url: "/admin/company/service/catagory/status/".concat(params.id, "/").concat(params.status),
    method: 'GET'
  });
}
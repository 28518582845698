"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _store = _interopRequireDefault(require("./modules/store"));
var _order = _interopRequireDefault(require("./modules/order"));
var _user = _interopRequireDefault(require("./modules/user"));
var _distribution = _interopRequireDefault(require("./modules/distribution"));
var _marketing = _interopRequireDefault(require("./modules/marketing"));
var _channel = _interopRequireDefault(require("./modules/channel"));
var _financial = _interopRequireDefault(require("./modules/financial"));
var _content = _interopRequireDefault(require("./modules/content"));
var _operation = _interopRequireDefault(require("./modules/operation"));
var _appSetting = _interopRequireDefault(require("./modules/appSetting"));
var _maintain = _interopRequireDefault(require("./modules/maintain"));
var _mobile = _interopRequireDefault(require("./modules/mobile"));
var _streamManage = _interopRequireDefault(require("./modules/streamManage"));
var _serviceWork = _interopRequireDefault(require("./modules/serviceWork"));
var _stay = _interopRequireDefault(require("./modules/stay"));
var _beautify = _interopRequireDefault(require("./modules/beautify"));
var _beautifyPark = _interopRequireDefault(require("./modules/beautifyPark"));
var _beautifyHome = _interopRequireDefault(require("./modules/beautifyHome"));
var _enterprise = _interopRequireDefault(require("./modules/enterprise"));
var _live = _interopRequireDefault(require("./modules/live"));
var _repair = _interopRequireDefault(require("./modules/repair"));
var _Inspection = _interopRequireDefault(require("./modules/Inspection"));
var _valueAccount = _interopRequireDefault(require("./modules/valueAccount"));
var _defForm = _interopRequireDefault(require("./modules/defForm"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
_vue.default.use(_vueRouter.default);

/* Layout */

/* Router Modules */
// import componentsRouter from './modules/components'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [
// 留资管理
_stay.default,
// 装修
_beautify.default,
// 装修首页
_beautifyHome.default,
// 园区装修
_beautifyPark.default,
// 企业服务
_enterprise.default,
// 自定义表单
_defForm.default,
// 维修点
_repair.default,
// 巡检团购
_Inspection.default,
// 投放渠道
_channel.default,
// 商品
_store.default,
// 订单
_order.default,
// 会员
_user.default,
// 分销
_distribution.default,
// 营销
_marketing.default,
// 财务
_financial.default,
// 内容
_content.default,
// 设置
_operation.default,
// 应用
_appSetting.default,
// 维护
_maintain.default,
//移动端管理
_mobile.default,
// 直播管理
_streamManage.default,
// 客服工作台
_serviceWork.default,
// 微吼 直播
_live.default,
// valueAccount
_valueAccount.default,
// 数据
{
  path: "/datas",
  component: _layout.default,
  redirect: "/datas",
  name: "Datas",
  meta: {
    title: "数据",
    icon: "clipboard"
  },
  children: [{
    path: "transaction",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/datas/transaction/index"));
      });
    },
    name: "Transaction",
    meta: {
      title: "交易数据",
      icon: ""
    },
    children: [{
      path: "transactionorder",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/datas/transaction/order/index"));
        });
      },
      name: "transactionOrder",
      meta: {
        title: "订单统计",
        icon: ""
      }
    }, {
      path: "transactiongoods",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/datas/transaction/goods/index"));
        });
      },
      name: "transactionGoods",
      meta: {
        title: "商品统计",
        icon: ""
      }
    }]
  }]
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/error-page/404"));
    });
  },
  hidden: true
}, {
  path: "/redirect",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "/redirect/:path(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/redirect/index"));
      });
    }
  }]
}, {
  path: "/auth-send",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/mobile/auth-send"));
    });
  },
  hidden: true
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/privacy",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/privacy/index"));
    });
  },
  hidden: true,
  meta: {
    title: "隐私条款"
  }
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/login/auth-redirect"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/error-page/401"));
    });
  },
  hidden: true
}, {
  path: "/",
  component: _layout.default,
  redirect: "/dashboard",
  children: [{
    path: "dashboard",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/stay/stayManage"));
      });
    },
    name: "Dashboard",
    meta: {
      title: "留资管理",
      icon: "dashboard",
      affix: true
    }
  }]
}, {
  path: "/setting/uploadPicture",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/components/uploadPicture/index.vue"));
    });
  },
  name: "uploadPicture"
}, {
  path: "/setting/uploadPictureNew",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/components/uploadPicture/newUpload.vue"));
    });
  },
  name: "uploadPictureNew"
},
// 404 page must be placed at the end !!!
{
  path: "*",
  redirect: "/404",
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    mode: "history",
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "components-container" },
          [
            _c(
              "el-form",
              {
                ref: "pram",
                attrs: { "label-width": "150px", model: _vm.pram },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "类型：", props: "articleType" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.onChangeSpec(_vm.pram.articleType)
                          },
                        },
                        model: {
                          value: _vm.pram.articleType,
                          callback: function ($$v) {
                            _vm.$set(_vm.pram, "articleType", $$v)
                          },
                          expression: "pram.articleType",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("轻阅读"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("微视频"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "标题",
                      prop: "title",
                      rules: [
                        {
                          required: true,
                          message: "请填写标题",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "标题", maxlength: "100" },
                      model: {
                        value: _vm.pram.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "title", $$v)
                        },
                        expression: "pram.title",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "描述",
                      prop: "describeStr",
                      rules: [
                        {
                          required: true,
                          message: "请填写描述",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "描述", maxlength: "100" },
                      model: {
                        value: _vm.pram.describeStr,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "describeStr", $$v)
                        },
                        expression: "pram.describeStr",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "作者",
                      prop: "author",
                      rules: [
                        {
                          required: true,
                          message: "请填作者",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "作者", maxlength: "20" },
                      model: {
                        value: _vm.pram.author,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "author", $$v)
                        },
                        expression: "pram.author",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "分类",
                      prop: "cidList",
                      rules: [
                        {
                          required: true,
                          message: "请选择活动区域",
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { multiple: "", placeholder: "请选择" },
                        model: {
                          value: _vm.pram.cidList,
                          callback: function ($$v) {
                            _vm.$set(_vm.pram, "cidList", $$v)
                          },
                          expression: "pram.cidList",
                        },
                      },
                      _vm._l(_vm.categoryTreeData, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "标签",
                      prop: "label",
                      rules: [
                        {
                          required: true,
                          message: "请填写标签",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder:
                          "请输入内容标签,以英文状态下的逗号隔开,最多16个字符",
                        maxlength: "16",
                      },
                      model: {
                        value: _vm.pram.label,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "label", $$v)
                        },
                        expression: "pram.label",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "后台标签",
                      prop: "clLabel",
                      rules: [
                        {
                          required: true,
                          message: "请填写标签",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "仅传输回convertlab，以英文逗号隔开",
                        maxlength: "255",
                      },
                      model: {
                        value: _vm.pram.clLabel,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "clLabel", $$v)
                        },
                        expression: "pram.clLabel",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.pram.articleType == 0
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "图文封面",
                          prop: "imageInput",
                          rules: [
                            {
                              required: true,
                              message: "请上传图文封面",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("1")
                              },
                            },
                          },
                          [
                            _vm.pram.imageInput
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: { src: _vm.pram.imageInput },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("(推荐尺寸：400 X 400像素,1：1)"),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.pram.articleType == 1
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "视频封面",
                          prop: "imageInput",
                          rules: [
                            {
                              required: true,
                              message: "请上传视频封面",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("1")
                              },
                            },
                          },
                          [
                            _vm.pram.imageInput
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: { src: _vm.pram.imageInput },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("(推荐尺寸：331px x 474px 200dpi)"),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.pram.articleType == 0
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "运营规范:" } },
                      [
                        _c("div", { staticClass: "upLoadPicBox" }, [
                          _c(
                            "div",
                            { staticClass: "demo-pictrue" },
                            [
                              _c("el-image", {
                                staticStyle: { width: "58px", height: "58px" },
                                attrs: {
                                  src: "https://dellapp.oss-cn-beijing.aliyuncs.com/ecomimage/maintain/2022/01/06/e92b12cda5904caa9c378487b112a425iv9txtvcvz.jpg",
                                  "preview-src-list": _vm.srcList,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "des-info demo-like",
                            attrs: { placement: "bottom" },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [_vm._v("编辑种草内容的运营规范")]
                            ),
                            _vm._v(" "),
                            _c("i", { staticClass: "el-icon-question" }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.pram.articleType == 1
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "视频",
                          prop: "imageInput",
                          rules: [
                            {
                              required: true,
                              message: "请上传视频",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _vm.articleContent[0].content
                          ? _c("div", { staticClass: "avatar" }, [
                              _c("video", {
                                attrs: {
                                  src: _vm.articleContent[0].content,
                                  controls: "controls",
                                },
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-error btndel",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRemoveVideo()
                                  },
                                },
                              }),
                            ])
                          : _c("upload-file", {
                              attrs: {
                                value: _vm.articleContent[0].content,
                                fileType: "mov,mp4,rmvb",
                              },
                              on: { input: _vm.getVideo },
                            }),
                        _vm._v(" "),
                        _c("span", { staticStyle: { color: "#999" } }, [
                          _vm._v("(推荐格式:mov,mp4,rmvb)"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.pram.articleType == 0
                  ? _c("el-form-item", { attrs: { label: "选择内容" } }, [
                      _c(
                        "div",
                        [
                          _vm._l(_vm.articleContent, function (itema, indexa) {
                            return _c(
                              "div",
                              { key: indexa, staticClass: "content_item" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "choose_msg" },
                                  [
                                    _c("ueditor-from", {
                                      attrs: { content: itema.content },
                                      model: {
                                        value: itema.content,
                                        callback: function ($$v) {
                                          _vm.$set(itema, "content", $$v)
                                        },
                                        expression: "itema.content",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "choosw_imgs phone-list" },
                                  [
                                    _vm._l(
                                      itema.phoneList,
                                      function (rowItem, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "row-set",
                                          },
                                          [
                                            _c("div", { staticClass: "f-t" }, [
                                              _vm._v(
                                                "电话" +
                                                  _vm._s(index + 1) +
                                                  "："
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(rowItem.phone) +
                                                  "\n                    "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "125px",
                                                  flex: "unset",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delPhone(
                                                          index,
                                                          indexa
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm.articleContent[indexa].phoneTemModel
                                      ? _c("div", { staticClass: "row-set" }, [
                                          _c("div", { staticClass: "f-t" }, [
                                            _vm._v("电话："),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    prop: "picName",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "180px",
                                                      "margin-right": "20px",
                                                    },
                                                    attrs: {
                                                      placeholder: "请输入",
                                                    },
                                                    model: {
                                                      value:
                                                        itema.phoneTemModel
                                                          .phone,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          itema.phoneTemModel,
                                                          "phone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "itema.phoneTemModel.phone",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "125px",
                                                flex: "unset",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.submitPhoneRow(
                                                        indexa
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("保存")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.phoneCancle(
                                                        indexa
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("取消")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        itema.sceneList.length < 10
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addPhone(indexa)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "新增电话\n                  "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "choosw_imgs" },
                                  [
                                    _vm._l(
                                      itema.sceneList,
                                      function (rowItem, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "row-set",
                                          },
                                          [
                                            _c("div", { staticClass: "f-t" }, [
                                              _vm._v("访问页面："),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleSetUrl(
                                                          indexa,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        rowItem.jumpType > 0
                                                          ? "已配置"
                                                          : "路径配置"
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "f-t" }, [
                                              _vm._v("图片："),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalFirmPicTap(
                                                        index,
                                                        indexa
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  rowItem.pic
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: rowItem.pic,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass: "upLoad",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "f-t" }, [
                                              _vm._v("图片名称："),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "",
                                                      prop: "",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "180px",
                                                        "margin-right": "20px",
                                                      },
                                                      attrs: {
                                                        placeholder: "请输入",
                                                      },
                                                      model: {
                                                        value: rowItem.picName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            rowItem,
                                                            "picName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "rowItem.picName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delFirm(
                                                          index,
                                                          indexa
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm.articleContent[indexa].firmModel
                                      ? _c("div", { staticClass: "row-set" }, [
                                          _c("div", { staticClass: "f-t" }, [
                                            _vm._v("访问页面："),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleSetUrl(
                                                        indexa,
                                                        -1
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      itema.firmModel.jumpType >
                                                        0
                                                        ? "已配置"
                                                        : "路径配置"
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "f-t" }, [
                                            _vm._v("图片："),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "upLoadPicBox",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.modalFirmPicTap(
                                                      -1,
                                                      indexa
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                itema.firmModel.pic
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "pictrue",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: itema.firmModel
                                                              .pic,
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      { staticClass: "upLoad" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "f-t" }, [
                                            _vm._v("图片名称："),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    prop: "picName",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "180px",
                                                      "margin-right": "20px",
                                                    },
                                                    attrs: {
                                                      placeholder: "请输入",
                                                    },
                                                    model: {
                                                      value:
                                                        itema.firmModel.picName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          itema.firmModel,
                                                          "picName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "itema.firmModel.picName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.submitFirmRow(
                                                        indexa
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("保存")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.firmCancle(
                                                        indexa
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("取消")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        itema.sceneList.length < 10
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addFirm(indexa)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "新增图片配置\n                  "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "choose_goods" }, [
                                  _c(
                                    "div",
                                    { staticClass: "acea-row picss" },
                                    [
                                      _vm._l(
                                        itema.articleProductList,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "pictrue",
                                            },
                                            [
                                              _c("img", {
                                                attrs: { src: item.image },
                                              }),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-error btndel",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleRemove(
                                                      indexa,
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "upLoadPicBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeGood(indexa)
                                            },
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-shopping-cart-2 cameraIconfont",
                                            }),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "deleteContItem" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeItem(indexa)
                                          },
                                        },
                                      },
                                      [_vm._v("删除本段")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "continueAdd",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.addContentGoods()
                                },
                              },
                            },
                            [_vm._v("继续添加")]
                          ),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.pram.articleType == 1
                  ? _c("el-form-item", { attrs: { label: "关联商品：" } }, [
                      _c("div", { staticClass: "content_item" }, [
                        _c("div", { staticClass: "choose_goods" }, [
                          _c(
                            "div",
                            { staticClass: "acea-row picss" },
                            [
                              _vm._l(
                                _vm.articleContent[0].articleProductList,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "pictrue" },
                                    [
                                      _c("img", { attrs: { src: item.image } }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-error btndel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(0, index)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeGood()
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-shopping-cart-2 cameraIconfont",
                                    }),
                                  ]),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "留资标题",
                      prop: "stayTitle",
                      rules: [
                        {
                          required: false,
                          message: "请填写留资标题",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "表单头图标题，最多可输入7个字",
                        maxlength: "7",
                      },
                      model: {
                        value: _vm.pram.stayTitle,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "stayTitle", $$v)
                        },
                        expression: "pram.stayTitle",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "留资头图",
                      prop: "stayHeadImage",
                      rules: [
                        {
                          required: false,
                          message: "请上传留资头图",
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "upLoadPicBox",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTapStay("1")
                          },
                        },
                      },
                      [
                        _vm.pram.stayHeadImage
                          ? _c("div", { staticClass: "pictrue" }, [
                              _c("img", {
                                attrs: { src: _vm.pram.stayHeadImage },
                              }),
                            ])
                          : _c("div", { staticClass: "upLoad" }, [
                              _c("i", {
                                staticClass: "el-icon-camera cameraIconfont",
                              }),
                            ]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { color: "#999" } }, [
                          _vm._v("(推荐尺寸：380 X 750像素,1：1)"),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.pram.articleType == 0
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "相关内容推荐：" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "dialogWidth",
                            staticStyle: { width: "250px" },
                            attrs: { placeholder: "请选择", readOnly: "" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { slot: "append" },
                                on: { click: _vm.changeLike },
                                slot: "append",
                              },
                              [_vm._v("选择")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.pram.articleType == 0 &&
                _vm.pram.articleRecommendList.length > 0
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "已关联:" } },
                      _vm._l(
                        _vm.pram.articleRecommendList,
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "store-child" },
                            [
                              _c("div", [_vm._v(_vm._s(item.title))]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "del-btn",
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeArticle(index)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "是否热门" } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.pram.isHot,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "isHot", $$v)
                        },
                        expression: "pram.isHot",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "排序", prop: "sort" } },
                  [
                    _c("el-input-number", {
                      attrs: { label: "排序", min: 0 },
                      model: {
                        value: _vm.pram.sort,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "sort", $$v)
                        },
                        expression: "pram.sort",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "企业服务", prop: "isEnterpriseService" } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.pram.isEnterpriseService,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "isEnterpriseService", $$v)
                        },
                        expression: "pram.isEnterpriseService",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.pram.isEnterpriseService
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "副标题", prop: "subtitle" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "副标题", maxlength: "16" },
                          model: {
                            value: _vm.pram.subtitle,
                            callback: function ($$v) {
                              _vm.$set(_vm.pram, "subtitle", $$v)
                            },
                            expression: "pram.subtitle",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.pram.isEnterpriseService
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "封面配置",
                          prop: "coverImage",
                          rules: [
                            {
                              required: true,
                              message: "请上传封面配置",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTapTwo("1")
                              },
                            },
                          },
                          [
                            _vm.pram.coverImage
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: { src: _vm.pram.coverImage },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("（推荐尺寸：696px X 360px）"),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "园区首页" } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.pram.isParkFirst,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "isParkFirst", $$v)
                        },
                        expression: "pram.isParkFirst",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.pram.isParkFirst
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "封面配置",
                          prop: "parkCoverImage",
                          rules: [
                            {
                              required: true,
                              message: "请上传封面配置",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTapThree("1")
                              },
                            },
                          },
                          [
                            _vm.pram.parkCoverImage
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: { src: _vm.pram.parkCoverImage },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("（推荐尺寸：400px X 400px）"),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.pram.isParkFirst
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "标签配置" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入标签配置",
                            maxlength: "5",
                          },
                          model: {
                            value: _vm.pram.parkTag,
                            callback: function ($$v) {
                              _vm.$set(_vm.pram, "parkTag", $$v)
                            },
                            expression: "pram.parkTag",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "是否精彩推荐" } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.pram.isRecommend,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "isRecommend", $$v)
                        },
                        expression: "pram.isRecommend",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loading },
                        on: {
                          click: function ($event) {
                            return _vm.handerSubmit("pram")
                          },
                        },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("page-link", {
        ref: "pageLinkSet",
        attrs: { visible: _vm.pageLinkShow, editData: _vm.pageLinkData },
        on: {
          "update:visible": function ($event) {
            _vm.pageLinkShow = $event
          },
          cancle: _vm.cancleSetUrl,
          save: _vm.updateUrlSet,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
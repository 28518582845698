var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "发放详情",
        visible: _vm.dialogVisible,
        "destroy-on-close": "",
        "close-on-click-modal": false,
      },
      on: { close: _vm.handleClose },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.listData.list, height: "400px", size: "mini" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", "min-width": "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "couponName", label: "名称", "min-width": "180" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "money", label: "面值", "min-width": "80" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { "min-width": "150", label: "有效期限" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.day
                      ? _c("div", [_vm._v(_vm._s(row.day) + "天")])
                      : _c("span", [
                          _vm._v(
                            "\n          " + _vm._s(row.useStartTime) + " -"
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(row.useEndTime) +
                              "\n        "
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "totalStr", label: "发布数量", "min-width": "80" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: "发送日期",
              "min-width": "150",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.listPram.limit,
              "current-page": _vm.listPram.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.listData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
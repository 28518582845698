"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'NodeForm',
  data: function data() {
    return {
      nodeForm: {
        name: '' // 节点名称
      },
      nodeRule: {
        name: [{
          required: true,
          message: '请输入节点名称',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    nodeFormInit: function nodeFormInit(name) {
      this.nodeForm.name = name;
    },
    validate: function validate() {
      if (this.nodeForm.name) {
        return true;
      }
      return false;
    }
  }
};
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _router = require("@/router");
var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));
var _replyList = _interopRequireDefault(require("@/components/Category/replyList"));
var _contentManage = _interopRequireDefault(require("./contentManage.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "index"
  components: {
    categoryList: _replyList.default,
    contentManage: _contentManage.default
  },
  data: function data() {
    return {
      asyncRoutes: _router.asyncRoutes,
      constants: this.$constants,
      activeName: 'first'
    };
  },
  mounted: function mounted() {},
  methods: {
    categoryAdd: function categoryAdd(pram) {
      var _this = this;
      var _pram = {
        name: pram.name,
        pid: pram.pid,
        sort: pram.sort,
        status: pram.status,
        type: 8
      };
      categoryApi.addCategroy(_pram).then(function (data) {
        _this.$message.success('添加菜单成功');
      });
    },
    getNewList: function getNewList() {
      console.log(232324);
    }
  }
};
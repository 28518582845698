var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.id ? "修改核销员" : "添加核销员",
        visible: _vm.dialogFormVisible,
        width: "750px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.cancel,
      },
      model: {
        value: _vm.dialogFormVisible,
        callback: function ($$v) {
          _vm.dialogFormVisible = $$v
        },
        expression: "dialogFormVisible",
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "150px",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "管理员：", prop: "uid" } },
            [
              _c("span", {
                domProps: { textContent: _vm._s(_vm.ruleForm.avatar) },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.upImg },
                },
                [_vm._v("选择管理员")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "所属提货点：", prop: "storeId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "50%" },
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.ruleForm.storeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "storeId", $$v)
                    },
                    expression: "ruleForm.storeId",
                  },
                },
                _vm._l(_vm.storeSelectList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "核销员名称：" } },
            [
              _c("el-input", {
                staticClass: "dialogWidth",
                attrs: { placeholder: "请输入核销员名称" },
                model: {
                  value: _vm.ruleForm.staffName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "staffName", $$v)
                  },
                  expression: "ruleForm.staffName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "手机号码：" } },
            [
              _c("el-input", {
                staticClass: "dialogWidth",
                attrs: { placeholder: "请输入手机号码" },
                model: {
                  value: _vm.ruleForm.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "phone", $$v)
                  },
                  expression: "ruleForm.phone",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _vm.id
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.editForm("ruleForm")
                    },
                  },
                },
                [_vm._v("修改")]
              )
            : _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c("customer-info", { ref: "customer", on: { upImgUid: _vm.upImgUid } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "nodeForm",
      attrs: {
        model: _vm.nodeForm,
        rules: _vm.nodeRule,
        "label-width": "120px",
      },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 18 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "节点名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.nodeForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.nodeForm, "name", $$v)
                      },
                      expression: "nodeForm.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
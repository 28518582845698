"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _couponsForm = _interopRequireDefault(require("../common/coupons-form"));
var _generalMixin = require("./general-mixin");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    couponsFlowForm: _couponsForm.default
  },
  mixins: [_generalMixin.generalMixin],
  methods: {}
};
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var roleApi = _interopRequireWildcard(require("@/api/role.js"));
var systemAdminApi = _interopRequireWildcard(require("@/api/systemadmin.js"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  // name: "edit"
  components: {},
  props: {
    isCreate: {
      type: Number,
      required: true
    },
    editData: {
      type: Object,
      default: function _default() {
        return {
          rules: []
        };
      }
    }
  },
  data: function data() {
    var _this = this;
    var validatePhone = function validatePhone(rule, value, callback) {
      if (!value) {
        return callback(new Error('请填写手机号'));
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error('手机号格式不正确!'));
      } else {
        callback();
      }
    };
    var validatePass = function validatePass(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== _this.pram.pwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      constants: this.$constants,
      pram: {
        account: null,
        level: null,
        pwd: null,
        repwd: null,
        realName: null,
        roles: [],
        status: null,
        id: null,
        phone: null
      },
      roleList: [],
      rules: {
        account: [{
          required: true,
          message: '请填管理员账号',
          trigger: ['blur', 'change']
        }],
        // level: null,
        pwd: [{
          required: true,
          message: '请填管理员密码',
          trigger: ['blur', 'change']
        }],
        repwd: [{
          required: true,
          message: '确认密码密码',
          validator: validatePass,
          trigger: ['blur', 'change']
        }],
        realName: [{
          required: true,
          message: '管理员姓名',
          trigger: ['blur', 'change']
        }],
        roles: [{
          required: true,
          message: '管理员身份',
          type: 'array',
          trigger: ['blur', 'change']
        }],
        phone: [{
          validator: validatePhone,
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.initEditData();
    this.handleGetRoleList();
  },
  methods: {
    close: function close() {
      this.$emit('hideEditDialog');
    },
    handleGetRoleList: function handleGetRoleList() {
      var _this2 = this;
      var _pram = {
        page: 1,
        limit: this.constants.page.limit[4],
        status: 1
      };
      roleApi.getRoleList(_pram).then(function (data) {
        _this2.roleList = data;
      });
    },
    initEditData: function initEditData() {
      if (this.isCreate !== 1) return;
      var _this$editData = this.editData,
        account = _this$editData.account,
        realName = _this$editData.realName,
        roles = _this$editData.roles,
        level = _this$editData.level,
        status = _this$editData.status,
        id = _this$editData.id,
        phone = _this$editData.phone;
      this.pram.account = account;
      this.pram.realName = realName;
      var _roles = [];
      if (roles.length > 0 && !roles.includes(',')) {
        _roles.push(Number.parseInt(roles));
      } else {
        _roles.push.apply(_roles, _toConsumableArray(roles.split(',').map(function (item) {
          return Number.parseInt(item);
        })));
      }
      this.pram.roles = _roles;
      this.pram.status = status;
      this.pram.id = id;
      this.pram.phone = phone;
      this.rules.pwd = [];
      this.rules.repwd = [];
    },
    handlerSubmit: function handlerSubmit(form) {
      var _this3 = this;
      this.$refs[form].validate(function (valid) {
        if (!valid) return;
        if (_this3.isCreate === 0) {
          _this3.handlerSave();
        } else {
          _this3.handlerEdit();
        }
      });
    },
    handlerSave: function handlerSave() {
      var _this4 = this;
      systemAdminApi.adminAdd(this.pram).then(function (data) {
        _this4.$message.success('创建管理员成功');
        _this4.$emit('hideEditDialog');
      });
    },
    handlerEdit: function handlerEdit() {
      var _this5 = this;
      this.pram.roles = this.pram.roles.join(',');
      systemAdminApi.adminUpdate(this.pram).then(function (data) {
        _this5.$message.success('更新管理员成功');
        _this5.$emit('hideEditDialog');
      });
    },
    rulesSelect: function rulesSelect(selectKeys) {
      this.pram.rules = selectKeys;
    },
    handlerPwdInput: function handlerPwdInput(val) {
      var _this6 = this;
      if (!val) {
        this.rules.pwd = [];
        this.rules.repwd = [];
        return;
      }
      this.rules.pwd = [{
        required: true,
        message: '请填管理员密码',
        trigger: ['blur', 'change']
      }, {
        min: 6,
        max: 20,
        message: '长度6-20个字符',
        trigger: ['blur', 'change']
      }];
      this.rules.repwd = [{
        required: true,
        message: '两次输入密码不一致',
        validator: function validator(rule, value, callback) {
          if (value === '') {
            callback(new Error('两次输入密码不一致!'));
          } else if (value !== _this6.pram.pwd) {
            callback(new Error('两次输入密码不一致!'));
          } else {
            callback();
          }
        },
        trigger: ['blur', 'change']
      }];
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/goodList/index.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'GoodListFrom',
  components: {
    goodList: _index.default
  },
  data: function data() {
    return {
      handleNum: '',
      visible: false,
      callback: function callback() {},
      checked: []
    };
  },
  methods: {
    handleClose: function handleClose() {
      this.visible = false;
    },
    getStoreItem: function getStoreItem(img) {
      // console.log(img, 'iiiii')
      this.callback(img);
      this.visible = false;
    }
  }
};
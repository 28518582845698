"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));
var _Parser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/Parser"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/**
   * 注意：和Parser唯一的区别就是这里仅仅传入表单配置id即可自动加载已配置的表单
   *      数据后渲染表单，
   *      其他业务和Parser保持一致
   */
var _default = exports.default = {
  // name: "ZBParser"
  components: {
    parser: _Parser.default
  },
  props: {
    formId: {
      type: Number,
      required: true
    },
    isCreate: {
      type: Number,
      default: 0 // 0=create 1=edit
    },
    editData: {
      type: Object
    }
  },
  data: function data() {
    return {
      loading: false,
      formConf: {
        fields: []
      }
    };
  },
  mounted: function mounted() {
    this.handlerGetFormConfig(this.formId);
  },
  methods: {
    handlerGetFormConfig: function handlerGetFormConfig(formId) {
      var _this = this;
      // 获取表单配置后生成table列
      this.loading = true;
      var _pram = {
        id: formId
      };
      systemFormConfigApi.getFormConfigInfo(_pram).then(function (data) {
        _this.formConf = JSON.parse(data.content);
        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
    },
    handlerSubmit: function handlerSubmit(formValue) {
      // console.log("2222");
      this.$emit('submit', formValue);
    },
    resetForm: function resetForm(formValue) {
      this.$emit('resetForm', formValue);
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content_item" }, [
      _c("div", { staticClass: "choose_goods" }, [
        _c(
          "div",
          { staticClass: "acea-row picss" },
          [
            _vm._l(
              _vm.articleContent.articleProductList,
              function (item, index) {
                return _c("div", { key: index, staticClass: "pictrue" }, [
                  _c("img", { attrs: { src: item.image } }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "el-icon-error btndel",
                    on: {
                      click: function ($event) {
                        return _vm.handleRemove(index)
                      },
                    },
                  }),
                ])
              }
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "upLoadPicBox", on: { click: _vm.changeGood } },
              [_vm._m(0)]
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "choose_msg" },
        [
          _c("ueditor-from", {
            attrs: { content: _vm.articleContent.content },
            model: {
              value: _vm.articleContent.content,
              callback: function ($$v) {
                _vm.$set(_vm.articleContent, "content", $$v)
              },
              expression: "articleContent.content",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "deleteContItem" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.deleteItem()
                },
              },
            },
            [_vm._v("删除文章及商品")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upLoad" }, [
      _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
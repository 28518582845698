var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.formModel.id ? "编辑维修点" : "新增维修点",
            visible: _vm.visible,
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "560px",
          },
          on: { close: _vm.cancle },
        },
        [
          _c(
            "el-form",
            {
              ref: "editPram",
              attrs: {
                model: _vm.formModel,
                "label-width": "110px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "维修点编号",
                    prop: "code",
                    rules: [
                      {
                        required: true,
                        message: "请输入",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "15", placeholder: "请输入维修点编号" },
                    model: {
                      value: _vm.formModel.code,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formModel,
                          "code",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formModel.code",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "省",
                    prop: "province",
                    rules: [
                      {
                        required: true,
                        message: "请输入",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", placeholder: "请输入省" },
                    model: {
                      value: _vm.formModel.province,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModel, "province", $$v)
                      },
                      expression: "formModel.province",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "市",
                    prop: "city",
                    rules: [
                      {
                        required: true,
                        message: "请输入",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", placeholder: "请输入市" },
                    model: {
                      value: _vm.formModel.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModel, "city", $$v)
                      },
                      expression: "formModel.city",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "区",
                    prop: "district",
                    rules: [
                      {
                        required: true,
                        message: "请输入",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", placeholder: "请输入区" },
                    model: {
                      value: _vm.formModel.district,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModel, "district", $$v)
                      },
                      expression: "formModel.district",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "地址",
                    prop: "detailedAddress",
                    rules: [
                      {
                        required: true,
                        message: "请输入",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", placeholder: "请输入地址" },
                    model: {
                      value: _vm.formModel.detailedAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModel, "detailedAddress", $$v)
                      },
                      expression: "formModel.detailedAddress",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "电话",
                    prop: "phone",
                    rules: [
                      {
                        required: true,
                        message: "请输入",
                        trigger: "blur",
                      },
                      {
                        pattern: /^(\+|[0-9])?([0-9]|-|\/|,|\+)*[0-9]{5,}$/,
                        message: "请输入正确的电话",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入多个电话时，请用英文逗号隔开" },
                    model: {
                      value: _vm.formModel.phone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formModel,
                          "phone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formModel.phone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancle } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.submitLoading,
                    type: "primary",
                    plain: "",
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
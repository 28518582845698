"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserList',
  filters: {
    saxFilter: function saxFilter(status) {
      var statusMap = {
        0: '未知',
        1: '男',
        2: '女'
      };
      return statusMap[status];
    },
    statusFilter: function statusFilter(status) {
      var statusMap = {
        'wechat': '微信用户',
        'routine': '小程序用户'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      templateRadio: 0,
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 10,
        keywords: ''
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getTemplateRow: function getTemplateRow(idx, row) {
      this.$emit('getTemplateRow', row);
    },
    // 列表
    getList: function getList() {
      var _this = this;
      this.loading = true;
      (0, _user.userListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.loading = false;
      }).catch(function (res) {
        _this.$message.error(res.message);
        _this.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _图标 = _interopRequireDefault(require("@/assets/imgs/\u56FE\u6807.png"));
var _errImg = _interopRequireDefault(require("@/assets/errImg.png"));
var _streamManage = require("@/api/streamManage");
var _emotion = _interopRequireDefault(require("../emotion/emotion.vue"));
var _store = require("@/api/store");
var _dayjs = _interopRequireDefault(require("dayjs"));
var _workSpace = require("@/api/workSpace");
var _marketing = require("@/api/marketing");
var _index2 = _interopRequireDefault(require("@/components/uploadPicture/index.vue"));
var _systemConfig = require("@/api/systemConfig");
var _auth = require("@/utils/auth");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import serviceImg from "@/assets/serviceImg.png";
// import { orderListApi } from "@/api/order";
// import { ListArticle } from "@/api/article.js";
var _default = exports.default = {
  components: {
    Emotion: _emotion.default,
    uploadPic: _index2.default
  },
  data: function data() {
    return {
      openHeight1: false,
      beginTalking: true,
      //页面初始化进入
      isTalking: false,
      windowHeight: document.documentElement.clientHeight,
      selectLi: -1,
      activeNames: "1",
      talkingData: [
      // 待接入
      {
        label: "待接入(0)人",
        children: [{}]
      },
      // 已接入
      {
        label: "已接入(0)人",
        children: []
      },
      // 已完成
      {
        label: "已完成(0)人",
        children: []
      }],
      message: "第一次测试",
      from2: "",
      from1: "https://dellapp.oss-cn-beijing.aliyuncs.com/ecomimage/maintain/2022/01/11/5d9c995e3fdb4c8fac3877e268a6cc8fqwee2ztlki.png",
      from: 1,
      from3: "https://dellapp.oss-cn-beijing.aliyuncs.com/ecomimage/maintain/2022/01/11/b26d1c7ebfde4de6bd779a3c0ca7acd02an5wbe3bh.png",
      chatItems: [],
      editableTabs: [{
        title: "会话中",
        name: "1",
        content: "Tab 1 content"
      }, {
        title: "历史会话",
        name: "2",
        content: "Tab 2 content"
      }],
      editableTabsValue: "first",
      childTabsValue: "one",
      inputContent: "",
      list: [["微笑", "撇嘴", "色", "发呆", "得意", "流泪", "害羞", "闭嘴"], ["睡", "大哭", "尴尬", "发怒", "调皮", "呲牙", "惊讶", "难过"][("酷", "冷汗", "抓狂", "吐", "偷笑", "可爱", "白眼", "傲慢")], ["饥饿", "困", "惊恐", "流汗", "憨笑", "大兵", "奋斗", "咒骂"], ["疑问", "嘘", "晕", "折磨", "衰", "骷髅", "敲打", "再见"], ["擦汗", "抠鼻", "鼓掌", "糗大了", "坏笑", "左哼哼", "右哼哼", "哈欠"], ["鄙视", "委屈", "快哭了", "阴险", "亲亲", "吓", "可怜", "菜刀"], ["西瓜", "啤酒", "篮球", "乒乓", "咖啡", "饭", "猪头", "玫瑰"], ["凋谢", "示爱", "爱心", "心碎", "蛋糕", "闪电", "炸弹", "刀"], ["足球", "瓢虫", "便便", "月亮", "太阳", "礼物", "拥抱", "强"], ["弱", "握手", "胜利", "抱拳", "勾引", "拳头", "差劲", "爱你"], ["NO", "OK", "爱情", "飞吻", "跳跳", "发抖", "怄火", "转圈"], ["磕头", "回头", "跳绳", "挥手", "激动", "街舞", "左太极", "右太极"]],
      list1: ["微笑", "撇嘴", "色", "发呆", "得意", "流泪", "害羞", "闭嘴", "睡", "大哭", "尴尬", "发怒", "调皮", "呲牙", "惊讶", "难过", "酷", "冷汗", "抓狂", "吐", "偷笑", "可爱", "白眼", "傲慢", "饥饿", "困", "惊恐", "流汗", "憨笑", "大兵", "奋斗", "咒骂", "疑问", "嘘", "晕", "折磨", "衰", "骷髅", "敲打", "再见", "擦汗", "抠鼻", "鼓掌", "糗大了", "坏笑", "左哼哼", "右哼哼", "哈欠", "鄙视", "委屈", "快哭了", "阴险", "亲亲", "吓", "可怜", "菜刀", "西瓜", "啤酒", "篮球", "乒乓", "咖啡", "饭", "猪头", "玫瑰", "凋谢", "示爱", "爱心", "心碎", "蛋糕", "闪电", "炸弹", "刀", "足球", "瓢虫", "便便", "月亮", "太阳", "礼物", "拥抱", "强", "弱", "握手", "胜利", "抱拳", "勾引", "拳头", "差劲", "爱你", "NO", "OK", "爱情", "飞吻", "跳跳", "发抖", "怄火", "转圈", "磕头", "回头", "跳绳", "挥手", "激动", "街舞", "左太极", "右太极"],
      showEmotion: false,
      manyiDialog: false,
      goodsDetailInput: "",
      goodsDetailDialog: false,
      goodsDetailTable: [],
      goodsDetailForm: {
        page: 1,
        limit: 10,
        cateId: "",
        keywords: "",
        type: "1",
        // status: -1,
        isPresent: false
      },
      goodsDetail: {},
      wuliuDialog: false,
      wuliuForm: {
        page: 1,
        limit: 10,
        orderId: "",
        status: "spike",
        isDel: false,
        uid: ''
      },
      wuliuTableData: [],
      streamTableData: [],
      PageSize2: 10,
      currentPage2: 1,
      streamDialog: false,
      liveStatusOptions: [{
        label: "直播中",
        value: 101
      }, {
        label: "未开始",
        value: 102
      }, {
        label: "已结束",
        value: 103
      }, {
        label: "禁播",
        value: 104
      }, {
        label: "暂停",
        value: 105
      }, {
        label: "异常",
        value: 106
      }, {
        label: "已过期",
        value: 107
      }],
      streamPageTotal: 0,
      wuliuPageTotal: 0,
      goodsPageTotal: 0,
      articleDialog: false,
      unReadList: [],
      // 未读消息列表
      alReadList: [],
      articleTableData: [],
      articleForm: {
        keywords: null,
        cid: null,
        page: 1,
        limit: 10,
        articleType: 0,
        sortType: 0
      },
      articlePageTotal: 0,
      couponDialog: false,
      couponTableData: [],
      couponPageTotal: 0,
      counponForm: {
        name: null,
        page: 1,
        limit: 10,
        status: 1,
        type: 1,
        isDel: false,
        nowDate: (0, _dayjs.default)(new Date()).format("YYYY-MM-DD HH:MM:SS")
      },
      streamForm: {
        start: 1,
        // 从第几个房间开始拉取
        limit: 10 // 每次拉取的数量
      },
      websocket: null,
      lastPer: "",
      insertWebsocket: null,
      //点击接入时建立websocket链接
      baseInfoForm: {},
      //基本资料表单
      orderForm: [],
      //订单信息表单
      orderPage: 1,
      orderUid: "",
      env: "",
      replyContent: "",
      replyOptions: [],
      categoryProps: {
        value: "id",
        label: "name",
        children: "child",
        isLeaf: "leaf"
      },
      pictureDialogVisiable: false,
      imageList: [],
      isMore: "",
      wuliuLoading: false,
      goodsLoading: false,
      streamLoading: false,
      articleLoading: false,
      satisTips: "",
      rightCard: false,
      goodsCurrentRow: {},
      wuliuCurrentRow: {},
      couponCurrentRow: {},
      articleCurrentRow: {},
      streamCurrentRow: {},
      tagDialogVisiable: false,
      tagInput: "",
      tagForm: {},
      tagFormRules: {
        tagInput: [{
          required: true,
          message: "请输入标签名称",
          trigger: "blur"
        }]
      },
      remarkForm: {
        remarkInput: ""
      },
      remarkFormRules: {
        remarkInput: [{
          required: true,
          message: "请输入名称",
          trigger: "blur"
        }]
      },
      remarkDialogVisiable: false,
      closeDialogVisiable: false,
      closeSocket: false,
      imageMessage: {},
      treeNode: {},
      kefuDialogVisiable: false,
      bindKefu: "",
      kefuList: [],
      kefuObject: {},
      fileList: [],
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      },
      mediaId: "",
      orderInfo: "",
      // 订单编号
      active: 1,
      showDetail: false,
      inputUrl: "",
      lockReconnect: false,
      heartCheck: null,
      tt: null,
      heartCheck1: null,
      tt1: null,
      lockReconnect1: false,
      bigPic: "",
      showBigPic: false,
      srcList: [],
      turnReadList: [],
      endUserList: [],
      // 已完成列表
      accessLabel: "已接入",
      waitLabel: "未接入",
      completeLabel: "已完成(0)人",
      websocketState: false,
      isActive: false,
      isBegintalking: false,
      inputContentText: "",
      // 根据不同的订单状态展示不同的流程
      showOne: false,
      showTwo: false,
      showThree: false,
      completeUser: {},
      arrHostioryList: [],
      pageNum: 1,
      kefuName: this.$store.getters.realName,
      insertSelectLi: false,
      completeSelectLi: -1,
      appid: "wx54a84183a51902f3",
      _this: this,
      goodsDetailObj: {},
      userAddress: "",
      wuliuDisabled: false,
      highlight: false
    };
  },
  created: function created() {
    this.createWebSocket();
    // 第一个心跳检测
    var tt;
    var that = this;
    var heartCheck = {
      timeout: 300000,
      timeoutObj: null,
      serverTimeoutObj: null,
      start: function start() {
        var self = this;
        this.timeoutObj && clearTimeout(this.timeoutObj);
        this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
        this.timeoutObj = setTimeout(function () {
          //这里发送一个心跳，后端收到后，返回一个心跳消息，
          that.websocket.send("{\n            \"toUserId\": \"".concat(that.$store.getters.scoketUserId, "\",\n            \"contentText\": \"ping\",\n            \"type\": \"heartbeat\"\n          }"));
          self.serverTimeoutObj = setTimeout(function () {
            that.websocket.close();
          }, self.timeout);
        }, this.timeout);
      }
    };
    this.heartCheck = heartCheck;
    this.tt = tt;
    heartCheck = null;

    // insert时的心跳检测
    var tt1;
    var that = this;
    var heartCheck1 = {
      timeout1: 300000,
      timeoutObj1: null,
      serverTimeoutObj1: null,
      start: function start() {
        var self1 = this;
        this.timeoutObj && clearTimeout(this.timeoutObj1);
        this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj1);
        this.timeoutObj = setTimeout(function () {
          //这里发送一个心跳，后端收到后，返回一个心跳消息，
          that.insertWebsocket.send("{\n             \"toUserId\": \"".concat(that.lastPer, "\",\n            \"contentText\": \"heartbeat\",\n            \"type\": \"heartbeat\"\n          }"));
          self1.serverTimeoutObj = setTimeout(function () {
            that.insertWebsocket.close();
          }, self1.timeout);
        }, this.timeout1);
      }
    };
    this.heartCheck1 = heartCheck1;
    this.tt1 = tt1;
    heartCheck1 = null;
  },
  mounted: function mounted() {
    this.$store.state.app.sidebar.opened = false;
    // // div键盘事件监听
    // let pgId = this.$refs.inputContent.innerText;
    // console.log(pgId, "pgIdpgId");
    // window.addEventListener("keydown", function () {
    //   var myEvent = new Event("keydown");
    //   if (myEvent.keyCode !== 13) return;
    //   pgId.dispatchEvent(myEvent);
    // });
    console.log(this.windowHeight, 222);
    this.getContentList();
    var that = this;
    // 监听滚动事件
    document.getElementById("bigDiv").addEventListener("scroll", that.handleScroll, true);
  },
  filters: {
    turnTime: function turnTime(val, vue) {
      // console.log(vue, this);
      if (vue.isToday(val)) {
        val = (0, _dayjs.default)(val).format("HH:mm");
      } else {
        val = (0, _dayjs.default)(val).format("YYYY-MM-DD HH:mm");
      }
      return val;
    },
    turnTime1: function turnTime1(val) {
      val = (0, _dayjs.default)(val).format("YYYY-MM-DD HH:mm");
      return val;
    } // levelTurn(e) {
    //   let options = [
    //     { name: "普通会员", value: 1 },
    //     { name: "银卡会员", value: 2 },
    //     { name: "金卡会员", value: 3 },
    //     { name: "钻卡会员", value: 4 },
    //   ];
    //   e = options.filter((val) => {
    //     if (e == val.value) return val.name;
    //   });
    //   if (e && e.length !== 0) {
    //     return e[0].name;
    //   }
    // },
  },
  methods: {
    goOrderDetails: function goOrderDetails(val) {
      console.log(val, 'vvvv');
      var routerJump = this.$router.resolve({
        path: '/orderDetail',
        query: {
          id: val.id
        }
      });
      window.open(routerJump.href, '_blank');
      // this.$router.push({path:'/orderDetail',query:{id: val.id}});
    },
    isToday: function isToday(str) {
      return new Date().getTime() - new Date(str).getTime() < 86400000;
    },
    returnList: function returnList() {
      this.orderInfo = "";
      this.getOrderMessage();
    },
    handleScroll: function handleScroll() {
      var _this = this;
      var that = this;
      var sh = document.getElementById("bigDiv").scrollHeight; // 滚动条高度
      var st = document.getElementById("bigDiv").scrollTop; // 滚动条距离顶部的距离
      var ch = document.getElementById("bigDiv").clientHeight; // 滚动条外容器的高度
      if (st + ch >= sh) {
        that.pageNum += 1;
        if (that.completeTotalPage >= that.pageNum) {
          (0, _workSpace.getCompleteMessage)({
            page: that.pageNum,
            limit: 30,
            userid: that.completeUser.userid
          }).then(function (res) {
            res.list.map(function (item) {
              that.arrHostioryList.push(item);
            });
            console.log(res.list, "rrrr");
            that.handleChatItem(_this.arrHostioryList);
          });
        }
      }
    },
    modalPicTap: function modalPicTap() {
      this.pictureDialogVisiable = true;
    },
    searchEnterFun: function searchEnterFun(e) {
      var _this2 = this;
      // var keyCode = window.event?e.keyCode:e.which;
      // if(keyCode==13){
      if (this.replyContent == "") {
        this.getContentList();
      } else {
        var keywords = this.replyContent;
        (0, _workSpace.getFaqList)({
          keywords: keywords
        }).then(function (res) {
          res.map(function (item) {
            item.name = item.content;
            item.leaf = true;
          });
          console.log(res, "rrrrrreee");
          _this2.replyOptions = res;
        });
      }
      // }
    },
    inputContentClick: function inputContentClick() {
      if (this.$refs.inputContent.innerHTML !== "") {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    },
    createWebSocket: function createWebSocket() {
      try {
        var baseApi = process.env.VUE_APP_BASE_API.split("://");
        //  测试测试
        this.env = "wss://" + baseApi[1] + "/wss/adminserver/";
        // this.env = 'ws://192.168.2.136:8333/adminserver/'
        this.env1 = "wss://" + baseApi[1] + "/wss/userserver/";
        // this.env1 = "ws://192.168.2.136:8333/userserver/";
        this.websocket = new WebSocket(this.env + this.$store.getters.scoketUserId);
        this.initWebSocket();
        this.$store.state.tagsView.websocket = this.websocket;
      } catch (e) {
        this.reconnect(this.env + this.$store.getters.scoketUserId);
      }
    },
    initWebSocket: function initWebSocket() {
      // 连接错误
      this.websocket.onerror = this.setErrorMessage;

      // 连接成功
      this.websocket.onopen = this.setOnopenMessage;

      // 收到消息的回调
      this.websocket.onmessage = this.setOnmessageMessage;

      // 连接关闭的回调
      this.websocket.onclose = this.setOncloseMessage;

      // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = this.onbeforeunload;
    },
    setErrorMessage: function setErrorMessage(e) {
      console.log("连接异常");
      this.websocketState = false;
      this.reconnect(this.env + this.$store.getters.scoketUserId + "/" + 0);
    },
    reconnect: function reconnect(url) {
      var that = this;
      if (this.lockReconnect) {
        return;
      }
      this.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      this.tt && clearTimeout(this.tt);
      this.tt = setTimeout(function () {
        that.createWebSocket(url);
        that.lockReconnect = false;
      }, 4000);
    },
    reconnect1: function reconnect1(url) {
      var that = this;
      if (this.lockReconnect1) {
        return;
      }
      this.lockReconnect1 = true;
      //没连接上会一直重连，设置延迟避免请求过多
      this.tt1 && clearTimeout(this.tt1);
      this.tt1 = setTimeout(function () {
        that.createWebSocket1();
        that.lockReconnect1 = false;
      }, 4000);
    },
    // 返回列表
    goBackList: function goBackList() {
      this.showDetail = false;
    },
    // 进入订单详情
    goDetail: function goDetail(item) {
      var _this3 = this;
      this.goodsDetail = [];
      var pic = item.productList[0].info.productInfo.image;
      this.showDetail = true;
      item.productList.map(function (item) {
        _this3.goodsDetail.push(item);
      });
      this.goodsDetailObj = item;
      this.goodsDetailObj.userAddress = "";
      // this.active = 1
      (0, _workSpace.getOrderDetail)(item.id).then(function (res) {
        _this3.userAddress = res.userAddress;
        console.log(_this3.goodsDetail, _this3.goodsDetailObj, _this3.goodsDetailObj.userAddress, "eeeee");
        //   this.goodsDetail = res;
        //   this.goodsDetail.pic = pic
        //   this.goodsDetail.newStatus = item.statusStr.value
      });
    },
    // 搜索订单信息
    searchOrderInfo: function searchOrderInfo(e) {
      // var keyCode = window.event?e.keyCode:e.which;
      // if(keyCode==13){
      this.getOrderMessage();
      // }
    },
    // 结束对话
    closeTalking: function closeTalking() {
      this.closeDialogVisiable = true;
    },
    closeTalkingClick: function closeTalkingClick() {
      var _this4 = this;
      this.isTalking = false;
      this.isBegintalking = false;
      this.insertWebsocket.send("{\n        \"toUserId\": \"" + this.lastPer + "\",\"contentText\":\"" + this.$store.getters.scoketUserId + "\",\"type\": \"close\"\n}");
      setTimeout(function () {
        _this4.websocket.send("{\"toUserId\":\"" + _this4.$store.getters.scoketUserId + "\",\"contentText\":\"on\",\"type\":\"list\"}");
        _this4.websocket.send("{\"toUserId\":\"" + _this4.$store.getters.scoketUserId + "\",\"contentText\":\"end\",\"type\":\"list\"}");
      }, 1000);
      this.closeSocket = false;
      this.closeDialogVisiable = false;
    },
    // 新增标签
    addTags: function addTags() {
      this.tagDialogVisiable = true;
    },
    sureAddTag: function sureAddTag() {
      var _this5 = this;
      var that = this;
      var params = {
        name: that.tagForm.tagInput,
        tagId: that.baseInfoForm.tagId,
        uid: that.baseInfoForm.uid
      };
      this.$refs.tagForm.validate(function (valid) {
        if (valid) {
          (0, _workSpace.saveUserTag)(params).then(function (res) {
            that.getCardMessage();
            that.$message.success("添加成功");
            _this5.tagDialogVisiable = false;
          }).catch(function (err) {
            // this.$message.error("err");
          });
        }
      });
      that.tagForm.tagInput = "";
    },
    // 删除标签
    handleClose: function handleClose(tag) {
      var _this6 = this;
      var that = this;
      var param = {
        tagId: tag.id,
        uid: this.baseInfoForm.uid
      };
      (0, _workSpace.deleteUserTag)(param).then(function (res) {
        that.getCardMessage();
        _this6.$message.success("删除成功");
      }).catch(function (err) {});
    },
    // 发送直播间卡片
    sendStream: function sendStream() {
      // const url = 'https://workstation.chinaeast2.cloudapp.chinacloudapi.cn:8099/ecomimage/maintain/2021/08/26/ec341b10b1dd4635bbeab383252cbdbc7ishc1c16m.jpg'
      var img = "<div style=\"height:250px\">Dell\u5C0F\u4F01\u4E1A\u5546\u57CE:</br>".concat(this.streamCurrentRow.name, "</br><div style=\"width:200px;height:168px\"><img src=").concat(this.streamCurrentRow.cover_img, " width=\"100%\" height=\"100%\" class=\"cardClass\" id=\"cardClass\" style=\"margin-top:5px\"><el-divider></el-divider></br><img src=\"").concat(_图标.default, "\" width=\"15px\" height=\"15px\" /><span style=\"margin-left:5px\">Dell\u5C0F\u4F01\u4E1A\u5546\u57CE</span></div>");
      this.sendInputMessage(this.streamCurrentRow.name, img, this.streamCurrentRow.roomid, this.streamCurrentRow.cover_img);
      this.streamDialog = false;
      // ${this.streamCurrentRow.cover_img}
      var msg = "{\n          \"toUserId\": \"".concat(this.lastPer, "\",\n          \"contentText\": {\n          \"title\": \"").concat(this.streamCurrentRow.name, "\",\n          \"appid\": \"").concat(this.appid, "\",\n          \"pagepath\": \"plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=").concat(this.streamCurrentRow.roomid, "\",\n          \"thumb_url\": \"").concat(this.streamCurrentRow.cover_img, "\"\n            },\n          \"type\": \"join\",\n          \"msgType\": \"miniprogrampage\",\n          \"kefuname\": \"").concat(this.kefuName, "\",\n          \"kefuavatar\": \"").concat(this.from1, "\"\n          }");
      this.insertWebsocket.send(msg);
    },
    streamCurrentChange: function streamCurrentChange(val) {
      var _this7 = this;
      console.log(val, "bbbbbb");
      (0, _workSpace.findPageAndList)({
        liveId: val.roomid
      }).then(function (res) {
        _this7.streamCurrentRow.cover_img = res[0].coverImg;
        _this7.streamCurrentRow.feeds_img = res[0].feedsImg;
        _this7.streamCurrentRow.share_img = res[0].shareImg;
        _this7.streamCurrentRow.roomid = res[0].liveId;
        _this7.streamCurrentRow.name = val.name;
      });
    },
    // 发送种草文章卡片
    sendArticle: function sendArticle() {
      var img = "Dell\u5C0F\u4F01\u4E1A\u5546\u57CE:</br>".concat(this.articleCurrentRow.title, "<div style=\"width:200px;height:168px\"><img src=").concat(this.articleCurrentRow.imageInput, " width=\"100%\" height=\"100%\" class=\"cardClass\" id=\"cardClass\"></div><el-divider></el-divider></br><img src=\"").concat(_图标.default, "\" width=\"15px\" height=\"15px\" /><span style=\"margin-left:5px\">Dell\u5C0F\u4F01\u4E1A\u5546\u57CE</span>");
      this.sendInputMessage(this.articleCurrentRow.title, img, this.articleCurrentRow.id, this.articleCurrentRow.imageInput);
      this.articleDialog = false;
      var msg = "{\n          \"toUserId\": \"".concat(this.lastPer, "\",\n          \"contentText\": {\n          \"title\": \"").concat(this.articleCurrentRow.title, "\",\n          \"appid\": \"").concat(this.appid, "\",\n          \"pagepath\": \"pages/seeDetail/seeDetail?id=").concat(this.articleCurrentRow.id, "\",\n          \"thumb_url\": \"").concat(this.articleCurrentRow.imageInput, "\"\n            },\n          \"type\": \"join\",\n          \"msgType\": \"miniprogrampage\",\n          \"kefuname\": \"").concat(this.kefuName, "\",\n          \"kefuavatar\": \"").concat(this.from1, "\"\n          }");
      this.insertWebsocket.send(msg);
    },
    articleCurrentChange: function articleCurrentChange(val) {
      this.articleCurrentRow = val;
    },
    // 发送优惠券卡片
    sendCoupon: function sendCoupon() {
      var url = "https://dellapp.oss-cn-beijing.aliyuncs.com/ecomimage/maintain/2022/01/11/5879cba046264b768a88d4f097e1fc97pvj5awgvtn.png";
      var img = "Dell\u5C0F\u4F01\u4E1A\u5546\u57CE:</br>\u70B9\u51FB\u9886\u53D6\u60A8\u7684\u4E13\u5C5E\u4F18\u60E0\u5238<div style=\"width:200px;height:168px\"><img src=".concat(url, " width=\"100%\" height=\"100%\" class=\"cardClass\" id=\"cardClass\"></div><el-divider></el-divider><div style=\"margin-top:5px\"><img src=\"").concat(_图标.default, "\" width=\"15px\" height=\"15px\" /><span style=\"margin-left:5px\">Dell\u5C0F\u4F01\u4E1A\u5546\u57CE</span></div>");
      this.sendInputMessage('点击领取您的专属优惠券', img, this.couponCurrentRow.id, url);
      this.couponDialog = false;
      var msg = "{\n          \"toUserId\": \"".concat(this.lastPer, "\",\n          \"contentText\": {\n          \"title\": \"\u70B9\u51FB\u9886\u53D6\u60A8\u7684\u4E13\u5C5E\u4F18\u60E0\u5238\",\n          \"appid\": \"").concat(this.appid, "\",\n          \"pagepath\": \"pages/users/receive_coupon/index?id=").concat(this.couponCurrentRow.id, "\",\n          \"thumb_url\": \"").concat(url, "\"\n            },\n          \"type\": \"join\",\n          \"msgType\": \"miniprogrampage\",\n          \"kefuname\": \"").concat(this.kefuName, "\",\n          \"kefuavatar\": \"").concat(this.from1, "\"\n          }");
      this.insertWebsocket.send(msg);
    },
    couponCurrentChange: function couponCurrentChange(val) {
      this.couponCurrentRow = val;
    },
    // 发送物流卡片
    sendWuliuMessage: function sendWuliuMessage() {
      var url = "https://dellapp.oss-cn-beijing.aliyuncs.com/ecomimage/maintain/2022/01/11/f2a691d825ea47428ab264a93f2255d3luh69uackn.png";
      var img = "Dell\u5C0F\u4F01\u4E1A\u5546\u57CE:</br>\u8BF7\u70B9\u51FB\u67E5\u770B\u60A8\u7684\u8D27\u7269\u4FE1\u606F<div style=\"width:200px;height:168px\"><img src=".concat(url, " width=\"100%\" height=\"100%\" class=\"cardClass\" id=\"cardClass\"></div><el-divider></el-divider><div style=\"margin-top:5px\"><img src=\"").concat(_图标.default, "\" width=\"15px\" height=\"15px\" /><span style=\"margin-left:5px\">Dell\u5C0F\u4F01\u4E1A\u5546\u57CE</span></div>");
      this.sendInputMessage('请点击查看您的货物信息', img, this.wuliuCurrentRow.id, url);
      this.wuliuDialog = false;
      var msg = "{\n          \"toUserId\": \"".concat(this.lastPer, "\",\n          \"contentText\": {\n          \"title\": \"\u8BF7\u70B9\u51FB\u67E5\u770B\u60A8\u7684\u8D27\u7269\u4FE1\u606F\",\n          \"appid\": \"").concat(this.appid, "\",\n          \"pagepath\": \"pages/users/goods_logistics/index?orderId=").concat(this.wuliuCurrentRow.orderId, "\",\n          \"thumb_url\": \"").concat(url, "\"\n            },\n          \"type\": \"join\",\n          \"msgType\": \"miniprogrampage\",\n          \"kefuname\": \"").concat(this.kefuName, "\",\n          \"kefuavatar\": \"").concat(this.from1, "\"\n          }");
      this.insertWebsocket.send(msg);
    },
    selectedstyle: function selectedstyle(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (!row.deliveryName || !row.deliveryId) {
        return {
          backgroundColor: "#eee"
        };
      }
    },
    wuliuCurrentChange: function wuliuCurrentChange(val) {
      if (val.deliveryName && val.deliveryId) {
        this.highlight = true;
        this.wuliuDisabled = false;
      } else {
        this.highlight = false;
        this.wuliuDisabled = true;
      }
      this.wuliuCurrentRow = val;
    },
    goodsCurrentChange: function goodsCurrentChange(val) {
      this.goodsCurrentRow = val;
    },
    // 发送商品卡片
    chooseGoodsList: function chooseGoodsList() {
      var img = "Dell\u5C0F\u4F01\u4E1A\u5546\u57CE:</br><div style=\"height:20px\">".concat(this.goodsCurrentRow.storeName, "......</div></br><div style=\"width:200px;height:168px\"><img src='").concat(this.goodsCurrentRow.image, "' width=\"100%\" height=\"100%\" class=\"cardClass\" id=\"cardClass\"></div><el-divider></el-divider></br><img src=\"").concat(_图标.default, "\" width=\"15px\" height=\"15px\" /><span style=\"margin-left:5px\">Dell\u5C0F\u4F01\u4E1A\u5546\u57CE</span>");
      this.sendInputMessage(this.goodsCurrentRow.storeName, img, this.goodsCurrentRow.id, this.goodsCurrentRow.image);
      this.goodsDetailDialog = false;
      var msg = "{\n          \"toUserId\": \"".concat(this.lastPer, "\",\n          \"contentText\": {\n          \"title\": \"").concat(this.goodsCurrentRow.storeName, "\",\n          \"appid\": \"").concat(this.appid, "\",\n          \"pagepath\": \"pages/goods_details/index?id=").concat(this.goodsCurrentRow.id, "\",\n          \"thumb_url\": \"").concat(this.goodsCurrentRow.image, "\"\n            },\n          \"type\": \"join\",\n          \"msgType\": \"miniprogrampage\",\n          \"kefuname\": \"").concat(this.kefuName, "\",\n          \"kefuavatar\": \"").concat(this.from1, "\"\n          }");
      this.insertWebsocket.send(msg);
    },
    sendInputMessage: function sendInputMessage(name, img, id, url) {
      var _this8 = this;
      var divTag = document.createElement("span");
      divTag.innerHTML = img;
      this.chatItems.push({
        from: 3,
        content: {
          msg: img
        }
      });
      setTimeout(function () {
        var cssStyle = document.getElementById("cardClass").parentNode.style;
        cssStyle.width = "200px";
        cssStyle.height = "178px";
        cssStyle.paddingTop = "10px";
        cssStyle.borderRadius = "5px";
      }, 500);
      this.$nextTick(function () {
        var scrollDiv = document.getElementById("bigDiv");
        scrollDiv.scrollTop = scrollDiv.scrollHeight;
      });
      this.alReadList.map(function (item, index) {
        if (item.userid === _this8.lastPer) {
          _this8.alReadList[index].lastMsg = {
            msg: '[卡片消息]'
          };
        }
      });
    },
    picClick: function picClick() {
      this.pictureDialogVisiable = true;
    },
    getImage: function getImage(val) {
      this.imageMessage = val[0];
      var imgTag = document.createElement("img");
      imgTag.src = val[0].sattDir;
      imgTag.className = "picClass";
      document.getElementById("inputId").appendChild(imgTag);
      this.pictureDialogVisiable = false;
    },
    loadNode: function loadNode(node, resolve) {
      if (node.level > 2) return resolve([]);
      if (node.level === 1) {
        return resolve(node.data.child);
      }
      if (node.level === 2) {
        console.log(node, "nnnnn");
        (0, _workSpace.getFaqList)({
          cid: node.data.id,
          keywords: this.replyContent
        }).then(function (res) {
          res.map(function (item) {
            item.name = item.content;
            console.log(item.name.length, "item.name.lengthitem.name.length");
            // if(item.name.length > 16){
            //   item.name = item.name.substring(0,16) + '.....'
            // }
            item.leaf = true;
          });
          console.log(res, "rrrrr");
          return resolve(res);
        });
      }
    },
    treeClick: function treeClick(val, val1, val2) {
      if (!val.leaf) return;
      this.inputContent = val.name;
      this.treeNode = val;
      // console.log(this.$refs.inputContent.innerText, 'this.$refs.inputContent.innerText');
      // if (this.$refs.inputContent.innerText !== "") {
      this.isActive = true;
      // } else {
      // this.isActive = false;
      // }
    },
    getContentList: function getContentList() {
      var _this9 = this;
      // 获取快捷回复信息
      (0, _workSpace.getCategory)().then(function (data) {
        _this9.replyOptions = data;
        console.log(_this9.replyOptions, "this.replyOptionsthis.replyOptions");
      });
    },
    searchReplyContent: function searchReplyContent() {
      var _this10 = this;
      // this.loadNode(node, resolve)
      if (this.replyContent == "") {
        this.getContentList();
      } else {
        var keywords = this.replyContent;
        (0, _workSpace.getFaqList)({
          keywords: keywords
        }).then(function (res) {
          res.map(function (item) {
            item.name = item.content;
            item.leaf = true;
          });
          _this10.replyOptions = res;
        });
      }
    },
    resetReplyContent: function resetReplyContent() {
      this.replyContent = "";
      this.getContentList();
    },
    setOnopenMessage: function setOnopenMessage(evt) {
      var _this11 = this;
      console.log("connection...");
      this.websocketState = true;
      // this.heartCheck.start();
      this.websocket.send("{\"toUserId\":\"" + this.$store.getters.scoketUserId + "\",\"contentText\":\"wait\",\"type\":\"list\"}");

      // 已接入消息列表
      setTimeout(function () {
        _this11.websocket.send("{\"toUserId\":\"" + _this11.$store.getters.scoketUserId + "\",\"contentText\":\"on\",\"type\":\"list\"}");
        _this11.websocket.send("{\"toUserId\":\"" + _this11.$store.getters.scoketUserId + "\",\"contentText\":\"end\",\"type\":\"list\"}");
      }, 1000);
    },
    setOnmessageMessage: function setOnmessageMessage(evt) {
      // this.heartCheck.start(evt.data);
      this.handleData(evt.data);
    },
    handleData: function handleData(evt) {
      var _this12 = this;
      var that = this;
      try {
        console.log(JSON.parse(evt), "initWebsocket");
        if (JSON.parse(evt).message === "当前客服已在线，即将回到首页！！！" && JSON.parse(evt).type === "error") {
          this.$message.error("JSON.parse(evt).message");
          this.$router.push({
            path: "/dashboard"
          });
        }
        if (JSON.parse(evt).waitUserList) {
          var unReadList = [];
          unReadList = JSON.parse(evt).waitUserList;
          unReadList.map(function (item) {
            // debugger
            if (!item.avatar) {
              item.avatar = _errImg.default;
            }
            if (item.createtime) {
              item.createtime = (0, _dayjs.default)(item.createtime).format("HH:mm");
              console.log(item.createtime, "iiii");
            }
          });
          this.unReadList = unReadList;
          this.turnLastMsg(this.unReadList);
        }
        // 推送消息
        if (JSON.parse(evt).type === "wait") {
          this.websocket.send("{\"toUserId\":\"" + this.$store.getters.scoketUserId + "\",\"contentText\":\"end\",\"type\":\"list\"}");
          var newText = JSON.parse(evt).contentText;
          newText.lastMsg = JSON.parse(JSON.parse(evt).contentText.lastMsg);
          var isExist = false;
          for (var i = 0; i < this.unReadList.length; i++) {
            if (this.unReadList[i].userid === JSON.parse(evt).contentText.userid) {
              // console.log(this.unReadList[i].userid, JSON.parse(evt).contentText.userid, 'JSON.parse(evt).contentText.userid');
              var lastmsg = JSON.parse(JSON.parse(evt).contentText.lastMsg);
              this.handleLeftMsg(lastmsg);
              this.unReadList[i].lastMsg = lastmsg;

              // console.log(
              //   this.unReadList[i].lastMsg,
              //   this.unReadList[i].lastMsg.msg.msgtype,
              //   "this.unReadList[i].lastMsg.msg.msgtype"
              // );

              isExist = true;
              break;
            }
          }
          if (!isExist) {
            this.unReadList.push(newText);
          }
        }

        // 已接入显示最后一条消息
        if (JSON.parse(evt).type === "on") {
          console.log(this.lastPer, 23556);
          // debugger
          var _newText = JSON.parse(evt).contentText;
          _newText.lastMsg = JSON.parse(JSON.parse(evt).contentText.lastMsg);
          for (var i = 0; i < this.alReadList.length; i++) {
            if (this.alReadList[i].userid === JSON.parse(evt).contentText.userid) {
              var lastMsg = JSON.parse(JSON.parse(evt).contentText.lastMsg);
              console.log(lastMsg, that, 'lastMsglastMsg');
              that.handleLeftMsg(lastMsg);
              this.alReadList[i].lastMsg = lastMsg;
              console.log(this.alReadList[i].lastMsg, _newText, 22222222, "this.alReadList[i].lastMsg.length");
              this.alReadList[i].createtime = (0, _dayjs.default)(_newText.createtime).format("HH:mm");
              if (this.alReadList[i].lastMsg.msg.length > 7) {
                this.alReadList[i].lastMsg.msg = this.alReadList[i].lastMsg.msg.substring(0, 7) + "......";
              }
              // 将新消息用户提到数组的最前面
              var sortAlReadList = [];
              sortAlReadList = JSON.parse(JSON.stringify(this.alReadList));
              sortAlReadList.splice(i, 1);
              console.log(22244);
              this.alReadList = [this.alReadList[i]].concat(_toConsumableArray(sortAlReadList));
              console.log(this.alReadList, 12345);
              break;
            }
          }
          console.log(this.alReadList, 112233);
          // 新推消息已读之后改变消息状态
          if (JSON.parse(evt).contentText.userid === this.lastPer) {
            that.insertWebsocket.send("{\"toUserId\":\"" + this.lastPer + "\",\"contentText\":\"" + JSON.parse(evt).contentText.msgId + "\",\"type\":\"read\"}");
          }
        }
        this.unReadList.map(function (item) {
          if (!item.avatar) {
            item.avatar = _errImg.default;
          }
          if (item.createtime && typeof item.createtime === "number") {
            item.createtime = (0, _dayjs.default)(item.createtime).format("HH:mm");
          }
          if (item.lastMsg.msg.length > 7) {
            item.lastMsg.msg = item.lastMsg.msg.substring(0, 7) + ".....";
          }
        });
        if (JSON.parse(evt).onUserList) {
          console.log(JSON.parse(evt).onUserList, "JSON.parse(evt).onUserList");
          var alReadList = [];
          alReadList = JSON.parse(evt).onUserList;
          // sessionStorage.setItem('onUserList', alReadList)
          this.alReadList = alReadList;
          this.alReadList.map(function (item, index) {
            // debugger
            console.log(item, _this12.lastPer, "ttttttttt");
            if (!item.avatar) {
              item.avatar = _errImg.default;
            }
            // debugger
            if (item.createtime && typeof item.createtime === "number") {
              item.createtime = (0, _dayjs.default)(item.createtime).format("HH:mm");
            }
            console.log(33444);
            if (item.userid === _this12.lastPer) {
              _this12.selectLi = _this12.lastPer;
            }
            console.log();
            _this12.turnLastMsg(_this12.alReadList);
          });
          // console.log(this.alReadList, 'wwwwww');
        }

        // 已完成
        if (JSON.parse(evt).endUserList) {
          console.log(JSON.parse(evt).endUserList, "JSON.parse(evt).endUserList");
          var endUserList = [];
          endUserList = JSON.parse(evt).endUserList;
          endUserList.map(function (item) {
            if (!item.avatar) {
              item.avatar = _errImg.default;
            }
            if (item.createtime && typeof item.createtime === "number") {
              item.createtime = (0, _dayjs.default)(item.createtime).format("MM-DD HH:mm");
            }
          });
          console.log(endUserList, "endUserList");
          this.endUserList = endUserList;
        }
      } catch (_unused) {}
    },
    handleLeftMsg: function handleLeftMsg(lastmsg) {
      // debugger
      console.log(lastmsg, 'lastmsglastmsglastmsg222');
      if (lastmsg) {
        console.log(lastmsg, 444555);
        if (lastmsg.msg.msgtype && lastmsg.msg.msgtype === "image") {
          lastmsg.msg = "[图片]";
        } else if (lastmsg.msgtype && lastmsg.msgtype === "unkown") {
          lastmsg.msg = "错误信息";
        } else if (lastmsg.msg.msgtype && lastmsg.msg.msgtype === "miniprogrampage") {
          lastmsg.msg = "卡片消息";
        } else if (lastmsg.msgtype && lastmsg.msgtype === "text") {
          console.log(lastmsg.msg.length, 444444444444455555555555555);
          if (lastmsg.msg.length > 7) {
            lastmsg.msg = lastmsg.msg.substring(0, 7) + ".....";
            console.log(lastmsg.msg, "lastmsg.msg1111111111");
          } else {
            lastmsg.msg = lastmsg.msg;
          }
        } else if (lastmsg.miniprogrampage) {
          lastmsg.msg = "卡片消息";
        }
      }
    },
    turnLastMsg: function turnLastMsg(arrList) {
      console.log(arrList, "arrListarrList");
      try {
        arrList.map(function (item) {
          // debugger
          item.lastMsg = JSON.parse(item.lastMsg);
          console.log(item.lastMsg, item.lastMsg.msgtype, "ooooooo");
          // return
          if (item.lastMsg.mediaid) {
            item.lastMsg.msg = "[图片]";
          }
          if (item.lastMsg.msg.msgtype && item.lastMsg.msg.msgtype === "image") {
            item.lastMsg.msg = "[图片]";
          } else if (item.lastMsg.msgtype && item.lastMsg.msgtype === "unkown") {
            item.lastMsg.msg = "错误信息";
          } else if (item.lastMsg.msg.msgtype && item.lastMsg.msg.msgtype === "miniprogrampage") {
            item.lastMsg.msg = "卡片消息";
          } else if (item.lastMsg.msgtype && item.lastMsg.msgtype === "text") {
            console.log(item.lastMsg, 444444444444455555555555555);
            if (item.lastMsg.msg.length > 7) {
              item.lastMsg.msg = item.lastMsg.msg.substring(0, 7) + ".....";
              console.log(item.lastMsg.msg, "item.lastMsg.msg1111111111");
            } else {
              item.lastMsg.msg = item.lastMsg.msg;
            }
          } else if (item.lastMsg.miniprogrampage) {
            item.lastMsg.msg = "卡片消息";
          }
        });
      } catch (_unused2) {}
    },
    setOncloseMessage: function setOncloseMessage(e) {
      console.log("连接关闭", e);
      this.websocketState = false;
      this.reconnect(this.env + this.$store.getters.scoketUserId + "/" + 0);
    },
    insertWebsocketClose: function insertWebsocketClose(e) {
      console.log("websocket 断开: " + e.code + " " + e.reason + " " + e.wasClean);
      this.reconnect1(this.env1 + this.$store.getters.scoketUserId + "/" + this.lastPer);
    },
    createWebSocket1: function createWebSocket1() {
      try {
        this.insertWebsocket = new WebSocket(this.env1 + this.$store.getters.scoketUserId + "/" + this.lastPer);
        this.$store.state.tagsView.insertWebsocket = this.insertWebsocket;
        this.initWebSocket1();
      } catch (e) {
        this.reconnect1(this.env1 + this.$store.getters.scoketUserId + "/" + this.lastPer);
      }
    },
    initWebSocket1: function initWebSocket1() {
      this.insertWebsocket.onclose = this.insertWebsocketClose;
      this.insertWebsocket.onmessage = this.insertWebsocketOnmessage;
      this.insertWebsocket.onerror = this.insertWebsocketError;
      this.insertWebsocket.onopen = this.insertWebsocketOpen;
      // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = this.onbeforeunload;
    },
    insertWebsocketError: function insertWebsocketError() {
      console.log("发生异常了");
      this.reconnect1(this.env1 + this.$store.getters.scoketUserId + "/" + this.lastPer);
    },
    insertWebsocketOpen: function insertWebsocketOpen() {
      if (this.heartCheck1) {
        // this.heartCheck1.start();
      }
    },
    insertWebsocketOnmessage: function insertWebsocketOnmessage(evt) {
      if (this.heartCheck1) {
        // this.heartCheck1.start();
      }
      var that = this;
      try {
        if (JSON.parse(evt.data).errcode && JSON.parse(evt.data).errcode == 600) {
          this.$message.error(JSON.parse(evt.data).errmsg);
          this.closeSocket = false;
          return;
        } else {
          that.handleData(evt.data);
          if (JSON.parse(evt.data).contentText) {
            that.$nextTick(function () {
              setTimeout(function () {
                var scrollDiv = document.getElementById("bigDiv");
                scrollDiv.scrollTop = scrollDiv.scrollHeight;
              }, 300);
            });
            console.log(222211);
            var obj = JSON.parse(evt.data).contentText;
            obj.content = JSON.parse(obj.content);
            // 在这里判断新推消息是发给哪一个客户，然后修改对应的消息状态
            console.log(JSON.parse(evt.data).contentText.userid, that.lastPer, 1000);
            if (JSON.parse(evt.data).contentText.userid !== that.lastPer) {
              console.log("11119");
              that.alReadList.map(function (item) {
                if (item.userid == JSON.parse(evt.data).contentText.userid) {
                  item.msgStatus = 0;
                }
              });
            }
            if (obj.content.msgtype === "image") {
              obj.content.msg = JSON.parse(obj.content.msg);
              if (obj.content.msg.image.mediaid) {
                var picurl = obj.content.msg.image.picurl;
                obj.content.msg = "<div  id=\"kehuPic\" ><img src=\"".concat(picurl, "\"  width=\"100%\" height=\"100%\" ></div>");
              }
            }
            console.log(obj, "oobbjj");
            if (obj.userid === that.lastPer) {
              that.chatItems.push(obj);
            }
          } else if (JSON.parse(evt.data).messageList) {
            that.chatItems = JSON.parse(evt.data).messageList;
          }
          // 已接入消息列表
          console.log(that.chatItems, "that.chatItemsthat.chatItems");
          that.handleChatItem(that.chatItems);
        }
      } catch (_unused3) {
        (function (err) {
          console.log(err, "错误信息");
        });
      }
    },
    leftPicClick: function leftPicClick(val) {
      console.log(val, "leftttttt");
      this.bigPic = val;
      this.srcList = [val];
      this.showBigPic = true;
    },
    insertComplete: function insertComplete(val, index) {
      var _this13 = this;
      this.selectLi = -1;
      // document.getElementById('#bigDiv').style
      this.completeSelectLi = index;
      this.chatItems = [];
      this.lastPer = val.userid;
      this.beginTalking = false;
      this.isTalking = false;
      this.closeSocket = false;
      // 控制样式
      this.isBegintalking = true;
      var that = this;
      this.completeUser = val;
      this.from2 = val.avatar;
      this.arrHostioryList = [];
      this.getCardMessage();
      (0, _workSpace.getCompleteMessage)({
        page: 1,
        limit: 30,
        userid: val.userid
      }).then(function (res) {
        _this13.arrHostioryList = res.list;
        that.completeTotalPage = res.totalPage;
        that.handleChatItem(res.list);
      });
    },
    handleChatItem: function handleChatItem(val) {
      // console.log(val, 'vvvvv')
      try {
        var that = this;
        val.map(function (item, index) {
          // console.log(item, item.content, index, "ittttt");
          if (!item.avatar) {
            item.avatar = _errImg.default;
          }
          if (item.content && item.content.msg === undefined && !item.content.err_msg) {
            // debugger
            // console.log(item.content, 8888);
            item.content = JSON.parse(item.content);
          }
          // 文本消息
          if (item.content.msg && item.content.msgtype === "text") {
            if (item.content.msg.indexOf("/") !== -1) {
              var str = item.content.msg.substring(item.content.msg.indexOf("/"), 3);
              str = str.substring(1, 3);
              if (that.list1.indexOf(str) !== -1) {
                var _index = that.list1.indexOf(str);
                item.content.msg = item.content.msg.replace("/" + str, "<img src= \"https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/".concat(_index, ".gif\">"));
              }
              if (item.content.msg.indexOf("</a>") !== -1) {
                // console.log(item.content.msg, 11111);

                var eles = bigDiv.getElementsByTagName("a");
                setTimeout(function () {
                  for (var i = 0; i < eles.length; i++) {
                    eles[i].setAttribute("target", "_blank");
                    eles[i].setAttribute("id", "eleId");
                  }
                }, 1000);
              }
            }
          }
          // 判断图片
          if (item.content.msg && item.content.msg.msgtype === "image") {
            var picurl = item.content.msg.picurl;
            item.content.msg = "<div style=\"\" @click=\"leftPicClick(".concat(picurl, ")\"><img src=\"").concat(picurl, "\"  width=\"100%\" height=\"100%\" ></div>");
          }
          // 判断小程序卡片
          if (item.content.msg && item.content.msg.msgtype === "miniprogrampage" && !item.content.msg.especialCard) {
            item.content.msg = "Dell\u5C0F\u4F01\u4E1A\u5546\u57CE:</br><div style=\"height:20px;\">".concat(item.content.msg.title, "</div></br><div style=\"width:200px;height:168px\"><img src='").concat(item.content.msg.thumb_url, "' width=\"100%\" height=\"100%\" class=\"cardClass\" id=\"cardClass\"></div><el-divider></el-divider></br><img src=\"").concat(_图标.default, "\" width=\"15px\" height=\"15px\" style=\"margin-top:5px\"/><span style=\"margin-left:5px\">Dell\u5C0F\u4F01\u4E1A\u5546\u57CE</span>");
          }
          // 判断错误信息
          // if (item.content.msgtype === "unknow") {
          //   if (JSON.parse(item.content.query).image) {
          //     item.content.msg = `<img src='${
          //       JSON.parse(item.content.query).image.picurl
          //     }' class="picClass">`;
          //   }
          // }
          // 判断用户发送卡片 title
          if (item.content.msg && item.content.msg.msgtype === "miniprogrampage" && item.content.msg.especialCard) {
            item.content.msg = "\u7528\u6237\u53D1\u9001\u4E86\uFF1A".concat(item.content.msg.title);
          }
          // 判断是否是客户发的图片
          if (item.content.mediaid) {
            if (JSON.parse(item.content.msg).image) {
              var _picurl = JSON.parse(item.content.msg).image.picurl;
              item.content.msg = "<div  id=\"kehuPic\" ><img src=\"".concat(_picurl, "\"  width=\"100%\" height=\"100%\" ></div>");
              if (document.getElementById("kehuPic")) {}
            }
          }
        });
        this.chatItems = val;
      } catch (err) {
        console.log(err, "err");
      }
    },
    insertMsg: function insertMsg(val, index) {
      var _this14 = this;
      this.childTabsValue = "one";
      this.goBackList();
      this.completeSelectLi = -1;
      this.isBegintalking = false;
      this.activeNames = "2";
      this.$refs.tabRight.style.setProperty("--height", "500px");
      this.isTalking = true;
      this.beginTalking = false;
      this.chatItems = [];
      this.selectLi = val.userid;
      console.log(this.selectLi == val.userid, 333);
      this.from2 = val.avatar;
      this.closeSocket = true;
      this.rightCard = true;
      this.lastPer = val.userid;
      console.log(this.lastPer, 122222222222);
      setTimeout(function () {
        _this14.websocket.send("{\"toUserId\":\"" + _this14.$store.getters.scoketUserId + "\",\"contentText\":\"on\",\"type\":\"list\"}");
      }, 1500);
      this.createWebSocket1();
      this.$nextTick(function () {
        setTimeout(function () {
          var scrollDiv = document.getElementById("bigDiv");
          scrollDiv.scrollTop = scrollDiv.scrollHeight;
        }, 800);
      });
      this.getCardMessage();
    },
    // 获取卡片信息
    getCardMessage: function getCardMessage() {
      var _this15 = this;
      // 用户基本资料
      (0, _workSpace.getCustomer)(this.lastPer).then(function (res) {
        _this15.baseInfoForm = res;
        if (!_this15.baseInfoForm.avatar) {
          _this15.baseInfoForm.avatar = _errImg.default;
        }
        _this15.orderUid = res.uid;
        _this15.wuliuForm.uid = res.uid;
        _this15.getOrderMessage();
        _this15.getRemark();
      });
    },
    // 获取备注信息
    getRemark: function getRemark() {
      var _this16 = this;
      // 获取备注信息
      var params = {
        adminId: this.$store.getters.scoketUserId,
        uid: this.baseInfoForm.uid
      };
      (0, _workSpace.findInfo)(params).then(function (res) {
        if (res) {
          _this16.baseInfoForm.remark = res.markname;
          _this16.baseInfoForm.remarkId = res.id;
        }
      });
    },
    // 获取订单信息
    getOrderMessage: function getOrderMessage() {
      var _this17 = this;
      var data = {
        status: "all",
        uid: this.orderUid,
        orderId: this.orderInfo
      };
      (0, _workSpace.getOrderMessage)(data).then(function (res) {
        _this17.orderForm = res.list.list;
      });
    },
    uploadSuccess: function uploadSuccess(val, file) {
      var imgTag = document.createElement("img");
      imgTag.className = "picClass";
      imgTag.src = URL.createObjectURL(file.raw);
      document.getElementById("inputId").appendChild(imgTag);
      this.handleUploadForm(file.raw);
    },
    handleUploadForm: function handleUploadForm(param) {
      var _this18 = this;
      var formData = new FormData();
      formData.append("media", param.file);
      var loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      (0, _streamManage.wechatUploadApi)(formData, {
        type: "image"
      }).then(function (res) {
        var imgTag = document.createElement("img");
        imgTag.src = res.url;
        imgTag.className = "picClass";
        document.getElementById("inputId").appendChild(imgTag);
        _this18.mediaId = res.mediaId;
        _this18.inputUrl = res.url;
        loading.close();
        _this18.$message.success("上传成功");
        _this18.isActive = true;
      }).catch(function () {
        loading.close();
      });
    },
    keyup_submit: function keyup_submit(e) {
      var _this19 = this;
      // console.log()
      if (!this.$refs.inputContent.innerHTML) return;
      // this.inputContentClick()
      var that = this;
      var msg;
      if (this.$refs.inputContent.innerHTML.indexOf('class="picClass"') !== -1) {
        msg = "{\n          \"toUserId\": \"".concat(this.lastPer, "\",\n          \"contentText\": {\n            \"media_id\": \"").concat(this.mediaId, "\",\n            \"picurl\": \"").concat(this.inputUrl, "\"\n          },\n          \"title\": \"openai\u5BF9\u8BDD\u63D2\u4EF6\",\n          \"type\": \"join\",\n          \"msgType\": \"image\",\n          \"kefuname\": \"").concat(this.kefuName, "\",\n          \"kefuavatar\": \"").concat(this.from1, "\"\n          }");
        that.chatItems.push({
          from: 3,
          content: {
            msg: this.$refs.inputContent.innerHTML
          }
        });
      } else if (this.$refs.inputContent.innerHTML.indexOf('class="emoClass"') !== -1) {
        var allArr = [];
        var str = this.$refs.inputContent.innerHTML.split('class="emoClass"');
        var re = /src=\"([^\"]*?)\"/i;
        str.map(function (item) {
          var arr = item.match(re);
          if (arr && arr.length !== 1) {
            allArr.push(arr[1]);
          }
        });
        var emoArr = [];
        allArr.map(function (item) {
          emoArr.push(Number(item.split(".gif")[0].split("emotion/")[1]));
        });
        var lastArr = [];
        emoArr.map(function (item) {
          lastArr.push("/" + _this19.list1[item]);
        });
        var strMsg = lastArr.join(" ") + this.$refs.inputContent.innerText;
        msg = "{\n          \"toUserId\": \"".concat(this.lastPer, "\",\n          \"contentText\": {\n            \"msg\": \"").concat(strMsg, "\"\n            },\n          \"title\": \"openai\u5BF9\u8BDD\u63D2\u4EF6\",\n          \"type\": \"join\",\n          \"msgType\": \"text\",\n          \"kefuname\": \"").concat(this.kefuName, "\",\n          \"kefuavatar\": \"").concat(this.from1, "\"\n          }");
        this.chatItems.push({
          from: 3,
          content: {
            msg: this.$refs.inputContent.innerHTML
          }
        });
      } else {
        msg = "{\n          \"toUserId\": \"".concat(this.lastPer, "\",\n          \"contentText\": {\n            \"msg\": \"").concat(this.$refs.inputContent.innerText, "\"\n            },\n          \"title\": \"openai\u5BF9\u8BDD\u63D2\u4EF6\",\n          \"type\": \"join\",\n          \"msgType\": \"text\",\n          \"kefuname\": \"").concat(this.kefuName, "\",\n          \"kefuavatar\": \"").concat(this.from1, "\"\n          }");
        that.chatItems.push({
          from: 3,
          content: {
            msg: this.$refs.inputContent.innerText
          }
        });
      }
      if (!this.websocketState) {
        this.$message.error("连接异常");
        return;
      }
      var infoType = that.$refs.inputContent.innerHTML;
      this.alReadList.map(function (item, index) {
        if (item.userid === _this19.lastPer) {
          if (infoType.indexOf('emoClass') !== -1 || infoType.indexOf('picClass') !== -1) {
            // msg: that.$refs.inputContent.innerHTML
            _this19.alReadList[index].lastMsg = {
              msg: '[图片]'
            };
          } else {
            if (that.$refs.inputContent.innerText.length > 7) {
              _this19.alReadList[index].lastMsg = {
                msg: infoType.substring(0, 7) + '.....'
              };
            } else {
              _this19.alReadList[index].lastMsg = {
                msg: that.$refs.inputContent.innerText
              };
            }
          }
        }
        // this.alReadList[i].userid === JSON.parse(evt).contentText.userid
      });
      console.log(this.$refs.inputContent.innerHTML, 66666);
      this.insertWebsocket.send(msg);
      this.$refs.inputContent.innerText = "";
      this.$refs.inputContent.innerHTML = "";
      this.$nextTick(function () {
        setTimeout(function () {
          var scrollDiv = document.getElementById("bigDiv");
          scrollDiv.scrollTop = scrollDiv.scrollHeight;
        }, 1000);
      });
    },
    clickHandler: function clickHandler(i) {
      var emotion = "#".concat(i, ";");
      this.$emit("emotion", emotion);
      var list = this.list1;
      var index = list.indexOf(i);
      var imgTag = document.createElement("img");
      imgTag.src = "https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/".concat(index, ".gif");
      imgTag.className = "emoClass";
      document.getElementById("inputId").appendChild(imgTag);
      this.showEmotion = !this.showEmotion;
      this.isActive = true;
    },
    clickEmotion: function clickEmotion() {
      this.showEmotion = !this.showEmotion;
    },
    // 满意度评价
    manyiClick: function manyiClick() {
      var _this20 = this;
      // 获取满意度评价
      (0, _systemConfig.configGetUniq)({
        key: "workspace_review_key"
      }).then(function (res) {
        try {
          _this20.satisTips = res;
        } catch (_unused4) {}
      });
      this.manyiDialog = true;
    },
    // 商品详情卡片
    goodsDetailClick: function goodsDetailClick() {
      this.goodsDetailDialog = true;
      this.goodsLoading = true;
      this.getList();
    },
    getList: function getList() {
      var _this21 = this;
      (0, _store.productLstApi)(this.goodsDetailForm).then(function (res) {
        _this21.goodsDetailTable = res.list;
        _this21.goodsPageTotal = res.total;
        _this21.goodsLoading = false;
      }).catch(function (res) {
        _this21.$message.error(res.message);
      });
    },
    goodsPageChange: function goodsPageChange(page) {
      this.goodsDetailForm.page = page;
      this.getList();
    },
    goodsSizeChange: function goodsSizeChange(val) {
      this.goodsDetailForm.limit = val;
      this.getList();
    },
    // 发送满意度评价
    sendSatis: function sendSatis() {
      this.manyiDialog = false;
      var that = this;
      if (!this.satisTips) {
        this.$message.error("请先维护满意度评价");
        return;
      }
      this.insertWebsocket.send("{\"msgtype\": \"text\",\"toUserId\": \"".concat(this.lastPer, "\", \"contentText\": { \"msg\": \"").concat(this.satisTips, "\"}, \"type\": \"assessment\",\"kefuname\": \"").concat(this.kefuName, "\",\"kefuavatar\": \"").concat(this.from1, "\"\n}"));
      this.chatItems.push({
        from: 3,
        content: {
          msg: that.satisTips
        }
      });
      this.$nextTick(function () {
        var scrollDiv = document.getElementById("bigDiv");
        scrollDiv.scrollTop = scrollDiv.scrollHeight;
      });
    },
    searchGoods: function searchGoods() {
      this.goodsDetailForm.page = 1;
      this.getList();
    },
    // 物流卡片
    wuliuClick: function wuliuClick() {
      this.wuliuLoading = true;
      this.wuliuDialog = true;
      this.onOrderLog();
    },
    onOrderLog: function onOrderLog() {
      var _this22 = this;
      (0, _workSpace.getLogisticsList)(this.wuliuForm).then(function (res) {
        _this22.wuliuTableData = res.list || [];
        _this22.wuliuPageTotal = res.total;
        _this22.wuliuLoading = false;
      }).catch(function () {});
    },
    searchWuliu: function searchWuliu() {
      this.wuliuForm.page = 1;
      this.onOrderLog();
    },
    // 物流分页
    wuliuPageChange: function wuliuPageChange(page) {
      this.wuliuForm.page = page;
      this.onOrderLog();
    },
    wuliuSizeChange: function wuliuSizeChange(val) {
      this.wuliuForm.limit = val;
      this.wuliuForm.page = 1;
      this.getVedioList();
    },
    // 直播分页
    streamPageChange: function streamPageChange(page) {
      this.streamForm.start = page;
      this.getVedioList();
    },
    streamSizeChange: function streamSizeChange(val) {
      this.streamForm.start = 1;
      this.streamForm.limit = val;
      this.getVedioList();
    },
    getVedioList: function getVedioList() {
      var _this23 = this;
      var that = this;
      (0, _streamManage.getStreamList)(this.streamForm).then(function (res) {
        var streamTableData = res.room_info;
        that.streamTableData = [];
        _this23.streamPageTotal = res.total * _this23.PageSize2;
        streamTableData.map(function (item) {
          console.log(item, "uiiii");
          item.start_time = (0, _dayjs.default)(item.start_time * 1000).format("YYYY-MM-DD HH:mm");
          item.end_time = (0, _dayjs.default)(item.end_time * 1000).format("YYYY-MM-DD HH:mm");
          item.live_status = _this23.liveStatusOptions.filter(function (val) {
            return val.value == item.live_status;
          })[0].label;
          if (item.live_status == "未开始" || item.live_status == "直播中") {
            that.streamTableData.push(item);
          }
        });
        _this23.streamLoading = false;
      });
    },
    streamClick: function streamClick() {
      this.getVedioList();
      this.streamDialog = true;
      this.streamLoading = true;
    },
    getArticleList: function getArticleList() {
      var _this24 = this;
      console.log(this.articleForm, "this.articleFormthis.articleForm");
      (0, _workSpace.ListArticle)(this.articleForm).then(function (data) {
        _this24.articleTableData = data.list;
        _this24.articlePageTotal = data.total;
        _this24.articleLoading = false;
      });
    },
    articleClick: function articleClick() {
      this.getArticleList();
      this.articleDialog = true;
      this.articleLoading = true;
    },
    searchArticle: function searchArticle() {
      this.getArticleList();
    },
    searchCoupon: function searchCoupon() {
      this.counponForm.nowDate = (0, _dayjs.default)(new Date()).format("YYYY-MM-DD HH:mm:ss");
      this.getCouponList();
    },
    getCouponList: function getCouponList() {
      var _this25 = this;
      (0, _marketing.marketingListApi)(this.counponForm).then(function (res) {
        _this25.couponTableData = res.list;
        _this25.couponPageTotal = res.total;
      }).catch(function (res) {});
    },
    couponClick: function couponClick() {
      this.counponForm.name = "";
      this.counponForm.nowDate = (0, _dayjs.default)(new Date()).format("YYYY-MM-DD HH:mm:ss");
      this.getCouponList();
      this.couponDialog = true;
    },
    // 优惠券分页
    couponSizeChange: function couponSizeChange(val) {
      this.counponForm.limit = val;
      this.counponForm.page = 1;
      this.counponForm.nowDate = (0, _dayjs.default)(new Date()).format("YYYY-MM-DD HH:mm:ss");
      this.getCouponList();
    },
    couponPageChange: function couponPageChange(val) {
      this.counponForm.page = val;
      this.getCouponList();
    },
    // 种草分页
    articleSizeChange: function articleSizeChange(val) {
      this.articleForm.limit = val;
      this.articleForm.page = 1;
      this.getArticleList();
    },
    articlePageChange: function articlePageChange(val) {
      this.articleForm.page = val;
      this.getArticleList();
    },
    trunKefu: function trunKefu() {
      var _this26 = this;
      (0, _workSpace.getCustomerList)().then(function (res) {
        _this26.kefuList = res;
        if (_this26.kefuList.length !== 0) {
          for (var i = 0; i < _this26.kefuList.length; i++) {
            if (_this26.kefuList[i].id == _this26.$store.getters.scoketUserId) {
              _this26.kefuList.splice(i, 1);
            }
          }
        }
        if (_this26.kefuList.length == 0) {
          _this26.$message.error("没有可转接的客服");
          return;
        } else {
          _this26.kefuDialogVisiable = true;
        }
      });
    },
    chooseKefuChange: function chooseKefuChange(val) {
      var _this27 = this;
      this.kefuList.map(function (item) {
        if (item.id == val) {
          _this27.kefuObject = item;
        }
      });
    },
    sureTurnKufu: function sureTurnKufu() {
      var _this28 = this;
      var msg = "{\n        \"toUserId\": \"".concat(this.kefuObject.id, "\",\n        \"contentText\": {\n        \"msg\": \"").concat(this.$store.getters.scoketUserId, "\"\n        },\n        \"type\": \"transfer\",\n        \"msgType\": \"text\",\n        \"kefuname\": \"").concat(this.kefuName, "\",\n        \"kefuavatar\": \"kefuavatar\"\n      }");
      this.insertWebsocket.send(msg);
      this.$message.success("转接成功");
      this.kefuDialogVisiable = false;
      this.closeSocket = false;
      this.isTalking = false;
      this.beginTalking = true;
      setTimeout(function () {
        _this28.websocket.send("{\"toUserId\":\"" + _this28.$store.getters.scoketUserId + "\",\"contentText\":\"on\",\"type\":\"list\"}");
        _this28.websocket.send("{\"toUserId\":\"".concat(_this28.kefuObject.id, "\",\"contentText\":\"on\",\"type\":\"list\"}"));
      }, 1000);
    }
  },
  watch: {
    unReadList: {
      handler: function handler(val) {
        this.waitLabel = "未接入" + "(" + val.length + "人)";
      }
    },
    alReadList: {
      handler: function handler(val) {
        this.accessLabel = "已接入" + "(" + val.length + "人)";
      }
    },
    endUserList: {
      handler: function handler(val) {
        this.completeLabel = "已完成" + "(" + val.length + "人)";
      }
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "source-drawer" },
    [
      _c(
        "el-drawer",
        {
          ref: "drawer",
          attrs: {
            visible: _vm.visible,
            "with-header": false,
            direction: "rtl",
            size: "40%",
            "custom-class": "demo-drawer",
          },
          on: { "update:visible": _vm.cancel },
        },
        [
          _c(
            "div",
            { staticClass: "demo-drawer__content" },
            [
              _c("node-form", { ref: "NodeForm" }),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("message-flow-form", { ref: "messageFlowForm" }),
              _vm._v(" "),
              _c("div", { staticClass: "demo-drawer__footer" }, [
                _c(
                  "div",
                  { staticClass: "demo-drawer__footer" },
                  [
                    _c(
                      "el-row",
                      { attrs: { type: "flex", justify: "center" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c("el-button", { on: { click: _vm.cancel } }, [
                              _vm._v("取 消"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.save },
                              },
                              [_vm._v("确 定")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
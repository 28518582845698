var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.orderDatalist.invoice
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "发票信息",
                visible: _vm.logVisible,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.logVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "description",
                },
                [
                  _c("div", { staticClass: "fuTitle" }, [
                    _vm._v(
                      "发票类型:" +
                        _vm._s(
                          _vm.orderDatalist.invoice.type == 1
                            ? "个人发票"
                            : _vm.orderDatalist.invoice.type == 2
                            ? "个人发票(单位)"
                            : "企业增值税专用发票"
                        )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "description",
                },
                [
                  _c("div", { staticClass: "fuTitle" }, [
                    _vm._v("支付方式:" + _vm._s(_vm.orderDatalist.payTypeStr)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "description",
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("价格详情")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "acea-row" }, [
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        "实际支付运费：" + _vm._s(_vm.orderDatalist.payPostage)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v(
                        "实际优惠金额：" + _vm._s(_vm.orderDatalist.couponPrice)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description-term" }, [
                      _vm._v("总计：" + _vm._s(_vm.orderDatalist.payPrice)),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _vm.orderDatalist.invoice.type == 3
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "description",
                    },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("发票详情")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "acea-row" }, [
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "发票抬头:" +
                              _vm._s(_vm.orderDatalist.invoice.corporationName)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "单位税号:" +
                              _vm._s(_vm.orderDatalist.invoice.corporationTax)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "注册地址:" +
                              _vm._s(_vm.orderDatalist.invoice.address)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "注册电话:" +
                              _vm._s(_vm.orderDatalist.invoice.phoneNumber)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "开户银行:" +
                              _vm._s(_vm.orderDatalist.invoice.bankName)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "银行账户:" +
                              _vm._s(_vm.orderDatalist.invoice.bankAccount)
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.orderDatalist.invoice.type == 2
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "description",
                    },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("发票详情")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "acea-row" }, [
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "单位名称:" +
                              _vm._s(_vm.orderDatalist.invoice.corporationName)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "单位地址:" +
                              _vm._s(_vm.orderDatalist.invoice.address)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "税号:" +
                              _vm._s(_vm.orderDatalist.invoice.corporationTax)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "电话号码:" +
                              _vm._s(_vm.orderDatalist.invoice.phoneNumber)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "开户银行:" +
                              _vm._s(_vm.orderDatalist.invoice.bankName)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "银行账户:" +
                              _vm._s(_vm.orderDatalist.invoice.bankAccount)
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "el-dialog",
            {
              attrs: {
                title: "发票信息",
                visible: _vm.logVisible,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.logVisible = $event
                },
              },
            },
            [_c("div", { staticClass: "title" }, [_vm._v("暂无发票信息")])]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
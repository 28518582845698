var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header",
          },
          [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c(
                  "el-form",
                  { attrs: { inline: "", size: "small" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "直播分类：" } },
                      [
                        _c("el-cascader", {
                          ref: "cascader",
                          staticClass: "selWidth",
                          attrs: {
                            options: _vm.merCateList,
                            clearable: "",
                            props: _vm.props2,
                            "node-key": "id",
                          },
                          on: { change: _vm.handerSearch },
                          model: {
                            value: _vm.listPram.liveCategoryId,
                            callback: function ($$v) {
                              _vm.$set(_vm.listPram, "liveCategoryId", $$v)
                            },
                            expression: "listPram.liveCategoryId",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "关键词：" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "请输入关键词",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.listPram.keyword,
                              callback: function ($$v) {
                                _vm.$set(_vm.listPram, "keyword", $$v)
                              },
                              expression: "listPram.keyword",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: { click: _vm.handerSearch },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "router-link",
              { attrs: { to: { path: "/living/addLive" } } },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "mr10",
                    attrs: { size: "small", type: "primary" },
                  },
                  [_vm._v("添加直播")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                staticClass: "table",
                attrs: {
                  data: _vm.listData.list,
                  size: "mini",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "id", label: "ID", "min-width": "50" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "liveTitle",
                    label: "标题",
                    "min-width": "180",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "分类", "min-width": "180" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            _vm._l(
                              scope.row.liveCategoryName,
                              function (item, index) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: index,
                                    staticStyle: { "margin-right": "5px" },
                                    attrs: { type: "success", size: "mini" },
                                  },
                                  [_vm._v(_vm._s(item))]
                                )
                              }
                            ),
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "updateTime", label: "更新时间" },
                }),
                _vm._v(" "),
                _c("el-table-column", { attrs: { label: "是否结束" } }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    "min-width": "100",
                    fixed: "right",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { path: "/living/addLive/" + scope.row.id },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "mr10",
                                  attrs: { size: "small", type: "text" },
                                },
                                [_vm._v("编辑")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !scope.row.isSpecial
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlerDelete(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.isSyncCl == 0 &&
                          scope.row.isEnd == 1 &&
                          scope.row.endTime != null &&
                          scope.row.videoType == 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.synchronizationData(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("同步数据")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.isSyncCl == 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    loading: "",
                                    type: "text",
                                    disabled: "",
                                    size: "small",
                                  },
                                },
                                [_vm._v("同步中")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.isSyncCl == 9
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    size: "small",
                                    disabled: "",
                                  },
                                },
                                [_vm._v("同步完成")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.listPram.page,
                "page-sizes": _vm.constants.page.limit,
                layout: _vm.constants.page.layout,
                total: _vm.listData.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
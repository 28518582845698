var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { rules: _vm.rules, model: _vm.ruleForm, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "用户编号：" } },
        [
          _c("el-input", {
            staticClass: "selWidth",
            attrs: { disabled: "" },
            model: {
              value: _vm.ruleForm.id,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "id", $$v)
              },
              expression: "ruleForm.id",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "微信昵称：" } },
        [
          _c("el-input", {
            staticClass: "selWidth",
            model: {
              value: _vm.ruleForm.nickname,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "nickname", $$v)
              },
              expression: "ruleForm.nickname",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "手机号码：", prop: "phone" } },
        [
          _c("el-input", {
            staticClass: "selWidth",
            attrs: { disabled: true },
            model: {
              value: _vm.ruleForm.phone,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "phone", _vm._n($$v))
              },
              expression: "ruleForm.phone",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "生日：" } },
        [
          _c("el-date-picker", {
            staticClass: "selWidth",
            attrs: {
              type: "date",
              placeholder: "选择日期",
              format: "yyyy 年 MM 月 dd 日",
              "value-format": "yyyy-MM-dd",
            },
            model: {
              value: _vm.ruleForm.birthday,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "birthday", $$v)
              },
              expression: "ruleForm.birthday",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "地区：" } },
        [
          _c("el-input", {
            staticClass: "selWidth",
            attrs: { disabled: "" },
            model: {
              value: _vm.ruleForm.area,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "area", $$v)
              },
              expression: "ruleForm.area",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "用户备注：" } },
        [
          _c("el-input", {
            staticClass: "selWidth",
            attrs: { type: "textarea" },
            model: {
              value: _vm.ruleForm.mark,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "mark", $$v)
              },
              expression: "ruleForm.mark",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "用户等级：" } },
        [
          _c(
            "el-select",
            {
              staticClass: "selWidth",
              attrs: { placeholder: "请选择", clearable: "", filterable: "" },
              model: {
                value: _vm.ruleForm.level,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "level", $$v)
                },
                expression: "ruleForm.level",
              },
            },
            _vm._l(_vm.levelList, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { value: item.id, label: item.name },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "用户分组：" } },
        [
          _c(
            "el-select",
            {
              staticClass: "selWidth",
              attrs: { placeholder: "请选择", clearable: "", filterable: "" },
              model: {
                value: _vm.ruleForm.groupId,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "groupId", $$v)
                },
                expression: "ruleForm.groupId",
              },
            },
            _vm._l(_vm.groupList, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { value: item.id, label: item.groupName },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "用户标签：" } },
        [
          _c(
            "el-select",
            {
              staticClass: "selWidth",
              attrs: {
                placeholder: "请选择",
                clearable: "",
                filterable: "",
                multiple: "",
              },
              model: {
                value: _vm.labelData,
                callback: function ($$v) {
                  _vm.labelData = $$v
                },
                expression: "labelData",
              },
            },
            _vm._l(_vm.labelLists, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { value: item.id, label: item.name },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "是否为销售：" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.ruleForm.isSales,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "isSales", $$v)
              },
              expression: "ruleForm.isSales",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.ruleForm.isSales
        ? _c(
            "el-form-item",
            { attrs: { label: "销售负责人" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.ruleForm.isSalesLeader,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "isSalesLeader", $$v)
                  },
                  expression: "ruleForm.isSalesLeader",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ruleForm.isSalesLeader && _vm.ruleForm.isSales
        ? _c(
            "el-form-item",
            { attrs: { label: "负责人类型" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.couponType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "couponType", $$v)
                    },
                    expression: "ruleForm.couponType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("DA Coupon"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("其他优惠券"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ruleForm.isSales
        ? _c(
            "el-form-item",
            { attrs: { label: "销售姓名：" } },
            [
              _c("el-input", {
                staticClass: "selWidth",
                model: {
                  value: _vm.ruleForm.realName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "realName", $$v)
                  },
                  expression: "ruleForm.realName",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ruleForm.isSales
        ? _c(
            "el-form-item",
            { attrs: { label: "cep电话", prop: "cepPhone" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.cepPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "cepPhone", $$v)
                  },
                  expression: "ruleForm.cepPhone",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ruleForm.isSales
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: "上传二维码：",
                prop: "saleQrCode",
                "label-width": "108px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "upLoadPicBox",
                  on: {
                    click: function ($event) {
                      return _vm.modalPicTap("1")
                    },
                  },
                },
                [
                  _vm.ruleForm.saleQrCode
                    ? _c("div", { staticClass: "pictrue" }, [
                        _c("img", { attrs: { src: _vm.ruleForm.saleQrCode } }),
                      ])
                    : _c("div", { staticClass: "upLoad" }, [
                        _c("i", {
                          staticClass: "el-icon-camera cameraIconfont",
                        }),
                      ]),
                  _vm._v(" "),
                  _c("span", { staticStyle: { color: "#999" } }, [
                    _vm._v("(推荐尺寸：400 X 400像素,1：1)"),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ruleForm.isSales
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: "销售头像：",
                prop: "salesAvatar",
                "label-width": "108px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "upLoadPicBox",
                  on: {
                    click: function ($event) {
                      return _vm.modalPicTap("2")
                    },
                  },
                },
                [
                  _vm.ruleForm.salesAvatar
                    ? _c("div", { staticClass: "pictrue" }, [
                        _c("img", { attrs: { src: _vm.ruleForm.salesAvatar } }),
                      ])
                    : _c("div", { staticClass: "upLoad" }, [
                        _c("i", {
                          staticClass: "el-icon-camera cameraIconfont",
                        }),
                      ]),
                  _vm._v(" "),
                  _c("span", { staticStyle: { color: "#999" } }, [
                    _vm._v("(推荐尺寸：400 X 400像素,1：1)"),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ruleForm.isSales
        ? _c(
            "el-form-item",
            { attrs: { label: "销售职位：", prop: "salesPosition" } },
            [
              _c("el-input", {
                staticClass: "selWidth",
                model: {
                  value: _vm.ruleForm.salesPosition,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "salesPosition", $$v)
                  },
                  expression: "ruleForm.salesPosition",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ruleForm.isSales
        ? _c(
            "el-form-item",
            { attrs: { label: "销售电话：", prop: "salesPhone" } },
            [
              _c("el-input", {
                staticClass: "selWidth",
                model: {
                  value: _vm.ruleForm.salesPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "salesPhone", $$v)
                  },
                  expression: "ruleForm.salesPhone",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ruleForm.isSales
        ? _c(
            "el-form-item",
            { attrs: { label: "销售邮箱：", prop: "salesEmail" } },
            [
              _c("el-input", {
                staticClass: "selWidth",
                model: {
                  value: _vm.ruleForm.salesEmail,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "salesEmail", $$v)
                  },
                  expression: "ruleForm.salesEmail",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ruleForm.isSales
        ? _c(
            "el-form-item",
            { attrs: { label: "销售微信：", prop: "salesWechat" } },
            [
              _c("el-input", {
                staticClass: "selWidth",
                model: {
                  value: _vm.ruleForm.salesWechat,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "salesWechat", $$v)
                  },
                  expression: "ruleForm.salesWechat",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("提交")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resetForm("ruleForm")
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发送货",
            visible: _vm.dialogVisible,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.orderDatalist
            ? _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.goodsForm.productList },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "商品信息", width: "350" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (props) {
                                return [
                                  _c("div", { staticClass: "goodsinfo" }, [
                                    _c(
                                      "div",
                                      { staticClass: "goods_image" },
                                      [
                                        _c("el-image", {
                                          attrs: {
                                            src: props.row.image,
                                            "preview-src-list": [
                                              props.row.image,
                                            ],
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "goods_name" },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: props.row.storeName,
                                              placement: "top-start",
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("strFilter")(
                                                    props.row.storeName
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              props.row.suk
                                                ? props.row.suk
                                                : "-"
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(
                                          props.row.presents,
                                          function (item1) {
                                            return _c(
                                              "span",
                                              { key: item1.id },
                                              [
                                                _vm._v(
                                                  _vm._s(item1.name) +
                                                    " |\n                "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(props.row.price) +
                                              " x " +
                                              _vm._s(props.row.cartNum)
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2252865386
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "GII编码", width: "200" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请输入GII编号",
                                    },
                                    model: {
                                      value: scope.row.giiCode,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "giiCode", $$v)
                                      },
                                      expression: "scope.row.giiCode",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2032749917
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "快递公司", width: "200" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        filterable: "",
                                        disabled: scope.row.disabled,
                                        size: "mini",
                                      },
                                      model: {
                                        value: scope.row.deliveryCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "deliveryCode",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.deliveryCode",
                                      },
                                    },
                                    _vm._l(_vm.express, function (item, i) {
                                      return _c("el-option", {
                                        key: i,
                                        attrs: {
                                          value: item.code,
                                          label: item.name,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4200001610
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "快递编号", width: "200" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      size: "mini",
                                      disabled: scope.row.disabled,
                                      placeholder: "请输入快递编号",
                                    },
                                    model: {
                                      value: scope.row.deliveryId,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "deliveryId", $$v)
                                      },
                                      expression: "scope.row.deliveryId",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1767239758
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitSendGoods()
                    },
                  },
                },
                [_vm._v("提交")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _settingMer = _interopRequireDefault(require("@/utils/settingMer"));
var _wechat = require("@/libs/wechat");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var service = _axios.default.create({
  baseURL: 'http://10.158.104.86:1200',
  timeout: 50000 // 过期时间
});
// baseURL: 'http://172.17.2.79:1200',

// request interceptor
service.interceptors.request.use(function (config) {
  // 发送请求之前做的
  // const token = !store.getters.token?sessionStorage.getItem('token'):store.getters.token;
  // if (token) {
  //   config.headers['Authori-zation'] = token
  // }
  if (/get/i.test(config.method)) {
    config.params = config.params || {};
    config.params.temp = Date.parse(new Date()) / 1000;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  // if the custom code is not 20000, it is judged as an error.
  if (res.code === 401) {
    // to re-login
    _elementUi.MessageBox.confirm('您已经登出，您可以取消停留在这个页面，或重新登录', '退出', {
      confirmButtonText: '或重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      _store.default.dispatch('user/resetToken').then(function () {
        location.reload();
      });
    });
  }
  if (res.code !== 200 && res.code !== 401) {
    if ((0, _wechat.isPhone)()) {
      //移动端
      return Promise.reject(res || 'Error');
    }
    (0, _elementUi.Message)({
      message: res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject();
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    // return Promise.reject(new Error(res.message || 'Error'))
  } else {
    return res.data;
  }
}, function (error) {
  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = exports.default = service;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "CouponUser",
  filters: {
    failFilter: function failFilter(status) {
      var statusMap = {
        receive: "自己领取",
        send: "后台发送",
        give: "满赠",
        new: "新人",
        buy: "买赠送",
        register: "新人注册",
        authorityCorporate: "企业注册"
      };
      return statusMap[status];
    },
    statusFilter: function statusFilter(status) {
      var statusMap = {
        0: "未核销",
        1: "已核销",
        2: "已过期"
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      Loading: false,
      roterPre: _settings.roterPre,
      imgList: [],
      tableFromIssue: {
        page: 1,
        limit: 20,
        uid: "",
        name: "",
        status: "",
        couponCode: "",
        storeGroupId: "",
        salesName: ""
      },
      issueData: {
        data: [],
        total: 0
      },
      loading: false,
      options: [],
      groupList: []
    };
  },
  mounted: function mounted() {
    this.getGroup();
    this.getIssueList();
  },
  methods: {
    collect: function collect(row) {
      var _this = this;
      var that = this;
      this.$confirm("是否确认恢复?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        that.recoveryCoupon(row);
      }).catch(function () {
        _this.$message({
          type: "info",
          message: "已取消恢复"
        });
      });
    },
    recoveryCoupon: function recoveryCoupon(row) {
      var _this2 = this;
      (0, _marketing.couponRecoveryApi)({
        couponId: row.couponId,
        id: row.id,
        uid: row.uid
      }).then(function (res) {
        _this2.$message.success("恢复成功");
        _this2.seachList();
      }).catch(function (res) {
        _this2.$message.error(res.message || "恢复失败");
      });
    },
    remoteMethod: function remoteMethod(query) {
      var _this3 = this;
      if (query !== "") {
        this.loading = true;
        setTimeout(function () {
          _this3.loading = false;
          (0, _user.userListApi)({
            keywords: query,
            page: 1,
            limit: 10
          }).then(function (res) {
            _this3.options = res.list;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    seachList: function seachList() {
      this.tableFromIssue.page = 1;
      this.getIssueList();
    },
    // 列表
    getIssueList: function getIssueList() {
      var _this4 = this;
      this.Loading = true;
      (0, _marketing.couponUserListApi)(this.tableFromIssue).then(function (res) {
        _this4.issueData.data = res.list;
        _this4.issueData.total = res.total;
        // this.issueData.data.map((item) => {
        //   this.imgList.push(item.user.avatar)
        // })
        _this4.Loading = false;
      }).catch(function (res) {
        _this4.Loading = false;
        _this4.$message.error(res.message);
      });
    },
    pageChangeIssue: function pageChangeIssue(page) {
      this.tableFromIssue.page = page;
      this.getIssueList();
    },
    handleSizeChangeIssue: function handleSizeChangeIssue(val) {
      this.tableFromIssue.limit = val;
      this.getIssueList();
    },
    getGroup: function getGroup() {
      var _this5 = this;
      (0, _marketing.couponGroupSelectApi)().then(function (res) {
        _this5.groupList = res || [];
      }).catch(function () {});
    },
    handleExport: function handleExport() {
      var _this6 = this;
      var that = this;
      this.$confirm("是否确认导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        that.submitExport();
      }).catch(function () {
        _this6.$message({
          type: "info",
          message: "已取消导出"
        });
      });
    },
    submitExport: function submitExport() {
      (0, _marketing.couponUserExportApi)(this.tableFromIssue).then(function (res) {
        console.log(res, "success");
        window.open(res.fileName);
      }).catch(function (res) {});
    }
  }
};
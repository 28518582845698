"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "edit"
  props: {
    prent: {
      type: Object,
      required: true
    },
    isCreate: {
      type: Number,
      default: 0
    },
    editData: {
      type: Object
    },
    biztype: {
      type: Object,
      required: true
    },
    allTreeList: {
      type: Array
    }
  },
  data: function data() {
    return {
      loadingBtn: false,
      constants: this.$constants,
      sort: 0,
      editPram: {
        name: null,
        pid: 0,
        sort: '0',
        status: true,
        type: 8,
        id: 0
      },
      categoryProps: {
        value: 'id',
        label: 'name',
        children: 'child',
        expandTrigger: 'hover',
        checkStrictly: true,
        emitPath: false
      },
      parentOptions: []
    };
  },
  mounted: function mounted() {
    this.initEditData();
    console.log(this.editData, 'assddasd');
    // this.editPram = this.editData
    // this.editPram = this.prent
  },
  methods: {
    addTreeListLabelForCasCard: function addTreeListLabelForCasCard(arr, child) {
      arr.forEach(function (o, i) {
        if (o.child && o.child.length) {
          // o.disabled = true
          o.child.forEach(function (j) {
            j.disabled = true;
          });
        }
      });
    },
    initEditData: function initEditData() {
      this.parentOptions = _toConsumableArray(this.allTreeList);
      this.addTreeListLabelForCasCard(this.parentOptions, 'child');
      var _this$editData = this.editData,
        extra = _this$editData.extra,
        name = _this$editData.name,
        pid = _this$editData.pid,
        sort = _this$editData.sort,
        status = _this$editData.status,
        type = _this$editData.type,
        id = _this$editData.id,
        url = _this$editData.url;
      if (this.isCreate === 1) {
        this.editPram.extra = extra;
        this.editPram.name = name;
        this.editPram.pid = pid;
        this.editPram.sort = sort;
        this.editPram.status = status;
        this.editPram.type = 8;
        this.editPram.url = url;
        this.editPram.id = id;
      } else {
        this.editPram.pid = this.prent.id;
        this.editPram.type = 8;
      }
    },
    close: function close() {
      this.$emit('hideEditDialog');
    },
    handlerSubmit: function handlerSubmit(formName) {
      var _this = this;
      this.editPram.sort = this.sort;
      this.$refs[formName].validate(function (valid) {
        if (!valid) return;
        _this.handlerSaveOrUpdate(_this.isCreate === 0);
      });
    },
    handlerSaveOrUpdate: function handlerSaveOrUpdate(isSave) {
      var _this2 = this;
      if (isSave) {
        this.loadingBtn = true;
        console.log(this.editPram, 'this.editPramthis.editPram');
        categoryApi.addCategroy(this.editPram).then(function (data) {
          _this2.$emit('hideEditDialog');
          _this2.$message.success('创建目录成功');
          _this2.loadingBtn = false;
        }).catch(function () {
          _this2.loadingBtn = false;
        });
      } else {
        this.editPram.pid = Array.isArray(this.editPram.pid) ? this.editPram.pid[0] : this.editPram.pid;
        this.loadingBtn = true;
        categoryApi.updateCategroy(this.editPram).then(function (data) {
          _this2.$emit('hideEditDialog');
          _this2.$message.success('更新目录成功');
          _this2.loadingBtn = false;
        }).catch(function () {
          _this2.loadingBtn = false;
        });
      }
    }
  }
};
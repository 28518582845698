"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IMport = IMport;
exports.add = add;
exports.del = del;
exports.edit = edit;
exports.getList = getList;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/*
 * @Author: Zcl84765
 * @Date: 2023-02-07 09:54:43
 * @LastEditors: Zcl84765
 * @LastEditTime: 2023-02-07 14:39:00
 */

/**
 *
 * 新增关键字
 */

function add(data) {
  return (0, _request.default)({
    url: "/admin/searchKeyword/save",
    method: "post",
    data: data
  });
}
/**
 *
 * 关键字查询
 */
function getList(params) {
  return (0, _request.default)({
    url: "/admin/searchKeyword/getList",
    method: "get",
    params: params
  });
}

/**
 *
 * 编辑关键字
 */

function edit(data) {
  return (0, _request.default)({
    url: "/admin/searchKeyword/edit",
    method: "post",
    data: data
  });
}

/**
 *
 * 关键删除
 */
function del(params) {
  return (0, _request.default)({
    url: "/admin/searchKeyword/delete",
    method: "get",
    params: params
  });
}

/**
 *
 * 关键字导入
 */
function IMport(data) {
  return (0, _request.default)({
    url: "/admin/searchKeyword/importKeywords",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LiveList = LiveList;
exports.addLive = addLive;
exports.deleteLive = deleteLive;
exports.exportSignUpform = exportSignUpform;
exports.getLiveDetail = getLiveDetail;
exports.getSignUpPageList = getSignUpPageList;
exports.synchronizationDataApi = synchronizationDataApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 *
 * 新增直播间/更新
 */

function addLive(data) {
  return (0, _request.default)({
    url: "/admin/vhall/saveOrUpdate",
    method: "post",
    data: data
  });
}

/**
 *
 * 查询直播间的列表
 */

function LiveList(params) {
  return (0, _request.default)({
    url: "/admin/vhall/findPageAndList",
    method: "get",
    params: params
  });
}

/**
 *
 * 查询直播间详情
 */

function getLiveDetail(params) {
  return (0, _request.default)({
    url: "/admin/vhall/findVhallInfo",
    method: "get",
    params: params
  });
}

/**
 *
 * 删除直播间
 */

function deleteLive(data) {
  return (0, _request.default)({
    url: "/admin/vhall/deleteByIdList",
    method: "post",
    data: data
  });
}

/**
 *
 * 直播报名列表
 */

function getSignUpPageList(params) {
  return (0, _request.default)({
    url: "/admin/vhall/findSignUpPageAndList",
    method: "get",
    params: params
  });
}

/***
 *
 * 报名名单导出
 */

function exportSignUpform(params) {
  return (0, _request.default)({
    url: "admin/vhall/exportSignUpform",
    method: "get",
    params: params
  });
}

/**
 * 
 * 直播间同步数据
 */

function synchronizationDataApi(params) {
  return (0, _request.default)({
    url: 'admin/vhall/backLiveInfoToCL',
    method: 'get',
    params: params
  });
}
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var enterpriseApi = _interopRequireWildcard(require("@/api/enterprise.js"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "list",
  components: {},
  data: function data() {
    return {
      listPram: {
        name: null,
        page: 1,
        limit: this.$constants.page.limit[0],
        area: "1"
      },
      constants: this.$constants,
      headeNum: [{
        name: "戴尔用户专区",
        type: 1
      }, {
        name: "跨品牌服务专区",
        type: 2
      }, {
        name: "活动表单",
        type: 3
      }],
      listData: [{}],
      editDialogVisual: false
    };
  },
  filters: {
    areaFilter: function areaFilter(val) {
      return val == 1 ? "戴尔用户专区" : val == 2 ? "跨品牌服务专区" : "活动表单";
    }
  },
  created: function created() {
    if (this.$route.query.type) {
      this.listPram.area = this.$route.query.type.toString();
    }
  },
  mounted: function mounted() {
    this.handlerGetListData();
  },
  methods: {
    handlerExportRow: function handlerExportRow(id) {
      var _this = this;
      var loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      enterpriseApi.exportEnterpriseService(id).then(function (res) {
        loading.close();
        window.open(res.fileName);
        _this.$message.success("操作成功");
      }).catch(function () {
        loading.close();
      });
    },
    onchangeIsShow: function onchangeIsShow(row, flag) {
      var _this2 = this;
      enterpriseApi.OpenEnterpriseService({
        id: row.id,
        status: flag
      }).then(function (res) {
        _this2.$message.success("操作成功");
        _this2.handlerGetListData();
      });
    },
    handerSearch: function handerSearch() {
      this.listPram.page = 1;
      this.handlerGetListData();
    },
    handlerGetListData: function handlerGetListData() {
      var _this3 = this;
      enterpriseApi.ListEnterpriseService(this.listPram).then(function (data) {
        _this3.listData = data;
      });
    },
    handlerOpenEdit: function handlerOpenEdit(id, type) {
      this.editDialogVisual = true;
      this.$router.push({
        path: id ? "serve-add/".concat(id) : "serve-add",
        query: {
          type: type
        }
      });
    },
    handlerHideDialog: function handlerHideDialog() {},
    handlerDelete: function handlerDelete(rowData) {
      var _this4 = this;
      this.$confirm("确定删除当前数据", "提示").then(function (result) {
        enterpriseApi.DelEnterpriseService(rowData.id).then(function (data) {
          _this4.$message.success("删除数据成功");
          _this4.handlerGetListData();
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetListData();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetListData();
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, "label-width": "80px", inline: "true" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "流程状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    _vm._l(_vm.paneList, function (item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.name, value: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "流程名称", "label-width": "80px" } },
                [_c("el-input")],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "router-link",
            { attrs: { to: "/marketing/flow" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                },
                [_vm._v("新建短信")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: { data: _vm.tableData },
            },
            [
              _c("el-table-column", {
                attrs: { fixed: "", prop: "name", label: "名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "date", label: "起止时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleClick(scope.row)
                              },
                            },
                          },
                          [_vm._v("克隆")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          { attrs: { type: "text", size: "small" } },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleClick(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.length,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
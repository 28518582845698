var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: "", size: "small" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "关键词：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入关键词",
                                size: "small",
                                clearable: "",
                              },
                              model: {
                                value: _vm.listPram.solutionName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.listPram, "solutionName", $$v)
                                },
                                expression: "listPram.solutionName",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: { click: _vm.handerSearch },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlerOpenEdit(0)
                    },
                  },
                },
                [_vm._v("添加解决方案")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticClass: "table",
              attrs: {
                data: _vm.listData.list,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "solutionName",
                  label: "解决方案名称",
                  "min-width": "180",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  "min-width": "180",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 0,
                            "inactive-value": 1,
                            "active-text": "上架",
                            "inactive-text": "下架",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onchangeIsShow(scope.row, $event)
                            },
                          },
                          model: {
                            value: scope.row.status,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "100",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerOpenEdit(1, scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        !scope.row.isSpecial
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerDelete(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.listPram.page,
              "page-sizes": _vm.constants.page.limit,
              layout: _vm.constants.page.layout,
              total: _vm.listData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editDialogConfig.visible,
            title:
              _vm.editDialogConfig.isEdit === 0
                ? "创建解决方案"
                : "编辑解决方案",
            "destroy-on-close": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialogConfig, "visible", $event)
            },
          },
        },
        [
          _vm.editDialogConfig.visible
            ? _c("edit", {
                attrs: {
                  "is-edit": _vm.editDialogConfig.isEdit,
                  "data-obj": _vm.editDialogConfig.editData,
                },
                on: { hideDialog: _vm.handlerHideDialog },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
//
//
//
//
//
//
var _default = exports.default = {
  name: "App",
  data: function data() {
    return {
      _gap_time: "",
      _beforeUnload_time: ""
    };
  },
  created: function created() {} //  mounted() {
  //    console.log(process.env.VUE_APP_BASE_API,'process.env.VUE_APP_BASE_API1111');
  // // this.unloadHandler()
  //     window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  //     // window.alert(444555)
  //     window.addEventListener('unload', e => this.unloadHandler(e))
  //     console.log(this,window.addEventListener, 'windowwindowwindowwindow');
  //   },
  //   destroyed() {
  //     window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  //     window.removeEventListener('unload', e => this.unloadHandler(e))
  //   },
  //   methods: {
  //     async logOut() {
  //           await logout().then(res => {
  //             console.log(res, 'rrrrrrrrrrrrrr');
  //               //  this.$message.warning(num, 2);
  //             //   console.log(num)
  //             //  if(response.Code==200){
  //             //     this.Login=true
  //             //  }else{
  //             //     this.Login=false
  //             //  }
  //           })
  //       },
  //     beforeunloadHandler(e){
  //       this._beforeUnload_time = new Date().getTime();
  //       console.log(this._beforeUnload_time, 'this._beforeUnload_time');
  //       e.returnValue = '关闭提示'; '弹窗'
  //     },
  //     unloadHandler(e){
  //       // debugger
  //       this._gap_time = new Date().getTime() - this._beforeUnload_time;
  //       console.log(this._gap_time, 'this._gap_time');
  //       //判断是窗口关闭还是刷新
  //       window.alert(666)
  //       // debugger
  //       this.logOut()
  //       if (this._gap_time <= 5) {
  //         // alert('555')
  //       this.logOut()
  //         // let datas = {
  //         //   objCode:null,
  //         //   opertion:'退出登录ajax',
  //         //   param:null,
  //         //   path:null,}
  //         // datas =  JSON.stringify(datas)
  //         // $.ajax({
  //         //   url: process.env.VUE_APP_BASE_API + '/api/admin/logout',
  //         //   type: 'get',
  //         //   contentType:'application/json',
  //         //   data:datas,
  //         //   dataType: "json",
  //         //   async: false, //或false,是否异步
  //         // })
  //       }
  //     },
  // },
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("meta", { attrs: { name: "referrer", content: "no-referrer" } }),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { margin: "5px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addRoom(1)
                        },
                      },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%", "font-size": "12px" },
              attrs: { data: _vm.tableData, "tooltip-effect": "dark" },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "直播间id",
                  width: "80",
                  prop: "roomid",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "直播间标题",
                  width: "120",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "cover_img", label: "背景图", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: { src: scope.row.cover_img },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "分享图片",
                  prop: "share_img",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: { src: scope.row.share_img },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "live_status",
                  label: "直播间状态",
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-tag", [
                          _vm._v(
                            "\n              " +
                              _vm._s(scope.row.live_status) +
                              "\n            "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "start_time",
                  label: "开始时间",
                  width: "180",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "end_time",
                  label: "预计结束时间",
                  width: "180",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "anchor_name",
                  label: "主播昵称",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "250",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.live_type == "1" &&
                        scope.row.live_status === "未开始"
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getPushFlow(scope.row)
                                  },
                                },
                              },
                              [_vm._v("推流")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.live_type == "0" &&
                        scope.row.live_status === "未开始"
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: { click: _vm.alertPic },
                              },
                              [_vm._v("手机")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.live_status === "未开始"
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(2, scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        _vm._v(" "),
                        scope.row.live_status === "未开始"
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAdd(scope.row)
                                  },
                                },
                              },
                              [_vm._v("添加商品")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage2,
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.PageSize,
                  layout: "sizes, prev, pager, next",
                  total: _vm.totalPage,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    _vm.currentPage2 = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentPage2 = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "添加直播间",
                visible: _vm.dialogVisibleAdd,
                width: "50%",
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisibleAdd = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播间标题", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "17",
                              minlength: "3",
                              type: "text",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.ruleForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "name", $$v)
                              },
                              expression: "ruleForm.name",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("最短3个汉字，最长17个汉字"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播间背景图" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo mr10",
                              class: { disabled: _vm.uploadDisabled },
                              attrs: {
                                action: "#",
                                "list-type": "picture-card",
                                "file-list": _vm.fileList2,
                                "http-request": _vm.handleUploadForm,
                                "on-change": _vm.uploadChange1,
                                "on-remove": _vm.handleRemove1,
                              },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("建议像素1080*1920，大小不超过2M"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播间分享图" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo mr10",
                              class: { disabled: _vm.uploadDisabled1 },
                              attrs: {
                                action: "#",
                                "list-type": "picture-card",
                                "file-list": _vm.fileList3,
                                "http-request": _vm.handleUploadForm1,
                                "on-change": _vm.uploadChange2,
                                "on-remove": _vm.handleRemove2,
                              },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("建议像素800*640，大小不超过1M"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播频道封面图" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo mr10",
                              class: { disabled: _vm.uploadDisabled2 },
                              attrs: {
                                action: "#",
                                "list-type": "picture-card",
                                "file-list": _vm.fileList4,
                                "http-request": _vm.handleUploadForm2,
                                "on-change": _vm.uploadChange3,
                                "on-remove": _vm.handleRemove3,
                              },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("建议像素800*800，大小不超过100KB"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "计划开播时间", prop: "start_time" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              placeholder: "选择日期时间",
                            },
                            model: {
                              value: _vm.ruleForm.start_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "start_time", $$v)
                              },
                              expression: "ruleForm.start_time",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "red",
                            "margin-left": "120px",
                            "margin-bottom": "5px",
                          },
                        },
                        [
                          _vm._v(
                            "开播时间需要在当前时间的10分钟后 并且 开始时间不能在 6\n              个月后"
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "计划关播时间", prop: "end_time" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              placeholder: "选择日期时间",
                            },
                            model: {
                              value: _vm.ruleForm.end_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "end_time", $$v)
                              },
                              expression: "ruleForm.end_time",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "red",
                            "margin-left": "120px",
                            "margin-bottom": "5px",
                          },
                        },
                        [
                          _vm._v(
                            "开播时间和结束时间间隔不得短于30分钟，不得超过24小时"
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "主播昵称", prop: "anchor_name" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.ruleForm.anchor_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "anchor_name", $$v)
                              },
                              expression: "ruleForm.anchor_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "主播微信号", prop: "anchor_wechat" },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.ruleForm.anchor_wechat,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "anchor_wechat", $$v)
                              },
                              expression: "ruleForm.anchor_wechat",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播间类型", prop: "live_type" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.typeDisabled },
                              model: {
                                value: _vm.ruleForm.live_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "live_type", $$v)
                                },
                                expression: "ruleForm.live_type",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("推流"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "0" } }, [
                                _vm._v("手机直播"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播间点赞", prop: "close_like" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.close_like,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "close_like", $$v)
                                },
                                expression: "ruleForm.close_like",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("关闭"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "0" } }, [
                                _vm._v("开启"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播间货架", prop: "close_goods" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.close_goods,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "close_goods", $$v)
                                },
                                expression: "ruleForm.close_goods",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("关闭"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "0" } }, [
                                _vm._v("开启"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "直播间评论", prop: "close_comment" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.close_comment,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "close_comment", $$v)
                                },
                                expression: "ruleForm.close_comment",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("关闭"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "0" } }, [
                                _vm._v("开启"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播回放", prop: "close_replay" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.close_replay,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "close_replay", $$v)
                                },
                                expression: "ruleForm.close_replay",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("关闭"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "0" } }, [
                                _vm._v("开启"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.ruleForm.close_share
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "分享直播间",
                                prop: "close_share",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.close_share,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "close_share", $$v)
                                    },
                                    expression: "ruleForm.close_share",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("关闭"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: "0" } }, [
                                    _vm._v("开启"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisibleAdd = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.submitFlag },
                      on: {
                        click: function ($event) {
                          return _vm.summitVideo("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.goodsDialogVisible
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    title: "修改直播间商品信息",
                    visible: _vm.goodsDialogVisible,
                    width: "35%",
                    "before-close": _vm.handleClose,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.goodsDialogVisible = $event
                    },
                  },
                },
                [
                  _c("span", [_vm._v("选择入库商品")]),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        action: "#",
                        "list-type": "picture-card",
                        "on-preview": _vm.handlePictureCardPreview,
                        "on-remove": _vm.handleRemove,
                        "http-request": _vm.httpRequest,
                        "file-list": _vm.fileList,
                        disabled: _vm.selectGoodsDisabled,
                      },
                    },
                    [
                      _c("div", { on: { click: _vm.openGoodsListDialog } }, [
                        _c("i", {
                          staticClass: "el-icon-plus",
                          staticStyle: { width: "100%", height: "100%" },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      attrs: { visible: _vm.uploadVisable },
                      on: {
                        "update:visible": function ($event) {
                          _vm.uploadVisable = $event
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "100%",
                          src: _vm.dialogImageUrl,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.goodsDialogVisible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.confirmAddProduct },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogVisiblePicture },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisiblePicture = $event
                },
              },
            },
            [
              _c(
                "el-row",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-right": "5px" },
                      attrs: { span: 8 },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入搜索内容" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.selectValue,
                            callback: function ($$v) {
                              _vm.selectValue = $$v
                            },
                            expression: "selectValue",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: {
                    icon: "el-icon-search",
                    size: "mini",
                    type: "success",
                  },
                },
                [_vm._v("搜索")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "goodsListTable",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.productList, "tooltip-effect": "dark" },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "直播商品id",
                      width: "120",
                      prop: "goodsId",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "商品名称", width: "120" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "cover_img", label: "关联商品id" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "商品图片", prop: "coverImgUrl" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-image", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: { src: scope.row.coverImgUrl },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.goodsDialogClose },
                    },
                    [_vm._v("确定选择")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "推流地址",
            visible: _vm.pushFlowVisible,
            width: "30%",
            "before-close": _vm.pushFlowClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.pushFlowVisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "line-height": "30px" } }, [
            _vm._v("服务器地址: rtmp://wxalivepush.weixin.qq.com/live/"),
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { "line-height": "30px" } }, [
            _vm._v("串流密钥: " + _vm._s(_vm.secretKey)),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.pushFlowVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "开播码",
            visible: _vm.openVideoDialog,
            width: "30%",
            "before-close": _vm.openVideoClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openVideoDialog = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { "margin-left": "15%" },
            attrs: {
              src: "https://workstation.chinaeast2.cloudapp.chinacloudapi.cn:8221/ecomimage/maintain/2021/12/08/787a9b40dd3645bda30c0c7ae8c1d357rombwa04yc.png",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.openVideoDialog = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
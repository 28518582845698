"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFormConfigEdit = getFormConfigEdit;
exports.getFormConfigInfo = getFormConfigInfo;
exports.getFormConfigList = getFormConfigList;
exports.getFormConfigSave = getFormConfigSave;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getFormConfigInfo(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/system/form/temp/info',
    method: 'GET',
    params: data
  });
}
function getFormConfigList(pram) {
  var data = {
    keywords: pram.keywords,
    page: pram.page,
    limit: pram.limit
  };
  return (0, _request.default)({
    url: '/admin/system/form/temp/list',
    method: 'GET',
    params: data
  });
}
function getFormConfigSave(pram) {
  var data = {
    content: pram.content,
    info: pram.info,
    name: pram.name
  };
  return (0, _request.default)({
    url: '/admin/system/form/temp/save',
    method: 'POST',
    data: data
  });
}
function getFormConfigEdit(pram) {
  var params = {
    id: pram.id
  };
  var data = {
    content: pram.content,
    info: pram.info,
    name: pram.name
  };
  return (0, _request.default)({
    url: '/admin/system/form/temp/update',
    method: 'POST',
    params: params,
    data: data
  });
}
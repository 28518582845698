var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "editPram",
          attrs: { model: _vm.editPram, "label-width": "130px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "分区名称",
                prop: "name",
                rules: [
                  {
                    required: true,
                    message: "请输入分区名称",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "分区名称" },
                model: {
                  value: _vm.editPram.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.editPram, "name", $$v)
                  },
                  expression: "editPram.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序" } },
            [
              _c("el-input-number", {
                attrs: { step: 1, min: 0 },
                model: {
                  value: _vm.editPram.weight,
                  callback: function ($$v) {
                    _vm.$set(_vm.editPram, "weight", $$v)
                  },
                  expression: "editPram.weight",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-text": "开启",
                  "inactive-text": "关闭",
                  "active-value": 1,
                  "inactive-value": 0,
                },
                model: {
                  value: _vm.editPram.enableStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.editPram, "enableStatus", $$v)
                  },
                  expression: "editPram.enableStatus",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingBtn },
                  on: {
                    click: function ($event) {
                      return _vm.handlerSubmit("editPram")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "pram",
          attrs: { model: _vm.pram, "label-width": "100px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "身份名称",
                prop: "roleName",
                rules: [
                  {
                    required: true,
                    message: "请填写身份名称",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "身份名称" },
                model: {
                  value: _vm.pram.roleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.pram, "roleName", $$v)
                  },
                  expression: "pram.roleName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c("el-switch", {
                attrs: { "active-value": true, "inactive-value": false },
                model: {
                  value: _vm.pram.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.pram, "status", $$v)
                  },
                  expression: "pram.status",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "权限" } },
            [
              _c("category-list", {
                attrs: {
                  biztype: _vm.constants.categoryType[4],
                  "select-model": true,
                  "row-select": _vm.pram.rules,
                  "select-model-keys": _vm.editData.rules
                    ? _vm.editData.rules.split(",")
                    : [],
                },
                on: { rulesSelect: _vm.rulesSelect },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlerSubmit("pram")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.isCreate === 0 ? "確定" : "更新"))]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
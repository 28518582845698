var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox store-page" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.currentTab,
                    "align-center": "",
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", { attrs: { title: "商品信息" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "商品详情" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "规格设置" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "其他设置" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "110px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 0,
                      expression: "currentTab === 0",
                    },
                  ],
                  attrs: { gutter: 24 },
                },
                [
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品名称：", prop: "storeName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "249",
                              placeholder: "请输入商品名称",
                            },
                            model: {
                              value: _vm.formValidate.storeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "storeName", $$v)
                              },
                              expression: "formValidate.storeName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品分类：", prop: "cateIds" } },
                        [
                          _c("el-cascader", {
                            ref: "cascader",
                            staticClass: "selWidth",
                            attrs: {
                              options: _vm.merCateList,
                              props: _vm.props2,
                              clearable: "",
                              "show-all-levels": false,
                              "node-key": "id",
                            },
                            model: {
                              value: _vm.formValidate.cateIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "cateIds", $$v)
                              },
                              expression: "formValidate.cateIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品关键字：" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "搜索关键字，任意间隔方式" },
                            model: {
                              value: _vm.formValidate.keyword,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "keyword", $$v)
                              },
                              expression: "formValidate.keyword",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单位：", prop: "unitName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入单位" },
                            model: {
                              value: _vm.formValidate.unitName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "unitName", $$v)
                              },
                              expression: "formValidate.unitName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", _vm._b({}, "el-col", _vm.grid2, false)),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品封面图：", prop: "image" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1")
                                },
                              },
                            },
                            [
                              _vm.formValidate.image
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formValidate.image },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "brief" }, [
                            _vm._v(
                              "\n              （请上传一张商品主图，建议尺寸：800 x 800 像素）\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "价格类型" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formValidate.priceType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "priceType", $$v)
                                },
                                expression: "formValidate.priceType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("起始价"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("原价"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("中文价"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formValidate.priceType == 1 ||
                  _vm.formValidate.priceType == 0
                    ? _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "商品价格：",
                                prop: "lowestPrice",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "number",
                                  min: 0,
                                  placeholder: "请输入商品价格：",
                                },
                                model: {
                                  value: _vm.formValidate.lowestPrice,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "lowestPrice",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.lowestPrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formValidate.priceType == 2
                    ? _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "中文价格：",
                                prop: "chinesePrice",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入中文价格：最多10个字",
                                  maxlength: "10",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.formValidate.chinesePrice,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "chinesePrice",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.chinesePrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "position-l", attrs: { span: 20 } },
                        _vm._l(_vm.sellingPointsList, function (domain, index) {
                          return _c(
                            "el-form-item",
                            {
                              key: index,
                              attrs: { label: "人气卖点" + (index + 1) + ":" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  placeholder: "请输入人气卖点，最多30个字",
                                  maxlength: "30",
                                },
                                model: {
                                  value: domain.value,
                                  callback: function ($$v) {
                                    _vm.$set(domain, "value", $$v)
                                  },
                                  expression: "domain.value",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-button", { on: { click: _vm.addPoint } }, [
                                _vm._v("新增"),
                              ]),
                              _vm._v(" "),
                              _vm.sellingPointsList.length > 1
                                ? _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.removePoint(domain)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品轮播图：",
                            prop: "sliderImages",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _vm._l(
                                _vm.formValidate.sliderImages,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "pictrue",
                                      attrs: { draggable: "true" },
                                      on: {
                                        dragstart: function ($event) {
                                          return _vm.handleDragStart(
                                            $event,
                                            item
                                          )
                                        },
                                        dragover: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDragOver(
                                            $event,
                                            item
                                          )
                                        },
                                        dragenter: function ($event) {
                                          return _vm.handleDragEnter(
                                            $event,
                                            item
                                          )
                                        },
                                        dragend: function ($event) {
                                          return _vm.handleDragEnd($event, item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", { attrs: { src: item } }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-error btndel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(index)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.formValidate.sliderImages.length < 10
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "upLoadPicBox",
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalPicTap("2")
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "brief" }, [
                            _vm._v(
                              "\n              （请上传商品轮播图，建议尺寸：800 x 800\n              像素，可以拖拽图片调整图片顺序）\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 1,
                      expression: "currentTab === 1",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品详情：" } },
                        [
                          _c("ueditor-from", {
                            attrs: { content: _vm.formValidate.content },
                            model: {
                              value: _vm.formValidate.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "content", $$v)
                              },
                              expression: "formValidate.content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 3,
                      expression: "currentTab === 3",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "排序：" } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "des-info",
                                  staticStyle: { left: "208px" },
                                  attrs: { placement: "bottom" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [_vm._v("数字越大，排序越靠前")]
                                  ),
                                  _vm._v(" "),
                                  _c("i", { staticClass: "el-icon-question" }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("el-input-number", {
                                attrs: { min: 0, placeholder: "请输入排序" },
                                model: {
                                  value: _vm.formValidate.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "sort", $$v)
                                  },
                                  expression: "formValidate.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "积分：" } },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0, placeholder: "请输入排序" },
                                model: {
                                  value: _vm.formValidate.giveIntegral,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "giveIntegral",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.giveIntegral",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "虚拟销量：" } },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0, placeholder: "请输入排序" },
                                model: {
                                  value: _vm.formValidate.ficti,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "ficti", $$v)
                                  },
                                  expression: "formValidate.ficti",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "des-demo", attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否园区专供：" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.checkShowTag,
                                callback: function ($$v) {
                                  _vm.checkShowTag = $$v
                                },
                                expression: "checkShowTag",
                              },
                            },
                            _vm._l(_vm.showPark, function (item, index) {
                              return _c(
                                "el-checkbox",
                                { key: index, attrs: { label: item.value } },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "des-demo", attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否新品：" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.checkShowTag,
                                callback: function ($$v) {
                                  _vm.checkShowTag = $$v
                                },
                                expression: "checkShowTag",
                              },
                            },
                            _vm._l(_vm.showTag, function (item, index) {
                              return _c(
                                "el-checkbox",
                                { key: index, attrs: { label: item.value } },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "des-info",
                          staticStyle: { left: "198px" },
                          attrs: { placement: "bottom" },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [_vm._v("小程序前端商品标签展示")]
                          ),
                          _vm._v(" "),
                          _c("i", { staticClass: "el-icon-question" }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "des-demo", attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品推荐：" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { size: "small" },
                              on: { change: _vm.onChangeGroup },
                              model: {
                                value: _vm.checkboxGroup,
                                callback: function ($$v) {
                                  _vm.checkboxGroup = $$v
                                },
                                expression: "checkboxGroup",
                              },
                            },
                            _vm._l(_vm.recommend, function (item, index) {
                              return _c(
                                "el-checkbox",
                                { key: index, attrs: { label: item.value } },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "des-info demo-re",
                              attrs: { placement: "bottom" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [_vm._v("小程序前端商品推荐列表")]
                              ),
                              _vm._v(" "),
                              _c("i", { staticClass: "el-icon-question" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "des-info demo-re des-box",
                              attrs: { placement: "bottom" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n                勾选后为绝妙搭配默认产品展示 最多展示18个\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("i", { staticClass: "el-icon-question" }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "des-demo", attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "猜你喜欢：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "dialogWidth",
                              staticStyle: { width: "250px" },
                              attrs: {
                                placeholder: "请选择商品",
                                readOnly: "",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "append" },
                                  on: { click: _vm.changeLike },
                                  slot: "append",
                                },
                                [_vm._v("选择")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "des-info demo-like",
                              attrs: { placement: "bottom" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [_vm._v("勾选小程序前端商品详情页内猜你喜欢")]
                              ),
                              _vm._v(" "),
                              _c("i", { staticClass: "el-icon-question" }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.guessYouLikeProductList.length > 0
                    ? _c(
                        "el-col",
                        {
                          staticClass: "des-demo select-store",
                          attrs: { span: 22 },
                        },
                        _vm._l(
                          _vm.guessYouLikeProductList,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "store-child" },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "猜你喜欢：" } },
                                  [
                                    _c("div", { staticClass: "width-box" }, [
                                      _vm._v(_vm._s(item.storeName)),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "del-btn",
                                    attrs: { size: "small", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.removeLike(index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "des-demo", attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "绝妙搭配：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "dialogWidth",
                              staticStyle: { width: "250px" },
                              attrs: {
                                placeholder: "请选择商品",
                                readOnly: "",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "append" },
                                  on: { click: _vm.changePrefect },
                                  slot: "append",
                                },
                                [_vm._v("选择")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "des-info demo-like",
                              attrs: { placement: "bottom" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [_vm._v("勾选小程序前端商品详情页内绝妙搭配")]
                              ),
                              _vm._v(" "),
                              _c("i", { staticClass: "el-icon-question" }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.perfectMatchProductList.length > 0
                    ? _c(
                        "el-col",
                        {
                          staticClass: "des-demo select-store",
                          attrs: { span: 22 },
                        },
                        _vm._l(
                          _vm.perfectMatchProductList,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "store-child" },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "绝妙搭配：" } },
                                  [
                                    _c("div", { staticClass: "width-box" }, [
                                      _vm._v(_vm._s(item.storeName)),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "del-btn",
                                    attrs: { size: "small", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.removePrefect(index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "des-demo", attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "相关文章" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "dialogWidth",
                              staticStyle: { width: "250px" },
                              attrs: {
                                placeholder: "请选择内容",
                                readOnly: "",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "append" },
                                  on: { click: _vm.changeContent },
                                  slot: "append",
                                },
                                [_vm._v("选择")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "des-info demo-like",
                              attrs: { placement: "bottom" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [_vm._v("最多可添加三个文章")]
                              ),
                              _vm._v(" "),
                              _c("i", { staticClass: "el-icon-question" }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.articleIdList.length > 0
                    ? _c(
                        "el-col",
                        {
                          staticClass: "des-demo select-store",
                          attrs: { span: 22 },
                        },
                        _vm._l(_vm.articleIdList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "store-child" },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "相关文章：" } },
                                [
                                  _c("div", { staticClass: "width-box" }, [
                                    _vm._v(_vm._s(item.title)),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "del-btn",
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removecontent(index)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "留资标题：", prop: "stayTitle" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "7",
                              placeholder: "表单头图标题，最多可输入7个字",
                            },
                            model: {
                              value: _vm.formValidate.stayTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "stayTitle", $$v)
                              },
                              expression: "formValidate.stayTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "留资头图",
                            prop: "stayHeadImage",
                            rules: [
                              {
                                required: false,
                                message: "请上传留资头图",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTapStay("1")
                                },
                              },
                            },
                            [
                              _vm.formValidate.stayHeadImage
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.formValidate.stayHeadImage,
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                              _vm._v(" "),
                              _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v("(推荐尺寸：380 X 750像素,1：1)"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 2,
                      expression: "currentTab === 2",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _vm.ccid
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "商品规格：", props: "specType" },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChangeSpec(
                                        _vm.formValidate.specType
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.formValidate.specType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "specType",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.specType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "radio",
                                      attrs: {
                                        label: false,
                                        disabled: _vm.formValidate.specType,
                                      },
                                    },
                                    [_vm._v("单规格")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        label: true,
                                        disabled: !_vm.formValidate.specType,
                                      },
                                    },
                                    [_vm._v("多规格")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.ccid
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "商品规格：", props: "specType" },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChangeSpec(
                                        _vm.formValidate.specType
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.formValidate.specType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "specType",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.specType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "radio",
                                      attrs: { label: false },
                                    },
                                    [_vm._v("单规格")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("多规格"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "noForm", attrs: { span: 24 } },
                    [
                      _vm.formValidate.specType
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "选择规格：", prop: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.formValidate.selectRule,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "selectRule",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.selectRule",
                                      },
                                    },
                                    _vm._l(_vm.ruleList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.ruleName,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr20",
                                      attrs: { type: "primary" },
                                      on: { click: _vm.confirm },
                                    },
                                    [_vm._v("确认")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      on: { click: _vm.addRule },
                                    },
                                    [_vm._v("添加规格")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.attr.length > 0
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.formValidate.specType
                                  ? ""
                                  : "规格配置：",
                              },
                            },
                            _vm._l(
                              _vm.formValidate.attr,
                              function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "div",
                                    { staticClass: "acea-row row-middle" },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "300px" },
                                        model: {
                                          value: item.attrName,
                                          callback: function ($$v) {
                                            _vm.$set(item, "attrName", $$v)
                                          },
                                          expression: "item.attrName",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-circle-close",
                                        staticStyle: {
                                          "font-size": "24px",
                                          "padding-left": "10px",
                                          color: "#aaa",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemoveAttr(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "acea-row row-middle attrDesc",
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "300px" },
                                        model: {
                                          value: item.attrDesc,
                                          callback: function ($$v) {
                                            _vm.$set(item, "attrDesc", $$v)
                                          },
                                          expression: "item.attrDesc",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "rulesBox" },
                                    [
                                      _vm._l(
                                        item.attrValue,
                                        function (j, indexn) {
                                          return _c(
                                            "el-tag",
                                            {
                                              key: indexn,
                                              staticClass: "mb5 mr10",
                                              attrs: {
                                                closable: "",
                                                size: "medium",
                                                "disable-transitions": false,
                                              },
                                              on: {
                                                close: function ($event) {
                                                  return _vm.handleClose(
                                                    item.attrValue,
                                                    indexn
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      item.attrValue[indexn],
                                                    expression:
                                                      "item.attrValue[indexn]",
                                                  },
                                                ],
                                                staticClass: "input-attr",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: item.attrValue[indexn],
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      item.attrValue,
                                                      indexn,
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      item.inputVisible
                                        ? _c("el-input", {
                                            ref: "saveTagInput",
                                            refInFor: true,
                                            staticClass: "input-new-tag",
                                            attrs: { size: "small" },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.createAttr(
                                                  item.attrValue.attrsVal,
                                                  index
                                                )
                                              },
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.createAttr(
                                                  item.attrValue.attrsVal,
                                                  index
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.attrValue.attrsVal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item.attrValue,
                                                  "attrsVal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.attrValue.attrsVal",
                                            },
                                          })
                                        : _c(
                                            "el-button",
                                            {
                                              staticClass: "button-new-tag",
                                              attrs: { size: "small" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showInput(item)
                                                },
                                              },
                                            },
                                            [_vm._v("+ 添加")]
                                          ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "picture-box" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "upLoadPicBox",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlePicTap("2", index)
                                          },
                                        },
                                      },
                                      [
                                        item.attrPopupImage
                                          ? _c(
                                              "div",
                                              { staticClass: "pictrue" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: item.attrPopupImage,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "upLoad" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-camera cameraIconfont",
                                                }),
                                              ]
                                            ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    item.attrPopupImage
                                      ? _c(
                                          "span",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.handlePic("2", index)
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ])
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isBtn
                        ? _c(
                            "el-col",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xl: 6,
                                    lg: 9,
                                    md: 9,
                                    sm: 24,
                                    xs: 24,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "规格：" } },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "请输入规格" },
                                        model: {
                                          value: _vm.formDynamic.attrsName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formDynamic,
                                              "attrsName",
                                              $$v
                                            )
                                          },
                                          expression: "formDynamic.attrsName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xl: 6,
                                    lg: 9,
                                    md: 9,
                                    sm: 24,
                                    xs: 24,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "规格注释：" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入规格注释",
                                        },
                                        model: {
                                          value: _vm.formDynamic.attrDesc,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formDynamic,
                                              "attrDesc",
                                              $$v
                                            )
                                          },
                                          expression: "formDynamic.attrDesc",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "帮我选择" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "picture-box" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "upLoadPicBox",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handlePicTap("1")
                                                },
                                              },
                                            },
                                            [
                                              _vm.formDynamic.attrPopupImage
                                                ? _c(
                                                    "div",
                                                    { staticClass: "pictrue" },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: _vm.formDynamic
                                                            .attrPopupImage,
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    { staticClass: "upLoad" },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-camera cameraIconfont",
                                                      }),
                                                    ]
                                                  ),
                                              _vm._v(" "),
                                              _vm.formDynamic.attrPopupImage
                                                ? _c(
                                                    "span",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handlePic(
                                                            "1"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xl: 6,
                                    lg: 9,
                                    md: 9,
                                    sm: 24,
                                    xs: 24,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "规格值：" } },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "请输入规格值" },
                                        model: {
                                          value: _vm.formDynamic.attrsVal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formDynamic,
                                              "attrsVal",
                                              $$v
                                            )
                                          },
                                          expression: "formDynamic.attrsVal",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xl: 12,
                                    lg: 6,
                                    md: 6,
                                    sm: 24,
                                    xs: 24,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { staticClass: "noLeft" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "mr15",
                                          attrs: { type: "primary" },
                                          on: { click: _vm.createAttrName },
                                        },
                                        [_vm._v("确定")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        { on: { click: _vm.offAttrName } },
                                        [_vm._v("取消")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isBtn &&
                      (_vm.formValidate.specType ||
                        (!_vm.formValidate.specType &&
                          _vm.formValidate.attr.length == 0))
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.formValidate.specType
                                  ? ""
                                  : "规格配置：",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "mr15",
                                  attrs: { type: "primary", icon: "md-add" },
                                  on: { click: _vm.addBtn },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formValidate.specType
                                        ? "添加新规格"
                                        : "配置规格"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "noForm", attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "参数配置：" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "mr15",
                              attrs: { type: "primary", icon: "md-add" },
                              on: { click: _vm.editParemSingle },
                            },
                            [_vm._v(_vm._s(_vm.paramsSet ? "配置" : "未配置"))]
                          ),
                          _vm._v(" "),
                          _c("el-tooltip", { attrs: { placement: "bottom" } }, [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(
                                  "\n               非必选项，不配置在小程序中不显示\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("i", { staticClass: "el-icon-question" }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formValidate.specType
                    ? _c(
                        "el-col",
                        { staticClass: "noForm", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "选中规格信息：" } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.selectAttrName) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab > 0,
                          expression: "currentTab > 0",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSubmitUp },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab < 3,
                          expression: "currentTab < 3",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest("formValidate")
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab === 3,
                          expression: "currentTab === 3",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.goPreview("formValidate")
                        },
                      },
                    },
                    [_vm._v("预览")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab === 3 || _vm.$route.params.id,
                          expression: "currentTab === 3 || $route.params.id",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("CreatTemplates", {
        ref: "addTemplates",
        on: { getList: _vm.getShippingList },
      }),
      _vm._v(" "),
      _c("present-list", {
        attrs: {
          dialogPresent: _vm.dialogPresent,
          selectPresentList: _vm.selectPresentList,
        },
        on: { closeDialog: _vm.closeDialog },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "赠品配置",
            visible: _vm.dialogVisible,
            width: "700px",
            "before-close": _vm.handleGiftClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.formData, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择赠品：", prop: "latitude" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "dialogWidth",
                      attrs: { placeholder: "请选择赠品", readOnly: "" },
                      model: {
                        value: _vm.ruleForm.latitude,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "latitude", $$v)
                        },
                        expression: "ruleForm.latitude",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "append" },
                          on: { click: _vm.goSelectPresent },
                          slot: "append",
                        },
                        [_vm._v("选择")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "giftPadding" },
                _vm._l(_vm.itemGift, function (itemn, indexn) {
                  return _c(
                    "div",
                    { key: indexn, staticClass: "present-list" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "giftItem",
                          attrs: {
                            label: "赠品名称:",
                            rules: {
                              required: true,
                              message: "请选择赠品",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("div", { staticStyle: { width: "300px" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(itemn.name) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "赠品件数:" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 1 },
                            model: {
                              value: itemn.num,
                              callback: function ($$v) {
                                _vm.$set(itemn, "num", $$v)
                              },
                              expression: "itemn.num",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "del-btn",
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removePresent(indexn)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitPresent("formData")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "参数配置",
            visible: _vm.dialogParamsVisible,
            width: "720px",
            "before-close": _vm.handleGiftClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogParamsVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "fixedParam" }, [_vm._v("固定参数:")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  staticStyle: {
                    width: "100%",
                    height: "410px",
                    overflow: "scroll",
                  },
                  attrs: { data: _vm.paramsCheckList, border: false },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      "show-overflow-tooltip": "",
                      width: "50",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "image", label: "缩略图", width: "80px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-image", { attrs: { src: scope.row.image } }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    { attrs: { prop: "subCategory" } },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c("span", [_vm._v("参数名称 ")]),
                          _vm._v(" "),
                          _c("el-tooltip", { attrs: { placement: "bottom" } }, [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(
                                  "\n                1. 前六个主参数均可用于展示"
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                2.其余填写的参数值将会被展示在查看详细参数弹框内。\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("i", { staticClass: "el-icon-question" }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "参数" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: scope.row.paramValue,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "paramValue", $$v)
                                },
                                expression: "scope.row.paramValue",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.$index < 6
                                ? _c("el-input", {
                                    attrs: { placeholder: "请输入内容" },
                                    model: {
                                      value: scope.row.abbreviatedParamValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "abbreviatedParamValue",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scope.row.abbreviatedParamValue",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c("span", [_vm._v("缩略版 ")]),
                          _vm._v(" "),
                          _c("el-tooltip", { attrs: { placement: "bottom" } }, [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(
                                  "\n                1. 按照顺序，依次展示在商品 详情页内和商品列表页。"
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                2.内容限制为10个字符。\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("i", { staticClass: "el-icon-question" }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitParams } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("conListss", {
        ref: "contentss",
        attrs: { ladaData: _vm.articleIdList },
        on: { chooseContent: _vm.chooseContent },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));
var valueAccountApi = _interopRequireWildcard(require("@/api/valueAccount.js"));
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "edit"
  props: {
    isEdit: {
      type: Number,
      default: 0
    },
    editData: {
      type: Object
    }
  },
  data: function data() {
    return {
      loadingBtn: false,
      constants: this.$constants,
      editPram: {
        name: null,
        weight: 1,
        enableStatus: 1,
        id: null
      },
      parentOptions: []
    };
  },
  mounted: function mounted() {
    this.initEditData();
  },
  methods: {
    close: function close() {
      this.$emit("hideDialog");
    },
    initEditData: function initEditData() {
      if (this.isEdit === 1) {
        var _this$editData = this.editData,
          name = _this$editData.name,
          weight = _this$editData.weight,
          enableStatus = _this$editData.enableStatus,
          id = _this$editData.id;
        this.editPram.name = name;
        this.editPram.weight = weight;
        this.editPram.enableStatus = enableStatus;
        this.editPram.id = id;
        console.log(this.editPram.weight, "this.editPram.weight");
      }
    },
    handlerSubmit: function handlerSubmit(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (!valid) return;
        _this.handlerSaveOrUpdate(_this.isEdit === 0);
      });
    },
    handlerSaveOrUpdate: function handlerSaveOrUpdate(isSave) {
      var _this2 = this;
      if (isSave) {
        this.loadingBtn = true;
        valueAccountApi.addArea(this.editPram).then(function (data) {
          _this2.$emit("hideDialog");
          _this2.$message.success("创建分区成功");
          _this2.loadingBtn = false;
        }).catch(function () {
          _this2.loadingBtn = false;
        });
      } else {
        this.loadingBtn = true;
        valueAccountApi.updateArea(this.editPram).then(function (data) {
          _this2.$emit("hideDialog");
          _this2.$message.success("更新分区成功");
          _this2.loadingBtn = false;
        }).catch(function () {
          _this2.loadingBtn = false;
        });
      }
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auto = require("@/api/auto");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "wayList",
  data: function data() {
    return {
      checkRadio: "1",
      searchName: "",
      tableData: [],
      tableFrom: {
        name: "",
        status: 5,
        pageSize: 20,
        pageNum: 1
      },
      total: 0
    };
  },
  created: function created() {
    this.handleList();
  },
  methods: {
    handleList: function handleList() {
      var _this = this;
      (0, _auto.getList)(this.tableFrom).then(function (res) {
        console.log(res);
        _this.tableData = res.campaignList;
        _this.total = res.total;
      });
    },
    delectDraft: function delectDraft(id) {
      var _this2 = this;
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _auto.deleteDraft)({
          id: id
        }).then(function (res) {
          _this2.$message({
            type: "success",
            message: "删除成功!"
          });
          _this2.handleList();
        });
      }).catch(function () {
        _this2.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this3 = this;
      (0, _auto.updateStatus)({
        id: row
      }).then(function (res) {
        _this3.$message.success("操作成功");
      }).catch(function (res) {
        _this3.$message.error("操作失败");
      });
    },
    handleChange: function handleChange() {
      console.log(this.checkRadio, "handleChange");
      this.handleList();
    },
    creatProcess: function creatProcess() {
      this.$router.push({
        path: "/marketing/automated/flow",
        query: {
          id: "1"
        }
      });
    },
    goEdit: function goEdit(row) {
      localStorage.setItem("currentTemplate", JSON.stringify(row));
      this.$router.push({
        path: "/marketing/automated/flow",
        query: {
          id: "2"
        }
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.handleList();
    },
    // 每页多少条
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.handleList();
    }
  }
};
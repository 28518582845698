"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
require("core-js/modules/es6.array.copy-within");
require("core-js/modules/es6.array.fill");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.array.from");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.array.iterator");
require("core-js/modules/es6.array.of");
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.array.species");
require("core-js/modules/es6.date.to-primitive");
require("core-js/modules/es6.function.has-instance");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.map");
require("core-js/modules/es6.math.acosh");
require("core-js/modules/es6.math.asinh");
require("core-js/modules/es6.math.atanh");
require("core-js/modules/es6.math.cbrt");
require("core-js/modules/es6.math.clz32");
require("core-js/modules/es6.math.cosh");
require("core-js/modules/es6.math.expm1");
require("core-js/modules/es6.math.fround");
require("core-js/modules/es6.math.hypot");
require("core-js/modules/es6.math.imul");
require("core-js/modules/es6.math.log1p");
require("core-js/modules/es6.math.log10");
require("core-js/modules/es6.math.log2");
require("core-js/modules/es6.math.sign");
require("core-js/modules/es6.math.sinh");
require("core-js/modules/es6.math.tanh");
require("core-js/modules/es6.math.trunc");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.number.epsilon");
require("core-js/modules/es6.number.is-finite");
require("core-js/modules/es6.number.is-integer");
require("core-js/modules/es6.number.is-nan");
require("core-js/modules/es6.number.is-safe-integer");
require("core-js/modules/es6.number.max-safe-integer");
require("core-js/modules/es6.number.min-safe-integer");
require("core-js/modules/es6.number.parse-float");
require("core-js/modules/es6.number.parse-int");
require("core-js/modules/es6.object.assign");
require("core-js/modules/es7.object.define-getter");
require("core-js/modules/es7.object.define-setter");
require("core-js/modules/es7.object.entries");
require("core-js/modules/es6.object.freeze");
require("core-js/modules/es6.object.get-own-property-descriptor");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.get-own-property-names");
require("core-js/modules/es6.object.get-prototype-of");
require("core-js/modules/es7.object.lookup-getter");
require("core-js/modules/es7.object.lookup-setter");
require("core-js/modules/es6.object.prevent-extensions");
require("core-js/modules/es6.object.is");
require("core-js/modules/es6.object.is-frozen");
require("core-js/modules/es6.object.is-sealed");
require("core-js/modules/es6.object.is-extensible");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.object.seal");
require("core-js/modules/es6.object.set-prototype-of");
require("core-js/modules/es7.object.values");
require("core-js/modules/es6.promise");
require("core-js/modules/es7.promise.finally");
require("core-js/modules/es6.reflect.apply");
require("core-js/modules/es6.reflect.construct");
require("core-js/modules/es6.reflect.define-property");
require("core-js/modules/es6.reflect.delete-property");
require("core-js/modules/es6.reflect.get");
require("core-js/modules/es6.reflect.get-own-property-descriptor");
require("core-js/modules/es6.reflect.get-prototype-of");
require("core-js/modules/es6.reflect.has");
require("core-js/modules/es6.reflect.is-extensible");
require("core-js/modules/es6.reflect.own-keys");
require("core-js/modules/es6.reflect.prevent-extensions");
require("core-js/modules/es6.reflect.set");
require("core-js/modules/es6.reflect.set-prototype-of");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.flags");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.search");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.set");
require("core-js/modules/es6.symbol");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.string.anchor");
require("core-js/modules/es6.string.big");
require("core-js/modules/es6.string.blink");
require("core-js/modules/es6.string.bold");
require("core-js/modules/es6.string.code-point-at");
require("core-js/modules/es6.string.ends-with");
require("core-js/modules/es6.string.fixed");
require("core-js/modules/es6.string.fontcolor");
require("core-js/modules/es6.string.fontsize");
require("core-js/modules/es6.string.from-code-point");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.string.italics");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.string.link");
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es7.string.pad-end");
require("core-js/modules/es6.string.raw");
require("core-js/modules/es6.string.repeat");
require("core-js/modules/es6.string.small");
require("core-js/modules/es6.string.starts-with");
require("core-js/modules/es6.string.strike");
require("core-js/modules/es6.string.sub");
require("core-js/modules/es6.string.sup");
require("core-js/modules/es6.typed.array-buffer");
require("core-js/modules/es6.typed.int8-array");
require("core-js/modules/es6.typed.uint8-array");
require("core-js/modules/es6.typed.uint8-clamped-array");
require("core-js/modules/es6.typed.int16-array");
require("core-js/modules/es6.typed.uint16-array");
require("core-js/modules/es6.typed.int32-array");
require("core-js/modules/es6.typed.uint32-array");
require("core-js/modules/es6.typed.float32-array");
require("core-js/modules/es6.typed.float64-array");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es6.weak-set");
require("core-js/modules/web.timers");
require("core-js/modules/web.immediate");
require("core-js/modules/web.dom.iterable");
require("regenerator-runtime/runtime");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/icons/icons/iconfont.css");
var _vueAwesomeSwiper = _interopRequireDefault(require("vue-awesome-swiper"));
require("swiper/dist/css/swiper.css");
require("vue-ydui/dist/ydui.base.css");
var _vueLazyload = _interopRequireDefault(require("vue-lazyload"));
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _attrFrom = _interopRequireDefault(require("./components/attrFrom"));
var _uploadFrom = _interopRequireDefault(require("./components/uploadPicture/uploadFrom"));
var _goodListFrom = _interopRequireDefault(require("./components/goodList/goodListFrom"));
var _couponFrom = _interopRequireDefault(require("./components/couponList/couponFrom"));
var _articleFrom = _interopRequireDefault(require("./components/articleList/articleFrom"));
var _index = _interopRequireDefault(require("@/components/uploadPicture/index.vue"));
var _uploadFile = _interopRequireDefault(require("@/components/Upload/uploadFile.vue"));
var _ueditorFrom = _interopRequireDefault(require("@/components/ueditorFrom"));
var _vueUeditorWrap = _interopRequireDefault(require("vue-ueditor-wrap"));
var _iconFrom = _interopRequireDefault(require("./components/iconFrom"));
var _TimeSelect = _interopRequireDefault(require("@/components/TimeSelect"));
var _dialog = _interopRequireDefault(require("@/libs/dialog"));
var _loading = _interopRequireDefault(require("@/libs/loading"));
var _asyncValidator = _interopRequireDefault(require("async-validator"));
var _index2 = _interopRequireDefault(require("@/components/uploadPicture/forGenrator/index.vue"));
var _utils = _interopRequireDefault(require("@/utils/utils"));
require("./utils/excelPermission");
var _modalAttr = _interopRequireDefault(require("@/libs/modal-attr"));
var _modalIcon = _interopRequireDefault(require("@/libs/modal-icon"));
var _public = require("@/libs/public");
var _loadScript = require("@/components/FormGenerator/utils/loadScript");
require("./permission");
require("./utils/error-log");
var filters = _interopRequireWildcard(require("./filters"));
var _utils2 = require("@/utils");
var Auth = _interopRequireWildcard(require("@/libs/wechat"));
var constants = _interopRequireWildcard(require("@/utils/constants.js"));
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
var _plugins = _interopRequireDefault(require("@/plugins"));
require("@/icons");
require("@/styles/index.scss");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; } // require('any-observable/register')('rxjs');
// import 'babel-polyfill'
// a modern alternative to CSS resets
// import dayjs from 'dayjs'
// import '@/styles/index.scss' // global css
// import "@/assets/iconfont/iconfont";
// 懒加载
_vue.default.config.devtools = true;

// 切勿更改 此组件为表单生成中使用的图片上传组件

//导入自定义权限指令

// import './icons' // icon
// permission control
// error integralLog
// global filters

_vue.default.use(_vueLazyload.default, {
  preLoad: 1.3,
  error: require("./assets/imgs/no.png"),
  loading: require("./assets/imgs/moren.jpg"),
  attempt: 1,
  listenEvents: ["scroll", "wheel", "mousewheel", "resize", "animationend", "transitionend", "touchmove"]
});
_vue.default.use(_uploadFrom.default);
_vue.default.use(_goodListFrom.default);
_vue.default.use(_couponFrom.default);
_vue.default.use(_articleFrom.default);
_vue.default.use(_vueAwesomeSwiper.default);
_vue.default.component("vue-ueditor-wrap", _vueUeditorWrap.default);
_vue.default.component("attrFrom", _attrFrom.default);
_vue.default.component("UploadIndex", _index.default);
_vue.default.component("SelfUpload", _index2.default);
_vue.default.component("iconFrom", _iconFrom.default);
_vue.default.component("ueditorFrom", _ueditorFrom.default);
_vue.default.component("uploadFile", _uploadFile.default);
_vue.default.component("timeSelect", _TimeSelect.default);
_vue.default.prototype.$modalSure = _public.modalSure;
_vue.default.prototype.$modalAttr = _modalAttr.default;
_vue.default.prototype.$modalIcon = _modalIcon.default;
_vue.default.prototype.$dialog = _dialog.default;
_vue.default.prototype.$scroll = _loading.default;
_vue.default.prototype.$wechat = Auth;
_vue.default.prototype.$util = _utils.default;
_vue.default.prototype.$constants = constants;
_vue.default.prototype.$selfUtil = selfUtil;
// Vue.prototype.$dayjs = dayjs;
_vue.default.prototype.$validator = function (rule) {
  return new _asyncValidator.default(rule);
};
var cookieName = "VCONSOLE";
var query = (0, _utils2.parseQuery)();
var urlSpread = query["spread"];
var vconsole = query[cookieName.toLowerCase()];
var md5 = "b14d1e9baeced9bb7525ab19ee35f2d2"; // MD5 加密开启vconsole模式
var md5Un = "3dca2162c4e101b7656793a1af20295c"; // MD5 加密关闭vconsole模式

if (vconsole !== undefined) {
  if (vconsole === md5Un && _jsCookie.default.has(cookieName)) _jsCookie.default.remove(cookieName);
} else vconsole = _jsCookie.default.get(cookieName);
if (vconsole !== undefined && vconsole === md5) {
  _jsCookie.default.set(cookieName, md5, 3600);
  var _module = function module() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("vconsole"));
    });
  };
  _module().then(function (Module) {
    new Module.default();
  });
}
// 自定义实现String 类型的replaceAll方法
String.prototype.replaceAll = function (s1, s2) {
  return this.replace(new RegExp(s1, "gm"), s2);
};
// Vue.prototype.$modalCoupon = modalCoupon
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */

// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get("size") || "mini" // set element-ui default size
});

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});

//icon

// global css
// import "@/components";
_vue.default.use(_plugins.default);
_vue.default.config.productionTip = false;
var $previewApp = document.getElementById("previewApp");
var childAttrs = {
  file: "",
  dialog: ' width="600px" class="dialog-width" v-if="visible" :visible.sync="visible" :modal-append-to-body="false" '
};
window.addEventListener("message", init, false);
function buildLinks(links) {
  var strs = "";
  links.forEach(function (url) {
    strs += "<link href=\"".concat(url, "\" rel=\"stylesheet\">");
  });
  return strs;
}
function init(event) {
  if (event.data.type === "refreshFrame") {
    var code = event.data.data;
    var attrs = childAttrs[code.generateConf.type];
    var links = "";
    if (Array.isArray(code.links) && code.links.length > 0) {
      links = buildLinks(code.links);
    }
    $previewApp.innerHTML = "".concat(links, "<style>").concat(code.css, "</style><div id=\"app\"></div>");
    if (Array.isArray(code.scripts) && code.scripts.length > 0) {
      (0, _loadScript.loadScriptQueue)(code.scripts, function () {
        newVue(attrs, code.js, code.html);
      });
    } else {
      newVue(attrs, code.js, code.html);
    }
  }
}
function newVue(attrs, main, html) {
  // eslint-disable-next-line no-eval
  main = eval("(".concat(main, ")"));
  main.template = "<div>".concat(html, "</div>");
  new _vue.default({
    components: {
      child: main
    },
    data: function data() {
      return {
        visible: true
      };
    },
    template: "<div><child ".concat(attrs, "/></div>")
  }).$mount("#app");
}
String.prototype.replaceAll = function (s1, s2) {
  return this.replace(new RegExp(s1, "gm"), s2);
};

/* 路由发生变化修改页面title */
_router.default.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
new _vue.default({
  el: "#app",
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});
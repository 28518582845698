var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.infoLoading,
                  expression: "infoLoading",
                },
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.getInfo },
                      model: {
                        value: _vm.ruleForm.dept,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "dept", $$v)
                        },
                        expression: "ruleForm.dept",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "CSG" } }, [
                        _vm._v("CSG"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "ISG" } }, [
                        _vm._v("ISG"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "WS" } }, [
                        _vm._v("WS"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "DS" } }, [
                        _vm._v("DS"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cont-block" },
                [
                  _c("div", { staticClass: "cont-t" }, [_vm._v("主Banner")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片配置：", prop: "name" } },
                    [
                      _c("div", { staticStyle: { color: "#999" } }, [
                        _vm._v(
                          "\n            （请上传Banner轮播图，建议尺寸：500 X 750像素）\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.ruleForm.bannerList,
                        function (rowItem, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "row-set" },
                            [
                              _c("div", { staticClass: "f-t" }, [
                                _vm._v("访问页面："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleSetUrl(
                                            "bannerList",
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          rowItem.jumpType > 0
                                            ? "已配置"
                                            : "路径配置"
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "f-t" }, [
                                _vm._v("图片："),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "upLoadPicBox",
                                    on: {
                                      click: function ($event) {
                                        return _vm.modalBannerPicTap(index)
                                      },
                                    },
                                  },
                                  [
                                    rowItem.pic
                                      ? _c("div", { staticClass: "pictrue" }, [
                                          _c("img", {
                                            attrs: { src: rowItem.pic },
                                          }),
                                        ])
                                      : _c("div", { staticClass: "upLoad" }, [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-camera cameraIconfont",
                                          }),
                                        ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "f-t" }, [
                                _vm._v("图片名称："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "",
                                        prop:
                                          "bannerList." + index + ".picName",
                                        rules: [
                                          {
                                            required: true,
                                            validator: _vm.validPicName,
                                            trigger: "blur",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "180px",
                                          "margin-right": "20px",
                                        },
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: rowItem.picName,
                                          callback: function ($$v) {
                                            _vm.$set(rowItem, "picName", $$v)
                                          },
                                          expression: "rowItem.picName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delBanner(index)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }
                      ),
                      _vm._v(" "),
                      !!_vm.bannerModel
                        ? _c("div", { staticClass: "row-set" }, [
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("访问页面："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSetUrl(
                                          "bannerList",
                                          -1
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.bannerModel.jumpType > 0
                                          ? "已配置"
                                          : "路径配置"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("图片："),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalBannerPicTap(-1)
                                    },
                                  },
                                },
                                [
                                  _vm.bannerModel.pic
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: { src: _vm.bannerModel.pic },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("图片名称："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "", prop: "picName" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "180px",
                                        "margin-right": "20px",
                                      },
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: _vm.bannerModel.picName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.bannerModel,
                                            "picName",
                                            $$v
                                          )
                                        },
                                        expression: "bannerModel.picName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.submitBannerRow },
                                  },
                                  [_vm._v("保存")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.bannerCancle },
                                  },
                                  [_vm._v("取消")]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm.ruleForm.bannerList &&
                          _vm.ruleForm.bannerList.length < 6
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", plain: "" },
                                  on: { click: _vm.addBanner },
                                },
                                [_vm._v("新增图片配置\n            ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cont-block" },
                [
                  _c("div", { staticClass: "cont-t" }, [_vm._v("Offer区")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片配置：", prop: "name" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("offer")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.offer
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: { src: _vm.ruleForm.offer },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("(推荐尺寸：550px X 750px)"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "图片名称",
                        prop: "offerName",
                        rules: [
                          {
                            required: true,
                            validator: _vm.validPicName,
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.ruleForm.offerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "offerName", $$v)
                          },
                          expression: "ruleForm.offerName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cont-block" },
                [
                  _c("div", { staticClass: "cont-t" }, [_vm._v("企业认证区")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片配置：", prop: "firm" } },
                    [
                      _c("span", { staticStyle: { color: "#999" } }, [
                        _vm._v(
                          "(请上传企业认证图，尺寸为：160px X\n            678px，最多可上传3张图片)"
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.ruleForm.corpAuthList,
                        function (rowItem, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "row-set" },
                            [
                              _c("div", { staticClass: "f-t" }, [
                                _vm._v("访问页面："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleSetUrl(
                                            "corpAuthList",
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          rowItem.jumpType > 0
                                            ? "已配置"
                                            : "路径配置"
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "f-t" }, [
                                _vm._v("图片："),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "upLoadPicBox",
                                    on: {
                                      click: function ($event) {
                                        return _vm.modalFirmPicTap(index)
                                      },
                                    },
                                  },
                                  [
                                    rowItem.pic
                                      ? _c("div", { staticClass: "pictrue" }, [
                                          _c("img", {
                                            attrs: { src: rowItem.pic },
                                          }),
                                        ])
                                      : _c("div", { staticClass: "upLoad" }, [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-camera cameraIconfont",
                                          }),
                                        ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "f-t" }, [
                                _vm._v("图片名称："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "",
                                        prop:
                                          "corpAuthList." + index + ".picName",
                                        rules: [
                                          {
                                            required: true,
                                            validator: _vm.validPicName,
                                            trigger: "blur",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "180px",
                                          "margin-right": "20px",
                                        },
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: rowItem.picName,
                                          callback: function ($$v) {
                                            _vm.$set(rowItem, "picName", $$v)
                                          },
                                          expression: "rowItem.picName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delFirm(index)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }
                      ),
                      _vm._v(" "),
                      _vm.firmModelShow
                        ? _c("div", { staticClass: "row-set" }, [
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("访问页面："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSetUrl(
                                          "corpAuthList",
                                          -1
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.firmModel.jumpType > 0
                                          ? "已配置"
                                          : "路径配置"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("图片："),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalFirmPicTap(-1)
                                    },
                                  },
                                },
                                [
                                  _vm.firmModel.pic
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: { src: _vm.firmModel.pic },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("图片名称："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "", prop: "picName" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "180px",
                                        "margin-right": "20px",
                                      },
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: _vm.firmModel.picName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.firmModel,
                                            "picName",
                                            $$v
                                          )
                                        },
                                        expression: "firmModel.picName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.submitFirmRow },
                                  },
                                  [_vm._v("保存")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.firmCancle },
                                  },
                                  [_vm._v("取消")]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm.ruleForm.corpAuthList.length < 3
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", plain: "" },
                                  on: { click: _vm.addFirm },
                                },
                                [_vm._v("新增图片配置\n            ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cont-block" },
                [
                  _c("div", { staticClass: "cont-t" }, [_vm._v("热卖专区区")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题配置：", prop: "name" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("product")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.product
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: { src: _vm.ruleForm.product },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("(推荐尺寸：179 X 750px)"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "颜色配置：", prop: "color" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.ruleForm.color,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "color", $$v)
                          },
                          expression: "ruleForm.color",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类配置：", prop: "name" } },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "hotTypeSel",
                          attrs: { placeholder: "请选择" },
                          on: { "visible-change": _vm.hotTypeSelVisual },
                          model: {
                            value: _vm.saleType,
                            callback: function ($$v) {
                              _vm.saleType = $$v
                            },
                            expression: "saleType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "分类一", value: 0 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "分类二", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "分类三", value: 2 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "padding-left": "150px" } },
                    _vm._l(_vm.ruleForm.classList, function (saleItem, index) {
                      return _c("div", { key: index }, [
                        _vm.saleType == index
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "名称配置：",
                                      prop:
                                        "classList[" + index + "].className",
                                      rules: [
                                        {
                                          required: true,
                                          validator: _vm.validHotClassName,
                                          trigger: "blur",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: saleItem.className,
                                        callback: function ($$v) {
                                          _vm.$set(saleItem, "className", $$v)
                                        },
                                        expression: "saleItem.className",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "分类图配置：",
                                      prop: "classPic",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "upLoadPicBox",
                                        on: {
                                          click: function ($event) {
                                            return _vm.modalSaleTap(index)
                                          },
                                        },
                                      },
                                      [
                                        saleItem.classPic
                                          ? _c(
                                              "div",
                                              { staticClass: "pictrue" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: saleItem.classPic,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "upLoad" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-camera cameraIconfont",
                                                }),
                                              ]
                                            ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#999" } },
                                          [_vm._v("(推荐尺寸：280 X 750px)")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "商品图片配置：",
                                      prop: "productList",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#999" } },
                                      [
                                        _vm._v(
                                          "(请上传产品封面，尺寸为：590px X\n                  320px，最多可上传6张图片)"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      saleItem.productList,
                                      function (rowItem, prdIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: prdIndex,
                                            staticClass: "row-set",
                                          },
                                          [
                                            _c("div", { staticClass: "f-t" }, [
                                              _vm._v("留资名称："),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "",
                                                      prop:
                                                        "classList." +
                                                        index +
                                                        ".productList." +
                                                        prdIndex +
                                                        ".stayName",
                                                      rules: [
                                                        {
                                                          required: true,
                                                          validator:
                                                            _vm.validHotStayName,
                                                          trigger: "blur",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "180px",
                                                      },
                                                      attrs: {
                                                        placeholder: "请输入",
                                                      },
                                                      model: {
                                                        value: rowItem.stayName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            rowItem,
                                                            "stayName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "rowItem.stayName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "f-t" }, [
                                              _vm._v("图片："),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalHotPrdPicTap(
                                                        index,
                                                        prdIndex
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  rowItem.pic
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: rowItem.pic,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass: "upLoad",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticStyle: { flex: "0.5" } },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delHotPrd(
                                                          index,
                                                          prdIndex
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm.hotPrdModelShow
                                      ? _c("div", { staticClass: "row-set" }, [
                                          _c("div", { staticClass: "f-t" }, [
                                            _vm._v("留资名称："),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "180px" },
                                                attrs: {
                                                  placeholder: "请输入",
                                                },
                                                model: {
                                                  value:
                                                    _vm.hotPrdModel.stayName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.hotPrdModel,
                                                      "stayName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "hotPrdModel.stayName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "f-t" }, [
                                            _vm._v("图片："),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "upLoadPicBox",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.modalHotPrdPicTap(
                                                      -1,
                                                      -1
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm.hotPrdModel.pic
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "pictrue",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: _vm.hotPrdModel
                                                              .pic,
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      { staticClass: "upLoad" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticStyle: { flex: "0.5" } },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.submitHotPrd(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("保存")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: _vm.hotPrdCancle,
                                                  },
                                                },
                                                [_vm._v("取消")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        saleItem.productList.length < 6
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  plain: "",
                                                },
                                                on: { click: _vm.addHotPrd },
                                              },
                                              [
                                                _vm._v(
                                                  "新增图片配置\n                  "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cont-block" },
                [
                  _c("div", { staticClass: "cont-t" }, [_vm._v("客服区")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "入口图片配置：", prop: "name" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("customerServiceEntrance")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.customerServiceEntrance
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.customerServiceEntrance,
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("(推荐尺寸：140 X 750px)"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "静静二维码：", prop: "name" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("customerServiceQrCode")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.customerServiceQrCode
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.customerServiceQrCode,
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("(推荐尺寸：150 X 150px)"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cont-block" },
                [
                  _c("div", { staticClass: "cont-t" }, [_vm._v("适用场景区")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题配置：", prop: "name" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("applicationScene")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.applicationScene
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: { src: _vm.ruleForm.applicationScene },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("(推荐尺寸：180 X 750px)"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片配置：", prop: "firm" } },
                    [
                      _c("span", { staticStyle: { color: "#999" } }, [
                        _vm._v("(请对应上传封面图，尺寸为：180px X 750px)"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.ruleForm.sceneList, function (rowItem, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "row-set" },
                          [
                            _c("div", { staticClass: "f-s" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    index + 1 < _vm.ruleForm.sceneList.length
                                      ? index + 1
                                      : "更多"
                                  ) +
                                  " "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v("访问页面："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSetUrl(
                                          "sceneList",
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        rowItem.jumpType > 0
                                          ? "已配置"
                                          : "路径配置"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "f-t-2" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    index + 1 < _vm.ruleForm.sceneList.length
                                      ? "图片："
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              index < 3
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "upLoadPicBox",
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalScenePicTap(index)
                                        },
                                      },
                                    },
                                    [
                                      rowItem.pic
                                        ? _c(
                                            "div",
                                            { staticClass: "pictrue" },
                                            [
                                              _c("img", {
                                                attrs: { src: rowItem.pic },
                                              }),
                                            ]
                                          )
                                        : _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "f-t" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    index + 1 < _vm.ruleForm.sceneList.length
                                      ? "图片名称："
                                      : "路径名称："
                                  ) +
                                  " "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "",
                                      prop: "sceneList." + index + ".picName",
                                      rules: [
                                        {
                                          required: true,
                                          validator: _vm.validPicName,
                                          trigger: "blur",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "180px",
                                        "margin-right": "20px",
                                      },
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: rowItem.picName,
                                        callback: function ($$v) {
                                          _vm.$set(rowItem, "picName", $$v)
                                        },
                                        expression: "rowItem.picName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delScene(index)
                                      },
                                    },
                                  },
                                  [_vm._v("清空")]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cont-block" },
                [
                  _c("div", { staticClass: "cont-t" }, [_vm._v("解决方案区")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题配置：", prop: "name" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("solution")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.solution
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: { src: _vm.ruleForm.solution },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("(推荐尺寸：180 X 750px)"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片配置：", prop: "firm" } },
                    [
                      _c("span", { staticStyle: { color: "#999" } }, [
                        _vm._v(
                          "(请上传解决方案封面，尺寸为：430px X\n            320px，最多可上传8张图片)"
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.ruleForm.solutionList,
                        function (rowItem, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "solute-row-set row-set",
                            },
                            [
                              _c("div", { staticClass: "s-left" }, [
                                _c("div", [
                                  _c("div", { staticClass: "f-t" }, [
                                    _vm._v("访问页面："),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleSetUrl(
                                                "solutionList",
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              rowItem.jumpType > 0
                                                ? "已配置"
                                                : "路径配置"
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "f-t" }, [
                                    _vm._v("图片："),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "upLoadPicBox",
                                        on: {
                                          click: function ($event) {
                                            return _vm.modalSolutionPicTap(
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        rowItem.pic
                                          ? _c(
                                              "div",
                                              { staticClass: "pictrue" },
                                              [
                                                _c("img", {
                                                  attrs: { src: rowItem.pic },
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "upLoad" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-camera cameraIconfont",
                                                }),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "f-t" }, [
                                    _vm._v("图片名称："),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "",
                                            prop:
                                              "solutionList." +
                                              index +
                                              ".picName",
                                            rules: [
                                              {
                                                required: true,
                                                validator: _vm.validPicName,
                                                trigger: "blur",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "180px" },
                                            attrs: { placeholder: "请输入" },
                                            model: {
                                              value: rowItem.picName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  rowItem,
                                                  "picName",
                                                  $$v
                                                )
                                              },
                                              expression: "rowItem.picName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("div", { staticClass: "f-t" }, [
                                    _vm._v("标签配置："),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "",
                                            prop:
                                              "solutionList." + index + ".tag",
                                            rules: [
                                              {
                                                required: true,
                                                validator: _vm.validTagName,
                                                trigger: "blur",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder:
                                                "最多可输入25个字，标签以逗号隔开",
                                            },
                                            model: {
                                              value: rowItem.tag,
                                              callback: function ($$v) {
                                                _vm.$set(rowItem, "tag", $$v)
                                              },
                                              expression: "rowItem.tag",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "s-p" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delSolution(index)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }
                      ),
                      _vm._v(" "),
                      _vm.solutionModelShow
                        ? _c("div", { staticClass: "solute-row-set row-set" }, [
                            _c("div", { staticClass: "s-left" }, [
                              _c("div", [
                                _c("div", { staticClass: "f-t" }, [
                                  _vm._v("访问页面："),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleSetUrl(
                                              "solutionList",
                                              -1
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.solutionModel.jumpType > 0
                                              ? "已配置"
                                              : "路径配置"
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "f-t" }, [
                                  _vm._v("图片："),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "upLoadPicBox",
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalSolutionPicTap(-1)
                                        },
                                      },
                                    },
                                    [
                                      _vm.solutionModel.pic
                                        ? _c(
                                            "div",
                                            { staticClass: "pictrue" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.solutionModel.pic,
                                                },
                                              }),
                                            ]
                                          )
                                        : _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "f-t" }, [
                                  _vm._v("图片名称："),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "", prop: "picName" } },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "180px" },
                                          attrs: { placeholder: "请输入" },
                                          model: {
                                            value: _vm.solutionModel.picName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.solutionModel,
                                                "picName",
                                                $$v
                                              )
                                            },
                                            expression: "solutionModel.picName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("div", { staticClass: "f-t" }, [
                                  _vm._v("标签配置："),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "", prop: "tag" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder:
                                              "最多可输入25个字，标签以逗号隔开",
                                          },
                                          model: {
                                            value: _vm.solutionModel.tag,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.solutionModel,
                                                "tag",
                                                $$v
                                              )
                                            },
                                            expression: "solutionModel.tag",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "s-p" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.submitSolutionRow },
                                  },
                                  [_vm._v("保存")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.solutionCancle },
                                  },
                                  [_vm._v("取消")]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm.ruleForm.solutionList.length < 8
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", plain: "" },
                                  on: { click: _vm.addSolution },
                                },
                                [_vm._v("新增图片配置\n            ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cont-block" },
                [
                  _c("div", { staticClass: "cont-t" }, [_vm._v("本周推荐")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "相关文章：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "dialogWidth",
                          staticStyle: { width: "250px" },
                          attrs: { placeholder: "请选择", readOnly: "" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "append" },
                              on: { click: _vm.changeLike },
                              slot: "append",
                            },
                            [_vm._v("选择")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "des-info demo-like",
                          attrs: { placement: "bottom" },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [_vm._v("最多可添加3个文章")]
                          ),
                          _vm._v(" "),
                          _c("i", { staticClass: "el-icon-question" }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.articleList.length > 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        _vm._l(
                          _vm.ruleForm.articleList,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "store-child" },
                              [
                                _c("span", [_vm._v("相关文章：")]),
                                _vm._v(" "),
                                _c("div", [_vm._v(_vm._s(item.title))]),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "del-btn",
                                    attrs: { size: "small", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.removeArticle(index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除\n            ")]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSubmit },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("page-link", {
        ref: "pageLinkSet",
        attrs: { visible: _vm.pageLinkShow, editData: _vm.pageLinkData },
        on: {
          "update:visible": function ($event) {
            _vm.pageLinkShow = $event
          },
          cancle: _vm.cancleSetUrl,
          save: _vm.updateUrlSet,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
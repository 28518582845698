var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "微信公众号", prop: "weChatId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择微信公众号" },
                      model: {
                        value: _vm.form.weChatId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "weChatId", $$v)
                        },
                        expression: "form.weChatId",
                      },
                    },
                    _vm._l(_vm.weChatList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "微信模板", prop: "templateId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择微信模板" },
                      model: {
                        value: _vm.form.templateId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "templateId", $$v)
                        },
                        expression: "form.templateId",
                      },
                    },
                    _vm._l(_vm.templateList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-form-item", { attrs: { label: "模板内容" } }, [
                _c("div", { staticClass: "template_content" }, [
                  _c(
                    "div",
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.form.firstData,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "firstData", $$v)
                          },
                          expression: "form.firstData",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "template_content_item" },
                    [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("报名主题："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.form.wxTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "wxTitle", $$v)
                          },
                          expression: "form.wxTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "template_content_item" },
                    [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("报名状态："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.form.wxStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "wxStatus", $$v)
                          },
                          expression: "form.wxStatus",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "template_content_item" },
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.form.wxRemark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "wxRemark", $$v)
                          },
                          expression: "form.wxRemark",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "template_content_example" }, [
                    _c("div", [
                      _c("label", [_vm._v("参数赋值：")]),
                      _vm._v(" "),
                      _c("span", [_vm._v("微信公众号后台配置的消息模板")]),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("label", [_vm._v("first:")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(" 品牌大使开始招募了！")]),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("label", [_vm._v("keyword1:")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(" 报名品牌大使")]),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("label", [_vm._v("keyword2:")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(" 已获得资格，待报名确认")]),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("label", [_vm._v("remark:")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          " 参与品牌体验任务，可获取金币、新品试用及品牌体验活动等特权哦。快来添加微信报名吧！"
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "微信测试" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择微信模板" },
                      model: {
                        value: _vm.form.member,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "member", $$v)
                        },
                        expression: "form.member",
                      },
                    },
                    _vm._l(_vm.memberList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "connect_btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.testConnect },
                    },
                    [_vm._v("\n          连接测试\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auto = require("@/api/auto");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "couponsForm",
  data: function data() {
    return {
      couponsList: [{
        label: "老客户满199-30",
        value: "1"
      }, {
        label: "新客户满299-15",
        value: "2"
      }],
      form: {
        name: "",
        // 节点名称
        value: "",
        // 选择优惠券类型
        type: "action"
      },
      rules: {
        value: [{
          required: true,
          message: "请选择优惠券",
          trigger: "change"
        }]
      }
    };
  },
  created: function created() {
    var _this = this;
    (0, _auto.getCouponDict)().then(function (res) {
      _this.couponsList = res;
    });
  },
  methods: {
    init: function init(params) {
      var initForm = {
        value: "",
        // 公众号
        name: "",
        type: "action",
        actionType: "coupon"
      };
      console.log(params, 'params开始');
      this.form = _objectSpread(_objectSpread({}, initForm), params);
      console.log(this.form, 'this.form');
    }
  }
};
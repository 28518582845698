"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var landingApi = _interopRequireWildcard(require("@/api/landing.js"));
var _editDialog = _interopRequireDefault(require("./edit/editDialog.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "list",
  components: {
    edit: _editDialog.default
  },
  data: function data() {
    return {
      listData: [{}],
      editDialogVisual: false
    };
  },
  mounted: function mounted() {
    this.handlerGetListData();
  },
  methods: {
    onchangeIsShow: function onchangeIsShow(row, flag) {
      var _this = this;
      landingApi.PublicBeautyHome({
        id: row.id,
        status: flag
      }).then(function (res) {
        _this.$message.success("操作成功");
        _this.handlerGetListData();
      });
    },
    handerSearch: function handerSearch() {
      this.handlerGetListData();
    },
    handleSort: function handleSort(scope, flag) {
      var _this2 = this;
      landingApi.SortBeautyHome({
        id: scope.row.id,
        sortType: flag
      }).then(function () {
        _this2.handlerGetListData();
      });
    },
    handlerGetListData: function handlerGetListData() {
      var _this3 = this;
      landingApi.ListBeautyHome().then(function (data) {
        _this3.listData = data;
      });
    },
    handlerOpenEdit: function handlerOpenEdit(id) {
      var _this4 = this;
      this.editDialogVisual = true;
      this.$nextTick(function () {
        _this4.$refs['editForm'].initEditData(id);
      });
    },
    handlerHideDialog: function handlerHideDialog() {},
    handlerDelete: function handlerDelete(rowData) {
      var _this5 = this;
      this.$confirm("确定删除当前数据", "提示").then(function (result) {
        landingApi.DelBeautyHome({
          id: rowData.id
        }).then(function (data) {
          _this5.$message.success("删除数据成功");
          _this5.handlerGetListData();
        });
      });
    }
  }
};
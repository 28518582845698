"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/contentGoods/index"));
var _index2 = _interopRequireDefault(require("@/components/Tinymce/index"));
var valueAccountApi = _interopRequireWildcard(require("@/api/valueAccount.js"));
var _systemSetting = require("@/api/systemSetting");
var _auth = require("@/utils/auth");
var _configApi = require("@/api/configApi");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 导入组件
var _default = exports.default = {
  // name: "edit",
  components: {
    Tinymce: _index2.default,
    contentGoods: _index.default
  },
  props: {
    isEdit: {
      type: Number,
      default: 0
    },
    dataObj: {
      type: Object
    }
  },
  data: function data() {
    return {
      loading: false,
      constants: this.$constants,
      pram: {
        id: null,
        solutionName: "",
        pdfUrl: "",
        sliderImages: [],
        pdfPreviewUrlList: "",
        status: 0,
        delete: 0
      },
      editData: {},
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      },
      infoLoading: false,
      dragging: null
    };
  },
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
  },
  mounted: function mounted() {
    if (this.isEdit == 1) {
      this.getInfo();
      // this.setTagsViewTitle();
    }
  },
  watch: {
    'pram.sliderImages': function pramSliderImages() {
      this.$refs['pram'].validateField('sliderImages');
    },
    'pram.pdfUrl': function pramPdfUrl() {
      this.$refs['pram'].validateField('pdfUrl');
    }
  },
  methods: {
    close: function close() {
      this.$emit("hideDialog");
    },
    // 移动
    handleDragStart: function handleDragStart(e, item) {
      this.dragging = item;
    },
    handleDragEnd: function handleDragEnd(e, item) {
      this.dragging = null;
    },
    handleDragOver: function handleDragOver(e) {
      e.dataTransfer.dropEffect = "move";
    },
    handleDragEnter: function handleDragEnter(e, item) {
      e.dataTransfer.effectAllowed = "move";
      if (item === this.dragging) {
        return;
      }
      var newItems = _toConsumableArray(this.pram.sliderImages);
      var src = newItems.indexOf(this.dragging);
      var dst = newItems.indexOf(item);
      newItems.splice.apply(newItems, [dst, 0].concat(_toConsumableArray(newItems.splice(src, 1))));
      this.pram.sliderImages = newItems;
    },
    handleRemove: function handleRemove(i) {
      this.pram.sliderImages.splice(i, 1);
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit === "1" && !num) {
          _this.pram.coverPath = img[0].sattDir;
        }
        if (tit === "2" && !num) {
          if (img.length > 4) return this.$message.warning("最多选择4张图片！");
          if (img.length + _this.pram.sliderImages.length > 4) return this.$message.warning("最多选择4张图片！");
          img.map(function (item) {
            _this.pram.sliderImages.push(item.sattDir);
          });
        }
      }, tit, "content");
    },
    // 上传视频
    getVideo: function getVideo(url, field) {
      this.pram[field] = url;
    },
    getInfo: function getInfo() {
      var _this2 = this;
      this.infoLoading = true;
      var prams = {
        id: this.dataObj.id
      };
      valueAccountApi.infoSolution(prams).then(function (data) {
        console.log("data=====", data);
        _this2.editData = data;
        _this2.editData.sliderImages = data.pdfPreviewUrlList.split(",");
        _this2.hadlerInitEditData();
      }).catch(function () {
        _this2.infoLoading = false;
      });
    },
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this3 = this;
      var formData = new FormData();
      formData.append("media", param.file);
      var loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      (0, _systemSetting.fileImageApi)(formData, {
        type: "image"
      }).then(function (res) {
        loading.close();
        _this3.pram.mediaId = res.mediaId;

        // this.formValidate.contents.mediaId = res.mediaId
        // this.formValidate.contents.srcUrl = res.url
        _this3.$message.success("上传成功");
      }).catch(function () {
        loading.close();
      });
    },
    // 修改赋值
    hadlerInitEditData: function hadlerInitEditData() {
      // if (!this.dataObj.id) return;
      var _this$editData = this.editData,
        solutionName = _this$editData.solutionName,
        id = _this$editData.id,
        status = _this$editData.status,
        pdfUrl = _this$editData.pdfUrl,
        sliderImages = _this$editData.sliderImages;
      console.log(1111111111, this.editData);
      this.pram.solutionName = solutionName;
      this.pram.pdfUrl = pdfUrl;
      this.pram.id = id;
      this.pram.status = status;
      this.pram.sliderImages = sliderImages;
      this.infoLoading = false;
    },
    handerSubmit: function handerSubmit(form) {
      var _this4 = this;
      this.$refs[form].validate(function (valid) {
        if (!valid) return;
        if (_this4.isEdit == 0) {
          _this4.handlerSave();
        } else {
          _this4.handlerUpdate();
        }
      });
    },
    handlerUpdate: function handlerUpdate() {
      var _this5 = this;
      this.loading = true;
      var conInfo = Object.assign({}, this.pram);
      conInfo.pdfPreviewUrlList = this.pram.sliderImages.join(",");
      delete conInfo["sliderImages"];
      conInfo.delete = 0;
      valueAccountApi.updateSolution(conInfo).then(function (data) {
        _this5.$emit("hideDialog");
        _this5.$message.success("修改解决方案成功");
        _this5.loading = false;
        // this.$router.push({ path: "/content/caseManager" });
      }).catch(function () {
        _this5.loading = false;
      });
    },
    // 新增
    handlerSave: function handlerSave() {
      var _this6 = this;
      this.loading = true;
      var conInfo = Object.assign({}, this.pram);
      conInfo.pdfPreviewUrlList = this.pram.sliderImages.join(",");
      delete conInfo["sliderImages"];
      valueAccountApi.addSolution(conInfo).then(function (data) {
        _this6.$emit("hideDialog");
        _this6.$message.success("新增解决方案成功");
        _this6.loading = false;
        // this.$router.push({ path: "/content/caseManager" });
      }).catch(function () {
        _this6.loading = false;
      });
    },
    setTagsViewTitle: function setTagsViewTitle() {
      var title = "编辑解决方案";
      var route = Object.assign({}, this.tempRoute, {
        title: "".concat(title, "-").concat(this.$route.params.id)
      });
      this.$store.dispatch("tagsView/updateVisitedView", route);
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configCheckUnique = configCheckUnique;
exports.configDelete = configDelete;
exports.configGetUniq = configGetUniq;
exports.configInfo = configInfo;
exports.configList = configList;
exports.configSave = configSave;
exports.configSaveForm = configSaveForm;
exports.configSaveUniq = configSaveUniq;
exports.configUpdate = configUpdate;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function configCheckUnique(pram) {
  var data = {
    name: pram.name
  };
  return (0, _request.default)({
    url: '/admin/system/config/check',
    method: 'GET',
    params: data
  });
}
function configDelete(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/system/config/delete',
    method: 'GET',
    params: data
  });
}
function configInfo(pram) {
  var data = {
    formId: pram.id
  };
  return (0, _request.default)({
    url: '/admin/system/config/info',
    method: 'GET',
    params: data
  });
}
function configList(pram) {
  var data = {
    page: pram.page,
    limit: pram.limit
  };
  return (0, _request.default)({
    url: '/admin/system/config/list',
    method: 'GET',
    params: data
  });
}
function configSave(pram) {
  var data = {
    systemConfigRequest: {
      desc: pram.desc,
      groupId: pram.groupId,
      info: pram.info,
      name: pram.name,
      pid: pram.pid,
      status: pram.status,
      type: pram.type,
      value: pram.value // value 存储表单配置数据，其他的参数来自于父级数据 justForAPI
    }
  };
  return (0, _request.default)({
    url: '/admin/system/config/save',
    method: 'POST',
    params: data
  });
}
function configSaveForm(pram) {
  return (0, _request.default)({
    url: '/admin/system/config/save/form',
    method: 'POST',
    data: pram
  });
}
function configUpdate(pram) {
  var data = {
    id: pram.id,
    systemConfigRequest: pram.systemConfigRequest
  };
  return (0, _request.default)({
    url: '/admin/system/config/update',
    method: 'POST',
    params: data
  });
}
function configSaveUniq(pram) {
  var data = {
    key: pram.key,
    value: pram.value
  };
  return (0, _request.default)({
    url: '/admin/system/config/saveuniq',
    method: 'POST',
    params: data
  });
}
function configGetUniq(pram) {
  var data = {
    key: pram.key
  };
  return (0, _request.default)({
    url: '/admin/system/config/getuniq',
    method: 'GET',
    params: data
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/layout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; } /** When your routing table is too long, you can split it into small modules **/
var marketingRouter = {
  path: '/marketing',
  component: _layout.default,
  redirect: '/coupon/list',
  name: 'Marketing',
  meta: {
    title: '营销',
    icon: 'clipboard'
  },
  children: [{
    path: 'note',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/noteList/note'));
      });
    },
    name: 'note',
    meta: {
      title: '短信管理',
      icon: ''
    },
    hidden: false
  }, {
    path: 'coupon',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/coupon/index'));
      });
    },
    name: 'Coupon',
    meta: {
      title: '优惠券',
      icon: ''
    },
    children: [{
      path: 'template',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/coupon/couponTemplate/index'));
        });
      },
      name: 'couponTemplate',
      hidden: true,
      meta: {
        title: '优惠券模板',
        icon: ''
      }
    }, {
      path: 'list/save/:id?',
      name: 'couponAdd',
      meta: {
        title: '优惠劵添加',
        noCache: true,
        activeMenu: "/marketing/coupon/list"
      },
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/coupon/list/creatCoupon'));
        });
      }
    }, {
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/coupon/list/index'));
        });
      },
      name: 'List',
      meta: {
        title: '优惠券列表',
        icon: ''
      }
    }, {
      path: 'record',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/coupon/record/index'));
        });
      },
      name: 'Record',
      meta: {
        title: '会员领取记录',
        icon: ''
      }
    }, {
      path: 'group',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/coupon/group/index'));
        });
      },
      name: 'couponGroup',
      meta: {
        title: '优惠券分组',
        icon: ''
      }
    }]
  }, {
    path: 'bargain',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/bargain/index'));
      });
    },
    name: 'Bargain',
    meta: {
      title: '砍价管理',
      icon: ''
    },
    alwaysShow: true,
    hidden: true,
    children: [{
      path: 'bargainGoods',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/bargain/bargainGoods/index'));
        });
      },
      name: 'bargainGoods',
      meta: {
        title: '砍价商品',
        icon: ''
      }
    }, {
      path: 'creatBargain/:id?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/bargain/bargainGoods/creatBargain'));
        });
      },
      name: 'creatBargain',
      meta: {
        title: '砍价商品',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/bargain/bargainGoods"
      }
    }, {
      path: 'bargainList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/bargain/bargainList/index'));
        });
      },
      name: 'bargainList',
      meta: {
        title: '砍价列表',
        icon: ''
      }
    }]
  }, {
    path: 'groupBuy',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/groupBuy/index'));
      });
    },
    name: 'groupBuy',
    meta: {
      title: '拼团管理',
      icon: ''
    },
    hidden: true,
    children: [{
      path: 'groupGoods',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/groupBuy/groupGoods/index'));
        });
      },
      name: 'groupGoods',
      meta: {
        title: '拼团商品',
        icon: ''
      }
    }, {
      path: 'creatGroup/:id?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/groupBuy/groupGoods/creatGroup'));
        });
      },
      name: 'creatGroup',
      meta: {
        title: '拼团商品',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/groupBuy/groupGoods"
      }
    }, {
      path: 'groupList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/groupBuy/groupList/index'));
        });
      },
      name: 'groupList',
      meta: {
        title: '拼团列表',
        icon: ''
      }
    }]
  }, {
    path: 'seckill',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/seckill/index'));
      });
    },
    name: 'Seckill',
    meta: {
      title: '秒杀管理',
      icon: ''
    },
    children: [{
      path: 'config',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/seckill/seckillConfig/index'));
        });
      },
      name: 'SeckillConfig',
      meta: {
        title: '秒杀配置',
        icon: ''
      }
    }, {
      path: 'list/:timeId?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/seckill/seckillList/index'));
        });
      },
      name: 'SeckillList',
      meta: {
        title: '秒杀商品',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/seckill/list"
      }
    }, {
      path: 'creatSeckill/:name?/:timeId?/:id?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/seckill/seckillList/creatSeckill'));
        });
      },
      name: 'CreatSeckill',
      meta: {
        title: '添加秒杀商品',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/seckill/list"
      }
    }]
  }, {
    path: 'integral',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/integral/index'));
      });
    },
    name: 'Integral',
    meta: {
      title: '积分',
      icon: ''
    },
    children: [{
      path: 'integralconfig',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/integral/config/index'));
        });
      },
      name: 'integralConfig',
      meta: {
        title: '积分配置',
        icon: ''
      }
    }, {
      path: 'integrallog',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/integral/integralLog/index'));
        });
      },
      name: 'integralLog',
      meta: {
        title: '积分日志',
        icon: ''
      }
    }]
  }, {
    path: 'buy',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/buy/index'));
      });
    },
    name: 'buy',
    meta: {
      title: '营销工具',
      icon: ''
    },
    children: [{
      path: 'present',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/buy/buyPresent/index'));
        });
      },
      name: 'present',
      meta: {
        title: '买赠',
        icon: ''
      }
    }]
  },
  // {
  //   path: 'flow',
  //   component: () => import('@/views/Flow/index'),
  //   name: 'flow',
  //   meta: { title: '营销列表', icon: '' },
  //   hidden: false
  // },
  {
    path: 'automated',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/automated/index'));
      });
    },
    name: 'automated',
    meta: {
      title: '营销方式',
      icon: ''
    },
    children: [{
      path: 'flow',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/automated/Flow/index'));
        });
      },
      name: 'flow',
      meta: {
        title: '营销配置',
        icon: ''
      }
    }, {
      path: 'way',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/automated/automateList/index'));
        });
      },
      name: 'way',
      meta: {
        title: '营销列表',
        icon: ''
      }
    }]
  }]
};
var _default = exports.default = marketingRouter;
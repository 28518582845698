"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonMixin = require("./common-mixin");
var _branchMixin = require("./branch-mixin");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  mixins: [_commonMixin.commonMixin, _branchMixin.branchMixin],
  data: function data() {
    return {
      branchGroupRules: {
        branchGroupRate: [{
          required: false,
          message: '请输入比例',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    numChange: function numChange(val, index) {
      // 判断
      this.$set(this.branchForm.branchList[index], 'name', "".concat(val, "%"));
    }
  }
};